import React from 'react';
import { Routes, Route } from 'react-router-dom';
import GroupsList from './GroupsList';
import GroupPage from './GroupPage';

export default function GroupsPage() {
  return (
    <Routes>
      <Route path='list' element={<GroupsList />} />
      <Route path='create' element={<GroupPage />} />
      <Route path='edit/:id' element={<GroupPage />} />
    </Routes>
  );
}
