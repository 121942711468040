import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Card, MenuItem, TextField, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { DialogProps } from '@mui/material/Dialog';
import { useNavigate, useLocation } from 'react-router-dom';
import { IAppState } from '../../../../store/rootDuck';
import { setLayoutFooter, setLayoutSubheader } from '../../../../utils/layout';
import ButtonWithLoader from '../../../../components/ui/Buttons/ButtonWithLoader';
import homeStyles from '../../homeStyles';
import useCrudSnackbar from '../../../../hooks/useCrudSnackbar';
import { actions as categoriesActions } from '../../../../store/ducks/categories.duck';
import { actions as bannersActions } from '../../../../store/ducks/banners.duck';
import { useFormatMessage, useTabs } from '../../../../hooks';
import FilesDropzone from '../../../../components/formComponents/FilesDropzone';
import { useCustomFormik } from './hooks/useCustomFormik';
import { Tabs } from './components/Tabs';
import { Container } from './components/Container';
import { TabPanel } from '../../../../components/other/Tab/TabPanel';
import { createChildrenList } from '../../../../utils/createParentList';
import { Modal } from '../../../../components/other/Modals';
import PublicationTable from '../../../../components/tableComponents/Table/PublicationTable';
import { actions as productActions } from '../../../../store/ducks/product.duck';
import AlertDialog from '../../../../components/other/Dialog/AlertDialog';
import { Placement, TypeContentBanner } from '../interfaces';
import { IProduct } from '../../../../interfaces/product';

interface IField<T> {
  title: string;
  field: T;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actions: {
      marginTop: theme.spacing(3),
    },
  })
);

const CreateBannerPage: React.FC<TPropsFromRedux> = ({
  loading,
  success,
  error,
  categories,
  products,
  page,
  perPage,
  total,
  fetch,
  createBanner,
  clearCreateBanner,
  fetchCategories,
}) => {
  const classes = useStyles();
  const homeClasses = homeStyles();
  const navigate = useNavigate();
  const fm = useFormatMessage();
  const { state } = useLocation();
  const [tabValue, changeTab] = useTabs();
  const [image, setImage] = useState<File | null>(null);
  const [openModalProducts, setOpenModalProducts] = useState(false);
  const inputRef = useRef<HTMLDivElement>(null);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [typeContent, setTypeContent] = useState<TypeContentBanner>(TypeContentBanner.LINK);

  const handleSubmitPress = useCallback(
    (data: any) => {
      if (!image) {
        setIsAlertOpen(true);
      } else {
        createBanner(data);
      }
    },
    [image]
  );

  const { values, handleBlur, handleChange, errors, touched, handleSubmit, setFieldValue } =
    useCustomFormik({
      submit: handleSubmitPress,
      image,
    });

  const FIELDS: IField<keyof typeof values>[] = [
    { title: fm('BANNERS.CREATE.TITLE'), field: 'title' },
    { title: fm('description'), field: 'description' },
    // { title: fm('BANNERS.CREATE.EXTERNAL_LINK'), field: 'url' },
  ];

  setLayoutSubheader({
    title: fm('BANNERS.CREATE.CREATE_BANNER'),
  });

  useCrudSnackbar({
    success,
    error,
    clear: () => clearCreateBanner(),
    successMessage: fm('SUCCESS'),
    errorMessage: `${fm('ERROR')}: ${error}`,
    afterSuccess: () => navigate('/banners/list'),
  });

  setLayoutFooter({ show: true });

  useEffect(() => {
    if (state?.placement) {
      setFieldValue('placement', state?.placement);
    }
  }, [state?.placement]);

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    fetch({ page, perPage, categoryId: values.category_id || 0 });
  }, [values.category_id]);

  const handleConnectProduct = useCallback((productId: IProduct) => {
    setOpenModalProducts(false);
    setFieldValue('product_id', productId.id);
    setFieldValue('product_name', productId.name);
  }, []);

  const handleConnectsProduct = useCallback(() => {
    setOpenModalProducts(false);
  }, []);

  return (
    <>
      <AlertDialog
        open={isAlertOpen}
        message={fm('BANNER.CREATE.REQUIRED_PHOTO_FIELD')}
        okText={fm('BANNER.CREATE.SELECT_IMAGE')}
        cancelText={fm('CATEGORIES.DELETE.CANCEL')}
        handleClose={() => {
          setIsAlertOpen(false);
        }}
        handleAgree={() => {
          changeTab({} as React.ChangeEvent<{}>, 1);
          setIsAlertOpen(false);
        }}
      />
      <Container>
        <Tabs tabValue={tabValue} changeTab={changeTab} />
        <form onSubmit={handleSubmit} className={homeClasses.classes.form} autoComplete='off'>
          <TabPanel value={tabValue} index={0}>
            {FIELDS.map(item => {
              return (
                <TextField
                  disabled={item.field === 'url' && !!values.category_id}
                  key={item.field}
                  type='text'
                  label={item.title}
                  margin='normal'
                  name={item.field}
                  value={values[item.field]}
                  multiline={item.field === 'description'}
                  rows={item.field === 'description' ? 3 : 0}
                  variant='outlined'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  helperText={touched[item.field] && errors[item.field]}
                  error={Boolean(touched[item.field] && errors[item.field])}
                />
              );
            })}
            <TextField
              select
              label={fm('TENDER.TYPE.PLACEMENT')}
              margin='normal'
              name='placement'
              value={values.placement}
              variant='outlined'
              onBlur={handleBlur('placement')}
              onChange={handleChange}
            >
              <MenuItem key={Placement.TOP} value={Placement.TOP}>
                {fm(Placement.TOP)}
              </MenuItem>
              <MenuItem key={Placement.MID} value={Placement.MID}>
                {fm(Placement.MID)}
              </MenuItem>
              <MenuItem key={Placement.BOTTOM} value={Placement.BOTTOM}>
                {fm(Placement.BOTTOM)}
              </MenuItem>
            </TextField>
            <TextField
              select
              label={fm('TYPE.CONTENT.BANNER')}
              margin='normal'
              value={typeContent}
              variant='outlined'
              onChange={event => {
                setTypeContent(event.target.value as TypeContentBanner);
              }}
            >
              <MenuItem key={TypeContentBanner.LINK} value={TypeContentBanner.LINK}>
                {fm('BANNERS.CREATE.EXTERNAL_LINK')}
              </MenuItem>
              <MenuItem key={TypeContentBanner.PRODUCT} value={TypeContentBanner.PRODUCT}>
                {fm('BANNERS.CREATE.PRODUCT_LINK')}
              </MenuItem>
            </TextField>
            {typeContent === TypeContentBanner.PRODUCT ? (
              <>
                <TextField
                  select
                  label={fm('PRODUCT.EDIT.CATEGORY')}
                  margin='normal'
                  name='category_id'
                  value={values.category_id || ''}
                  variant='outlined'
                  onBlur={handleBlur('category_id')}
                  onChange={handleChange}
                >
                  {categories &&
                    createChildrenList(categories, '\u00B7').map(cat => (
                      <MenuItem key={cat.id?.toString()} value={cat.id?.toString()}>
                        {cat.name}
                      </MenuItem>
                    ))}
                </TextField>
                <TextField
                  label={fm('PRODUCT.SNACKBAR.TITLE')}
                  margin='normal'
                  name='product_name'
                  variant='outlined'
                  value={values.product_name || ''}
                  disabled={!values.category_id}
                  onClick={() => setOpenModalProducts(true)}
                  ref={inputRef}
                />
              </>
            ) : (
              <TextField
                key='url'
                type='text'
                label={fm('BANNERS.CREATE.EXTERNAL_LINK')}
                margin='normal'
                name='url'
                value={values.url}
                variant='outlined'
                onBlur={handleBlur}
                onChange={handleChange}
                helperText={touched.url && errors.url}
                error={Boolean(touched.url && errors.url)}
              />
            )}
            <div className={classes.actions}>
              <ButtonWithLoader
                disabled={loading}
                loading={loading}
                onPress={() => {
                  if (typeContent === TypeContentBanner.PRODUCT) {
                    setFieldValue('url', '');
                  } else {
                    setFieldValue('product_name', '');
                    setFieldValue('category_id', '');
                    setFieldValue('product_id', '');
                  }
                  handleSubmit();
                  setFieldValue('tabValue', 0);
                }}
              >
                {fm('COMMON.BUTTON.SAVE')}
              </ButtonWithLoader>
            </div>
            <Modal
              DialogProps={{ maxWidth: 'md' } as DialogProps}
              open={openModalProducts}
              onClose={() => setOpenModalProducts(false)}
              title={fm('BANNERS.LINK')}
              content={
                <PublicationTable
                  onConnect={productId => handleConnectProduct(productId)}
                  products={products || []}
                  onConnectOff={() => handleConnectsProduct()}
                  values={values}
                  fetch={({ page, perPage }) => fetch({ perPage, page })}
                  page={page}
                  perPage={perPage}
                  total={total}
                />
              }
              actions={[
                {
                  title: 'Закрыть',
                  onClick: () => setOpenModalProducts(false),
                },
              ]}
            />
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            <div
              style={{
                padding: 40,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {!!image && (
                <Card style={{ marginBottom: 25 }}>
                  <img
                    src={URL.createObjectURL(image)}
                    alt=''
                    style={{ maxWidth: 550, maxHeight: 500 }}
                  />
                </Card>
              )}

              <FilesDropzone
                cropAspect={3 / 1.5}
                uploadFiles={(files: File[]) => {
                  setImage(files[0]);
                  changeTab({} as React.ChangeEvent<{}>, 0);
                }}
                avaliableNumberOfFilesToUpload={1}
                title={fm('COMMON.PHOTO.LOAD')}
                withCrop
              />
            </div>
          </TabPanel>
        </form>
      </Container>
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    me: state.profile.me,
    loading: state.banners.createBannerLoading,
    success: state.banners.createBannerSuccess,
    error: state.banners.createBannerError,

    categories: state.categories.categories,

    products: state.products.products,
    productLoading: state.products.loading,
    page: state.products.page,
    perPage: state.products.per_page,
    total: state.products.total,
  }),
  {
    createBanner: bannersActions.createBannerRequest,
    clearCreateBanner: bannersActions.clearCreateBanner,
    fetch: productActions.fetchRequest,
    fetchCategories: categoriesActions.fetchFullRequest,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CreateBannerPage);
