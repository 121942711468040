import React, { useCallback, useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Button,
  CardActions,
  Typography,
  Theme,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { makeStyles, createStyles } from '@mui/styles';

import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { ICategoryTreeTable } from '../interfaces';
import { ICategoryItem } from '../../categories/interfaces';
import { IProductType } from '../../../../interfaces/productType';

// type StyledTreeItemProps = {
//   select: (id: number) => void;
//   selectedCategoryId: number | null;
//   item: ICategoryItem;
//   classes: any;
// };

const useTreeItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.primary,
      margin: 0,
      paddingLeft: 20,
      listStyle: 'none',
      '&:focus > $content, &$selected > $content': {
        backgroundColor: `var(--tree-view-bg-color)`,
        color: 'var(--tree-view-color)',
      },
      '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
        backgroundColor: 'transparent',
      },
      '&:hover > $content $label, &:hover > $content $label, &$selected > $content $label': {
        backgroundColor: 'transparent',
      },
    },
    cardCategory: {
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    cardButtons: {
      justifyContent: 'flex-end',
      padding: 16,
    },
    buttons: {
      marginRight: theme.spacing(2),
    },
    button: {
      '&:active': {
        opacity: 0.2,
      },
    },
    content: {
      color: theme.palette.text.primary,
      fontWeight: 500,
      '$expanded > &': {
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
    group: {
      marginLeft: 0,
    },
    expanded: {},
    selected: {
      backgroundColor: `var(--tree-view-bg-color)`,
    },
    label: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      paddingBottom: theme.spacing(0.7),
      paddingTop: theme.spacing(0.7),
      width: '100%',
      padding: 10,
      cursor: 'pointer',
      position: 'relative',
      transition: '.25s ease-out',
    },
    labelContainerChildren: {
      maxHeight: '100%',
      overflow: 'hidden',
    },
    labelChildren: {
      display: 'flex',
      alignItems: 'center',
      paddingBottom: theme.spacing(0.7),
      paddingTop: theme.spacing(0.7),
      width: '100%',
      padding: 10,
      cursor: 'pointer',
      paddingLeft: 30,
      transition: '.25s ease-out',
    },
    labelIcon: {},
    labelTitle: {
      fontWeight: 700,
      flexGrow: 1,
      fontSize: 14,
      color: '#282a3c',
    },
    labelText: {
      fontWeight: 600,
      flexGrow: 1,
      fontSize: 14,
      color: '#282a3c',
      opacity: 0.8,
    },
    activeItem: {
      backgroundColor: 'rgba(54, 159, 247,0.5)',
      color: theme.palette.primary.main,
    },
    hide: {
      height: 0,
    },
  })
);

type TestProps = {
  select: (id: number) => void;
  categories: ICategoryItem[];
  selectChildrenId: number | null;
  setSelectChildrenId: (id: number) => void;
  locale: string;
  setSelectedCategoryTypes?: (value: IProductType[]) => void;
  selectedCategoryId?: number | null;
};

export const StyledTreeItem = ({
  categories,
  select,
  setSelectChildrenId,
  selectChildrenId,
  locale,
  setSelectedCategoryTypes,
  selectedCategoryId,
}: TestProps) => {
  return (
    <li>
      {Object.entries(categories).map(([cartKey, item]) => (
        <StyledTreeSubItem
          key={item.id?.toString() || cartKey.toString()}
          category={item}
          select={select}
          selectChildrenId={selectChildrenId}
          setSelectChildrenId={setSelectChildrenId}
          locale={locale}
          setSelectedCategoryTypes={setSelectedCategoryTypes}
          selectedCategoryId={selectedCategoryId}
        />
      ))}
    </li>
  );
};

type TestPropsItem = {
  category: ICategoryItem;
  select: (id: number) => void;
  selectChildrenId: number | null;
  setSelectChildrenId: (id: number) => void;
  locale: string;
  setSelectedCategoryTypes?: (value: IProductType[]) => void;
  selectedCategoryId?: number | null;
};
export const StyledTreeSubItem = ({
  category,
  select,
  setSelectChildrenId,
  selectChildrenId,
  locale,
  setSelectedCategoryTypes,
  selectedCategoryId,
}: TestPropsItem) => {
  const classes = useTreeItemStyles();
  const [selectChild, setSelectChild] = useState<boolean>(false);
  const intl = useIntl();

  // @ts-ignore
  // const nameLang = locale === 'ru' ? category.name : category.names[`name_${locale}`];

  const handleItem = (id: number) => {
    select(id);
    category && category.id && setSelectChildrenId(category.id);
  };
  const onClickHandler = () => {
    setSelectChild(!selectChild);
  };

  useEffect(() => {
    if (selectedCategoryId?.toString() === category.id?.toString() && !selectChild) {
      onClickHandler();
      category &&
        setSelectedCategoryTypes &&
        setSelectedCategoryTypes(category.types?.map(i => i.type) || []);
      category && category.id && handleItem(category.id);
      category && category.id && setSelectChildrenId(category.id);
    }
  }, [selectedCategoryId]);

  return (
    <>
      {/* показывать категории в которых есть товары  {category.products_num !== undefined && category.products_num > 0 ? ( */}
      <li style={{ marginLeft: '20px' }}>
        <div
          onClick={() => {
            onClickHandler();
            category &&
              setSelectedCategoryTypes &&
              setSelectedCategoryTypes(category.types?.map(i => i.type) || []);
            category && category.id && handleItem(category.id);
            category && category.id && setSelectChildrenId(category.id);
          }}
          className={clsx(classes.labelRoot, {
            [classes.activeItem]: category.id === selectChildrenId,
          })}
        >
          <div style={{ position: 'absolute', left: -20 }}>
            {Boolean(category.children?.length) &&
              (selectChild ? <ArrowDropDownIcon /> : <ArrowRightIcon />)}
          </div>
          <Typography
            variant='body2'
            style={{
              fontWeight: 600, 
              flexGrow: 1,
              fontSize: 14,
              color: '#282a3c',
              opacity: 0.8,
            }}
          >
            {intl.locale === 'ru'
              ? category.name
              : // @ts-ignore
                category.names?.[`name_${intl.locale}`] || category.name}
          </Typography>
        </div>
        {/* поменять на !selectChild чтоб категории показывать  в развернутом виде */}
        {selectChild && category.children?.length ? (
          <StyledTreeItem
            categories={category.children}
            select={select}
            selectChildrenId={selectChildrenId}
            setSelectChildrenId={setSelectChildrenId}
            locale={locale}
            setSelectedCategoryTypes={setSelectedCategoryTypes}
          />
        ) : null}
      </li>
      {/* ) : null */}
      {/* } */}
    </>
  );
};

const FilterCategoriesTree: React.FC<ICategoryTreeTable> = ({
  categories,
  searchProducts,
  resetProducts,
  filter,
  setSearch,
  setSelectedCategoryId,
  selectedCategoryId,
  searchForCategory,
  setCategoryTypes,
  companyId,
  isAdmin,
  isVender,
}) => {
  const intl = useIntl();
  const classes = useTreeItemStyles();
  const [selectChildrenId, setSelectChildrenId] = useState<number>(0);
  // const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(null);

  // const fetch = useCallback(() => {
  //   setSearch(true);
  //   searchForCategory && searchForCategory(selectChildrenId);
  //   setCategoryTypes && setCategoryTypes(selectedCategoryTypes);
  //   searchProducts &&
  //     filter &&
  //     searchProducts({
  //       page: 1,
  //       perPage: 12,
  //       filter,
  //       companyId,
  //       categoryId: selectedCategoryId || undefined,
  //       activity,
  //     });
  // }, [
  //   selectedCategoryId,
  //   filter,
  //   searchProducts,
  //   selectChildrenId,
  //   searchForCategory,
  //   activity,
  //   setCategoryTypes,
  // ]);

  useEffect(() => {
    selectedCategoryId && fetch();
  }, [selectedCategoryId]);

  const fetch = useCallback(() => {
    setSearch(true);
    searchForCategory && searchForCategory(selectChildrenId);
    if (searchProducts && selectChildrenId) {
      if (selectedCategoryId && filter && companyId) {
        if (isAdmin || isVender) {
          searchProducts({
            page: 1,
            perPage: 12,
            filter,
            companyId,
            categoryId: selectChildrenId,
          });
        } else
          searchProducts({
            page: 1,
            perPage: 12,
            filter,
            companyId,
            categoryId: selectChildrenId,
            active: true,
          });
      } else if (selectedCategoryId && filter && !companyId) {
        if (isAdmin) {
          searchProducts({
            page: 1,
            perPage: 12,
            filter,
            companyId,
            categoryId: selectChildrenId,
          });
        } else {
          searchProducts({
            page: 1,
            perPage: 12,
            filter,
            companyId,
            categoryId: selectChildrenId,
            active: true,
          });
        }
      }
    }
  }, [
    selectedCategoryId,
    filter,
    searchProducts,
    selectChildrenId,
    searchForCategory,
    companyId,
  ]);

  const select = (id: number) => {
    setSelectedCategoryId(id);
  };

  const clear = () => {
    resetProducts();
    if (filter && filter.parameters.length === 0 && !filter.price_from && !filter.price_to) {
      setSearch(false);
    }
    setCategoryTypes && setCategoryTypes([]);
    setSelectedCategoryId(null);
  };

  return (
    <Card className={classes.cardCategory}>
      <CardContent>
        <Typography variant='h5' className={classes.labelTitle} style={{ marginBottom: 16 }}>
          {intl.formatMessage({ id: 'CATEGORIES.TITLE' })}
        </Typography>
        <ul className={classes.root}>
          <StyledTreeItem
            categories={categories}
            select={select}
            setSelectChildrenId={setSelectChildrenId}
            selectChildrenId={selectChildrenId}
            locale={intl.locale}
            setSelectedCategoryTypes={setCategoryTypes}
            selectedCategoryId={selectedCategoryId}
          />
        </ul>
      </CardContent>
      <CardActions className={classes.cardButtons}>
        <Button onClick={clear} className={classes.buttons} variant='outlined' color='primary'>
          {intl.formatMessage({ id: 'PRODUCT.FILTER.RESET' })}
        </Button>
        <Button
          disabled={Boolean(!selectedCategoryId)}
          className={clsx(classes.button)}
          color='primary'
          variant='contained'
          onClick={fetch}
        >
          {intl.formatMessage({ id: 'PRODUCT.FILTER.SEARCH' })}
        </Button>
      </CardActions>
    </Card>
  );
};

export default FilterCategoriesTree;
