import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { MenuItem, TextField } from '@mui/material';

import ButtonWithLoader from '../../../../../components/other/Buttons/ButtonWithLoader';
import { IProductType } from '../../../../../interfaces/productType';
import homeStyles from '../../../../../constants/homeStyles';
import { RedOutlinedButton } from '../../../../../components/other/Buttons';
import { useStylesTypeForm } from '../../hooks/useStyles';
import { categoryLanguages } from '../../../categories/utils/categoryLanguages';

interface IProps {
  type?: IProductType;
  typeAction: any;
  delProductType: any;
}

const getInitialValues = (type?: IProductType) => ({
  name: type?.name || '',
  names: type?.names || {},
  specialType: type?.special_type || 'physical',
});
const TypeTitleForm: React.FC<IProps> = ({ type, typeAction, delProductType }) => {
  const intl = useIntl();
  const [typeLanguage, setLanguage] = useState(intl.locale === 'en' ? 'ru' : 'en');
  const classes1 = useStylesTypeForm();
  const {classes} = classes1
  const homeClasses = homeStyles();
  return (
    <Formik
      initialValues={getInitialValues(type)}
      enableReinitialize
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .required(intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' }))
          .nullable(),
      })}
      onSubmit={(values, { setSubmitting }) => {
        typeAction({
          name: values.name,
          specialType: values.specialType,
          names: {
            [intl.locale]: values.name,
            ...values.names,
          },
        });
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleSubmit,
        setFieldValue,
        handleChange,
        isSubmitting,
      }) => (
        <div>
          <form noValidate autoComplete='off' className='kt-form' onSubmit={handleSubmit}>
            <TextField
              select
              label={intl.formatMessage({ id: 'TYPE' })}
              margin='normal'
              name='specialType'
              value={values.specialType || 'physical'}
              variant='outlined'
              onBlur={handleBlur('specialType')}
              onChange={handleChange}
              helperText={touched.specialType && errors.specialType}
              error={Boolean(touched.specialType && errors.specialType)}
            >
              <MenuItem value='physical'>
                {intl.formatMessage({ id: 'TYPE.PHYSICAL' })}
              </MenuItem>
              <MenuItem value='digital'>{intl.formatMessage({ id: 'TYPE.DIGITAL' })}</MenuItem>
              <MenuItem value='service'>{intl.formatMessage({ id: 'TYPE.SERVICE' })}</MenuItem>
            </TextField>
            <div className={classes.containerName}>
              <div className={classes.typeNameMainLang}>
                <TextField
                  select
                  margin='normal'
                  label={intl.formatMessage({
                    id: 'MAIN.LANGUAGE',
                  })}
                  value={intl.locale}
                  variant='outlined'
                  disabled
                >
                  <MenuItem value={intl.locale}>
                    {intl.formatMessage({
                      id: `CATEGORY.${intl.locale.toUpperCase()}.LANGUAGE`,
                    })}
                  </MenuItem>
                </TextField>
                <TextField
                  type='text'
                  label={intl.formatMessage({
                    id: 'PRODUCT.TYPES.INPUT.NAME',
                  })}
                  margin='normal'
                  name={`names.${intl.locale}`}
                  value={values.names[intl.locale] || values.name || ''}
                  variant='outlined'
                  onBlur={handleBlur}
                  onChange={e => {
                    setFieldValue(`names.${intl.locale}`, e.target.value);
                    setFieldValue('name', e.target.value);
                  }}
                  helperText={touched.name && errors.name}
                  error={Boolean(touched.name && errors.name)}
                />
              </div>
              <div className={classes.typeNameLang}>
                <TextField
                  select
                  margin='normal'
                  label={intl.formatMessage({
                    id: 'AUTH.INPUT.LANG',
                  })}
                  name='language'
                  value={typeLanguage}
                  onChange={e => setLanguage(e.target.value)}
                  variant='outlined'
                >
                  {categoryLanguages
                    .filter(lang => lang.code !== intl.locale)
                    .map(lang => (
                      <MenuItem key={lang.code} value={lang.code}>
                        {intl.formatMessage({
                          id: `CATEGORY.${lang.code.toUpperCase()}.LANGUAGE`,
                        })}
                      </MenuItem>
                    ))}
                </TextField>
                <TextField
                  type='text'
                  label={intl.formatMessage({
                    id: 'PRODUCT.TYPES.INPUT.NAME',
                  })}
                  margin='normal'
                  name={`names_${typeLanguage}`}
                  value={values.names[typeLanguage] || ''}
                  variant='outlined'
                  onBlur={handleBlur}
                  onChange={e => setFieldValue(`names.${typeLanguage}`, e.target.value)}
                  helperText={touched.name && errors.name?.toString()}
                  error={Boolean(touched.name && errors.name)}
                />
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div className={homeClasses.classes.buttonContainer}>
                <ButtonWithLoader loading={isSubmitting} onPress={handleSubmit}>
                  {intl.formatMessage({ id: 'PRODUCT.TYPES.BUTTON.SAVE' })}
                </ButtonWithLoader>
              </div>
              <div className={homeClasses.classes.buttonContainer} style={{ marginLeft: 10 }}>
                {type && (
                  <RedOutlinedButton onClick={delProductType}>
                    {intl.formatMessage({ id: 'PRODUCT.TYPES.BUTTON.DEL' })}
                  </RedOutlinedButton>
                )}
              </div>
            </div>
          </form>
        </div>
      )}
    </Formik>
  );
};

export default React.memo(TypeTitleForm);
