import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import './styles.css';
import Preloader from '../../../../../components/ui/Preloader/Preloader';
import useCrudSnackbar from '../../../../../hooks/useCrudSnackbar';

export default function TariffSinglePayForm({
  clientSecret,
  payInit,
  loading,
  success,
  afterSuccess,
}) {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    payInit();
  }, []);

  const cardStyle = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#32325d',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };
  const handleChange = async event => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };

  const handleSubmit = async ev => {
    ev.preventDefault();
    setProcessing(true);
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });
    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);
    }
  };

  useCrudSnackbar({
    success: succeeded,
    error,
    successMessage: 'Success',
    errorMessage: `Error: ${error}`,
    afterSuccess: () => {
      afterSuccess();
    },
  });

  if (loading) return <Preloader />;

  return (
    success && (
      <div className='pay-container'>
        <form id='payment-form' onSubmit={handleSubmit}>
          <CardElement id='card-element' options={cardStyle} onChange={handleChange} />
          <button type='button' disabled={processing || disabled || succeeded} id='submit'>
            <span id='button-text'>
              {processing ? <div className='spinner' id='spinner' /> : 'Pay now'}
            </span>
          </button>
        </form>
      </div>
    )
  );
}
