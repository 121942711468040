import React, { useCallback } from 'react';
import { Card, CardContent, Grid, IconButton } from '@mui/material';
import { Delete, StarBorder } from '@mui/icons-material';

import { useStyles } from '../../UploadImages/hooks/useStyles';

interface IProps {
  images: File[];
  setImages: any;
  disableMain?: boolean;
}

export const ImagesGalleryOffline: React.FC<IProps> = ({ images, setImages, disableMain }) => {
  const classes = useStyles();

  const deleteImage = useCallback(
    (index: number) => {
      const imagesCopy = [...images];
      imagesCopy.splice(index, 1);
      setImages(imagesCopy);
    },
    [images]
  );

  const setMainImage = useCallback(
    (index: number) => {
      const imagesCopy = [...images];
      const removedImgs = imagesCopy.splice(index, 1);
      setImages([...removedImgs, ...imagesCopy]);
    },
    [images]
  );

  if (images.length < 2) return <></>;

  return (
    <div style={{ marginBottom: 30 }}>
      <CardContent style={{ padding: 0 }}>
        <Grid container direction='row' justifyContent='center' alignItems='center'>
          {images.map((img, index) => (
            <>
              {index !== 0 && (
                <Card className={classes.galleryImgContainer} key={index}>
                  <img
                    src={URL.createObjectURL(img)}
                    alt=''
                    style={{ height: '100%', width: '100%' }}
                  />
                  <Grid
                    container
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    className={classes.galleryImgTitleBar}
                  >
                    {setMainImage && !disableMain && (
                      <IconButton
                        style={{ color: 'white' }}
                        onClick={() => setMainImage(index)}
                      >
                        <StarBorder />
                      </IconButton>
                    )}
                    {deleteImage && (
                      <IconButton
                        style={{ color: 'white' }}
                        onClick={() => deleteImage(index)}
                      >
                        <Delete />
                      </IconButton>
                    )}
                  </Grid>
                </Card>
              )}
            </>
          ))}
        </Grid>
      </CardContent>
    </div>
  );
};
