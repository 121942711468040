import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import {
  Paper,
  Tab,
  Tabs,
  TextField,
  Button,
  IconButton,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import { DialogProps } from '@mui/material/Dialog';

import { ProductForm } from './components/ProductForm';
import Preloader from '../../../components/other/Preloader/Preloader';
import { IAppState } from '../../../store/rootDuck';
import { actions as productActions } from '../../../store/ducks/product.duck';
import { actions as categoriesActions } from '../../../store/ducks/categories.duck';
import { actions as productTypesActions } from '../../../store/ducks/productType.duck';
import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';
import useHomeStyles from '../../../constants/homeStyles';
import { actions as profileActions } from '../../../store/ducks/profile.duck';
import CompanyBind from '../companies/CompanyBind';
import { actions as companiesActions } from '../../../store/ducks/companies.duck';
import { UploadImages } from '../../../components/ui/UploadImages';
import { handleProductImages } from './hooks/handleUploadImages';
import {
  useDefineUserRole,
  useFormatMessage,
  useShowErrors,
  useShowSuccesses,
} from '../../../hooks';
import { useFormikProductForm } from './hooks/useFormikProductForm';
import { Modal } from '../../../components/other/Modals';
import CompaniesTable from '../../../components/tableComponents/Table/CompaniesTable';
import { IUser } from '../../../interfaces/user';
import { useStylesProductPage } from './hooks/useStylesProductPage';
import { useDefineProductCompany } from './hooks/useDefineProductCompany';
import { UploadImagesOffline } from '../../../components/ui/UploadImagesOffline';
import ReviewsList from './components/ReviewsList';
import UploadFilesForm from './components/UploadFilesForm';
import { IFile } from '../../../interfaces/file';
import { handleProductFiles } from './hooks/handleUploadFiles';
import { useAxiosGetCallback } from '../../../hooks/useAxiosGet';

const ProductPage: React.FC<TPropsFromRedux> = ({
  loading,
  editLoading,
  editSuccess,
  editError,
  product,
  fetch,
  fetchCategories,
  categories,
  clearEdit,
  clearProduct,
  paramsLoading,
  fetchProductTypes,
  fetchTypeParams,
  types,
  typeParams,
  me,
  loadingMe,
  fetchMe,
  clearMe,
  pageCompanies,
  perPageCompanies,
  totalCompanies,
  companies,
  loadingSearchCompanies,
  loadingCompanies,
  searchCompany,
  fetchCompanies,
  addProductId,
  addSuccess,
  clearParameters,
  clearPagination,
  fetchTypeId,
  editStockError,
  editStockSuccess,
  editStockLoading,
  byIdError,
  clearById,
}) => {
  const { id, parentId, companyIdProp, companyNameProp } = useParams();
  const parentCategoryId = parentId;

  const { enqueueSnackbar } = useSnackbar();
  const styles = useHomeStyles();
  const navigate = useNavigate();
  const fm = useFormatMessage();
  const classes1 = useStylesProductPage();
  const {classes} = classes1
  const { state, key } = useLocation();
  const intl = useIntl();

  const { data: countries, makeRequest } = useAxiosGetCallback<any>();
  const [tabValue, setTabValue] = useState(0);
  const [paramLanguage, setLanguage] = useState(intl.locale === 'en' ? 'ru' : 'en');
  const [isAllCompaniesDialogOpen, setIsAllCompaniesDialogOpen] = useState(false);
  const [searchCompanyInn, setSearchCompanyInn] = useState('');
  const [disableBack, setDisableBack] = useState(false);
  const [oneSuccess, setOneSuccess] = useState(false);
  const [files, setFiles] = useState<IFile[] | undefined>([]);
  const isManager = useDefineUserRole(me, 'ROLE_MANAGER');
  const isAdmin = useDefineUserRole(me, 'ROLE_ADMIN');

  useEffect(() => {
    if (byIdError) {
      enqueueSnackbar(`${fm('ERROR')}: ${byIdError}`, { variant: 'error' });
      navigate('/products/catalog');
    }
  }, [byIdError]);

  useEffect(() => {
    if (product) {
      setFiles(
        product?.attachments?.map(({ id, path, title, size }) => ({
          id,
          path,
          title,
          size,
        }))
      );
    }
  }, [product]);

  setLayoutSubheader({
    title: fm('PRODUCT.MANAGEMENT'),
    breadcrumb:
      !!companyIdProp || !!state?.isBack
        ? undefined
        : [
            {
              title: fm('MENU.PRODUCTS.CATALOG'),
              root: true,
              page: 'products/catalog',
              translate: 'MENU.PRODUCTS.CATALOG',
              click: () => clearPagination(),
            },
          ],
    back: !!companyIdProp || !!state?.isBack,
  });
  setLayoutFooter({ show: true });

  useEffect(() => {
    if (product) {
      if (!oneSuccess && (editStockSuccess || editSuccess)) {
        setOneSuccess(true);
      } else if (oneSuccess && (editStockSuccess || editSuccess)) {
        key ? navigate(-1) : navigate('/products/catalog');
        clearEdit();
      }
    } else if (editSuccess && !disableBack) {
      key ? navigate(-1) : navigate('/products/catalog');
    }
  }, [editStockSuccess, editSuccess]);

  useEffect(() => {
    if (editError || editStockError) {
      setOneSuccess(false);
    }
  }, [editError, editStockError]);

  useEffect(() => {
    state?.tabValue && setTabValue(state?.tabValue);
  }, [state]);

  useEffect(() => {
    if (addSuccess && addProductId) {
      navigate(`/products/edit/${addProductId}`, { state: { tabValue: 1 }, replace: true });
    }
  }, [addSuccess]);

  useEffect(() => {
    clearEdit();
    makeRequest('/api/phone_codes');
    state?.isReviews && setTabValue(2);
    fetchMe();
    fetchCategories();
    fetchProductTypes();
    clearParameters();
    return () => {
      clearMe();
      clearProduct();
      clearEdit();
      clearParameters();
      clearById();
    };
  }, []);

  useEffect(() => {
    id ? fetch({ id: Number(id) }) : clearParameters();
  }, [id]);

  // useEffect(() => {
  //   if (editSuccess && id && tabValue === 0) {
  //     clearParameters();
  //     history.goBack();
  //   } else if (addSuccess && addProductId) {
  //     if (tabValue === 1) {
  //       history.replace(`/products/edit/${addProductId}`);
  //     } else {
  //       clearParameters();
  //       history.goBack();
  //     }
  //   } else if (editSuccess || addSuccess) {
  //     setImages([]);
  //     formik.setFieldValue('files', []);
  //   }
  // }, [editSuccess, history, addSuccess]);

  const productCompany = useDefineProductCompany(me, product, companyIdProp, companyNameProp);

  // handle images when edit (UploadImages.tsx)
  const [
    imagesErr,
    uploadImages,
    deleteImage,
    setMainImage,
    // setBannerImage,
  ] = handleProductImages(product);

  const [fileErr, uploadFile, deleteFile] = handleProductFiles(product);

  // handle images when create (UploadImagesOffline.tsx)
  const [images, setImages] = useState([]);

  const {
    formik,
    setDescEditorState,
    setDescEditorStateTranslation,
    descEditorState,
    descEditorStateTranslation,
  } = useFormikProductForm(
    paramLanguage,
    fetchTypeId,
    product,
    productCompany,
    parentCategoryId,
    typeParams,
    images,
    id || '',
    categories,
    me
  );

  // const handleUploadImg = useCallback(
  //   (files: any) => {
  //     formik.setFieldValue('files', files);
  //     formik.handleSubmit();
  //   },
  //   [formik, typeParams, uploadImages]
  // );

  useEffect(() => {
    if (state?.values) {
      formik.setValues({
        ...state?.values,
        name: `${fm('СOPY.OF')} - ${state?.values?.name || ''}`,
      });
      const typeId = state?.values?.productTypeId;
      typeId && fetchTypeParams({ id: typeId });
      window.scrollTo(0, 0);
    }
  }, [state]);

  const handleChangeTabPhoto = useCallback(
    (newValue: number) => {
      setDisableBack(true);
      formik.handleSubmit();
      if (formik.values.name && formik.values.price && formik.values.categoryId) {
        setTabValue(newValue);
      }
    },
    [formik]
  );

  // fetch product type params
  useEffect(() => {
    const typeId = Number(formik.values.productTypeId);
    if (typeId) fetchTypeParams({ id: typeId });
  }, [formik.values.productTypeId]);

  // notifications
  useShowErrors([editError, imagesErr, editStockError, fileErr]);
  useShowSuccesses([
    {
      when: editSuccess,
      msg: `${fm('PRODUCT.SNACKBAR.TITLE')} ${
        id ? `${fm('PRODUCT.SNACKBAR.EDIT')}` : `${fm('PRODUCT.SNACKBAR.ADD')}`
      }`,
    },
  ]);
  if (!me || loadingMe || loading) return <Preloader />;

  if (!me.is_admin && !isManager && !me.company) {
    return <CompanyBind />;
  }

  return (
    <Paper className={styles.classes.container}>
      <Tabs
        value={tabValue}
        onChange={(_: React.ChangeEvent<{}>, newValue: number) => {
          if (newValue === 1 && !id) {
            handleChangeTabPhoto(newValue);
          } else {
            setTabValue(newValue);
          }
        }}
        indicatorColor='primary'
        textColor='primary'
      >
        <Tab label={fm('PRODUCT.TABS.COMMON')} />
        <Tab label={fm('PRODUCT.TABS.PHOTOS')} />
        <Tab label={fm('PRODUCT.TABS.FILES')} />
        {id && <Tab label={fm('PRODUCT.TABS.REVIEWS')} />}
      </Tabs>

      <div style={{ display: tabValue === 0 ? 'contents' : 'none' }}>
        {(isAdmin || isManager) && (
          <div className={classes.companySearch}>
            <TextField
              label={fm('COMPANY.SEARCH.BY_INN')}
              margin='normal'
              name='searchCompanyInn'
              placeholder=''
              value={searchCompanyInn}
              onChange={e => setSearchCompanyInn(e.target.value.replace(/[^0-9]/g, ''))}
              variant='outlined'
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      if (searchCompanyInn !== '') {
                        searchCompany({ page: 1, perPage: 20, inn: searchCompanyInn });
                        setIsAllCompaniesDialogOpen(true);
                      }
                    }}
                  >
                    <SearchIcon color='primary' fontSize='small' />
                  </IconButton>
                ),
              }}
            />
            <Button
              style={{
                marginTop: 8,
                marginLeft: 10,
                height: 52,
              }}
              variant='outlined'
              color='primary'
              onClick={() => {
                fetchCompanies({ perPage: 20, page: pageCompanies });
                setIsAllCompaniesDialogOpen(true);
              }}
            >
              {fm('COMPANY.BUTTON.ALL')}
            </Button>
          </div>
        )}
        <ProductForm
          id={id}
          formik={formik}
          description={product?.description}
          setDescEditorState={setDescEditorState}
          setDescEditorStateTranslation={setDescEditorStateTranslation}
          editLoading={editLoading || editStockLoading}
          categories={categories}
          types={types}
          typeParams={typeParams}
          paramsLoading={paramsLoading}
          me={me}
          product={product}
          countries={countries}
          paramLanguage={paramLanguage}
          setLanguage={setLanguage}
          descEditorState={descEditorState}
          descEditorStateTranslation={descEditorStateTranslation}
        />
      </div>

      <div style={{ display: tabValue === 1 ? 'contents' : 'none' }}>
        {id ? (
          <UploadImages
            images={product?.photos}
            uploadImages={uploadImages}
            withGallery
            withCrop
            cropAspect={3 / 4}
            setMainImage={setMainImage}
            deleteImage={deleteImage}
            avaliableNumberOfFilesToUpload={product?.photos ? 10 - product.photos.length : 10}
          />
        ) : (
          <UploadImagesOffline
            images={images}
            setImages={setImages}
            avaliableNumberOfFilesToUpload={10 - images.length}
            withGallery
            withCrop
          />
        )}
      </div>
      <div style={{ display: tabValue === 2 ? 'contents' : 'none' }}>
        {(isAdmin || isManager) && (
          <UploadFilesForm
            files={files}
            uploadFile={uploadFile}
            deleteFile={deleteFile}
            isEditFiles={isAdmin || isManager || me?.company?.id === product?.company?.id}
          />
        )}
      </div>

      {!!id && (
        <div style={{ display: tabValue !== 3 ? 'none' : undefined, width: '100%' }}>
          <ReviewsList productId={id} />
        </div>
      )}
      {(isAdmin || isManager) && (
        <Modal
          DialogProps={{ maxWidth: 'md' } as DialogProps}
          open={isAllCompaniesDialogOpen}
          onClose={() => setIsAllCompaniesDialogOpen(false)}
          title={fm('COMPANY.EDIT.MOUNT_TITLE')}
          loading={loadingCompanies || loadingSearchCompanies}
          content={
            <CompaniesTable
              me={me as IUser}
              page={pageCompanies}
              perPage={perPageCompanies}
              total={totalCompanies}
              onConnectUser={company => {
                formik.setFieldValue('companyId', company.id);
                formik.setFieldValue('companyName', company.name);
                setIsAllCompaniesDialogOpen(false);
              }}
              companies={companies}
              fetch={({ page, perPage }) => fetchCompanies({ page, perPage })}
            />
          }
          actions={[
            {
              title: fm('COMMON.BUTTON.CLOSE'),
              onClick: () => setIsAllCompaniesDialogOpen(false),
            },
          ]}
        />
      )}
    </Paper>
  );
};

const connector = connect(
  (state: IAppState) => ({
    me: state.profile.me,
    loadingMe: state.profile.loading,

    product: state.products.product,
    loading: state.products.byIdLoading,
    editLoading: state.products.editLoading,
    editSuccess: state.products.editSuccess,
    editError: state.products.editError,
    categories: state.categories.categories,
    typeParams: state.productTypes.typeParams,
    types: state.productTypes.productTypes,
    paramsLoading: state.productTypes.paramsLoading,

    pageCompanies: state.companies.page,
    perPageCompanies: state.companies.per_page,
    totalCompanies: state.companies.total,
    companies: state.companies.companies,
    loadingSearchCompanies: state.companies.searchLoading,
    loadingCompanies: state.companies.loading,
    addSuccess: state.products.addSuccess,
    addProductId: state.products.addProductId,
    fetchTypeId: state.productTypes.fetchTypeId,

    editStockSuccess: state.products.editStockSuccess,
    editStockError: state.products.editStockError,
    editStockLoading: state.products.editStockLoading,
    byIdError: state.products.byIdError,
  }),
  {
    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,

    fetch: productActions.fetchByIdRequest,
    add: productActions.addRequest,
    edit: productActions.editRequest,
    fetchCategories: categoriesActions.fetchFullRequest,
    clearEdit: productActions.clearEdit,
    clearProduct: productActions.clearProduct,
    fetchProductTypes: productTypesActions.fetchRequest,
    fetchTypeParams: productTypesActions.fetchParamsByIdRequest,
    clearParameters: productTypesActions.clearParams,
    clearPagination: productActions.clearPagination,

    clearById: productActions.clearById,

    searchCompany: companiesActions.searchRequest,
    fetchCompanies: companiesActions.fetchRequest,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ProductPage);
