import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { TAppActions } from '../rootDuck';
import { ActionsUnion, createAction } from '../../utils/action-helper';
import { IServerResponse } from '../../interfaces/server';
import { IProduct, IProductFilter } from '../../interfaces/product';
import { deleteProduct, getProducts, getProductFavorites } from '../../crud/product.crud';
import { getResponseMessage } from '../../utils/utils';
import { ILocation } from '../../interfaces/locations';
import { SortType } from '../../interfaces/productType';

const CLEAR_REQUEST = 'products-catalog/CLEAR_REQUEST';
const FETCH_REQUEST = 'products-catalog/FETCH_REQUEST';
const FETCH_NEWPRODUCTS_REQUEST = 'products-catalog/FETCH_NEWPRODUCTS_REQUEST';
const FETCH_NEWPRODUCTS_SUCCESS = 'products-catalog/FETCH_NEWPRODUCTS_SUCCESS';
const FETCH_NEWPRODUCTS_FAIL = 'products-catalog/FETCH_NEWPRODUCTS_FAIL';
const SET_NEWPRODUCTS_EMPTY = 'products-catalog/SET_NEWPRODUCTS_EMPTY';

const FETCH_SUCCESS = 'products-catalog/FETCH_SUCCESS';
const FETCH_FAIL = 'products-catalog/FETCH_FAIL';
const SET_EMPTY = 'products-catalog/SET_EMPTY';
const DEL_REQUEST = 'products-catalog/DEL_REQUEST';
const DEL_SUCCESS = 'products-catalog/DEL_SUCCESS';
const DEL_FAIL = 'products-catalog/DEL_FAIL';
const CLEAR_DEL = 'products-catalog/CLEAR_DEL';
const SET_FILTER = 'products-catalog/SET_FILTER';
const CLEAR_FILTER = 'products-catalog/CLEAR_FILTER';
const CLEAR_PRODUCTS = 'products-catalog/CLEAR_PRODUCTS';
const SET_VIEWTYPE = 'products-catalog/SET_VIEWTYPE';
const SET_SEARCH = 'products-catalog/SET_SEARCH';
const FETCH_PRODUCTS_FAVORITES = 'products-catalog/FETCH_PRODUCTS_FAVORITES';
const FETCH_PRODUCTS_FAVORITES_SUCCESS = 'products-catalog/FETCH_PRODUCTS_FAVORITES_SUCCESS';
const SET_PAGE = 'products-catalog/SET_PAGE';
const SET_CATEGORY_ID_ACTIVE = 'products-catalog/SET_CATEGORY_ID_ACTIVE';

export interface IInitialState {
  page: number;
  per_page: number;
  total: number;
  products: IProduct[] | undefined;
  productsNew: IProduct[] | undefined;
  productsFavorites: IProduct[] | undefined;
  loading: boolean;
  success: boolean;
  error: string | null;
  delError: string | null;
  delSuccess: boolean;
  isSearching: boolean;
  filter: IProductFilter;
  viewType: 'table' | 'grid' | 'list';
  search: string | undefined;
  sortType: string | undefined;
  categoryIdActive: number | null;
}

const defaultPaginatorProps = {
  page: 1,
  per_page: 12,
  total: 0,
};

const initialState: IInitialState = {
  ...defaultPaginatorProps,
  products: undefined,
  productsNew: undefined,
  productsFavorites: undefined,
  loading: false,
  success: false,
  error: null,
  delError: null,
  delSuccess: false,
  isSearching: false,
  search: '',
  sortType: '',
  filter: {
    price_from: 0,
    price_to: 0,
    parameters: '',
  },
  viewType: 'grid',
  categoryIdActive: null
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'products-catalog', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case SET_EMPTY: {
        return { ...state, products: [] };
      }

      case CLEAR_PRODUCTS: {
        return { ...state, products: undefined, filter: initialState.filter };
      }

      case CLEAR_REQUEST: {
        return { ...state, page: 1, per_page: 20 };
      }

      case SET_NEWPRODUCTS_EMPTY: {
        return { ...state, productsNew: [] };
      }

      case FETCH_NEWPRODUCTS_REQUEST: {
        return {
          ...state,
          productsNew: undefined,
          loading: true,
          success: false,
          error: null,
          search: action.payload.search,
          sortNewType: action.payload.sort_type,
        };
      }

      case FETCH_NEWPRODUCTS_SUCCESS: {
        return {
          ...state,
          page: action.payload.page,
          per_page: action.payload.per_page,
          total: action.payload.total,
          productsNew: action.payload.data,
          loading: false,
          success: true,
        };
      }

      case FETCH_NEWPRODUCTS_FAIL: {
        return { ...state, loading: false, error: action.payload };
      }

      case FETCH_REQUEST: {
        return {
          ...state,
          products: undefined,
          loading: true,
          success: false,
          error: null,
          search: action.payload.search,
          sortType: action.payload.sort_type || '',
        };
      }

      case FETCH_SUCCESS: {
        return {
          ...state,
          page: action.payload.page,
          per_page: action.payload.per_page,
          total: action.payload.total,
          products: action.payload.data,
          loading: false,
          success: true,
        };
      }

      case SET_CATEGORY_ID_ACTIVE: {
        
        return {
          ...state,
          categoryIdActive: action.payload,
        };
      }

      case SET_FILTER: {
        const filter = {
          ...state.filter,
          ...action.payload,
        };

        return {
          ...state,
          ...defaultPaginatorProps,
          filter,
        };
      }

      case CLEAR_FILTER: {
        return {
          ...state,
          filter: initialState.filter,
        };
      }

      case FETCH_FAIL: {
        return { ...state, loading: false, error: action.payload };
      }

      case DEL_FAIL: {
        return { ...state, delError: action.payload, delSuccess: false };
      }

      case DEL_SUCCESS: {
        return { ...state, delError: null, delSuccess: true };
      }

      case CLEAR_DEL: {
        return { ...state, delError: null, delSuccess: false };
      }

      case SET_VIEWTYPE: {
        return { ...state, viewType: action.payload };
      }

      case SET_SEARCH: {
        return { ...state, isSearching: action.payload };
      }

      case FETCH_PRODUCTS_FAVORITES_SUCCESS: {
        return { ...state, productsFavorites: action.payload.data };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  clearRequest: () => createAction(CLEAR_REQUEST),
  fetchRequest: (payload: {
    page: number;
    perPage: number;
    filter: IProductFilter;
    search?: string;
    categoryId?: number;
    sort_type?: string;
    locale?: ILocation | null ;
    activity?: string;
    sortType?: SortType;
  }) => {
    return createAction(FETCH_REQUEST, payload);
  },
  fetchNewProductsRequest: (payload: {
    page: number;
    perPage: number;
    filter: IProductFilter;
    search?: string;
    categoryId?: number;
    sort_type?: string;
  }) => {
    return createAction(FETCH_NEWPRODUCTS_REQUEST, payload);
  },
  fetchNewProductsSuccess: (payload: IServerResponse<IProduct[]>) =>
    createAction(FETCH_NEWPRODUCTS_SUCCESS, payload),
  fetchNewProductsFail: (payload: string) => createAction(FETCH_NEWPRODUCTS_FAIL, payload),
  fetchSuccess: (payload: IServerResponse<IProduct[]>) => createAction(FETCH_SUCCESS, payload),
  fetchFail: (payload: string) => createAction(FETCH_FAIL, payload),
  setEmpty: () => createAction(SET_EMPTY),
  setNewProductsEmpty: () => createAction(SET_NEWPRODUCTS_EMPTY),
  clearProducts: () => createAction(CLEAR_PRODUCTS),
  setFilter: (payload: Partial<IProductFilter>) => createAction(SET_FILTER, payload),
  clearFilter: () => createAction(CLEAR_FILTER),
  delRequest: (payload: {
    id: number | undefined;
    page: number;
    perPage: number;
    filter: IProductFilter;
    categoryId?: number;
  }) => createAction(DEL_REQUEST, payload),
  delFail: (payload: string) => createAction(DEL_FAIL, payload),
  delSuccess: () => createAction(DEL_SUCCESS),
  clearDel: () => createAction(CLEAR_DEL),
  setViewType: (payload: 'table' | 'grid' | 'list') => createAction(SET_VIEWTYPE, payload),
  setSearch: (payload: boolean) => createAction(SET_SEARCH, payload),
  fetchProductsFavorites: () => createAction(FETCH_PRODUCTS_FAVORITES),
  fetchProductsFavoritesSuccess: (payload: IServerResponse<IProduct[]>) =>
    createAction(FETCH_PRODUCTS_FAVORITES_SUCCESS, payload),
  setPage: (payload: {
    page: number;
    perPage: number;
  }) => createAction(SET_PAGE, payload),
  setCategoryIdActive: (payload: number) => createAction(SET_CATEGORY_ID_ACTIVE, payload),
};

export type TActions = ActionsUnion<typeof actions>;

function* fetchSaga({
  payload,
}: {
  payload: {
    page: number;
    perPage: number;
    filter: IProductFilter;
    search?: string;
    categoryId?: number;
    locale?: ILocation | null;
    activity?: string;
    sortType?: SortType;
  };
}) {
  try {
    if (typeof payload.categoryId === 'number') {
      yield put(actions.setCategoryIdActive(payload.categoryId));
    }
    const { data }: { data: IServerResponse<IProduct[]> } = yield call(() =>
      getProducts(payload)
    );
    yield put(actions.fetchSuccess(data));
  } catch (e) {
    yield put(actions.fetchFail(getResponseMessage(e)));
  }
}

function* fetchProductsFavoritesSaga() {
  try {
    const { data }: { data: IServerResponse<IProduct[]> } = yield call(() =>
      getProductFavorites()
    );
    yield put(actions.fetchProductsFavoritesSuccess(data));
  } catch (e) {
    yield put(actions.fetchFail(getResponseMessage(e)));
  }
}


function* fetchNewProductsSaga({
  payload,
}: {
  payload: {
    page: number;
    perPage: number;
    filter: IProductFilter;
    search?: string;
    categoryId?: number;
  };
}) {
  try {
    const { data }: { data: IServerResponse<IProduct[]> } = yield call(() =>
      getProducts(payload)
    );
    yield put(actions.fetchNewProductsSuccess(data));
  } catch (e) {
    yield put(actions.fetchNewProductsFail(getResponseMessage(e)));
  }
}

function* delSaga({ payload }: any) {
  try {
    yield call(() => deleteProduct(payload.id));
    yield put(actions.delSuccess());
    yield put(
      actions.fetchRequest({
        page: payload.page,
        perPage: payload.perPage,
        filter: payload.filter,
        categoryId: payload.categoryId,
      })
    );
  } catch (e) {
    yield put(actions.delFail(getResponseMessage(e)));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.fetchRequest>>(FETCH_REQUEST, fetchSaga);
  yield takeLatest<ReturnType<typeof actions.delRequest>>(DEL_REQUEST, delSaga);
  yield takeLatest<ReturnType<typeof actions.fetchNewProductsRequest>>(
    FETCH_NEWPRODUCTS_REQUEST,
    fetchNewProductsSaga
  );
  yield takeLatest<ReturnType<typeof actions.fetchProductsFavorites>>(
    FETCH_PRODUCTS_FAVORITES,
    fetchProductsFavoritesSaga
  );
}
