import React, { CSSProperties } from 'react';
import {
  Chip,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Theme,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { makeStyles } from '@mui/styles';

type TProps = {
  label: string;
  options: { value: string; label: string }[];
  selectedValues: TProps['options'][0]['value'][];
  setSelectedValues: (values: TProps['selectedValues']) => void;
  emptyValueLabel?: TProps['options'][0]['label'];
  error?: boolean;
  helperText?: string;
  styleField?: CSSProperties;
  styleChip?: CSSProperties;
  disabled?: boolean;
};

const MultiSelect: React.FC<TProps> = ({
  options,
  label,
  emptyValueLabel,
  selectedValues,
  setSelectedValues,
  error,
  helperText,
  styleField,
  styleChip,
  disabled,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root} style={styleField}>
      <div className={classes.header}>
        <InputLabel shrink>{label}</InputLabel>
        {selectedValues.length > 0 && (
          <div className={classes.clearIcon}>
            <IconButton
              disabled={disabled}
              aria-label='delete'
              size='small'
              onClick={() => {
                setSelectedValues([]);
              }}
            >
              <ClearIcon fontSize='inherit' />
            </IconButton>
          </div>
        )}
      </div>
      <FormControl className={classes.formControl} variant='outlined'>
        <Select
          multiple
          value={selectedValues}
          onChange={(e: any) => {
            const values = e.target.value as string[];
            setSelectedValues(values);
          }}
          displayEmpty
          disabled={disabled}
          error={error}
          renderValue={(values: any) => {
            if (values.length > 0) {
              return (
                <div className={classes.chips}>
                  {values.map((el: string) => (
                    <Chip
                      key={el}
                      label={options.find(o => o.value === el)?.label}
                      className={classes.chip}
                      size='small'
                      style={styleChip}
                    />
                  ))}
                </div>
              );
            }
            return emptyValueLabel;
          }}
        >
          {options.map(el => (
            <MenuItem key={el.value} value={el.value}>
              {el.label}
            </MenuItem>
          ))}
        </Select>
        {helperText && <FormHelperText error>{helperText}</FormHelperText>}
      </FormControl>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 15,
    marginBottom: 8,
    '&:first-child': {
      marginTop: 0,
    },
  },
  formControl: {
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    borderRadius: 4,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  clearIcon: {
    marginTop: -4,
  },
}));

export default MultiSelect;
