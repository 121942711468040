import React, { useRef } from 'react';
import { connect } from 'react-redux';
import * as builder from '../../ducks/builder';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Menu from './Menu';

const AsideLeft = (props: any) => {
  const asideOffCanvasRef: any = useRef();
  return (
    <>
      <button className='kt-aside-close' id='kt_aside_close_btn'>
        <i className='la la-close' />
      </button>
      <div
        id='kt_aside'
        ref={asideOffCanvasRef}
        className={`kt-aside ${props.asideClassesFromConfig} kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop`}
      >
        <div
          id='kt_aside_menu_wrapper'
          className='kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid'
        >
          {props.disableScroll && <Menu />}
          {!props.disableScroll && (
            <PerfectScrollbar options={{ wheelSpeed: 2, wheelPropagation: false }}>
              <Menu />
            </PerfectScrollbar>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (store: any) => ({
  disableAsideSelfDisplay: builder.selectors.getConfig(store, 'aside.self.display') === false,
  disableScroll: builder.selectors.getConfig(store, 'aside.menu.dropdown') === 'true' || false,
  asideClassesFromConfig: builder.selectors.getClasses(store, {
    path: 'aside',
    toString: true,
  }),
  menuCanvasOptions: {
    baseClass: 'kt-aside',
    overlay: true,
    closeBy: 'kt_aside_close_btn',
    toggleBy: {
      target: 'kt_aside_mobile_toggler',
      state: 'kt-header-mobile__toolbar-toggler--active',
    },
  },
});

export default connect(mapStateToProps)(AsideLeft);
