import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Tooltip,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/MenuOpen';
import ProductListIcon from '@mui/icons-material/FormatListNumbered';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';

import { useIntl } from 'react-intl';

import { ICategoryItem, ICategoryTableTooltip, ICategoryTreeTable } from '../interfaces';

export const useTreeItemStyles = makeStyles()((theme)=>{
  return {
    label: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      paddingBottom: theme.spacing(0.7),
      paddingTop: theme.spacing(0.7),
    },
    labelText: {
      fontWeight: 'inherit',
      flexGrow: 1,
    },
    labelIcon: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    icon: {
      width: 22,
      height: 22,
    },
    iconDelete: {
      width: 22,
      height: 22,
    },
    fontNoLang: {
      flexGrow: 1,
      fontWeight: 200,
    },
}});

const treeNode = (
  item: ICategoryItem,
  classes: any,
  handleDeleteDialog: any,
  editAction: any,
  pushAction: any,
  tooltip: ICategoryTableTooltip,
  productListAction: any,
  selectItemAction: any,
  is_admin: boolean,
  is_manager: boolean,
  lang: string,
  viewAction: any
) => {
  // @ts-ignore
  const nameLang = lang === 'ru' ? item.name : item.names[`name_${lang}`];

  return (
    <div className={classes.labelRoot} onClick={() => selectItemAction(item.id)}>
      <Typography
        variant='body2'
        className={!nameLang ? classes.fontNoLang : classes.labelText}
      >
        {nameLang || item.name}
      </Typography>
      <div style={{ display: 'flex', height: 30 }}>
        <Tooltip title={tooltip.view}>
          <IconButton onClick={() => viewAction(item)}>
            <VisibilityIcon color='primary' />
          </IconButton>
        </Tooltip>
        {(!Array.isArray(item.children) || item.children.length === 0) && (
          <Tooltip title={tooltip.productList}>
            <IconButton
              onClick={() => productListAction(item)}
              size='small'
              className={classes.labelIcon}
            >
              <ProductListIcon color='primary' fontSize='small' />
            </IconButton>
          </Tooltip>
        )}
        {is_admin && (
          <>
            {!is_manager && (
              <Tooltip title={tooltip.add}>
                <IconButton
                  onClick={() => pushAction(item.id)}
                  size='small'
                  className={classes.labelIcon}
                >
                  <AddIcon color='primary' fontSize='small' />
                </IconButton>
              </Tooltip>
            )}
            {/* <Tooltip title={tooltip.edit}> */}
            <IconButton
              onClick={() => editAction(item)}
              size='small'
              className={classes.labelIcon}
            >
              <EditIcon color='primary' fontSize='small' />
            </IconButton>
            {/* </Tooltip> */}
            {/* <Tooltip title={tooltip.delete}> */}
            {!is_manager && (
              <IconButton
                onClick={() => {
                  handleDeleteDialog(item.id);
                }}
                size='small'
                className={classes.labelIcon}
              >
                <DeleteIcon color='error' fontSize='small' />
              </IconButton>
            )}
            {/* </Tooltip> */}
          </>
        )}
      </div>
    </div>
  );
};

const renderTree = (
  nodes: ICategoryItem,
  classes: any,
  handleDeleteDialog: any,
  editAction: any,
  pushAction: any,
  tooltip: ICategoryTableTooltip,
  productListAction: any,
  selectItemAction: any,
  is_admin: boolean,
  is_manager: boolean,
  lang: string,
  viewAction: any
) => (
  <TreeItem
    key={nodes.id}
    nodeId={nodes.id?.toString() || 'key'}
    label={treeNode(
      nodes,
      classes,
      handleDeleteDialog,
      editAction,
      pushAction,
      tooltip,
      productListAction,
      selectItemAction,
      is_admin,
      is_manager,
      lang,
      viewAction
    )}
  >
    {Array.isArray(nodes.children)
      ? nodes.children.map(node =>
          renderTree(
            node,
            classes,
            handleDeleteDialog,
            editAction,
            pushAction,
            tooltip,
            productListAction,
            selectItemAction,
            is_admin,
            is_manager,
            lang,
            viewAction
          )
        )
      : null}
  </TreeItem>
);

const CategoriesTree: React.FC<ICategoryTreeTable> = ({
  categories,
  handleDeleteDialog,
  editAction,
  pushAction,
  productListAction,
  selectProductId,
  classes,
  is_admin,
  is_manager,
  lang,
  viewAction,
}) => {
  const innerClasses = useTreeItemStyles();
  const intl = useIntl();
  const tooltip: ICategoryTableTooltip = {
    productList: intl.formatMessage({ id: 'CATEGORIES.TABLE.TOOLTIP.PRODUCTS' }),
    edit: intl.formatMessage({ id: 'CATEGORIES.TABLE.TOOLTIP.EDIT' }),
    add: intl.formatMessage({ id: 'CATEGORIES.TABLE.TOOLTIP.ADD' }),
    delete: intl.formatMessage({ id: 'CATEGORIES.TABLE.TOOLTIP.DELETE' }),
    view: intl.formatMessage({ id: 'CATEGORY.VIEW' }),
  };
  return (
    <Card className={classes.card}>
      <CardContent>
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpanded={[]}
          defaultExpandIcon={<ChevronRightIcon />}
        >
          {categories.map(item =>
            renderTree(
              item,
              innerClasses.classes,
              handleDeleteDialog,
              editAction,
              pushAction,
              tooltip,
              productListAction,
              selectProductId,
              is_admin,
              is_manager,
              lang,
              viewAction
            )
          )}
        </TreeView>
      </CardContent>
    </Card>
  );
};

export default CategoriesTree;
