import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { deleteEnumValue } from '../../../../crud/product.crud';
import { getResponseMessage } from '../../../../utils/utils';
import { useFormatMessage } from '../../../../hooks';

export const useDeleteEnumValue = (successFunc?: () => void) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loadingDelete, setLoading] = useState(false);
  const [successDelete, setSuccess] = useState(false);
  const fm = useFormatMessage();

  const fetchDelete = useCallback(
    async (parameter_id: number) => {
      setLoading(true);
      deleteEnumValue(parameter_id)
        .then(() => {
          enqueueSnackbar(fm('PRODUCT.TYPES.PARAMETER.VALUE.DELETE'), { variant: 'success' });
          successFunc && successFunc();
          setSuccess(true);
        })
        .catch(e => {
          enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`, { variant: 'error' });
        })
        .finally(() => {
          setSuccess(false);
          setLoading(false);
        });
    },
    [successFunc]
  );

  return { fetchDelete, loadingDelete, successDelete };
};
