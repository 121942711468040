import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { put, takeLatest, call } from 'redux-saga/effects';
import { IRequest, IRequestData, IRequestResponse } from '../../interfaces/request';

import { TAppActions } from '../rootDuck';

import { ActionsUnion, createAction } from '../../utils/action-helper';
import { 
  getRequests, 
  editRequest, 
  createRequest, 
  editStatus, 
  getRequestById,
  deleteRequest,
} from '../../crud/request';
import { IServerResponse } from '../../interfaces/server';
import { getResponseMessage } from '../../utils/utils';

const FETCH_REQUEST = 'request/FETCH_REQUEST';
const FETCH_SUCCESS = 'request/FETCH_SUCCESS';
const FETCH_FAIL = 'request/FETCH_FAIL';

const CLEAR_EDIT = 'request/CLEAR_EDIT';
const EDIT_REQUEST = 'request/EDIT_REQUEST';
const EDIT_SUCCESS = 'request/EDIT_SUCCESS';
const EDIT_FAIL = 'request/EDIT_FAIL';

const CREATE_REQUEST = 'request/CREATE_REQUEST';
const SUCCESS_REQUEST = 'request/SUCCESS_REQUEST';
const FAIL_REQUEST = 'request/FAIL_REQUEST';

const EDIT_STATUS_REQUEST = 'request/EDIT_STATUS_REQUEST';
const EDIT_STATUS_SUCCESS = 'request/EDIT_STATUS_SUCCESS';
const EDIT_STATUS_FAIL = 'request/EDIT_STATUS_FAIL';

const FETCH_BY_ID_REQUEST = 'request/FETCH_BY_ID_REQUEST';
const FETCH_BY_ID_SUCCESS = 'request/FETCH_BY_ID_SUCCESS';
const FETCH_BY_ID_FAIL = 'request/FETCH_BY_ID_FAIL';

const DELETE_REQUEST = 'request/DELETE_REQUEST';
const DELETE_SUCCESS = 'request/DELETE_SUCCESS';
const DELETE_FAIL = 'request/DELETE_FAIL';

export interface IInitialState {
  page: number;
  per_page: number;
  total: number;
  requests: IRequest[] | undefined;
  request: IRequest | undefined;

  loading: boolean;
  success: boolean;
  error: string | null;

  editLoading: boolean;
  editSuccess: boolean;
  editError: string | null;

  createLoading: boolean;
  createSuccess: boolean;
  createError: string | null;

  editStatusLoading: boolean;
  editStatusSuccess: boolean;
  editStatusError: string | null;

  loadingById: boolean;
  successById: boolean;
  errorById: string | null;

  delError: string | null;
}

const initialState: IInitialState = {
  page: 1,
  per_page: 20,
  total: 0,
  requests: undefined,
  loading: false,
  success: false,
  error: null,

  request: undefined,

  editLoading: false,
  editSuccess: false,
  editError: null,

  createLoading: false,
  createSuccess: false,
  createError: null,

  editStatusLoading: false,
  editStatusSuccess: false,
  editStatusError: null,

  loadingById: false,
  successById: false,
  errorById: null,

  delError: null,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'models', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case FETCH_REQUEST: {
        return {
          ...state,
          requests: undefined,
          loading: true,
          success: false,
          error: null,
        };
      }

      case FETCH_SUCCESS: {
        return {
          ...state,
          page: action.payload.page,
          per_page: action.payload.per_page,
          total: action.payload.total,
          requests: action.payload.data,
          loading: false,
          success: true,
        };
      }

      case FETCH_FAIL: {
        return { ...state, loading: false, error: action.payload };
      }

      case CLEAR_EDIT: {
        return {
          ...state,
          request: undefined,
          editLoading: false,
          editSuccess: false,
          editError: null,
        };
      }

      case EDIT_REQUEST: {
        return { ...state, editLoading: true, editSuccess: false, editError: null };
      }

      case EDIT_SUCCESS: {
        return { ...state, editLoading: false, editSuccess: true };
      }

      case EDIT_FAIL: {
        return { ...state, editLoading: false, editError: action.payload };
      }

      case CREATE_REQUEST: {
        return { ...state, createLoading: true, createSuccess: false, createError: null };
      }

      case SUCCESS_REQUEST: {
        return {
          ...state,
          createLoading: false,
          createSuccess: true,
          requests: state.requests ? [...state.requests, action.payload] : [action.payload],
        };
      }

      case FAIL_REQUEST: {
        return { ...state, createLoading: false, createError: action.payload };
      }

      case EDIT_STATUS_REQUEST: {
        return { ...state, editStatusLoading: true, editStatusSuccess: false, editStatusError: null };
      }

      case EDIT_STATUS_SUCCESS: {
        const updatedRequests = state.requests?.map(req =>
          req.id === action.payload.data.id ? action.payload.data : req
        );
      
        return {
          ...state,
          editStatusLoading: false,
          editStatusSuccess: true,
          requests: updatedRequests,
        };
      }

      case EDIT_STATUS_FAIL: {
        return { ...state, editStatusLoading: false, editStatusError: action.payload };
      }

      case FETCH_BY_ID_REQUEST: {
        return {
          ...state,
          loadingById: true,
          successById: false,
          errorById: null,
        };
      }

      case FETCH_BY_ID_SUCCESS: {        
        return {
          ...state,
          request: action.payload.data,
          loadingById: false,
          byIdSuccess: true,
        };
      }

      case FETCH_BY_ID_FAIL: {
        return { ...state, loadingById: false, errorById: action.payload };
      }

      case DELETE_FAIL: {
        return { ...state, delError: action.payload };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  fetchRequest: (payload: {
    page: number;
    perPage: number;
    categories?: string;
    user_id?: string | number;
  }) => createAction(FETCH_REQUEST, payload),
  fetchSuccess: (payload: IServerResponse<IRequest[]>) => createAction(FETCH_SUCCESS, payload),
  fetchFail: (payload: string) => createAction(FETCH_FAIL, payload),

  clearEdit: () => createAction(CLEAR_EDIT),
  editRequest: (payload: { id: number; data: any }) => createAction(EDIT_REQUEST, payload),
  editSuccess: () => createAction(EDIT_SUCCESS),
  editFail: (payload: string) => createAction(EDIT_FAIL, payload),

  addRequest: (payload: { data: FormData ,params: IRequestData }) => createAction(CREATE_REQUEST, payload),
  addSuccess: (payload: IRequest) => createAction(SUCCESS_REQUEST, payload),
  addFail: (payload: string) => createAction(FAIL_REQUEST, payload),

  editStatusRequest: (payload: { id: number; status: string }) => createAction(EDIT_STATUS_REQUEST, payload),
  editStatusSuccess: (payload: IServerResponse<IRequest>) => createAction(EDIT_STATUS_SUCCESS, payload),
  editStatusFail: (payload: string) => createAction(EDIT_STATUS_FAIL, payload),

  fetchByIdRequest: (payload: number) => createAction(FETCH_BY_ID_REQUEST, payload),
  fetchByIdSuccess: (payload: IServerResponse<IRequest>) =>
    createAction(FETCH_BY_ID_SUCCESS, payload),
  fetchByIdFail: (payload: string) => createAction(FETCH_BY_ID_FAIL, payload),

  delRequest: (payload: { 
    id: number;
    page: number;
    perPage: number;
    statuses?: string;
    categories?: string;
  }) => createAction(DELETE_REQUEST, payload),
  delSuccess: () => createAction(DELETE_SUCCESS),
  delFail: (payload: string) => createAction(DELETE_FAIL, payload),
};

export type TActions = ActionsUnion<typeof actions>;

function* fetchSaga({
  payload,
}: {
  payload: {
    page: number;
    perPage: number;
    statuses?: string;
    categories?: string;
  };
}) {
  try {
    const { data }: { data: IServerResponse<IRequest[]> } = yield call(() =>
      getRequests(payload.page, payload.perPage, payload.statuses, payload.categories)
    );
    yield put(actions.fetchSuccess(data));
  } catch (e) {
    yield put(actions.fetchFail(getResponseMessage(e)));
  }
}

function* editSaga({ payload: { id, data } }: { payload: { id: number; data: IRequestResponse } }) {
  try {
    yield call(() => editRequest(id, data));
    yield put(actions.editSuccess());
  } catch (e) {
    yield put(actions.editFail(getResponseMessage(e)));
  }
}

function* editStatusSaga({ payload: { id, status } }: { payload: { id: number; status: string } }) {
  try {
    const { data }: { data: IServerResponse<IRequest> } = yield call(() => editStatus(id, status));
    yield put(actions.editStatusSuccess(data));
  } catch (e) {
    yield put(actions.editStatusFail(getResponseMessage(e)));
  }
}

function* addSaga({ payload }: { payload: { data: FormData, params: IRequestData } }) {
  try {
    const { data }: { data: IServerResponse<IRequest> } = yield call(() =>
      createRequest(payload.data, payload.params)
    );
    yield put(actions.addSuccess(data.data));
  } catch (e) {
    yield put(actions.addFail(getResponseMessage(e)));
  }
}

function* fetchSagaById({ payload }: { payload: number }) {
  try {
    const { data }: { data: IServerResponse<IRequest> } = yield call(() =>
      getRequestById(payload)
    );

    yield put(actions.fetchByIdSuccess(data));
  } catch (e) {
    yield put(actions.fetchByIdFail(getResponseMessage(e)));
  }
}

function* delSaga({
  payload,
}: {
  payload: {
    id: number;
    page: number;
    perPage: number;
    statuses?: string;
    categories?: string;
  };
}) {
  try {
    yield call(() => deleteRequest(payload.id));
    yield put(
      actions.fetchRequest({
        page: payload.page,
        perPage: payload.perPage,
      })
    );
  } catch (e) {
    yield put(actions.delFail(getResponseMessage(e)));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.fetchRequest>>(FETCH_REQUEST, fetchSaga);
  yield takeLatest<ReturnType<typeof actions.fetchByIdRequest>>(FETCH_BY_ID_REQUEST, fetchSagaById);
  yield takeLatest<ReturnType<typeof actions.editRequest>>(EDIT_REQUEST, editSaga);
  yield takeLatest<ReturnType<typeof actions.editStatusRequest>>(EDIT_STATUS_REQUEST, editStatusSaga);
  yield takeLatest<ReturnType<typeof actions.addRequest>>(CREATE_REQUEST, addSaga);
  yield takeLatest<ReturnType<typeof actions.delRequest>>(DELETE_REQUEST, delSaga);
}
