import axios from 'axios';
import {
  createPublicationUrl,
  getPublicationUrl,
  deletePublicationUrl,
  getAllPublicationsUrl,
  getMyPublicationsUrl,
  createPublicationLogoUrl,
  editPublicationUrl,
  getFavoritePublicationsUrl,
  getStatusPublicationsUrl,
} from '../constants';
import { ICreatePublication, IEditPublication } from '../pages/home/blog/interfaces';

export function createPublication(data: ICreatePublication) {
  return axios.post(createPublicationUrl(), data);
}
export function editPublication(id: number, data: IEditPublication) {
  return axios.put(editPublicationUrl(id), data);
}
export function getPublication(id: number) {
  return axios.get(getPublicationUrl(id));
}
export function deletePublication(id: number) {
  return axios.delete(deletePublicationUrl(id));
}
export function getPublicationsUrl(data: {
  page: number;
  perPage: number;
  my?: boolean;
  favorite?: boolean;
  status?: string;
}) {
  if (data.my) {
    return axios.get(getMyPublicationsUrl(data));
  }

  if (data.favorite) {
    return axios.get(getFavoritePublicationsUrl(data));
  }

  return axios.get(getAllPublicationsUrl(data));
}
export function getAllPublications(data: { page: number; perPage: number }) {
  return axios.get(getAllPublicationsUrl(data));
}
export function getMyPublications(data: { page: number; perPage: number }) {
  return axios.get(getMyPublicationsUrl(data));
}
export function createPublicationLogo(id: number, file: FormData) {
  return axios.post(createPublicationLogoUrl(id), file);
}

export const editPostsStatus = (id: number, status: string) =>
  axios.put(`/api/publication/${id}`, {}, { params: { status } });

export function getAprowedPublications(data: {
  page: number;
  perPage: number;
  status: string;
}) {
  return axios.get(getStatusPublicationsUrl(data));
}
