import React from 'react';
import { useIntl } from 'react-intl';
import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';
import RequestsItem from './components/RequestsItem';

const OrderListPage: React.FC = () => {
  const intl = useIntl();
  setLayoutSubheader({
    title: intl.formatMessage({ id: 'REQUEST.TITLE' }),
  });
  setLayoutFooter({ show: true });

  return <RequestsItem type='abandoned' />;
};

export default OrderListPage;
