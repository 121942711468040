import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, IconButton, Typography } from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import { useSnackbar } from 'notistack';
import GridOnIcon from '@mui/icons-material/GridOn';
import ListIcon from '@mui/icons-material/List';
import { Helmet } from 'react-helmet';

import clsx from 'clsx';
import AlertDialog from '../../../components/ui/Dialog/AlertDialog';
import Preloader from '../../../components/ui/Preloader/Preloader';

import { IAppState } from '../../../store/rootDuck';
import { actions as productCatalogActions } from '../../../store/ducks/product-catalog.duck';
import { API_DOMAIN } from '../../../constants';
import { setLayoutSubheader } from '../../../utils/layout';
import { actions as categoriesActions } from '../../../store/ducks/categories.duck';
import { actions as productTypesActions } from '../../../store/ducks/productType.duck';
import { actions as profileActions } from '../../../store/ducks/profile.duck';
import { actions as cartActions } from '../../../store/ducks/cart.duck';
import useCrudSnackbar from '../../../hooks/useCrudSnackbar';
import { useStylesCategoryView } from './hooks/useStyles';
import { toAbsoluteUrl } from '../../../../_base';
import FilterCategoriesTree from '../products/components/FilterCategoriesTree';
import ProductsTableListComponent from '../products/components/ProductsTableList';
import { ProductsGrid } from '../products/components';
import { useDefineUserRole, useFormatMessage } from '../../../hooks';
import { IUser } from '../../../interfaces/user';

const CategoryView: React.FC = () => {
  const classes1 = useStylesCategoryView();
  const { classes } = classes1;
  const fm = useFormatMessage();
  const navigate = useNavigate();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [deleteId, setDeleteId] = useState<number | undefined>(-1);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(id ? +id : null);

  const {
    productsCount,
    filter,
    viewType,
    products,
    loading,
    page,
    perPage,
    total,
    deleteError,
  } = useSelector((store: IAppState) => ({
    productsCount: store.productsCatalog.total,
    filter: store.productsCatalog.filter,
    viewType: store.productsCatalog.viewType,
    products: store.productsCatalog.products,
    loading: store.productsCatalog.loading,
    page: store.productsCatalog.page,
    perPage: store.productsCatalog.per_page,
    total: store.productsCatalog.total,
    deleteError: store.productsCatalog.delError,
  }));

  const { category, catalogCategories } = useSelector((store: IAppState) => ({
    category: store.categories.category,
    loadingById: store.categories.byIdLoading,
    catalogCategories: store.categories.catalogCategories,
  }));

  const { addProductLoading, addProductSuccess, addProductError } = useSelector(
    (store: IAppState) => ({
      addProductLoading: store.cart.addProductLoading,
      addProductSuccess: store.cart.addProductSuccess,
      addProductError: store.cart.addProductError,
    })
  );

  const { isAdmin, isAuthorized, user } = useSelector((store: IAppState) => ({
    isAdmin: store.auth.user?.is_admin,
    user: store.auth.user,
    isAuthorized: store.auth.user != null,
  }));

  const isVendor = useDefineUserRole(user as unknown as IUser, [
    'ROLE_VENDOR',
    'ROLE_VENDOR_STAFF',
  ]);

  const { me, loadingMe } = useSelector((store: IAppState) => ({
    me: store.profile.me,
    loadingMe: store.profile.loading,
  }));

  useLayoutEffect(() => {
    id && setSelectedCategoryId(+id);
    dispatch(profileActions.fetchRequest());
    dispatch(categoriesActions.fetchCatalogCategories({}));
    dispatch(productTypesActions.clearParams());
    return () => {
      dispatch(profileActions.clearMe());
      dispatch(productCatalogActions.clearProducts());
    };
  }, []);

  useEffect(() => {
    if (selectedCategoryId) {
      dispatch(categoriesActions.fetchByIdRequest(selectedCategoryId || 0));
      dispatch(
        productCatalogActions.fetchRequest({
          page,
          perPage,
          filter: {},
          categoryId: selectedCategoryId || 0,
        })
      );
    }
  }, [selectedCategoryId]);

  useEffect(() => {
    if (deleteError) {
      enqueueSnackbar(deleteError, { variant: 'error' });
      setAlertOpen(false);
      dispatch(productCatalogActions.clearDel());
    }
    return () => {
      dispatch(productCatalogActions.clearDel());
    };
  }, [deleteError, enqueueSnackbar]);

  useCrudSnackbar({
    success: addProductSuccess,
    error: addProductError,
    successMessage: fm('CART.ADD_PRODUCT.SUCCESS'),
    errorMessage: `${fm('ERROR')}: ${addProductError}`,
  });

  setLayoutSubheader({
    title: fm('CATEGORY.VIEW'),
    back: true,
  });

  const handleDeleteDialog = useCallback((id: number | undefined) => {
    setDeleteId(id);
    setAlertOpen(true);
  }, []);

  const deleteAction = useCallback(() => {
    setAlertOpen(false);
    deleteId &&
      dispatch(
        productCatalogActions.delRequest({
          // @ts-ignore
          id: deleteId?.id,
          page,
          perPage,
          filter: {},
          categoryId: selectedCategoryId || 0,
        })
      );
  }, [deleteId, perPage, page, selectedCategoryId]);

  const editAction = useCallback(
    (item: any) => {
      navigate(`/products/edit/${item.id}`, { state: { isBack: true } });
    },
    [navigate]
  );

  const viewAction = useCallback(
    (item: any) => {
      navigate(`/viewproduct/${item.id}`);
    },
    [navigate]
  );

  const handleCartDialog = useCallback((item: any) => {
    if (isAuthorized) {
      dispatch(
        cartActions.addProductRequest({
          product_id: item.id ? item?.id : 0,
          count: 1,
        })
      );
    } else {
      dispatch(
        cartActions.setProductGuestCart({
          data: item,
          type: 'cart',
        })
      );
    }
  }, []);

  const resetProducts = useCallback(() => {
    const categoryId = id ? +id : 0;
    dispatch(categoriesActions.fetchByIdRequest(categoryId));
    dispatch(
      productCatalogActions.fetchRequest({
        page: 1,
        perPage: 12,
        filter: {},
        categoryId,
      })
    );
    setSelectedCategoryId(categoryId || null);
  }, [selectedCategoryId, id]);

  if (loadingMe) return <Preloader />;

  return (
    <>
      <Helmet>
        <title>{category?.name || ''}</title>
      </Helmet>
      <AlertDialog
        open={isAlertOpen}
        message={fm('PRODUCT.DELETE.TEXT')}
        okText={fm('CATEGORIES.DELETE.OK')}
        cancelText={fm('CATEGORIES.DELETE.CANCEL')}
        handleClose={() => {
          dispatch(productCatalogActions.clearDel());
          setAlertOpen(false);
        }}
        handleAgree={() => deleteAction()}
      />

      <Row>
        <Col>
          <div className={classes.category}>
            <Card className={classes.imgContainer} elevation={0}>
              <img
                src={
                  category?.avatar
                    ? // @ts-ignore
                      `${API_DOMAIN}/${category.avatar.small}`
                    : toAbsoluteUrl('/images/placeholder.png')
                }
                className={classes.imgCom}
                alt=''
              />
            </Card>

            <div className={classes.containterCategory}>
              <p className={classes.name}>
                <b>{category?.name}</b>
              </p>
              {category?.description && (
                <p className={classes.listKey}>{category?.description}</p>
              )}
            </div>
          </div>

          <div className={classes.container}>
            <div className={classes.filterCol}>
              <FilterCategoriesTree
                categories={catalogCategories || []}
                resetProducts={resetProducts}
                searchProducts={payload =>
                  dispatch(productCatalogActions.fetchRequest(payload))
                }
                filter={filter}
                setSearch={payload => dispatch(productCatalogActions.setSearch(payload))}
                loading={loading}
                selectedCategoryId={selectedCategoryId}
                setSelectedCategoryId={setSelectedCategoryId}
                isAdmin={isAdmin}
                isVender={!!isVendor}
              />
            </div>

            <div className={classes.productsCol}>
              {loading ? (
                <Preloader />
              ) : (
                <>
                  {!products || !products.length ? (
                    <Typography className={classes.empty} component='h5' variant='h5'>
                      {fm('PRODUCT.CATALOG.LIST.EMPTY')}
                    </Typography>
                  ) : (
                    <div className={clsx(classes.card)}>
                      <div>
                        <div className={classes.productColHeader}>
                          <div className={classes.productColHeaderItem}>
                            <Typography variant='h6' className={classes.count}>
                              {fm('PRODUCT.ALL.PRODUCTS')}
                            </Typography>
                          </div>

                          <div className={classes.productColHeaderItem}>
                            <IconButton
                              onClick={() =>
                                dispatch(productCatalogActions.setViewType('grid'))
                              }
                              color={viewType === 'grid' ? 'primary' : 'inherit'}
                            >
                              <GridOnIcon className={classes.icon} />
                            </IconButton>

                            <IconButton
                              onClick={() =>
                                dispatch(productCatalogActions.setViewType('table'))
                              }
                              color={viewType === 'table' ? 'primary' : 'inherit'}
                            >
                              <ListIcon className={classes.icon} />
                            </IconButton>
                          </div>
                        </div>

                        {viewType === 'table' ? (
                          <ProductsTableListComponent
                            companyId=''
                            data={products}
                            page={page}
                            perPage={perPage}
                            total={total}
                            categoryId=''
                            fetch={({ page, perPage }) =>
                              dispatch(
                                productCatalogActions.fetchRequest({
                                  page,
                                  perPage,
                                  filter,
                                  categoryId: selectedCategoryId || 0,
                                })
                              )
                            }
                            isAuthorized={isAuthorized}
                            isAdmin={!!isAdmin}
                            me={me}
                            addProductLoading={addProductLoading}
                            handleCartDialog={handleCartDialog}
                            viewAction={viewAction}
                            editAction={editAction}
                            handleDeleteDialog={handleDeleteDialog}
                          />
                        ) : (
                          <>
                            {viewType === 'list' ? (
                              <></>
                            ) : (
                              <ProductsGrid
                                addProductLoading={addProductLoading}
                                isAuthorized={isAuthorized}
                                isAdmin={!!isAdmin}
                                me={me}
                                handleCartDialog={handleCartDialog}
                                viewAction={viewAction}
                                editAction={editAction}
                                handleDeleteDialog={handleDeleteDialog}
                                data={products}
                                page={page}
                                perPage={perPage}
                                total={productsCount}
                                categoryId=''
                                companyId=''
                                fetch={({ page, perPage }) =>
                                  dispatch(
                                    productCatalogActions.fetchRequest({
                                      page,
                                      perPage,
                                      filter,
                                      categoryId: selectedCategoryId || 0,
                                    })
                                  )
                                }
                              />
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CategoryView;
