import { TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { ButtonWithLoader } from '../../../components/ui/Buttons';
import { useAxiosEnterPassword } from '../hooks/useAxiosEnterPassword';
import { useFormikEnterPassword } from '../hooks/useFormikEnterPassword';
import { actions as authActions } from '../../../store/ducks/auth.duck';
import { TAuthTabs } from '.';
import { IAppState } from '../../../store/rootDuck';

interface IProps {
  changeAuthTab: (newTab: TAuthTabs, props?: any) => void;
  email: string;
}

export const EnterPassword: React.FC<IProps> = ({ changeAuthTab, email }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginSuccess = useSelector(({ auth }: IAppState) => auth.loginSuccess, shallowEqual);

  const { makeRequest, loading, res } = useAxiosEnterPassword();
  const formik = useFormikEnterPassword(email, makeRequest);
  // useEffect(() => {
  //   loginSuccess && navigate('main/catalog');
  // }, [loginSuccess]);

  // on success
  useEffect(() => {
    if (res) {
      dispatch(authActions.loginSuccess(res));
    }
  }, [res]);

  return (
    <div className='kt-login__body'>
      <div className='kt-login__form'>
        <div className='kt-login__title'>
          <h3>{intl.formatMessage({ id: 'AUTH.INPUT.PASSWORD_REQUIRED' })}</h3>
        </div>

        <form noValidate autoComplete='off' className='kt-form' onSubmit={formik.handleSubmit}>
          <div className='form-group'>
            <TextField
              type='password'
              margin='normal'
              label={intl.formatMessage({
                id: 'AUTH.INPUT.PASSWORD',
              })}
              className='kt-width-full'
              name='password'
              autoFocus
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.password}
              helperText={formik.touched.password && formik.errors.password}
              error={Boolean(formik.touched.password && formik.errors.password)}
            />
          </div>

          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              margin: '15px 0',
            }}
          >
            <Link
              to='#'
              onClick={() => changeAuthTab('ForgotPassword')}
              className='kt-link kt-login__link-forgot'
            >
              <FormattedMessage id='AUTH.GENERAL.FORGOT_BUTTON' />
            </Link>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <div className='kt-login__actions' style={{ marginRight: 30 }}>
              <button
                onClick={() => changeAuthTab('CheckInSystem', { initEmail: email })}
                type='button'
                className='btn btn-secondary btn-elevate kt-login__btn-secondary'
              >
                {intl.formatMessage({ id: 'AUTH.GENERAL.BACK_BUTTON' })}
              </button>
            </div>

            <div className='kt-login__actions'>
              <ButtonWithLoader
                onPress={formik.handleSubmit}
                disabled={loading}
                loading={loading}
              >
                <FormattedMessage id='AUTH.LOGIN.BUTTON' />
              </ButtonWithLoader>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
