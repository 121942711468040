import axios from 'axios';

export const DOC_URL = '/api/document';
export const LEGACY_URL = 'api/docs/legacy';
export const CREATE_DOC = '/api/document';
export const GET_DOCS = '/api/documents';
export const GET_PUBLIC_DOC = '/api/_public/document';

export function getLegacy() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(LEGACY_URL);
}

export function createDoc(data: any) {
  return axios.post(CREATE_DOC, data);
}

export function editDoc(id: any, data: any) {
  return axios.put(`${DOC_URL}/${id}/edit`, data);
}

export function getDoc(code: any, locale: any) {
  return axios.get(`${DOC_URL}/${code}`, { params: { locale } });
}

export function getDocs(params: any) {
  return axios.get(GET_DOCS, params);
}

export function getPublicDoc(code: any, locale: any) {
  return axios.get(`${GET_PUBLIC_DOC}/${code}`, { params: { locale } });
}
