import { useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { uploadFiles } from '../../../../crud/search.crud';
import { getResponseMessage } from '../../../../utils/utils';
import { actions as searchActions } from '../../../../store/ducks/search.duck';

export const useUploadFiles: any = (id: string) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const fetch = useCallback(
    async (id: number, images: File[]) => {
      setLoading(true);
      const data = new FormData();
      images.forEach(img => data.append('files[]', img));
      uploadFiles(id, data)
        .then((res: any) => {
          id && dispatch(searchActions.fetchByIdSuccess(res.data));
          id &&
            enqueueSnackbar(`${intl.formatMessage({ id: 'SEARCH.UPLOAD.SUCCESS' })}`, {
              variant: 'success',
            });
          setSuccess(true);
        })
        .catch(e => {
          navigate(-1);
          enqueueSnackbar(`${intl.formatMessage({ id: 'ERROR' })}: ${getResponseMessage(e)}`, {
            variant: 'error',
          });
        })
        .finally(() => {
          setLoading(false);
          setSuccess(false);
        });
    },
    [id]
  );
  return [fetch, loading, success];
};
