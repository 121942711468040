import React from 'react';
import { Routes, Route } from 'react-router-dom';
import TenderListPage from './TenderListPage';
import TenderPage from './TenderPage';

export default function TendersPage() {
  return (
    <Routes>
      <Route path='list/:type' element={<TenderListPage />} />
      <Route path=':id/:mode' element={<TenderPage />} />
      <Route path='create' element={<TenderPage />} />
    </Routes>
  );
}
