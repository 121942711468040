import React from 'react';
import { Tab, Tabs } from '@mui/material';

import { useFormatMessage } from '../../../../../hooks';
import { a11yProps } from '../../../../../components/other/Tab/TabPanel';

type TProps = {
  value: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
};

const TabsRole: React.FC<TProps> = ({ value, setValue }) => {
  const fm = useFormatMessage();
  return (
    <Tabs
      orientation='vertical'
      variant='scrollable'
      value={value}
      onChange={(_event, newValue) => setValue(newValue)}
      sx={{ backgroundColor: '#ffff' }}
      indicatorColor='primary'
    >
      <Tab label={fm('ACTIVITY.ALL')} {...a11yProps(0)} />
      <Tab label={fm('SUBMENU.CUSTOMERS')} {...a11yProps(1)} />
      <Tab label={fm('SUBMENU.VENDORS')} {...a11yProps(2)} />
      <Tab label={fm('TEAM')} {...a11yProps(3)} />
    </Tabs>
  );
};

export default React.memo(TabsRole);
