import React from 'react';
import { useStylesHeaderAddMultiply } from '../../hooks/useStyles';
import EditParams from './EditParams';
import TypeParams from './TypeParams';

type TProps = {
  formik: any;
  countries: any;
  i: number;
};

const ProductAddMultiply: React.FC<TProps> = ({ formik, countries, i }) => {
  const classes1 = useStylesHeaderAddMultiply();
  const {classes} = classes1

  return (
    <>
      <div className={classes.containerWhite}>
        <EditParams i={i} formik={formik} />
        <TypeParams i={i} formik={formik} countries={countries} />
      </div>
    </>
  );
};

export default React.memo(ProductAddMultiply);
