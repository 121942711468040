import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LayoutSplashScreen } from './LayoutContext';
import * as builder from '../ducks/builder';
import { IAppState } from '../../app/store/rootDuck';

/**
 * Used to synchronize current layout `menuConfig`, `layoutConfig` and
 * `htmlClassService` with redux store.
 */

type TProps = {
  children: React.ReactNode;
  menuConfig: any;
  layoutConfig: any;
  htmlClassService: any;
};

const LayoutInitializer: React.FC<TProps> = ({
  children,
  menuConfig,
  layoutConfig,
  htmlClassService,
}) => {
  const dispatch = useDispatch();

  const builderState = useSelector(({ builder }: IAppState) => builder);

  useEffect(() => {
    // dispatch(builder.actions.setMenuConfig(menuConfig));
  }, [dispatch, menuConfig]);

  useEffect(() => {
    if (layoutConfig.demo !== builderState.layoutConfig.demo) {
      dispatch(builder.actions.setLayoutConfigs(layoutConfig));
    }
  }, [dispatch, builderState, layoutConfig]);

  useEffect(() => {
    dispatch(builder.actions.setHtmlClassService(htmlClassService));
  }, [dispatch, htmlClassService]);

  return htmlClassService === builderState.htmlClassServiceObjects ? (
    // Render content when `htmlClassService` synchronized with redux store.
    <>{children}</>
  ) : (
    // Otherwise sow loading splash screen.
    <LayoutSplashScreen />
  );
};

export default LayoutInitializer;
