import React, { useEffect, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import {
  Typography,
  IconButton,
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  CardContent,
  Theme,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { makeStyles, createStyles } from '@mui/styles';

import { Row, Col } from 'react-bootstrap';
import { useNavigate, Link as RouterLink } from 'react-router-dom';

import { SHeadTableCell } from '../../../components/styledComponents/Tables';
import Preloader from '../../../components/other/Preloader/Preloader';
import AlertDialog from '../../../components/other/Dialog/AlertDialog';
import { IAppState } from '../../../store/rootDuck';
import { actions as productTypesActions } from '../../../store/ducks/productType.duck';
import homeStyles from '../../../constants/homeStyles';
import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';

const ProductTypesList: React.FC<TPropsFromRedux> = ({
  types,
  loading,
  fetch,
  delProductType,
}) => {
  const [deleteId, setDeleteId] = useState<number | undefined>(-1);
  const [isAlertOpen, setAlertOpen] = useState(false);

  const homeClasses = homeStyles();
  const classes = useStyles();
  const intl = useIntl();
  const navigate = useNavigate();

  useEffect(() => {
    fetch();
  }, [fetch]);

  setLayoutSubheader({
    title: intl.formatMessage({ id: 'PRODUCT.TYPES.TITLE' }),
  });
  setLayoutFooter({ show: true });

  const handleOpenDeleteDialog = useCallback((id: number | undefined) => {
    setDeleteId(id);
    setAlertOpen(true);
  }, []);

  const deleteAction = useCallback(() => {
    setAlertOpen(false);
    if (deleteId) {
      delProductType({ id: deleteId });
    }
  }, [deleteId, delProductType]);

  const goToCreateProductType = useCallback(() => {
    navigate('/products/types/create');
  }, [navigate]);

  if (!types || loading) return <Preloader />;

  return (
    <>
      <AlertDialog
        open={isAlertOpen}
        message={intl.formatMessage({ id: 'PRODUCT.TYPES.DELETE.TEXT' })}
        okText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })}
        handleClose={() => {
          // clearDel();
          setAlertOpen(false);
        }}
        handleAgree={deleteAction}
      />

      <Button color='primary' variant='contained' onClick={() => goToCreateProductType()}>
        {intl.formatMessage({ id: 'PRODUCT.TYPES.BUTTON.ADD' })}
      </Button>

      <Row>
        <Col>
          {!types || !types.length ? (
            <Typography className={classes.empty} component='h5' variant='h5'>
              {intl.formatMessage({ id: 'COMMON.LIST.EMPTY' })}
            </Typography>
          ) : (
            <>
              <Card className={classes.card}>
                <CardContent className={homeClasses.classes.tableContainer}>
                  <Table aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <SHeadTableCell>
                          {intl.formatMessage({ id: 'PRODUCT.TABLE.ID' })}
                        </SHeadTableCell>
                        <SHeadTableCell>
                          {intl.formatMessage({ id: 'PRODUCT.TABLE.NAME' })}
                        </SHeadTableCell>
                        <SHeadTableCell align='right'>
                          {intl.formatMessage({ id: 'PRODUCT.TABLE.ACTIONS' })}
                        </SHeadTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {types.map(item => (
                        <TableRow key={item.id}>
                          <TableCell component='th' scope='row'>
                            {item.id || '-'}
                          </TableCell>
                          <TableCell>
                            <Link
                              component={RouterLink}
                              to={`/products/types/edit/${item.id}`}
                              className={classes.buttons}
                            >
                              {item.names?.[intl.locale] || item.name || '-'}
                            </Link>
                          </TableCell>
                          <TableCell align='right'>
                            <IconButton
                              color='primary'
                              aria-label='previous'
                              onClick={() => navigate(`/products/types/edit/${item.id}`)}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                handleOpenDeleteDialog(item.id);
                              }}
                            >
                              <DeleteIcon color='error' />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    empty: {
      marginBottom: 20,
      marginTop: 20,
    },
    buttons: {
      marginRight: theme.spacing(3),
    },
    card: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    categoryTree: {
      maxWidth: 400,
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    img: {
      width: 50,
      height: 50,
    },
  })
);

const connector = connect(
  (state: IAppState) => ({
    types: state.productTypes.productTypes,
    loading: state.productTypes.loading,
  }),
  {
    fetch: productTypesActions.fetchRequest,
    delProductType: productTypesActions.delRequest,
    clearDel: productTypesActions.clearDel,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ProductTypesList);
