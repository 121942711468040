import React from 'react';

import { useIntl } from 'react-intl';
import FilesDropzone from '../../formComponents/FilesDropzone';
import { MainImg } from './components/MainImg';
import { useStyles } from './hooks/useStyles';

export type TUrl = string;

interface IProps {
  image?: string;
  file?: File;
  uploadImages: (files: File[]) => void;
  deleteFile?: () => void;
  deleteImage?: () => void;
  containerStyle?: React.CSSProperties;
}

export const UploadIcon: React.FC<IProps> = ({
  image,
  file,
  uploadImages,
  deleteFile,
  containerStyle,
  deleteImage,
}) => {
  const intl = useIntl();

  const classes = useStyles();
  return (
    <div className={classes.container} style={containerStyle}>
      <MainImg image={image} file={file} deleteFile={deleteFile} deleteImage={deleteImage} />
      <FilesDropzone
        uploadFiles={uploadImages}
        avaliableNumberOfFilesToUpload={1}
        title={intl.formatMessage({ id: 'ADD.ICON' })}
        stylesDropZone={{ padding: 25 }}
        hideDescription
      />
    </div>
  );
};
