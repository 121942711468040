import { format, parse } from 'date-fns';
import { ru } from 'date-fns/locale';

export const getDateStop = (current_user_tariff?: any) => {
  if (current_user_tariff?.stop_datetime) {
    return format(
      parse(
        format(new Date(current_user_tariff.stop_datetime), 'dd.MM.yyyy'),
        'dd.MM.yyyy',
        new Date(),
        { locale: ru }
      ),
      'd MMMM y',
      { locale: ru }
    );
  }
  return 0;
};
