import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import { TextField, CircularProgress, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { makeStyles } from 'tss-react/mui';
import { useIntl } from 'react-intl';
import { debounce } from 'lodash';
import { ILocation } from '../../interfaces/locations';

const LIMIT_INTERVAL = 2000;
// const FIRST_FETCH_SYMBOL = 3;

const useStyles = makeStyles()(() => {
  return {
    pulseRoot: {
      '& fieldset': {
        animation: '2000ms ease-in-out infinite both TextFieldBorderPulse',
      },
    },
  };
});

interface IProps {
  id?: string;
  options: ILocation[];
  inputValue: ILocation;
  label: string;
  inputClassName: any;
  editable: boolean;
  loading: boolean;
  inputError: boolean;
  inputHelperText: any;
  disable: boolean;
  handleBlur?: (e: any) => {} | void;
  fetchLocations: (data: { location: string; locale: string }) => {};
  clearLocations: () => {};
  setSelectedLocation: (location: any) => {} | void;
  prompterRunning?: boolean;
  prompterStep?: number;
}

const AutocompleteComponent: React.FC<IProps> = ({
  id = '',
  options,
  inputValue,
  label,
  inputClassName,
  handleBlur,
  editable,
  loading,
  inputError,
  inputHelperText,
  fetchLocations,
  clearLocations,
  setSelectedLocation,
  disable,
  prompterRunning,
  prompterStep,
}) => {
  const [editableLocation, setEditableLocation] = useState(editable);
  const [location, setLocation] = useState<string>('');
  const [fetchedLocation, setFetchedLocation] = useState('');
  const classes1 = useStyles();
  const { classes } = classes1;
  const intl = useIntl();

  useEffect(() => {
    clearLocations();
  }, []);

  const fetchDebouncedLocations = debounce(() => {
    if (location.length >= 3 && location !== fetchedLocation) {
      fetchLocations({ location, locale: intl.locale });
      setFetchedLocation(location);
    }
  }, LIMIT_INTERVAL);

  useEffect(() => {
    fetchDebouncedLocations();
    return () => fetchDebouncedLocations.cancel();
  }, [location, intl.locale]);

  const handleInputChange = (_e: any, _val: string, reason: any) => {
    if (reason === 'clear') {
      setSelectedLocation({ address: '' });
    } else if (reason === 'input') {
      setLocation(_val);
    }
  };

  useEffect(() => {
    if (!inputValue?.address) {
      setEditableLocation(true);
    } else {
      setEditableLocation(false);
    }
  }, [inputValue]);

  return (
    <Autocomplete
      id={`autocomplete${id}`}
      noOptionsText={intl.formatMessage({ id: 'STORE.INPUT.LOCATION_PLACEHOLDER' })}
      options={options}
      loading={loading}
      getOptionLabel={option => option.address}
      onChange={(e: any, val: any) => {
        if (val) {
          setSelectedLocation(val);
          setEditableLocation(false);
        } else {
          setLocation('');
        }
      }}
      filterOptions={o => o}
      disabled={!editableLocation}
      value={inputValue}
      onInputChange={handleInputChange}
      classes={
        prompterRunning && prompterStep === 0 && !location ? { root: classes.pulseRoot } : {}
      }
      renderInput={(params: any) => (
        <TextField
          {...params}
          type='text'
          margin='normal'
          name='location'
          variant='outlined'
          label={label}
          className={inputClassName}
          onBlur={handleBlur}
          helperText={inputHelperText}
          error={inputError}
          InputProps={{
            ...params.InputProps,
            startAdornment: <PersonPinCircleIcon fontSize='large' color='primary' />,
            endAdornment: (
              <>
                {(loading || location !== fetchedLocation) && (
                  <CircularProgress color='inherit' size={20} />
                )}
                {!editableLocation && !disable && (
                  <IconButton
                    onClick={() => setEditableLocation(!editableLocation)}
                    size='small'
                  >
                    <EditIcon fontSize='small' />
                  </IconButton>
                )}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default AutocompleteComponent;
