export function googleDataToEntities(data: any) {
  try {
    return data.results.map((i: any) => {
      
      return ({
        address: i.title.text,
        // lng: i.geometry.location.log,
        // lat: i.geometry.location.lat,
      })
    });
  } catch (e) {
    return [];
  }
}
