import React from 'react';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  buttons: {
    // marginLeft: theme.spacing(2),
    color: 'red',
    borderColor: '#eb7f7f',
    '&:hover': {
      borderColor: 'red',
    },
  },
}));

interface IProps {
  children: React.ReactNode;
  type?: 'button' | 'reset' | 'submit' | undefined;
  onClick?: () => {} | void;
}

const noop = () => {};

const RedOutlinedButton: React.FC<IProps> = ({
  children,
  type = 'button',
  onClick = noop,
}) => {
  const classes = useStyles();

  return (
    <Button
      type={type}
      variant='outlined'
      color='inherit'
      onClick={onClick}
      className={classes.buttons}
    >
      {children}
    </Button>
  );
};

export default RedOutlinedButton;
