import { makeStyles } from '@mui/styles';

export const useStyle = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 0,
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  chats: {
    display: 'flex',
    minWidth: 400,
    height: '85vh',
    marginRight: '2.5rem',
    // paddingTop: '2.25rem',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: 0,
    marginBottom: 0,
    flexDirection: 'column',
    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      marginRight: 0,
      height: 300,
      marginBottom: 15,
      marginTop: 15,
    },
  },
  scrollBarChats: {
    position: 'relative',
    minWidth: 400,
    overflow: 'scroll',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
  },
  chatsBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '1rem 0',
    margin: '0 2.25rem',
    '&:hover': {
      transition: '0.3s ease-out',
      opacity: 0.7,
      cursor: 'pointer',
    },
  },
  chatsInfo: { display: 'flex', flexDirection: 'row' },
  chatsAvatar: {
    display: 'flex',
    width: 45,
    height: 45,
    borderRadius: 45,
    backgroundColor: '#F1FAFFFF',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '1.25rem',
    minWidth: 45,
  },
  chatAvatar: {
    display: 'flex',
    width: 35,
    height: 35,
    borderRadius: 35,
    backgroundColor: '#F1FAFFFF',
    justifyContent: 'center',
    alignItems: 'center',
  },
  chatsName: {
    color: '#181c32',
    fontWeight: 600,
    fontSize: '1.15rem',
    marginBottom: '0.5rem',
    wordWrap: 'break-word',
    '&:hover': {
      transition: '0.3s ease-out',
      color: '#009EF7FF',
      cursor: 'pointer',
    },
  },
  chatsEmail: { color: '#b5b5c3', fontWeight: 500, wordWrap: 'break-word' },
  chatsTimeWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 65,
  },
  chatsTime: { color: '#a1a5b7', fontSize: '.95rem', fontWeight: 400 },
  chatCard: {
    display: 'flex',
    width: '70%',
    height: '85vh',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: 0,
    marginBottom: 0,
    flexDirection: 'column',
    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      marginBottom: 0,
    },
  },
  chatHeader: {
    borderBottom: '1px solid #eff2f5',
    width: '100%',
    padding: '1rem 2.25rem',
  },
  chatName: {
    color: '#181c32',
    fontSize: '1.25rem',
    marginBottom: '1.25rem',
    fontWeight: 600,
    '&:hover': {
      transition: '0.3s ease-out',
      color: '#009EF7FF',
      cursor: 'pointer',
    },
  },
  chatStatusWrapper: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
  chatStatusIndicator: {
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: '#50CD89FF',
    marginRight: 8,
  },
  chatStatus: { color: '#b5b5c3', fontSize: '.95rem', fontWeight: 500 },
  scrollBarChat: { position: 'relative', width: '100%', height: '100%', overflow: 'scroll' },
  message: {
    display: 'flex',
    width: '100%',
    padding: '0 2.25rem',
    flexDirection: 'column',
    paddingBottom: '0.5rem',
  },
  messageUser: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '0.5rem',
    marginTop: '0.5rem',
  },
  messageUserAvatar: { height: 35, width: 35, borderRadius: '50%' },
  messageUserName: {
    marginLeft: '0.75rem',
    fontSize: '1.15rem',
    color: '#181c32',
    fontWeight: 600,
    marginRight: '0.25rem',
    '&:hover': {
      transition: '0.3s ease-out',
      color: '#009EF7FF',
      cursor: 'pointer',
    },
  },
  messageUserTime: {
    fontSize: '.95rem',
    color: '#a1a5b7',
    fontWeight: 400,
    marginTop: 4,
  },
  messageTextWrapper: {
    display: 'flex',
    flexDirection: 'column',
    // width: '100%',
    maxWidth: 600,
    backgroundColor: '#F8F5FFFF',
    borderRadius: '0.475rem',
    padding: '1.25rem',
  },
  messageText: { color: '#181c32', fontSize: 13, fontWeight: 600, whiteSpace: 'pre-wrap' },
  myMessage: {
    display: 'flex',
    width: '100%',
    padding: '0 2.25rem',
    flexDirection: 'column',
    paddingBottom: '0.5rem',
  },
  myMessageUser: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '0.5rem',
    justifyContent: 'flex-end',
    marginTop: '0.5rem',
  },
  myMessageTime: {
    fontSize: '.95rem',
    color: '#a1a5b7',
    fontWeight: 400,
    marginTop: 4,
  },
  myMessageUserName: {
    marginRight: '0.75rem',
    fontSize: '1.15rem',
    color: '#181c32',
    fontWeight: 600,
    marginLeft: '0.25rem',
    '&:hover': {
      transition: '0.3s ease-out',
      color: '#009EF7FF',
      cursor: 'pointer',
    },
  },
  myMessageTextWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  myMessageTextBlock: {
    display: 'flex',
    flexDirection: 'column',
    // width: '100%',
    maxWidth: 600,
    backgroundColor: '#F1FAFFFF',
    borderRadius: '0.475rem',
    padding: '1.25rem',
  },
  myMessageText: {
    color: '#181c32',
    fontSize: 13,
    fontWeight: 600,
    textAlign: 'end',
    whiteSpace: 'pre-wrap',
  },
  bottomContainer: {
    display: 'flex',
    width: '100%',
    marginTop: '1.25rem',
    flexDirection: 'column',
    marginBottom: '2.25rem',
  },
  input: {
    height: 70,
    maxHeight: 70,
    minHeight: 70,
    border: 'none',
    overflow: 'auto',
    outline: 'none',
    padding: '.75rem 1rem',
    marginLeft: '2.25rem',
    marginRight: '2.25rem',
    resize: 'none',
    marginBottom: '1.25rem',
    color: '#5e6278',
    fontSize: '1.1rem',
    fontWeight: 600,
  },
  wrapperInput: {
    marginLeft: '2.25rem',
    marginRight: '2.25rem',
    marginBottom: '1.25rem',
  },
  inputProps: {
    color: '#5e6278',
    fontSize: '1.1rem',
    fontWeight: 600,
  },
  inputLabelProps: {
    color: '#5e6278',
    fontWeight: 600,
  },
  wrapperBtn: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    paddingRight: '2.25rem',
    paddingLeft: '2.25rem',
    alignItems: 'center',
  },
  btnSend: {
    borderRadius: '0.475rem',
    backgroundColor: '#009EF7FF',
    padding: '0.75rem',
    cursor: 'pointer',
    minWidth: 98,
    '&:hover': {
      transition: '0.3s ease-out',
      opacity: 0.8,
      cursor: 'pointer',
    },
  },
  btnSendText: { fontSize: '1.1rem', color: '#fff', fontWeight: 600 },
  addFileBtn: {
    display: 'flex',
    width: 35,
    height: 35,
    borderRadius: '0.475rem',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      transition: '0.3s ease-out',
      opacity: 0.7,
      cursor: 'pointer',
    },
  },
  wrapperFiles: {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: '2.25rem',
    paddingLeft: '2.25rem',
    flexWrap: 'wrap',
  },
  wrapperFile: {
    display: 'flex',
    flexDirection: 'row',
    padding: 5,
    margin: 5,
    maxHeight: 30,
  },
  fileName: {
    color: '#181c32',
    fontSize: 13,
    fontWeight: 600,
    textAlign: 'end',
    whiteSpace: 'pre-wrap',
    marginRight: 5,
    marginLeft: 5,
  },
  deleteFileBtn: {
    '&:hover': {
      transition: '0.3s ease-out',
      opacity: 0.7,
      cursor: 'pointer',
    },
  },
}));

export const useStyleFileChat = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    alignItems: 'center',
    '&:hover': {
      transition: '0.3s ease-out',
      opacity: 0.7,
      cursor: 'pointer',
    },
  },
  file: { display: 'flex', flexDirection: 'column' },
  fileName: {
    color: '#181c32',
    fontSize: 13,
    fontWeight: 600,
    whiteSpace: 'pre-wrap',
    margin: '0 5px',
  },
  fileSize: {
    color: '#009EF7FF',
    fontSize: 13,
    fontWeight: 600,
    whiteSpace: 'pre-wrap',
    margin: '0 5px',
  },
}));

export const useStyleImageChat = makeStyles(() => ({
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center center',
    backgroundColor: '#000',
  },
}));
