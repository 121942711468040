import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Typography, IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useIntl } from 'react-intl';
import Preloader from '../../../../components/ui/Preloader/Preloader';
import { IAppState } from '../../../../store/rootDuck';
import { useGetReviews } from '../hooks/useGetReviews';
import { useDeleteReview } from '../hooks/useDeleteReview';
import { ReviewsStyles } from '../hooks/ReviewsStyles';
import { useDefineUserRole, useFormatMessage } from '../../../../hooks';
import Rating from './Rating';
import { API_DOMAIN } from '../../../../constants';
import { toAbsoluteUrl } from '../../../../../_base';
import { IProduct } from '../../../../interfaces/product';
import { useStylesReviewsList } from '../hooks/useStyles';
import TablePaginator from '../../../../components/TablePaginator/TablePaginator';

interface IProps {
  product: IProduct;
}

const ReviewsTextComponent: React.FC<IProps> = ({ product }) => {
  const classes = ReviewsStyles();
  const styles1 = useStylesReviewsList();
  const styles = styles1.classes
  const intl = useIntl();
  const fm = useFormatMessage();
  const reviewsProduct = useSelector(
    ({ products: { reviewsProduct } }: IAppState) => reviewsProduct,
    shallowEqual
  );
  const me = useSelector(({ profile: { me } }: IAppState) => me, shallowEqual);
  const isAdmin = useDefineUserRole(me, 'ROLE_ADMIN');
  const productId = useMemo(() => product.id, [product]);

  const [fetchReviews, loading] = useGetReviews();
  const [deleteReview] = useDeleteReview();
  const [pageReviews, setPageReviews] = useState({ perPage: 20, page: 1 });

  useEffect(() => {
    productId && fetchReviews(productId, pageReviews.perPage, pageReviews.page);
  }, [productId, fetchReviews]);

  const handleDeleteReview = useCallback(
    (id: number) => {
      deleteReview(id, productId, pageReviews.perPage, pageReviews.page);
    },
    [productId, pageReviews]
  );

  if (loading) return <Preloader />;

  return (
    <>
      <div className={classes.headerTitle}>{fm('PRODUCT.REVIEWS')}</div>
      <div className={classes.reviewsText}>
        <div className={classes.reviewsList}>
          {reviewsProduct && reviewsProduct.reviews.length > 0 && (
            <>
              <div style={{ marginBottom: 10 }}>
                {reviewsProduct.reviews.map(item => {
                  return (
                    <div className={styles.wrapperBlock} key={item.user.id}>
                      <div className='card card-bordered w-100'>
                        <div className='card-body'>
                          <div className={styles.wrapperUserInfo}>
                            <div className='d-flex align-items-center f'>
                              <div className={styles.wrapperUserAvatar}>
                                <img
                                  alt={item.user.first_name || 'avatar'}
                                  src={
                                    item.user.avatar
                                      ? `${API_DOMAIN}/${
                                          typeof item.user.avatar === 'string'
                                            ? item?.user?.avatar
                                            : item?.user?.avatar?.path
                                        }`
                                      : toAbsoluteUrl('/images/placeholder.png')
                                  }
                                  className={styles.userAvatar}
                                />
                              </div>
                              <div className='d-flex flex-column fs-5 text-gray-600 text-dark'>
                                <Typography className={styles.userFio}>{`${
                                  item.user.last_name || ''
                                } ${item.user.first_name || ''} ${
                                  item.user.middle_name || ''
                                }`}</Typography>
                                <Rating size='24px' rating={item.rating} />
                              </div>
                            </div>
                            {(isAdmin || item.user?.id === me?.id) && (
                              <Tooltip
                                title={intl.formatMessage({ id: 'PRODUCT.REVIEW.BUTTON.DEL' })}
                              >
                                <IconButton onClick={() => handleDeleteReview(item.id)}>
                                  <DeleteIcon color='error' />
                                </IconButton>
                              </Tooltip>
                            )}
                          </div>
                          <p className={styles.reviewText}>{item.text}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className={styles.wrapperPaginator}>
                <TablePaginator
                  page={pageReviews.page}
                  perPage={pageReviews.perPage}
                  fetchRows={(data: { page: number; perPage: number }) => {
                    const { page, perPage } = data;
                    productId && fetchReviews(productId, perPage, page);
                    setPageReviews({ perPage, page });
                  }}
                  total={reviewsProduct.total}
                  label={fm('RATING.TABLE.PER_PAGE')}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ReviewsTextComponent;
