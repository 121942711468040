import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, IconButton, MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ruLocale from 'date-fns/locale/ru';
import enLocale from 'date-fns/locale/en-US';
import csLocale from 'date-fns/locale/cs';
import moment from 'moment';
import { useIntl } from 'react-intl';

import useHomeStyles from '../../../../../constants/homeStyles';
import { useFormatMessage } from '../../../../../hooks';
import NumberFormatCustom from '../../../../../components/formComponents/NumberFormatCustom';
import ButtonWithLoader from '../../../../../components/other/Buttons/ButtonWithLoader';
import { useStyleShiftPage } from '../../hooks/useStyles';
import { ICategoryParent } from '../../../categories/interfaces';

export const locales: { [key: string]: any } = {
  ru: ruLocale,
  en: enLocale,
  cz: csLocale,
};

type TProps = {
  formik: any;
  id: string;
  childCategories: ICategoryParent[];
  deleteUser: () => void;
  handleOpenUsersDialog: () => void;
  deleteStore: () => void;
  handleOpenStoresDialog: () => void;
  loading: boolean;
};

const FormShiftPage: React.FC<TProps> = ({
  formik,
  id,
  childCategories,
  deleteUser,
  handleOpenUsersDialog,
  deleteStore,
  handleOpenStoresDialog,
  loading,
}) => {
  const intl = useIntl();

  const styles = useHomeStyles();
  const classes = useStyleShiftPage();
  const navigate = useNavigate();
  const fm = useFormatMessage();

  const {
    values,
    touched,
    setFieldValue,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
  } = formik;

  return (
    <>
      <div className={styles.classes.form} style={{ paddingTop: 0 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locales[intl.locale]}>
          <DatePicker
            // variant='dialog'
            format='yyyy-MM-dd HH:mm:ss'
            value={values.startString ? dayjs(values.startString.split('+')[0]) : null}
            onChange={date => {
              setFieldValue(
                'startString',
                moment(dayjs(date).toDate()).format('yyyy-MM-DD HH:mm:ss')
              );
            }}
            // InputProps={{
            //   classes: {
            //     // root: classes.input,
            //     focused: classes.inputFocused,
            //     notchedOutline: classes.notchedOutline,
            //   },
            //   readOnly: true,
            // }}
            // minDate={id ? undefined : Date.now()}
            label={fm('SHIFT.START.DATE')}
            // InputLabelProps={{ shrink: true }}
            // helperText={touched.startString && errors.startString}
            // error={Boolean(touched.startString && errors.startString)}
            // inputVariant='outlined'
            // style={{ width: '100%', marginTop: 16, marginBottom: 8 }}
            // okLabel={fm('CATEGORIES.DELETE.OK')}
            // cancelLabel={fm('COMMON.BUTTON.CANCEL')}
          />
          <DatePicker
            // variant='dialog'
            format='yyyy-MM-dd HH:mm:ss'
            value={values.endString ? dayjs(values.endString.split('+')[0]) : null}
            onChange={date => {
              setFieldValue(
                'endString',
                moment(dayjs(date).toDate()).format('yyyy-MM-DD HH:mm:ss')
              );
            }}
            // InputProps={{
            //   classes: {
            //     // root: classes.input,
            //     focused: classes.inputFocused,
            //     notchedOutline: classes.notchedOutline,
            //   },
            //   readOnly: true,
            // }}
            // minDate={id ? undefined : Date.now()}
            label={fm('SHIFT.END.DATE')}
            // InputLabelProps={{ shrink: true }}
            // helperText={touched.endString && errors.endString}
            // error={Boolean(touched.endString && errors.endString)}
            // inputVariant='outlined'
            // style={{ width: '100%', marginTop: 16, marginBottom: 8 }}
            // okLabel={fm('CATEGORIES.DELETE.OK')}
            // cancelLabel={fm('COMMON.BUTTON.CANCEL')}
          />
        </LocalizationProvider>
        <TextField
          select
          margin='normal'
          label={fm('SHIFT.CATEGORY')}
          value={values.categoryId}
          onChange={handleChange}
          name='categoryId'
          variant='outlined'
          helperText={touched.categoryId && errors.categoryId}
          error={Boolean(touched.categoryId && errors.categoryId)}
        >
          {childCategories.map(category => (
            <MenuItem key={category.id!.toString()} value={category.id!}>
              {category.name}
            </MenuItem>
          ))}
        </TextField>

        <div className={classes.companySearch}>
          <TextField
            label={fm('SHIFT.STAFF')}
            margin='normal'
            name='username'
            placeholder=''
            value={values.username}
            onChange={e => setFieldValue('username', e.target.value.replace(/[^0-9]/g, ''))}
            variant='outlined'
            InputProps={{
              readOnly: true,
              endAdornment:
                values.userId || values.username ? (
                  <IconButton onClick={deleteUser}>
                    <DeleteIcon fontSize='small' color='error' />
                  </IconButton>
                ) : (
                  <IconButton onClick={handleOpenUsersDialog}>
                    <SearchIcon color='primary' fontSize='small' />
                  </IconButton>
                ),
            }}
          />
          <Button
            variant='outlined'
            color='primary'
            onClick={() => handleOpenUsersDialog()}
            className={classes.allCompaniesBtn}
          >
            {fm('SUBMENU.VENDORS.STAFF')}
          </Button>
        </div>

        <TextField
          select
          margin='normal'
          label={fm('SHIFT.STATUS')}
          value={values.status || ''}
          onChange={handleChange}
          name='status'
          variant='outlined'
          helperText={touched.status && errors.status}
          error={Boolean(touched.status && errors.status)}
        >
          <MenuItem value='new'>{fm('SHIFT.TYPE.NEW')}</MenuItem>
          <MenuItem value='confirmed'>{fm('SHIFT.TYPE.CONFIRMED')}</MenuItem>
          <MenuItem value='canceled'>{fm('SHIFT.TYPE.CANCELED')}</MenuItem>
          <MenuItem value='unfulfilled'>{fm('SHIFT.TYPE.UNFULFILLED')}</MenuItem>
        </TextField>

        <TextField
          type='text'
          label={fm('SHIFT.RATE')}
          margin='normal'
          name='price'
          value={values.price || 0}
          variant='outlined'
          onBlur={handleBlur('price')}
          onChange={handleChange}
          helperText={touched.price && errors.price}
          error={Boolean(touched.price && errors.price)}
          InputProps={{
            inputComponent: NumberFormatCustom as any,
          }}
        />

        <div className={classes.companySearch}>
          <TextField
            label={fm('SHIFT.STORE')}
            margin='normal'
            name='storename'
            placeholder=''
            value={values.storename}
            onChange={e => setFieldValue('storename', e.target.value.replace(/[^0-9]/g, ''))}
            variant='outlined'
            InputProps={{
              readOnly: true,
              endAdornment:
                values.storeId || values.storename ? (
                  <IconButton onClick={deleteStore}>
                    <DeleteIcon fontSize='small' color='error' />
                  </IconButton>
                ) : (
                  <IconButton onClick={handleOpenStoresDialog}>
                    <SearchIcon color='primary' fontSize='small' />
                  </IconButton>
                ),
            }}
          />
          <Button
            variant='outlined'
            color='primary'
            onClick={() => handleOpenStoresDialog()}
            className={classes.allCompaniesBtn}
          >
            {fm('MENU.CATALOG.STORES')}
          </Button>
        </div>
        <div className={classes.actions}>
          <Button
            onClick={() => navigate(-1)}
            className={classes.buttons}
            variant='outlined'
            color='primary'
          >
            {fm('CATEGORY.BUTTON.CANCEL')}
          </Button>
          <ButtonWithLoader loading={loading} disabled={loading} onPress={handleSubmit}>
            {id ? fm('COMMON.BUTTON.SAVE') : fm('SHIFT.BUTTON.ADD')}
          </ButtonWithLoader>
        </div>
      </div>
    </>
  );
};

export default FormShiftPage;
