import { InputLabel, TextField } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

import NumberFormatCustom from '../../../../components/formComponents/NumberFormatCustom';
import MultiSelect from '../../../../components/ui/MultiSelect';
import { useFormatMessage } from '../../../../hooks';
import { ITypeParameter } from '../../../../interfaces/productType';

type TProps = {
  params: ITypeParameter[] | null;
  values: { [key: string]: any };
  setFieldValue: any;
};

const TypeParamsInputs: React.FC<TProps> = ({ params, values, setFieldValue }) => {
  const fm = useFormatMessage();
  const intl = useIntl();

  return (
    <>
      <>
        {params &&
          params.length > 0 &&
          params.map(el => {
            if (
              (el.type === 'enum' || el.type === 'multiple_enum') &&
              el.parameter_enum_values
            ) {
              return (
                <MultiSelect
                  key={el.id}
                  label={el.name || ''}
                  emptyValueLabel={fm('ORDER.FILTER.PLACEHOLDER')}
                  options={el.parameter_enum_values.map(i => ({
                    value: i.value,
                    label: i.translations?.[intl.locale] || i.value,
                  }))}
                  selectedValues={values[`param_${el.id}`] || []}
                  setSelectedValues={values => {
                    setFieldValue(`param_${el.id}`, values);
                  }}
                />
              );
            }
            if (el.type === 'string' || el.type === 'text') {
              return (
                <div style={{ marginTop: 15, marginBottom: 8 }}>
                  <TextField
                    label={el.name || ''}
                    name={`param_${el.id}`}
                    style={{ marginRight: 10 }}
                    placeholder={fm('PRODUCT.FILTER.FROM')}
                    value={values[`param_${el.id}`]?.[0] || ''}
                    onChange={e => {
                      setFieldValue(`param_${el.id}`, [e.target.value]);
                    }}
                    variant='outlined'
                  />
                </div>
              );
            }
            if (el.type === 'number') {
              return (
                <div style={{ marginTop: 15, marginBottom: 8 }}>
                  <InputLabel shrink>{el.name || ''}</InputLabel>
                  <TextField
                    name={`param_${el.id}`}
                    style={{ marginRight: 10 }}
                    placeholder={fm('PRODUCT.FILTER.FROM')}
                    value={values[`param_${el.id}`]?.[0] || ''}
                    onChange={e => {
                      let fieldValues = values[`param_${el.id}`];
                      if (!fieldValues) fieldValues = [];
                      fieldValues[0] = e.target.value;
                      setFieldValue(`param_${el.id}`, fieldValues);
                    }}
                    InputProps={{ inputMode: 'numeric' }}
                    variant='outlined'
                  />
                  <TextField
                    name={`param_${el.id}`}
                    placeholder={fm('PRODUCT.FILTER.TO')}
                    value={values[`param_${el.id}`]?.[1] || ''}
                    onChange={e => {
                      let fieldValues = values[`param_${el.id}`];
                      if (!fieldValues) fieldValues = [];
                      fieldValues[1] = e.target.value;
                      setFieldValue(`param_${el.id}`, fieldValues);
                    }}
                    InputProps={{ inputMode: 'numeric' }}
                    variant='outlined'
                  />
                </div>
              );
            }
          })}
      </>
      <div style={{ marginTop: 15, marginBottom: 8 }}>
        <InputLabel shrink>{fm('PRODUCT.FILTER.PRICE')}, ₽</InputLabel>
        <TextField
          name='min_price'
          style={{ marginRight: 10 }}
          placeholder={fm('PRODUCT.FILTER.FROM')}
          value={values.min_price || ''}
          onChange={e => {
            setFieldValue('min_price', e.target.value);
          }}
          InputProps={{
            inputComponent: NumberFormatCustom as any,
            inputMode: 'numeric',
          }}
          variant='outlined'
        />
        <TextField
          name='max_price'
          placeholder={fm('PRODUCT.FILTER.TO')}
          value={values.max_price || ''}
          onChange={e => {
            setFieldValue('max_price', e.target.value);
          }}
          InputProps={{
            inputComponent: NumberFormatCustom as any,
            inputMode: 'numeric',
          }}
          variant='outlined'
        />
      </div>
    </>
  );
};

export default TypeParamsInputs;
