import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { UsersPage, UsersEditPage } from './users';
import UserInvite from "./users/UserInvite";

export default function Users() {
  return (
    <Routes>
      <Route path='new/:type' element={<UsersEditPage />} />
      <Route path='edit/:type/:userId' element={<UsersEditPage />} />
      <Route path=':type' element={<UsersPage />} />
      <Route path='/invite/:type' element={<UserInvite/>} />
      <Route path='' element={<UsersPage />} />
    </Routes>
  );
}
