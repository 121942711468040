import React, { useState } from 'react';

import {
  Card,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useStyles } from '../hooks/useStyles';
import { useDefineUserRole, useFormatMessage } from '../../../../hooks';
import { locales } from '../../shifts/shiftPage/components/FormShiftPage';
import { IUser } from '../../../../interfaces/user';
import AutocompleteStaff from '../../../../components/AutocompleteStaff';
import AutocompleteStaffPhone from '../../../../components/AutocompleteStaffPhone';
import AutocompleteCustom from '../../../../components/AutocompleteCustom';
import { TStaffSearch } from '../../../../components/ui/UsersFilter/types';
import { ICompany } from '../../../../interfaces/companies';

type TProps = {
  fetchUsers: (params: Partial<TStaffSearch>) => Promise<void>;

  searchUsers: IUser[];
  setUsersSearch: (value: IUser[]) => void;
  searchUsersLoading: boolean;
  loadingPhone: boolean;
  fio: IUser;
  setFio: (value: IUser) => void;
  setPhone: (value: string | null) => void;
  setEmail: (value: string | null) => void;
  startDate: string;
  endDate: string;
  setStart: (value: string) => void;
  setEnd: (value: string) => void;
  userPhone: IUser;
  setUserPhone: (value: IUser) => void;
  companySelect: ICompany | null;
  setCompanySelect: (value: ICompany | null) => void;
  loadingCompanies: boolean;
  companies: ICompany[];
  me?: IUser;
};

const FilterOrders: React.FC<TProps> = ({
  setEnd,
  setStart,
  endDate,
  startDate,
  searchUsers,
  searchUsersLoading,
  fio,
  setFio,
  setPhone,
  setUsersSearch,
  fetchUsers,
  loadingPhone,
  userPhone,
  setUserPhone,
  setEmail,
  companySelect,
  setCompanySelect,
  loadingCompanies,
  companies,
  me,
}) => {
  const intl = useIntl();
  const classes1 = useStyles();
  const {classes} = classes1
  const fm = useFormatMessage();
  const [checkSearch, setCheckSearch] = useState<string | 'fio' | 'email' | 'number'>('fio');
  const isRoleVendor = useDefineUserRole(me, ['ROLE_VENDOR', 'ROLE_VENDOR_STAFF']);
  const isRoleBuyer = useDefineUserRole(me, ['ROLE_BUYER', 'ROLE_BUYER_STAFF']);

  const handleStartDateChange = (date: Date) => {
    setStart(moment(date).format('DD.MM.YYYY'));
    if (endDate && moment(endDate).isBefore(date, 'day')) {
      setEnd(moment(date).format('DD.MM.YYYY'));
    }
  };

  return (
    <div>
      <div className={classes.filterColTop}>
        <Card
          style={{
            overflow: 'visible',
          }}
        >
          {!isRoleVendor && (
            <>
              <Typography className={classes.label}>{fm('USER.SEARCH.USER.TITLE')}</Typography>
              <RadioGroup
                name='checkSearch'
                value={checkSearch}
                onChange={e => {
                  setCheckSearch(e.currentTarget.value);
                  // setUsersStaff([]);
                  setFio({} as IUser);
                  setPhone('');
                }}
                style={{ flexDirection: 'row' }}
              >
                <FormControlLabel
                  value='fio'
                  control={<Radio color='primary' />}
                  label={fm('USER.SEARCH.USER.NAME')}
                />
                <FormControlLabel
                  value='email'
                  control={<Radio color='primary' />}
                  label={fm('USER.SEARCH.USER.EMAIL')}
                />
                <FormControlLabel
                  value='number'
                  control={<Radio color='primary' />}
                  label={fm('USER.SEARCH.USER.PHONE')}
                />
              </RadioGroup>
              {checkSearch === 'fio' && (
                <AutocompleteStaff
                  options={searchUsers || []}
                  loading={searchUsersLoading}
                  inputValue={fio}
                  editable
                  label={fm('USER.SEARCH.USER.NAME')}
                  inputClassName={{ width: '100%' }}
                  searchStaff={fetchUsers}
                  clearUsers={() => {
                    setUsersSearch([]);
                    setPhone('');
                    setEmail(null);
                    setFio({} as IUser);
                  }}
                  setSelectedValue={value => {
                    value && setFio(value);
                    setPhone('');
                  }}
                  disable={false}
                />
              )}
              {checkSearch === 'email' && (
                <AutocompleteStaff
                  options={searchUsers || []}
                  loading={searchUsersLoading}
                  inputValue={fio}
                  editable
                  label={fm('USER.SEARCH.USER.EMAIL')}
                  inputClassName={{ width: '100%' }}
                  searchStaff={fetchUsers}
                  clearUsers={() => {
                    setUsersSearch([]);
                    setPhone('');
                    setEmail(null);
                    setFio({} as IUser);
                  }}
                  setSelectedValue={value => {
                    value && setEmail(value?.email);
                    setPhone('');
                  }}
                  disable={false}
                />
              )}
              {checkSearch === 'number' && (
                <AutocompleteStaffPhone
                  options={searchUsers || []}
                  loading={loadingPhone}
                  inputValue={userPhone}
                  // editable
                  label={fm('USER.SEARCH.USER.PHONE')}
                  inputClassName={{ width: '100%' }}
                  searchStaff={fetchUsers}
                  clearUsers={() => {
                    setUsersSearch([]);
                    setPhone('');
                    setEmail('');
                    setUserPhone({} as IUser);
                  }}
                  setSelectedValue={value => {
                    value && setPhone(value.phone);
                    setFio({} as IUser);
                  }}
                  // disable={false}
                />
              )}
            </>
          )}
          {!isRoleBuyer && (
            <>
              <Typography
                className={classes.label}
                style={{
                  marginTop: 16,
                }}
              >
                {fm('USER.SEARCH.USER.COMPANY')}
              </Typography>
              <AutocompleteCustom
                setValue={setCompanySelect}
                value={companySelect}
                options={companies}
                label={fm('USER.SEARCH.USER.COMPANY')}
                isLoading={loadingCompanies}
                getOptionLabel={(company: ICompany) => company.name}
              />
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={locales[intl.locale]}
              >
                <Typography
                  className={classes.label}
                  style={{
                    marginTop: 16,
                  }}
                >
                  {fm('USER.SEARCH.USER.DATE')}
                </Typography>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <DatePicker
                    // variant='dialog'
                    format='DD.MM.YYYY'
                    value={startDate ? dayjs(startDate) : null}
                    onChange={date => {
                      handleStartDateChange(dayjs(date).toDate());
                    }}
                    // InputProps={{
                    //   classes: {
                    //     focused: classes.inputFocused,
                    //   },
                    //   readOnly: true,
                    // }}
                    // InputLabelProps={{shrink: true}}
                    // inputVariant='outlined'
                    // style={{width: '100%',}}
                    // okLabel={fm('CATEGORIES.DELETE.OK')}
                    // cancelLabel={fm('COMMON.BUTTON.CANCEL')}
                    // minDate={startDate}
                  />
                </div>
                <InputLabel style={{ marginTop: 16 }}>{fm('SHIFT.END.DATETIME')}</InputLabel>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <DatePicker
                    // variant='dialog'
                    format='DD.MM.YYYY'
                    value={endDate ? dayjs(endDate) : null}
                    onChange={date => {
                      setEnd(moment(dayjs(date).toDate()).format('DD.MM.YYYY'));
                    }}
                    // InputProps={{
                    //   classes: {
                    //     focused: classes.inputFocused,
                    //   },
                    //   readOnly: true,
                    // }}
                    // InputLabelProps={{shrink: true}}
                    // inputVariant='outlined'
                    // style={{width: '100%',}}
                    // okLabel={fm('CATEGORIES.DELETE.OK')}
                    // cancelLabel={fm('COMMON.BUTTON.CANCEL')}
                    // minDate={startDate}
                  />
                </div>
              </LocalizationProvider>
            </>
          )}
        </Card>
      </div>
    </div>
  );
};

export default React.memo(FilterOrders);
