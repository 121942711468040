import React, { useMemo, useState } from 'react';
import { Card, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ImageGalleryLib from 'react-image-gallery';
import ReactImageMagnify from 'react-image-magnify';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { IProduct } from '../../../../interfaces/product';
import { API_DOMAIN } from '../../../../constants';
import { toAbsoluteUrl } from '../../../../../_base';

interface IProps {
  product: IProduct;
}

const useStyles = makeStyles((theme: any) => ({
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 0,
    backgroundColor: 'transparent',
    height: '100%',
    position: 'relative',
  },
  imgContainerSmall: {
    maxHeight: '250px',
    borderRadius: 0,
    [theme.breakpoints.up('md')]: {
      maxHeight: '85vh',
      borderRadius: 0,
    },
  },
  imgContainerFull: {
    height: '100vh',
    borderRadius: 0,
  },
  img: {
    width: 'auto',
    maxWidth: '100%',
    height: 'auto',
    maxHeight: '100%',
    objectFit: 'contain',
    objectPosition: 'center center',
    backgroundColor: 'transparent',
  },
  galleryContainer: {
    width: '100%',
    marginBottom: '50px',
  },
  arrow: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 2,
    color: '#369ff7',
  },
}));

const ImageGallery: React.FC<IProps> = ({ product }) => {
  const classes = useStyles();
  const [isFullScreen, setIsFullScreen] = useState(false);

  const images = useMemo(() => {
    if (product?.photos) {
      return product.photos
        .sort(a => (a.main ? -1 : 0))
        .map(el => ({
          original: el.big ? API_DOMAIN + el.big : toAbsoluteUrl('/images/placeholder.png'),
          thumbnail: el.small
            ? API_DOMAIN + el.small
            : toAbsoluteUrl('/images/placeholder.png'),
          renderItem: ({ original }: any) => (
            <>
              {isFullScreen ? (
                <Card
                  className={`${classes.imgContainer} ${classes.imgContainerFull}`}
                  onError={(e: any) => {
                    e.target.src = toAbsoluteUrl('/images/placeholder.png');
                  }}
                >
                  <img alt='' className={classes.img} src={original} />
                </Card>
              ) : (
                <ReactImageMagnify
                  smallImage={{
                    alt: 'Example Image',
                    isFluidWidth: true,
                    src: original,
                  }}
                  largeImage={{
                    src: original,
                    width: 1200,
                    height: 1800,
                  }}
                  enlargedImagePosition='over'
                />
              )}
            </>
          ),
        }));
    }

    return null;
  }, [product, isFullScreen]);

  return (
    <div className={classes.galleryContainer}>
      {images && (
        <>
          {images.length > 0 ? (
            <ImageGalleryLib
              onScreenChange={boolean => setIsFullScreen(boolean)}
              items={images}
              useBrowserFullscreen
              showThumbnails={images.length > 1}
              showFullscreenButton
              disableThumbnailScroll={false}
              showPlayButton={false}
              thumbnailPosition='left'
              infinite
              renderLeftNav={onClick => (
                <IconButton onClick={onClick} className={classes.arrow} style={{ left: 0 }}>
                  <ArrowBackIosNewIcon style={{ fontSize: 30 }} />
                </IconButton>
              )}
              renderRightNav={onClick => (
                <IconButton onClick={onClick} className={classes.arrow} style={{ right: 0 }}>
                  <ArrowForwardIosIcon style={{ fontSize: 30 }} />
                </IconButton>
              )}
              onThumbnailError={(e: any) => {
                e.target.src = toAbsoluteUrl('/images/placeholder.png');
              }}
            />
          ) : (
            <Card
              className={`${classes.imgContainer} ${classes.imgContainerSmall}`}
              elevation={0}
            >
              <img
                alt=''
                className={classes.img}
                src={toAbsoluteUrl('/images/placeholder.png')}
              />
            </Card>
          )}
        </>
      )}
    </div>
  );
};

export default ImageGallery;
