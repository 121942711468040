import React from 'react';
import { Route, Routes } from 'react-router-dom';
import RefferalsEditPage from './RefferalsEditPage';
import RefferalsPage from './RefferalsPage';

export default function Promocodes() {
  return (
    <Routes>
      <Route path='new' element={<RefferalsEditPage />} />
      <Route path='list' element={<RefferalsPage />} />
      <Route path='edit/:promocodeId' element={<RefferalsEditPage />} />
    </Routes>
  );
}
