import { TariffsType } from '../TariffList';

const getSubheaderId = (type: TariffsType) => {
  switch (type) {
    case TariffsType.buyers:
      return 'SUBMENU.TARIFFS.BUYERS';
    case TariffsType.vendors:
      return 'SUBMENU.TARIFFS.PROVIDERS';
    default:
      return 'SUBMENU.TARIFFS.PROVIDERS';
  }
};

export default getSubheaderId;
