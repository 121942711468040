import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { addCompanyByInn } from '../../../../crud/companies.crud';
import { ICompany } from '../../../../interfaces/companies';
import { getResponseMessage } from '../../../../utils/utils';

export const useAddCompany: any = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState<ICompany | null>(null);

  const addCompany = useCallback(async (inn: string, role?: string) => {
    setLoading(true);
    addCompanyByInn(inn, role)
      .then(({ data }) => {
        if (data?.data) {
          const searchCompanies = data.data;
          if (searchCompanies.length > 0) {
            setCompanies(searchCompanies);
          } else {
            enqueueSnackbar(`По заданному ИНН компании не найдены`, { variant: 'error' });
          }
        } else {
          enqueueSnackbar(`Ошибка поиска компаний`, { variant: 'error' });
        }
      })
      .catch(e => {
        enqueueSnackbar(`Ошибка: ${getResponseMessage(e)}`, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return [addCompany, loading, companies];
};
