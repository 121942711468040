import { makeStyles } from '@mui/styles';
import { createStyles, Theme } from '@mui/material/styles';

export const useStylesPublicationPost: any = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      width: '100%',
      transition: '.2s ease-out',
      '&:hover': {
        transform: 'scale(1.03)',
      },
    },
    content: {
      flexWrap: 'nowrap',
      gap: 30,
      [theme.breakpoints.down(1025)]: {
        flexWrap: 'wrap',
      },
    },
    rootView: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '80%',
      },
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    media: {
      height: 220,
      paddingTop: '52.25%', // 16:9
      marginBottom: 10,
    },
    buttonViewContain: {
      paddingBottom: '15px',
    },
    buttonContent: {
      display: 'flex',
      flexDirection: 'row',
      gap: 10,
      // justifyContent: 'space-between',
      // width: '100%',
      marginTop: 25,
    },
    // btnAddProduct: {
    //   width: '100%',
    //   display: 'flex',
    //   justifyContent: 'center',
    //   height: 45,
    //   [theme.breakpoints.down('md')]: {
    //     // marginBottom: 10,
    //   },
    //   padding: '0 5px',
    //   marginBottom: 5,
    // },
    //     productCard: {
    //       display: 'flex',
    //       flexDirection: 'column',
    // // width: 300,
    //       // cursor: 'pointer',
    //       // transition: '.3s ease-out',
    //       // boxShadow: 'none !important',
    //       height: 477,
    //       justifyContent: 'space-between',

    //       // '&:hover': {
    //       //   transform: 'scale(1.03)',
    //       // },

    //       // [theme.breakpoints.up('sm')]: {
    //       //   width: 285,
    //       // },
    //       // minWidth: '24.7%',
    //       // marginLeft: 15,
    //       cursor: 'pointer',
    //       transition: '.3s ease-out',
    //       boxShadow: 'none !important',

    //       '&:hover': {
    //         transform: 'scale(1.03)',
    //       },

    //       [theme.breakpoints.up('sm')]: {
    //         width: 280,
    //       },
    //     },
    title: {
      fontWeight: 400,
      color: '#212328',
      wordWrap: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',

      [theme.breakpoints.down('sm')]: {
        width: 280,
        height: 42,
        fontSize: 14,
      },
      [theme.breakpoints.up('sm')]: {
        width: 200,
        height: 42,
        fontSize: 14,
      },
      [theme.breakpoints.up('md')]: {
        width: 240,
        height: 42,
        fontSize: 16,
      },
      [theme.breakpoints.up('lg')]: {
        width: 250,
        height: 45,
        fontSize: 16,
      },
      [theme.breakpoints.up('xl')]: {
        width: 300,
        height: 50,
        fontSize: 18,
      },
      // @ts-ignore
      [theme.breakpoints.up('xxl')]: {
        minWidth: 360,
        height: 60,
        fontSize: 20,
      },
    },
    textContent: {
      color: '#000',
      wordWrap: 'break-word',
      marginTop: 20,
      [theme.breakpoints.down('md')]: {
        fontSize: '1.4rem',
      },
    },
    titleIsAdmin: {
      fontWeight: 400,
      color: '#212328',
      wordWrap: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',

      [theme.breakpoints.down('sm')]: {
        width: 270,
        height: 42,
        fontSize: 14,
      },
      [theme.breakpoints.up('sm')]: {
        width: 180,
        height: 42,
        fontSize: 14,
      },
      [theme.breakpoints.up('md')]: {
        width: 200,
        height: 42,
        fontSize: 16,
      },
      [theme.breakpoints.up('lg')]: {
        width: 210,
        height: 45,
        fontSize: 16,
      },
      [theme.breakpoints.up('xl')]: {
        width: 230,
        height: 50,
        fontSize: 18,
      },
      // @ts-ignore
      [theme.breakpoints.up('xxl')]: {
        minWidth: 330,
        height: 60,
        fontSize: 20,
      },
    },

    container: {
      display: 'grid',
      gridGap: 24,
      gridTemplateColumns: 'repeat(4, 220px)',

      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
      [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },
      [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },
      [theme.breakpoints.up('xl')]: {
        gridTemplateColumns: 'repeat(4, 1fr)',
      },
      // @ts-ignore
      [theme.breakpoints.up('xxl')]: {
        gridTemplateColumns: 'repeat(5, 1fr)',
      },
    },
    productCard: {
      minWidth: '100%',
      marginBottom: 25,
      cursor: 'pointer',
      transition: '.3s ease-out',
      boxShadow: 'none !important',

      '&:hover': {
        transform: 'scale(1.03)',
      },

      [theme.breakpoints.up('md')]: {
        width: 220,
      },
    },
    containerBtns: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
      },
    },
    btnSupport: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      height: 45,
    },
    btnAddProduct: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      height: 45,
      [theme.breakpoints.down('md')]: {
        // marginBottom: 10,
      },
    },
    cardContent: {
      padding: '15px 15px 0 15px',
      height: 120,
      overflow: 'hidden',

      [theme.breakpoints.up('xl')]: {
        height: 140,
      },
      // @ts-ignore
      [theme.breakpoints.up('xxl')]: {
        height: 160,
      },
    },
    columnContent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    reviewContent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    card_content: {
      paddingRight: 0,
      paddingTop: 120,
      width: '50%',

      [theme.breakpoints.up('lg')]: {
        width: '25%',
      },
      [theme.breakpoints.down('lg')]: {
        padding: '50px 0 0 0',
      },
      [theme.breakpoints.up('xl')]: {
        width: '26%',
      },
      // @ts-ignore
      [theme.breakpoints.up('xxl')]: {
        width: '22%',
      },
    },
    name: {
      padding: 0,
      margin: 0,
      fontWeight: 'normal',
      fontFamily: 'AtypTextRegular',
      fontSize: '18px',
      lineHeight: '16.9px',
    },
    price: {
      fontSize: '21px',
      fontWeight: 'bold',
      padding: 0,
      margin: 0,
    },
  })
);
