import { makeStyles } from '@mui/styles';

export const useStylesTariffChange = makeStyles((theme: any) => ({
  container: {
    maxWidth: '1200px',
    minWidth: '300px',
  },
  cardContain: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.up('sm')]: {
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
    },
  },
  card: {
    width: '100%',
    margin: 5,
    fontSize: 16,
    [theme.breakpoints.up('sm')]: {
      width: '25%',
    },
  },
  title: {
    fontSize: 18,
    fontWeight: 500,
  },
  button: {
    width: '100%',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  startDateRow: {
    marginBottom: theme.spacing(2),
  },
  checkboxRow: {
    marginBottom: theme.spacing(2),
    alignItems: 'center',
  },
}));
