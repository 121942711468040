import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Typography,
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  CardContent,
  Theme,
} from '@mui/material';
import { Row, Col } from 'react-bootstrap';
import { makeStyles, createStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useSelector } from 'react-redux';

import { SHeadTableCell } from '../../../components/styledComponents/Tables';
import Preloader from '../../../components/other/Preloader/Preloader';
import AlertDialog from '../../../components/other/Dialog/AlertDialog';
import homeStyles from '../../../constants/homeStyles';
import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';
import { useAxiosGet, useFormatMessage } from '../../../hooks';
import { useAxiosDeleteCallback } from '../../../hooks/useAxiosGet';
import useCrudSnackbar from '../../../hooks/useCrudSnackbar';
import { IAppState } from '../../../store/rootDuck';
import { TCollection } from './types';
import { IProductFilter } from '../../../interfaces/product';

const CollectionsList: React.FC = () => {
  const [deleteId, setDeleteId] = useState<number | undefined>(-1);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const { me } = useSelector(({ profile }: IAppState) => profile);
  const homeClasses = homeStyles();
  const classes = useStyles();
  const intl = useIntl();
  const navigate = useNavigate();
  const fm = useFormatMessage();

  const [collections, loading, err, reload] = useAxiosGet(
    me?.is_admin ? '/api/shop/collections' : `/api/shop/collections?slot2=true`
  );
  const { delRes, delErr, del } = useAxiosDeleteCallback();

  setLayoutSubheader({ title: fm('COLLECTIONS') });
  setLayoutFooter({ show: true });

  const handleOpenDeleteDialog = useCallback((id: number | undefined) => {
    setDeleteId(id);
    setAlertOpen(true);
  }, []);

  const clickCollection = useCallback(
    (item: TCollection) => {
      const params: any = {};
      const paramsArray: any = [];
      item.parameters.forEach(param => {
        if (param.filter_type) {
          params[param.parameter] = { values: param.values };
          paramsArray.push({
            parameter: param.parameter,
            values: param.values,
          });
        }
      });
      const productsFilter: IProductFilter = {
        price_to:
          item.max_price && Number.isInteger(item.max_price) ? item.max_price.toString() : '',
        price_from:
          item.min_price && Number.isInteger(item.min_price) ? item.min_price.toString() : '',
        parameters: params,
      };

      navigate('/products/catalog', {
        state: {
          filter: productsFilter,
          productTypeId: item.product_type_id,
          paramsArray,
        },
      });

      // false
      //   ? history.push(`/collections/edit/${item.id}`)
      //   : history.push('/products/catalog', {
      //     filter: productsFilter,
      //     productTypeId: item.product_type_id,
      //   });
    },
    [me]
  );

  const deleteAction = useCallback(() => {
    setAlertOpen(false);
    if (deleteId) del('/api/shop/collection/', deleteId.toString());
  }, [deleteId]);

  useCrudSnackbar({
    success: delRes,
    error: err || delErr,
    successMessage: fm('COLLECTION.DELETED'),
    errorMessage: err || delErr,
    afterSuccess: reload,
  });

  if (loading) return <Preloader />;

  return (
    <>
      <AlertDialog
        open={isAlertOpen}
        message={intl.formatMessage({ id: 'PRODUCT.TYPES.DELETE.TEXT' })}
        okText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })}
        handleClose={() => {
          // clearDel();
          setAlertOpen(false);
        }}
        handleAgree={deleteAction}
      />

      {me?.is_admin && (
        <Button
          color='primary'
          variant='contained'
          onClick={() => navigate('/collections/create')}
        >
          {fm('ADD.COLLECTION')}
        </Button>
      )}

      <Row>
        <Col>
          {!collections || !collections.length ? (
            <Typography className={classes.empty} component='h5' variant='h5'>
              {fm('COMMON.LIST.EMPTY')}
            </Typography>
          ) : (
            <>
              <Card className={classes.card}>
                <CardContent className={homeClasses.classes.tableContainer}>
                  <Table aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        {me?.is_admin && (
                          <SHeadTableCell>{fm('PRODUCT.TABLE.ID')}</SHeadTableCell>
                        )}
                        <SHeadTableCell>{fm('PRODUCT.TABLE.NAME')}</SHeadTableCell>
                        {/* <SHeadTableCell>{fm('PRODUCTS')}</SHeadTableCell> */}
                        {me?.is_admin && (
                          <SHeadTableCell align='right'>
                            {fm('PRODUCT.TABLE.ACTIONS')}
                          </SHeadTableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {collections.map((item: any) => (
                        <TableRow key={item.id}>
                          {me?.is_admin && (
                            <TableCell component='th' scope='row'>
                              {item.id || '-'}
                            </TableCell>
                          )}
                          <TableCell>
                            <div
                              className={classes.buttons}
                              style={{
                                color: '#369EF5',
                                cursor: 'pointer',
                              }}
                              onClick={() => clickCollection(item)}
                            >
                              {item.name || '-'}
                            </div>
                          </TableCell>
                          {/* <TableCell component='th' scope='row'> */}
                          {/*   {item.products.length} */}
                          {/* </TableCell> */}
                          {me?.is_admin && (
                            <TableCell align='right'>
                              <IconButton
                                color='primary'
                                aria-label='previous'
                                onClick={() => navigate(`/collections/edit/${item.id}`)}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  handleOpenDeleteDialog(item.id);
                                }}
                              >
                                <DeleteIcon color='error' />
                              </IconButton>
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    empty: {
      marginBottom: 20,
      marginTop: 20,
    },
    buttons: {
      marginRight: theme.spacing(3),
    },
    card: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    categoryTree: {
      maxWidth: 400,
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    img: {
      width: 50,
      height: 50,
    },
  })
);

export default CollectionsList;
