import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Card, IconButton, MenuItem, TextField, Theme } from '@mui/material';
import { DialogProps } from '@mui/material/Dialog';
import { createStyles, makeStyles } from '@mui/styles';
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate, useParams } from 'react-router-dom';

import { IAppState } from '../../../../store/rootDuck';
import { setLayoutFooter, setLayoutSubheader } from '../../../../utils/layout';
import ButtonWithLoader from '../../../../components/ui/Buttons/ButtonWithLoader';
import homeStyles from '../../homeStyles';
import useCrudSnackbar from '../../../../hooks/useCrudSnackbar';
import { actions as categoriesActions } from '../../../../store/ducks/categories.duck';
import { actions as bannersActions } from '../../../../store/ducks/banners.duck';
import { useFormatMessage, useTabs } from '../../../../hooks';
import FilesDropzone from '../../../../components/formComponents/FilesDropzone';
import { useCustomFormik } from './hooks/useCustomFormik';
import { Tabs } from './components/Tabs';
import { Container } from './components/Container';
import { TabPanel } from '../../../../components/other/Tab/TabPanel';
import { createChildrenList } from '../../../../utils/createParentList';
import { Modal } from '../../../../components/other/Modals';
import PublicationTable from '../../../../components/tableComponents/Table/PublicationTable';
import { actions as productActions } from '../../../../store/ducks/product.duck';
import { IBanner, Placement, TypeContentBanner } from '../interfaces';
import { API_DOMAIN } from '../../../../constants';
import Preloader from '../../../../components/other/Preloader/Preloader';
import { IProduct } from '../../../../interfaces/product';

interface IField<T> {
  title: string;
  field: T;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actions: {
      marginTop: theme.spacing(3),
    },
  })
);

const EditBannerPage: React.FC<TPropsFromRedux> = ({
  loading,
  success,
  error,
  editBanner,
  clearEditBanner,

  banner,
  bannerLoading,
  bannerError,
  fetchBanner,
  clearFetchBanner,

  fetchCategories,
  categories,
  products,
  page,
  perPage,
  total,
  fetch,
}) => {
  const classes = useStyles();
  const homeClasses = homeStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  const fm = useFormatMessage();

  const [tabValue, changeTab] = useTabs();
  const [image, setImage] = useState<File | null>(null);
  const [openModalProducts, setOpenModalProducts] = useState(false);
  const inputRef = useRef<HTMLDivElement>(null);
  const [typeContent, setTypeContent] = useState<TypeContentBanner | null>(null);

  const { values, handleBlur, handleChange, errors, touched, handleSubmit, setFieldValue } =
    useCustomFormik({
      id: id || '',
      data: banner as IBanner,
      submit: editBanner,
      image,
    });

  const FIELDS: IField<keyof typeof values>[] = [
    { title: fm('BANNERS.CREATE.TITLE'), field: 'title' },
    { title: fm('description'), field: 'description' },
    // { title: fm('BANNERS.CREATE.EXTERNAL_LINK'), field: 'url' },
  ];

  setLayoutSubheader({
    title: fm('BANNER.EDIT.TITLE'),
  });

  useCrudSnackbar({
    success,
    error,
    clear: clearEditBanner,
    successMessage: fm('SUCCESS'),
    errorMessage: `${fm('ERROR')}: ${error}`,
    afterSuccess: () => navigate('/banners/list'),
  });

  setLayoutFooter({ show: true });

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (banner && banner?.product) {
      setTypeContent(TypeContentBanner.PRODUCT);
    } else {
      setTypeContent(TypeContentBanner.LINK);
    }
  }, [banner]);

  useEffect(() => {
    if (id) {
      fetchBanner({ id: +id });
    }
    return () => {
      clearFetchBanner();
      clearEditBanner();
    };
  }, [id]);

  useEffect(() => {
    if (bannerError) {
      navigate('/banners/list');
    }
  }, [bannerError]);

  useEffect(() => {
    fetch({ page, perPage, categoryId: Number(values.category_id) || 0 });
  }, [values.category_id]);

  const handleConnectProduct = useCallback(
    (productId: IProduct) => {
      setOpenModalProducts(false);
      setFieldValue('product_id', productId.id);
      setFieldValue('product_name', productId.name);
    },
    [setFieldValue]
  );

  const handleConnectsProduct = useCallback(() => {
    setOpenModalProducts(false);
  }, []);

  if (bannerLoading) {
    return <Preloader />;
  }

  return (
    <Container>
      <Tabs tabValue={tabValue} changeTab={changeTab} />
      <form onSubmit={handleSubmit} className={homeClasses.classes.form} autoComplete='off'>
        <TabPanel value={tabValue} index={0}>
          {FIELDS.map(item => {
            return (
              <TextField
                disabled={item.field === 'url' && !!values.category_id}
                key={item.field}
                type='text'
                label={item.title}
                margin='normal'
                name={item.field}
                value={values[item.field]}
                multiline={item.field === 'description'}
                rows={item.field === 'description' ? 3 : 0}
                variant='outlined'
                onBlur={handleBlur}
                onChange={handleChange}
                helperText={touched[item.field] && errors[item.field]}
                error={Boolean(touched[item.field] && errors[item.field])}
                InputProps={{
                  endAdornment: item.field === 'url' && values[item.field] !== '' && (
                    <IconButton onClick={() => setFieldValue('url', '')}>
                      <ClearIcon />
                    </IconButton>
                  ),
                }}
              />
            );
          })}
          <TextField
            select
            label={fm('TENDER.TYPE.PLACEMENT')}
            margin='normal'
            name='placement'
            value={values.placement}
            variant='outlined'
            onBlur={handleBlur('placement')}
            onChange={handleChange}
          >
            <MenuItem key={Placement.TOP} value={Placement.TOP}>
              {fm(Placement.TOP)}
            </MenuItem>
            <MenuItem key={Placement.MID} value={Placement.MID}>
              {fm(Placement.MID)}
            </MenuItem>
            <MenuItem key={Placement.BOTTOM} value={Placement.BOTTOM}>
              {fm(Placement.BOTTOM)}
            </MenuItem>
          </TextField>
          <TextField
            select
            label={fm('TYPE.CONTENT.BANNER')}
            margin='normal'
            value={typeContent}
            variant='outlined'
            onChange={event => {
              setTypeContent(event.target.value as TypeContentBanner);
            }}
          >
            <MenuItem key={TypeContentBanner.LINK} value={TypeContentBanner.LINK}>
              {fm('BANNERS.CREATE.EXTERNAL_LINK')}
            </MenuItem>
            <MenuItem key={TypeContentBanner.PRODUCT} value={TypeContentBanner.PRODUCT}>
              {fm('BANNERS.CREATE.PRODUCT_LINK')}
            </MenuItem>
          </TextField>
          {typeContent === TypeContentBanner.PRODUCT ? (
            <>
              <TextField
                select
                label={fm('PRODUCT.EDIT.CATEGORY')}
                margin='normal'
                name='category_id'
                value={values.category_id || ''}
                variant='outlined'
                onBlur={handleBlur('category_id')}
                onChange={handleChange}
              >
                {categories &&
                  createChildrenList(categories, '\u00B7').map(cat => (
                    <MenuItem key={cat.id?.toString()} value={cat.id?.toString()}>
                      {cat.name}
                    </MenuItem>
                  ))}
              </TextField>
              <TextField
                label={fm('PRODUCT.SNACKBAR.TITLE')}
                margin='normal'
                name='product_name'
                variant='outlined'
                value={values.product_name || ''}
                disabled={!values.category_id}
                onClick={() => {
                  if (!values.product_id) {
                    setOpenModalProducts(true);
                  }
                }}
                ref={inputRef}
                InputProps={{
                  endAdornment: values.product_name !== '' && (
                    <IconButton
                      onClick={() => {
                        setFieldValue('product_name', '');
                        setFieldValue('category_id', '');
                        setFieldValue('product_id', '');
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  ),
                }}
              />
            </>
          ) : (
            <TextField
              key='url'
              type='text'
              label={fm('BANNERS.CREATE.EXTERNAL_LINK')}
              margin='normal'
              name='url'
              value={values.url}
              variant='outlined'
              onBlur={handleBlur}
              onChange={handleChange}
              helperText={touched.url && errors.url}
              error={Boolean(touched.url && errors.url)}
            />
          )}
          <div className={classes.actions}>
            <ButtonWithLoader
              disabled={loading}
              loading={loading}
              onPress={() => {
                if (typeContent === TypeContentBanner.PRODUCT) {
                  setFieldValue('url', '');
                } else {
                  setFieldValue('product_name', '');
                  setFieldValue('category_id', '');
                  setFieldValue('product_id', '');
                }
                handleSubmit();
                setFieldValue('tabValue', 0);
              }}
            >
              {fm('COMMON.BUTTON.SAVE')}
            </ButtonWithLoader>
          </div>
          <Modal
            DialogProps={{ maxWidth: 'md' } as DialogProps}
            open={openModalProducts}
            onClose={() => setOpenModalProducts(false)}
            title={fm('BANNERS.LINK')}
            content={
              <PublicationTable
                onConnect={productId => handleConnectProduct(productId)}
                products={products || []}
                onConnectOff={() => handleConnectsProduct()}
                values={values}
                fetch={({ page, perPage }) => fetch({ perPage, page })}
                page={page}
                perPage={perPage}
                total={total}
              />
            }
            actions={[
              {
                title: 'Закрыть',
                onClick: () => setOpenModalProducts(false),
              },
            ]}
          />
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <div
            style={{
              padding: 40,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Card style={{ marginBottom: 25 }}>
              <img
                src={
                  image
                    ? URL.createObjectURL(image)
                    : `${API_DOMAIN}/${
                        banner?.photo?.big || banner?.photo?.small || banner?.photo?.path
                      }`
                }
                alt=''
                style={{ maxWidth: 550, maxHeight: 500 }}
              />
            </Card>
            <FilesDropzone
              uploadFiles={(files: File[]) => {
                setImage(files[0]);
                changeTab({} as React.ChangeEvent<{}>, 0);
              }}
              avaliableNumberOfFilesToUpload={1}
              title={fm('COMMON.PHOTO.LOAD')}
              withCrop
              cropAspect={3 / 1.5}
            />
          </div>
        </TabPanel>
      </form>
    </Container>
  );
};

const connector = connect(
  (state: IAppState) => ({
    loading: state.banners.editBannerLoading,
    success: state.banners.editBannerSuccess,
    error: state.banners.editBannerError,

    categories: state.categories.categories,

    banner: state.banners.banner,
    banners: state.banners.banners,
    bannerLoading: state.banners.fetchBannerLoading,
    bannerSuccess: state.banners.fetchBannerSuccess,
    bannerError: state.banners.fetchBannerError,

    deleteLoading: state.banners.deleteBannerLoading,
    deleteSuccess: state.banners.deleteBannerSuccess,
    deleteError: state.banners.deleteBannerError,

    products: state.products.products,
    productLoading: state.products.loading,
    page: state.products.page,
    perPage: state.products.per_page,
    total: state.products.total,
  }),
  {
    editBanner: bannersActions.editBannerRequest,
    clearEditBanner: bannersActions.clearEdiBanner,

    fetchBanner: bannersActions.fetchBannerRequest,

    clearFetchBanner: bannersActions.clearFetchBanner,
    fetchCategories: categoriesActions.fetchFullRequest,

    deleteBanner: bannersActions.deleteBannerRequest,

    clearCreateBanner: bannersActions.clearCreateBanner,
    fetch: productActions.fetchRequest,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EditBannerPage);
