import React, { CSSProperties } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { DialogProps } from '@mui/material/Dialog';

interface IAlertDialog extends DialogProps {
  handleAgree: any;
  handleClose: any;
  okText?: string;
  cancelText?: string;
  message?: string;
  title?: string;
  styleActions?: CSSProperties;
}

const AlertDialog: React.FC<IAlertDialog> = ({
  open,
  handleClose,
  handleAgree,
  okText = 'OK',
  cancelText = 'Cancel',
  message = '',
  title = '',
  styleActions,
}) => (
  <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby='alert-dialog-title'
    aria-describedby='alert-dialog-description'
  >
    <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id='alert-dialog-description'>{message}</DialogContentText>
    </DialogContent>
    <DialogActions style={styleActions}>
      <Button onClick={handleAgree} color='primary'>
        {okText}
      </Button>
      <Button onClick={handleClose} color='primary' autoFocus>
        {cancelText}
      </Button>
    </DialogActions>
  </Dialog>
);

export default AlertDialog;
