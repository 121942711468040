import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { getResponseMessage } from '../../../../../utils/utils';
import { pushUser } from '../../../../../crud/users.crud';

export const usePushUser = () => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [loadingPush, setLoading] = useState(false);
  const [successPush, setSuccess] = useState(false);
  const push = useCallback(async (user_id: number, platform: string, message: string) => {
    setLoading(true);
    pushUser({ user_id, platform, message })
      .then(() => {
        setSuccess(true);
        enqueueSnackbar(`${intl.formatMessage({ id: 'USER.PUSH.DEVICE' })}`, {
          variant: 'success',
        });
      })
      .catch(e => {
        enqueueSnackbar(`${intl.formatMessage({ id: 'ERROR' })}: ${getResponseMessage(e)}`, {
          variant: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
        setSuccess(false);
      });
  }, []);
  return { push, loadingPush, successPush };
};
