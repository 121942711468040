import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useResolvedPath } from 'react-router-dom';

import * as builder from '../ducks/builder';
import { IAppState } from '../../app/store/rootDuck';

type TProps = {
  children: React.ReactNode;
};

const KtContent: React.FC<TProps> = ({ children }) => {
  const url = useResolvedPath('').pathname;

  const { contentContainerClasses } = useSelector((store: IAppState) => ({
    contentContainerClasses: builder.selectors.getClasses(store, {
      path: 'content_container',
      toString: true,
    }),
  }));

  const animationEndClass = 'kt-grid--animateContent-finished';
  const [cssClassesState, setCssClassesState] = useState([
    'kt-grid--animateContent',
    'kt-container',
    contentContainerClasses.split(' '),
    'kt-grid__item kt-grid__item--fluid',
    animationEndClass,
  ]);

  useEffect(() => {
    const fullClasses = [...cssClassesState];
    const startAnimation = fullClasses.filter(el => el !== animationEndClass);
    setCssClassesState(startAnimation);
    const timeOutId = setTimeout(() => {
      setCssClassesState(fullClasses);
    }, 200);

    return () => {
      clearTimeout(timeOutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return <div className={cssClassesState.join(' ')}>{children}</div>;
};

export default KtContent;
