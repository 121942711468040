import React from 'react';
import { Route, Routes } from 'react-router-dom';
import SearchList from './SearchList';
import SearchPage from './SearchPage';
import SearchView from './SearchView';

const TenderPages = () => (
  <Routes>
    <Route path='list' element={<SearchList />} />
    <Route path='create' element={<SearchPage />} />
    <Route path='view/:id' element={<SearchView />} />
    <Route path='edit/:id' element={<SearchPage />} />
  </Routes>
);

export default TenderPages;
