import React from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';
import DeliveryList from './components/DeliveryList';
import { ListType } from './constatns';

const DeliveryListPage: React.FC = () => {
  const { type } = useParams();
  const intl = useIntl();
  if (type === ListType.FULL) {
    setLayoutSubheader({
      title: intl.formatMessage({ id: 'SUBMENU.ALLDELIVERIES' }),
    });
  }
  if (type === ListType.ABANDONED)
    setLayoutSubheader({
      title: intl.formatMessage({ id: 'SUBMENU.ABANDONEDCHECKOUTS' }),
    });
  if (type === ListType.HISTORY)
    setLayoutSubheader({
      title: intl.formatMessage({ id: 'SUBMENU.DELIVERY.HISTORY' }),
    });
  setLayoutFooter({ show: true });

  return <DeliveryList type={type || ''} smallSendMsgBtn />;
};

export default DeliveryListPage;
