import React from 'react';
import { Routes, Route } from 'react-router-dom';
import DashboardComponentList from './DashboardComponent';

export default function DashboardPage() {
  return (
    <Routes>
      <Route path='list' element={<DashboardComponentList />} />
    </Routes>
  );
}
