import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { addParameterImages } from '../../../../crud/product.crud';
import { getResponseMessage } from '../../../../utils/utils';
import { useFormatMessage } from '../../../../hooks';

export const useAddIconParam = (successFunc?: () => void) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loadingUploadIcons, setLoading] = useState(false);
  const fm = useFormatMessage();

  const fetchUploadIcons = useCallback(
    async (files: { id: number; value: string; file: File }[]) => {
      setLoading(true);
      const promises: Promise<any>[] = [];
      files.forEach(i => {
        const formData = new FormData();
        formData.append('value', i.value);
        formData.append('image', i.file);
        const data = addParameterImages(i.id, formData);
        promises.push(data);
      });
      Promise.all(promises)
        .then(() => {
          setLoading(false);
          successFunc && successFunc();
        })
        .catch(e => {
          setLoading(false);
          enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`, {
            variant: 'error',
          });
        });
    },
    [successFunc]
  );

  const fetchDeleteIcon = useCallback(
    async (id: number, data: FormData) => {
      setLoading(true);
      addParameterImages(id, data)
        .then(() => {
          setLoading(false);
          successFunc && successFunc();
        })
        .catch(e => {
          setLoading(false);
          enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`, {
            variant: 'error',
          });
        });
    },
    [successFunc]
  );

  return { fetchUploadIcons, loadingUploadIcons, fetchDeleteIcon };
};
