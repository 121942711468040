import { useFormik } from 'formik';
import * as Yup from 'yup';

const getInitValues = (): {
  priceUp: string;
  priceDown: string;
  roundingType: string;
  amountUp: string;
  amountDown: string;
} => {
  return {
    priceUp: '',
    amountDown: '',
    amountUp: '',
    priceDown: '',
    roundingType: '2',
  };
};

export const useFormikEditPricing = () => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitValues(),
    validationSchema: Yup.object().shape({
      //   price: Yup.string().required(fm('PRODUCT.REQUIRED.PRICE.COMMON')).trim(),
    }),
    onSubmit: () => {},
  });

  return formik;
};
