import React from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import ColorDot from './ColorDot';
import { useFormatMessage } from '../../hooks';

interface IProps {
  colors: string[];
}

const useStyles: any = makeStyles((theme: Theme) => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    border: '1px solid rgba(0, 0, 0, 0.26)',
    borderRadius: '4px',
  },

  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const TrafficLight: React.FC<IProps> = ({ colors }) => {
  const classes = useStyles();
  const fm = useFormatMessage();

  return (
    <div className={classes.box}>
      <div>
        <b>{fm('COMPANY.FORM.COMPANY.CHECK')}</b>
      </div>
      {!colors.length ? (
        <div>{fm('COMPANY.FORM.COMPANY.CHECK.NO')}</div>
      ) : (
        colors.map((item, i) => (
          <div className={classes.flexRow} key={i} style={{ marginTop: 4 }}>
            <ColorDot color={item} />
            <div>{fm(`COMPANY.FORM.COMPANY.CHECK.${item}`)}</div>
          </div>
        ))
      )}
    </div>
  );
};

export default TrafficLight;
