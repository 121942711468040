import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createProduct } from '../../../../crud/product.crud';
import { getResponseMessage } from '../../../../utils/utils';
import { useFormatMessage } from '../../../../hooks';
import { useAddProductToTender } from '../../tenders/hooks/useAddProductToTender';

export const useCreateRiskToTender = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loadindCreate, setLoading] = useState(false);
  const [err, setErr] = useState<any>(null);
  const [successCreate, setSuccess] = useState(false);
  const dispatch = useDispatch();
  const fm = useFormatMessage();
  const { addRisk, successAdd } = useAddProductToTender();
  const createRisk = useCallback(
    async (data: { data: FormData }, id: number) => {
      setLoading(true);
      createProduct(data.data)
        .then((res: any) => {
          addRisk(res.data.data.id, id);
        })
        .catch(e => {
          setLoading(false);
          enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`, { variant: 'error' });
        })
        .finally(() => {
          setErr(null);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    if (successAdd) {
      setLoading(false);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 100);
    }
  }, [successAdd]);

  useEffect(() => {
    err && setLoading(false);
  }, [err]);

  return { createRisk, loadindCreate, successCreate };
};
