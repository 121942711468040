import React from 'react';
import { Route, Routes } from 'react-router-dom';
import BannersListPage from './BannersListPage';
import CreateBannerPage from './CreateBannerPage/CreateBannerPage';
import EditBannerPage from './EditBannerPage/EditBannerPage';

export default function BannersPage() {
  return (
    <Routes>
      <Route path='list' element={<BannersListPage />} />
      <Route path='create' element={<CreateBannerPage />} />
      <Route path='edit/:id' element={<EditBannerPage />} />
    </Routes>
  );
}
