import { useEffect, useState } from 'react';
import { TChannel } from '../../../../../interfaces/chat';
import { getTimePastFromNow } from '../../../../../utils/utils';
import { useRerenderComponentEach } from './useRerenderComponent';

export const useGetChatsWithInfo = (channels: TChannel[] | null) => {
  // rerender component each n milliseconds, need to count time since last post
  const [rerenderCount] = useRerenderComponentEach(1000);
  const [channelsWithInfo, setChannelsWithInfo] = useState<any>([]);

  // add info to channels and sort them
  useEffect(() => {
    if (channels) {
      const newChannels = channels
        .sort((a, b) => b.last_post_at - a.last_post_at)
        .map(el => {
          return {
            ...el,
            timeSinceLastPost: getTimePastFromNow(el.last_post_at),
            hasDeliveredNotifications: false,
          };
        });
      setChannelsWithInfo(newChannels);
    }
  }, [channels, rerenderCount]);

  return [channelsWithInfo, rerenderCount];
};
