import React, { CSSProperties, useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { makeStyles } from 'tss-react/mui';
import Close from '@mui/icons-material/Close';
import { useIntl } from 'react-intl';

const useStyles = makeStyles()(() => {
  return {
    root: {
      // marginBottom: 8,
      width: '100%',
    },
    input: {
      width: '100%',
      padding: '16px 15px',
      borderWidth: 0,
      borderRadius: 4,
      // fontSize: 17,
      fontWeight: 400,
      outline: 'none',
      boxSizing: 'border-box',
    },
    ul: {
      width: '100%',
      listStyle: 'none',
      margin: 0,
      padding: 0,
      overflow: 'hidden',
      overflowY: 'auto',
      position: 'absolute',
      flex: 1,
      backgroundColor: '#fff',
      zIndex: 1111,
      borderWidth: 1,
      borderRadius: 4,
    },
    li: {
      textDecoration: 'none',
      padding: '5px 7px',
      '&:hover': {
        backgroundColor: 'rgb(0, 90, 90, 0.1)',
      },
      backgroundColor: '#fff',
      zIndex: 1111,
      cursor: 'pointer',
      color: 'black',
    },
  };
});

interface IProps {
  value: { address: string };
  error?: boolean;
  helperText?: string;
  setSelectedLocation: (data: { address: string; lat: number; lng: number }) => void;
  disabled?: boolean;
  isDelivery?: boolean;
  lenghtAddress?: number;
  marginTop?: number;
  marginBottom?: number;
  title?: string;
  billingAddress?: boolean;
  handleChange?: any;
  disableMargin?: boolean;
  styleField?: CSSProperties;
  widthList?: number | string;
  styleRoot?: CSSProperties;
}

const AutocompleteCopy: React.FC<IProps> = ({
  value,
  setSelectedLocation,
  error,
  disabled,
  lenghtAddress = 40,
  marginTop = 20,
  marginBottom = 8,
  title,
  billingAddress = false,
  handleChange,
  disableMargin,
  styleField,
  widthList,
  styleRoot,
}) => {
  const intl = useIntl();
  const classes1 = useStyles();
  const { classes } = classes1;

  const [filteredSuggestions, setFilteredSuggestions] = useState<any[]>([]);
  const [selectedValue, setSelectedValue] = useState(value.address || '');
  const [isSuggestionsClicked, setIsSuggestionsClicked] = useState(false);

  useEffect(() => {
    value.address && setSelectedValue(value.address);
  }, [value]);

  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    usePlacesService({
      apiKey: 'AIzaSyB_rjiR94heML-tlGsexMmxwtpVQ7WlcxI',
      language: intl.locale === 'cz' ? 'cs' : intl.locale,
    });

  const handleInputChange = async (inputValue: any) => {
    setSelectedValue(inputValue);
    if (!inputValue) {
      setFilteredSuggestions([]);
      getPlacePredictions({
        input: '',
        types: ['geocode', 'establishment'],
      });
      return;
    }
    getPlacePredictions({
      input: inputValue,
      types: ['geocode', 'establishment'],
    });
  };

  useEffect(() => {
    if (placePredictions.length) {
      Promise.all(
        placePredictions.map((prediction: any) => {
          return new Promise(resolve => {
            placesService?.getDetails(
              {
                placeId: prediction.place_id,
                fields: [
                  'utc_offset_minutes',
                  'name',
                  'formatted_address',
                  'place_id',
                  'geometry',
                  'address_components',
                ],
              },
              (placeDetails: any, status: string) => {
                if (status !== 'OK') return;
                resolve({
                  ...placeDetails,
                  ...prediction,
                });
              }
            );
          });
        })
      ).then(result => {
        setFilteredSuggestions(result);
      });
    }
  }, [placePredictions]);

  return (
    <div className={classes.root} style={{ marginTop, marginBottom, ...styleRoot }}>
      {disabled ? (
        <TextField
          type='text'
          value={
            value.address ||
            intl.formatMessage({
              id: billingAddress ? 'STORE.INPUT.BILLING.ADDRESS' : 'STORE.INPUT.LOCATION',
            })
          }
          variant='outlined'
          margin={disableMargin ? 'none' : 'normal'}
          label={
            title ||
            intl.formatMessage({
              id: billingAddress ? 'STORE.INPUT.BILLING.ADDRESS' : 'STORE.INPUT.LOCATION',
            })
          }
          style={styleField}
          disabled
        />
      ) : (
        <>
          <TextField
            type='text'
            onChange={e => handleInputChange(e.target.value)}
            variant='outlined'
            margin={disableMargin ? 'none' : 'normal'}
            value={selectedValue}
            onFocus={e => {
              handleInputChange(e.target.value);
            }}
            onBlur={() => {
              if (!isSuggestionsClicked) {
                setFilteredSuggestions(() => []);
              }
            }}
            style={styleField}
            error={error}
            label={
              title ||
              intl.formatMessage({
                id: billingAddress ? 'STORE.INPUT.BILLING.ADDRESS' : 'STORE.INPUT.LOCATION',
              })
            }
            InputProps={{
              endAdornment: !isPlacePredictionsLoading && (
                <Close
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    handleChange &&
                      handleChange({
                        target: {
                          name: billingAddress ? 'locationId2' : 'locationId',
                          value: 0,
                        },
                      });
                    setSelectedValue('');
                    setSelectedLocation({
                      address: '',
                      lat: 0,
                      lng: 0,
                    });
                  }}
                />
              ),
            }}
          />
          {filteredSuggestions && (
            <ul className={classes.ul} style={{ width: widthList || '100%' }}>
              {filteredSuggestions?.map((place: any) => {
                return (
                  <li
                    onMouseDown={() => {
                      setIsSuggestionsClicked(() => true);
                      setSelectedValue(place.formatted_address);
                      if (place?.geometry?.location) {
                        setSelectedLocation({
                          address: place.formatted_address,
                          lat: place.geometry.location.lat(),
                          lng: place.geometry.location.lng(),
                        });
                      }
                      setFilteredSuggestions([]);
                      setIsSuggestionsClicked(() => false);
                    }}
                    className={classes.li}
                    key={place.place_id}
                  >
                    {place.formatted_address.length > lenghtAddress
                      ? place.formatted_address.slice(0, lenghtAddress) + '...'
                      : place.formatted_address}
                  </li>
                );
              })}
            </ul>
          )}

          {/* {!!helperText && ( */}
          {/*  <FormHelperText style={{ margin: '3px 14px 0px' }} error={error}> */}
          {/*    {helperText} */}
          {/*  </FormHelperText> */}
          {/* )} */}
        </>
      )}
    </div>
  );
};

export default AutocompleteCopy;
