import axios from 'axios';

export const buyTariff = (price: any) =>
  axios.get(`/api/tinkoff/pay`, { params: { amount: price } });

export const editUserTariff = (id: number, days: number) =>
  axios.post(`/api/user_tariff/${id}`, undefined, { params: { days } });

export const editCompanyTariff = (id: number, days: number) =>
  axios.post(`/api/company_tariff/${id}`, undefined, { params: { days } });
