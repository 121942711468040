import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import AllPublicationsPage from './AllPublications';
import MyPublicationsPage from './MyPublications';
import CreatePublicationPage from './CreatePublication';
import EditPublicationPage from './EditPublication';
import ViewPublicationPage from './ViewPublication';
import { IAppState } from '../../../store/rootDuck';
import ProtectedRoute from '../../../router/Routes';

export default function BlogPage() {
  const { isAuthorized } = useSelector(({ auth }: IAppState) => ({
    isAuthorized: auth.user != null,
  }));

  return (
    <Routes>
      <Route path='all' element={<AllPublicationsPage />} />
      <Route
        path='my'
        element={
          <ProtectedRoute isAuthorized={isAuthorized} outlet={<MyPublicationsPage />} />
        }
      />
      <Route
        path='create'
        element={
          <ProtectedRoute isAuthorized={isAuthorized} outlet={<CreatePublicationPage />} />
        }
      />
      <Route
        path='edit/:id'
        element={
          <ProtectedRoute isAuthorized={isAuthorized} outlet={<EditPublicationPage />} />
        }
      />
      <Route path='view/:id' element={<ViewPublicationPage />} />
    </Routes>
  );
}
