import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { getPostsForChannel } from '../../../../../crud/chat.crud';
import { getResponseMessage } from '../../../../../utils/utils';
import { actions as chatActions } from '../../../../../store/ducks/chat.duck';
import ChatSocket from '../../../../../utils/socket';

export const useGetChatMessages: any = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState<any>(null);
  const [success, setSuccess] = useState(false);

  const fetch = useCallback(async (channelId: string) => {
    await ChatSocket.getMyAuthToken();
    setLoading(true);
    getPostsForChannel(channelId, 0)
      .then(async (res: any) => {
        dispatch(
          chatActions.setChat({
            ...res.data,
            channelId,
          })
        );
      })
      .catch(e => {
        enqueueSnackbar(`${intl.formatMessage({ id: 'ERROR' })}: ${getResponseMessage(e)}`, {
          variant: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
        setErr(null);
        setSuccess(false);
      });
  }, []);
  return [fetch, loading, success, err];
};
