import React from 'react';
import { useIntl } from 'react-intl';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  Avatar,
  Tooltip,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import EditIcon from '@mui/icons-material/Edit';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import LinkIcon from '@mui/icons-material/Link';

import { SHeadTableCell } from '../../styledComponents/Tables';
import TablePaginator from '../TablePaginator/TablePaginator';
import Preloader from '../../other/Preloader/Preloader';

import { IUser } from '../../../interfaces/user';
import { IPaginationData } from '../../../interfaces/server';
import { getRoleName } from '../../../pages/home/users/users/utils';
import NoRowsOverlay from './NoRowsOverlay';
import { API_DOMAIN } from '../../../constants';

interface IProps {
  users: IUser[];
  loading: boolean;
  onEdit: (id: number) => any;
  onConnectOff?: (id: number) => any;
  onConnect?: (id: number) => any;
  connectedUsers?: number[];
  paginationData: IPaginationData;
  fetch?: (data: { page: number; perPage: number }) => void;
  buyerID?: number;
}

const useStyles = makeStyles()(() => {
  return {
    empty: {
      height: 200,
    },
    table: {
      marginTop: 20,
    },
    emptyOverlay: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    companyNameAvatarWrapper: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
    },
    green: {
      color: '#fff',
      backgroundColor: '#566DC6FF',
    },
    emptyCompany: {
      width: 40,
      height: 40,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };
});

const UsersBuyerTable: React.FC<IProps> = ({
  users,
  loading,
  paginationData: { page, perPage, total },
  onConnectOff,
  onConnect,
  onEdit,
  fetch,
}) => {
  const intl = useIntl();
  const classes1 = useStyles();
  const { classes } = classes1;

  if (loading) return <Preloader />;

  return (
    <Table aria-label='stores' className={classes.table}>
      <TableHead>
        <TableRow>
          <SHeadTableCell>{intl.formatMessage({ id: 'USER.TABLE.ID' })}</SHeadTableCell>
          <SHeadTableCell style={{ textAlign: 'center' }}>
            {intl.formatMessage({ id: 'SETTING.PROFILE.PHOTO' })}
          </SHeadTableCell>
          <SHeadTableCell>{intl.formatMessage({ id: 'USER.TABLE.LOGIN' })}</SHeadTableCell>
          <SHeadTableCell>{intl.formatMessage({ id: 'USER.TABLE.EMAIL' })}</SHeadTableCell>
          <SHeadTableCell>{intl.formatMessage({ id: 'USER.TABLE.ROLE' })}</SHeadTableCell>
          <SHeadTableCell>{intl.formatMessage({ id: 'USER.TABLE.FIO' })}</SHeadTableCell>
          <SHeadTableCell>{intl.formatMessage({ id: 'USER.TABLE.COMPANY' })}</SHeadTableCell>
          <SHeadTableCell align='right'>
            {intl.formatMessage({ id: 'USER.TABLE.ACTION' })}
          </SHeadTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {loading && (
          <TableRow>
            <TableCell className={classes.empty} colSpan={6} component='th' scope='row'>
              <Preloader />
            </TableCell>
          </TableRow>
        )}

        {!loading && !users.length && (
          <TableRow>
            <TableCell className={classes.empty} colSpan={6} component='th' scope='row'>
              <div className={classes.emptyOverlay}>
                <NoRowsOverlay label={intl.formatMessage({ id: 'MANAGER.EMPTY' })} />
              </div>
            </TableCell>
          </TableRow>
        )}

        {users.map(item => (
          <TableRow key={item.id}>
            <TableCell component='th' scope='row'>
              {item.id}
            </TableCell>
            <TableCell>
              {item.avatar ? (
                <Avatar
                  style={{ margin: '0 auto' }}
                  src={`${API_DOMAIN}/${
                    typeof item.avatar === 'string' ? item.avatar : item.avatar.small
                  }`}
                  className={classes.green}
                />
              ) : (
                <div className={classes.companyNameAvatarWrapper}>
                  <span
                    className='kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success'
                    style={{
                      width: 40,
                      height: 40,
                      borderRadius: 20,
                      backgroundColor: '#566DC6FF',
                    }}
                  >
                    <b>{(item.first_name ? item.first_name.trim()[0] : '').toUpperCase()}</b>
                  </span>
                </div>
              )}
            </TableCell>
            <TableCell>{item.login}</TableCell>
            <TableCell>{item.email}</TableCell>
            <TableCell>{getRoleName(item.roles, intl)}</TableCell>
            <TableCell>
              {`${item.last_name || ''} ${item.first_name || ''} ${item.middle_name || ''}`}
            </TableCell>
            <TableCell>
              {item.company ? (
                <Tooltip title={item.company?.name} aria-label={item.company?.name}>
                  <Avatar className={classes.green} alt={item.company?.name}>
                    {item.company?.name.slice(0, 1)}
                  </Avatar>
                </Tooltip>
              ) : (
                <div className={classes.emptyCompany}>-</div>
              )}
            </TableCell>
            <TableCell>
              <IconButton
                color='primary'
                aria-label='previous'
                onClick={() => onEdit(item.id)}
              >
                <EditIcon />
              </IconButton>

              {onConnectOff && !onConnect && (
                <IconButton onClick={() => onConnectOff(item.id)}>
                  <LinkOffIcon color='error' />
                </IconButton>
              )}

              {onConnect && (
                <IconButton onClick={() => onConnect(item.id)}>
                  <LinkIcon color='primary' />
                </IconButton>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      {!!fetch && (
        <TableFooter>
          <TableRow>
            <TablePaginator
              page={page}
              perPage={perPage}
              total={total}
              realPerPage={users.length}
              fetchRows={fetch}
              label={intl.formatMessage({ id: 'BUYERS.TABLE.PER_PAGE' })}
            />
          </TableRow>
        </TableFooter>
      )}
    </Table>
  );
};

export default UsersBuyerTable;
