import { createStyles, makeStyles } from '@mui/styles';

export const useStylesSearchPage = makeStyles((theme: any) => ({
  card: {
    padding: '21px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
    },
  },
  actions: {
    marginTop: 15,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  deleteBtns: {
    color: '#0685d1',
    borderColor: '#0685d1',
  },
  buttons: {
    marginRight: theme.spacing(2),
  },
  switch: {
    marginLeft: theme.spacing(1),
  },
  companyField: {
    display: 'flex',
    alignItems: 'center',
  },
  companySearch: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: 24,
    maxWidth: 800,
    paddingBottom: 0,
  },
  allCompaniesBtn: {
    marginTop: 8,
    marginLeft: 10,
    height: 52,
  },
  field: {
    marginTop: 24,
    marginBottom: 0,
  },
  tabs: {
    width: '100%',
    maxWidth: 728,
    padding: '0 24px',
    marginTop: 30,
  },
  labelTab: {
    // fontSize: 18,
    padding: 0,
    fontWeight: 600,
    minHeight: 0,
  },
  labelTextFieldShrink: {
    color: '#5e6278',
  },
  fontNoLang: {
    fontWeight: 200,
  },
  labelText: {
    fontWeight: 500,
  },
}));

export const useStylesSearchView = makeStyles((theme: any) =>
  createStyles({
    editor: {
      boxShadow: 'initial !important',
      padding: '0 !important',
    },
    titleVendors: {
      // fontSize: 20,
      fontWeight: 600,
      marginBottom: 10,
      margin: '0 7.5px',
    },
    container: {
      padding: '0',
      [theme.breakpoints.up('md')]: {
        padding: '0 100px',
      },
    },
    card: {
      padding: '21px',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-start',
      },
    },
    leftCol: {
      maxWidth: '690px',
      [theme.breakpoints.up('md')]: {
        marginRight: '50px',
      },
    },
    rightCol: {
      width: '100%',

      [theme.breakpoints.up('md')]: {
        width: '36%',
      },
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
    },
    name: {
      // fontSize: 34,
      fontWeight: 600,
      margin: 0,

      [theme.breakpoints.up('md')]: {
        // fontSize: 34,
        lineHeight: 1.2,
      },
    },
    price: {
      lineHeight: '36px',
      color: theme.palette.primary.main,
      // fontSize: 34,
      fontWeight: 600,

      [theme.breakpoints.up('md')]: {
        // fontSize: 34,
        margin: '25px 0',
      },
    },
    whatsAppButton: {
      width: '100%',
      height: 42,
      padding: '12px 20px',
      borderRadius: 4,
      backgroundColor: '#25d366',
      // fontSize: 14,
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'center',
      color: '#fff',
      display: 'inline-block',
      marginTop: 20,

      '&:hover': {
        color: '#fff',
      },

      [theme.breakpoints.up('md')]: {
        width: 215,
      },
    },
    listItem: {
      marginBottom: 15,
    },
    listValue: {
      // fontSize: 17,
      fontWeight: 700,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#000000',
      whiteSpace: 'break-spaces',
      margin: 0,
    },
    listValueImportant: {
      // fontSize: 17,
      fontWeight: 700,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: theme.palette.primary.main,
      whiteSpace: 'break-spaces',
      margin: 0,
    },
    listKey: {
      marginBottom: 0,
      // fontSize: 17,
      fontWeight: 400,
      color: '#757575',
    },
    companyCard: {
      padding: '20px 25px',
      borderRadius: '4px',
      backgroundColor: 'rgba(208, 28, 31, 0.1)',
      marginTop: 50,
    },
    description: {
      // fontSize: 17,
      fontWeight: 400,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: theme.palette.primary.main,
      whiteSpace: 'break-spaces',
      margin: 0,
    },
    tabs: {
      width: '100%',
      maxWidth: 728,
      padding: '0 24px',
      marginTop: 30,
    },
    labelTab: {
      // fontSize: 18,
      padding: 0,
      fontWeight: 600,
      minHeight: 0,
    },
    rub: {
      // fontSize: 17,
    },
    category: {
      // fontSize: 17,
      lineHeight: '18px',
      fontWeight: theme.typography.fontWeightRegular,
      margin: 0,
      color: '#757575',
      marginBottom: 16,
    },
    categoryName: {
      // fontSize: 17,
      lineHeight: '18px',
      fontWeight: 600,
      margin: 0,
    },
    comment: {
      // fontSize: 17,
      lineHeight: '23px',
      fontWeight: theme.typography.fontWeightRegular,
      margin: 0,
      color: '#000000',
      marginTop: 29,
    },
    btnAdd: {
      height: 50,
      width: 350,
      borderRadius: 4,
      cursor: 'pointer',
      // backgroundColor: '#d01c1f',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&:hover': {
        opacity: 0.9,
      },
    },
    titleAdd: {
      // fontSize: 17,
      color: '#ffffff',
      fontWeight: theme.typography.fontWeightRegular,
    },
  })
);

export const useStylesProductsTable = makeStyles((theme: any) =>
  createStyles({
    table: {
      marginBottom: 20,
    },
    editBtn: {
      marginRight: 5,
    },
    img: {
      maxHeight: 55,
    },
    btnAdd: {
      height: 50,
      minWidth: 180,
      borderRadius: 4,
      cursor: 'pointer',
      // backgroundColor: '#d01c1f',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&:hover': {
        opacity: 0.9,
      },
    },
    titleAdd: {
      // fontSize: 17,
      color: '#ffffff',
      fontWeight: theme.typography.fontWeightRegular,
    },
    cell: {
      // fontSize: 18,
    },
    btnCancel: {
      marginTop: 20,
      height: 50,
      width: 150,
      borderRadius: 4,
      border: 'solid 1px #000000',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&:hover': {
        opacity: 0.9,
      },
    },
    titleCancel: {
      // fontSize: 17,
      color: '#000000',
      fontWeight: 600,
    },
  })
);

export const useStylesImageGallery = makeStyles((theme: any) => ({
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 0,
    backgroundColor: '#fffff',
  },
  imgContainerSmall: {
    maxHeight: '400px',

    [theme.breakpoints.up('md')]: {
      maxHeight: '700px',
    },
  },
  imgContainerFull: {
    maxHeight: '85vh',
  },
  img: {
    height: '100%',
    backgroundColor: '#fffff',
  },
  galleryContainer: {
    width: '100%',
  },
  arrow: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 2,
    // color: '#d01c1f',
  },
}));

export const useStylesAddProductForm = makeStyles((theme: any) =>
  createStyles({
    table: {
      marginBottom: 20,
    },
    editBtn: {
      marginRight: 5,
    },
    img: {
      maxHeight: 55,
    },
    btnAdd: {
      height: 50,
      minWidth: 150,
      borderRadius: 4,
      border: 'solid 1px #000000',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&:hover': {
        opacity: 0.9,
      },
    },
    btnCancel: {
      height: 50,
      width: 150,
      borderRadius: 4,
      border: 'solid 1px #000000',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&:hover': {
        opacity: 0.9,
      },
    },
    titleAdd: {
      // fontSize: 17,
      color: '#000000',
      fontWeight: 600,
    },
    cell: {
      // fontSize: 18,
      borderWidth: 0,
    },
    field: {
      marginTop: 8,
      marginBottom: 20,
    },
    btnCreate: {
      height: 50,
      width: 180,
      borderRadius: 4,
      marginLeft: 20,
      cursor: 'pointer',
      // backgroundColor: '#d01c1f',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&:hover': {
        opacity: 0.9,
      },
    },
    titleCreate: {
      // fontSize: 17,
      color: '#ffffff',
      fontWeight: theme.typography.fontWeightRegular,
    },
    actions: {
      display: 'flex',
      flexDirection: 'row',
    },
  })
);

export const useStylesProposalsForm = makeStyles((theme: any) =>
  createStyles({
    container: {
      maxWidth: 795,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    btnAdd: {
      marginTop: 25,
      height: 50,
      width: 350,
      borderRadius: 4,
      cursor: 'pointer',
      // backgroundColor: '#d01c1f',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&:hover': {
        opacity: 0.9,
      },
    },
    titleVendors: {
      // fontSize: 20,
      fontWeight: 600,
      marginBottom: 10,
      margin: '0 7.5px',
    },
    card: {
      position: 'relative',
      maxWidth: 250,
      margin: '0 7.5px',
      marginBottom: 10,
    },
    img: {
      height: 350,
      width: '100%',
      objectFit: 'cover',
    },
    cardInfo: {
      padding: '0 15px',
      marginTop: 10,
    },
    productName: {
      // fontSize: 17,
      fontWeight: 700,
      marginBottom: 5,
    },
    blockPriceWrite: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
      marginBottom: 16,
    },
    price: {
      color: theme.palette.primary.main,
      fontWeight: 600,
      // fontSize: 17,
      margin: 0,
      padding: 0,
      marginRight: 8,
    },
    rub: {
      // fontSize: 12,
    },
    whatsApp: {
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
    },
    whatsAppIcon: {
      height: 21,
      width: 21,
      marginRight: 8,
    },
    write: {
      color: '#103928',
      fontWeight: 600,
      // fontSize: 17,
      '&:hover': {
        color: '#103928',
      },
    },
    comment: {
      // fontSize: 17,
      lineHeight: '23px',
      fontWeight: theme.typography.fontWeightRegular,
      color: '#000000',
      padding: '0 15px',
    },
    starIcon: {
      width: 20,
      height: 20,
      marginRight: 5,
    },
    rating: {
      // fontSize: 17,
      fontWeight: 700,
    },
    containerRating: {
      position: 'absolute',
      top: 15,
      right: 15,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    containerCards: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    btnCancel: {
      marginTop: 20,
      height: 50,
      width: '100%',
      borderRadius: 4,
      border: 'solid 1px #000000',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&:hover': {
        opacity: 0.9,
      },
    },
    titleAdd: {
      // fontSize: 17,
      color: '#000000',
      fontWeight: 600,
    },
    ratingContent: {
      position: 'absolute',
      right: '20px',
      top: '0px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  })
);

export const useStylesSearchList = makeStyles((theme: any) =>
  createStyles({
    empty: {
      marginBottom: 20,
    },
    buttons: {
      marginRight: theme.spacing(3),
    },
    card: {
      marginBottom: theme.spacing(3),
      boxShadow: 'none !important',
    },
    categoryTree: {
      maxWidth: 400,
    },
    actions: {
      marginTop: theme.spacing(2),
    },
    img: {
      maxHeight: 55,
    },
    autoLoc: {
      maxWidth: 400,
    },
    icon: {
      width: 20,
      height: 20,
    },
    cell: {
      // fontSize: 18,
    },
    titleProposals: {
      // color: '#d01c1f',
      cursor: 'pointer',
      // fontSize: 18,
      fontWeight: 400,
    },
    titleName: {
      cursor: 'pointer',
    },
    iconDelete: {
      // color: '#d01c1f',
    },
  })
);
