import React, { useCallback, useMemo, useRef } from 'react';
import objectPath from 'object-path';
import { Link } from 'react-router-dom';
import { MenuItemText } from './MenuItemText';
import { MenuSubmenu } from './MenuSubmenu';
import clsx from 'clsx';
import { UsersFilter } from '../../../app/components/ui/UsersFilter';

type TProps = {
  item: any;
  currentUrl: string;
  parentItem?: any;
  layoutConfig: any;
};

export const MenuItem: React.FC<TProps> = ({ item, currentUrl, parentItem, layoutConfig }) => {
  const asideLeftLIRef: any = useRef();
  const isDropdown = document.body.classList.contains('kt-aside-menu--dropdown');

  const submenuToggle = useMemo(
    () =>
      item.toggle === 'click'
        ? 'click'
        : objectPath.get(item, 'submenu.type') === 'tabs'
        ? 'tabs'
        : 'hover',
    [item]
  );

  const mouseEnter = useCallback(() => {
    if (!isDropdown) {
      return;
    }

    if (item.submenu && asideLeftLIRef?.current) {
      asideLeftLIRef.current.classList.add('kt-menu__item--hover');
      asideLeftLIRef.current.setAttribute('data-ktmenu-submenu-toggle', submenuToggle);
    }
  }, [isDropdown, item, asideLeftLIRef, submenuToggle]);

  const mouseLeave = useCallback(() => {
    if (!isDropdown) {
      return;
    }

    if (item.submenu && submenuToggle === 'hover') {
      asideLeftLIRef.current.classList.remove('kt-menu__item--hover');
      asideLeftLIRef.current.removeAttribute('data-ktmenu-submenu-toggle');
    }
  }, [isDropdown, item, asideLeftLIRef, submenuToggle]);

  const isMenuItemIsActive = useCallback(
    (item: any) => {
      let haveActivePage = false;

      if (item.activePages?.length) {
        for (let i = 0; i < item.activePages.length; i++) {
          const url = item.activePages[i];
          const isCurrentUrlInActivePage = currentUrl.indexOf(url) !== -1;
          if (isCurrentUrlInActivePage) {
            haveActivePage = true;
            break;
          }
        }
      }

      if (haveActivePage) {
        return true;
      }

      if (item.submenu) {
        return isMenuRootItemIsActive(item);
      }

      if (!item.page) {
        return false;
      }

      return currentUrl.indexOf(item.page) !== -1;
    },
    [currentUrl]
  );

  const isMenuRootItemIsActive = useCallback((item: any) => {
    for (const subItem of item.submenu) {
      if (isMenuItemIsActive(subItem)) {
        return true;
      }
    }

    return false;
  }, []);

  const isActive = isMenuItemIsActive(item);

  let Filter = null;

  if (item.page === 'users/vendors') {
    Filter = () => <UsersFilter />;
  }

  return (
    <li
      ref={asideLeftLIRef}
      aria-haspopup='true'
      data-placement='right'
      data-ktmenu-submenu-mode={item.mode}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      className={clsx(
        'kt-menu__item',
        {
          'kt-menu__item--submenu': item.submenu || item.dynamic,
          'kt-menu__item--open kt-menu__item--here':
            isActive && (item.submenu || item.dynamic),
          'kt-menu__item--active kt-menu__item--here': isActive,
          'kt-menu__item--icon-only': item['icon-only'],
        },
        item['custom-class']
      )}
      data-ktmenu-dropdown-toggle-class={item['dropdown-toggle-class']}
    >
      {!(item.submenu || item.dynamic) && (
        <>
          <Link to={`/${item.page}`} className='kt-menu__link kt-menu__toggle'>
            <MenuItemText item={item} parentItem={parentItem} />
          </Link>

          {Filter && isActive ? (
            <div className='kt-menu__link'>
              <Filter />
            </div>
          ) : null}
        </>
      )}

      {(item.submenu || item.dynamic) && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          className='kt-menu__link kt-menu__toggle'
          onClick={() => {
            if (!!item.dynamic) {
              // this.setState({ dynamicOpen: !this.state.dynamicOpen });
            }
          }}
        >
          <MenuItemText item={item} parentItem={parentItem} />
        </a>
      )}

      {item.submenu && (
        <div className='kt-menu__submenu'>
          <span className='kt-menu__arrow' />

          {item['custom-class'] === 'kt-menu__item--submenu-fullheight' && (
            <div className='kt-menu__wrapper'>
              <MenuSubmenu item={item} currentUrl={currentUrl} layoutConfig={layoutConfig} />
            </div>
          )}

          {item['custom-class'] !== 'kt-menu__item--submenu-fullheight' && (
            <MenuSubmenu item={item} currentUrl={currentUrl} layoutConfig={layoutConfig} />
          )}
        </div>
      )}

      {item.dynamic && (
        <div className='kt-menu__submenu'>
          <span className='kt-menu__arrow' />
          {item['custom-class'] !== 'kt-menu__item--submenu-fullheight' && item.dynamic()}
        </div>
      )}
    </li>
  );
};
