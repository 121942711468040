import React from 'react';
import { makeStyles } from '@mui/styles';
import StarActive from './StarActive';
import StarDisable from './StarDisable';

interface IProps {
  rating?: number | null;
  size?: string;
  onChange?: (rating: number) => void;
}

const useStyles = makeStyles(() => ({
  star: {
    cursor: 'pointer',
  },
}));

const Rating: React.FC<IProps> = ({ rating, size, onChange }) => {
  const classes = useStyles();

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div
        className={onChange ? classes.star : ''}
        onClick={onChange ? () => onChange(1) : undefined}
      >
        {rating && rating >= 1 ? <StarActive size={size} /> : <StarDisable size={size} />}
      </div>
      <div
        className={onChange ? classes.star : ''}
        onClick={onChange ? () => onChange(2) : undefined}
      >
        {rating && rating >= 2 ? <StarActive size={size} /> : <StarDisable size={size} />}
      </div>
      <div
        className={onChange ? classes.star : ''}
        onClick={onChange ? () => onChange(3) : undefined}
      >
        {rating && rating >= 3 ? <StarActive size={size} /> : <StarDisable size={size} />}
      </div>
      <div
        className={onChange ? classes.star : ''}
        onClick={onChange ? () => onChange(4) : undefined}
      >
        {rating && rating >= 4 ? <StarActive size={size} /> : <StarDisable size={size} />}
      </div>
      <div
        className={onChange ? classes.star : ''}
        onClick={onChange ? () => onChange(5) : undefined}
      >
        {rating && rating >= 5 ? <StarActive size={size} /> : <StarDisable size={size} />}
      </div>
    </div>
  );
};

export default Rating;
