import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { createTender } from '../../../../crud/orders.crud';
import { getResponseMessage } from '../../../../utils/utils';

export const useCreateTender = () => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [createLoading, setLoading] = useState(false);
  const [successTender, setSuccess] = useState(false);
  const [createId, setCreateId] = useState<any>(null);
  const createTenderFetch = useCallback(async (data: any) => {
    setLoading(true);
    createTender(data)
      .then((res: any) => {
        setCreateId(res.data.data.id);
        setSuccess(true);
        enqueueSnackbar(`${intl.formatMessage({ id: 'TENDER.SUCCESS.CREATE' })}`, {
          variant: 'success',
        });
      })
      .catch(e => {
        enqueueSnackbar(`${intl.formatMessage({ id: 'ERROR' })}: ${getResponseMessage(e)}`, {
          variant: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
        setSuccess(false);
        setCreateId(null);
      });
  }, []);
  return { createTenderFetch, createLoading, successTender, createId };
};
