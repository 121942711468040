import React, { useCallback, useEffect, useState } from 'react';
import { Table, TableRow, TableBody, TableCell, TextField } from '@mui/material';
import { useIntl } from 'react-intl';

import { IProduct } from '../../../../../interfaces/product';
import { toAbsoluteUrl } from '../../../../../../_base';
import { getProductImage } from '../../../products/utils/getProductImage';
import { thousands } from '../../../../../utils/utils';
import { useStylesAddProductForm } from '../../hooks/useStyles';
import { useCreateParticipation } from '../../hooks/useCreateParticipation';

type TProps = {
  product: IProduct | null;
  onCancel: () => void;
  close: () => void;
  id: string | number;
};

const AddProductForm: React.FC<TProps> = ({ product, onCancel, close, id }) => {
  const classes = useStylesAddProductForm();
  const intl = useIntl();
  const [comment, setComment] = useState('');
  const { createPart, loadingCreate, successCreate } = useCreateParticipation();

  useEffect(() => {
    if (successCreate) {
      close();
    }
  }, [successCreate]);

  const handleCreate = useCallback(() => {
    if (product) {
      const data = {
        comment,
        productsIds: product.id?.toString(),
      };
      createPart(+id, data);
    }
  }, [product, comment, id]);

  return (
    <div className={classes.table}>
      <Table aria-label='CompaniesTable'>
        <TableBody>
          {product && (
            <TableRow key={product.id?.toString()}>
              <TableCell className={classes.cell}>{product.id || '-'}</TableCell>
              <TableCell className={classes.cell}>
                <img
                  src={getProductImage(product)}
                  className={classes.img}
                  alt=' '
                  onError={(e: any) => {
                    e.target.src = toAbsoluteUrl('/images/placeholder.png');
                  }}
                />
              </TableCell>
              <TableCell className={classes.cell}>{product.name || '-'}</TableCell>
              <TableCell className={classes.cell}>
                {product.price ? thousands(String(product.price)) : '-'}
              </TableCell>
              <TableCell className={classes.cell} style={{ paddingRight: 0 }}>
                <div className={classes.btnAdd} onClick={() => !loadingCreate && onCancel()}>
                  <b className={classes.titleAdd}>
                    {intl.formatMessage({ id: 'COMMON.BUTTON.CANCEL' }).toUpperCase()}
                  </b>
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {product && (
        <TextField
          className={classes.field}
          type='text'
          onChange={e => setComment(e.target.value)}
          label={intl.formatMessage({ id: 'SEARCH.COMMENT.OFFER' })}
          margin='normal'
          name='description'
          value={comment}
          variant='outlined'
          rows={8}
          multiline
        />
      )}
      <div className={classes.actions}>
        <div className={classes.btnCancel} onClick={() => !loadingCreate && close()}>
          <b className={classes.titleAdd}>
            {intl.formatMessage({ id: 'COMMON.BUTTON.CANCEL' }).toUpperCase()}
          </b>
        </div>
        <div className={classes.btnCreate} onClick={() => !loadingCreate && handleCreate()}>
          <b className={classes.titleCreate}>
            {intl.formatMessage({ id: 'SEARCH.OFFER' }).toUpperCase()}
          </b>
        </div>
      </div>
    </div>
  );
};

export default React.memo(AddProductForm);
