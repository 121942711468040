import React, { useCallback, useEffect, useState } from 'react';
import { DialogProps, Paper } from '@mui/material';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from 'react-sortable-hoc';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { ButtonWithLoader } from '../../../../components/ui/Buttons';
import { ITypeParameter } from '../../../../interfaces/productType';
import { useFormatMessage } from '../../../../hooks';
import { IGroup } from '../../../../interfaces/groups';
import GroupTitleForm from './GroupTitleForm';
import GroupParamForm from './GroupParamForm';
import { useStylesTypeForm } from '../../products/hooks/useStyles';
import { Modal } from '../../../../components/other/Modals';
import { useGetParams } from '../hooks/useGetParams';
import ParamsTable from './ParamsTable';

interface ITypeForm {
  group?: IGroup;
  editAction: (name: string) => void;
  delGroup: () => void;
  disabled: boolean;
  params: ITypeParameter[];
  setPositions: (positions: string) => void;
  editParamAction: (add?: number, remove?: number) => void;
  groups: IGroup[];
}

interface ISortableItem {
  param: ITypeParameter;
  deleteField: (id?: number) => void;
  index: number;
}
const DragHandle = SortableHandle(() => (
  <span style={{ cursor: 'move' }}>
    <DragHandleIcon />
  </span>
));

const SortableItem: any = SortableElement(({ param, deleteField }: ISortableItem) => (
  <div>
    {param.id && <DragHandle />}
    <GroupParamForm key={param.id} param={param} deleteField={deleteField} />
  </div>
));

const SortableList: any = SortableContainer(({ children }: { children: React.ReactNode }) => (
  <div>{children}</div>
));

const GroupForm: React.FC<ITypeForm> = ({
  group,
  editAction,
  delGroup,
  disabled,
  params,
  setPositions,
  editParamAction,
  groups,
}) => {
  const fm = useFormatMessage();
  const homeClasses1 = useStylesTypeForm();
  const {classes} = homeClasses1
  const [getParams, loadingParams, paramsList] = useGetParams();
  const [typeParams, setTypeParams] = useState(params || []);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setTypeParams(params);
    getParams();
  }, [params]);

  const addEmptyCropParams = () => {
    getParams();
    setOpenModal(true);
  };

  const deleteField = useCallback((id?: number) => {
    id && editParamAction(undefined, id);
  }, []);

  const addField = useCallback((id?: number) => {
    id && editParamAction(id, undefined);
  }, []);

  return (
    <Paper className={classes.container}>
      <div className={classes.form}>
        <GroupTitleForm
          group={group}
          delGroup={delGroup}
          editAction={editAction}
          disabled={disabled}
        />
        {typeParams && (
          <SortableList
            useDragHandle
            useWindowAsScrollContainer
            onSortEnd={({ oldIndex, newIndex }: any) => {
              if (oldIndex !== newIndex) {
                const sortParams = arrayMove(typeParams, oldIndex, newIndex);
                const positions = sortParams.reduce(
                  (accumulator, item, index) =>
                    accumulator + (index === 0 ? '' : ',') + item.id,
                  ''
                );
                setPositions(positions);
              }
            }}
          >
            {typeParams.map((param, index) => (
              <SortableItem
                key={param.id}
                param={param}
                index={index}
                deleteField={deleteField}
                disabled={disabled}
              />
            ))}
          </SortableList>
        )}
        {group?.id && (
          <div className={classes.buttonAddContainer}>
            <ButtonWithLoader onPress={addEmptyCropParams} disabled={disabled}>
              {fm('PRODUCT.TYPES.PARAMETER.BUTTON.ADD')}
            </ButtonWithLoader>
          </div>
        )}
      </div>
      <Modal
        DialogProps={{ maxWidth: 'md' } as DialogProps}
        open={openModal}
        onClose={loadingParams ? () => {} : () => setOpenModal(false)}
        title={fm('PRODUCT.TYPES.PARAMETER.BUTTON.ADD')}
        loading={loadingParams}
        content={
          <ParamsTable
            paramsList={paramsList}
            onConnectParam={addField}
            textConnect={fm('COMMON.BUTTON.ADD')}
            paramsGroup={params}
            groups={groups}
          />
        }
        actions={[
          {
            title: fm('COMMON.BUTTON.CLOSE'),
            onClick: () => setOpenModal(false),
          },
        ]}
      />
    </Paper>
  );
};

export default React.memo(GroupForm);
