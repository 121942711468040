import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { Button, Card, Paper, Tab, Tabs, Typography } from '@mui/material';
import parse from 'html-react-parser';

import { a11yProps, TabPanel } from '../../../components/other/Tab/TabPanel';
import { IAppState } from '../../../store/rootDuck';
import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';
import { actions as companiesActions } from '../../../store/ducks/companies.duck';
import Preloader from '../../../components/other/Preloader/Preloader';
import { API_DOMAIN } from '../../../constants';
import { toAbsoluteUrl } from '../../../../_base';
import ImageDialog from '../../../components/other/ImageDialog/imageDialog';
import { actions as profileActions } from '../../../store/ducks/profile.duck';
import { useStyles } from './hooks/useStyles';
import { useFormatMessage, useShowErrors } from '../../../hooks';

const CompanyView: React.FC<TPropsFromRedux> = ({
  company,
  companyError,
  loading,
  fetch,
  clearDelCompany,

  me,
  meError,
  loadingMe,
  fetchMe,
  clearMe,
}) => {
  const classes1 = useStyles();
  const {classes} = classes1
  const navigate = useNavigate();
  const { id } = useParams();
  const fm = useFormatMessage();

  const [valueTabs, setValueTabs] = useState(0);
  const [isImageOpen, setImageOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  setLayoutSubheader({ title: fm('COMPANY.TITLE.VIEW') });
  setLayoutFooter({ show: true });

  useEffect(() => {
    fetchMe();
    return () => {
      clearMe();
    };
  }, []);

  useEffect(() => {
    fetch(Number(id));
    return () => {
      clearDelCompany();
    };
  }, [id]);

  const goToEditCompany = useCallback(() => {
    company && navigate(`/companies/edit/${company.id}`);
  }, [navigate, company]);

  const handleImage = useCallback(
    (url: any) => {
      if (isImageOpen) {
        setImageUrl(null);
        setImageOpen(false);
      } else {
        setImageUrl(url);
        setImageOpen(true);
      }
    },
    [isImageOpen, imageUrl]
  );

  useShowErrors([meError, companyError]);

  if (loading || !company || loadingMe) return <Preloader />;

  return (
    <>
      {imageUrl && <ImageDialog open={isImageOpen} handleClose={handleImage} url={imageUrl} />}
      <Paper className={classes.containerPaper}>
        <div className={classes.container}>
          <div className={classes.header}>
            <Typography variant='h5'>{`${company.name}`}</Typography>

            {me?.is_admin && (
              <Button color='primary' variant='contained' onClick={() => goToEditCompany()}>
                {fm('COMPANY.TABLE.BUTTON.EDIT')}
              </Button>
            )}
          </div>

          <div className={classes.containerTop}>
            <div className={classes.galleryContainer}>
              {company.logo ? (
                <Card
                  className={classes.imgContainer}
                  elevation={0}
                  style={{ height: 'auto', maxHeight: 400, alignItems: 'start' }}
                >
                  <img
                    onClick={() => handleImage(`${API_DOMAIN}/${company.logo}`)}
                    src={`${API_DOMAIN}/${company.logo}`}
                    className={classes.img}
                    alt=''
                  />
                </Card>
              ) : (
                <Card className={classes.imgContainer}>
                  <img
                    onClick={() =>
                      handleImage(toAbsoluteUrl('/images/flower_placeholder.png'))
                    }
                    src={toAbsoluteUrl('/images/flower_placeholder.png')}
                    className={classes.img}
                    alt=''
                  />
                </Card>
              )}
            </div>
            <div className={classes.productInfo}>
              <Typography variant='h5'>Brand:</Typography>
              <Typography variant='h6'>{company.brand}</Typography>
            </div>
          </div>
          <Tabs
            value={valueTabs}
            onChange={(_: any, newValue: number) => setValueTabs(newValue)}
            variant='scrollable'
            indicatorColor='primary'
            textColor='primary'
            aria-label='tabs'
          >
            {Boolean(company.description) && (
              <Tab label={fm('PRODUCT.TABLE.DESCRIPTION')} {...a11yProps(0)} />
            )}
          </Tabs>
          {Boolean(company.description) && (
            <TabPanel value={valueTabs} index={0}>
              <div className={classes.containerBot}>{parse(company?.description || '')}</div>
            </TabPanel>
          )}
        </div>
      </Paper>
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    company: state.companies.company,
    companyError: state.companies.byIdError,
    loading: state.companies.byIdLoading,
    byIdSuccess: state.companies.byIdSuccess,
    isAuthorized: state.auth.user != null,

    me: state.profile.me,
    meError: state.profile.error,
    loadingMe: state.profile.loading,
  }),
  {
    fetch: companiesActions.fetchByIdRequest,
    clearDelCompany: companiesActions.clearDelCompany,

    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CompanyView);
