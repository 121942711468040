import React, { useCallback, useMemo, useState } from 'react';
import { Dialog } from '@mui/material';
import { toAbsoluteUrl } from '../../../../../../_base';
import { useStylesModalTariff } from '../../hooks/useStyles';
import { tariffOptions } from '../../utils/tariffOptions';
import { IUser } from '../../../../../interfaces/user';
import { useFormatMessage } from '../../../../../hooks';
import { useEditUserTariff } from '../../hooks/useEditUserTariff';
import { ICompany } from '../../../../../interfaces/companies';
import { useEditCompanyTariff } from '../../hooks/useEditCompanyTariff';
import { ITariff } from '../../../../../interfaces/tariff';
// import { IUser } from '../../../../interfaces/user';

interface IProps {
  open: boolean;
  handleClose: (update?: boolean) => void;
  showBuyModal?: (id: number, days: number) => void;
  user?: IUser | null;
  company?: ICompany | null;
  successFunc?: () => void;
  tariffs: ITariff[];
}

const TariffModal: React.FC<IProps> = ({
  open,
  handleClose,
  user,
  successFunc,
  showBuyModal,
  company,
  tariffs,
}) => {
  const classes = useStylesModalTariff();
  const fm = useFormatMessage();
  // const { me } = useSelector(
  //   ({ profile: { me, loading } }: IAppState) => ({ me, loading }),
  //   shallowEqual
  // );

  const { editUserTariffFetch, loadingEdit } = useEditUserTariff(successFunc);
  const { editCompanyTariffFetch, loadingEditCompanyTariff } =
    useEditCompanyTariff(successFunc);
  // const [updateTariff, setUpdateTariff] = useState(false);
  // const [buyTariff, successBuy, loadingBuy] = useBuyTariff();

  const tariffsArr = useMemo(() => {
    if (tariffs.length === 0) return [];
    const newTariff: any[] = [];
    tariffs.forEach(item => {
      if (item.name === 'Премиум') {
        newTariff.push({
          id: 1,
          text: fm('TARIFF.PRIME'),
          subText: fm('TARIFF.ONE_WEEK'),
          price: item.price7days,
          days: 7,
        });
        newTariff.push({
          id: 2,
          text: fm('TARIFF.PRIME'),
          subText: fm('TARIFF.ONE_MONTH'),
          price: item.price30days,
          days: 30,
        });
        newTariff.push({
          id: 3,
          text: fm('TARIFF.PRIME'),
          subText: fm('TARIFF.FOUR_WEEK'),
          price: item.price120days,
          days: 120,
        });
        newTariff.push({
          id: 4,
          text: fm('TARIFF.PRIME'),
          subText: fm('TARIFF.ONE_YEAR'),
          price: item.price365days,
          days: 365,
        });
      } else if (item.name === 'Базовый') {
        newTariff.unshift({
          id: 5,
          text: fm('TARIFF.BASE'),
          subText: fm('TARIFF.BASE_SUBTEXT'),
          price: 0,
          days: 0,
        });
      }
    });
    return newTariff;
  }, [tariffs.length]);

  if (tariffsArr.length === 0) return <></>;

  const [activeTariff, setActiveTariff] = useState(tariffsArr[1]);

  const handleUpdateTariff = useCallback(() => {
    if (user) {
      editUserTariffFetch(user.id, activeTariff?.text === 'Базовый' ? 0 : activeTariff?.days);
    } else if (company) {
      editCompanyTariffFetch(
        company.id,
        activeTariff?.text === 'Базовый' ? 0 : activeTariff?.days
      );
    } else if (activeTariff?.text !== 'Базовый') {
      // buyTariff(activeTariff.price);
      // showBuyModal && showBuyModal();
      if (showBuyModal) {
        const id = tariffs.find(item => item.name.toLowerCase() === 'Премиум')?.id;
        id && showBuyModal(id, activeTariff?.days);
      }
      // setUpdateTariff(true);
    }
  }, [
    activeTariff,
    user,
    editUserTariffFetch,
    showBuyModal,
    editCompanyTariffFetch,
    company,
    tariffs,
  ]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        // handleClose(updateTariff);
        handleClose(false);
      }}
      style={{ margin: 0, padding: 0 }}
      fullWidth
      maxWidth='lg'
    >
      <div className={classes.container}>
        <div className={classes.closeWrapper}>
          <img
            alt='close'
            height={13}
            width={13}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              // handleClose(updateTariff)
              handleClose(false);
            }}
            src={toAbsoluteUrl('/media/icons/x.svg')}
          />
        </div>
        <div className={classes.header}>
          <p className={classes.headerTitle}>{fm('TARIFF.SELECT_TARIFF_TITLE')}</p>
          <p className={classes.headerSubTitle}>{fm('TARIFF.SELECT_TARIFF_SUBTITLE')}</p>
        </div>
        <div className={classes.content}>
          <div className={classes.wrapperTariffBlocks}>
            {tariffsArr.map((tariff: any) => (
              <div
                key={tariff?.id}
                className={classes.tariffBox}
                onClick={() => setActiveTariff(tariff)}
                style={{
                  backgroundColor: tariff?.id === activeTariff?.id ? '#369FF7FF' : '#FFFFFF',
                  border: tariff?.id === activeTariff?.id ? 'none' : 'dashed 1px #e4e6ef',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {tariff.text === 'Базовый' && !user && !company ? (
                    <div className={classes.bullet} />
                  ) : (
                    <img
                      alt='bullet'
                      className={classes.bullet}
                      src={toAbsoluteUrl(
                        tariff.id === activeTariff?.id
                          ? '/media/icons/bullet-active.svg'
                          : '/media/icons/bullet-passive.svg'
                      )}
                    />
                  )}
                  <div>
                    <p className={classes.tariffTitle}>{tariff.text}</p>
                    <p
                      className={classes.tariffSubTitle}
                      style={{
                        color: tariff?.id === activeTariff?.id ? '#7ECEFBFF' : '#A1A5B7FF',
                      }}
                    >
                      {tariff.subText}
                    </p>
                  </div>
                </div>
                <div className={classes.tariffPriceWrapper}>
                  <p
                    className={classes.tariffPrice}
                    style={{
                      color: tariff?.id === activeTariff?.id ? '#FFFFFF' : '#181C32FF',
                    }}
                  >
                    {tariff.price}
                  </p>
                  <p
                    className={classes.tariffPriceRub}
                    style={{
                      color: tariff.id === activeTariff?.id ? '#7ECEFBFF' : '#A1A5B7FF',
                    }}
                  >
                    ₽
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className={classes.tariffsContainer}>
            <p className={classes.tariffsTitle}>{fm('TARIFF.ICLUDES')}</p>
            <p className={classes.tariffSubTitle1}>{fm('TARIFF.FEATURES')}</p>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {tariffOptions().map(tariff => (
                <div key={tariff.text} className={classes.optionContainer}>
                  {tariff.isPrime ? (
                    <p
                      className={classes.tariffOption}
                      style={{
                        color:
                          activeTariff?.text !== fm('TARIFF.BASE') ? '#5E6278FF' : '#A2A6B8FF',
                      }}
                    >
                      {tariff.text}
                    </p>
                  ) : (
                    <p className={classes.tariffOption}>{tariff.text}</p>
                  )}
                  <img
                    alt='checked1'
                    className={classes.iconChecked}
                    src={
                      tariff.isPrime
                        ? toAbsoluteUrl(
                            activeTariff?.text !== fm('TARIFF.BASE')
                              ? '/media/icons/web-check-circle.svg'
                              : '/media/icons/canceled-circle.svg'
                          )
                        : toAbsoluteUrl('/media/icons/web-check-circle.svg')
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={classes.footerBtns}>
          <div
            className={classes.btnFooterCancel}
            onClick={() => {
              // handleClose(updateTariff)
              handleClose(false);
            }}
          >
            <p className={classes.btnTitleCancel}>{fm('TARIFF.CANCEL')}</p>
          </div>
          {activeTariff?.text !== 'Базовый' && !user && !company && (
            <div
              className={classes.btnFooter}
              onClick={() =>
                (!loadingEdit || !loadingEditCompanyTariff) && handleUpdateTariff()
              }
            >
              <p className={classes.btnTitle}>{fm('TARIFF.UPDATE')}</p>
            </div>
          )}
          {(user || company) && (
            <div
              className={classes.btnFooter}
              onClick={() =>
                (!loadingEdit || !loadingEditCompanyTariff) && handleUpdateTariff()
              }
            >
              <p className={classes.btnTitle}>{fm('TARIFF.UPDATE')}</p>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default TariffModal;
