import React, { FC, useState } from 'react';
import Slider from 'react-slick';
import { CardMedia } from '@mui/material';
import { API_DOMAIN } from '../../../constants';
import { toAbsoluteUrl } from '../../../../_base';
import { useStyles } from './useStyles';
import { CustomArrow } from '../CustomBanner/components/ArrowButton';
import { IUser } from '../../../interfaces/user';
import { IBanner } from '../../../pages/home/banners/interfaces';

interface IProductsProps {
  data: IBanner[];
  viewAction: any;
  me: IUser | undefined;
  isAdmin: boolean;
}

const CustomDots = ({
  onClick,
  active,
}: {
  onClick?: React.MouseEventHandler<any> | undefined;
  active: boolean;
}) => (
  <button type='button' className={`custom-dot${active ? '-active' : ''}`} onClick={onClick} />
);

const SlickSlider: FC<IProductsProps> = ({ data, viewAction }) => {
  const classes1 = useStyles();
  const { classes } = classes1;

  const [activeIndex, setActiveIndex] = useState(0);
  // const isBuyer = useDefineUserRole(me, 'ROLE_BUYER');
  // const [elementsCount, setElementCount] = useState(0);
  // const products = useMemo(() => {
  //   return data.filter(item => item.active && !item?.is_deleted);
  // }, [data]);

  // useEffect(() => {
  //   let elementsCounter = 0
  //   products.forEach((item) => {
  //     if (!((!getStock(item) || (getStock(item) || 0) < 0) && !me?.roles.includes(UserRoles.ROLE_VENDOR) && !isAdmin)) {
  //       elementsCounter += 1
  //     }
  //     if (me?.company?.id === item?.company?.id) {
  //       elementsCounter += 1
  //     }
  //   })
  //   setElementCount(elementsCounter)
  // }, [])

  // const getStock = useCallback(
  //   (product: IProduct) => {
  //     return isBuyer || !me ? product.stock_info?.available_quantity : product.stock_quantity;
  //   },
  //   [isBuyer, me]
  // );

  // const getSlidesToShow = (comparisonNumber: number, beforeNumber: number) => {
  //   if (data.length <= 6) {
  //     return 1;
  //   }
  //   if (elementsCount >= comparisonNumber) {
  //     return beforeNumber;
  //   }
  //   return elementsCount;
  // };

  const settings = {
    prevArrow: <CustomArrow type='left' onClick={() => setActiveIndex(activeIndex + 1)} />,
    nextArrow: <CustomArrow type='right' onClick={() => setActiveIndex(activeIndex - 1)} />,
    // centerMode: true,
    dots: true,
    arrows: true,
    // centerPadding: '0px',
    slidesToShow: 1,
    appendDots: (dots: React.ReactNode) => <div className='custom-dots-container'>{dots}</div>,
    customPaging: (i: number) => (
      <CustomDots onClick={() => setActiveIndex(i)} active={i === activeIndex} />
    ),
    responsive: [
      // {
      //   breakpoint: 2000,
      //   settings: {
      //     centerMode: true,
      //     centerPadding: '160px',
      //     slidesToShow: getSlidesToShow(5, 5),
      //     slidesToScroll: 2,
      //   },
      // },
      // {
      //   breakpoint: 1900,
      //   settings: {
      //     centerMode: true,
      //     centerPadding: '30px',
      //     slidesToShow: getSlidesToShow(5, 5),
      //     slidesToScroll: 2,
      //   },
      // },
      // {
      //   breakpoint: 1800,
      //   settings: {
      //     centerMode: true,
      //     centerPadding: '160px',
      //     slidesToShow: getSlidesToShow(3, 3),
      //     slidesToScroll: 2,
      //   },
      // },
      // {
      //   breakpoint: 1700,
      //   settings: {
      //     centerMode: true,
      //     centerPadding: '160px',
      //     slidesToShow: getSlidesToShow(3, 3),
      //     slidesToScroll: 2,
      //   },
      // },
      // {
      //   breakpoint: 1500,
      //   settings: {
      //     centerMode: true,
      //     centerPadding: '130px',
      //     slidesToShow: getSlidesToShow(3, 3),
      //     slidesToScroll: 2,
      //   },
      // },
      // {
      //   breakpoint: 1400,
      //   settings: {
      //     centerMode: true,
      //     centerPadding: '60px',
      //     slidesToShow:getSlidesToShow(3, 3),
      //     slidesToScroll: 2,
      //   },
      // },
      // {
      //   breakpoint: 1300,
      //   settings: {
      //     centerMode: true,
      //     centerPadding: '200px',
      //     slidesToShow: getSlidesToShow(2, 1),
      //   },
      // },
      // {
      //   breakpoint: 1200,
      //   settings: {
      //     centerMode: true,
      //     centerPadding: '160px',
      //     slidesToShow: getSlidesToShow(2, 1),
      //   },
      // },
      // {
      //   breakpoint: 1000,
      //   settings: {
      //     centerMode: true,
      //     centerPadding: '30px',
      //     slidesToShow: getSlidesToShow(2, 1),
      //   },
      // },
      // {
      //   breakpoint: 768,
      //   settings: {
      //     centerMode: true,
      //     centerPadding: '20px',
      //     slidesToShow: 1,
      //   },
      // },
      // {
      //   breakpoint: 480,
      //   settings: {
      //     centerMode: true,
      //     centerPadding: '10px',
      //     slidesToShow: 1,
      //   },
      // },
      // {
      //   breakpoint: 350,
      //   settings: {
      //     centerMode: true,
      //     centerPadding: '0',
      //     slidesToShow: 1,
      //   },
      // },
    ],
  };

  return (
    <Slider {...settings} className={classes.container}>
      {data.map((item, index) => {
        return (
          <div key={index}>
            <div className={`${classes.productCard}`}>
              <CardMedia
                component='img'
                className={classes.productCardMedia}
                image={
                  item?.photo?.path && item?.photo?.small
                    ? `${API_DOMAIN}/${item.photo.big || item.photo.small || item.photo.path}`
                    : toAbsoluteUrl('/images/placeholder.png')
                }
                title={item.title}
                onClick={() =>
                  viewAction(
                    item
                    // 'view_from_favorite'
                  )
                }
              />
            </div>
          </div>
        );
      })}
    </Slider>
  );
};

export default SlickSlider;
