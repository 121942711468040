import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TableBlockInterface } from '../../../interfaces';
import EditableCell from './EditableCell';
import { ITariff } from '../../../../../../interfaces/tariff';
import { TariffsType } from '../../../TariffList';
import { TariffEditFields } from '../../../../../../store/ducks/tariff.duck';
import EditableRow from './EditableRow';

const useStyles = makeStyles((theme: any) => ({
  blackCell: {
    backgroundColor: 'rgba(150, 150, 150, 0.4)',
  },
  titleCell: {
    width: '100px',
  },
  tariffCell: {
    width: '200px',
  },
  freeCell: {
    width: '200px',
    backgroundColor: '#F7F5F0',
  },
  buyerProCell: {
    width: '200px',
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
  },
  providerProCell: {
    width: '200px',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
}));

const Block: React.FC<{
  type: TariffsType;
  block: TableBlockInterface;
  loading: boolean;
  success: boolean;
  editable: boolean;
  freeTariff: ITariff;
  proTariff: ITariff;
  editTariff: (id: number, forRole: string, name: string, field: TariffEditFields, value: string | number | null) => void;
  submit: (id: number, field: TariffEditFields, value: string | number | null) => void;
}> = ({ type, block, loading, success, submit, editable, freeTariff, proTariff, editTariff }) => {
  const classes = useStyles();
  const proClass =
    type === TariffsType.buyers ? classes.buyerProCell : classes.providerProCell;

  return (
    <>
      <TableRow>
        <TableCell className={classes.titleCell}>
          <b>{block.title}</b>
        </TableCell>
        <TableCell align='center' className={classes.freeCell} />
        <TableCell align='center' className={proClass} />
      </TableRow>

      {block.rows.map((row, index) => (
        <TableRow key={index.toString()}>
          <TableCell className={classes.titleCell}>{row.name}</TableCell>
          <TableCell align='center' className={classes.freeCell}>
            {typeof row.free === 'string' ? (
              <EditableRow
                row={row.free}
                loading={loading}
                success={success}
                editable={editable}
                submit={newValue =>
                  editTariff(
                    freeTariff.id,
                    freeTariff.forRole,
                    freeTariff.name,
                    // @ts-ignore
                    row.title,
                    newValue
                  )
                }
              />
            ) : (
              <EditableCell
                cell={row.free}
                loading={loading}
                success={success}
                submit={newValue =>
                  submit(
                    freeTariff.id,
                    // @ts-ignore
                    row.free.field,
                    newValue
                  )
                }
                editable={editable}
              />
            )}
          </TableCell>

          <TableCell align='center' className={proClass}>
            {typeof row.pro === 'string' ? (
              <EditableRow
                row={row.pro}
                loading={loading}
                success={success}
                editable={editable}
                submit={newValue =>
                  editTariff(
                    proTariff.id,
                    proTariff.forRole,
                    proTariff.name,
                    // @ts-ignore
                    row.title,
                    newValue
                  )
                }
              />
            ) : (
              <EditableCell
                cell={row.pro}
                loading={loading}
                success={success}
                submit={newValue =>
                  submit(
                    proTariff.id,
                    // @ts-ignore
                    row.pro.field,
                    newValue
                  )
                }
                editable={editable}
              />
            )}
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default Block;
