import { makeStyles, createStyles } from '@mui/styles';

export const useStylesSupply = makeStyles((theme: any) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    empty: {
      marginBottom: 20,
      marginTop: 20,
      color: theme.palette.primary.main,
    },
    autoLoc: {
      width: '100%',
    },
    totalCard: {
      width: '30%',

      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginTop: 30,
      },
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    bonusActive: {
      backgroundColor: theme.palette.secondary.main,
      color: '#FFF',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    wrapperText: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(3),
    },
  })
);

export const useStylesSupplyItems = makeStyles((theme: any) =>
  createStyles({
    empty: {
      marginBottom: 20,
      marginTop: 20,
    },
    buttons: {
      marginRight: theme.spacing(3),
    },
    card: {
      width: '65%',
      marginBottom: theme.spacing(3),

      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginBottom: 0,
      },
    },
    categoryTree: {
      maxWidth: 400,
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    img: {
      maxHeight: 55,
      cursor: 'pointer',
    },
    totalCard: {
      width: '100%',

      [theme.breakpoints.up('sm')]: {
        width: '30%',
      },
    },
  })
);
