import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { deleteParameterById } from '../../../../crud/productType.crud';
import { getResponseMessage } from '../../../../utils/utils';
import { actions as productTypesActions } from '../../../../store/ducks/productType.duck';

export const useDeleteParam: any = (typeId?: number) => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();
  const fetch = useCallback(
    async (id: number) => {
      setLoading(true);
      deleteParameterById(id)
        .then(() => {
          setSuccess(true);
          enqueueSnackbar(`${intl.formatMessage({ id: 'PRODUCT.TYPE.PARAM.DELETE' })}`, {
            variant: 'success',
          });
          typeId && dispatch(productTypesActions.fetchByIdRequest(typeId));
        })
        .catch(e => {
          enqueueSnackbar(`${intl.formatMessage({ id: 'ERROR' })}: ${getResponseMessage(e)}`, {
            variant: 'error',
          });
        })
        .finally(() => {
          setLoading(false);
          setSuccess(false);
        });
    },
    [dispatch, typeId, productTypesActions]
  );
  return [fetch, loading, success];
};
