import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { actions as CategoriesActions } from '../../../../store/ducks/categories.duck';
import { addCategoryAvatar } from '../../../../crud/categories.crud';
import { getResponseMessage } from '../../../../utils/utils';
import { IAppState } from '../../../../store/rootDuck';

export const useAddAvatar = (parentId: string) => {
  const dispatch = useDispatch();
  const { page, perPage } = useSelector(
    (store: IAppState) => ({
      page: store.categories.page,
      perPage: store.categories.per_page,
    }),
    shallowEqual
  );
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState<any>(null);
  const [success, setSuccess] = useState(false);
  const fetchAvatar = useCallback(
    async (id: number, photo: FormData) => {
      setLoading(true);
      addCategoryAvatar(id, photo)
        .then(res => {
          dispatch(CategoriesActions.fetchByIdSuccess(res.data));
          dispatch(CategoriesActions.fetchFullRequest());
          dispatch(CategoriesActions.fetchRootRequest({ page, perPage, parentId: +parentId }));
          enqueueSnackbar(`${intl.formatMessage({ id: 'COMMON.PHOTO.SAVE' })}`, {
            variant: 'success',
          });
          setSuccess(true);
        })
        .catch(e => {
          enqueueSnackbar(`${intl.formatMessage({ id: 'ERROR' })}: ${getResponseMessage(e)}`, {
            variant: 'error',
          });
        })
        .finally(() => {
          setLoading(false);
          setErr(null);
          setSuccess(false);
        });
    },
    [page, perPage, parentId]
  );

  return [fetchAvatar, success, loading, err];
};
