import { makeStyles } from '@mui/styles';

export const useStylesCalendar = makeStyles((theme: any) => ({
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export const useStyleShiftPage = makeStyles((theme: any) => ({
  inputFocused: {
    backgroundColor: 'white !important',
  },
  notchedOutline: {
    // borderWidth: 0,
  },
  inputLabel: {
    position: 'absolute',
    marginTop: 20,
    marginLeft: 14,
    color: '#5e6278',
    zIndex: 9999,
    paddingTop: 3,
  },
  companySearch: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    maxWidth: 800,
    paddingBottom: 0,
  },
  allCompaniesBtn: {
    marginTop: 8,
    marginLeft: 10,
    height: 52,
  },
  actions: {
    marginTop: theme.spacing(3),
  },
  buttons: {
    marginRight: theme.spacing(2),
  },
}));
