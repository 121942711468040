import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { getResponseMessage } from '../../../../../utils/utils';
import { editManagerRole } from '../../../../../crud/users.crud';

export const useManagerRole = (showSuccess?: boolean) => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [loadingEditManager, setLoading] = useState(false);
  const [successEditManager, setSuccess] = useState(false);
  const editManager = useCallback(
    async (
      manager_id: number,
      role: 'ROLE_VENDOR' | 'ROLE_VENDOR_STAFF' | 'ROLE_BUYER' | 'ROLE_BUYER_STAFF'
    ) => {
      setLoading(true);
      editManagerRole(manager_id, role)
        .then(() => {
          setSuccess(true);
          showSuccess &&
            enqueueSnackbar(`${intl.formatMessage({ id: 'ROLE.SAVED' })}`, {
              variant: 'success',
            });
        })
        .catch(e => {
          enqueueSnackbar(`${intl.formatMessage({ id: 'ERROR' })}: ${getResponseMessage(e)}`, {
            variant: 'error',
          });
        })
        .finally(() => {
          setLoading(false);
          setTimeout(() => {
            setSuccess(false);
          }, 200);
        });
    },
    [showSuccess]
  );
  return { editManager, loadingEditManager, successEditManager };
};
