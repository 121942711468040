import { useFormik } from 'formik';
import moment from 'moment';

import { IShift, IShiftEdit } from '../../../../interfaces/shift';

const getInitValues = (shift?: IShift | null) => {
  const initValues = {
    startString: shift ? shift.start : moment(Date.now()).format('YYYY-MM-DD[T]HH:mm'),
    endString: shift ? shift.end : moment(Date.now()).format('YYYY-MM-DD[T]HH:mm'),
    categoryId: shift?.category?.id || undefined,
    userId: shift?.user?.id || undefined,
    username: shift?.user?.login || '',
    status: shift?.status || 'new',
    price: shift?.price || 0,
    companyId: shift?.company?.id || undefined,
    storeId: shift?.store?.id || undefined,
    storename: shift?.store?.name || '',
  };
  return initValues;
};

export const useFormikAddShift = (
  createShift: (data: IShiftEdit) => void,
  editShift: (id: number, data: IShiftEdit) => void,
  shift?: IShift | null
) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitValues(shift),
    onSubmit: submitValues => {
      const data: IShiftEdit = {
        startString: submitValues.startString,
        endString: submitValues.endString,
        categoryId: submitValues.categoryId || undefined,
        companyId: submitValues.companyId || undefined,
        userId: submitValues.userId || undefined,
        status: submitValues.status,
        price: submitValues.price,
        storeId: submitValues.storeId || undefined,
      };
      shift ? editShift(shift.id, data) : createShift(data);
    },
  });

  return formik;
};
