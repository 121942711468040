import React, { FC } from 'react';
import { Card } from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import homeStyles from '../../../homeStyles';

export const Container: FC<{ children: React.ReactNode }> = ({ children }) => {
  const homeClasses = homeStyles();

  return (
    <Row>
      <Col>
        <Card className={homeClasses.classes.container}>{children}</Card>
      </Col>
    </Row>
  );
};
