import React from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/styles';
import {createTheme} from "@mui/material/styles";

const theme = createTheme(
  /**
   * @see https://material-ui.com/customization/themes/#theme-configuration-variables
   */
  {
    // direction: "rtl",
    typography: {
      fontFamily: ['Open Sans'].join(','),
    },

    breakpoints: {
      values: {
        sm: 600, // Small screen / phone
        md: 920, // Medium screen / tablet
        lg: 1200, // Large screen / desktop
        xl: 1500,
        xs: 0,
        // xxl: 1800,
      },
    },

    palette: {
      contrastThreshold: 3,
      primary: {
        // light: will be calculated from palette.primary.main,
        main: '#369ff7',
        contrastText: '#fff',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        // light: will be calculated from palette.primary.main,
        //main: '#0abb87',
        main: '#fd397a',
        // dark: will be calculated from palette.primary.main,
        contrastText: '#ffffff',
      },
      error: {
        // light: will be calculated from palette.primary.main,
        main: '#fd397a',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      success: {
        // light: will be calculated from palette.primary.main,
        main: '#0abb87',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
    },
    // overrides: {
    //   // PrivateNotchedOutline: {
    //   //   legendNotched: {
    //   //     maxWidth: '0px',
    //   //   }
    //   // },
    //   MuiTooltip: {
    //     tooltip: {
    //       fontSize: '12px',
    //     },
    //   },
    //   MuiButton: {
    //     // raisedPrimary: {
    //     //   color: 'white',
    //     // },
    //   },
    // },

    /**
     * @see https://material-ui.com/customization/globals/#default-props
     */
    // props: {
    //   // Name of the component ⚛️
    //   MuiButtonBase: {
    //     // The properties to apply
    //     // raisedPrimary: { color: '#fff' },
    //     disableRipple: true, // No more ripple, on the whole application 💣!
    //   },

    //   // Set default elevation to 1 for popovers.
    //   MuiPopover: {
    //     elevation: 1,
    //   },
    // },
  }
);

export default function ThemeProvider(props: { children: React.ReactNode }) {
  const { children } = props;

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}
