import React, { useCallback, useMemo, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Collapse,
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
} from '@mui/material';
import { useIntl } from 'react-intl';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import moment from 'moment';
import { ViewMode } from '../../orders/OrderPage';
import { IUser, UserRoles } from '../../../../interfaces/user';
import { useFormatMessage } from '../../../../hooks';
import { thousands } from '../../../../utils/utils';
import { IOrder } from '../../../../interfaces/order';
import { TRole } from '../../../auth/interfaces';
import { API_DOMAIN } from '../../../../constants';

type TProps = {
  item: IOrder;
  me?: IUser;
  userRole?: TRole[];
  setDeleteId: (value: number | undefined) => void;
  setAlertOpen: (value: boolean) => void;
  hideDeliveryCost: boolean;
  isBoxberryCompany: boolean;
};

const TableRowDetailedList: React.FC<TProps> = ({
  item,
  // me,
  userRole,
  // setDeleteId,
  // setAlertOpen,
  hideDeliveryCost,
  isBoxberryCompany,
}) => {
  const intl = useIntl();
  const fm = useFormatMessage();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  // const daysShippingValue = useCallback((order: IOrder) => daysShipping(order), []);

  // const handleDeleteDialog = useCallback((id: number | undefined) => {
  //   setDeleteId(id);
  //   setAlertOpen(true);
  // }, []);

  const editAction = useCallback(
    (item: IOrder, mode: string) => {
      navigate(`/orders/${item.id}/${mode}`);
    },
    [navigate]
  );

  // const isVendor = useMemo(() => userRole?.[0] === UserRoles.ROLE_VENDOR, [userRole]);
  const isAdmin = useMemo(() => userRole?.[0] === UserRoles.ROLE_ADMIN, [userRole]);

  // const newChat = useCallback((item: any) => {
  //   navigate(`/chats`, {
  //     state: {
  //       createUserChat: item?.user,
  //     },
  //   });
  // }, []);

  // const newUserChat = useCallback((item?: any) => {
  //   if (item?.company && item?.company.id) {
  //     navigate(`/chats`, {
  //       state: {
  //         createCompanyChat: item?.company,
  //       },
  //     });
  //   } else {
  //     navigate(`/chats`, {
  //       state: {
  //         createUserChat: me,
  //       },
  //     });
  //   }
  // }, []);

  return (
    <>
      <TableRow key={item.id}>
        <TableCell>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}
            disabled={item.items.length === 0}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell onClick={() => item.items.length && setOpen(!open)}>{item.id}</TableCell>

        <TableCell onClick={() => item.items.length && setOpen(!open)}>
          {item.checkout_at ? moment(item.checkout_at).format('DD.MM.YY') : ''}
        </TableCell>

        {!isBoxberryCompany && (
          <TableCell onClick={() => item.items.length && setOpen(!open)}>
            {item.goods_num}
          </TableCell>
        )}

        {!isBoxberryCompany && (
          <TableCell onClick={() => item.items.length && setOpen(!open)}>
            {item.accord_data?.order_state_description || 'Новый'}
          </TableCell>
        )}

        {isAdmin && !hideDeliveryCost && <TableCell>{item.delivery_cost || 0}</TableCell>}
        {!isBoxberryCompany && (
          <TableCell onClick={() => item.items.length && setOpen(!open)}>
            {item.order_summ || 0}
          </TableCell>
        )}
        {!isBoxberryCompany && (
          <TableCell onClick={() => item.items.length && setOpen(!open)}>
            {item.paid_bonuses || 0}
          </TableCell>
        )}

        {!isBoxberryCompany && (
          <TableCell onClick={() => item.items.length && setOpen(!open)}>
            {item.order_summ + (item.delivery_cost || 0) - (item.paid_bonuses || 0)}
          </TableCell>
        )}

        <TableCell>
          <div>
            <Tooltip
              title={intl.formatMessage({
                id: 'PRODUCTS.TABLE.TOOLTIP.VIEW',
              })}
            >
              <IconButton
                onClick={() => {
                  editAction(item, ViewMode.VIEW);
                }}
              >
                <VisibilityIcon color='primary' />
              </IconButton>
            </Tooltip>

            {isAdmin && (
              <Tooltip
                title={intl.formatMessage({
                  id: 'PRODUCTS.TABLE.TOOLTIP.EDIT',
                })}
              >
                <IconButton
                  onClick={() => {
                    editAction(item, ViewMode.EDIT);
                  }}
                >
                  <EditIcon color='primary' />
                </IconButton>
              </Tooltip>
            )}
            {/* {isAdmin && (
              <Tooltip
                title={intl.formatMessage({
                  id: 'PRODUCTS.TABLE.TOOLTIP.DELETE',
                })}
              >
                <IconButton
                  onClick={() => {
                    handleDeleteDialog(item.id);
                  }}
                >
                  <DeleteIcon color='secondary' />
                </IconButton>
              </Tooltip>
            )} */}
            {/* {isAdmin && (
              <IconButton
                onClick={() => {
                  newChat(item);
                }}
                color='primary'
                aria-label='previous'
              >
                <MessageIcon />
              </IconButton>
            )}
            {isVendor && (
              <IconButton
                onClick={() => {
                  newUserChat(me);
                }}
                color='primary'
                aria-label='previous'
              >
                <MessageIcon />
              </IconButton>
            )} */}
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={isAdmin ? 11 : 9}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box margin={1}>
              <Typography variant='h6' gutterBottom component='div'>
                {fm('MENU.PRODUCTS')}
              </Typography>
              <Table size='small' aria-label='purchases'>
                <TableHead>
                  <TableRow>
                    <TableCell>{fm('PRODUCT.TABLE.PREVIEW')}</TableCell>
                    <TableCell>{fm('PRODUCT.TABLE.NAME')}</TableCell>
                    <TableCell>{fm('PRODUCT.TABLE.PRICE')}</TableCell>
                    <TableCell>{fm('CART.TOTAL')}</TableCell>
                    <TableCell>{fm('SETTING.PROFILE.BONUSES')}</TableCell>
                    <TableCell>{fm('MENU.COMPANY')}</TableCell>
                    <TableCell>{fm('PRODUCT.TABLE.COUNT')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {item.items.map((orderItem, index) => (
                    <TableRow key={index.toString()}>
                      <TableCell>
                        {orderItem.product?.photos && orderItem.product.photos.length > 0 && (
                          <RouterLink to={`/viewproduct/${orderItem.product.id}`}>
                            <img
                              src={`${API_DOMAIN}/${
                                orderItem.product?.photos?.find((el: any) => el.main)?.small ||
                                orderItem.product?.photos[0].small
                              }`}
                              style={{ width: 50, height: 50 }}
                              alt={orderItem.product.name}
                            />
                          </RouterLink>
                        )}
                      </TableCell>
                      <TableCell>
                        <RouterLink to={`/viewproduct/${orderItem.product.id}`}>
                          {orderItem.product.name}
                        </RouterLink>
                      </TableCell>
                      <TableCell>
                        {orderItem.product.price
                          ? `${thousands(String(orderItem.product.price))} ₽`
                          : '-'}
                      </TableCell>
                      <TableCell>{`${orderItem.summ} ₽` || '-'}</TableCell>
                      <TableCell>{orderItem.discount}</TableCell>
                      <TableCell>
                        <RouterLink to={`/viewcompany/${orderItem.product.company?.id}`}>
                          {orderItem.product.company?.name}
                        </RouterLink>
                      </TableCell>
                      <TableCell component='th' scope='row'>
                        {orderItem.count}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Typography variant='h6' gutterBottom component='div'>
                {`${fm('ORDER.TABLE.SUM_ORDER')} ${thousands(String(item.summ))} ₽`}
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default React.memo(TableRowDetailedList);
