import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    containerPaper: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 20,
      boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
    },
    container: {
      paddingTop: 50,
      paddingBottom: 50,
      [theme.breakpoints.down('md')]: {
        paddingLeft: 40,
        paddingRight: 40,
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: 50,
        paddingRight: 50,
      },
    },
    containerTop: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 35,
      [theme.breakpoints.up('sm')]: {
        width: 400,
      },
    },
    containerBot: {
      // marginTop: 35,
    },
    imgContainer: {
      height: 400,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        maxWidth: 280,
        height: 200,
      },
      [theme.breakpoints.only('sm')]: {
        maxWidth: 350,
        height: 250,
      },
    },
    img: {
      maxWidth: 430,
      [theme.breakpoints.down('xs')]: {
        maxWidth: 200,
      },
      [theme.breakpoints.only('sm')]: {
        maxWidth: 250,
      },
      cursor: 'pointer',
    },
    galleryContainer: {
      [theme.breakpoints.down('xs')]: {
        maxWidth: 280,
        maxHeigth: 280,
      },
      [theme.breakpoints.only('sm')]: {
        maxWidth: 350,
        maxHeigth: 350,
      },
    },
    productInfo: {
      paddingLeft: 35,
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 0,
        marginTop: 25,
      },
    },
    addToCartBtn: {},
    actions: {
      marginTop: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        marginTop: 10,
      },
    },
    richEditor: {
      [theme.breakpoints.up('lg')]: {
        width: 950,
      },
    },
    richEditor1: {},
    header: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 40,
    },
    listItem: {
      marginBottom: 15,
    },
    listValue: {
      fontSize: 19,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#3f4254',
      marginBottom: 5,
    },
    listKey: {
      marginBottom: 0,
    },
    companyCard: {
      padding: '25px 30px',
      borderRadius: '4px',
      backgroundColor: '#f5f8fa',
    },
  })
);

export const useStylesLoyalties = makeStyles((theme: any) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      padding: '0',
    },
    ordersCol: {
      width: '100%',
      // [theme.breakpoints.up('md')]: {
      //   width: 'calc(100% - 326px - 30px)',
      //   '&.full': {
      //     width: '100%',
      //   },
      // },
    },
    filterCol: {
      width: '100%',
      marginBottom: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        width: 326,
      },
    },
    filter: {
      marginTop: theme.spacing(3),
      marginBottom: 5,
      overflow: 'visible',
    },
    title: {
      marginBottom: 20,
      textTransform: 'capitalize',
    },
    empty: {
      marginBottom: 20,
      marginTop: 20,
    },
    card: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    addBtn: {
      marginBottom: 10,
    },
  })
);

export const useStylesLoyaltyEdit = makeStyles((theme: any) => ({
  card: {
    marginTop: theme.spacing(3),
  },
  actions: {
    marginTop: theme.spacing(3),
  },
  buttons: {
    marginRight: theme.spacing(2),
  },
  btnDel: {
    marginLeft: theme.spacing(2),
  },
  delButton: {
    marginLeft: theme.spacing(2),
  },
  switch: {
    marginLeft: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  managers: {
    minHeight: 55,
    marginTop: 10,
    padding: theme.spacing(1.5),
  },
  managersTitle: {
    marginBottom: 10,
  },
  rootTable: {
    marginBottom: 20,
    minHeight: 200,
  },
  input: {
    height: 36,
    width: '100%',
    textAlign: 'right',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  title: {
    marginBottom: 10,
    marginTop: 10,
    textTransform: 'capitalize',
  },
}));
