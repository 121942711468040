import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => {
  return {
    container: {
      maxWidth: 1640,
      overflow: 'hidden',
      position: 'relative',
      height: 400,
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#FFF',
      borderRadius: 4,
      margin: '0 auto',
    },
    dotContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 30,
      marginTop: 15,
      margin: '0 auto',

      [theme.breakpoints.up('lg')]: {
        maxWidth: 708,
      },
      [theme.breakpoints.up('xl')]: {
        maxWidth: 955,
      },
    },
    wrapper: {
      display: 'flex',
      gridGap: 24,
      position: 'absolute',
      transition: '.4s ease-out',
      alignItems: 'center',
      left: 0,
    },
    productCard: {
      minWidth: 220,
      height: 294,
      transition: '.4s ease-out',
      cursor: 'pointer',
      boxShadow: 'none !important',
      display: 'flex',
      alignItems: 'center',
      borderRadius: 4,
      justifyContent: 'center',
    },
    productCardMedia: {
      width: 220,
      height: 294,
    },
    carousel: {
      display: 'gird',
      gridGap: 25,
      justifyContent: 'space-between',
      width: '100%',
    },
    arrow: {
      position: 'absolute',
      top: '44%',
      minWidth: 42,
      minHeight: 42,
      maxWidth: 50,
      maxHeight: 50,
      backgroundColor: 'rgba(255, 255, 255, 0.4)',
      borderRadius: 20,
      cursor: 'pointer',
      zIndex: 100,
      '&:active': {
        opacity: 0.3,
      },
    },
    arrowLeft: {
      transform: 'translateY(-12px) rotate(180deg)',
      left: -80,
      [theme.breakpoints.down('sm')]: {
        left: 15,
      },
      [theme.breakpoints.down(1400)]: {
        left: -9,
      },
      boxShadow: '0.5px 0px 8px 2px rgba(0, 0, 0, 0.09)',
    },
    arrowRight: {
      right: -80,
      transform: 'translateY(-12px)',
      [theme.breakpoints.down('sm')]: {
        right: 15,
      },
      [theme.breakpoints.down(1400)]: {
        right: -9,
      },
      boxShadow: '0.5px 0px 8px 2px rgba(0, 0, 0, 0.09)',
    },
    arrowIcon: {
      color: theme.palette.primary.main,
      width: '100%',
      height: '100%',
    },
    cardSlideCenter: {
      opacity: 1,
      transform: 'scale(1.2)',
      marginLeft: 24,
      marginRight: 24,
    },
    cardSlideBackground: {
      opacity: 0.5,
    },
    dotsButton: {
      maxWidth: 12,
      minHeight: 12,
      minWidth: 12,
      borderRadius: 4,
      backgroundColor: theme.palette.secondary.main,
      marginRight: 15,
      marginLeft: 15,
      opacity: 0.5,
      listStyle: 'none',
      cursor: 'pointer',

      '&:hover': {
        opacity: 0.2,
      },
    },
    dotsActive: {
      opacity: 1,
    },
  };
});
