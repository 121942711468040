import { useFormik } from 'formik';
import * as Yup from 'yup';
import {useDefineUserRole, useFormatMessage} from '../../../../../hooks';
import { ICompany } from '../../../../../interfaces/companies';
import { IUser, UserRoleType } from '../../../../../interfaces/user';
import { IStore } from '../../../../../interfaces/store';

export const getInitialValues = (
  role:UserRoleType,
  me?: IUser
): {
  email: string;
  role: UserRoleType;
  phoneNumbers: string;
  companies: ICompany[];
  categories: string[];
  stores: IStore[];
} => {
  const isBuyer = useDefineUserRole(me, ['ROLE_BUYER', 'ROLE_BUYER_STAFF']);
  const isVendor = useDefineUserRole(me, ['ROLE_VENDOR', 'ROLE_VENDOR_STAFF']);
  const companies = (isBuyer || isVendor) && me?.company
      ? [me?.company]
      : [];

  return {
    email: '',
    role,
    companies,
    phoneNumbers: '',
    categories: [],
    stores: [],
  };
};

export const useFormikUserInvite = (
  role: UserRoleType,
  fetchInvite: (data: {
    role: string;
    email: string;
    country_code: string;
    phone_numbers: string;
    login: string;
    stores_ids?: string | undefined;
    categories_ids?: string | undefined;
    companies_ids?: string | undefined;
  }) => void,
  countryData: any,
  me?: IUser
) => {
  const fm = useFormatMessage();
  const isBuyer = useDefineUserRole(me, ['ROLE_BUYER', 'ROLE_BUYER_STAFF']);
  const isVendor = useDefineUserRole(me, ['ROLE_VENDOR', 'ROLE_VENDOR_STAFF']);
  const roleManager = role === 'ROLE_MANAGER';

  return useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(role, me),
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required(fm('USER.EDIT.EMAIL.REQUIRED'))
        .email(fm('USER.EDIT.EMAIL.INCORRECT'))
        .nullable(),
      phoneNumbers: Yup.string().required(`${fm('USER.EDIT.PHONE.REQUIRED')}`),
      categories: Yup.array().min(roleManager ? 1 : 0, fm('AUTH.VALIDATION.REQUIRED_FIELD')),
      companies: Yup.array().min(1, fm('AUTH.VALIDATION.REQUIRED_FIELD')),
      stores: isBuyer || isVendor
        ? Yup.array()
        : Yup.array().min(1, fm('AUTH.VALIDATION.REQUIRED_FIELD')),
    }),

    onSubmit: values => {
      const data = {
        role,
        email: values.email,
        login: values.email,
        country_code: countryData?.code || '420',
        phone_numbers: values.phoneNumbers,
        stores_ids: values.stores.map(i => i.id).toString(),
        categories_ids: values.categories.toString(),
        companies_ids: values.companies.map(i => i.id).toString(),
      };
      fetchInvite(data);
    },
  });
};
