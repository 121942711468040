import { useCallback, useEffect, useState } from 'react';
import { ICategoryItem } from '../../categories/interfaces';

export const levelsSubCategories = [1, 2, 3, 4, 5];

export const useSubCategories: any = (
  values: any,
  setFieldValue: any,
  categories?: ICategoryItem[]
) => {
  const [subCategories, setSubCategories] = useState<ICategoryItem[][]>([]);

  const getSubCategories = useCallback(
    (level: number) => {
      return subCategories[level];
    },
    [subCategories]
  );

  useEffect(() => {
    if (values.mainCategoryId && categories) {
      const newCategories = categories.find(
        item => item.id?.toString() === values.mainCategoryId.toString()
      );
      if (newCategories && newCategories.children) {
        if (
          subCategories[1] &&
          newCategories.children[0] &&
          subCategories[1].length > 0 &&
          subCategories[1][0].id !== newCategories.children[0].id
        ) {
          levelsSubCategories.forEach(level => setFieldValue(`categoryId_${level}`, ''));
        }
        const subCategoriesLvl1 = newCategories.children;
        const subCategoriesLvl2: ICategoryItem | undefined =
          values.categoryId_1 &&
          subCategoriesLvl1?.length &&
          newCategories.children.find(
            item => item.id?.toString() === values.categoryId_1?.toString()
          );
        const subCategoriesLvl3: ICategoryItem | undefined =
          values.categoryId_2 &&
          subCategoriesLvl2?.children?.length &&
          subCategoriesLvl2.children.find(
            item => item.id?.toString() === values.categoryId_2?.toString()
          );
        const subCategoriesLvl4: ICategoryItem | undefined =
          values.categoryId_3 &&
          subCategoriesLvl3?.children?.length &&
          subCategoriesLvl3.children.find(
            item => item.id?.toString() === values.categoryId_3?.toString()
          );
        const subCategoriesLvl5: ICategoryItem | undefined =
          values.categoryId_4 &&
          subCategoriesLvl4?.children?.length &&
          subCategoriesLvl4.children.find(
            item => item.id?.toString() === values.categoryId_4?.toString()
          );
        if (subCategoriesLvl1.length === 0) {
          setFieldValue(`categoryId_1`, '');
          setFieldValue(`categoryId_2`, '');
          setFieldValue(`categoryId_3`, '');
          setFieldValue(`categoryId_4`, '');
          setFieldValue(`categoryId_5`, '');
        }
        if (subCategoriesLvl2?.children?.length === 0) {
          setFieldValue(`categoryId_2`, '');
          setFieldValue(`categoryId_3`, '');
          setFieldValue(`categoryId_4`, '');
          setFieldValue(`categoryId_5`, '');
        }
        if (subCategoriesLvl3?.children?.length === 0) {
          setFieldValue(`categoryId_3`, '');
          setFieldValue(`categoryId_4`, '');
          setFieldValue(`categoryId_5`, '');
        }
        if (subCategoriesLvl4?.children?.length === 0) {
          setFieldValue(`categoryId_4`, '');
          setFieldValue(`categoryId_5`, '');
        }
        if (subCategoriesLvl5?.children?.length === 0) {
          setFieldValue(`categoryId_5`, '');
        }
        setSubCategories(state => {
          const newArr = [...state];
          newArr[1] = subCategoriesLvl1.length ? subCategoriesLvl1 : [];
          newArr[2] = subCategoriesLvl2?.children?.length ? subCategoriesLvl2?.children : [];
          newArr[3] = subCategoriesLvl3?.children?.length ? subCategoriesLvl3?.children : [];
          newArr[4] = subCategoriesLvl4?.children?.length ? subCategoriesLvl4?.children : [];
          newArr[5] = subCategoriesLvl5?.children?.length ? subCategoriesLvl5?.children : [];
          return newArr;
        });
      } else {
        setSubCategories([]);
      }
    } else {
      setSubCategories([]);
    }
  }, [
    categories,
    values.mainCategoryId,
    values.categoryId_1,
    values.categoryId_2,
    values.categoryId_3,
    values.categoryId_4,
    values.categoryId_5,
  ]);

  return [subCategories, getSubCategories];
};
