import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { getResponseMessage } from '../../../../utils/utils';
import { changeParamGroup } from '../../../../crud/productType.crud';
import { useFormatMessage } from '../../../../hooks';

export const useChangeParamGroup = (successFunc?: () => void) => {
  const { enqueueSnackbar } = useSnackbar();
  const fm = useFormatMessage();
  const [loadingParam, setLoading] = useState(false);

  const handleParam = useCallback(async (id: number, add?: number, remove?: number) => {
    setLoading(true);
    changeParamGroup(id, add, remove)
      .then(() => {
        successFunc && successFunc();
        enqueueSnackbar(
          fm(remove ? 'PRODUCT.TYPE.PARAM.DELETE' : 'PRODUCT.TYPE.PARAM.SAVED'),
          { variant: 'success' }
        );
      })
      .catch(e => {
        enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { handleParam, loadingParam };
};
