import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProfileEditPage from './profile/ProfileEditPage';

export default function SettingsProfilePage() {
  return (
    <Routes>
      <Route path='profile' element={<ProfileEditPage />} />
    </Routes>
  );
}
