import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useCallback } from 'react';
import { ICreatePublication } from '../../interfaces';
import { useFormatMessage, useDefineUserRole } from '../../../../../hooks';

const getValidationScheme = () => {
  const fm = useFormatMessage();

  return Yup.object().shape({
    title: Yup.string().required(fm('AUTH.VALIDATION.REQUIRED_FIELD')),
  });
};

const getInitValue = () => {
  return {
    title: '',
    description: '',
    text: '',
    type: 'admin',
    favorite: false,
  };
};

export const useCustomFormik = ({
  submit,
  editorState,
  images,
  me,
}: {
  submit: (props: { data: FormData }) => void;
  editorState: any;
  images: File[];
  me: any;
}) => {
  const isAdmin = useDefineUserRole(me, 'ROLE_ADMIN');

  const submitHandler = useCallback(
    (values: ICreatePublication) => {
      const data = new FormData();
      data.append('title', values.title);
      data.append('description', values.description);
      if (isAdmin) {
        data.append('type', values.type);
        data.append('favorite', values.favorite ? 'true' : 'false');
      }

      let text = '';
      if (editorState) {
        text = editorState.getData() || '';
      }
      values.text = text;
      data.append('text', text);

      if (images.length > 0) {
        data.append('photo', images[0]);
      }

      submit({ data });
    },
    [submit, editorState, images]
  );

  return useFormik({
    enableReinitialize: true,
    initialValues: getInitValue(),
    validationSchema: getValidationScheme(),
    onSubmit: submitHandler,
  });
};
