import React, { useState } from 'react';
import { FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import ButtonWithLoader from '../../../../../components/other/Buttons/ButtonWithLoader';
import { IUser } from '../../../../../interfaces/user';
import homeStyles from '../../../../../constants/homeStyles';
import { useStylesBonusesUser } from '../hooks/useStyles';

type TProps = {
  user: IUser;
  editLoading: boolean;
  loadingPush: boolean;
  isAdd: boolean;
  addDevice: (platform: string, token: string) => void;
  push: (user_id: number, platform: string, message: string) => void;
};

const PushUser: React.FC<TProps> = ({
  user,
  editLoading,
  addDevice,
  isAdd,
  loadingPush,
  push,
}) => {
  const classes = useStylesBonusesUser();
  const homeClasses = homeStyles();
  const intl = useIntl();
  const [platform, setPlatform] = useState('ios');

  return (
    <Formik
      initialValues={{
        token: '',
        message: '',
      }}
      onSubmit={values => {
        isAdd ? push(user.id, platform, values.message) : addDevice(platform, values.token);
      }}
    >
      {({ values, errors, touched, handleBlur, handleSubmit, setFieldValue }) => (
        <form className={homeClasses.classes.form} onSubmit={handleSubmit} style={{}}>
          <Typography>{intl.formatMessage({ id: 'ADD.DEVICE' })}</Typography>
          <RadioGroup
            name='location.pickup_point'
            value={platform}
            onChange={e => {
              e.currentTarget.value && setPlatform(e.currentTarget.value);
            }}
            style={{ flexDirection: 'row' }}
          >
            <FormControlLabel value='ios' control={<Radio />} label='ios' />

            <FormControlLabel value='android' control={<Radio />} label='android' />
          </RadioGroup>
          <TextField
            type='text'
            label={intl.formatMessage({ id: 'TOKEN.DEVICE' })}
            margin='normal'
            name='token'
            value={values.token || null}
            variant='outlined'
            onBlur={handleBlur}
            onChange={e => {
              e.target.value.length < 500 && setFieldValue('token', e.target.value);
            }}
            helperText={touched.token && errors.token}
            error={Boolean(touched.token && errors.token)}
          />

          <div className={classes.actions}>
            <ButtonWithLoader disabled={editLoading || !values.token} type='submit'>
              {intl.formatMessage({ id: 'COMMON.BUTTON.ADD' })}
            </ButtonWithLoader>
          </div>
          <TextField
            disabled={!isAdd}
            style={{ marginTop: 40 }}
            type='text'
            label={intl.formatMessage({ id: 'PUSH.NOTIFICATION' })}
            margin='normal'
            name='message'
            multiline
            rows={5}
            value={values.message || null}
            variant='outlined'
            onBlur={handleBlur}
            onChange={e => {
              e.target.value.length < 500 && setFieldValue('message', e.target.value);
            }}
            helperText={touched.message && errors.message}
            error={Boolean(touched.message && errors.message)}
          />
          <div className={classes.actions}>
            <ButtonWithLoader disabled={!isAdd} loading={loadingPush} type='submit'>
              {intl.formatMessage({ id: 'SEND' })}
            </ButtonWithLoader>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default React.memo(PushUser);
