import axios from 'axios';
import { IShiftEdit } from '../interfaces/shift';

export const createShift = (data: IShiftEdit) => axios.post('api/work_shift', data);

export const editShift = (id: number, data: IShiftEdit) =>
  axios.put(`/api/work_shift/${id}`, data);

export const getShifts = (page = 1, perPage = 20, staff?: number, company?: number) =>
  axios.get('api/work_shifts', { params: { page, per_page: perPage, staff, company } });

export const getShift = (id: number) => axios.get(`api/work_shift/${id}`);

export const getStaffUsers = ({
  work_shift,
  free,
  start,
  end,
  categories,
  manager_id,
  page,
  per_page,
  active,
  show_managers,
  fio,
  phone,
}: {
  work_shift?: number;
  free?: boolean;
  start?: string;
  end?: string;
  categories?: string;
  manager_id?: number;
  page?: number;
  per_page?: number;
  active?: boolean;
  show_managers?: boolean;
  fio?: string;
  phone?: string;
}) =>
  axios.get('api/staff', {
    params: {
      work_shift,
      free,
      start,
      end,
      categories,
      manager_id,
      page,
      fio,
      phone,
      per_page,
      active: active ? true : null,
      show_managers: typeof show_managers === 'boolean' ? show_managers : undefined,
    },
  });
