import React from 'react';
import { Route, Routes } from 'react-router-dom';
import TransactionEditPage from './TransactionEditPage';
import TransactionPage from './TransactionsPage';

export default function Transactions() {
  return (
    <Routes>
      <Route path='new' element={<TransactionEditPage />} />
      <Route path='list' element={<TransactionPage />} />
      <Route path='edit/:transactionId' element={<TransactionEditPage />} />
    </Routes>
  );
}
