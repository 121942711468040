/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import {
  Button,
  Typography,
  Card,
  Table,
  CardContent,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TableFooter,
  Avatar,
  Tooltip,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MessageIcon from '@mui/icons-material/Message';

import { SHeadTableCell } from '../../../../components/styledComponents/Tables';
import Preloader from '../../../../components/other/Preloader/Preloader';
import { Modal } from '../../../../components/other/Modals';
import TablePaginator from '../../../../components/tableComponents/TablePaginator/TablePaginator';

import { setLayoutFooter, setLayoutSubheader } from '../../../../utils/layout';
import { IAppState } from '../../../../store/rootDuck';
import { actions as usersActions } from '../../../../store/ducks/users.duck';
import { actions as tariffActions } from '../../../../store/ducks/tariff.duck';
import { getRole, getRoleBreadcrumbName, getFio } from './utils';
import useCrudSnackbar from '../../../../hooks/useCrudSnackbar';
import { UserListType } from '../constants';
import homeStyles from '../../../../constants/homeStyles';
import { useStyles } from './hooks/useStyles';
import { useDownloadUsersCallback } from './hooks/useDownloadUsersCallback';
import { IUser, UserRoles } from '../../../../interfaces/user';
import TariffModal from '../../tariffs/components/UserCurrentTariff/TariffsModal';
import { getDateStop } from '../../tariffs/utils/getStopDate';
import MultiSelect from '../../../../components/ui/MultiSelect';
import {useDefineUserRole, useFormatMessage} from '../../../../hooks';
import { useUserAttributes } from './hooks/getUserAttributes';
import { API_DOMAIN } from '../../../../constants';
import { useCreateChat } from '../../chats/chatsPage/hooks/useCreateChat';

import AutocompleteStaff from '../../../../components/AutocompleteStaff';
import AutocompleteStaffPhone from '../../../../components/AutocompleteStaffPhone';
import { useSearchUsers } from './hooks/useSearchUsers';

const UsersPage: React.FC<TPropsFromRedux> = ({
  page,
  perPage,
  total,
  userRole,
  users,
  fetch,
  loading,
  clearDelUser,
  delUser,
  delUserSuccess,
  delUserLoading,
  delUserError,
  filter,
  setFilter,
  fetchTariffs,
  clearAddUserId,
  tariffs,
  loadingTariff,
  setUsersList,
  me
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const homeClasses = homeStyles();
  const navigate = useNavigate();
  const { type } = useParams();
  const fm = useFormatMessage();

  const [open, setOpen] = React.useState(false);
  const [userId, setUserId] = React.useState<number>();
  const role: any = getRole(type as UserListType);
  const downloadUsers = useDownloadUsersCallback(role);
  const [editUserTariff, setEditUserTariff] = useState<IUser | null>(null);
  const { userAttr } = useUserAttributes();
  const { loadingCreated } = useCreateChat();

  const [checkSearch, setCheckSearch] = useState('name');
  const [fio, setFio] = useState({} as IUser);

  const isVendor = useMemo(() => role === 'ROLE_VENDOR', [role]);
  const isVendorStaff = useMemo(() => role === 'ROLE_VENDOR_STAFF', [role]);
  const isBuyer = useMemo(() => role === 'ROLE_BUYER', [role]);
  const isAdmin = useMemo(() => userRole?.[0] === UserRoles.ROLE_ADMIN, [userRole]);
  const meBuyer = useDefineUserRole(me, ['ROLE_BUYER', 'ROLE_BUYER_STAFF']);
  const meVendor = useDefineUserRole(me, ['ROLE_VENDOR', 'ROLE_VENDOR_STAFF']);

  const {
    fetchUsers,
    loading: searchLoading,
    loadingPhone,
    page: searchPage,
    perPage: searchPerPage,
    users: searchUsers,
  } = useSearchUsers();

  const searchGetUsersStaff = useCallback(
    (data: { fio?: string; phone?: string }) => {
      fetchUsers({
        page: searchPage,
        per_page: searchPerPage,
        fio: data.fio,
        phone: data.phone,
      });
    },
    [fio, role, searchPage, searchPerPage]
  );

  // For delete actions
  useCrudSnackbar({
    success: delUserSuccess,
    error: delUserError,
    clear: clearDelUser,
    successMessage: fm('USER.SNACKBAR.DELETE.SUCCESS'),
    errorMessage: `${fm('ERROR')}: ${delUserSuccess}`,
    afterSuccessOrError: () => {
      setOpen(false);
      clearDelUser();
    },
    afterSuccess: () => {
      fetch({ page, per_page: perPage, roles: role, filter });
    },
  });

  const getTariffs = useCallback(() => {
    if (isVendor || isBuyer || isVendorStaff) {
      fetchTariffs({ role });
    }
  }, [isVendor, isBuyer, role, fetchTariffs, isVendorStaff]);

  useEffect(() => {
    clearAddUserId();
    getTariffs();
    // searchGetUsersStaff({})
    fetch({ page, per_page: perPage, roles: role, filter });
    return () => {
      clearDelUser();
    };
  }, [role, filter]);

  const successEditUserTariff = useCallback(() => {
    setEditUserTariff(null);
    fetch({ page, per_page: perPage, roles: role, filter });
  }, [page, perPage, role, filter]);

  setLayoutSubheader({
    title: intl.formatMessage(
      { id: 'SUBMENU.USER_BY_ROLE.LIST' },
      { role: intl.formatMessage({ id: getRoleBreadcrumbName(type as UserListType) }) }
    ),
    breadcrumb: [],
  });
  setLayoutFooter({ show: true });

  // const newChat = useCallback((companyId?: string | number, userId?: string | number) => {
  //   if (companyId) {
  //     createChatFetch({ companyId });
  //   }
  //   createChatFetch({ userId });
  // }, []);

  if (loading || loadingCreated || loadingTariff) return <Preloader />;

  return (
    <>
      <Row>
        <Col>
          <Button
            color='primary'
            variant='contained'
            onClick={() => navigate(`/users/new/${type}`)}
            className={classes.addBtn}
          >
            {fm('COMMON.BUTTON.ADD')}
          </Button>

          <Button
            style={{ marginLeft: '15px' }}
            color='primary'
            variant='contained'
            onClick={downloadUsers}
            className={classes.addBtn}
          >
            {fm('BUTTON.DOWNLOAD')}
          </Button>

          {(isAdmin || meBuyer || meVendor) &&
            (role === 'ROLE_BUYER' ||
              role === 'ROLE_VENDOR') && (
              <Button
                style={{ marginLeft: '15px' }}
                color='primary'
                variant='contained'
                onClick={() => navigate(`/users/invite/${type}`)}
                className={classes.addBtn}
              >
                {fm('INVITE')}
              </Button>
            )}
          <div className={classes.container}>
            <div>
              <div className={classes.filterColTop}>
                {(isVendor || isBuyer) && (
                  <Card
                    style={{
                      padding: '25px 30px 0px ',
                      overflow: 'visible',
                    }}
                  >
                    <RadioGroup
                      name='checkSearch'
                      value={checkSearch}
                      onChange={e => {
                        e.currentTarget.value && setCheckSearch(e.currentTarget.value);
                        setFio({} as IUser);
                      }}
                      style={{ flexDirection: 'row' }}
                    >
                      <FormControlLabel
                        value='id'
                        control={<Radio color='primary' />}
                        label={fm('USER.SEARCH.STAFF.ID')}
                      />
                      <FormControlLabel
                        value='name'
                        control={<Radio color='primary' />}
                        label={fm('USER.SEARCH.FIO')}
                      />
                      <FormControlLabel
                        value='number'
                        control={<Radio color='primary' />}
                        label={fm('USER.SEARCH.PHONE')}
                      />
                    </RadioGroup>
                    {checkSearch === 'name' && (
                      <AutocompleteStaff
                        options={searchUsers || []}
                        loading={searchLoading}
                        inputValue={fio}
                        editable
                        label={fm('USER.SEARCH.STAFF.FIO')}
                        inputClassName={{ width: '100%' }}
                        searchStaff={searchGetUsersStaff}
                        clearUsers={() => {
                          fetch({ page, per_page: perPage, roles: role, filter });
                        }}
                        setSelectedValue={value => {
                          setUsersList([value]);
                          // value && setFio(value);
                          // setPhone('');
                        }}
                        disable={false}
                      />
                    )}
                    {checkSearch === 'number' && (
                      <AutocompleteStaffPhone
                        options={searchUsers || []}
                        loading={loadingPhone}
                        label={fm('USER.SEARCH.STAFF.PHONE')}
                        inputClassName={{ width: '100%' }}
                        searchStaff={searchGetUsersStaff}
                        clearUsers={() => {
                          fetch({ page, per_page: perPage, roles: role, filter });
                        }}
                        setSelectedValue={value => {
                          setUsersList([value]);
                        }}
                      />
                    )}
                    {checkSearch === 'id' && (
                      <AutocompleteStaffPhone
                        options={searchUsers || []}
                        loading={loadingPhone}
                        isId
                        label={fm('USER.SEARCH.STAFF.ID')}
                        inputClassName={{ width: '100%' }}
                        searchStaff={searchGetUsersStaff}
                        clearUsers={() => {
                          fetch({ page, per_page: perPage, roles: role, filter });
                        }}
                        setSelectedValue={value => {
                          setUsersList([value]);
                        }}
                      />
                    )}
                  </Card>
                )}

                {userAttr && Object.keys(userAttr).length > 0 ? (
                  <div className={classes.filterCol}>
                    <Card className={classes.filter}>
                      {Object.keys(userAttr).map(attrKey => (
                        <MultiSelect
                          key={attrKey}
                          label={fm(attrKey)}
                          emptyValueLabel={fm('ORDER.FILTER.PLACEHOLDER')}
                          options={userAttr[attrKey].map(i => ({ value: i, label: i }))}
                          selectedValues={filter[attrKey] || []}
                          setSelectedValues={values => {
                            setFilter({ ...filter, [attrKey]: values });
                          }}
                        />
                      ))}
                    </Card>
                  </div>
                ) : null}
              </div>
            </div>

            {loading ? (
              <div className={classes.preloaderContainer}>
                <Preloader />
              </div>
            ) : !users.length ? (
              <Typography className={classes.empty} component='h5' variant='h5'>
                {fm('COMMON.LIST.EMPTY')}
              </Typography>
            ) : (
              <Card className={classes.tableContainer}>
                <CardContent className={homeClasses.classes.tableContainer}>
                  <Table aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <SHeadTableCell>{fm('USER.TABLE.ID')}</SHeadTableCell>
                        <SHeadTableCell style={{ textAlign: 'center' }}>
                          {intl.formatMessage({ id: 'SETTING.PROFILE.PHOTO' })}
                        </SHeadTableCell>
                        <SHeadTableCell>{fm('USER.TABLE.LOGIN')}</SHeadTableCell>
                        <SHeadTableCell>{fm('USER.TABLE.EMAIL')}</SHeadTableCell>
                        {/* <SHeadTableCell>{fm('USER.TABLE.ROLE')}</SHeadTableCell> */}
                        <SHeadTableCell>{fm('USER.TABLE.FIO')}</SHeadTableCell>
                        {/* <SHeadTableCell>Статус</SHeadTableCell> */}
                        <SHeadTableCell>{fm('USER.TABLE.COMPANY')}</SHeadTableCell>
                        {isBuyer && (
                          <>
                            <SHeadTableCell align='center'>
                              {fm('USER.TABLE.TARIFF')}
                            </SHeadTableCell>
                            <SHeadTableCell align='center'>
                              {fm('USER.TABLE.STOPDATETARIFF')}
                            </SHeadTableCell>
                          </>
                        )}

                        <SHeadTableCell align='right'>
                          {fm('USER.TABLE.ACTION')}
                        </SHeadTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users?.map(item => (
                        <TableRow key={item.id}>
                          <TableCell component='th' scope='row'>
                            {item.id || '-'}
                          </TableCell>
                          <TableCell>
                            {item.avatar ? (
                              <Avatar
                                style={{ margin: '0 auto' }}
                                src={`${API_DOMAIN}/${
                                  typeof item.avatar === 'string'
                                    ? item.avatar
                                    : item.avatar.small
                                }`}
                                className={classes.green}
                              />
                            ) : (
                              <div className={classes.companyNameAvatarWrapper}>
                                <span
                                  className='kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success'
                                  style={{
                                    width: 40,
                                    height: 40,
                                    borderRadius: 20,
                                    backgroundColor: '#566DC6FF',
                                  }}
                                >
                                  <b>
                                    {(item.first_name
                                      ? item.first_name.trim()[0]
                                      : ''
                                    ).toUpperCase()}
                                  </b>
                                </span>
                              </div>
                            )}
                          </TableCell>
                          <TableCell>{item.login || '-'}</TableCell>
                          <TableCell>{item.email || '-'}</TableCell>
                          {/* <TableCell>{getRoleName(item.roles || [], intl)}</TableCell> */}
                          <TableCell>{getFio(item)}</TableCell>
                          {/* <TableCell>
                            <StatusIndicator isActive={item.active} />
                          </TableCell> */}
                          <TableCell>
                            {item.company ? (
                              <Tooltip
                                title={item.company?.name}
                                aria-label={item.company?.name}
                              >
                                <Avatar className={classes.green} alt={item.company?.name}>
                                  {item.company?.name.slice(0, 1)}
                                </Avatar>
                              </Tooltip>
                            ) : (
                              <div className={classes.emptyCompany}>-</div>
                            )}
                          </TableCell>
                          {isBuyer && (
                            <>
                              {!item.current_user_tariff ? (
                                <>
                                  <TableCell align='center' />
                                  <TableCell align='center' />
                                </>
                              ) : (
                                <>
                                  <TableCell align='center'>
                                    <p
                                      className={classes.tariffText}
                                      onClick={() => setEditUserTariff(item)}
                                    >
                                      {item.current_user_tariff.tariff.name === 'Премиум'
                                        ? fm('TARIFF.PRIME')
                                        : fm('TARIFF.BASE')}
                                    </p>
                                  </TableCell>
                                  <TableCell align='center'>
                                    {item.current_user_tariff.tariff.name === 'Премиум'
                                      ? getDateStop(item.current_user_tariff)
                                      : ''}
                                  </TableCell>
                                </>
                              )}
                            </>
                          )}
                          <TableCell align='right'>
                            <div className={classes.actions}>
                              <IconButton
                                color='primary'
                                aria-label='previous'
                                onClick={() => {
                                  clearDelUser();
                                  navigate(`/users/edit/${type}/${item.id}`);
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                              {isAdmin && (
                                <IconButton
                                  color='secondary'
                                  aria-label='previous'
                                  onClick={() => {
                                    setUserId(item.id);
                                    setOpen(true);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )}
                              {isAdmin && (
                                <IconButton
                                  onClick={() => {
                                    // newChat(item.company?.id, item.id)
                                    navigate(`/chats`, {
                                      state: {
                                        createCompanyChat: item?.company,
                                        createUserChat: item,
                                      },
                                    });
                                  }}
                                  color='primary'
                                  aria-label='previous'
                                >
                                  <MessageIcon />
                                </IconButton>
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePaginator
                          page={page}
                          realPerPage={users.length}
                          perPage={perPage}
                          total={total}
                          fetchRows={(data: any) => fetch({ ...data, roles: role, filter })}
                          label={fm('USER.TABLE.PER_PAGE')}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </CardContent>
              </Card>
            )}
          </div>
        </Col>
      </Row>

      {!loading && (
        <TariffModal
          open={Boolean(editUserTariff)}
          handleClose={() => setEditUserTariff(null)}
          user={editUserTariff}
          successFunc={successEditUserTariff}
          tariffs={tariffs}
        />
      )}

      <Modal
        open={open}
        onClose={delUserLoading ? () => {} : () => setOpen(false)}
        title={fm('USER.DELETE.TITLE')}
        loading={delUserLoading}
        content={delUserLoading ? undefined : fm('USER.DELETE.TEXT')}
        actions={
          delUserLoading
            ? undefined
            : [
                {
                  title: fm('COMMON.BUTTON.CANCEL'),
                  onClick: () => setOpen(false),
                },
                {
                  title: fm('COMMON.BUTTON.DELETE'),
                  onClick: () => userId && delUser(userId),
                },
              ]
        }
      />
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    userRole: state.auth.user?.roles,
    page: state.users.page,
    perPage: state.users.per_page,
    total: state.users.total,
    users: state.users.users,
    loading: state.users.loading,
    delUserSuccess: state.users.delUserSuccess,
    delUserLoading: state.users.delUserLoading,
    delUserError: state.users.delUserError,
    filter: state.users.filter,
    tariffs: state.tariff.tariffs,
    loadingTariff: state.tariff.loading,
    me: state.profile.me,

  }),
  {
    fetch: usersActions.fetchRequest,
    clearDelUser: usersActions.clearDelUser,
    delUser: usersActions.delUserRequest,
    setUsersList: usersActions.setUsers,
    fetchTariffs: tariffActions.fetchRequest,
    setFilter: usersActions.setFilter,
    clearAddUserId: usersActions.clearAddUserId,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(UsersPage);
