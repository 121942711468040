import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Button, DialogProps, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { stateToHTML } from 'draft-js-export-html';
import { EditorState } from 'draft-js';
import { useNavigate } from 'react-router-dom';
import { stateFromHTML } from 'draft-js-import-html';
import { IAppState } from '../../../../store/rootDuck';
import { actions as companiesActions } from '../../../../store/ducks/companies.duck';
import { Modal } from '../../../../components/other/Modals';
import CompaniesTable from '../../../../components/tableComponents/Table/CompaniesTable';
import { IUser } from '../../../../interfaces/user';
import { useStyles } from '../hooks/useStyles';
import { ITender } from '../../../../interfaces/order';
import { ICompany } from '../../../../interfaces/companies';
import { useDefineUserRole, useFormatMessage } from '../../../../hooks';
import ButtonWithLoader from '../../../../components/other/Buttons/ButtonWithLoader';
import TrafficLight from '../../../../components/TrafficLight/TrafficLight';
import { useSanctionsCompany } from '../../companies/hooks/useSanctionsCompany';
// import { ReactComponent as IconCircleInfo } from '../../../../../_base/layout/assets/layout-svg-icons/iconCircleInfo.svg';
import { ReactComponent as IconCircleList } from '../../../../../_base/layout/assets/layout-svg-icons/iconCircleList.svg';
import { ReactComponent as IconCircleDel } from '../../../../../_base/layout/assets/layout-svg-icons/iconCircleDel.svg';
import { ReactComponent as IconCirclSearch } from '../../../../../_base/layout/assets/layout-svg-icons/iconCircleSearch.svg';
import { ReactComponent as SpinnerSmall } from '../../../../../_base/layout/assets/layout-svg-icons/spinnerSmall.svg';

import { useSearchCompany } from '../../companies/hooks/useSearchCompany';
import { useAddCompany } from '../../companies/hooks/useAddCompany';

const getInitialValues = (company: ICompany | null) => ({
  name: company ? company.name : '',
  brand: company ? company.brand : '',
  inn: company ? company.inn : '',
  ogrn: company ? company.ogrn : '',
  kpp: company ? company.kpp : '',
  okpo: company ? company.okpo : '',
  okved: company ? company.okved : '',
  phone_number: company ? company.phone_number : '',
  site: company ? company.site : '',
  role: company?.roles ? company.roles[0] : '',
});

interface IProps {
  tenderFormik: any;
  id: string;
  tender?: ITender;
  isFull?: boolean;
}

const TenderClientCompanyForm: React.FC<IProps> = ({ tenderFormik, id, tender, isFull }) => {
  const [openDialogCompanies, setOpenDialogCompanies] = useState(false);
  const [openSanctionsButtons, setOpenSanctionsButtons] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [searchSanctions, loadingSanctions, sanctions] = useSanctionsCompany();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const me = useSelector(({ profile }: IAppState) => profile.me, shallowEqual);
  const isManager = useDefineUserRole(me, 'ROLE_MANAGER');
  const fm = useFormatMessage();
  const [
    searchCompanyHook,
    loadingCompanySearch,
    companiesSearch,
    notFound,
  ] = useSearchCompany();
  const [addCompanyHook, loadingCompanyAdd, companiesAdd] = useAddCompany();

  const {
    companies,
    loadingCompanies,
    editCompanyLoading,
    pageCompanies,
    perPageCompanies,
    totalCompanies,
    loadingSearchCompanies,
    company,
    byIdSuccess,
  } = useSelector(
    ({ companies }: IAppState) => ({
      companies: companies.companies,
      loadingCompanies: companies.loading,
      editCompanyLoading: companies.editLoading,
      pageCompanies: companies.page,
      perPageCompanies: companies.per_page,
      totalCompanies: companies.total,
      loadingSearchCompanies: companies.searchLoading,
      company: companies.companyCustomer,
      byIdSuccess: companies.byCustomerIdSuccess,
    }),
    shallowEqual
  );
  const classes = useStyles();

  const { values, setValues } = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(company),
    validationSchema: Yup.object().shape({}),
    onSubmit: submitValues => {
      let description = '';
      const currentEditorContent = editorState.getCurrentContent();
      if (currentEditorContent.hasText()) {
        description = stateToHTML(currentEditorContent);
      }
      const data = {
        ...submitValues,
        description,
        roles: submitValues.role ? [submitValues.role] : null,
      };
      if (company?.id) {
        dispatch(companiesActions.editRequest({ id: +company.id, data }));
      }
    },
  });

  useEffect(() => {
    if (company?.description) {
      const contentState = stateFromHTML(company.description);
      setEditorState(EditorState.createWithContent(contentState));
    }
    if (byIdSuccess && company) {
      setValues(getInitialValues(company));
    }
  }, [byIdSuccess, company, setValues]);

  useEffect(() => {
    if (tender?.customer?.id) {
      dispatch(companiesActions.fetchByCustomerIdRequest(tender.customer.id));
    }
  }, [tender]);

  useEffect(() => {
    return () => {
      dispatch(companiesActions.clearEdit());
      dispatch(companiesActions.clearFetch());
    };
  }, []);

  const deleteCompany = useCallback(() => {
    tenderFormik.setFieldValue('customer', null);
  }, []);

  useEffect(() => {
    if (companiesSearch) {
      companiesSearch?.length > 0 &&
        tenderFormik.setFieldValue('customer', companiesSearch[0]) &&
        tenderFormik.setFieldValue('companyInnCustomer', '') &&
        setTimeout(() => tenderFormik.handleSubmit(), 100);
    }
  }, [companiesSearch]);

  const addIfNotFound = true;

  useEffect(() => {
    if (notFound) {
      addCompanyHook(tenderFormik.values.companyInnCustomer, 'client');
    }
  }, [notFound]);

  useEffect(() => {
    if (companiesAdd) {
      companiesAdd?.length > 0 &&
        tenderFormik.setFieldValue('customer', companiesAdd[0]) &&
        tenderFormik.setFieldValue('companyInnCustomer', '') &&
        setTimeout(() => tenderFormik.handleSubmit(), 100);
    }
  }, [companiesAdd]);

  // const openSearchCompaniesDialog = useCallback(() => {
  //   if (tenderFormik.values.companyInnCustomer === '') return null;
  //   dispatch(
  //     companiesActions.searchRequest({
  //       page: pageCompanies,
  //       perPage: perPageCompanies,
  //       inn: tenderFormik.values.companyInnCustomer,
  //     })
  //   );
  //   setOpenDialogCompanies(true);
  //   setIsSearch(true);
  // }, [tenderFormik.values]);

  const openCompaniesDialog = useCallback(() => {
    dispatch(
      companiesActions.fetchRequest({
        perPage: 20,
        page: pageCompanies,
      })
    );
    setOpenDialogCompanies(true);
  }, [tenderFormik.values, pageCompanies]);

  const renderIcon = () => {
    if (loadingCompanySearch || loadingCompanyAdd) {
      return <SpinnerSmall />;
    }
    return (
      <IconCirclSearch
        className={classes.inputIcon}
        onClick={() =>
          searchCompanyHook(tenderFormik.values.companyInnCustomer, addIfNotFound)
        }
      />
    );
  };

  return (
    <div style={{ width: isFull ? '49%' : '100%' }}>
      <Modal
        DialogProps={{ maxWidth: 'xl' } as DialogProps}
        open={openDialogCompanies}
        onClose={() => {
          if (!editCompanyLoading) {
            setOpenDialogCompanies(false);
            setIsSearch(false);
          }
        }}
        title={intl.formatMessage({ id: 'COMPANY.EDIT.MOUNT_TITLE' })}
        loading={loadingCompanies || loadingSearchCompanies || editCompanyLoading}
        content={
          <CompaniesTable
            me={me as IUser}
            page={pageCompanies}
            perPage={perPageCompanies}
            total={totalCompanies}
            companies={companies}
            fetch={({ page, perPage }) =>
              dispatch(
                companiesActions.fetchRequest({
                  page,
                  perPage,
                  roles: 'ROLE_VENDOR',
                  inn: isSearch ? tenderFormik.values.companyInnCustomer : undefined,
                })
              )
            }
            onConnectUser={company => {
              tenderFormik.setFieldValue('customer', company);
              setTimeout(() => tenderFormik.handleSubmit(), 100);
              setOpenDialogCompanies(false);
              setIsSearch(false);
            }}
            hideEdit
            textConnect={intl.formatMessage({ id: 'COMMON.BUTTON.ADD' })}
          />
        }
        actions={[
          {
            title: intl.formatMessage({ id: 'COMMON.BUTTON.CLOSE' }),
            onClick: () => setOpenDialogCompanies(false),
          },
        ]}
      />
      <Modal
        DialogProps={{ maxWidth: 'xl' } as DialogProps}
        open={openSanctionsButtons}
        onClose={() => {
          if (!editCompanyLoading) {
            setOpenDialogCompanies(false);
            setIsSearch(false);
          }
        }}
        loading={loadingCompanies || loadingSearchCompanies || editCompanyLoading}
        content={
          id &&
          tender?.customer &&
          company && (
            <>
              <div className={classes.btns}>
                <ButtonWithLoader
                  loading={loadingSanctions}
                  onPress={() => {
                    if (values.inn) {
                      searchSanctions(values.inn);
                    }
                  }}
                  disabled={loadingSanctions}
                >
                  {intl.formatMessage({ id: 'COMPANY.BUTTON.SANCTION' })}
                </ButtonWithLoader>
                {tender?.customer && tender.customer.id && (isManager || me?.is_admin) && (
                  <Button
                    onClick={() =>
                      navigate(`/chats`, {
                        state: { createCompanyChat: tender.customer },
                      })
                    }
                    className={classes.buttons}
                    style={{ margin: 0 }}
                    variant='outlined'
                    color='primary'
                  >
                    {intl.formatMessage({ id: 'BUTTON.CHAT' })}
                  </Button>
                )}
              </div>
              {sanctions && sanctions.text && (
                <Typography
                  component='h6'
                  variant='h6'
                  style={{ marginBottom: 8, marginTop: 16 }}
                >
                  {`${fm('SANCTIONS')}: ${sanctions.text}`}
                </Typography>
              )}
              {company?.colors && <TrafficLight colors={company.colors} />}
            </>
          )
        }
        actions={[
          {
            title: intl.formatMessage({ id: 'COMMON.BUTTON.CLOSE' }),
            onClick: () => setOpenSanctionsButtons(false),
          },
        ]}
      />
      {((id && companies) || !id) && (
        <div className={classes.companyField}>
          <TextField
            label={intl.formatMessage({ id: 'TENDER.TABLE.CLIENT' })}
            type='text'
            autoComplete='off'
            margin='normal'
            value={
              tenderFormik.values.customer?.name || tenderFormik.values.companyInnCustomer
            }
            name={tenderFormik.values.customer?.name ? 'customer' : 'companyInnCustomer'}
            variant='outlined'
            helperText={tenderFormik.touched.customer && tenderFormik.errors.customer}
            error={Boolean(tenderFormik.touched.customer && tenderFormik.errors.customer)}
            onChange={tenderFormik.handleChange}
            disabled={
              !!tenderFormik.values.customer?.name ||
              (!me?.is_admin && !isManager && !(tender?.client?.id === me?.company?.id))
            }
            InputProps={{
              endAdornment: tenderFormik.values.customer?.name
                ? (!me?.is_admin && !isManager) || (
                    <div className={classes.inputButtons}>
                      {/* <IconCircleInfo
                      className={classes.inputIcon}
                      onClick={() => setOpenSanctionsButtons(true)}
                    /> */}
                      <IconCircleDel
                        className={classes.inputIcon}
                        fontSize='small'
                        onClick={deleteCompany}
                      />
                    </div>
                  )
                : (!me?.is_admin && !isManager) ||
                  (tenderFormik.values.companyInnCustomer.length === 10 ? (
                    renderIcon()
                  ) : (
                    <IconCircleList
                      className={classes.inputIcon}
                      onClick={openCompaniesDialog}
                    />
                  )),
            }}
            InputLabelProps={{ shrink: true }}
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(TenderClientCompanyForm);
