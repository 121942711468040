import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import {
  Button,
  CardActions,
  Card,
  CardContent,
  CardMedia,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import Slider from 'react-slick';
import { API_DOMAIN } from '../../../../constants';
import { formatAsThousands } from '../../../../utils/utils';
import { toAbsoluteUrl } from '../../../../../_base';
import { IProduct } from '../../../../interfaces/product';
import { useDefineUserRole, useFormatMessage } from '../../../../hooks';
import { IUser } from '../../../../interfaces/user';
import StarActive from './StarActive';
import StarDisable from './StarDisable';
import { CustomArrow } from '../../../../components/ui/CustomBanner/components/ArrowButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'grid',
      gridGap: 24,
      gridTemplateColumns: 'repeat(4, 220px)',

      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
      [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },
      [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },
      [theme.breakpoints.up('xl')]: {
        gridTemplateColumns: 'repeat(4, 1fr)',
      },
      // @ts-ignore
      [theme.breakpoints.up('xxl')]: {
        gridTemplateColumns: 'repeat(5, 1fr)',
      },
    },
    productCard: {
      maxWidth: 285,
      cursor: 'pointer',
      transition: '.3s ease-out',
      boxShadow: 'none !important',
      margin: '0 auto',
      '&:hover': {
        transform: 'scale(1.01)',
      },
      minHeight: 352,
      // [theme.breakpoints.down(650)]: {
      //   margin: '0 auto !important',
      //   // width: 232,
      // },
    },
    containerBtns: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
      },
    },
    btnSupport: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      height: 45,
      [theme.breakpoints.down('md')]: {
        height: 30,
      },
    },
    btnAddProduct: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      height: 45,
      [theme.breakpoints.down('md')]: {
        // marginBottom: 10,
      },
    },
    cardContent: {
      padding: '15px 15px 0 15px',
      // height: 130,
      overflow: 'hidden',
      [theme.breakpoints.down('md')]: {
        height: 68,
      },
    },
    productName: {
      height: 50,
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 3,
    },
    columnContent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    reviewContent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    name: {
      marginBottom: 3,
      lineHeight: 1.3,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
    },
  })
);

interface IProductsProps {
  data: IProduct[];
  page?: number;
  perPage?: number;
  total?: number;
  categoryId?: string;
  companyId?: string;
  fetch?: (data: { page: number; perPage: number }) => void;
  viewAction: any;
  editAction?: any;
  handleDeleteDialog?: any;
  isAuthorized: boolean;
  isAdmin: boolean;
  me: IUser | undefined;
  addProductLoading: boolean;
  handleCartDialog?: any;
  isPaginator?: boolean;
  edit?: any;
  isHideProduct?: boolean;
  isSearching?: boolean;
}

const ProductsGrid: FC<IProductsProps> = ({
  data,
  viewAction,
  editAction,
  handleDeleteDialog,
  isAdmin,
  me,
  addProductLoading,
  handleCartDialog,
  edit,
  isHideProduct,
}) => {
  const fm = useFormatMessage();
  const classes = useStyles();
  const ref = useRef(null);
  const [heightImg, setHeightImg] = useState(285);
  const isBuyer = useDefineUserRole(me, ['ROLE_BUYER', 'ROLE_BUYER_STAFF']);
  const isVendor = useDefineUserRole(me, ['ROLE_VENDOR', 'ROLE_VENDOR_STAFF']);

  const getStock = useCallback(
    (product: IProduct) => {
      return isBuyer || !me ? product.stock_info?.available_quantity : product.stock_quantity;
    },
    [isBuyer, me]
  );

  useEffect(() => {
    const rootObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      const len = entries[0].target.clientWidth / 3;
      setHeightImg(entries[0].target.clientWidth + len);
    });
    ref.current && rootObserver.observe(ref?.current);

    return () => {
      rootObserver.disconnect();
    };
  }, []);

  const changeHide = useCallback((item: IProduct) => {
    if (isHideProduct) {
      const data = new FormData();
      data.append('active', '0');
      edit({ id: Number(item?.id), data });
    }
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <CustomArrow type='left' />,
    nextArrow: <CustomArrow type='right' />,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          centerMode: false,
          centerPadding: '20px',
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          centerMode: false,
          centerPadding: '20px',
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          centerMode: false,
          centerPadding: '20px',
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 650,
        settings: {
          centerMode: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 350,
        settings: {
          centerMode: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  const productSize = data.length < 4 ? 'larger' : 'normal';

  return (
    <div style={{}}>
      <Slider {...settings}>
        {data.map((item, index) => {
          const imageURL =
            item.photos &&
            item.photos.length > 0 &&
            item.photos.filter(photo => Boolean(photo.main));
          if (!((!getStock(item) || (getStock(item) || 0) < 0) && !isVendor && !isAdmin)) {
            return (
              <div
                style={{ display: 'flex', maxWidth: 285, margin: '0 10px', minHeight: 352 }}
                key={item.id}
              >
                <Card
                  className={classes.productCard}
                  sx={{
                    transition: 'transform 0.3s ease-in-out',
                    '&:hover': {
                      transform: 'scale(1.05)',
                    },
                  }}
                  style={{ width: productSize, margin: index !== 0 ? '0 10px' : '0 10px 0 0' }}
                >
                  <CardMedia
                    style={{
                      height: heightImg,
                      minHeight: 294,
                      objectFit: 'contain',
                      // opacity: !getStock(item) || (getStock(item) || 0) < 0 ? 0.1 : 1,
                    }}
                    ref={ref}
                    image={
                      imageURL && imageURL.length && !!imageURL[0].small
                        ? `${API_DOMAIN}/${imageURL[0].small}`
                        : toAbsoluteUrl('/images/placeholder.png')
                    }
                    title={item.name}
                    onClick={() => {
                      viewAction(item);
                    }}
                  />
                  <CardContent
                    onClick={() =>
                      viewAction(
                        item
                        // 'view_from_catalog'
                      )
                    }
                    className={classes.cardContent}
                  >
                    <div className={classes.columnContent}>
                      <Typography
                        gutterBottom
                        variant='h5'
                        component='h2'
                        style={{ lineHeight: 1.3, alignItems: 'center', paddingTop: '5px' }}
                      >
                        <b>{formatAsThousands(item.price)} ₽</b>
                      </Typography>

                      <div className={classes.reviewContent}>
                        <Typography
                          gutterBottom
                          variant='h5'
                          component='h2'
                          style={{ lineHeight: -1, paddingTop: '-5px' }}
                        >
                          <b>
                            {item?.rating ? (
                              <StarActive size='28' />
                            ) : (
                              <StarDisable size='28' />
                            )}
                          </b>
                        </Typography>

                        <Typography
                          gutterBottom
                          variant='h5'
                          component='h2'
                          style={{ lineHeight: 1.3, alignItems: 'center', paddingTop: '5px' }}
                        >
                          <b>{formatAsThousands(item.rating || '')}</b>
                        </Typography>
                      </div>
                    </div>
                    <Typography
                      color='inherit'
                      component='p'
                      className={classes.productName}
                      style={{
                        marginBottom: 3,
                        lineHeight: 1.3,
                      }}
                    >
                      {item.name}
                    </Typography>
                  </CardContent>
                  <CardActions
                    style={{ padding: '0px 7px 7px 7px' }}
                    className={classes.containerBtns}
                  >
                    {!isVendor && !isAdmin && (
                      <div className={classes.btnAddProduct}>
                        <Button
                          style={{ width: '100%', margin: '0 5px' }}
                          variant='outlined'
                          disabled={
                            addProductLoading || !getStock(item) || (getStock(item) || 0) < 0
                          }
                          onClick={() => {
                            handleCartDialog(item);
                          }}
                          color={
                            !getStock(item) || (getStock(item) || 0) < 0
                              ? 'secondary'
                              : 'primary'
                          }
                        >
                          {!getStock(item) || (getStock(item) || 0) < 0
                            ? fm('PRODUCT.BUTTON.SOLD.OUT')
                            : fm('PRODUCT.BUTTON.ADD_CART')}
                        </Button>
                      </div>
                    )}
                    {isAdmin && (
                      <>
                        <Button onClick={() => editAction(item)} color='primary'>
                          {fm('edit')}
                        </Button>

                        <Button
                          onClick={() => {
                            handleDeleteDialog(item);
                            changeHide(item);
                          }}
                          color='secondary'
                          style={{ marginLeft: 8 }}
                        >
                          {fm('COMMON.BUTTON.DELETE')}
                        </Button>
                      </>
                    )}
                  </CardActions>
                </Card>
              </div>
            );
          }
          if (!isAdmin && item?.company?.favorite) {
            return (
              <div key={item.id} style={{ marginRight: 10, display: 'flex' }}>
                <Card
                  className={classes.productCard}
                  sx={{
                    transition: 'transform 0.3s ease-in-out',
                    '&:hover': {
                      transform: 'scale(1.05)',
                    },
                  }}
                >
                  <CardMedia
                    style={{
                      height: heightImg,
                      minHeight: 294,
                      objectFit: 'contain',
                      // opacity: !getStock(item) || (getStock(item) || 0) < 0 ? 0.1 : 1,
                    }}
                    ref={ref}
                    image={
                      imageURL && imageURL.length && !!imageURL[0].small
                        ? `${API_DOMAIN}/${imageURL[0].small}`
                        : toAbsoluteUrl('/images/placeholder.png')
                    }
                    title={item.name}
                    onClick={() => viewAction(item)}
                  />
                  <CardContent
                    onClick={() => viewAction(item)}
                    className={classes.cardContent}
                  >
                    <Typography
                      gutterBottom
                      variant='h5'
                      component='h2'
                      style={{ lineHeight: 1 }}
                    >
                      <b>{formatAsThousands(item.price)} ₽</b>
                    </Typography>
                    <Typography
                      color='inherit'
                      component='p'
                      style={{ marginBottom: 3, lineHeight: 1.3 }}
                    >
                      {item.name}
                    </Typography>
                  </CardContent>
                  <CardActions style={{ padding: '0px 7px 7px 7px' }}>
                    {!isVendor && !isAdmin && (
                      <div className={classes.btnSupport}>
                        <Button
                          disabled={
                            addProductLoading || !getStock(item) || (getStock(item) || 0) < 0
                          }
                          onClick={() => {
                            handleCartDialog(item);
                          }}
                          color={
                            !getStock(item) || (getStock(item) || 0) < 0
                              ? 'secondary'
                              : 'primary'
                          }
                        >
                          {!getStock(item) || (getStock(item) || 0) < 0
                            ? fm('PRODUCT.BUTTON.SOLD.OUT')
                            : fm('PRODUCT.BUTTON.ADD_CART')}
                        </Button>
                      </div>
                    )}
                    {isAdmin && (
                      <>
                        <Button onClick={() => editAction(item)} color='primary'>
                          {fm('edit')}
                        </Button>

                        <Button
                          onClick={() => {
                            handleDeleteDialog(item);
                            changeHide(item);
                          }}
                          color='secondary'
                          style={{ marginLeft: 8 }}
                        >
                          {fm('COMMON.BUTTON.DELETE')}
                        </Button>
                      </>
                    )}
                  </CardActions>
                </Card>
              </div>
            );
          }
          if (
            !(
              (!getStock(item) || (getStock(item) || 0) < 0) &&
              me?.company?.id === item?.company?.id
            ) &&
            !item.company?.favorite
          ) {
            return (
              <div key={item.id} style={{ marginRight: 10, display: 'flex' }}>
                <Card
                  className={classes.productCard}
                  sx={{
                    transition: 'transform 0.3s ease-in-out',
                    '&:hover': {
                      transform: 'scale(1.05)',
                    },
                  }}
                >
                  <CardMedia
                    style={{
                      height: heightImg,
                      minHeight: 294,
                      objectFit: 'contain',
                      // opacity: !getStock(item) || (getStock(item) || 0) < 0 ? 0.1 : 1,
                    }}
                    ref={ref}
                    image={
                      imageURL && imageURL.length && !!imageURL[0].small
                        ? `${API_DOMAIN}/${imageURL[0].small}`
                        : toAbsoluteUrl('/images/placeholder.png')
                    }
                    title={item.name}
                    onClick={() => {
                      viewAction(item);
                    }}
                  />
                  <CardContent
                    onClick={() => viewAction(item)}
                    className={classes.cardContent}
                  >
                    <Typography
                      gutterBottom
                      variant='h5'
                      component='h2'
                      style={{ lineHeight: 1 }}
                    >
                      <b>{formatAsThousands(item.price)} ₽</b>
                    </Typography>
                    <p className={classes.name}>{item.name}</p>
                  </CardContent>
                  <CardActions style={{ padding: '0px 7px 7px 7px' }}>
                    {!isVendor && !isAdmin && (
                      <div className={classes.btnSupport}>
                        <Button
                          disabled={
                            addProductLoading || !getStock(item) || (getStock(item) || 0) < 0
                          }
                          onClick={() => {
                            handleCartDialog(item);
                          }}
                          color={
                            !getStock(item) || (getStock(item) || 0) < 0
                              ? 'secondary'
                              : 'primary'
                          }
                        >
                          {!getStock(item) || (getStock(item) || 0) < 0
                            ? fm('PRODUCT.BUTTON.SOLD.OUT')
                            : fm('PRODUCT.BUTTON.ADD_CART')}
                        </Button>
                      </div>
                    )}
                    {isAdmin && (
                      <>
                        <Button onClick={() => editAction(item)} color='primary'>
                          {fm('edit')}
                        </Button>

                        <Button
                          onClick={() => {
                            handleDeleteDialog(item);
                            changeHide(item);
                          }}
                          color='secondary'
                          style={{ marginLeft: 8 }}
                        >
                          {fm('COMMON.BUTTON.DELETE')}
                        </Button>
                      </>
                    )}
                  </CardActions>
                </Card>
              </div>
            );
          }
        })}

        {/* <div style={{position: 'absolute', top: '47%',right: 120}}>
          <CustomArrow type='right' onClick={()=> sliderRight} />
        </div> */}
      </Slider>
      {/* </div> */}
    </div>
  );
};

export default ProductsGrid;
