import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { getResponseMessage } from '../../../../utils/utils';
import { deleteGroup } from '../../../../crud/productType.crud';
import { useFormatMessage } from '../../../../hooks';

export const useDeleteGroup = () => {
  const { enqueueSnackbar } = useSnackbar();
  const fm = useFormatMessage();
  const [loadingDelete, setLoading] = useState(false);
  const [successDelete, setSuccess] = useState(false);

  const handleDelete = useCallback(async (id: number) => {
    setLoading(true);
    deleteGroup(id)
      .then(() => {
        setSuccess(true);
        enqueueSnackbar(fm('GROUP.DELETED'), { variant: 'success' });
      })
      .catch(e => {
        enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`, { variant: 'error' });
      })
      .finally(() => {
        setSuccess(false);
        setLoading(false);
      });
  }, []);

  return { handleDelete, loadingDelete, successDelete };
};
