import React, { useCallback, useState } from 'react';
import {
  Card,
  CardContent,
  Button,
  CardActions,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { TCollection } from '../../collections/types';
import { IProductFilter } from '../../../../interfaces/product';
import { ILocation } from '../../../../interfaces/locations';
import { SortType } from '../../../../interfaces/productType';

type TFilterCollection = {
  setSearch: (payload: boolean) => void;
  selectedCategoryId: number | null;
  collections: TCollection[];
  setFilterData: any;
  fetch: (payload: {
    page: number;
    perPage: number;
    filter: IProductFilter;
    search?: string | undefined;
    categoryId?: number | undefined;
    sort_type?: string | undefined;
    locale?: ILocation | null | undefined;
    activity?: string | undefined;
    sortType?: SortType | undefined;
  }) => void;
  setPriceFrom: (value: string) => void;
  setPriceTo: (value: string) => void;
  setNewType: (value: number | null | 'disabled') => void;
  resetProductTypes: (categoryId?: number | null, newFilter?: IProductFilter) => void;
  clearParams: () => void;
};
const useTreeItemStyles = makeStyles()((theme)=>{
  return {
    root: {
      color: theme.palette.text.secondary,
      margin: 0,
      paddingLeft: 20,
      listStyle: 'none',
      '&:focus > $content, &$selected > $content': {
        backgroundColor: `var(--tree-view-bg-color)`,
        color: 'var(--tree-view-color)',
      },
      '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
        backgroundColor: 'transparent',
      },
      '&:hover > $content $label, &:hover > $content $label, &$selected > $content $label': {
        backgroundColor: 'transparent',
      },
    },
    cardCategory: {
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    cardButtons: {
      justifyContent: 'flex-end',
      padding: 16,
    },
    buttons: {
      marginRight: theme.spacing(2),
    },
    button: {
      '&:active': {
        opacity: 0.2,
      },
    },
    content: {
      color: theme.palette.text.primary,
      fontWeight: 500,
      '$expanded > &': {
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
    group: {
      marginLeft: 0,
    },
    expanded: {},
    selected: {
      backgroundColor: `var(--tree-view-bg-color)`,
    },
    label: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      paddingBottom: theme.spacing(0.7),
      paddingTop: theme.spacing(0.7),
      width: '100%',
      padding: 10,
      cursor: 'pointer',
      position: 'relative',
      transition: '.25s ease-out',
    },
    labelContainerChildren: {
      maxHeight: '100%',
      overflow: 'hidden',
    },
    labelChildren: {
      display: 'flex',
      alignItems: 'center',
      paddingBottom: theme.spacing(0.7),
      paddingTop: theme.spacing(0.7),
      width: '100%',
      padding: 10,
      cursor: 'pointer',
      paddingLeft: 30,
      transition: '.25s ease-out',
    },
    labelIcon: {},
    labelTitle: {
      fontWeight: 700,
      flexGrow: 1,
      fontSize: 14,
      color: '#282a3c',
    },
    labelText: {
      fontWeight: 600,
      flexGrow: 1,
      fontSize: 14,
      color: '#282a3c',
      opacity: 0.8,
    },
    activeItem: {
      backgroundColor: 'rgba(54, 159, 247,0.5)',
      color: theme.palette.primary.main,
    },
    hide: {
      height: 0,
    },
}});

const FilterCollections: React.FC<TFilterCollection> = ({
  setSearch,
  selectedCategoryId,
  collections,
  setFilterData,
  fetch,
  setPriceFrom,
  setPriceTo,
  setNewType,
  resetProductTypes,
  clearParams,
}) => {
  const intl = useIntl();
  const classes1 = useTreeItemStyles();
  const {classes} = classes1
  const [selectCollection, setSelectCollection] = useState<TCollection | null>(null);

  const fetchCollection = useCallback(() => {
    if (selectCollection) {
      setSearch(true);
      clearParams();
      const params: any = {};
      const paramsArray: any = [];
      selectCollection.parameters.forEach(param => {
        if (param.filter_type) {
          params[param.parameter] = { values: param.values };
          paramsArray.push({
            parameter: param.parameter,
            values: param.values,
          });
        }
      });
      setPriceTo(selectCollection.max_price?.toString() || '');
      setPriceFrom(selectCollection.min_price?.toString() || '');
      setFilterData(params);
      setNewType(selectCollection.product_type_id);
      fetch({
        page: 1,
        perPage: 12,
        categoryId: selectedCategoryId || 0,
        filter: {
          parameters: paramsArray,
          price_from: selectCollection.min_price?.toString() || '',
          price_to: selectCollection.max_price?.toString() || '',
        },
      });
    }
  }, [selectCollection, selectedCategoryId]);

  const select = (value: TCollection) => {
    setSelectCollection(value);
    if (value) {
      setSearch(true);
      clearParams();
      const params: any = {};
      const paramsArray: any = [];
      value.parameters.forEach(param => {
        if (param.filter_type) {
          params[param.parameter] = { values: param.values };
          paramsArray.push({
            parameter: param.parameter,
            values: param.values,
          });
        }
      });
      setPriceTo(value.max_price?.toString() || '');
      setPriceFrom(value.min_price?.toString() || '');
      setFilterData(params);
      setNewType(value.product_type_id);
      fetch({
        page: 1,
        perPage: 12,
        categoryId: selectedCategoryId || 0,
        filter: {
          parameters: paramsArray,
          price_from: value.min_price?.toString() || '',
          price_to: value.max_price?.toString() || '',
        },
      });
    }
  };

  const clear = () => {
    resetProductTypes(selectedCategoryId, {
      parameters: [],
      price_from: '',
      price_to: '',
    });
    setSelectCollection(null);
    localStorage.removeItem('filter');
    localStorage.removeItem('selectedTypeId');
    localStorage.removeItem('priceTo');
    localStorage.removeItem('priceFrom');
    setFilterData({});
    setSearch(false);
    setPriceFrom('');
    setPriceTo('');
    setNewType(null);
  };

  return (
    <Card className={classes.cardCategory}>
      <CardContent>
        <Typography variant='h5' className={classes.labelTitle} style={{ marginBottom: 16 }}>
          {intl.formatMessage({ id: 'COLLECTIONS' })}
        </Typography>
        <ul className={classes.root}>
          {collections.map(i => (
            <li>
              <div
                onClick={() => {
                  select(i);
                }}
                className={clsx(classes.labelRoot, {
                  [classes.activeItem]: i.id === selectCollection?.id,
                })}
              >
                <Typography variant='body2' className={classes.labelText}>
                  {i.name}
                </Typography>
              </div>
            </li>
          ))}
        </ul>
      </CardContent>
      <CardActions className={classes.cardButtons}>
        <Button onClick={clear} className={classes.buttons} variant='outlined' color='primary'>
          {intl.formatMessage({ id: 'PRODUCT.FILTER.RESET' })}
        </Button>
        <Button
          disabled={Boolean(!selectCollection)}
          className={clsx(classes.button)}
          color='primary'
          variant='contained'
          onClick={fetchCollection}
        >
          {intl.formatMessage({ id: 'PRODUCT.FILTER.SEARCH' })}
        </Button>
      </CardActions>
    </Card>
  );
};

export default FilterCollections;
