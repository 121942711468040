import React, { useMemo, useState } from 'react';
import { Card, IconButton } from '@mui/material';
import ImageGalleryLib from 'react-image-gallery';
import ArrowBack from '@mui/icons-material/ArrowBackIos';
import ArrowNext from '@mui/icons-material/ArrowForwardIos';
import './styles.css';
import { API_DOMAIN } from '../../../../../constants';
import { toAbsoluteUrl } from '../../../../../../_base';
import { IRequest } from '../../../../../interfaces/request';
import { useStylesImageGallery } from '../../hooks/useStyles';

interface IProps {
  request: IRequest;
}

const ImageGalleryRequest: React.FC<IProps> = ({ request }) => {
  const classes = useStylesImageGallery();
  const [isFullScreen, setIsFullScreen] = useState(false);

  // images
  const images = useMemo(() => {
    if (request?.photos) {
      return request.photos.map(el => ({
        original: API_DOMAIN + el.path,
        thumbnail: API_DOMAIN + el.path,
        renderItem: ({ original }: any) => (
          <div
            className={`${classes.imgContainer} ${
              isFullScreen ? classes.imgContainerFull : classes.imgContainerSmall
            }`}
            style={{ height: 700, overflow: 'hidden', display: 'flex' }}
          >
            <img
              alt=''
              className={classes.img}
              style={{ objectFit: 'contain' }}
              src={original}
            />
          </div>
        ),
      }));
    }

    return null;
  }, [request, isFullScreen]);

  return (
    <div className={classes.galleryContainer}>
      {images && (
        <>
          {images.length > 0 ? (
            <ImageGalleryLib
              onScreenChange={boolean => setIsFullScreen(boolean)}
              items={images}
              useBrowserFullscreen
              showThumbnails={images.length > 1}
              showFullscreenButton={false}
              disableThumbnailScroll={false}
              showPlayButton={false}
              thumbnailPosition={images.length > 1 ? 'left' : undefined}
              infinite
              renderLeftNav={onClick => (
                <IconButton onClick={onClick} className={classes.arrow} style={{ left: 15 }}>
                  <ArrowBack style={{ fontSize: 30 }} />
                </IconButton>
              )}
              renderRightNav={onClick => (
                <IconButton onClick={onClick} className={classes.arrow} style={{ right: 15 }}>
                  <ArrowNext style={{ fontSize: 30 }} />
                </IconButton>
              )}
            />
          ) : (
            <Card
              className={`${classes.imgContainer} ${classes.imgContainerSmall}`}
              elevation={0}
            >
              <img
                alt=''
                className={classes.img}
                src={toAbsoluteUrl('/images/placeholder.png')}
              />
            </Card>
          )}
        </>
      )}
    </div>
  );
};

export default ImageGalleryRequest;
