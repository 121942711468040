import { TariffsType } from '../TariffList';
import { UserRoles } from '../../../../interfaces/user';

const getRole = (type: TariffsType): UserRoles => {
  switch (type) {
    case TariffsType.buyers:
      return UserRoles.ROLE_BUYER;
    case TariffsType.vendors:
      return UserRoles.ROLE_VENDOR;
    default:
      return UserRoles.ROLE_VENDOR;
  }
};

export default getRole;
