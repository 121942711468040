import React from 'react';
import { InputLabel, FormHelperText } from '@mui/material';

const keyNameStyle = {
  margin: 0,
  marginRight: 8,
  fontWeight: 400,
};
const valueStyle = { fontSize: '1rem', margin: 0, color: 'black' };

type TProps = {
  keyName: string;
  value: string | number;
};

export const SimpleInfoLine = ({ keyName, value }: TProps) => (
  <span style={{ display: 'flex', alignItems: 'center' }}>
    <InputLabel style={keyNameStyle}>{keyName}:</InputLabel>
    <FormHelperText style={valueStyle}>{value}</FormHelperText>
  </span>
);
