import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { getResponseMessage } from '../../../../../utils/utils';
import { addBonusesUser } from '../../../../../crud/promocodes.crud';

export const useAddBonusesUser: any = () => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const fetch = useCallback(async (userId: number, amount: number) => {
    setLoading(true);
    addBonusesUser(userId, +amount)
      .then(() => {
        setSuccess(true);
        enqueueSnackbar(`${intl.formatMessage({ id: 'USER.CHANGE.BONUSES' })}`, {
          variant: 'success',
        });
      })
      .catch(e => {
        enqueueSnackbar(`${intl.formatMessage({ id: 'ERROR' })}: ${getResponseMessage(e)}`, {
          variant: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
        setSuccess(false);
      });
  }, []);
  return [fetch, loading, success];
};
