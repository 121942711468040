import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { LayoutSplashScreen } from '../../../_base';
import { IAppState } from '../../store/rootDuck';
import { actions as authActions } from '../../store/ducks/auth.duck';
import { actions as cartActions } from '../../store/ducks/cart.duck';

const Logout: React.FC<TPropsFromRedux> = ({ logout, hasAuthToken, clearCart }) => {
  useEffect(() => {
    clearCart();
    logout();
  }, [logout]);

  return (
    <>
      {hasAuthToken ? (
        <LayoutSplashScreen />
      ) : (
        <Routes>
          <Route path='*' element={<Navigate to='/' replace />} />
        </Routes>
      )}
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    hasAuthToken: Boolean(state.auth.authToken),
  }),
  {
    logout: authActions.logout,
    clearCart: cartActions.clearCartRequest,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Logout);
