import React from 'react';
import { Typography, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import { IPublication } from '../interfaces';
import { PublicationPost } from '../../../../components/ui/Blog';

interface IProps {
  publications: IPublication[];
  deletePost: (id: number) => void;
  meId: number;
  companyId?: number;
  isAdmin?: boolean;
  width?: number | undefined;
  admin?: boolean;
  btnPub?: boolean;
  showEdit?: boolean;
  style?: any;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    empty: {
      marginBottom: 20,
      marginTop: 20,
      color: 'rgb(0, 90, 90)',
    },
    publicationContainer: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      // padding: '0 10px',
      gap: 20,
    },
    publicationColumn: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    },
    publicationItem: {
      marginBottom: 15,
      width: '100%',
    },
  })
);

const PublicationsList: React.FC<IProps> = ({
  publications,
  deletePost,
  meId,
  companyId,
  isAdmin,
  width,
  admin,
  btnPub,
  showEdit,
  style,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const firstHalf: IPublication[] = [];
  const secondHalf: IPublication[] = [];
  const thirdHalf: IPublication[] = [];

  publications.forEach((publication, index) => {
    const numberInArray = index + 1;

    if (width && width >= 1150) {
      if (numberInArray % 3 === 0) {
        thirdHalf.push(publication);
      } else if (numberInArray % 2 === 0) {
        secondHalf.push(publication);
      } else {
        firstHalf.push(publication);
      }
    } else if (width && width > 600) {
      if (numberInArray % 2 === 0) {
        secondHalf.push(publication);
      } else {
        firstHalf.push(publication);
      }
    } else {
      firstHalf.push(publication);
    }
  });

  return (
    <>
      {publications.length === 0 ? (
        <Typography className={classes.empty} component='h5' variant='h5'>
          {intl.formatMessage({ id: 'COMMON.LIST.EMPTY' })}
        </Typography>
      ) : (
        <div style={style} className={classes.publicationContainer}>
          <div className={classes.publicationColumn}>
            {firstHalf.map(publication => (
              <div className={classes.publicationItem} key={publication.id.toString()}>
                <PublicationPost
                  isView={false}
                  meId={meId}
                  companyId={companyId}
                  deletePost={deletePost}
                  publication={publication}
                  isAdmin={Boolean(isAdmin)}
                  admin={Boolean(admin)}
                  btnPub={btnPub}
                  showEdit={showEdit}
                />
              </div>
            ))}
          </div>

          {width && width > 600 ? (
            <div className={classes.publicationColumn}>
              {secondHalf.map(publication => (
                <div className={classes.publicationItem} key={publication.id.toString()}>
                  <PublicationPost
                    isView={false}
                    meId={meId}
                    deletePost={deletePost}
                    publication={publication}
                    isAdmin={Boolean(isAdmin)}
                    admin={Boolean(admin)}
                    btnPub={btnPub}
                    showEdit={showEdit}
                    companyId={companyId}
                  />
                </div>
              ))}
            </div>
          ) : null}

          {width && width >= 1150 ? (
            <div className={classes.publicationColumn}>
              {thirdHalf.map(publication => (
                <div className={classes.publicationItem} key={publication.id.toString()}>
                  <PublicationPost
                    isView={false}
                    meId={meId}
                    deletePost={deletePost}
                    publication={publication}
                    isAdmin={Boolean(isAdmin)}
                    admin={Boolean(admin)}
                    btnPub={btnPub}
                    showEdit={showEdit}
                    companyId={companyId}
                  />
                </div>
              ))}
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default PublicationsList;
