import React, { useState } from 'react';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  CardContent,
  Tooltip,
  Theme,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useIntl } from 'react-intl';

import { SHeadTableCell } from '../../../../components/styledComponents/Tables';

import homeStyles from '../../../../constants/homeStyles';
import { ButtonWithLoader } from '../../../../components/ui/Buttons';
import { ButtonTheme } from '../../../../components/ui/Buttons/ButtonWithLoader';
import { ICompany } from '../../../../interfaces/companies';
import { IUser } from '../../../../interfaces/user';
import { Modal } from '../../../../components/other/Modals';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttons: {
      marginRight: theme.spacing(3),
    },

    card: {
      // marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      paddingTop: 20,
      marginRight: 20,
      minWidth: 650,
    },
    cardTitle: {
      marginLeft: theme.spacing(4),
      fontSize: 17,
      fontWeight: 700,
      color: '#000',
    },

    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    img: {
      maxHeight: 55,
      cursor: 'pointer',
    },
    closeButton: {
      // borderRadius: '25px 0 25px 0',
      backgroundColor: '#441861',
      color: 'white',
      paddingLeft: 15,
      paddingRight: 15,
      marginRight: 16,
      marginBottom: 12,
      borderRadius: 0,
      '&:hover': {
        backgroundColor: '#441861',
      },
    },
    okButton: {
      // borderRadius: '25px 0 25px 0',
      backgroundColor: '#216214',
      color: 'white',
      paddingLeft: 15,
      paddingRight: 15,
      marginRight: 16,
      marginBottom: 12,
      borderRadius: 0,
      '&:hover': {
        backgroundColor: '#216214',
      },
    },
    head: {
      fontWeight: 'bold',
      fontSize: 14,
      paddingBottom: 17,
      paddingTop: 17,
    },
    cell: {
      padding: 13,
    },
  })
);
interface ISortableCategoryItem {
  companies: ICompany[];
  me: IUser;
  delCompanyLoading: boolean;
  delCompany: (id: number) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const TableNewCompanies: React.FC<ISortableCategoryItem> = ({
  companies,
  me,
  delCompanyLoading,
  delCompany,
  open,
  setOpen,
}) => {
  const intl = useIntl();
  const homeClasses = homeStyles();
  const classes = useStyles();
  const navigate = useNavigate();
  // const [open, setOpen] = useState(false);
  const [companyId, setCompanyId] = useState<number>();

  return (
    <>
      <Card className={classes.card}>
        <text className={classes.cardTitle}>
          {' '}
          {intl.formatMessage({ id: 'PRODUCT.ALL.NEW_COMPANIES' })}
        </text>
        <CardContent className={homeClasses.classes.tableContainer}>
          <Table aria-label='simple table'>
            <TableHead>
              <TableRow>
                <SHeadTableCell className={classes.head}>
                  {intl.formatMessage({ id: 'COMPANY.TABLE.ID' })}
                </SHeadTableCell>
                {/* <SHeadTableCell style={{ textAlign: 'center' }}> */}
                {/*   {intl.formatMessage({ id: 'COMPANY.TAB.LOGO' })} */}
                {/* </SHeadTableCell> */}
                <SHeadTableCell className={classes.head}>
                  {intl.formatMessage({ id: 'COMPANY.TABLE.NAME' })}
                </SHeadTableCell>
                <SHeadTableCell className={classes.head}>
                  {intl.formatMessage({ id: 'COMPANY.TABLE.LEGAL_PERSON' })}
                </SHeadTableCell>
                <SHeadTableCell className={classes.head}>
                  {intl.formatMessage({ id: 'COMPANY.TABLE.INN' })}
                </SHeadTableCell>
                <SHeadTableCell className={classes.head}>
                  {intl.formatMessage({ id: 'COMPANY.TABLE.MANAGERS' })}
                </SHeadTableCell>
                {/* {me.is_admin && ( */}
                {/*   <> */}
                {/*     <SHeadTableCell align='center'> */}
                {/*       {intl.formatMessage({ id: 'USER.TABLE.TARIFF' })} */}
                {/*     </SHeadTableCell> */}
                {/*     <SHeadTableCell align='center'> */}
                {/*       {intl.formatMessage({ id: 'USER.TABLE.STOPDATETARIFF' })} */}
                {/*     </SHeadTableCell> */}
                {/*   </> */}
                {/* )} */}
                <SHeadTableCell align='center' className={classes.head}>
                  {intl.formatMessage({ id: 'COMPANY.TABLE.ACTIONS' })}
                </SHeadTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companies.map((item, index) =>
                index < 3 ? (
                  <TableRow key={item.id}>
                    <TableCell component='th' scope='row' className={classes.cell}>
                      {item.id}
                    </TableCell>

                    {/* <TableCell> */}
                    {/*   {item.logo || !item.brand ? ( */}
                    {/*     <Avatar */}
                    {/*       style={{ margin: '0 auto' }} */}
                    {/*       src={ */}
                    {/*         item.logo */}
                    {/*           ? `${API_DOMAIN}/${item.logo.small}` */}
                    {/*           : toAbsoluteUrl('/images/placeholder.png') */}
                    {/*       } */}
                    {/*       className={classes.green} */}
                    {/*     /> */}
                    {/*   ) : ( */}
                    {/*     <div className={classes.companyNameAvatarWrapper}> */}
                    {/*       <span */}
                    {/*         className='kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success' */}
                    {/*         style={{ */}
                    {/*           width: 40, */}
                    {/*           height: 40, */}
                    {/*           borderRadius: 20, */}
                    {/*           backgroundColor: '#566DC6FF', */}
                    {/*         }} */}
                    {/*       > */}
                    {/*         <b> */}
                    {/*           {(item.brand ? item.brand.trim()[0] : '').toUpperCase()} */}
                    {/*         </b> */}
                    {/*       </span> */}
                    {/*     </div> */}
                    {/*   )} */}
                    {/* </TableCell> */}

                    <TableCell className={classes.cell}>
                      {/* <Link to={`/companies/edit/${item.id}`}>{item.brand} </Link> */}
                    </TableCell>

                    <TableCell className={classes.cell}>{item.name}</TableCell>

                    <TableCell className={classes.cell}>{item.inn}</TableCell>

                    <TableCell className={classes.cell}>
                      {item.managers &&
                        item.managers.length > 0 &&
                        [...item.managers.slice(0, 3)].map(manager => (
                          <span key={manager.id} style={{ display: 'block' }}>
                            {/* <Link
                                      to={`/users/edit/${UserListType.MANAGER}/${manager.id}`}
                                  >
                                    {manager.first_name ||
                                    manager.last_name ||
                                    manager.middle_name
                                        ? `${manager.last_name || ''} ${manager.first_name || ''
                                        } ${manager.middle_name || ''}`
                                        : manager.email}
                                  </Link>
                                  */}
                          </span>
                        ))}
                    </TableCell>

                    {/* {me.is_admin && ( */}
                    {/*   <> */}
                    {/*     {!item.current_tariff ? ( */}
                    {/*       <> */}
                    {/*         <TableCell align='center' /> */}
                    {/*         <TableCell align='center' /> */}
                    {/*       </> */}
                    {/*     ) : ( */}
                    {/*       <> */}
                    {/*         <TableCell align='center'> */}
                    {/*           <p */}
                    {/*             className={classes.tariffText} */}
                    {/*             onClick={() => setEditCompanyTariff(item)} */}
                    {/*           > */}
                    {/*             {item.current_tariff.tariff.name === 'Премиум' */}
                    {/*               ? 'Профессиональный' */}
                    {/*               : 'Базовый'} */}
                    {/*           </p> */}
                    {/*         </TableCell> */}
                    {/*         <TableCell align='center'> */}
                    {/*           {item.current_tariff.tariff.name === 'Премиум' */}
                    {/*             ? getDateStop(item.current_tariff) */}
                    {/*             : ''} */}
                    {/*         </TableCell> */}
                    {/*       </> */}
                    {/*     )} */}
                    {/*   </> */}
                    {/* )} */}

                    <TableCell align='center' className={classes.cell}>
                      <div className={classes.actions}>
                        <Tooltip
                          title={intl.formatMessage({
                            id: 'PRODUCTS.TABLE.TOOLTIP.EDIT',
                          })}
                        >
                          <IconButton
                            color='primary'
                            aria-label='previous'
                            onClick={() => navigate(`/companies/edit/${item.id}`)}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>

                        {/* <IconButton */}
                        {/*    color='primary' */}
                        {/*    aria-label='previous' */}
                        {/*    onClick={() => */}
                        {/*        history.push( */}
                        {/*            `/products/create/company/${item.id}/${item.name}` */}
                        {/*        ) */}
                        {/*    } */}
                        {/* > */}
                        {/*    <AddIcon /> */}
                        {/* </IconButton> */}
                        {/* <IconButton */}
                        {/*   color='primary' */}
                        {/*   aria-label='previous' */}
                        {/*   onClick={() => { */}
                        {/*     history.push(`/products/list/U${item.id}/${item.id}`); */}
                        {/*   }} */}
                        {/* > */}
                        {/*   <svg */}
                        {/*     xmlns='http://www.w3.org/2000/svg' */}
                        {/*     enableBackground='new 0 0 24 24' */}
                        {/*     height='24px' */}
                        {/*     viewBox='0 0 24 24' */}
                        {/*     width='24px' */}
                        {/*     fill='#441861' */}
                        {/*   > */}
                        {/*     <rect fill='none' height='24' width='24' /> */}
                        {/*     <path d='M19,5v14H5V5H19 M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3L19,3z' /> */}
                        {/*     <path d='M14,17H7v-2h7V17z M17,13H7v-2h10V13z M17,9H7V7h10V9z' /> */}
                        {/*   </svg> */}
                        {/* </IconButton> */}
                        {me.is_admin && (
                          <Tooltip
                            title={intl.formatMessage({
                              id: 'PRODUCTS.TABLE.TOOLTIP.DELETE',
                            })}
                          >
                            <IconButton
                              aria-label='previous'
                              onClick={() => {
                                setCompanyId(item.id);
                                setOpen(true);
                              }}
                            >
                              <DeleteIcon color='error' />
                            </IconButton>
                          </Tooltip>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ) : null
              )}
            </TableBody>
            {/* <TableFooter>
                                 <TableRow>
                                     <TablePaginator
                                         page={page}
                                         realPerPage={companies.length}
                                         perPage={perPage}
                                         total={total}
                                         fetchRows={fetch}
                                         label={intl.formatMessage({ id: 'COMPANY.TABLE.PERPAGE' })}
                                     />
                                 </TableRow>
                             </TableFooter> */}
          </Table>
        </CardContent>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ButtonWithLoader
            style={{ marginBottom: 25, marginRight: 10 }}
            theme={ButtonTheme.PRIMARY}
            onPress={() => navigate('/companies/list')}
          >
            {intl.formatMessage({ id: 'CATEGORIES.BUTTON.VIEW.ALL' })}
          </ButtonWithLoader>
        </div>
      </Card>

      <Modal
        open={open}
        onClose={delCompanyLoading ? () => {} : () => setOpen(false)}
        title={intl.formatMessage({ id: 'COMPANY.DELETE.TITLE' })}
        loading={delCompanyLoading}
        content={
          delCompanyLoading ? undefined : intl.formatMessage({ id: 'COMPANY.DELETE.TEXT' })
        }
        actions={
          delCompanyLoading
            ? undefined
            : [
                {
                  title: intl.formatMessage({ id: 'COMMON.BUTTON.CANCEL' }),
                  onClick: () => setOpen(false),
                  // className: classes.closeButton,
                },
                {
                  title: intl.formatMessage({ id: 'COMMON.BUTTON.DELETE' }),
                  onClick: () => companyId && delCompany(companyId),
                  // className: classes.okButton,
                },
              ]
        }
      />
    </>
  );
};

export default TableNewCompanies;
