import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { getResponseMessage } from '../../../../utils/utils';
import { createParticipation } from '../../../../crud/search.crud';
import { actions as searchActions } from '../../../../store/ducks/search.duck';

export const useCreateParticipation = () => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [loadingCreate, setLoading] = useState(false);
  const [successCreate, setSuccess] = useState(false);
  const createPart = useCallback(async (id: number, data: any) => {
    setLoading(true);
    createParticipation(id, data)
      .then(() => {
        setSuccess(true);
        enqueueSnackbar(`${intl.formatMessage({ id: 'PARTICIPATION.SUCCESS.POST.SEARCH' })}`, {
          variant: 'success',
        });
        dispatch(searchActions.fetchParticipation({ tender_id: id, page: 1, per_page: 20 }));
      })
      .catch(e => {
        enqueueSnackbar(`${intl.formatMessage({ id: 'ERROR' })}: ${getResponseMessage(e)}`, {
          variant: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
        setSuccess(false);
      });
  }, []);

  return { createPart, loadingCreate, successCreate };
};
