import React from 'react';
import { useSelector } from 'react-redux';
import {
  Typography,
  TextField,
  MenuItem,
  FormControlLabel,
  Switch,
} from '@mui/material';

import { useDefineUserRole, useFormatMessage } from '../../../../../hooks';
import { IAppState } from '../../../../../store/rootDuck';
import NumberFormatPrice from '../../../../../components/NumberFormatPrice';
import { useStylesHeaderAddMultiply } from '../../hooks/useStyles';

type TProps = {
  formik: any;
};

const EditParams: React.FC<TProps> = ({ formik }) => {
  const fm = useFormatMessage();
  const classes1 = useStylesHeaderAddMultiply();
  const {classes} = classes1

  const { me } = useSelector(({ profile }: IAppState) => profile);

  const isVendor = useDefineUserRole(me, ['ROLE_VENDOR', 'ROLE_VENDOR_STAFF']);

  const { values, handleBlur, handleChange } = formik;

  return (
    <div className={classes.block}>
      <Typography className={classes.title}>
        {fm('PRODUCT.ADD.MULTIPLY.PRODUCTS.EDIT.PARAMS').toUpperCase()}
      </Typography>
      <div className={classes.wrapperFields}>
        <TextField
          type='text'
          label={fm('PRODUCT.EDIT.NAME')}
          color='primary'
          name='name'
          value={values.name || ''}
          variant='outlined'
          onBlur={handleBlur('name')}
          onChange={handleChange}
          multiline
          rows={3}
          className={classes.fieldCommon400}
        />
        <TextField
          type='text'
          label={fm('PRODUCT.TABLE.DESCRIPTION')}
          color='primary'
          name='description'
          value={values.description || ''}
          variant='outlined'
          onBlur={handleBlur('description')}
          onChange={handleChange}
          multiline
          rows={3}
          className={classes.fieldCommon400}
        />
      </div>
      <div className={classes.wrapperFields}>
        <TextField
          type='text'
          label={fm('PRODUCT.EDIT.PRICE')}
          color='primary'
          name='price'
          value={values.price || ''}
          variant='outlined'
          onBlur={handleBlur('price')}
          onChange={handleChange}
          className={classes.fieldCommon130}
          InputProps={{
            inputComponent: NumberFormatPrice as any,
          }}
        />

        {/* <TextField
          type='text'
          label={fm('PRODUCT.VENDOR.PRICE')}
          color='primary'
          name='vendor_price'
          value={values.vendor_price || ''}
          variant='outlined'
          onBlur={handleBlur('vendor_price')}
          onChange={handleChange}
          className={classes.fieldCommon130}
          InputProps={{
            inputComponent: NumberFormatPrice as any,
          }}
        /> */}

        <TextField
          type='number'
          label={fm('STOCK')}
          color='primary'
          name='stockQuantity'
          value={values.stockQuantity || ''}
          variant='outlined'
          onBlur={handleBlur('stockQuantity')}
          onChange={handleChange}
          className={classes.fieldCommon90}
        />

        <TextField
          select
          label={fm('DELIVERY.TYPE')}
          color='primary'
          name='deliveryType'
          value={values.deliveryType || ''}
          variant='outlined'
          onBlur={handleBlur('deliveryType')}
          onChange={handleChange}
          className={classes.fieldCommon135}
        >
          <MenuItem color='primary' value='in_stock'>
            {fm('DELIVERY.IN.STOCK')}
          </MenuItem>
          <MenuItem color='primary' value='out_of_stock'>
            {fm('DELIVERY.OUT.STOCK')}
          </MenuItem>
          <MenuItem color='primary' value='on_order'>
            <div>{fm('DELIVERY.ON.ORDER')}</div>
          </MenuItem>
          {(me?.is_admin || (isVendor && me?.company?.id === 37)) && (
            <MenuItem color='primary' value='barelton'>
              {fm('DELIVERY.BARELTON')}
            </MenuItem>
          )}
        </TextField>

        <FormControlLabel
          color='primary'
          className={classes.active}
          control={
            <Switch
              //   className={homeClasses.switch}
              size='small'
              name='active'
              color='primary'
              checked={values.active || false}
              onChange={handleChange}
            />
          }
          label={fm('PRODUCT.EDIT.STATUS')}
        />
      </div>
    </div>
  );
};

export default React.memo(EditParams);
