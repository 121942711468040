import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField, CircularProgress } from '@mui/material';
import { IUser } from '../../interfaces/user';
import { useFormatMessage } from '../../hooks';
import { getFio } from '../../pages/home/users/users/utils';

interface IProps {
  id?: string;
  options: IUser[];
  inputValue: IUser;
  label: string;
  inputClassName?: any;
  editable: boolean;
  loading: boolean;
  inputError?: boolean;
  inputHelperText?: any;
  disable: boolean;
  handleBlur?: (e: any) => {} | void;
  searchStaff: (data: { fio: string }) => void;
  clearUsers: () => void;
  setSelectedValue: (location: any) => {} | void;
  prompterRunning?: boolean;
  prompterStep?: number;
}

const AutocompleteStaff: React.FC<IProps> = ({
  id = '',
  options,
  inputValue,
  label,
  inputClassName,
  handleBlur,
  // editable,
  loading,
  // inputError,
  // inputHelperText,
  searchStaff,
  clearUsers,
  setSelectedValue,
  // disable,
  // prompterRunning,
  // prompterStep,
}) => {
  // const [editableLocation, setEditableLocation] = useState(editable);
  const [fio, setFio] = useState<string>('');
  // const [fetchedLocation, setFetchedLocation] = useState('');
  // const innerClasses = innerStyles();
  // const intl = useIntl();
  const fm = useFormatMessage();

  // useEffect(() => {
  //   clearUsers();
  // }, [clearUsers]);

  useEffect(() => {
    // if (!location || location.length === FIRST_FETCH_SYMBOL) {
    if (fio && fio.length > 1) {
      searchStaff({ fio });
      // setFetchedLocation(fio);
    }
  }, [searchStaff, fio]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (location !== fetchedLocation) {
  //       searchStaff({fio:location});
  //       setFetchedLocation(location);
  //     }
  //   }, LIMIT_INTERVAL);

  //   return () => interval && clearInterval(interval);
  // }, [location, searchStaff, searchStaff]);

  // useEffect(() => {
  //   setLocation(defaultValue.text);
  // }, [setLocation, defaultValue.text]);

  // useEffect(() => {
  //   if (inputValue.fio === '') setEditableLocation(true);
  //   if (inputValue.fio !== '') setEditableLocation(false);
  // }, [inputValue, setEditableLocation]);

  return (
    <Autocomplete
      id={`autocomplete${id}`}
      // noOptionsText={intl.formatMessage({ id: 'STORE.INPUT.LOCATION_PLACEHOLDER' })}
      noOptionsText={fm('USER.SEARCH.STAFF.FIO')}
      options={options}
      loading={loading}
      getOptionLabel={option => (getFio(option) !== '-' ? `${getFio(option)}` : '')}
      onChange={(e: any, val: any) => {
        if (val) {
          setSelectedValue(val);
          // setEditableLocation(false);
        } else {
          setFio('');
          clearUsers();
        }
      }}
      filterOptions={o => o}
      value={inputValue}
      onInputChange={(_e: any, _val: any, reason: any) => {
        if (reason === 'clear') setSelectedValue({ fio: '' });
      }}
      renderInput={(params: any) => {
        return (
          <TextField
            {...params}
            type='text'
            margin='normal'
            name='location'
            variant='outlined'
            label={label}
            className={inputClassName}
            onBlur={handleBlur}
            onChange={e => {
              setFio(e.target.value);
            }}
            style={{ margin: 0 }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading && <CircularProgress color='inherit' size={20} />}

                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        );
      }}
    />
  );
};

export default AutocompleteStaff;
