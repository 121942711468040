import React from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';
import OrdersList from './components/OrdersList';
import { ListType } from './constatns';

const OrderListPage: React.FC = () => {
  const { type } = useParams();
  const intl = useIntl();
  setLayoutSubheader({
    title:
      type === ListType.FULL
        ? intl.formatMessage({ id: 'SUBMENU.ALLORDERS' })
        : intl.formatMessage({ id: 'SUBMENU.ABANDONEDCHECKOUTS' }),
  });
  setLayoutFooter({ show: true });

  return <OrdersList type={type || ''} smallSendMsgBtn />;
};

export default OrderListPage;
