import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import {
  Badge,
  Button,
  Card,
  CardContent,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DockIcon from '@mui/icons-material/Dock';
import VisibilityIcon from '@mui/icons-material/Visibility';
import RateReviewIcon from '@mui/icons-material/RateReviewOutlined';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { useSnackbar } from 'notistack';

import { useFormik } from 'formik';
import TablePaginator from '../../../components/TablePaginator/TablePaginator';
import { SHeadTableCell } from '../../../components/styledComponents/Tables';
import StatusIndicator from '../../../components/styledComponents/StatusIndicator';
import AlertDialog from '../../../components/ui/Dialog/AlertDialog';
import Preloader from '../../../components/ui/Preloader/Preloader';

import { IAppState } from '../../../store/rootDuck';
import { actions as productTypesActions } from '../../../store/ducks/productType.duck';
import { actions as productForDaysActions } from '../../../store/ducks/product-for-days.duck';
import { actions as productActions } from '../../../store/ducks/product.duck';
import { actions as cartActions } from '../../../store/ducks/cart.duck';
import { actions as supplyActions } from '../../../store/ducks/supply.duck';
import homeStyles from '../homeStyles';
import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';

import { actions as profileActions } from '../../../store/ducks/profile.duck';
import { ICompany } from '../../../interfaces/companies';
import CompanyBind from '../companies/CompanyBind';
import { needBindCompany } from '../../../utils/user';
import useCrudSnackbar from '../../../hooks/useCrudSnackbar';
import { thousands } from '../../../utils/utils';
import { IProduct } from '../../../interfaces/product';
import { getProductImage } from './utils/getProductImage';
import { toAbsoluteUrl } from '../../../../_base';
import { useDefineUserRole, useFormatMessage } from '../../../hooks';
import { useStylesProductList } from './hooks/useStyles';

const ProductList: React.FC<TPropsFromRedux> = ({
  products,
  loading,
  fetch,
  page,
  perPage,
  total,
  delProduct,
  clearDel,
  deleteError,
  setEmpty,
  me,
  fetchMe,
  loadingMe,
  successMe,
  clearMe,
  clearProducts,
  pageForDays,
  perPageForDays,
  productsForDays,
  forDays,
  fetchProductsForDays,
  setForDays,

  addProductLoading,
  addProductSuccess,
  addProductError,
  addProductToCart,
  cartCompanyId,
  isAdmin,
  clearParameters,
  supplyCompanyId,
  addProductSupplyError,
  addProductSupplySuccess,
  addProductToSupply,
  clearAddProduct,
  fetchAccord,
  supplyData,
}) => {
  const homeClasses = homeStyles();
  const classes1 = useStylesProductList();
  const { classes } = classes1;
  const intl = useIntl();
  const fm = useFormatMessage();
  const navigate = useNavigate();
  const { type } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const isRoleVendor = useDefineUserRole(me, ['ROLE_VENDOR', 'ROLE_VENDOR_STAFF']);
  const isBuyer = useDefineUserRole(me, ['ROLE_BUYER', 'ROLE_BUYER_STAFF']);
  const isManager = useDefineUserRole(me, 'ROLE_MANAGER');
  const [deleteId, setDeleteId] = useState<number | undefined>(-1);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [cartProductId, setCartProductId] = useState<number>(-1);
  const [isCartAlertOpen, setCartAlertOpen] = useState(false);
  const [userCompany, setUserCompany] = useState<null | ICompany>();
  const [forDaysValue, setForDaysValue] = useState<null | number>();

  // const [supplyProductId, setSupplyProductId] = useState<number>(-1);
  const [isSupplyAlertOpen, setSupplyAlertOpen] = useState(false);

  const isProductsNew = useMemo(() => type === 'new', [type]);

  const title = useMemo(() => {
    if (isProductsNew) {
      return 'PRODUCT.ALL.NEW_PRODUCTS';
    }
    return 'PRODUCT.TITLE';
  }, [isProductsNew]);

  const productList = useMemo(
    () => (isProductsNew ? productsForDays : products),
    [products, productsForDays, type]
  );

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: { forDays: forDaysValue },
    enableReinitialize: true,
    onSubmit: values => {
      if (values.forDays) {
        setForDays({ page: pageForDays, perPage: perPageForDays, forDays: +values.forDays });
      }
    },
  });

  useLayoutEffect(() => {
    isRoleVendor && fetchAccord();
  }, [isRoleVendor]);

  useEffect(() => {
    fetchMe();
    clearParameters();
    return () => {
      clearMe();
      clearProducts();
      clearAddProduct();
    };
  }, []);

  useEffect(() => {
    if (me && !userCompany) {
      setUserCompany(me.company);
    }
  }, [successMe]);

  useEffect(() => {
    setForDaysValue(forDays);
  }, [forDays]);

  useEffect(() => {
    if (isProductsNew) {
      fetchProductsForDays({ page: pageForDays, perPage: perPageForDays });
    }
    if (userCompany !== undefined && me) {
      if (me.is_admin) {
        fetch({ page, perPage });
      } else if (userCompany) {
        const companyId = userCompany.id;
        fetch({ page, perPage, companyId });
      } else {
        setEmpty();
      }
    }
  }, [fetch, userCompany, me, fetchProductsForDays, isProductsNew]);

  useEffect(() => {
    if (deleteError) {
      enqueueSnackbar(deleteError, { variant: 'error' });
      setAlertOpen(false);
      clearDel();
    }
    return () => {
      clearDel();
    };
  }, [deleteError, clearDel, enqueueSnackbar]);

  useCrudSnackbar({
    success: addProductSuccess,
    error: addProductError,
    successMessage: intl.formatMessage({ id: 'CART.ADD_PRODUCT.SUCCESS' }),
    errorMessage: `${intl.formatMessage({ id: 'ERROR' })}: ${addProductError}`,
    afterSuccessOrError: () => {
      setCartAlertOpen(false);
      setCartProductId(-1);
      clearAddProduct();
    },
  });

  useCrudSnackbar({
    success: addProductSupplySuccess,
    error: addProductSupplyError,
    successMessage: fm('SUPPLY.ADD.SUCCESS'),
    errorMessage: `${addProductSupplyError}`,
    afterSuccess: () => {
      fetchAccord();
    },
  });

  setLayoutSubheader({
    title: intl.formatMessage({ id: title }),
  });
  setLayoutFooter({ show: true });

  const handleDeleteDialog = useCallback((id: number | undefined) => {
    setDeleteId(id);
    setAlertOpen(true);
  }, []);

  const deleteAction = useCallback(() => {
    setAlertOpen(false);
    if (userCompany !== undefined && me) {
      if (me.is_admin) {
        delProduct({ page, perPage, id: deleteId });
      } else if (userCompany) {
        const companyId = userCompany.id;
        delProduct({ page, perPage, id: deleteId, companyId });
      }
    }
    if (isProductsNew) {
      fetchProductsForDays({ page: pageForDays, perPage: perPageForDays });
    }
  }, [deleteId, delProduct, perPage, page, me, userCompany]);

  const editAction = useCallback(
    (item: IProduct, isReviews?: boolean) => {
      navigate(`/products/edit/${item.id}`, { state: { isReviews } });
    },
    [navigate]
  );

  const viewAction = useCallback(
    (item: IProduct) => {
      navigate(`/viewproduct/${item.id}`);
    },
    [navigate]
  );

  const handleAddToCart = useCallback(
    (item: IProduct) => {
      if (!item.id) return;

      const isTheSameCompany = cartCompanyId === item.company?.id;

      if (isTheSameCompany) {
        addProductToCart({ product_id: item.id, count: 1 });
      } else {
        setCartProductId(item.id);
        setCartAlertOpen(true);
      }
    },
    [cartCompanyId]
  );

  const handleAddToSupply = useCallback(
    (item: IProduct) => {
      if (!item.id) return;
      const documentProduct = supplyData?.find(doc => doc.product.id === item.id);
      addProductToSupply({
        product_id: item.id,
        count: documentProduct ? documentProduct.amount + 1 : 1,
      });
    },
    [supplyCompanyId, supplyData]
  );

  const addCartProductAction = useCallback(() => {
    if (!addProductLoading) {
      addProductToCart({
        product_id: cartProductId,
        count: 1,
        newCart: true,
      });
    }
  }, [cartProductId, addProductToCart]);

  const addSupplyProductAction = useCallback(() => {
    if (!addProductLoading) {
      addProductToSupply({
        // product_id: supplyProductId,
        product_id: -1,
        count: 1,
        newCart: true,
      });
    }
  }, [addProductToSupply]);

  const goToCreateProduct = useCallback(() => {
    if (me?.is_admin || isManager) {
      navigate(`/products/create`);
    } else {
      navigate(`/products/create/company/${me?.company?.id}/${me?.company?.name}`);
    }
  }, [navigate, me, isManager]);

  if (!me || loadingMe || !productList || loading) return <Preloader />;

  if (needBindCompany(me)) {
    return <CompanyBind />;
  }

  return (
    <>
      <AlertDialog
        open={isAlertOpen}
        message={intl.formatMessage({ id: 'PRODUCT.DELETE.TEXT' })}
        okText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })}
        handleClose={() => {
          clearDel();
          setAlertOpen(false);
        }}
        handleAgree={() => deleteAction()}
      />

      <AlertDialog
        open={isCartAlertOpen}
        message={intl.formatMessage({ id: 'CART.ALERT.TEXT' })}
        okText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })}
        handleClose={() => {
          setCartAlertOpen(false);
        }}
        handleAgree={() => addCartProductAction()}
      />

      <AlertDialog
        open={isSupplyAlertOpen}
        message={intl.formatMessage({ id: 'CART.ALERT.TEXT' })}
        okText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })}
        handleClose={() => {
          setSupplyAlertOpen(false);
        }}
        handleAgree={() => addSupplyProductAction()}
      />

      {!isBuyer && me?.company && me.company.name && (
        <Button
          color='primary'
          variant='contained'
          onClick={() => goToCreateProduct()}
          className={classes.createBtn}
        >
          {intl.formatMessage({ id: 'PRODUCT.BUTTON.ADD' })}
        </Button>
      )}

      {isProductsNew && (
        <form onSubmit={handleSubmit} autoComplete='off' className={classes.textInput}>
          <TextField
            type='number'
            label={intl.formatMessage({ id: 'PRODUCT.FOR.DAYS' })}
            margin='normal'
            name='forDays'
            value={values.forDays}
            variant='outlined'
            style={{ marginRight: 30 }}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched.forDays && errors.forDays}
            error={Boolean(touched.forDays && errors.forDays)}
          />
          <Button color='primary' type='submit' variant='contained' style={{ height: '100%' }}>
            {intl.formatMessage({ id: 'COMPANY.TABLE.BUTTON.EDIT' })}
          </Button>
        </form>
      )}

      <Row>
        <Col>
          {!productList || !productList.length ? (
            <Typography className={classes.empty} component='h5' variant='h5'>
              {intl.formatMessage({ id: 'COMMON.LIST.EMPTY' })}
            </Typography>
          ) : (
            <>
              <Card className={classes.card}>
                <CardContent className={homeClasses.classes.tableContainer}>
                  <Table aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <SHeadTableCell style={{ fontWeight: 'bold' }}>
                          {intl.formatMessage({ id: 'PRODUCT.TABLE.ID' })}
                        </SHeadTableCell>
                        <SHeadTableCell style={{ fontWeight: 'bold' }}>
                          {intl.formatMessage({ id: 'PRODUCT.TABLE.PREVIEW' })}
                        </SHeadTableCell>
                        <SHeadTableCell style={{ fontWeight: 'bold' }}>
                          {intl.formatMessage({ id: 'PRODUCT.TABLE.NAME' })}
                        </SHeadTableCell>
                        <SHeadTableCell style={{ fontWeight: 'bold' }}>
                          {intl.formatMessage({ id: 'PRODUCT.TABLE.CATEGORY.NAME' })}
                        </SHeadTableCell>
                        <SHeadTableCell style={{ fontWeight: 'bold' }}>
                          {intl.formatMessage({ id: 'PRODUCT.TABLE.PRICE' })}
                        </SHeadTableCell>
                        {(isRoleVendor || isAdmin) && (
                          <SHeadTableCell style={{ fontWeight: 'bold' }}>
                            {intl.formatMessage({ id: 'PRODUCT.TABLE.STOCK.QUANTITY' })}
                          </SHeadTableCell>
                        )}
                        {!isBuyer && (
                          <SHeadTableCell style={{ fontWeight: 'bold' }}>
                            {intl.formatMessage({ id: 'PRODUCT.TABLE.ACTIVE' })}
                          </SHeadTableCell>
                        )}
                        {me.is_admin && (
                          <SHeadTableCell style={{ fontWeight: 'bold' }}>
                            {intl.formatMessage({ id: 'MENU.COMPANY' })}
                          </SHeadTableCell>
                        )}
                        {!isBuyer && (
                          <SHeadTableCell style={{ fontWeight: 'bold' }}>
                            {intl.formatMessage({ id: 'PRODUCT.TABLE.ACTIONS' })}
                          </SHeadTableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {productList.map(item => (
                        <TableRow key={item.id}>
                          <TableCell component='th' scope='row'>
                            {item.id || '-'}
                          </TableCell>
                          <TableCell>
                            <img
                              src={getProductImage(item)}
                              className={classes.img}
                              alt=' '
                              onError={(e: any) => {
                                e.target.src = toAbsoluteUrl('/images/placeholder.png');
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <Link
                              component={RouterLink}
                              to={`/viewproduct/${item.id}`}
                              className={classes.buttons}
                            >
                              {item.translations?.[intl.locale]?.name || item.name || '-'}
                            </Link>
                          </TableCell>
                          <TableCell>
                            {intl.locale === 'ru'
                              ? item.category?.name || '-'
                              : // @ts-ignore
                                item.category?.names?.[`name_${intl.locale}`] ||
                                item.category?.name ||
                                '-'}
                          </TableCell>
                          <TableCell>
                            {item.price ? thousands(String(item.price)) : '-'}
                          </TableCell>
                          {(isRoleVendor || isAdmin) && (
                            <TableCell>{item.stock_quantity || '-'}</TableCell>
                          )}
                          {!isBuyer && (
                            <TableCell>
                              <StatusIndicator isActive={item.active} />
                            </TableCell>
                          )}
                          {me.is_admin && (
                            <TableCell>
                              {item.company ? (
                                <Link
                                  component={RouterLink}
                                  to={`/companies/edit/${item.company?.id}`}
                                  className={classes.buttons}
                                >
                                  {item.company.name}
                                </Link>
                              ) : (
                                '-'
                              )}
                            </TableCell>
                          )}
                          <TableCell>
                            <div>
                              {!isRoleVendor && !isAdmin && (
                                <IconButton
                                  disabled={addProductLoading}
                                  onClick={() => handleAddToCart(item)}
                                >
                                  <AddShoppingCartIcon color='primary' />
                                </IconButton>
                              )}
                              <IconButton onClick={() => viewAction(item)}>
                                <VisibilityIcon color='primary' />
                              </IconButton>
                              {!isBuyer && (
                                <>
                                  {!isAdmin && (
                                    <Tooltip
                                      title={intl.formatMessage({
                                        id: 'PRODUCTS.TABLE.TOOLTIP.ADD.TO.SUPPLY',
                                      })}
                                    >
                                      <IconButton
                                        onClick={() => {
                                          handleAddToSupply(item);
                                        }}
                                      >
                                        <DockIcon color='primary' />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                  <Tooltip
                                    title={intl.formatMessage({
                                      id: 'PRODUCTS.TABLE.TOOLTIP.EDIT',
                                    })}
                                  >
                                    <IconButton onClick={() => editAction(item)}>
                                      <EditIcon color='primary' />
                                    </IconButton>
                                  </Tooltip>
                                  <IconButton onClick={() => editAction(item, true)}>
                                    <Badge
                                      badgeContent={item.review_count || 0}
                                      color='primary'
                                    >
                                      <RateReviewIcon color='primary' />
                                    </Badge>
                                  </IconButton>
                                  <IconButton
                                    onClick={() => {
                                      handleDeleteDialog(item.id);
                                    }}
                                  >
                                    <DeleteIcon color='error' />
                                  </IconButton>
                                </>
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>

                    <TableFooter>
                      <TableRow>
                        <TablePaginator
                          id={undefined}
                          page={page}
                          realPerPage={productList.length}
                          perPage={perPage}
                          total={total}
                          fetchRows={fetch}
                          companyId={userCompany?.id.toString()}
                          label={intl.formatMessage({ id: 'PRODUCT.TABLE.PERPAGE' })}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </CardContent>
              </Card>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    products: state.products.products,
    loading: state.products.loading,
    page: state.products.page,
    perPage: state.products.per_page,
    total: state.products.total,
    deleteError: state.products.delError,

    me: state.profile.me,
    loadingMe: state.profile.loading,
    successMe: state.profile.success,

    addProductLoading: state.cart.addProductLoading,
    addProductSuccess: state.cart.addProductSuccess,
    addProductError: state.cart.addProductError,
    cartCompanyId: state.cart.companyId,
    isAdmin: state.auth.user?.is_admin,

    pageForDays: state.productForDays.page,
    perPageForDays: state.productForDays.per_page,
    productsForDays: state.productForDays.productsForDays,
    forDays: state.productForDays.forDays,

    addProductSupplyError: state.supply.addProductError,
    addProductSupplySuccess: state.supply.addProductSuccess,
    supplyData: state.supply.accord?.document_products,
    supplyCompanyId: state.supply.companyId,
  }),
  {
    fetch: productActions.fetchRequest,
    delProduct: productActions.delRequest,
    clearDel: productActions.clearDel,
    setEmpty: productActions.setEmpty,
    clearProducts: productActions.clearProducts,
    clearParameters: productTypesActions.clearParams,

    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,

    addProductToCart: cartActions.addProductRequest,

    fetchProductsForDays: productForDaysActions.fetchRequest,
    setForDays: productForDaysActions.setForDays,

    clearAddProduct: supplyActions.clearAddProduct,
    fetchAccord: supplyActions.fetchAccord,
    addProductToSupply: supplyActions.addProductRequest,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ProductList);
