import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { getResponseMessage } from '../../../../utils/utils';
import { useFormatMessage } from '../../../../hooks';
import { getGroup } from '../../../../crud/productType.crud';

export const useGetGroup = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loadingGroup, setLoading] = useState(false);
  const [params, setGroups] = useState([]);
  const fm = useFormatMessage();

  const fetchGroup = useCallback(async (group: number) => {
    setLoading(true);
    getGroup(group)
      .then(res => {
        setGroups(res.data.data);
      })
      .catch(e => {
        enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { fetchGroup, loadingGroup, params };
};
