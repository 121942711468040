import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { getCompanies } from '../../../../crud/companies.crud';
import { getResponseMessage } from '../../../../utils/utils';
import { ICompany } from '../../../../interfaces/companies';

export const useGetCompanies: any = () => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState<ICompany[] | null>([]);
  const [page, setPage] = useState({ page: 1, perPage: 20, total: 0 });

  const fetch = useCallback(async (page: number, perPage: number) => {
    setLoading(true);
    getCompanies(page, perPage)
      .then((res: any) => {
        setCompanies(res.data.data);
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { page, per_page, total } = res.data;
        setPage({ page, perPage: per_page, total });
      })
      .catch(e => {
        enqueueSnackbar(`${intl.formatMessage({ id: 'ERROR' })}: ${getResponseMessage(e)}`, {
          variant: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return [fetch, loading, companies, page];
};
