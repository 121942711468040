import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';

import { IProductType } from '../../../../interfaces/productType';

interface IProps {
  editSuccess: boolean;
  editParamError: string | null;
  editError: string | null;
  editParamSuccess: boolean;
  id: number | undefined;
  type: IProductType | undefined;
  clearError: () => void;
  setId: React.Dispatch<React.SetStateAction<number | undefined>>;
  errorPositions: string | null;
}

const useSnackbarProductType: React.FC<IProps> = ({
  editSuccess,
  editError,
  editParamSuccess,
  editParamError,
  id,
  setId,
  type,
  clearError,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();

  useEffect(() => {
    if (editSuccess || editError || editParamSuccess || editParamError) {
      if (editSuccess || editError) {
        enqueueSnackbar(
          editSuccess
            ? `${intl.formatMessage({ id: 'PRODUCT.TYPES.SNACKBAR.TITLE' })} ${
                id
                  ? `${intl.formatMessage({ id: 'PRODUCT.TYPES.SNACKBAR.EDIT' })}`
                  : `${intl.formatMessage({ id: 'PRODUCT.TYPES.SNACKBAR.ADD' })}`
              }`
            : editError,
          { variant: editSuccess ? 'success' : 'error' }
        );
      }

      if (editParamSuccess || editParamError) {
        enqueueSnackbar(
          editParamSuccess
            ? `${intl.formatMessage({ id: 'PRODUCT.TYPES.PARAM.SNACKBAR.TITLE' })} ${
                id
                  ? `${intl.formatMessage({ id: 'PRODUCT.TYPES.PARAM.SNACKBAR.EDIT' })}`
                  : `${intl.formatMessage({ id: 'PRODUCT.TYPES.PARAM.SNACKBAR.ADD' })}`
              }`
            : editError,
          { variant: editParamSuccess ? 'success' : 'error' }
        );
      }

      if (editSuccess || editParamSuccess) {
        setId(type?.id);
        clearError();
      }
    }
  }, [
    editSuccess,
    editError,
    enqueueSnackbar,
    id,
    editParamSuccess,
    editParamError,
    clearError,
    intl,
    setId,
    type,
  ]);

  return null;
};

export default useSnackbarProductType;
