import React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableFooter,
} from '@mui/material';
import { DialogProps } from '@mui/material/Dialog';
import { Modal } from '../../../../components/other/Modals';
import { useFormatMessage } from '../../../../hooks';
import { ICompany } from '../../../../interfaces/companies';
import TopTableCell from '../../../../components/other/Table/TopTableCell';
import { ButtonWithLoader } from '../../../../components/other/Buttons';
import TablePaginator from '../../../../components/tableComponents/TablePaginator/TablePaginator';

type TProps = {
  selectedCompany: ICompany | null;
  setSelectedCompany: (value: any) => void;
  open: boolean;
  setOpen: (value: boolean) => void;
  getCompanies: (page: number, perPage: number) => void;
  loading: boolean;
  companies: ICompany[];
  pageData: { page: number; perPage: number; total: number };
};

const ModalCompanies: React.FC<TProps> = ({
  selectedCompany,
  setSelectedCompany,
  open,
  setOpen,
  getCompanies,
  loading,
  companies,
  pageData,
}) => {
  const fm = useFormatMessage();

  return (
    <Modal
      DialogProps={{ maxWidth: 'md' } as DialogProps}
      open={open}
      onClose={() => setOpen(false)}
      title={fm('MENU.COMPANY')}
      loading={loading}
      content={
        <div>
          <Table aria-label='CompaniesTable'>
            <TableHead>
              <TableRow>
                <TopTableCell>{fm('COMPANY.TABLE.NAME')}</TopTableCell>
                <TopTableCell>{fm('COMPANY.TABLE.INN')}</TopTableCell>
                <TopTableCell>{fm('COMPANY.TABLE.PHONE')}</TopTableCell>
                <TopTableCell>{fm('COMPANY.TABLE.SITE')}</TopTableCell>
                <TopTableCell>{fm('COMPANY.TABLE.ACTIONS')}</TopTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companies.map((company: ICompany) => (
                <TableRow key={company.id} selected={selectedCompany?.id === company.id}>
                  <TableCell>{company.name}</TableCell>
                  <TableCell>{company.inn}</TableCell>
                  <TableCell>{company.phone_number}</TableCell>
                  <TableCell>{company.site}</TableCell>

                  <TableCell>
                    <div style={{ minWidth: 145 }}>
                      <ButtonWithLoader
                        onPress={() => {
                          setSelectedCompany(company);
                          setOpen(false);
                        }}
                      >
                        {fm('COMPANY.TABLE.BUTTON.CHOOSE')}
                      </ButtonWithLoader>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePaginator
                  page={pageData.page}
                  perPage={pageData.perPage}
                  fetchRows={(data: any) => getCompanies(data.page, data.perPage)}
                  total={pageData.total}
                  label={fm('COMPANY.TABLE.TITLE')}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      }
      actions={[
        {
          title: fm('COMMON.BUTTON.CLOSE'),
          onClick: () => setOpen(false),
        },
        {
          title: `${fm('COMPANY.TABLE.BUTTON.CHOOSE')} ${fm('COMPANY.BUTTON.ALL')}`,
          onClick: () => setSelectedCompany({ id: -1, name: fm('COMPANY.BUTTON.ALL') }),
          variant: 'contained',
        },
      ]}
    />
  );
};

export default React.memo(ModalCompanies);
