import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { CircularProgress } from '@mui/material';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);

interface IProps {
  size?: number;
  color?: string;
}

const Preloader: React.FC<IProps> = ({ size, color }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress style={{ color }} size={size} />
    </div>
  );
};

export default Preloader;
