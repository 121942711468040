import React from 'react';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';
import MessageIcon from '@mui/icons-material/MessageOutlined';
import DockIcon from '@mui/icons-material/Dock';

import { toAbsoluteUrl } from '../..';
import HeaderDropdownToggle from '../content/CustomDropdowns/HeaderDropdownToggle';
import { API_DOMAIN } from '../../../app/constants';
import { useDefineUserRole } from '../../../app/hooks';
import { UserRoles } from '../../../app/interfaces/user';
import { IAppState } from '../../../app/store/rootDuck';

const useStyles = makeStyles()(theme => {
  return {
    headerBtn: {
      marginLeft: theme.spacing(1),
    },
    authText: {
      display: 'none',

      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
    },
    cartText: {
      display: 'none',
      color: theme.palette.secondary.main,

      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
    },
    cartBtn: {
      marginRight: 0,
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
    },
    chatBtn: {
      marginRight: 10,
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      [theme.breakpoints.up('md')]: {
        marginRight: 20,
      },
    },
    cartBtnGuest: {
      marginRight: 0,
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      [theme.breakpoints.up('md')]: {
        marginRight: 30,
      },
    },
    cartBadge: {
      display: 'block',
      position: 'absolute',
      right: 0,
      width: 10,
      height: 10,
      [theme.breakpoints.up('md')]: {
        position: 'absolute',
        right: 0,
        transform: 'translateX(110%)',
      },
    },
    auth: {
      marginLeft: '20px',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        marginLeft: 0,
      },
    },
    profileAvatar: {
      width: 25,
      marginLeft: 15,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        marginLeft: 0,
        width: 80,
      },
    },
    showAvatar: {
      objectFit: 'cover',
      width: 25,
      height: 25,
      borderRadius: '50%',
      borderColor: '#369ff7',
      borderWidth: 2,
      borderStyle: 'solid',
    },
    notAvatar: {
      objectFit: 'contain',
      width: '100%',
      height: '100%',
      maxHeight: 25,
      cursor: 'pointer',
    },
  };
});

type TProps = {
  showAvatar?: boolean;
};

const UserProfile: React.FC<TProps> = ({ showAvatar }) => {
  const intl = useIntl();
  const classes1 = useStyles();
  const { classes } = classes1;

  const { me, loading } = useSelector((store: IAppState) => ({
    me: store.profile.me,
    loading: store.profile.loading,
  }));

  const { isAuthorized } = useSelector((store: IAppState) => ({
    isAuthorized: store.auth.user != null,
  }));

  const { supplyLength } = useSelector((store: IAppState) => ({
    supplyLength: store.supply.accord?.document_products
      ? store.supply.accord.document_products.length
      : 0,
  }));

  const { cartLenght, guestCartLenght } = useSelector((store: IAppState) => ({
    cartLenght: store.cart.cart?.goods_num,
    guestCartLenght: store.cart.guestCart?.goods_num,
  }));

  const isRoleVendor = useDefineUserRole(me, ['ROLE_VENDOR', 'ROLE_VENDOR_STAFF']);
  const isAdmin = useDefineUserRole(me, 'ROLE_ADMIN');
  const isBuyer = useDefineUserRole(me, ['ROLE_BUYER', 'ROLE_BUYER_STAFF']);

  if (loading) return null;

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {!isRoleVendor && !isAdmin && (
        <>
          {isBuyer && (
            <Link
              to={`/chats`}
              className={`kt-header__topbar-user kt-menu__link ${classes.chatBtn}`}
            >
              <MessageIcon style={{ width: 25, height: 25 }} />
              <div
                className={`kt-menu__link-text  ${classes.cartText}`}
                style={{ fontWeight: 700 }}
              >
                <FormattedMessage id='SUBMENU.CHATS' defaultMessage={'SUBMENU.CHATS'} />
              </div>
            </Link>
          )}
          {(isBuyer || !isAuthorized) && (
            <Link
              to={`/cart`}
              className={`kt-header__topbar-user kt-menu__link ${
                isAuthorized ? classes.cartBtn : classes.cartBtnGuest
              }`}
            >
              <img
                alt='Select file'
                className={`kt-menu__link-icon  kt-menu-cart-icon`}
                src={toAbsoluteUrl('/images/basket-icon.svg')}
              />
              <div
                className={`kt-menu__link-text  ${classes.cartText}`}
                style={{ fontWeight: 700 }}
              >
                <FormattedMessage
                  id='CART.SUBHEADER.TITLE'
                  defaultMessage={'CART.SUBHEADER.TITLE'}
                />
              </div>
              {((Boolean(isAuthorized) && cartLenght && cartLenght > 0) ||
                (!Boolean(isAuthorized) && guestCartLenght && guestCartLenght > 0)) && (
                <div className={classes.cartBadge}>
                  {Boolean(isAuthorized) && cartLenght && cartLenght > 0 && (
                    <span className='kt-menu__link-badge'>
                      <span
                        className={`kt-badge kt-badge--danger kt-badge--bolder kt-badge--inline kt-badge--pill`}
                      >
                        {cartLenght}
                      </span>
                    </span>
                  )}
                  {!Boolean(isAuthorized) && guestCartLenght && guestCartLenght > 0 && (
                    <span className='kt-menu__link-badge'>
                      <span
                        className={`kt-badge kt-badge--danger kt-badge--bolder kt-badge--inline kt-badge--pill`}
                      >
                        {guestCartLenght}
                      </span>
                    </span>
                  )}
                </div>
              )}
            </Link>
          )}
        </>
      )}

      {isAuthorized && me && !isAdmin && isRoleVendor && (
        <Link
          to={`/supply`}
          className={`kt-header__topbar-user kt-menu__link ${classes.cartBtn}`}
        >
          <DockIcon fontSize='large' color='primary' />
          <div
            className={`kt-menu__link-text  ${classes.cartText}`}
            style={{ fontWeight: 700 }}
          >
            <FormattedMessage
              id='SUPPLY.SUBHEADER.TITLE'
              defaultMessage={'SUPPLY.SUBHEADER.TITLE'}
            />
          </div>
          <div className={classes.cartBadge}>
            {Boolean(isAuthorized) && (
              <span className='kt-menu__link-badge'>
                <span
                  className={`kt-badge kt-badge--danger kt-badge--bolder kt-badge--inline kt-badge--pill`}
                >
                  {supplyLength}
                </span>
              </span>
            )}
          </div>
        </Link>
      )}

      {isAuthorized ? (
        <Dropdown
          className='kt-header__topbar-item kt-header__topbar-item--user'
          drop='down'
          alignRight
        >
          <Dropdown.Toggle as={HeaderDropdownToggle} id='dropdown-toggle-user-profile'>
            <div
              className='kt-header__topbar-user kt-menu__link'
              style={{ cursor: 'pointer' }}
            >
              {/* {showHi && ( */}
              {/*   <span className='kt-header__topbar-username kt-hidden-mobile '> */}
              {/*     {intl.formatMessage({ id: 'AUTH.WELCOME.TITLE' }) + */}
              {/*       (showHi && me.first_name ? ',' : '')} */}
              {/*   </span> */}
              {/* )} */}
              {/* {showHi && me.first_name && ( */}
              {/*   <span */}
              {/*     className='kt-header__topbar-username kt-hidden-mobile' */}
              {/*     style={{ padding: '0 0.55rem 0 0' }} */}
              {/*   > */}
              {/*     {me.first_name} */}
              {/*   </span> */}
              {/* )} */}

              {showAvatar && (
                <div className={`kt-user-card__avatar ${classes.profileAvatar}`}>
                  {me?.avatar ? (
                    <img
                      className={classes.showAvatar}
                      src={`${API_DOMAIN}/${
                        typeof me.avatar === 'string' ? me.avatar : me.avatar.path
                      }`}
                      alt=''
                    />
                  ) : (
                    <img
                      alt='Select file'
                      className={`kt-menu__link-icon  kt-menu-cart-icon ${classes.notAvatar}`}
                      style={{ maxHeight: 25 }}
                      src={toAbsoluteUrl('/images/profile-circle.svg')}
                    />
                    // <span className='kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success'>
                    //   <b>{(me.first_name || '').toUpperCase()}</b>
                    // </span>
                  )}
                  <div
                    className={`kt-menu__link-text  ${classes.cartText}`}
                    style={{ fontWeight: 700 }}
                  >
                    <FormattedMessage
                      id='SETTING.PROFILE.EDIT'
                      defaultMessage={'SETTING.PROFILE.EDIT'}
                    />
                  </div>
                </div>
              )}

              {/* {showBadge && ( */}
              {/*   <span className='kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold'> */}
              {/*     {me.first_name || ''} */}
              {/*   </span> */}
              {/* )} */}
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className='dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl'>
            {/** ClassName should be 'dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
            <div
              className='kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x'
              style={{
                backgroundImage: `url(${toAbsoluteUrl('/media/misc/bg-1.jpg')})`,
              }}
            >
              <div
                className='kt-user-card__avatar'
                style={{ overflow: 'hidden', width: 60, height: 60, borderRadius: 4 }}
              >
                {me?.avatar ? (
                  <img
                    style={{ objectFit: 'contain', width: '100%', height: '100%' }}
                    src={`${API_DOMAIN}/${
                      typeof me.avatar === 'string' ? me.avatar : me.avatar.path
                    }`}
                    alt=''
                  />
                ) : (
                  <span className='kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success'>
                    <b>{(me?.first_name || '').toUpperCase()}</b>
                  </span>
                )}
              </div>
              <div className='kt-user-card__name' style={{ fontWeight: '700' }}>
                {me?.first_name || ''}
              </div>
            </div>
            <div className='kt-notification'>
              <Link to='/settings/profile' className='kt-notification__item'>
                <div className='kt-notification__item-icon'>
                  <i className='flaticon2-calendar-3 kt-font-success' />
                </div>
                <div className='kt-notification__item-details'>
                  <div className='kt-notification__item-title' style={{ fontWeight: '700' }}>
                    {intl.formatMessage({ id: 'SUBMENU.PROFILE' })}
                  </div>
                  <div className='kt-notification__item-time' style={{ fontWeight: '600' }}>
                    {intl.formatMessage({ id: 'SUBMENU.ACCOUNT_SETTINGS' })}
                  </div>
                </div>
              </Link>
              {isBuyer ||
                (isRoleVendor && (
                  <>
                    <Link to='/orders/list/full' className='kt-notification__item'>
                      <div className='kt-notification__item-icon'>
                        <i className='flaticon-bag kt-font-success  icon-profile' />
                      </div>
                      <div className='kt-notification__item-details'>
                        <div
                          className='kt-notification__item-title'
                          style={{ fontWeight: '700' }}
                        >
                          {intl.formatMessage({ id: 'MENU.ORDERS' })}
                        </div>
                      </div>
                    </Link>
                    <Link to='/orders/list/history' className='kt-notification__item'>
                      <div className='kt-notification__item-icon'>
                        <i className='flaticon-clock-2 kt-font-success  icon-profile' />
                      </div>
                      <div className='kt-notification__item-details'>
                        <div
                          className='kt-notification__item-title'
                          style={{ fontWeight: '700' }}
                        >
                          {intl.formatMessage({ id: 'SUBMENU.HISTORY' })}
                        </div>
                      </div>
                    </Link>
                  </>
                ))}
              <div className='kt-notification__custom'>
                <Link
                  to='/logout'
                  className='btn btn-label-brand btn-sm'
                  style={{ fontWeight: '700' }}
                >
                  {intl.formatMessage({ id: 'SUBMENU.EXIT' })}
                </Link>
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        <Link to='/auth' className={`kt-login__signup ${classes.auth}`}>
          <img
            alt='Select file'
            style={{ maxHeight: 25 }}
            className={`kt-menu__link-icon  kt-menu-cart-icon`}
            src={toAbsoluteUrl('/images/profile-circle-auth.svg')}
          />
          <div
            className={`kt-menu__link-text  ${classes.cartText}`}
            style={{ fontWeight: 700 }}
          >
            <FormattedMessage id='SUBMENU.AUTHORIZE' defaultMessage={'SUBMENU.AUTHORIZE'} />
          </div>
          {/* <Button
            onClick={() => {
              history.push('/auth');
            }}
            className={classes.headerBtn}
            variant='contained'
            color='secondary'
          >
            {intl.formatMessage({ id: 'SUBMENU.AUTHORIZE' })}
          </Button> */}
        </Link>
      )}
    </div>
  );
};

export default UserProfile;
