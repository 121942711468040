import React from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Typography,
  Theme
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';

import { API_DOMAIN } from '../../../../constants';
import { ICart, ICartItem, IGuestCart, IGuestCartItem } from '../interfaces';
import homeStyles from '../../homeStyles';
import { IWorkingTime } from '../../../../interfaces/store';
import { thousands } from '../../../../utils/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    empty: {
      marginBottom: 20,
      marginTop: 20,
    },
    buttons: {
      marginRight: theme.spacing(3),
    },
    link: {
      marginRight: theme.spacing(3),
      fontSize: 17,
      color: '#002626 !important',
    },
    card: {
      width: '97%',
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginBottom: 20,
      },
    },
    categoryTree: {
      maxWidth: 400,
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    img: {
      maxHeight: 100,
      cursor: 'pointer',

      [theme.breakpoints.down('sm')]: {
        maxHeight: 150,
      },
    },
    totalCard: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '30%',
      },
    },
    contentWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    infoWrapper: {
      marginLeft: 20,
      width: '100%',
      [theme.breakpoints.down(365)]: {
        marginLeft: 13,
      },
      [theme.breakpoints.down(350)]: {
        marginLeft: 4,
      },
    },
    buttonsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      height: 50,
      justifyContent: 'space-between',
      width: 186,
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },
      [theme.breakpoints.down(365)]: {
        width: 178,
      },
      [theme.breakpoints.down(350)]: {
        width: 174,
      },
    },
    buttonBackground: {
      width: 40,
      height: 34,
      backgroundColor: '#f5f5f5',
      borderRadius: 4,
      textAlign: 'center',
    },
    infoButtonsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
      },
    },
  })
);

const ItemCart: React.FC<{
  cart: ICart | IGuestCart;
  setProductCount: (data: { product_id: number; count: number; type: 'dec' | 'inc' }) => void;
  setCountGuestCart: (data: {
    product_id: number;
    count: number;
    type: 'dec' | 'inc';
  }) => void;
  handleImage?: (url?: string) => void;
  setProductCountLoading: boolean;
  isAuthorized: boolean;
  isAdmin?: boolean;
  location?: {
    address: string;
    lng: number;
    lat: number;
    city: string;
    country: string;
    house: string;
    province: string;
    street: string;
    working_times?: IWorkingTime[];
  };
  alertWindow?: any;
}> = ({
  cart,
  setProductCount,
  setProductCountLoading,
  isAuthorized,
  setCountGuestCart,
  isAdmin,
  // location,
  handleImage,
  // alertWindow,
}) => {
  const classes = useStyles();
  const homeClasses = homeStyles();

  return (
    <div>
      {cart &&
        (cart.items as Array<ICartItem | IGuestCartItem>).map(item => {
          return (
            <Card className={classes.card}>
              <CardContent className={homeClasses.classes.tableContainer}>
                <div className={classes.contentWrapper}>
                  <div>
                    {item.product.photos && item.product.photos.length > 0 ? (
                      <img
                        alt=''
                        onClick={() =>
                          item.product.photos &&
                          handleImage &&
                          handleImage(
                            `${API_DOMAIN}/${
                              item?.product?.photos?.find(el => el.main)?.path ||
                              item.product.photos[0].path ||
                              ''
                            }`
                          )
                        }
                        src={`${API_DOMAIN}/${
                          item?.product?.photos?.find(el => el.main)?.path ||
                          item.product.photos[0].path
                        }`}
                        className={classes.img}
                      />
                    ) : (
                      <img
                        alt=''
                        onClick={() =>
                          item.product.photos &&
                          handleImage &&
                          handleImage(
                            `${API_DOMAIN}/${
                              item?.product?.photos?.find(el => el.main)?.path ||
                              item.product.photos[0].path ||
                              ''
                            }`
                          )
                        }
                        src='../../../images/placeholder.png'
                        className={classes.img}
                      />
                    )}
                  </div>
                  <div className={classes.infoWrapper}>
                    <div>
                      <div
                        style={{
                          maxHeight: 72,
                          overflow: 'hidden',
                        }}
                      >
                        <Link
                          to={`${
                            isAdmin
                              ? `/products/edit/${item.product.id}`
                              : `/viewproduct/${item.product.id}`
                          }`}
                          className={classes.link}
                        >
                          {item.product.name}
                        </Link>
                      </div>

                      <div className={classes.infoButtonsWrapper}>
                        <div>
                          <Typography
                            style={{
                              color: '#002626',
                              fontSize: 34,
                              fontWeight: 'bold',
                              height: 50,
                            }}
                          >
                            {thousands(String(+item.product.price * item.count))} ₽
                          </Typography>
                          <Typography
                            style={{
                              color: '#757575',
                              fontSize: 17,
                            }}
                          >
                            Вес: {item?.product?.stock_info?.weight || 0} гр.
                          </Typography>
                        </div>
                        <div className={classes.buttonsWrapper}>
                          <div className={classes.buttonBackground}>
                            <DeleteIcon
                              style={{
                                color: '#757575',
                                fontSize: 24,
                                marginTop: 5,
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                isAuthorized
                                  ? setProductCount({
                                      product_id: item.product.id || 0,
                                      count: 0,
                                      type: 'dec',
                                    })
                                  : setCountGuestCart({
                                      product_id: item.product.id || 0,
                                      count: 0,
                                      type: 'dec',
                                    });
                              }}
                            />
                          </div>
                          <div>
                            <ButtonGroup
                              color='primary'
                              aria-label='outlined primary button group'
                            >
                              <Button
                                disabled={setProductCountLoading}
                                onClick={() =>
                                  isAuthorized
                                    ? setProductCount({
                                        product_id: item.product.id || 0,
                                        count: +item.count - 1,
                                        type: 'dec',
                                      })
                                    : setCountGuestCart({
                                        product_id: item.product.id || 0,
                                        count: +item.count - 1,
                                        type: 'dec',
                                      })
                                }
                              >
                                <RemoveIcon fontSize='small' />
                              </Button>
                              <Button
                                disabled
                                style={{
                                  color: '#000',
                                  borderColor: '#369ff7',
                                  fontSize: 15,
                                }}
                              >
                                {item.count}
                              </Button>
                              <Button
                                style={{
                                  borderLeft: 'none',
                                  //  backgroundColor: '#D3D3D3',
                                  // (item?.product?.stock_info?.available_quantity || 0) >
                                  // +item.count
                                  //   ? 'inherit'
                                  //   : '#D3D3D3',
                                }}
                                disabled={setProductCountLoading}
                                onClick={() => {
                                  if (isAuthorized) {
                                    // if (
                                    //   +(item?.product?.stock_info?.available_quantity || 0) >
                                    //   +item.count
                                    // ) {
                                    setProductCount({
                                      product_id: item.product.id || 0,
                                      count: +item.count + 1,
                                      type: 'inc',
                                      // (item?.product?.stock_info?.available_quantity || 0) >
                                      // +item.count
                                      //   ? +item.count + 1
                                      //   : item.product?.stock_info?.available_quantity || 0,
                                    });
                                    // } else {
                                    //   alertWindow();
                                    // }
                                  } else {
                                    // if (
                                    //   +(item?.product?.stock_info?.available_quantity || 0) >
                                    //   +item.count
                                    // )
                                    setCountGuestCart({
                                      product_id: item.product.id || 0,
                                      count: +item.count + 1,
                                      // (item?.product?.stock_info?.available_quantity || 0) >
                                      // +item.count
                                      //   ? +item.count + 1
                                      //   : item.product?.stock_info?.available_quantity || 0,
                                      type: 'inc',
                                    });
                                    // } else {
                                    //   alertWindow();
                                    // }
                                  }
                                }}
                              >
                                <AddIcon fontSize='small' />
                              </Button>
                            </ButtonGroup>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          );
        })}
    </div>
  );
};

export default ItemCart;
