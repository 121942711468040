import { makeStyles, createStyles } from '@mui/styles';
import { green } from '@mui/material/colors';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      padding: '0',
      [theme.breakpoints.up('md')]: {
        padding: 0,
      },
    },
    cardWrapper: {
      width: '100%',
      backgroundColor: 'white',
      padding: '15px 30px 15px 30px',
      marginTop: 30
    },
    empty: {
      marginBottom: 20,
      marginTop: 20,
    },
    count: {
      fontStyle: 'bold',
      fontWeight: 600,
      color: '#000',
      marginRight: 15,
    },
    filterCol: {
      width: '100%',
      marginBottom: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        width: 326,
      },
    },
    filter: {
      marginTop: theme.spacing(3),
      marginBottom: 5,
      padding: '25px 30px',
      top: 100,
      boxShadow: 'none !important',
      overflow: 'visible',

      [theme.breakpoints.up('md')]: {},
    },
    label: {
      marginBottom: 7,
      fontSize: 14,
      fontWeight: 600,
      color: '#181c32',
    },
    rowFilter: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    ordersCol: {
      width: '100%',

      [theme.breakpoints.up('md')]: {
        width: 'calc(100% - 326px - 30px)',

        '&.full': {
          width: '100%',
        },
      },
    },
    orderColHeader: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 30,
    },
    orderColHeaderItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    filterItem: {
      transition: '0.3s ease-out',
      '&.hide': {
        [theme.breakpoints.down('sm')]: {
          height: 0,
          transform: 'scaleY(0)',
          transformOrigin: 'top',
          overflow: 'hidden',
        },
      },
    },
    buttons: {
      marginRight: theme.spacing(2),
    },
    card: {
      backgroundColor: '#FCFCFC',
      height: '100%',
      overflow: 'auto',
      borderRadius: '0 0 4px 4px',
      // boxShadow: '0 0 4px 0 #2f333a',
      border: 'solid 2px rgba(255, 255, 255, 0.4)',
      width: '100%',
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    bottomActions: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: theme.spacing(3),
      flexWrap: 'wrap',
    },
    img: {
      width: 50,
      height: 50,
    },
    typeList: {
      backgroundColor: 'white',
      maxWidth: 200,
    },
    itemStatus: {
      borderRadius: '4px',
      backgroundColor: '#c9f7f5',
      flex: 1,
    },
    viewButton: {
      marginRight: theme.spacing(2),
      color: theme.palette.primary.dark,
      textTransform: 'none',
      boxShadow: 'none',
      borderRadius: 2,
      padding: '10px 35px',
      fontWeight: 700,
      background: '#EBECF0',

      '&:hover': {
        boxShadow: 'none',
      },
    },
    iconViewButton: {
      marginLeft: 10,
    },
    green: {
      color: '#fff',
      backgroundColor: green[500],
    },
    emptyCompany: {
      width: 40,
      height: 40,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    companyField: {
      display: 'flex',
      // alignItems: 'center',
    },
    allCompaniesBtn: {
      marginTop: 8,
      marginLeft: 10,
      height: 52,
    },
    filesTable: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    filesTableRow: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
      },
      '&:last-child > td': {
        border: 0,
      },
      '&:last-child > th': {
        border: 0,
      },
    },
    filesTableCell: {
      [theme.breakpoints.down('sm')]: {
        '&:nth-child(1)': {
          paddingTop: 50,
        },
        '&:nth-child(1), &:nth-child(2)': {
          border: 0,
          paddingBottom: 0,
        },
        '&:nth-child(3)': {
          display: 'flex',
          justifyContent: 'flex-end',
        },
      },
    },
    filesTableInputCell: {
      width: '40%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    btnBlock: {
      display: 'flex',
      width: 56,
      height: 56,
      minWidth: 56,
      borderRadius: 4,
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 20,
    },
    btnText: {
      fontSize: 22,
      lineHeight: '28px',
      fontWeight: 'bold',
    },
    bottomBlocK: {
      width: '100%',
      height: 1,
      backgroundColor: '#727172FF',
    },
    btns: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    bottomBtn: {
      width: 200,
      height: 45,
      display: 'inline-flex',
      marginTop: 0,
    },
    titleFile: {
      fontSize: 20,
      lineHeight: '24px',
      fontWeight: 600,
      marginBottom: 20,
      marginTop: 20,
    },
    wrapperFile: {
      display: 'flex',
      // maxWidth: 950,
      width: '100%',
      backgroundColor: '#ffffff',
      borderRadius: 4,
      marginBottom: 15,
      padding: 15,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    wrapperBlocks: {
      display: 'flex',
      flexDirection: 'row',
    },
    wrapperBlock: {
      display: 'flex',
      width: 56,
      height: 56,
      minWidth: 56,
      borderRadius: 4,
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 20,
      backgroundColor: '#f7f9fb',
    },
    indexFile: {
      fontSize: 22,
      lineHeight: '28px',
      fontWeight: 'bold',
      color: theme.palette.primary.main,
    },
    fileInfo: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    fileType: {
      fontSize: 20,
      lineHeight: '28px',
      fontWeight: 500,
    },
    fileName: {
      // fontFamily: 'Montserrat',
      fontSize: 20,
      lineHeight: '28px',
      color: theme.palette.primary.main,
      fontWeight: 400,
      overflowWrap: 'anywhere',
    },
    fileBtn: {
      display: 'flex',
      width: 56,
      height: 56,
      minWidth: 56,
      borderRadius: 4,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#f7f9fb',
      marginLeft: 18,
      cursor: 'pointer',
    },
    content: {
      overflow: 'auto',
      height: '100%',
      width: '100%',
      backgroundColor: '#fafafb',
      display: 'flex',
      justifyContent: 'center',
    },
    typography: {
      fontSize: 20,
      lineHeight: '24px',
      fontWeight: 600,
      marginTop: 15,
      marginBottom: -10,
    },
    input: {
      backgroundColor: 'rgba(34, 61, 133, 0.1)',
      height: 62,
      border: 'none !important',
      outline: 'none !important',
      borderRadius: 4,
      fontSize: 17,
      paddingTop: 15,
    },
    inputFocused: {
      backgroundColor: 'white !important',
      outlineColor: '#223d85',
    },
    inputLabel: {
      position: 'absolute',
      marginTop: 20,
      marginLeft: 14,
      color: '#5e6278',
      zIndex: 9999,
      fontSize: 12,
      paddingTop: 3,
    },
    notchedOutline: {
      borderWidth: 0,
    },
    inputButtons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      width: '15%',
    },
    labelTextField: {
      color: '#5e6278',
    },
    labelTextFieldFocused: {
      marginTop: 15,
      color: '#5e6278',
    },
    labelTextFieldShrink: {
      marginTop: 15,
      color: '#5e6278',
    },
    inputIcon: {
      marginTop: 0,
      cursor: 'pointer'
    },
    selectFocused: {
      '&:focus': {
        backgroundColor: '#FFF'
      },
    },
    multilineInput: {
      backgroundColor: 'rgba(34, 61, 133, 0.1)',
      border: 'none !important',
      outline: 'none !important',
      borderRadius: 4,
      fontSize: 17,
      paddingTop: 25,
    },
    noCompanyStyle: {
      color: '#181c32',
      fontSize: 20,
      marginTop: 20,
      marginBottom: 20,
      marginLeft: 40,
      fontWeight: 700
    },
  })
);

export const useStylesModalAddParticipation = makeStyles((theme: any) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 20px',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#FFFFFFFF',
      [theme.breakpoints.up('sm')]: {
        width: 600,
      },
    },
    companyField: {
      display: 'flex',
      alignItems: 'center',
    },
    allCompaniesBtn: {
      marginTop: 8,
      marginLeft: 10,
      height: 52,
    },
    checkbox: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
      marginTop: 8,
    },
    rowFields: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    typography: {
      fontSize: 20,
      lineHeight: '24px',
      fontWeight: 600,
    },
    card: {
      width: '100%',
      backgroundColor: 'white',
      padding: '15px 30px 15px 30px',
      marginTop: 30
    },
    input: {
      backgroundColor: 'rgba(34, 61, 133, 0.1)',
      height: 62,
      border: 'none !important',
      outline: 'none !important',
      borderRadius: 4,
      fontSize: 17,
      // paddingTop: 15,
    },
    multilineInput: {
      backgroundColor: 'rgba(34, 61, 133, 0.1)',
      border: 'none !important',
      outline: 'none !important',
      borderRadius: 4,
      fontSize: 17,
      paddingTop: 25,
    },
    inputFocused: {
      backgroundColor: 'white !important',
      outlineColor: '#223d85',
      borderWidth: '1px !important'
    },
    inputLabel: {
      position: 'absolute',
      marginTop: 5,
      marginLeft: 14,
      color: '#5e6278',
      zIndex: 9999,
      fontSize: 10,
      padding: 3,
      backgroundColor: '#FFFFFF'
    },
    notchedOutline: {
      borderWidth: 0,
    },
    productButtonCard: {
      width: '100%',
      backgroundColor: 'white',
      marginTop: 30,
      padding: '0px 30px 10px 30px'
    },
    labelTextField: {
      color: '#5e6278',
    },
    labelTextFieldFocused: {
      marginTop: 15,
      color: '#5e6278',
    },
    labelTextFieldShrink: {
      marginTop: 15,
      color: '#5e6278',
    },
    inputIcon: {
      marginTop: 0,
      cursor: 'pointer',
    },
    selectFocused: {
      '&:focus': {
        backgroundColor: '#FFF'
      },
    },
  })
);
