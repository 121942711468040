import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { getResponseMessage } from '../../../../utils/utils';
import { deleteLogo } from '../../../../crud/companies.crud';

export const useDeleteLogo = () => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [loadingDeleteLogo, setLoading] = useState(false);
  const [successDeleteLogo, setSuccess] = useState(false);
  const deleteLogoFetch = useCallback(async (id: string) => {
    setLoading(true);
    if (id) {
      deleteLogo(id)
        .then(() => {
          setSuccess(true);
          enqueueSnackbar(`${intl.formatMessage({ id: 'COMPANY.DELETE.LOGO' })}`, {
            variant: 'success',
          });
        })
        .catch(e => {
          enqueueSnackbar(`${intl.formatMessage({ id: 'ERROR' })}: ${getResponseMessage(e)}`, {
            variant: 'error',
          });
        })
        .finally(() => {
          setLoading(false);
          setSuccess(false);
        });
    }
  }, []);
  return { deleteLogoFetch, loadingDeleteLogo, successDeleteLogo };
};
