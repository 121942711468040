import React, { useState, useCallback, useLayoutEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { DialogProps, Card } from '@mui/material';
import { useIntl } from 'react-intl';

import { Modal } from '../../../../components/other/Modals';
import { IAppState } from '../../../../store/rootDuck';
import { actions as usersActions } from '../../../../store/ducks/users.duck';
import { actions as categoriesActions } from '../../../../store/ducks/categories.duck';
import { setLayoutFooter, setLayoutSubheader } from '../../../../utils/layout';
import useHomeStyles from '../../../../constants/homeStyles';
import { actions as storeActions } from '../../../../store/ducks/store.duck';
import { useDefineUserRole, useFormatMessage } from '../../../../hooks';
import StoriesTable from '../../../../components/tableComponents/Table/StoresTable';
import { useFormikAddShift } from '../hooks/useFormikAddShift';
import { createChildrenList } from '../../../../utils/createParentList';
import UsersTable from '../../../../components/tableComponents/Table/UsersTable';
import { UserListType } from '../../users/constants';
import { useCreateShift } from '../hooks/useCreateShift';
import { useEditShift } from '../hooks/useEditShift';
import { useGetShift } from '../hooks/useGetShift';
import Preloader from '../../../../components/ui/Preloader/Preloader';
import FormShiftPage from './components/FormShiftPage';

const ShiftPage: React.FC = () => {
  const { id } = useParams();
  const intl = useIntl();

  const styles = useHomeStyles();
  const navigate = useNavigate();
  const fm = useFormatMessage();
  const dispatch = useDispatch();
  const {
    fetch: createShift,
    loading: loadingCreate,
    success: successCreate,
  } = useCreateShift();

  const { fetch: editShift, loading: loadingEdit, success: successEdit } = useEditShift();

  const { fetch: getShift, loading, shift } = useGetShift();

  const [openUsersDialog, setOpenUsersDialog] = useState(false);
  const [openStoresDialog, setOpenStoresDialog] = useState(false);

  // const { me } = useSelector(
  //   ({ profile: { me, loading } }: IAppState) => ({ me, loadingMe: loading }),
  //   shallowEqual
  // );

  const { categories } = useSelector(
    ({ categories: { categories } }: IAppState) => ({ categories }),
    shallowEqual
  );

  const { me } = useSelector(({ profile: { me } }: IAppState) => ({ me }), shallowEqual);

  const isManager = useDefineUserRole(me, 'ROLE_MANAGER');

  const { loadingUsers, users, perPageUsers, pageUsers, totalUsers, filter } = useSelector(
    ({ users: { loading, users, per_page, page, total, filter } }: IAppState) => ({
      loadingUsers: loading,
      users,
      perPageUsers: per_page,
      pageUsers: page,
      totalUsers: total,
      filter,
    }),
    shallowEqual
  );

  const { stores, loadingStores, pageStores, perPageStores, totalStores } = useSelector(
    ({ stores: { stores, loading, page, per_page, total } }: IAppState) => ({
      loadingStores: loading,
      stores,
      perPageStores: per_page,
      pageStores: page,
      totalStores: total,
    }),
    shallowEqual
  );

  const childCategories = useMemo(() => {
    return createChildrenList(categories, '\u00B7 ');
  }, [categories]);

  const handleOpenUsersDialog = useCallback(() => {
    dispatch(
      usersActions.fetchRequest({
        per_page: perPageUsers,
        page: pageUsers,
        roles: 'ROLE_VENDOR_STAFF',
        filter,
      })
    );
    setOpenUsersDialog(true);
  }, [perPageUsers, pageUsers, filter]);

  const handleOpenStoresDialog = useCallback(() => {
    dispatch(storeActions.fetchRequest({ page: pageStores, perPage: perPageStores }));
    setOpenStoresDialog(true);
  }, [pageStores, perPageStores]);

  const deleteUser = useCallback(() => {
    setFieldValue('userId', undefined);
    setFieldValue('username', '');
  }, []);

  const deleteStore = useCallback(() => {
    // setFieldValue('companyId', undefined);
    setFieldValue('storeId', undefined);
    setFieldValue('storename', '');
  }, []);

  const formik = useFormikAddShift(createShift, editShift, shift);

  const { values, setFieldValue } = formik;

  useLayoutEffect(() => {
    if (successCreate || successEdit) navigate(-1);
  }, [successCreate, successEdit]);

  useLayoutEffect(() => {
    id && getShift(+id);
  }, [id]);

  useLayoutEffect(() => {
    dispatch(categoriesActions.fetchFullRequest());
  }, []);

  setLayoutSubheader({
    title: id ? fm('SHIFT.BUTTON.EDIT') : fm('SHIFT.BUTTON.ADD'),
  });
  setLayoutFooter({ show: true });

  if (loading) return <Preloader />;

  return (
    <>
      <Card className={styles.classes.container}>
        <FormShiftPage
          formik={formik}
          id={id || ''}
          childCategories={childCategories}
          deleteUser={deleteUser}
          deleteStore={deleteStore}
          handleOpenStoresDialog={handleOpenStoresDialog}
          handleOpenUsersDialog={handleOpenUsersDialog}
          loading={loadingCreate || loadingEdit}
        />
      </Card>

      <Modal
        DialogProps={{ maxWidth: 'md' } as DialogProps}
        open={openUsersDialog}
        onClose={() => setOpenUsersDialog(false)}
        title={intl.formatMessage({ id: 'SUBMENU.VENDORS.STAFF' })}
        loading={loadingUsers}
        content={
          <UsersTable
            loading={loadingUsers}
            paginationData={{ page: pageUsers, perPage: perPageUsers, total: totalUsers }}
            onEdit={userId => navigate(`/users/edit/${UserListType.STAFF}/${userId}`)}
            showEdit={!!me?.is_admin || !!isManager}
            onConnect={(id, login) => {
              setFieldValue('userId', id);
              setFieldValue('username', login);
              setOpenUsersDialog(false);
            }}
            onConnectOff={() => deleteUser()}
            connectedUsers={values.userId ? [values.userId] : undefined}
            users={users}
            fetch={({ page, perPage }) =>
              dispatch(
                usersActions.fetchRequest({
                  per_page: perPage,
                  page,
                  roles: 'ROLE_VENDOR_STAFF',
                  filter,
                })
              )
            }
          />
        }
        actions={[
          {
            title: intl.formatMessage({ id: 'COMMON.BUTTON.CLOSE' }),
            onClick: () => setOpenUsersDialog(false),
          },
        ]}
      />

      <Modal
        DialogProps={{ maxWidth: 'md' } as DialogProps}
        open={openStoresDialog}
        onClose={() => setOpenStoresDialog(false)}
        title={intl.formatMessage({ id: 'MENU.CATALOG.STORES' })}
        loading={loadingStores}
        content={
          <StoriesTable
            stores={stores}
            loading={loadingStores}
            paginationData={{ page: pageStores, perPage: perPageStores, total: totalStores }}
            onEdit={storeId => navigate(`/stores/edit/${storeId}`)}
            connectedStores={values.storeId ? [values.storeId] : undefined}
            onConnect={(id, name) => {
              //   company && setFieldValue('companyId', company.id);
              setFieldValue('storeId', id);
              setFieldValue('storename', name);
              setOpenStoresDialog(false);
            }}
            onConnectOff={() => deleteStore()}
            fetch={({ page, perPage }) =>
              dispatch(storeActions.fetchRequest({ page, perPage }))
            }
          />
        }
        actions={[
          {
            title: intl.formatMessage({ id: 'COMMON.BUTTON.CLOSE' }),
            onClick: () => setOpenStoresDialog(false),
          },
        ]}
      />
    </>
  );
};

export default ShiftPage;
