import React from 'react';
import { Route, Routes } from 'react-router-dom';

import CompaniesPage from './CompaniesPage';
import CompanyEditPage from './CompanyEditPage';

export default function Companies() {
  return (
    <Routes>
      <Route path='new' element={<CompanyEditPage />} />
      <Route path='edit/:companyId' element={<CompanyEditPage />} />
      <Route path='list' element={<CompaniesPage />} />
    </Routes>
  );
}
