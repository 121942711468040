import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import {getProducts} from '../../../../crud/product.crud';
import { actions as categoriesActions } from '../../../../store/ducks/categories.duck';

import {IProduct} from "../../../../interfaces/product";
import {IServerResponse} from "../../../../interfaces/server";
import {ICategoryItem} from "../../categories/interfaces";

export const useGetProductsByCategory: () => [(ids: (ICategoryItem | undefined)[]) => Promise<void>, boolean, boolean] = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();

  const fetchProduct = async (category: ICategoryItem | undefined) => {
    return new Promise(async (resolve) => {
      if (category) {
         await getProducts({
          page: 1,
          perPage: 3,
          categoryId: category.id,
          sortType: 'rating'
        }).then((value: { data: IServerResponse<IProduct[]>}) => {
            resolve({category, products: value.data.data})
        })
      }
    })
  }

  const fetchProductsByCategory = useCallback(
    async (categories: (ICategoryItem | undefined)[]) => {
      setLoading(true);
      await Promise.all(categories.map((category) => fetchProduct(category))).then((value: any) => {
        const result = value.filter((item: any) => item.products.length > 0 && item.category.favorite);
        dispatch(categoriesActions.setProductsCategory(result))
      })
        .finally(() => {
          setLoading(false);
          setSuccess(false);
        });
    },
    [dispatch]
  );

  return [fetchProductsByCategory, loading, success];
};
