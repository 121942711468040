import React, { useCallback, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Typography } from '@mui/material';
import clsx from 'clsx';
import { useIntl } from 'react-intl';

import { useStyles } from './hooks/useStyles';
import { toAbsoluteUrl } from '../../../../_base';
import { TUrl } from '../../ui/UploadImages';
import { CroppedImage } from '../CroppedImage';
import { Modal } from '../../other/Modals';

interface IProps {
  uploadFiles: (files: File[]) => void;
  avaliableNumberOfFilesToUpload?: number;
  title?: string;
  description?: string;
  withCrop?: boolean;
  cropAspect?: number;
  stylesDropZone?: React.CSSProperties;
  hideDescription?: boolean;
}

const FilesDropzone: React.FC<IProps> = ({
  uploadFiles,
  avaliableNumberOfFilesToUpload = 1,
  title,
  description,
  withCrop = false,
  cropAspect,
  stylesDropZone,
  hideDescription,
}) => {
  const intl = useIntl();

  title = title || intl.formatMessage({ id: 'COMMON.PHOTO.LOAD' });
  description = description || intl.formatMessage({ id: 'COMMON.PHOTO.DESC' });

  const classes1 = useStyles();
  const {classes} = classes1

  const [imageBeforeCrop, setImageBeforeCrop] = useState<TUrl | null>(null);

  const handleDrop = useCallback(
    async (droppedFiles: File[]) => {
      uploadFiles(droppedFiles.slice(0, avaliableNumberOfFilesToUpload));
    },
    [avaliableNumberOfFilesToUpload]
  );

  const handleDropWithCrop = useCallback(async (droppedFiles: File[]) => {
    setImageBeforeCrop(URL.createObjectURL(droppedFiles[0]));
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: withCrop ? handleDropWithCrop : handleDrop,
  });

  const isDropZoneActive = avaliableNumberOfFilesToUpload > 0;

  const dropZoneProps = useMemo(() => {
    if (!isDropZoneActive) return {};
    return getRootProps();
  }, [avaliableNumberOfFilesToUpload, getRootProps]);

  return (
    <div className={clsx(classes.root)}>
      <div
        className={clsx({
          [classes.dropZone]: true,
          [classes.dropZoneActive]: isDropZoneActive,
          [classes.dragActive]: isDragActive,
        })}
        {...dropZoneProps}
        style={stylesDropZone}
      >
        <input {...getInputProps()} />
        <div>
          <img
            alt='Select file'
            className={classes.image}
            src={toAbsoluteUrl('/images/undraw_add_file2_gvbb.svg')}
          />
        </div>
        <div className={classes.content}>
          <Typography gutterBottom variant='h3' style={{ marginBottom: 0 }}>
            {title}
          </Typography>
          {!hideDescription && (
            <Typography className={classes.info} color='textSecondary' variant='body1'>
              {description}. Max files: {avaliableNumberOfFilesToUpload}
            </Typography>
          )}
        </div>
      </div>
      {withCrop && (
        <Modal
          open={!!imageBeforeCrop}
          onClose={() => setImageBeforeCrop(null)}
          title=''
          content={
            <CroppedImage
              aspect={cropAspect}
              src={imageBeforeCrop || ''}
              onSubmit={(croppedImg: File) => {
                handleDrop([croppedImg]);
                setImageBeforeCrop(null);
              }}
            />
          }
        />
      )}
    </div>
  );
};

export default FilesDropzone;
