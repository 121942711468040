import React from 'react';
import { Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import enLocale from 'date-fns/locale/en-US';
import ruLocale from 'date-fns/locale/ru';
import csLocale from 'date-fns/locale/cs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import homeStyles from '../../../../constants/homeStyles';
import { useStylesPromocodeEdit } from '../hooks/useStyles';

export interface IProps {
  handleChangeRange: (start: string | Date, end: string | Date) => void;
  startDate: string | Date;
  endDate: string | Date;
}

const SettingsTransactions: React.FC<IProps> = ({ handleChangeRange, startDate, endDate }) => {
  const classes = useStylesPromocodeEdit();
  const homeClasses = homeStyles();
  const intl = useIntl();

  const locales: { [key: string]: any } = {
    ru: ruLocale,
    en: enLocale,
    cz: csLocale,
  };

  return (
    <div className={homeClasses.classes.form}>
      <Typography variant='h6' className={classes.title}>
        {intl.formatMessage({ id: 'SUBMENU.PROMOCODES.DATE.RANGE' })}
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locales[intl.locale]}>
        <DatePicker
          // id='received_at'
          // variant='inline'
          className={classes.input}
          format='yyyy.MM.dd'
          // margin='normal'
          value={startDate ? dayjs(startDate) : null}
          onChange={date => date && handleChangeRange(dayjs(date).toString(), endDate)}
          // autoOk
        />
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locales[intl.locale]}>
        <DatePicker
          // id='received_at'
          // variant='inline'
          className={classes.input}
          format='yyyy.MM.dd'
          // margin='normal'
          value={endDate ? dayjs(endDate) : null}
          onChange={date => date && handleChangeRange(startDate, dayjs(date).toString())}
          // autoOk
        />
      </LocalizationProvider>
    </div>
  );
};

export default React.memo(SettingsTransactions);
