import React, { useCallback, useLayoutEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import AttachFile from '@mui/icons-material/AttachFile';
import DeleteFile from '@mui/icons-material/Delete';
import ImageFile from '@mui/icons-material/Image';
import { TextField } from '@mui/material';
import { useStyle } from '../styles';
import { useCreatePost } from '../hooks/useCreatePost';
import Preloader from '../../../../../components/other/Preloader/Preloader';
import { useCreatePostFiles } from '../hooks/useCreatePostFiles';
import { isImage } from '../../../../../utils/utils';

interface IProps {
  currentChatId?: string;
}

const InputMessage: React.FC<IProps> = ({ currentChatId }) => {
  const classes = useStyle();
  const [createPost, loadingPost] = useCreatePost();
  const [createPostFiles, loadingFiles] = useCreatePostFiles();
  const [message, setMessage] = useState('');
  const [uploadFiles, setUploadFiles] = useState<File[]>([]);

  const handleSend = useCallback(() => {
    if (
      message.trim() &&
      currentChatId &&
      !loadingPost &&
      !loadingFiles &&
      uploadFiles.length < 1
    ) {
      createPost(currentChatId, message);
      setMessage('');
    }
    if (currentChatId && !loadingPost && !loadingFiles && uploadFiles.length > 0) {
      createPostFiles(currentChatId, message, uploadFiles);
      setUploadFiles([]);
      setMessage('');
    }
  }, [message, currentChatId, loadingPost, uploadFiles, loadingFiles]);

  const handleKeyDown = useCallback(
    (e: any) => {
      if (e.keyCode === 13 && !e.ctrlKey && !e.shiftKey) {
        handleSend();
      }
      if (e.keyCode === 13 && (e.ctrlKey || e.shiftKey)) {
        setMessage(`${message}\n`);
      }
    },
    [handleSend, message]
  );

  const { getInputProps, open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
  });

  useLayoutEffect(() => {
    setUploadFiles([...uploadFiles, ...acceptedFiles]);
  }, [acceptedFiles]);

  useLayoutEffect(() => {
    setUploadFiles([]);
  }, [currentChatId]);

  const deleteFile = useCallback(
    (deleteIndex: number) => {
      const newFilesArray = uploadFiles.filter((item, index) => index !== deleteIndex);
      setUploadFiles(newFilesArray);
    },
    [uploadFiles]
  );

  return (
    <div className={classes.bottomContainer}>
      <div className={classes.wrapperInput}>
        <TextField
          style={{ margin: 0 }}
          type='text'
          label='Введите сообщение'
          margin='normal'
          value={message}
          variant='outlined'
          onChange={e => setMessage(e.target.value)}
          multiline
          rows={4}
          onKeyPress={e => {
            if (e.key === 'Enter') e.preventDefault();
          }}
          onKeyDown={handleKeyDown}
          inputProps={{
            className: classes.inputProps,
          }}
          InputLabelProps={{
            className: classes.inputLabelProps,
          }}
        />
      </div>
      <div className={classes.wrapperBtn}>
        <div className={classes.addFileBtn} onClick={open}>
          <input {...getInputProps()} />
          <AttachFile color='primary' />
        </div>
        <div className={classes.btnSend} onClick={handleSend}>
          {loadingFiles || loadingPost ? (
            <Preloader />
          ) : (
            <b className={classes.btnSendText}>Отправить</b>
          )}
        </div>
      </div>
      <div className={classes.wrapperFiles}>
        {uploadFiles.map((item, index) => (
          <div className={classes.wrapperFile}>
            {!isImage(item.name) ? (
              <AttachFile color='primary' />
            ) : (
              <ImageFile color='primary' />
            )}
            <b className={classes.fileName}>{item.name}</b>
            <div className={classes.deleteFileBtn} onClick={() => deleteFile(index)}>
              <DeleteFile color='error' />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default React.memo(InputMessage);
