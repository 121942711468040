import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { editTender } from '../../../../crud/orders.crud';
import { getResponseMessage } from '../../../../utils/utils';

export const useGenerateReport: any = () => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [loadingReport, setLoading] = useState(false);
  const [successGenerate, setSuccess] = useState(false);
  const generateReport = useCallback(async (id: number, tender: any) => {
    setLoading(true);
    editTender(id, tender)
      .then(() => {
        setSuccess(true);
        enqueueSnackbar(`${intl.formatMessage({ id: 'GENERATE.REPORT.SUCCESS' })}`, {
          variant: 'success',
        });
      })
      .catch(e => {
        enqueueSnackbar(`${intl.formatMessage({ id: 'ERROR' })}: ${getResponseMessage(e)}`, {
          variant: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
        setSuccess(false);
      });
  }, []);
  return { generateReport, loadingReport, successGenerate };
};
