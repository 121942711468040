import { Reducer } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { string } from 'yup';

const actionTypes = {
  SetLanguage: 'i18n/SET_LANGUAGE',
};

const initialState = {
  lang: 'ru',
};

export const reducer: Reducer = persistReducer(
  { storage, key: 'i18n' },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.SetLanguage:
        return { ...state, lang: action.payload.lang };

      default:
        return state;
    }
  }
);

export const actions = {
  setLanguage: (lang: string) => ({ type: actionTypes.SetLanguage, payload: { lang } }),
};
