import React, { useEffect, useLayoutEffect, useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import {
  Card,
  CardContent,
  Divider,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';
import { thousands } from '../../../utils/utils';
import useCrudSnackbar from '../../../hooks/useCrudSnackbar';
import { useDefineUserRole, useFormatMessage } from '../../../hooks';
import { IAppState } from '../../../store/rootDuck';
import { actions as supplyActions } from '../../../store/ducks/supply.duck';
import { SupplyItems } from './components';
import ButtonWithLoader from '../../../components/ui/Buttons/ButtonWithLoader';
import Preloader from '../../../components/ui/Preloader/Preloader';
import { useStylesSupply } from './hooks/useStyles';

const getInitialValues = () => ({
  comment: '',
});

const CategoriesListPage: React.FC<TPropsFromRedux> = ({
  supplyData,
  setProductCount,
  setProductCountLoading,
  checkoutLoading,
  checkoutSuccess,
  checkoutError,
  clearCheckout,
  checkout,
  clearStores,
  fetchStores,
  fetchAccord,
  accord,
  storesLoading,
  storesError,
  addProductSuccess,
  me,
  clearAddProduct,
}) => {
  const intl = useIntl();
  const classes = useStylesSupply();
  const theme = useTheme();
  const navigate = useNavigate();
  const fm = useFormatMessage();
  const isRoleVendor = useDefineUserRole(me, ['ROLE_VENDOR', 'ROLE_VENDOR_STAFF']);

  const { values, errors, touched, handleChange, handleBlur } = useFormik({
    // @ts-ignore
    initialValues: getInitialValues(),
    validationSchema: () =>
      Yup.lazy(() => {
        return Yup.object().shape({
          phone: Yup.string()
            .test('len', intl.formatMessage({ id: 'AUTH.INPUT.PHONE_REQUIRED' }), val => {
              const valLengthWithoutDashes = val
                ?.replace(`+`, '')
                .replace(/-|_/g, '')
                .replace(/ /g, '')
                .replace(/[{()}]/g, '').length;

              return valLengthWithoutDashes === 11;
            })
            .required(intl.formatMessage({ id: 'AUTH.INPUT.PHONE_REQUIRED' })),
          driverName: Yup.string().required(
            intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' })
          ),
        });
      }),
    onSubmit: () => {
      // let total = 0;
      // if (supplyData) {
      //   total = +supplyData?.reduce((count, el) => {
      //     // eslint-disable-next-line no-return-assign
      //     return (count += el?.product?.price * el.amount || 0);
      //   }, 0);
      // }
      // let totalWeight: number | undefined = 0;
      // if (supplyData) {
      //   totalWeight = +supplyData?.reduce((count, el) => {
      //     // eslint-disable-next-line no-return-assign
      //     return (count += (el?.product?.stock_info?.weight || 0) * el.amount || 0);
      //   }, 0);
      // }
    },
  });

  setLayoutSubheader({
    title: intl.formatMessage({ id: 'SUPPLY.HEADER.LIST' }),
  });

  setLayoutFooter({ show: true });

  useCrudSnackbar({
    success: checkoutSuccess,
    error: checkoutError,
    clear: clearCheckout,
    successMessage: intl.formatMessage({ id: 'SUPPLY.CHECKOUT.SUCCESS' }),
    errorMessage: `${intl.formatMessage({ id: 'ERROR' })}: ${checkoutError}`,
    afterSuccessOrError: () => {
      fetchAccord();
      navigate('delivery/list/full');
    },
  });

  useCrudSnackbar({
    success: false,
    error: storesError,
    clear: clearStores,
    successMessage: '',
    errorMessage: `${intl.formatMessage({ id: 'ERROR' })}: ${storesError}`,
  });

  useLayoutEffect(() => {
    isRoleVendor && addProductSuccess && fetchAccord();
  }, [addProductSuccess]);

  useEffect(() => {
    fetchAccord();
    return () => {
      clearAddProduct();
    };
  }, []);

  useEffect(() => {
    isRoleVendor && fetchStores();
  }, [isRoleVendor]);

  const cartTotal = useMemo(() => {
    let total = 0;

    if (supplyData) {
      total = +supplyData?.reduce((count, el) => {
        // eslint-disable-next-line no-return-assign
        return (count += el?.product?.price * el.amount || 0);
      }, 0);
    }

    return thousands(String(total));
  }, [supplyData]);

  const weightTotal = useMemo(() => {
    let totalWeight: number | undefined = 0;

    if (supplyData) {
      totalWeight = +supplyData?.reduce((count, el) => {
        // eslint-disable-next-line no-return-assign
        return (count += (el?.product?.stock_info?.weight || 0) * el.amount || 0);
      }, 0);
    }
    return thousands(String(totalWeight));
  }, [supplyData]);

  let cartEmpty = true;
  // @ts-ignore
  if (supplyData?.length >= 1) {
    cartEmpty = false;
  }

  if (storesLoading) return <Preloader />;
  return (
    <>
      <Row>
        <Col>
          {!cartEmpty && supplyData ? (
            <div className={classes.container}>
              <SupplyItems
                supply={supplyData}
                setProductCount={setProductCount}
                setProductCountLoading={setProductCountLoading}
              />
              <Card className={classes.totalCard}>
                <CardContent>
                  <Divider
                    style={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(3) }}
                  />
                  <>
                    <Typography variant='h6'>
                      {intl.formatMessage({ id: 'DELIVERY.COMMENT' })}
                    </Typography>
                    <TextField
                      type='text'
                      margin='normal'
                      name='comment'
                      value={values.comment}
                      helperText={touched.comment && errors.comment}
                      error={Boolean(touched.comment && errors.comment)}
                      variant='outlined'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      rows={3}
                      multiline
                    />
                  </>
                  <Divider
                    style={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(3) }}
                  />
                  <div>
                    <div className={classes.wrapperText}>
                      <Typography component='h6' variant='h6'>
                        {fm('CART.TOTAL')}
                      </Typography>

                      <Typography component='h6' variant='h6'>
                        <b>{cartTotal} ₽</b>
                      </Typography>
                    </div>
                    <div className={classes.wrapperText}>
                      <Typography component='h6' variant='h6'>
                        {fm('CART.TOTAL.WEIGHT')}
                      </Typography>
                      <Typography component='h6' variant='h6'>
                        <b>{weightTotal} гр.</b>
                      </Typography>
                    </div>
                  </div>
                  <ButtonWithLoader
                    disabled={checkoutLoading}
                    loading={checkoutLoading}
                    onPress={() => {
                      accord &&
                        checkout({ id: accord.id, status: 'new', comment: values.comment });
                    }}
                  >
                    <FormattedMessage id='SUPPLY.CHECKOUT.BUTTON' />
                  </ButtonWithLoader>
                </CardContent>
              </Card>
            </div>
          ) : (
            <Typography className={classes.empty} component='h5' variant='h5'>
              {intl.formatMessage({ id: 'COMMON.LIST.EMPTY' })}
            </Typography>
          )}
        </Col>
      </Row>
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    supplyData: state.supply.accord?.document_products,
    setProductCountLoading: state.supply.addProductLoading,
    checkoutLoading: state.supply.checkoutLoading,
    checkoutSuccess: state.supply.checkoutSuccess,
    checkoutError: state.supply.checkoutError,
    storesLoading: state.supply.storesLoading,
    storesError: state.supply.storesError,
    companyId: state.supply.companyId,
    me: state.profile.me,
    storesSuccess: state.supply.storesSuccess,
    accord: state.supply.accord,
    addProductSuccess: state.supply.addProductSuccess,
  }),
  {
    setProductCount: supplyActions.addProductRequest,
    clearCheckout: supplyActions.clearCheckout,
    clearStores: supplyActions.clearStores,
    fetchStores: supplyActions.fetchRequest,
    checkout: supplyActions.checkoutRequest,
    fetchAccord: supplyActions.fetchAccord,
    clearAddProduct: supplyActions.clearAddProduct,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CategoriesListPage);
