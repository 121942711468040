import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { createReview } from '../../../../crud/product.crud';
import { getResponseMessage } from '../../../../utils/utils';
import { useFormatMessage } from '../../../../hooks';
import { IReviewCreate } from '../../../../interfaces/product';

export const getInitValues = () => ({
  rating: 0,
  text: '',
});

export const useCreateReview: any = (productId: number, onSuccess?: () => void) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState<any>(null);
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();
  const fm = useFormatMessage();

  const fetchReview = useCallback(
    async (body: IReviewCreate) => {
      setLoading(true);
      createReview(productId, body)
        .then(() => {
          setSuccess(true);
          onSuccess?.()
        })
        .catch(e => {
          enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`, { variant: 'error' });
        })
        .finally(() => {
          setLoading(false);
          setErr(null);
          setSuccess(false);
          formik.resetForm();
        });
    },
    [dispatch, productId]
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitValues(),
    validationSchema: Yup.object().shape({
      rating: Yup.number()
        .min(1, fm('RATING.RATING.REQUIRED'))
        .max(5, fm('RATING.RATING.REQUIRED')),
      text: Yup.string().required(fm('RATING.TEXT.REQUIRED')).trim(),
    }),
    onSubmit: async (values: any) => fetchReview(values),
  });

  return [formik, loading, success, err];
};
