import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { IconButton, Typography } from '@mui/material';
import GridIcon from '@mui/icons-material/GridOn';
import ListIcon from '@mui/icons-material/List';
import clsx from 'clsx';
import AlertDialog from '../../../components/ui/Dialog/AlertDialog';
import Preloader from '../../../components/ui/Preloader/Preloader';

import { IAppState } from '../../../store/rootDuck';
import { actions as productCatalogActions } from '../../../store/ducks/product-catalog.duck';
import { actions as profileActions } from '../../../store/ducks/profile.duck';
import { actions as cartActions } from '../../../store/ducks/cart.duck';
import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';
import useCrudSnackbar from '../../../hooks/useCrudSnackbar';
import { IProduct } from '../../../interfaces/product';
import ProductsTableListComponent from '../products/components/ProductsTableList';
import { ProductsGrid } from '../products/components';
import { useStylesProductCatalog } from '../products/hooks/useStyles';
import { TCollection } from './types';
import { useAxiosGetCallback } from '../../../hooks/useAxiosGet';

const ProductsCollection: React.FC = () => {
  const classes1 = useStylesProductCatalog();
  const {classes} = classes1
  const intl = useIntl();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  const { viewType } = useSelector(
    ({ productsCatalog: { viewType } }: IAppState) => ({ viewType }),
    shallowEqual
  );

  const { me, loadingMe } = useSelector(
    ({ profile: { me, loading } }: IAppState) => ({ me, loadingMe: loading }),
    shallowEqual
  );

  const { isAdmin, isAuthorized } = useSelector(
    ({ auth: { user } }: IAppState) => ({
      isAuthorized: user != null,
      isAdmin: user?.is_admin,
    }),
    shallowEqual
  );

  const {
    addProductError,
    addProductLoading,
    addProductSuccess,
    cartCompanyId,
    companyGuestCart,
    countProductsGuest,
  } = useSelector(
    ({
      cart: { addProductError, addProductLoading, addProductSuccess, companyId, guestCart },
    }: IAppState) => ({
      addProductLoading,
      addProductSuccess,
      addProductError,
      cartCompanyId: companyId,
      companyGuestCart: guestCart?.cartCompanyId,
      countProductsGuest: guestCart?.items.length,
    }),
    shallowEqual
  );

  const {
    data: collection,
    loading: collectionsLoading,
    makeRequest: fetchCollection,
  } = useAxiosGetCallback<TCollection>();

  useLayoutEffect(() => {
    if (id) fetchCollection(`/api/shop/collection/${id}`);
  }, [id]);

  const [cartProductId, setCartProductId] = useState<IProduct | null>(null);
  const [isCartAlertOpen, setCartAlertOpen] = useState(false);

  useEffect(() => {
    dispatch(profileActions.fetchRequest());
    return () => {
      dispatch(profileActions.clearMe());
    };
  }, []);

  const viewAction = useCallback(
    (item: any) => {
      navigate(`/viewproduct/${item.id}`);
    },
    [navigate]
  );

  const handleCartDialog = useCallback(
    (item: any) => {
      if (isAuthorized) {
        const newCart = cartCompanyId ? item?.company?.id !== cartCompanyId : false;
        if (!newCart) {
          dispatch(
            cartActions.addProductRequest({
              product_id: item.id ? item?.id : 0,
              count: 1,
            })
          );
        } else {
          setCartProductId(item);
          setCartAlertOpen(true);
        }
      } else {
        const newCart = companyGuestCart ? item?.company?.id !== companyGuestCart : false;
        if (!newCart) {
          dispatch(cartActions.setProductGuestCart({ data: item, type: 'cart' }));
        } else if (countProductsGuest === 0) {
          dispatch(cartActions.setProductGuestCart({ data: item, type: 'new' }));
        } else {
          setCartProductId(item);
          setCartAlertOpen(true);
        }
      }
    },
    [cartCompanyId, companyGuestCart]
  );

  const addCartProductAction = useCallback(() => {
    if (!addProductLoading && cartProductId) {
      dispatch(
        cartActions.addProductRequest({
          product_id: cartProductId.id!,
          count: 1,
          newCart: true,
        })
      );
    }
  }, [cartProductId]);

  const addProductGuestCart = useCallback(() => {
    if (cartProductId) {
      dispatch(cartActions.setProductGuestCart({ data: cartProductId, type: 'new' }));
      setCartAlertOpen(false);
    }
  }, [cartProductId]);

  useCrudSnackbar({
    success: addProductSuccess,
    error: addProductError,
    successMessage: intl.formatMessage({ id: 'CART.ADD_PRODUCT.SUCCESS' }),
    errorMessage: `${intl.formatMessage({ id: 'ERROR' })}: ${addProductError}`,
    afterSuccessOrError: () => {
      setCartAlertOpen(false);
      setCartProductId(null);
    },
  });

  setLayoutSubheader({
    title: intl.formatMessage({ id: 'PRODUCT.CATALOG.COLLECTION.TITLE' }),
    breadcrumb: [
      {
        title: intl.formatMessage({ id: 'COLLECTIONS' }),
        root: true,
        page: 'collections/list',
        translate: 'COLLECTIONS',
      },
    ],
    back: true,
  });
  setLayoutFooter({ show: true });

  if (loadingMe || collectionsLoading) return <Preloader />;
  return (
    <>
      <AlertDialog
        open={isCartAlertOpen}
        message={intl.formatMessage({ id: 'CART.ALERT.TEXT' })}
        okText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })}
        handleClose={() => {
          setCartAlertOpen(false);
        }}
        handleAgree={() => (isAuthorized ? addCartProductAction() : addProductGuestCart())}
      />

      <div className={classes.container}>
        <div style={{ width: '100%' }}>
          {collectionsLoading ? (
            <Preloader />
          ) : (
            <>
              {!collection || !collection.products.length ? (
                <Typography className={classes.empty} component='h5' variant='h5'>
                  {intl.formatMessage({ id: 'PRODUCT.CATALOG.LIST.EMPTY' })}
                </Typography>
              ) : (
                <div className={clsx(classes.card)}>
                  <div>
                    <div className={classes.productColHeader}>
                      <div className={classes.productColHeaderItem}>
                        <IconButton
                          onClick={() => dispatch(productCatalogActions.setViewType('grid'))}
                          color={viewType === 'grid' ? 'primary' : 'inherit'}
                        >
                          <GridIcon />
                        </IconButton>

                        <IconButton
                          onClick={() => dispatch(productCatalogActions.setViewType('table'))}
                          color={viewType === 'table' ? 'primary' : 'inherit'}
                        >
                          <ListIcon />
                        </IconButton>
                      </div>
                    </div>
                    {viewType === 'table' ? (
                      <ProductsTableListComponent
                        companyId=''
                        data={collection.products}
                        categoryId=''
                        isAuthorized={isAuthorized}
                        isAdmin={!!isAdmin}
                        me={me}
                        addProductLoading={addProductLoading}
                        handleCartDialog={handleCartDialog}
                        viewAction={viewAction}
                      />
                    ) : (
                      <ProductsGrid
                        addProductLoading={addProductLoading}
                        isAuthorized={isAuthorized}
                        isAdmin={!!isAdmin}
                        me={me}
                        handleCartDialog={handleCartDialog}
                        viewAction={viewAction}
                        data={collection.products}
                        categoryId=''
                        companyId=''
                      />
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ProductsCollection;
