import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Button,
  Card,
  CardContent,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import moment from 'moment';

import { actions as statisticsActions } from '../../../../store/ducks/statistics.duck';
import TablePaginator from '../../../../components/tableComponents/TablePaginator/TablePaginator';
import { SHeadTableCell } from '../../../../components/styledComponents/Tables';
import AlertDialog from '../../../../components/other/Dialog/AlertDialog';
import Preloader from '../../../../components/other/Preloader/Preloader';
import { IAppState } from '../../../../store/rootDuck';
import { actions as orderActions } from '../../../../store/ducks/orders.duck';
import homeStyles from '../../../../constants/homeStyles';
import { orderTypeList } from '../constatns';
import { UserRoles } from '../../../../interfaces/user';
import { actions as profileActions } from '../../../../store/ducks/profile.duck';
import { useStyles } from '../hooks/useStyles';
import { setLayoutSubheader } from '../../../../utils/layout';
import FiltersStatisticsDetailed from './FiltersStatisticsDetailed';
import { ICompany } from '../../../../interfaces/companies';
import TableRowDetailedList from './TableRowDetailedList';
import { useDownloadStat } from '../hooks/useDownloadStat';

const StatisticsDetailedOrdersList: React.FC<TPropsFromRedux> = ({
  orders,
  loading,
  fetch,
  page,
  perPage,
  total,
  delOrder,
  delError,
  clearErrors,
  userRole,
  meLoading,
  fetchMe,
  clearMe,
  me,
  fetchStat,
  statistics,
}) => {
  const homeClasses = homeStyles();
  const classes = useStyles();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteId, setDeleteId] = useState<number | undefined>(-1);
  const [isAlertOpen, setAlertOpen] = useState(false);

  const [selectedType, setSelectedType] = useState<string>('');
  const [selectedCompany, setSelectedCompany] = useState<ICompany | null>(null);
  const [startDate, setStartDate] = React.useState<string>(
    moment(new Date()).format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = React.useState<string>(
    moment(new Date()).format('YYYY-MM-DD')
  );
  const [hideDeliveryCost, setHideDeliveryCost] = useState(false);

  const isBoxberryCompany = useMemo(
    () => !!(selectedCompany && selectedCompany.id.toString() === '-2'),
    [selectedCompany]
  );

  const companyId = useMemo(
    () =>
      selectedCompany && selectedCompany.id.toString() !== '-1'
        ? selectedCompany.id.toString()
        : undefined,
    [selectedCompany]
  );

  const downloadStat = useDownloadStat({
    statuses: orderTypeList.toString(),
    accord_order_state: selectedType,
    page,
    per_page: perPage,
    company_id: companyId,
    date_from: startDate,
    date_to: endDate,
    hide_delivery_cost: hideDeliveryCost,
  });

  const handleChangeRange = useCallback((start: string | Date, end: string | Date) => {
    const first = moment(start).format('YYYY-MM-DD');
    const second = moment(end).format('YYYY-MM-DD');
    setStartDate(first);
    setEndDate(second);
  }, []);

  setLayoutSubheader({
    title: intl.formatMessage({ id: 'SUBMENU.DETAIL.STATISTIC.ORDERS' }),
  });

  const deleteAction = useCallback(() => {
    setAlertOpen(false);
    if (deleteId) {
      delOrder({
        page,
        perPage,
        id: deleteId,
        statuses: orderTypeList.toString(),
        accord_order_state: selectedType,
        company_id:
          selectedCompany && selectedCompany.id.toString() !== '-1'
            ? selectedCompany.id.toString()
            : undefined,
        date_from: startDate,
        date_to: endDate,
        hide_delivery_cost: hideDeliveryCost,
      });
    }
  }, [
    deleteId,
    page,
    perPage,
    delOrder,
    selectedCompany,
    selectedType,
    startDate,
    endDate,
    hideDeliveryCost,
  ]);

  useEffect(() => {
    if (delError) {
      enqueueSnackbar(delError, { variant: 'error' });
      setAlertOpen(false);
      clearErrors();
    }
    return () => {
      clearErrors();
    };
  }, [delError, clearErrors, setAlertOpen, enqueueSnackbar]);

  useEffect(() => {
    fetch({
      statuses: orderTypeList.toString(),
      accord_order_state: selectedType,
      page,
      perPage,
      company_id: companyId,
      date_from: startDate,
      date_to: endDate,
      hide_delivery_cost: hideDeliveryCost,
    });
    fetchStat({
      date_from: startDate,
      date_to: endDate,
      company_id: companyId,
      accord_status: selectedType,
    });
  }, [fetch, selectedType, companyId, startDate, endDate, hideDeliveryCost]);

  useEffect(() => {
    if (!me) fetchMe();
    return () => {
      clearMe();
    };
  }, []);

  const isAdmin = useMemo(() => userRole?.[0] === UserRoles.ROLE_ADMIN, [userRole]);

  if (loading || meLoading) return <Preloader />;
  return (
    <>
      <AlertDialog
        open={isAlertOpen}
        message={intl.formatMessage({ id: 'ORDER.DELETE.TEXT' })}
        okText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })}
        handleClose={() => {
          clearErrors();
          setAlertOpen(false);
        }}
        handleAgree={() => deleteAction()}
      />

      {me &&
        !me?.roles.includes(UserRoles.ROLE_BUYER) &&
        !me.roles.includes(UserRoles.ROLE_BUYER_STAFF) && (
          <Button color='primary' variant='contained' onClick={() => downloadStat()}>
            {intl.formatMessage({ id: 'ORDERS.STAT.DOWNLOAD' })}
          </Button>
        )}

      <div className={classes.container}>
        <FiltersStatisticsDetailed
          setSelectedType={setSelectedType}
          selectedType={selectedType}
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
          startDate={startDate}
          endDate={endDate}
          hideDeliveryCost={hideDeliveryCost}
          setHideDeliveryCost={setHideDeliveryCost}
          handleChangeRange={handleChangeRange}
          statistics={statistics}
        />

        <div className={classes.ordersCol}>
          {loading ? (
            <Preloader />
          ) : (
            <>
              {!orders || !orders.length ? (
                <Typography className={classes.empty} component='h5' variant='h5'>
                  {intl.formatMessage({ id: 'ORDER.CATALOG.LIST.EMPTY' })}
                </Typography>
              ) : (
                <div className={classes.card}>
                  <Card className={classes.card}>
                    <CardContent className={homeClasses.classes.tableContainer}>
                      <Table aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <SHeadTableCell />
                            <SHeadTableCell>
                              {intl.formatMessage({ id: 'ORDER.TABLE.ID' })}
                            </SHeadTableCell>

                            <SHeadTableCell>
                              {intl.formatMessage({ id: 'ORDER.TABLE.DATE' })}
                            </SHeadTableCell>

                            {!isBoxberryCompany && (
                              <SHeadTableCell>
                                {intl.formatMessage({ id: 'ORDERS.COMPOSITION' })}
                              </SHeadTableCell>
                            )}

                            {!isBoxberryCompany && (
                              <SHeadTableCell>
                                {intl.formatMessage({ id: 'ORDER.ACCORD.STATUS' })}
                              </SHeadTableCell>
                            )}
                            {isAdmin && !hideDeliveryCost && (
                              <SHeadTableCell>
                                {intl.formatMessage({ id: 'STATISTIC.COST.SUM' })}
                              </SHeadTableCell>
                            )}
                            {!isBoxberryCompany && (
                              <SHeadTableCell>
                                {intl.formatMessage({ id: 'ORDERS.TOTAL.SUM' })}
                              </SHeadTableCell>
                            )}
                            {!isBoxberryCompany && (
                              <SHeadTableCell>
                                {intl.formatMessage({ id: 'SETTING.PROFILE.BONUSES' })}
                              </SHeadTableCell>
                            )}
                            {!isBoxberryCompany && (
                              <SHeadTableCell>
                                {intl.formatMessage({ id: 'ORDERS.COST' })}
                              </SHeadTableCell>
                            )}

                            <SHeadTableCell>
                              {intl.formatMessage({ id: 'ORDER.TABLE.ACTIONS' })}
                            </SHeadTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {orders.map(item => (
                            <TableRowDetailedList
                              key={item.id.toString()}
                              me={me}
                              item={item}
                              setAlertOpen={setAlertOpen}
                              setDeleteId={setDeleteId}
                              userRole={userRole}
                              hideDeliveryCost={hideDeliveryCost}
                              isBoxberryCompany={isBoxberryCompany}
                            />
                          ))}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePaginator
                              statuses={orderTypeList.toString()}
                              page={page}
                              realPerPage={orders.length}
                              perPage={perPage}
                              total={total}
                              fetchRows={(data: any) => {
                                fetch({
                                  page: data.page,
                                  perPage: data.perPage,
                                  statuses: data.statuses,
                                  accord_order_state: selectedType,
                                  company_id: companyId,
                                  date_from: startDate,
                                  date_to: endDate,
                                  hide_delivery_cost: hideDeliveryCost,
                                });
                                fetchStat({
                                  date_from: startDate,
                                  date_to: endDate,
                                  company_id: companyId,
                                  accord_status: selectedType,
                                });
                              }}
                              label={intl.formatMessage({ id: 'ORDER.TABLE.PERPAGE' })}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </CardContent>
                  </Card>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

const connector = connect(
  (state: IAppState) => {
    return {
      meLoading: state.profile.loading,
      orders: state.orders.orders,
      loading: state.orders.loading,
      page: state.orders.page,
      perPage: state.orders.per_page,
      total: state.orders.total,
      delError: state.orders.delError,
      userRole: state.auth.user?.roles,
      me: state.profile.me,
      statistics: state.stat.statisticOrders,
      loadingStat: state.stat.loading,
    };
  },
  {
    fetch: orderActions.fetchRequest,
    delOrder: orderActions.delRequest,
    clearErrors: orderActions.clearEdit,

    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,
    fetchStat: statisticsActions.fetchOrdersRequest,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(StatisticsDetailedOrdersList);
