import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useMemo } from 'react';
import * as Yup from 'yup';
import { useFormatMessage } from '../../../../hooks';
import { actions as categoriesActions } from '../../../../store/ducks/categories.duck';
import { ICategoryItem } from '../interfaces';

const findCategory = (item: ICategoryItem, id: string, categories: any[]): any => {
  if (!item.children) return categories;
  const category = item.children?.find(i => i.id?.toString() === id);
  if (category) {
    return [...categories, category];
  }
  for (const i of item.children) {
    if (i.children) {
      const newCategories = findCategory(i, id, categories);
      if (newCategories && newCategories.length > 0)
        return [...categories, i, ...newCategories];
    }
  }
  return categories;
};

const getInitValues = (
  id: string,
  parentId: string,
  category?: ICategoryItem,
  categories?: ICategoryItem[]
) => {
  const initValues: { [key: string]: any } = {
    name: +id && category ? category.name : '',
    active: +id && category ? category.active : false,
    favorite: +id && category ? category.favorite : false,
    description: +id && category ? category.description : '',
    parent: +parentId || -1,
    mainCategoryId: -1,
    categoryId_1: '',
    names: category?.names || {},
    types: category?.types ? category?.types.map(i => i.type) : [],
  };

  if (categories) {
    const id = +parentId || -1;
    let tree: ICategoryItem[] = categories;
    let newCategoriesStatus = false;
    if (id !== -1) {
      categories.forEach(item => {
        const newCategories = findCategory(item, id.toString(), []);
        if (newCategories && newCategories.length > 0) {
          newCategoriesStatus = true;
          tree = [item, ...newCategories];
        }
      });
      tree.forEach((item, index) => {
        if (parentId && parentId.toString() === item.id?.toString() && !item.parent_id) {
          initValues.mainCategoryId = item.id;
        } else if (index === 0) {
          initValues.mainCategoryId = item.id;
        } else if (newCategoriesStatus) {
          initValues[`categoryId_${index}`] = item.id;
        }
      });
    }
  }

  return initValues;
};

export const useFormikCategoryPage = (
  id: string,
  parentId: string,
  categories?: ICategoryItem[],
  category?: ICategoryItem
) => {
  const dispatch = useDispatch();
  const fm = useFormatMessage();

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      name: Yup.string().required(fm('CATEGORY.REQUIRED.NAME')).trim().nullable(),
    });
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitValues(id, parentId, category, categories),
    validationSchema,
    onSubmit: values => {
      let parent = values.mainCategoryId;
      // eslint-disable-next-line no-plusplus
      for (let i = 1; i < 6; i++) {
        if (values[`categoryId_${i}`] && !values[`categoryId_${i + 1}`]) {
          parent = values[`categoryId_${i}`];
          break;
        }
      }
      if (+id) {
        dispatch(
          categoriesActions.editRequest({
            id: +id,
            data: {
              name: values.name.trim(),
              description: values.description,
              active: values.active,
              favorite: values.favorite,
              parent_id: parent.toString() === '-1' ? null : +parent,
              names: values.names,
              typesIds: values.types.map((item: any) => item.id).toString(),
            },
          })
        );
      } else {
        dispatch(
          categoriesActions.addRequest({
            data: {
              name: values.name.trim(),
              description: values.description,
              active: values.active,
              favorite: values.favorite,
              parent_id: parent.toString() === '-1' ? null : +parent,
              names: values.names,
              typesIds: values.types.map((item: any) => item.id).toString(),
            },
          })
        );
      }
    },
  });

  return formik;
};
