export const getRoleByValueTab = (value: number) => {
  switch (value) {
    case 0:
      return '';
    case 1:
      return 'ROLE_BUYER';
    case 2:
      return 'ROLE_VENDOR';
    case 3:
      return 'ROLE_MANAGER';
    default:
      return '';
  }
};
