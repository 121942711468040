import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { getResponseMessage } from '../../../../utils/utils';
import { useFormatMessage } from '../../../../hooks';
import { getGroups } from '../../../../crud/productType.crud';

export const useGetGroups: any = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const fm = useFormatMessage();

  const fetchGroups = useCallback(async () => {
    setLoading(true);
    getGroups()
      .then(res => {
        setGroups(res.data.data);
      })
      .catch(e => {
        enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return [fetchGroups, loading, groups];
};
