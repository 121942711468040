import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PromocodeEditPage from './PromocodeEditPage';
import PromocodesPage from './PromocodesPage';

export default function Promocodes() {
  return (
    <Routes>
      <Route path='new' element={<PromocodeEditPage />} />
      <Route path='list' element={<PromocodesPage />} />
      <Route path='edit/:promocodeId' element={<PromocodeEditPage />} />
    </Routes>
  );
}
