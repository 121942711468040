import { ICategoryItem } from '../pages/home/categories/interfaces';
import { ICompany } from './companies';
import { ILocation } from './locations';
import { ITariff } from './tariff';

export interface IUserAddProps {
  login: string | undefined;
  email: string | undefined;
  password: string;
  roles: string[];
  first_name?: string;
  last_name?: string;
  middle_name?: string;
  countryCode?: string;
  phone?: string;
  phoneNumbers?: string;
  categoriesIds?: string | null;
  categories?: null;
  companyId?: number | string;
}

export interface IUserEditProps {
  login: string | undefined;
  email: string | undefined;
  password?: string;
  fio: string | undefined;
  active?: boolean;
  locale?: string;
  attributes?: TUserAttributes;
  first_name?: string;
  last_name?: string;
  middle_name?: string;
}

export interface IUserChangeLocal {
  locale: string;
}
export enum ManagerStatus {
  moderation = 'In moderation',
  hired = 'Hired',

  // Only in front
  canceled = 'Canceled',
}

export type TUserAttributes = { type: string; value: string }[];

export interface IUser {
  id: number;
  email: string;
  api_token: string;
  fio: string;
  login: string;
  is_admin: boolean;
  active: boolean;
  company: ICompany | null;
  roles: [UserRoleType];
  locale: string;
  manager_status: ManagerStatus;
  location?: ILocation | null;
  phone: string;
  phone_numbers: string;
  avatar: {
    id: number;
    path: string;
    small: string;
  };
  loyalty_level: null | {
    orders_summ: number;
    discount_percent: number;
  };
  attributes: TUserAttributes;
  current_user_tariff: any;
  first_name: string | null;
  last_name: string | null;
  middle_name: string | null;
  country?: any;
  bonus_count?: any;
  gender: string;
  birthday: string;
  death_date: string;
  about: string;
  user_tariffs?: {
    days_left: number;
    id: number;
    publish_expert_articles: number;
    start_datetime: string | null;
    stop_datetime: string | null;
    tariff: ITariff;
  }[];
  categories?: { category: ICategoryItem }[];
}

export type TUserAttr = {
  [key: string]: string[];
};

export enum UserRoles {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_MANAGER = 'ROLE_MANAGER',
  ROLE_USER = 'ROLE_USER',
  ROLE_VENDOR = 'ROLE_VENDOR',
  ROLE_BUYER = 'ROLE_BUYER',
  ROLE_VENDOR_STAFF = 'ROLE_VENDOR_STAFF',
  ROLE_CONTENT_MANAGER = 'ROLE_CONTENT_MANAGER',
  ROLE_BUYER_STAFF = 'ROLE_BUYER_STAFF',
}

export type UserRoleType =
  | 'ROLE_ADMIN'
  | 'ROLE_MANAGER'
  | 'ROLE_USER'
  | 'ROLE_VENDOR'
  | 'ROLE_BUYER'
  | 'ROLE_VENDOR_STAFF'
  | 'ROLE_BUYER_STAFF'
  | 'ROLE_CONTENT_MANAGER';

export enum LoginType {
  email = 'email',
  phone = 'phone',
  login = 'login',
}

export type TUserCountry = {
  code: string;
  en_name: string;
  id: number;
  mask: string;
  ru_name: string;
};
