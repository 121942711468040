import React, { useEffect, useState, useLayoutEffect, useMemo, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Paper, DialogProps } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import homeStyles from '../../../constants/homeStyles';
import { useFormatMessage } from '../../../hooks';
import { actions as categoriesActions } from '../../../store/ducks/categories.duck';
import { actions as productTypesActions } from '../../../store/ducks/productType.duck';
import { actions as companiesActions } from '../../../store/ducks/companies.duck';
import { useFormikAddMultiply } from './hooks/useFormikAddMultiply';
import CompaniesTable from '../../../components/tableComponents/Table/CompaniesTable';
import { Modal } from '../../../components/other/Modals';
import { IAppState } from '../../../store/rootDuck';
import { IUser } from '../../../interfaces/user';
import { useAxiosGetCallback } from '../../../hooks/useAxiosGet';
import HeaderAddMultiply from './components/HeaderAddMultiply';
import ProductAddMultiply from './components/ProductAddMultiply';
import { ButtonWithLoader } from '../../../components/ui/Buttons';
import { ButtonTheme } from '../../../components/ui/Buttons/ButtonWithLoader';
import AlertDialog from '../../../components/ui/Dialog/AlertDialog';
import { useCreateMultiplyProducts } from './hooks/useCreateMultiplyProducts';
import { useStylesHeaderAddMultiply } from './hooks/useStyles';

const ProductsAddMultiply: React.FC = () => {
  const homeClasses = homeStyles();
  const fm = useFormatMessage();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes1 = useStylesHeaderAddMultiply();
  const {classes} = classes1
  const [isAllCompaniesDialogOpen, setIsAllCompaniesDialogOpen] = useState(false);
  const [isAlertOpen, setAlertOpen] = useState(false);

  const { data: countries, makeRequest } = useAxiosGetCallback<any>();

  const { fetchCreate } = useCreateMultiplyProducts(() => navigate('/products/catalog'));

  const { me } = useSelector(({ profile }: IAppState) => profile);

  useLayoutEffect(() => {
    dispatch(productTypesActions.clearParams());
    makeRequest('/api/phone_codes');
    dispatch(categoriesActions.fetchFullRequest());
    dispatch(productTypesActions.fetchRequest());
    return () => {
      dispatch(productTypesActions.clearParams());
    };
  }, []);

  const {
    loadingCompanies,
    pageCompanies,
    perPageCompanies,
    totalCompanies,
    companies,
  } = useSelector(
    ({ companies: { loading, page, per_page, total, companies } }: IAppState) => ({
      loadingCompanies: loading,
      pageCompanies: page,
      perPageCompanies: per_page,
      totalCompanies: total,
      companies,
    }),
    shallowEqual
  );

  const { typeParams, paramsLoading } = useSelector(
    ({ productTypes: { typeParams, paramsLoading } }: IAppState) => ({
      typeParams,
      paramsLoading,
    }),
    shallowEqual
  );

  const filteredParams = useMemo(() => {
    if (typeParams && !paramsLoading) {
      return typeParams.filter(i => i.bulk_edit || i.required);
    }
    return [];
  }, [typeParams, paramsLoading]);

  const openModalCompanies = useCallback(() => {
    dispatch(companiesActions.fetchRequest({ page: 1, perPage: 20 }));
    setIsAllCompaniesDialogOpen(true);
  }, []);

  const formik = useFormikAddMultiply(fetchCreate, me);
  const { values, handleSubmit } = formik;

  const itemsLen = useMemo(() => {
    if (values.items_len) {
      return Array.from({ length: values.items_len }, (_v, k) => k + 1);
    }
    return [];
  }, [values.items_len]);

  // fetch product type params
  useEffect(() => {
    const typeId = Number(values.productTypeId);
    if (typeId) dispatch(productTypesActions.fetchParamsByIdRequest({ id: typeId }));
  }, [values.productTypeId]);

  return (
    <>
      <Paper className={homeClasses.classes.container}>
        <div className={homeClasses.classes.form} style={{ paddingTop: 0, maxWidth: 2000 }}>
          <HeaderAddMultiply
            filteredParams={filteredParams}
            formik={formik}
            countries={countries}
            openModalCompanies={openModalCompanies}
            close={() => setAlertOpen(true)}
          />
          {itemsLen.map(i => (
            <ProductAddMultiply
              key={i.toString()}
              i={i}
              formik={formik}
              countries={countries}
            />
          ))}
          {values.items_len && (
            <ButtonWithLoader
              onPress={handleSubmit}
              theme={ButtonTheme.PRIMARY}
              margin
              style={{ marginTop: 15 }}
            >
              {`${fm('PRODUCTS.ALL.SAVE')} (${values.items_len})`}
            </ButtonWithLoader>
          )}
        </div>
      </Paper>

      <Modal
        DialogProps={{ maxWidth: 'md' } as DialogProps}
        open={isAllCompaniesDialogOpen}
        onClose={() => setIsAllCompaniesDialogOpen(false)}
        title={fm('COMPANY.EDIT.MOUNT_TITLE')}
        loading={loadingCompanies}
        content={
          <CompaniesTable
            me={me as IUser}
            page={pageCompanies}
            perPage={perPageCompanies}
            total={totalCompanies}
            onConnectUser={company => {
              formik.setFieldValue('company', company);
              setIsAllCompaniesDialogOpen(false);
            }}
            companies={companies}
            fetch={({ page, perPage }) =>
              dispatch(companiesActions.fetchRequest({ page, perPage }))
            }
          />
        }
        actions={[
          {
            title: fm('COMMON.BUTTON.CLOSE'),
            onClick: () => setIsAllCompaniesDialogOpen(false),
            className: classes.closeButton,
          },
        ]}
      />

      <AlertDialog
        open={isAlertOpen}
        message={fm('PRODUCT.ADD.MULTIPLY.ALERT')}
        okText={fm('PRODUCT.ADD.MULTIPLY.ALERT.BUTTON.1')}
        cancelText={fm('PRODUCT.ADD.MULTIPLY.ALERT.BUTTON.2')}
        handleClose={() => setAlertOpen(false)}
        handleAgree={() => navigate(-1)}
        styleActions={{ marginBottom: 12 }}
      />
    </>
  );
};

export default ProductsAddMultiply;
