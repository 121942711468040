import React, { useLayoutEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, CardContent } from '@mui/material';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import moment from 'moment';
import { useIntl } from 'react-intl';

import homeStyles from '../../homeStyles';
import { useFormatMessage } from '../../../../hooks';
import { useGetShifts } from '../hooks/useGetShifts';
import Preloader from '../../../../components/ui/Preloader/Preloader';
import { useStylesCalendar } from '../hooks/useStyles';

const Calendar: React.FC = () => {
  const intl = useIntl();
  const homeClasses = homeStyles();
  const fm = useFormatMessage();
  const classes = useStylesCalendar();
  const navigate = useNavigate();
  const { fetchShifts, loading, page, shifts } = useGetShifts();

  const events = useMemo(() => {
    return shifts.map(item => {
      const startDate = moment(item.start.split('+')[0]).format('yyyy-MM-DD HH:mm:ss');
      const endDate = moment(item.end.split('+')[0]).format('yyyy-MM-DD HH:mm:ss');
      return {
        title: `${fm('SHIFT')} ${item.id}`,
        start: startDate,
        end: endDate,
        id: item.id.toString(),
      };
    });
  }, [shifts]);

  useLayoutEffect(() => {
    fetchShifts(page, 60);
  }, []);

  if (loading) return <Preloader />;

  return (
    <>
      <Card>
        <CardContent className={homeClasses.classes.tableContainer}>
          <Button
            color='primary'
            variant='contained'
            className={classes.button}
            onClick={() => navigate('/shifts/add')}
          >
            {fm('SHIFT.BUTTON.ADD')}
          </Button>
          <FullCalendar
            plugins={[dayGridPlugin]}
            initialView='dayGridMonth'
            weekends
            locale={intl.locale === 'cz' ? 'cs' : intl.locale}
            firstDay={1}
            buttonText={{
              today: fm('TODAY'),
              month: fm('MONTH'),
              week: fm('WEEK'),
              day: fm('DAY'),
              list: fm('LIST'),
            }}
            eventClick={arg => navigate(`/shifts/edit/${arg.event.id}`)}
            events={events}
            eventColor='#369ff7'
            eventContent={renderEventContent}
          />
        </CardContent>
      </Card>
    </>
  );
};

export default Calendar;

const renderEventContent = (eventInfo: any) => {
  return (
    <>
      <b style={{ fontSize: '1em' }}>{moment(eventInfo.event.start).format('HH:mm')} </b>
      <i style={{ fontSize: '1em' }}>{eventInfo.event.title}</i>
    </>
  );
};
