import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { addMainProposal } from '../../../../crud/orders.crud';
import { getResponseMessage } from '../../../../utils/utils';

export const useAddMainProposal = () => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [loadingMain, setLoading] = useState(false);
  const [errMain, setErr] = useState<any>(null);
  const [successMain, setSuccess] = useState(false);
  const addMain = useCallback(async (id: number) => {
    setLoading(true);
    addMainProposal(id)
      .then(() => {
        setSuccess(true);
      })
      .catch(e => {
        enqueueSnackbar(`${intl.formatMessage({ id: 'ERROR' })}: ${getResponseMessage(e)}`, {
          variant: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
        setErr(null);
        setSuccess(false);
      });
  }, []);
  return { addMain, loadingMain, successMain, errMain };
};
