/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback } from 'react';
import fileSaver from 'file-saver';
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import { getResponseMessage } from '../../../../utils/utils';
import { getOrders } from '../../../../crud/orders.crud';
import { API_DOMAIN } from '../../../../constants';

export const useDownloadStat: any = (data: {
  statuses: string;
  page: number;
  per_page: number;
  userId?: number;
  accord_order_state?: string;
  payment_status?: string;
  company_id?: string;
  date_from?: string;
  date_to?: string;
  hide_delivery_cost?: boolean;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  return useCallback(() => {
    const {
      statuses,
      page,
      userId,
      accord_order_state,
      payment_status,
      company_id,
      date_from,
      date_to,
      hide_delivery_cost,
    } = data;
    getOrders(
      statuses,
      page,
      99999999,
      userId,
      accord_order_state,
      payment_status,
      company_id,
      date_from,
      date_to,
      hide_delivery_cost,
      true
    )
      .then(res => {
        // fileSaver(`${API_DOMAIN}${res.data.data.link}`, 'stat.csv');
        // const blob = new Blob([res.data], {
        //   type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        // });
        fileSaver.saveAs(`${API_DOMAIN}${res.data.data.link}`, `stat.csv`);
      })
      .catch(e => {
        enqueueSnackbar(`${intl.formatMessage({ id: 'ERROR' })}: ${getResponseMessage(e)}`, {
          variant: 'error',
        });
      });
  }, [data]);
};
