import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Button,
  Typography,
  Card,
  Table,
  CardContent,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TableFooter,
  Tooltip,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';
import VisibilitySharp from '@mui/icons-material/VisibilitySharp';
import VisibilityOffSharp from '@mui/icons-material/VisibilityOffSharp';

import { useIntl } from 'react-intl';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { SHeadTableCell } from '../../../components/styledComponents/Tables';
import Preloader from '../../../components/other/Preloader/Preloader';
import TablePaginator from '../../../components/tableComponents/TablePaginator/TablePaginator';

import { actions as promocodesActions } from '../../../store/ducks/promocodes.duck';
import { actions as profileActions } from '../../../store/ducks/profile.duck';
import { IAppState } from '../../../store/rootDuck';
import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';
import useCrudSnackbar from '../../../hooks/useCrudSnackbar';
import homeStyles from '../../../constants/homeStyles';
import { useStylesPromocodes } from './hooks/useStyles';
import { IPromocode } from '../../../interfaces/promocodes';
import SettingsPromocodes from './components/SettingsPromocode';

const PromocodesPage: React.FC<TPropsFromRedux> = ({
  page,
  perPage,
  total,
  promocodes,
  fetch,
  loading,
  delPromocodeSuccess,
  clearDelPromocode,
  clearEdit,
  edit,
  delPromocodeError,
  clearPromocodes,
  me,
  fetchMe,
  loadingMe,
  clearMe,
  editSuccess,
}) => {
  const classes = useStylesPromocodes();
  const navigate = useNavigate();
  const homeClasses = homeStyles();
  const [headerName, setHeaderName] = useState('');
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [changeRange, setChangeRange] = React.useState<boolean>(false);
  const [startDate, setStartDate] = React.useState<string>(
    moment(new Date()).format('YYYY.MM.DD HH:mm')
  );
  const [endDate, setEndDate] = React.useState<string>(
    moment(new Date()).format('YYYY.MM.DD HH:mm')
  );

  useCrudSnackbar({
    success: delPromocodeSuccess,
    error: delPromocodeError,
    clear: clearDelPromocode,
    successMessage: intl.formatMessage({ id: 'COMPANY.DELETE.SUCCESS' }),
    errorMessage: `${intl.formatMessage({ id: 'ERROR' })}: ${delPromocodeError}`,
    afterSuccessOrError: () => {
      clearDelPromocode();
    },
    afterSuccess: () =>
      fetch({
        page,
        perPage,
        isFilter: changeRange,
        filterData: { start_date: startDate, end_date: endDate },
        by_user: false,
      }),
  });

  useLayoutEffect(() => {
    if (editSuccess) {
      enqueueSnackbar(
        `${intl.formatMessage({
          id: 'PROMOCODE.EDIT.SUCCESS',
        })}`,
        {
          variant: 'success',
        }
      );
      clearEdit();
    }
  }, [editSuccess]);

  useEffect(() => {
    fetchMe();
    return () => {
      clearMe();
      clearPromocodes();
      clearEdit();
    };
  }, []);

  const changeStatusPromocode = useCallback((item: IPromocode, active: boolean) => {
    edit({
      id: item.id,
      data: {
        type: item.type,
        code: item.code,
        amount: item.amount,
        author_bonus: item.author_bonus,
        active,
        createdAt: item.createdAt,
      },
    });
    fetchMe();
  }, []);

  const handleChangeRange = (start: string | Date, end: string | Date) => {
    const first = moment(start).format('YYYY-MM-DD HH:mm');
    const second = moment(end).format('YYYY-MM-DD HH:mm');
    setChangeRange(true);
    setStartDate(first);
    setEndDate(second);
  };

  useEffect(() => {
    setHeaderName(intl.formatMessage({ id: 'SUBMENU.PROMOCODES' }));
    fetch({
      page,
      perPage,
      isFilter: changeRange,
      filterData: { start_date: startDate, end_date: endDate },
      by_user: false,
    });
  }, [me, changeRange, startDate, endDate]);

  setLayoutSubheader({
    title: headerName,
    breadcrumb: [],
  });

  setLayoutFooter({ show: true });

  if (!me || loadingMe || !promocodes || loading) return <Preloader />;

  return (
    <>
      <div style={{ marginTop: 8, display: 'flex', justifyContent: 'space-between' }}>
        <Button
          color='primary'
          variant='contained'
          onClick={() => navigate('/promocodes/new')}
          className={classes.addBtn}
        >
          {intl.formatMessage({ id: 'SUBMENU.PROMOCODES.ADD' })}
        </Button>
      </div>
      <div className={classes.container}>
        <div className={classes.filterCol}>
          <Card className={classes.filter}>
            <>
              {me.is_admin && (
                <SettingsPromocodes {...{ handleChangeRange, startDate, endDate }} />
              )}
            </>
          </Card>
        </div>
        <div className={classes.ordersCol}>
          {!promocodes.length ? (
            <Typography className={classes.empty} component='h5' variant='h5'>
              {intl.formatMessage({ id: 'COMMON.LIST.EMPTY' })}
            </Typography>
          ) : (
            <>
              <Card className={classes.card}>
                <CardContent className={homeClasses.classes.tableContainer}>
                  <Table aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <SHeadTableCell>
                          {intl.formatMessage({ id: 'PROMOCODES.TABLE.CODE' })}
                        </SHeadTableCell>
                        <SHeadTableCell>
                          {intl.formatMessage({ id: 'PROMOCODES.TABLE.CREATED.AT' })}
                        </SHeadTableCell>
                        <SHeadTableCell>
                          {intl.formatMessage({ id: 'PROMOCODES.TABLE.ACTIVITY' })}
                        </SHeadTableCell>
                        <SHeadTableCell align='right'>
                          {intl.formatMessage({ id: 'PROMOCODES.TABLE.ACTIONS' })}
                        </SHeadTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {promocodes.map(item => (
                        <TableRow key={item.id}>
                          <TableCell component='th' scope='row'>
                            {item.code}
                          </TableCell>

                          <TableCell component='th' scope='row'>
                            {moment(item.created_at).format('DD.MM.YYYY HH:mm')}
                          </TableCell>

                          <TableCell component='th' scope='row'>
                            {item.active
                              ? intl.formatMessage({ id: 'PROMOCODES.TABLE.ACTIVE' })
                              : intl.formatMessage({ id: 'PROMOCODES.TABLE.INACTIVE' })}
                          </TableCell>

                          <TableCell align='right'>
                            <div>
                              <Tooltip
                                title={intl.formatMessage({
                                  id: 'PROMOCODES.EDIT.TITLE',
                                })}
                              >
                                <IconButton
                                  color='primary'
                                  aria-label='previous'
                                  onClick={() => navigate(`/promocodes/edit/${item.id}`)}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>

                              <Tooltip
                                title={intl.formatMessage({
                                  id: 'PROMOCODES.CHANGE.ACTIVITY',
                                })}
                              >
                                <IconButton color='primary' aria-label='previous'>
                                  {item.active ? (
                                    <Typography
                                      color='secondary'
                                      onClick={() => changeStatusPromocode(item, false)}
                                    >
                                      <VisibilityOffSharp />
                                    </Typography>
                                  ) : (
                                    <Typography
                                      color='primary'
                                      onClick={() => changeStatusPromocode(item, true)}
                                    >
                                      <VisibilitySharp />
                                    </Typography>
                                  )}
                                </IconButton>
                              </Tooltip>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePaginator
                          page={page}
                          realPerPage={promocodes.length}
                          perPage={perPage}
                          total={total}
                          fetchRows={fetch}
                          label={intl.formatMessage({ id: 'PROMOCODES.TABLE.PERPAGE' })}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </CardContent>
              </Card>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    page: state.promocodes.page,
    perPage: state.promocodes.per_page,
    total: state.promocodes.total,
    promocodes: state.promocodes.promocodes,
    loading: state.promocodes.loading,
    delPromocodeSuccess: state.promocodes.delPromocodeSuccess,
    delPromocodeError: state.promocodes.delPromocodeError,
    editSuccess: state.promocodes.editSuccess,

    me: state.profile.me,
    loadingMe: state.profile.loading,
  }),
  {
    fetch: promocodesActions.fetchRequest,
    clearDelPromocode: promocodesActions.clearDelPromocode,
    clearPromocodes: promocodesActions.clearPromocodes,
    edit: promocodesActions.editRequest,
    clearEdit: promocodesActions.clearEdit,

    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PromocodesPage);
