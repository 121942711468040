import { useFormik } from 'formik';
import * as Yup from 'yup';
import { IUser, IUserAddProps, TUserAttributes } from '../../../../../interfaces/user';
import { useDefineUserRole, useFormatMessage } from '../../../../../hooks';
import { cleanPhone } from '../../../../../utils/utils';
import { useManagerRole } from './useManagerRole';

const getUserAttrLanguages = (attributes: TUserAttributes) => {
  return attributes.filter(el => el.type === 'languages').map(el => el.value);
};

const getUserAttrCountry = (attributes: TUserAttributes) => {
  const countries = attributes.filter(el => el.type === 'country');
  return countries[0]?.value || '';
};

export const getInitialValues = (
  user: IUser | null,
  countryData: any,
  role:
    | 'ROLE_BUYER'
    | 'ROLE_MANAGER'
    | 'ROLE_VENDOR'
    | 'ROLE_VENDOR_STAFF'
    | 'ROLE_CONTENT_MANAGER'
    | 'ROLE_BUYER_STAFF'
) => ({
  login: user?.login || '',
  email: user?.email || '',
  phone: user?.phone?.substring(1) || '',
  password: '',
  password2: '',
  role: user?.roles[0] || role || '',
  company: user?.company || null,
  active: user?.active || false,
  companyInn: '',
  languages: user?.attributes ? getUserAttrLanguages(user.attributes) : [],
  country: user?.attributes ? getUserAttrCountry(user.attributes) : '',
  first_name: user?.first_name || '',
  last_name: user?.last_name || '',
  middle_name: user?.middle_name || '',
  phoneNumbers: user?.phone_numbers
    ? cleanPhone(user.phone_numbers, `+${countryData?.code || '7'}`)
    : '',
  categories: user?.categories ? user?.categories?.map(i => i.category.id!.toString()) : [],
});

export const useFormikUserEdit = (
  id: number | undefined | string,
  user: IUser | null,
  edit: any,
  add: any,
  countryData: any,
  role:
    | 'ROLE_BUYER'
    | 'ROLE_MANAGER'
    | 'ROLE_VENDOR'
    | 'ROLE_VENDOR_STAFF'
    | 'ROLE_CONTENT_MANAGER'
    | 'ROLE_BUYER_STAFF',
  me?: IUser
) => {
  const fm = useFormatMessage();
  const isRoleManager = useDefineUserRole(me, 'ROLE_MANAGER');
  const isVendorStaff = useDefineUserRole(user, 'ROLE_VENDOR_STAFF');
  const isBuyerStaff = useDefineUserRole(user, 'ROLE_BUYER_STAFF');
  const isVendor = useDefineUserRole(user, 'ROLE_VENDOR');
  const isBuyer = useDefineUserRole(user, 'ROLE_BUYER');
  const { editManager } = useManagerRole();

  return useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(user, countryData, role),
    validationSchema: Yup.object().shape({
      login: Yup.string().required(fm('AUTH.INPUT.LOGIN_REQUIRED')).nullable(),
      email: Yup.string()
        .required(fm('USER.EDIT.EMAIL.REQUIRED'))
        .email(fm('USER.EDIT.EMAIL.INCORRECT'))
        .nullable(),
      password2: Yup.string().test(
        'password2',
        fm('USER.EDIT.PASSWORD.SIMILAR'),
        function test(value) {
          // eslint-disable-next-line react/no-this-in-sfc
          return this.parent.password === value;
        }
      ),
      password: !id ? Yup.string().required(fm('USER.EDIT.PASSWORD.REQUIRED')) : Yup.string(),
      role: Yup.string().required(`${fm('USER.EDIT.ROLE.REQUIRED')}`),
    }),

    onSubmit: async values => {
      if (id) {
        const submitValues = { ...values };
        if (!submitValues.password.trim()) {
          // @ts-ignore
          delete submitValues.password;
          // @ts-ignore
          delete submitValues.password2;
        }

        if (
          (me?.is_admin || isRoleManager) &&
          (isVendorStaff || isBuyerStaff || isVendor || isBuyer) &&
          user
        ) {
          const currentRole = user.roles.includes(values.role);
          if (!currentRole) {
            await editManager(
              user.id,
              values.role as
                | 'ROLE_VENDOR'
                | 'ROLE_VENDOR_STAFF'
                | 'ROLE_BUYER'
                | 'ROLE_BUYER_STAFF'
            );
          }
        }

        edit({
          id: +id,
          data: {
            ...submitValues,
            phone: submitValues.phone
              ? `${countryData?.code || '7'}${submitValues.phone}`
              : undefined,
            phoneNumbers: values.phoneNumbers || undefined,
            countryCode: countryData.code || '7',
            categoriesIds:
              // eslint-disable-next-line no-nested-ternary
              role === 'ROLE_VENDOR_STAFF' || isVendorStaff
                ? values.categories.length > 0
                  ? values.categories.join()
                  : ''
                : undefined,
            categories: undefined,
          },
        });
      } else {
        const addData: IUserAddProps = {
          ...values,
          roles: [values.role],
          companyId: values.company?.id ? values.company?.id.toString() : undefined,
          countryCode: countryData.code || '7',
          phone: values.phone || undefined,
          phoneNumbers: values.phoneNumbers || undefined,
          categoriesIds:
            // eslint-disable-next-line no-nested-ternary
            role === 'ROLE_VENDOR_STAFF' || isVendorStaff
              ? values.categories.length > 0
                ? values.categories.join()
                : ''
              : undefined,
          categories: undefined,
        };
        add(addData);
      }
    },
  });
};
