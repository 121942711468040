import React from 'react';
import {
  Card,
  CardContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Theme,
} from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles, createStyles } from '@mui/styles';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { useIntl } from 'react-intl';
import { SHeadTableCell } from '../../../../components/styledComponents/Tables';
import homeStyles from '../../../../constants/homeStyles';
import { ButtonWithLoader } from '../../../../components/ui/Buttons';
import { ButtonTheme } from '../../../../components/ui/Buttons/ButtonWithLoader';
import { IUser } from '../../../../interfaces/user';
import { getProductImage } from '../utils/getProductImage';
import { thousands } from '../../../../utils/utils';
import { IProduct } from '../../../../interfaces/product';
import { useDefineUserRole } from '../../../../hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttons: {
      marginRight: theme.spacing(3),
    },

    card: {
      // marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      paddingTop: 20,
      marginRight: 20,
      minWidth: 650,
    },
    cardTitle: {
      marginLeft: theme.spacing(4),
      fontSize: 17,
      fontWeight: 700,
      color: '#000',
    },

    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    img: {
      maxHeight: 55,
      cursor: 'pointer',
    },
    head: {
      fontWeight: 'bold',
      fontSize: 14,
      paddingBottom: 17,
      paddingTop: 17,
    },
    cell: {
      padding: 13,
    },
  })
);
interface ISortableCategoryItem {
  productList: IProduct[];
  editAction: (item: IProduct, sReviews?: boolean) => void;
  handleAddToCart: (item: IProduct) => void;
  handleDeleteDialog: (id: number | undefined) => void;
  me: IUser;
  addProductLoading: boolean;
  isAuthorized: boolean;
}

const TableNewProducts: React.FC<ISortableCategoryItem> = ({
  productList,
  editAction,
  me,
  addProductLoading,
  handleAddToCart,
  handleDeleteDialog,
  isAuthorized,
}) => {
  const intl = useIntl();
  const homeClasses = homeStyles();
  const navigate = useNavigate();
  const classes = useStyles();
  const isBuyer = useDefineUserRole(me, ['ROLE_BUYER', 'ROLE_BUYER_STAFF']);
  const isManager = useDefineUserRole(me, 'ROLE_MANAGER');
  const isAdmin = useDefineUserRole(me, 'ROLE_ADMIN');

  return (
    <>
      <Card className={classes.card}>
        <text className={classes.cardTitle}>
          {' '}
          {intl.formatMessage({ id: 'PRODUCT.ALL.NEW_PRODUCTS' })}
        </text>
        <CardContent className={homeClasses.classes.tableContainer}>
          <Table aria-label='simple table'>
            <TableHead>
              <TableRow>
                {/* <SHeadTableCell> */}
                {/*  {intl.formatMessage({ id: 'PRODUCT.TABLE.ID' })} */}
                {/* </SHeadTableCell> */}
                <SHeadTableCell className={classes.head}>
                  {intl.formatMessage({ id: 'PRODUCT.TABLE.PREVIEW' })}
                </SHeadTableCell>
                <SHeadTableCell className={classes.head}>
                  {intl.formatMessage({ id: 'PRODUCT.TABLE.NAME' })}
                </SHeadTableCell>
                <SHeadTableCell className={classes.head}>
                  {intl.formatMessage({ id: 'PRODUCT.TABLE.CATEGORY.NAME' })}
                </SHeadTableCell>
                <SHeadTableCell className={classes.head}>
                  {intl.formatMessage({ id: 'PRODUCT.TABLE.PRICE' })}
                </SHeadTableCell>
                {/* <SHeadTableCell> */}
                {/*  {intl.formatMessage({id: 'PRODUCT.TABLE.STOCK.QUANTITY'})} */}
                {/* </SHeadTableCell> */}
                {/* {!me?.roles.includes(UserRoles.ROLE_BUYER) && ( */}
                {/*  <SHeadTableCell align='center'> */}
                {/*    {intl.formatMessage({ id: 'PRODUCT.TABLE.ACTIVE' })} */}
                {/*  </SHeadTableCell> */}
                {/* )} */}
                {me.is_admin ||
                  (isManager && (
                    <SHeadTableCell className={classes.head}>
                      {intl.formatMessage({ id: 'MENU.COMPANY' })}
                    </SHeadTableCell>
                  ))}
                {!isBuyer && (
                  <SHeadTableCell align='center' className={classes.head}>
                    {intl.formatMessage({ id: 'PRODUCT.TABLE.ACTIONS' })}
                  </SHeadTableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {productList.map((item, index) => {
                const photo = getProductImage(item);
                return index < 10 ? (
                  <TableRow key={item.id}>
                    {/* <TableCell component='th' scope='row'> */}
                    {/*  {item.id || '-'} */}
                    {/* </TableCell> */}
                    <TableCell className={classes.cell}>
                      {photo ? (
                        <img
                          onClick={() => editAction(item)}
                          src={photo}
                          className={classes.img}
                          alt=' '
                          // onError={(e: any) => {
                          //     e.target.src = toAbsoluteUrl('/images/placeholder.png');
                          // }}
                        />
                      ) : (
                        <div onClick={() => editAction(item)} className={classes.img} />
                      )}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <RouterLink to={`/products/edit/${item.id}`} className={classes.buttons}>
                        {item.name || '-'}
                      </RouterLink>
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {item.category?.name || '-'}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {item.price ? thousands(String(item.price)) : '-'}
                    </TableCell>
                    {/* <TableCell> */}
                    {/*  {item.stock_info?.stock_quantity || "-"} */}
                    {/* </TableCell> */}
                    {/* {!me?.roles.includes(UserRoles.ROLE_BUYER) && ( */}
                    {/*  <TableCell> */}
                    {/*    <div style={{ display: 'flex', justifyContent: 'center' }}> */}
                    {/*      <StatusIndicator isActive={item.active} /> */}
                    {/*    </div> */}
                    {/*  </TableCell> */}
                    {/* )} */}
                    {me.is_admin ||
                      (isManager && (
                        <TableCell className={classes.cell}>
                          {item.company ? (
                            <RouterLink
                              to={`/companies/edit/${item.company?.id}`}
                              className={classes.buttons}
                            >
                              {item.company.name}
                            </RouterLink>
                          ) : (
                            '-'
                          )}
                        </TableCell>
                      ))}
                    <TableCell className={classes.cell}>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {(isBuyer || !isAuthorized) && (
                          <IconButton
                            disabled={addProductLoading}
                            onClick={() => handleAddToCart(item)}
                          >
                            <AddShoppingCartIcon color='primary' />
                          </IconButton>
                        )}
                        {/* <IconButton onClick={() => viewAction(item)}> */}
                        {/*   <VisibilityIcon color='primary' /> */}
                        {/* </IconButton> */}
                        {!isBuyer && (
                          <>
                            {/* {!isAdmin && ( */}
                            {/*   <Tooltip */}
                            {/*     title={intl.formatMessage({ */}
                            {/*       id: 'PRODUCTS.TABLE.TOOLTIP.ADD.TO.SUPPLY', */}
                            {/*     })} */}
                            {/*   > */}
                            {/*     <IconButton */}
                            {/*       onClick={() => { */}
                            {/*         handleAddToSupply(item); */}
                            {/*       }} */}
                            {/*     > */}
                            {/*       <DockIcon color='primary' /> */}
                            {/*     </IconButton> */}
                            {/*   </Tooltip> */}
                            {/* )} */}
                            <Tooltip
                              title={intl.formatMessage({
                                id: 'PRODUCTS.TABLE.TOOLTIP.EDIT',
                              })}
                            >
                              <IconButton onClick={() => editAction(item)}>
                                <EditIcon color='primary' />
                              </IconButton>
                            </Tooltip>
                            {/* <IconButton onClick={() => editAction(item, true)}> */}
                            {/*   <Badge */}
                            {/*     badgeContent={item.review_count || 0} */}
                            {/*     color='primary' */}
                            {/*   > */}
                            {/*     <RateReviewIcon color='primary' /> */}
                            {/*   </Badge> */}
                            {/* </IconButton> */}
                            {(isAdmin || isManager) && (
                              <Tooltip
                                title={intl.formatMessage({
                                  id: 'PRODUCTS.TABLE.TOOLTIP.DELETE',
                                })}
                              >
                                <IconButton
                                  onClick={() => {
                                    handleDeleteDialog(item.id);
                                  }}
                                >
                                  <DeleteIcon color='error' />
                                </IconButton>
                              </Tooltip>
                            )}
                          </>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ) : null;
              })}
            </TableBody>

            {/* <TableFooter> */}
            {/*  <TableRow> */}
            {/*    <TablePaginator */}
            {/*      id={undefined} */}
            {/*      page={page} */}
            {/*      realPerPage={productList.length} */}
            {/*      perPage={perPage} */}
            {/*      total={total} */}
            {/*      fetchRows={fetch} */}
            {/*      companyId={userCompany?.id.toString()} */}
            {/*      label={intl.formatMessage({ id: 'PRODUCT.TABLE.PERPAGE' })} */}
            {/*    /> */}
            {/*  </TableRow> */}
            {/* </TableFooter> */}
          </Table>
        </CardContent>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ButtonWithLoader
            style={{ marginBottom: 25, marginRight: 10 }}
            theme={ButtonTheme.PRIMARY}
            onPress={() => navigate('/products/catalog')}
          >
            {intl.formatMessage({ id: 'CATEGORIES.BUTTON.VIEW.ALL' })}
          </ButtonWithLoader>
        </div>
      </Card>
    </>
  );
};

export default TableNewProducts;
