import React, { useState } from 'react';
import {
  Collapse,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useIntl } from 'react-intl';

import moment from 'moment';

import { SHeadTableCell } from '../../../../components/styledComponents/Tables';
import { ColorButton } from '../../../../components/other/Buttons';
import { orderTypeList } from '../constatns';
import { ViewMode } from '../OrderPage';
import { IUser } from '../../../../interfaces/user';
import { IOrder, IOrderType } from '../../../../interfaces/order';
import { useStyles } from '../hooks/useStyles';

const getStatusString = (type: string, selectedOrderStatuses: string[]): string => {
  if (type === 'full') {
    if (selectedOrderStatuses.length > 0) {
      return selectedOrderStatuses.toString();
    }
    return orderTypeList.toString();
  }
  if (type === 'abandoned') return 'cart';
  return '';
};
type TProps = {
  item: IOrder;
  me?: IUser;
  type: string;
  selectAction: (id: any) => void;
  selectActionPaid: (id: any) => void;
  editStatus: (id: any) => void;
  handleDeleteDialog: (id: number | undefined) => void;
  editAction: (item: any, mode: any) => void;
  selectedOrderStatuses: string[];
  selectedPaidOrderStatuses: string[];
  selectedId: number;
  selectedPaidId: number;
  isBuyer: boolean;
  translates: { [p: string]: string };
  translatesStatus: { [p: string]: string };
  page: number;
  perPage: number;
  userId: number | undefined;
  orderTypes: IOrderType[];
  orderStatusTypes: IOrderType[];
};
const TableRowOrderList: React.FC<TProps> = ({
  item,
  me,
  type,
  selectAction,
  handleDeleteDialog,
  editAction,
  selectedOrderStatuses,
  selectedId,
  isBuyer,
  translates,
  translatesStatus,
  editStatus,
  page,
  perPage,
  userId,
  orderTypes,
  orderStatusTypes,
  selectedPaidId,
  selectActionPaid,
}) => {
  const classes1 = useStyles();
  const {classes} = classes1
  const intl = useIntl();
  const [open, setOpen] = useState(false);

  const isOpen = me?.is_admin && (item?.orders?.length ?? 0) > 1;
  return (
    <>
      <TableRow key={item.id}>
        <TableCell className={classes.cell} onClick={() => isOpen && setOpen(!open)}>
          {item.id || '-'}{' '}
          {me?.is_admin && (item?.orders?.length ?? 0) > 1 ? (
            <IconButton aria-label='expand row' size='small'>
              {open ? (
                <KeyboardArrowUpIcon fontSize='large' />
              ) : (
                <KeyboardArrowDownIcon fontSize='large' />
              )}
            </IconButton>
          ) : null}
        </TableCell>
        <TableCell onClick={() => isOpen && setOpen(!open)} className={classes.cell}>
          {item.goods_num || '-'}
        </TableCell>
        <TableCell onClick={() => isOpen && setOpen(!open)} className={classes.cell}>
          {item.orders && item.orders.length > 0 ? item.orders[0]?.company?.brand || '' : ''}
        </TableCell>
        <TableCell onClick={() => isOpen && setOpen(!open)} className={classes.cell}>
          {item.created_at ? moment(item.created_at).format('DD.MM.YY') : '-'}
        </TableCell>
        <TableCell className={classes.cell}>
          {selectedId !== item.id ? (
            <ColorButton
              color='#DDDDDD'
              onClick={() => selectAction(item.id)}
              disable={Boolean(isBuyer)}
            >
              {translates[item.status]}
            </ColorButton>
          ) : (
            <TextField
              select
              margin='normal'
              value={item.status}
              onChange={event => {
                editStatus({
                  id: item.id,
                  page,
                  perPage,
                  statuses: getStatusString(type, selectedOrderStatuses),
                  data: { status: event.target.value },
                  userId,
                  root: me?.is_admin,
                });
                selectAction(-1);
              }}
              variant='outlined'
              className={classes.typeList}
              size='small'
            >
              {orderTypes &&
                orderTypes.map(option => (
                  <MenuItem key={option.type} value={option.type}>
                    {option.translate}
                  </MenuItem>
                ))}
            </TextField>
          )}
        </TableCell>
        <TableCell className={classes.cell}>
          {selectedPaidId !== item.id ? (
            <ColorButton
              color='#DDDDDD'
              onClick={() => selectActionPaid(item.id)}
              disable={Boolean(isBuyer)}
            >
              {translatesStatus[item.payment_status]}
            </ColorButton>
          ) : (
            <TextField
              select
              margin='normal'
              value={item.payment_status}
              onChange={event => {
                editStatus({
                  id: item.id,
                  page,
                  perPage,
                  statuses: getStatusString(type, selectedOrderStatuses),
                  data: {
                    payment_status: event.target.value,
                    items: item.items.map(e => ({
                      count: e.count,
                      product_id: e.product.id,
                    })),
                  },
                  userId,
                  root: me?.is_admin,
                });
                selectActionPaid(-1);
              }}
              variant='outlined'
              className={classes.typeList}
              size='small'
            >
              {orderStatusTypes &&
                orderStatusTypes.map(option => (
                  <MenuItem key={option.type} value={option.type}>
                    {option.translate}
                  </MenuItem>
                ))}
            </TextField>
          )}
        </TableCell>
        <TableCell onClick={() => isOpen && setOpen(!open)} className={classes.cell}>
          {item.order_summ || '-'}
        </TableCell>
        <TableCell onClick={() => isOpen && setOpen(!open)} className={classes.cell}>
          {item.summ || '-'}
        </TableCell>
        <TableCell className={classes.cell}>
          <IconButton
            onClick={() => {
              editAction(item, ViewMode.VIEW);
            }}
          >
            <VisibilityIcon color='primary' />
          </IconButton>

          {!isBuyer && (
            <>
              <IconButton
                color='primary'
                aria-label='previous'
                onClick={() => {
                  editAction(item, ViewMode.EDIT);
                }}
              >
                <EditIcon />
              </IconButton>

              <IconButton
                onClick={() => {
                  handleDeleteDialog(item.id);
                }}
              >
                <DeleteIcon color='error' />
              </IconButton>
            </>
          )}
        </TableCell>
      </TableRow>
      <TableRow className={classes.subTableRow}>
        <TableCell style={{ padding: 0 }} colSpan={11}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Table size='small' aria-label='purchases'>
              <TableHead>
                <TableRow>
                  <SHeadTableCell align='center' className={classes.cellItem}>
                    {intl.formatMessage({ id: 'ORDER.TABLE.ID' })}
                  </SHeadTableCell>
                  <SHeadTableCell className={classes.cellItem}>
                    {intl.formatMessage({ id: 'ORDER.TABLE.PRODUCT_AMOUNT' })}
                  </SHeadTableCell>
                  <SHeadTableCell className={classes.cellItem}>
                    {intl.formatMessage({ id: 'USER.ROLES.VENDOR' })}
                  </SHeadTableCell>
                  <SHeadTableCell className={classes.cellItem}>
                    {intl.formatMessage({ id: 'ORDER.TABLE.DATE' })}
                  </SHeadTableCell>
                  <SHeadTableCell className={classes.cellItem}>
                    {intl.formatMessage({ id: 'ORDER.TABLE.STATUS' })}
                  </SHeadTableCell>
                  <SHeadTableCell className={classes.cellItem}>
                    {intl.formatMessage({ id: 'ORDER.TABLE.STATUS.PAYMENT' })}
                  </SHeadTableCell>
                  <SHeadTableCell className={classes.cellItem}>
                    {intl.formatMessage({ id: 'ORDER.TABLE.SUM_ORDER' })}
                  </SHeadTableCell>
                  <SHeadTableCell className={classes.cellItem}>
                    {intl.formatMessage({ id: 'ORDER.TABLE.SUM_PAY' })}
                  </SHeadTableCell>
                  <SHeadTableCell className={classes.cellItem} align='center'>
                    {intl.formatMessage({ id: 'ORDER.TABLE.ACTIONS' })}
                  </SHeadTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {item?.orders?.map(orderItem => (
                  <TableRow key={orderItem.id}>
                    <TableCell align='right' className={classes.cell}>
                      {orderItem.id || '-'}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {orderItem.goods_num || '-'}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {orderItem.company?.brand || ''}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {orderItem.created_at
                        ? moment(orderItem.created_at).format('DD.MM.YY')
                        : '-'}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {selectedId !== orderItem.id ? (
                        <ColorButton
                          color='#DDDDDD'
                          onClick={() => selectAction(orderItem.id)}
                          disable={Boolean(isBuyer)}
                        >
                          {translates[orderItem.status]}
                        </ColorButton>
                      ) : (
                        <TextField
                          select
                          margin='normal'
                          value={orderItem.status}
                          onChange={event => {
                            editStatus({
                              id: orderItem.id,
                              page,
                              perPage,
                              statuses: getStatusString(type, selectedOrderStatuses),
                              data: {
                                status: event.target.value,
                                items: orderItem.items.map(e => ({
                                  count: e.count,
                                  product_id: e.product.id,
                                })),
                              },
                              userId,
                              root: me?.is_admin,
                            });
                            selectAction(-1);
                          }}
                          variant='outlined'
                          className={classes.typeList}
                          size='small'
                        >
                          {orderTypes &&
                            orderTypes.map(option => (
                              <MenuItem key={option.type} value={option.type}>
                                {option.translate}
                              </MenuItem>
                            ))}
                        </TextField>
                      )}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {selectedPaidId !== orderItem.id ? (
                        <ColorButton
                          color='#DDDDDD'
                          onClick={() => selectActionPaid(orderItem.id)}
                          disable={Boolean(isBuyer)}
                        >
                          {translatesStatus[orderItem.payment_status]}
                        </ColorButton>
                      ) : (
                        <TextField
                          select
                          margin='normal'
                          value={orderItem.payment_status}
                          onChange={event => {
                            editStatus({
                              id: orderItem.id,
                              page,
                              perPage,
                              statuses: getStatusString(type, selectedOrderStatuses),
                              data: {
                                payment_status: event.target.value,
                                items: orderItem.items.map(e => ({
                                  count: e.count,
                                  product_id: e.product.id,
                                })),
                              },
                              userId,
                              root: me?.is_admin,
                            });
                            selectActionPaid(-1);
                          }}
                          variant='outlined'
                          className={classes.typeList}
                          size='small'
                        >
                          {orderStatusTypes &&
                            orderStatusTypes.map(option => (
                              <MenuItem key={option.type} value={option.type}>
                                {option.translate}
                              </MenuItem>
                            ))}
                        </TextField>
                      )}
                    </TableCell>
                    <TableCell className={classes.cell}>{orderItem.summ || '-'}</TableCell>
                    <TableCell className={classes.cell}>{orderItem.summ || '-'}</TableCell>
                    <TableCell className={classes.cell} align='right'>
                      {/* {!smallSendMsgBtn && ( */}
                      {/*  <Tooltip */}
                      {/*    title={intl.formatMessage({ id: 'DIALOGCHAT.SEND' })} */}
                      {/*  > */}
                      {/*    <Button */}
                      {/*      onClick={() => handlerShowMessages(item)} */}
                      {/*      color='inherit' */}
                      {/*      variant='contained' */}
                      {/*      className={classes.viewButton} */}
                      {/*    > */}
                      {/*      {intl.formatMessage({ id: 'DIALOGCHAT.SEND' })} */}
                      {/*      <SendIcon */}
                      {/*        color='primary' */}
                      {/*        className={classes.iconViewButton} */}
                      {/*      /> */}
                      {/*    </Button> */}
                      {/*  </Tooltip> */}
                      {/* )} */}

                      <IconButton
                        onClick={() => {
                          editAction(orderItem, ViewMode.VIEW);
                        }}
                      >
                        <VisibilityIcon color='primary' />
                      </IconButton>

                      {!isBuyer && (
                        <>
                          <IconButton
                            onClick={() => {
                              editAction(orderItem, ViewMode.EDIT);
                            }}
                          >
                            <EditIcon color='primary' />
                          </IconButton>

                          <IconButton
                            onClick={() => {
                              handleDeleteDialog(orderItem.id);
                            }}
                          >
                            <DeleteIcon color='error' />
                          </IconButton>
                        </>
                      )}
                      {/* {smallSendMsgBtn && ( */}
                      {/*  <IconButton onClick={() => handlerShowMessages(item)}> */}
                      {/*    <SendIcon color='primary' /> */}
                      {/*  </IconButton> */}
                      {/* )} */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default React.memo(TableRowOrderList);
