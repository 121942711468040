import React, { FC } from 'react';
import { Tab, Tabs as TabsLib } from '@mui/material';
import { a11yProps } from '../../../../../components/other/Tab/TabPanel';
import { useFormatMessage } from '../../../../../hooks';

interface ITabs {
  tabValue: number;
  changeTab: (event: React.ChangeEvent<{}>, value: any) => void;
}

export const Tabs: FC<ITabs> = ({ tabValue, changeTab }) => {
  const fm = useFormatMessage();

  return (
    <TabsLib
      value={tabValue}
      onChange={changeTab}
      variant='scrollable'
      indicatorColor='primary'
      textColor='primary'
      aria-label='tabs'
    >
      <Tab label={fm('PUBLICATIONS.TABS.COMMON')} {...a11yProps(0)} />
      <Tab label={fm('PUBLICATIONS.TABS.PHOTOS')} {...a11yProps(1)} />
    </TabsLib>
  );
};
