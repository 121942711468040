import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => {
  return {
    container: {
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 4,
      flexWrap: 'wrap',
      marginBottom: 15,
      aspectRatio: '3/1',
      // @ts-ignore
      [theme.breakpoints.down('sm')]: {
        marginBottom: 0,
      },
    },
    dotContainwer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 10,
      marginTop: 15,
      margin: '0 auto',

      [theme.breakpoints.up('lg')]: {
        maxWidth: 708,
      },
      [theme.breakpoints.up('xl')]: {
        maxWidth: 955,
      },
    },
    wrapper: {
      display: 'flex !important',
      gridGap: 24,
      position: 'absolute',
      transition: '.4s ease-out',
      alignItems: 'center',
      left: 0,
    },
    productCard: {
      transition: '.4s ease-out',
      cursor: 'pointer',
      boxShadow: 'none !important',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexGrow: 1,
      flexDirection: 'column',
    },
    productCardMedia: {
      width: '100%',
      maxHeight: 410,
      borderRadius: 4,
      objectFit: '-moz-initial',
      [theme.breakpoints.down('sm')]: {
        maxHeight: 200,
      },
      // @ts-ignore
      [theme.breakpoints.up('xxl')]: {
        height: 430,
      },
    },
    carousel: {
      display: 'gird',
      gridGap: 25,
      justifyContent: 'space-between',
      width: '100%',
    },
    arrow: {
      position: 'absolute',
      top: '50%',
      minWidth: 42,
      minHeight: 42,
      maxWidth: 50,
      maxHeight: 50,
      backgroundColor: '#FFF',
      borderRadius: 6,
      cursor: 'pointer',

      '&:active': {
        opacity: 0.3,
      },
    },
    arrowLeft: {
      transform: 'translateY(-12px) rotate(180deg)',
      left: 15,
    },
    arrowRight: {
      right: 15,
      transform: 'translateY(-12px)',
    },
    arrowIcon: {
      color: theme.palette.primary.main,
      width: '100%',
      height: '100%',
    },
    cardSlideCenter: {
      opacity: 1,
      transform: 'scale(1.2)',
      marginLeft: 24,
      marginRight: 24,
    },
    cardSlideBackground: {
      opacity: 0.5,
    },
    dotsButton: {
      maxWidth: 12,
      minHeight: 12,
      minWidth: 12,
      borderRadius: 4,
      backgroundColor: theme.palette.secondary.main,
      marginRight: 15,
      marginLeft: 15,
      opacity: 0.5,
      listStyle: 'none',
      cursor: 'pointer',

      '&:hover': {
        opacity: 0.2,
      },
    },
    dotsActive: {
      opacity: 1,
    },
    nameProduct: {
      marginTop: 5,
      lineHeight: 1.3,
      marginRight: 5,
      marginLeft: 5,
    },
  };
});
