import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import Preloader from '../../../components/ui/Preloader/Preloader';

import { IAppState } from '../../../store/rootDuck';
import { actions as categoriesActions } from '../../../store/ducks/categories.duck';
import { actions as profileActions } from '../../../store/ducks/profile.duck';
import { actions as searchActions } from '../../../store/ducks/search.duck';
import { setLayoutSubheader } from '../../../utils/layout';
import SearchList from './components/SearchList';
import { useStylesProductCatalog } from '../products/hooks/useStyles';
import FilterCategoriesTree from '../products/components/FilterCategoriesTree';
import { useDeleteSearch } from './hooks/useDeleteSearch';
import { useDefineUserRole } from '../../../hooks';

const Searchs: React.FC<TPropsFromRedux> = ({
  tenders,
  loading,
  page,
  perPage,
  total,
  me,
  loadingMe,
  catalogCategories,
  fetch,
  fetchMe,
  fetchCatalogCategories,
}) => {
  const classes1 = useStylesProductCatalog();
  const {classes} = classes1
  const intl = useIntl();
  const navigate = useNavigate();
  const isBuyer = useDefineUserRole(me, ['ROLE_BUYER', 'ROLE_BUYER_STAFF']);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(null);
  const { deleteSearchFetch, successDelete } = useDeleteSearch();

  useLayoutEffect(() => {
    fetchMe();
    fetchCatalogCategories({});
  }, []);

  useLayoutEffect(() => {
    me && fetch({ page: 1, perPage: 20, user_id: isBuyer ? me?.id : undefined });
  }, [me]);

  useEffect(() => {
    successDelete && fetch({ page: 1, perPage: 20, user_id: isBuyer ? me?.id : undefined });
  }, [successDelete]);

  const deleteAction = useCallback((id: number) => {
    deleteSearchFetch(id);
  }, []);

  const editAction = useCallback(
    (item: any) => {
      navigate({ pathname: `/product_search/edit/${item.id}` });
    },
    [navigate]
  );

  const viewAction = useCallback(
    (item: any, isProposals?: boolean) => {
      navigate(`/product_search/view/${item.id}`, { state: { isProposals } });
    },
    [navigate]
  );

  setLayoutSubheader({
    title: intl.formatMessage({ id: 'SEARCH.TITLE' }),
  });

  const goToCreateTender = useCallback(() => {
    navigate(`/product_search/create`);
  }, [navigate]);

  const resetProducts = useCallback(() => {
    fetch({ page: 1, perPage: 12, user_id: isBuyer ? me?.id : undefined });
  }, [isBuyer]);

  if (loadingMe) return <Preloader />;

  return (
    <>
      <Button
        color='primary'
        variant='contained'
        style={{ marginBottom: 16 }}
        onClick={goToCreateTender}
      >
        {intl.formatMessage({ id: 'SEARCH.NEW' })}
      </Button>
      <div className={classes.container}>
        <div className={classes.filterCol}>
          <FilterCategoriesTree
            categories={catalogCategories || []}
            resetProducts={resetProducts}
            setSearch={() => {}}
            loading={loading}
            selectedCategoryId={selectedCategoryId}
            setSelectedCategoryId={setSelectedCategoryId}
            searchForCategory={id =>
              fetch({
                page,
                perPage,
                categories: id.toString(),
                user_id: isBuyer ? me?.id : undefined,
              })
            }
          />
        </div>

        <div className={classes.productsCol}>
          {loading ? (
            <Preloader />
          ) : (
            <>
              {!tenders || !tenders.length ? (
                <Typography className={classes.empty} component='h5' variant='h5'>
                  {intl.formatMessage({ id: 'CATEGORIES.TABLE.EMPTY' })}
                </Typography>
              ) : (
                <div className={classes.card}>
                  <div>
                    <SearchList
                      data={tenders}
                      page={page}
                      perPage={perPage}
                      total={total}
                      fetch={({ page, perPage }) =>
                        fetch({
                          page,
                          perPage,
                          categories: selectedCategoryId
                            ? selectedCategoryId.toString()
                            : undefined,
                          user_id: isBuyer ? me?.id : undefined,
                        })
                      }
                      viewAction={viewAction}
                      editAction={editAction}
                      deleteAction={deleteAction}
                      me={me}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

const connector = connect(
  (state: IAppState) => {
    return {
      tenders: state.search.tenders,
      page: state.search.page,
      perPage: state.search.per_page,
      total: state.search.total,
      loading: state.search.loading,

      me: state.profile.me,
      loadingMe: state.profile.loading,

      catalogCategories: state.categories.catalogCategories,
      isAuthorized: state.auth.user != null,
    };
  },
  {
    fetch: searchActions.fetchRequest,
    fetchMe: profileActions.fetchRequest,
    fetchCatalogCategories: categoriesActions.fetchCatalogCategories,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Searchs);
