import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { getResponseMessage } from '../../../../utils/utils';
import { uploadLogo } from '../../../../crud/companies.crud';

export const useUploadLogo = (id?: string) => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [loadingLogo, setLoading] = useState(false);
  const [successLogo, setSuccess] = useState(false);
  const uploadLogoFetch = useCallback(
    async (files: File[]) => {
      setLoading(true);
      const formData = new FormData();
      formData.append('photo', files[0]);
      if (id) {
        uploadLogo(id, formData)
          .then(() => {
            setSuccess(true);
            enqueueSnackbar(`${intl.formatMessage({ id: 'COMPANY.UPLOAD.LOGO' })}`, {
              variant: 'success',
            });
          })
          .catch(e => {
            enqueueSnackbar(
              `${intl.formatMessage({ id: 'ERROR' })}: ${getResponseMessage(e)}`,
              {
                variant: 'error',
              }
            );
          })
          .finally(() => {
            setLoading(false);
            setSuccess(false);
          });
      }
    },
    [id]
  );
  return { uploadLogoFetch, loadingLogo, successLogo };
};
