import axios from 'axios';
import {
  createReviewUrl,
  delPhotoFromProduct,
  delReviewUrl,
  FOR_DAYS_URL,
  getProductByIdUrl,
  getReviewsUrl,
  PRODUCT_URL,
  PRODUCTS_URL,
  setProductMainPhotoUrl,
  PRODUCTS_FAVORITES_URL,
} from '../constants';
import { ILocation } from '../interfaces/locations';
import { IProductFilter, IReviewCreate } from '../interfaces/product';
import { Translations } from '../interfaces/productType';

export const createProduct = (files: FormData) =>
  axios.post(PRODUCT_URL, files, {
    headers: { 'content-type': 'multipart/form-data' },
  });

export const editProduct = (id: number, files: FormData) =>
  axios.post(getProductByIdUrl(id), files, {
    headers: { 'content-type': 'multipart/form-data' },
  });

export const deleteProduct = (id: number | undefined) =>
  axios.delete(getProductByIdUrl(id || -1));

export const getProductById = (id: number) => axios.get(getProductByIdUrl(id));

export const getProductFavorites = () => axios.get(PRODUCTS_FAVORITES_URL);

export const getProducts = ({
  page = 1,
  perPage = 20,
  companyId,
  categoryId,
  search,
  activity,
  filter = {
    price_from: '',
    price_to: '',
  },
  locale,
  sortType,
  sort_type,
}: {
  page: number;
  perPage: number;
  companyId?: number;
  categoryId?: number;
  search?: string;
  filter?: Partial<IProductFilter>;
  activity?: string;
  sortType?: 'new' | 'rating';
  locale?: ILocation | null;
  sort_type?: string;
}) => {
  let active: string | undefined;
  if (activity === 'active') {
    active = 'true';
  }
  if (activity === 'no_active') {
    active = 'false';
  }
  if (search) {
    return axios.get('/api/shop/products/all', {
      params: {
        page,
        per_page: perPage,
        text: search.trim() ? search : undefined,
        active,
        sort_type: sortType,
      },
    });
  }
  // if (companyId) {
  //   return axios.get(`/api/shop/company/${companyId}/products`, {
  //     params: {
  //       page,
  //       per_page: perPage,
  //       category_id: categoryId,
  //       active,
  //       sort_type: sortType,
  //     },
  //   });
  // }
  if (companyId) {
    if (categoryId) {
      if (active) {
        return axios.post(`/api/shop/category/${categoryId}/products`, undefined, {
          params: {
            page,
            per_page: perPage,
            company_id: companyId,
            active,
          },
        });
      }
      return axios.post(`/api/shop/category/${categoryId}/products`, undefined, {
        params: {
          page,
          per_page: perPage,
          company_id: companyId,
        },
      });
    }
    if (active)
      return axios.get(`/api/shop/company/${companyId}/products`, {
        params: {
          page,
          per_page: perPage,
          active,
        },
      });
    return axios.get(`/api/shop/company/${companyId}/products`, {
      params: { page, per_page: perPage },
    });
  }
  if (companyId && categoryId && active) {
    return axios.post(`/api/shop/category/${categoryId}/products`, undefined, {
      params: {
        page,
        per_page: perPage,
        company_id: companyId,
        active,
      },
    });
  }
  if ((categoryId as number) >= 0) {
    if (!filter.parameters) {
      return axios.post(
        `/api/shop/category/${categoryId}/products`,
        {},
        {
          params: {
            page,
            per_page: perPage,
            lat: locale?.lat,
            lng: locale?.lng,
            active,
            sort_type: sortType,
          },
        }
      );
    }
    const formData = new FormData();
    formData.append('parameters', JSON.stringify(filter.parameters));
    return axios.post(`/api/shop/category/${categoryId}/products`, formData, {
      params: {
        page,
        per_page: perPage,
        lat: locale?.lat,
        lng: locale?.lng,
        sort_type,
        price_from: filter.price_from,
        price_to: filter.price_to,
        active,
      },
    });
  }

  if (filter.parameters) {
    const formData = new FormData();
    formData.append('parameters', JSON.stringify(filter.parameters));
    return axios.post(`/api/shop/category/${categoryId || 0}/products`, formData, {
      params: {
        page,
        per_page: perPage,
        price_from: filter.price_from,
        price_to: filter.price_to,
        active,
        sort_type,
      },
    });
  }

  const body = {
    price_from: filter?.price_from ? +filter.price_from : null,
    price_to: filter?.price_to ? +filter.price_to : null,
  };

  return axios.post(PRODUCTS_URL, body, {
    params: {
      page,
      per_page: perPage,
      active,
      sort_type,
    },
  });
};

export const delPhoto = (photoId: number) => axios.delete(delPhotoFromProduct(photoId));

export function setMainPhoto(id: number) {
  return axios.post(setProductMainPhotoUrl(id));
}

export const getReviews = (productId: number, perPage: number, page: number) =>
  axios.get(getReviewsUrl(productId, perPage, page));

export const getSimilar = (id: number) => axios.get(`/api/shop/products_similar/${id}`);
export const createReview = (productId: number, body: IReviewCreate) =>
  axios.post(createReviewUrl(productId), body);

export const delReview = (reviewId: number) => axios.delete(delReviewUrl(reviewId));

export const getMyFavorites = () => axios.get('/api/shop/favorites');

export const addMyFavorites = (product_id: number) =>
  axios.post('/api/shop/favorites', {}, { params: { product_id } });

export const deleteMyFavorites = (product_id: number) =>
  axios.delete('/api/shop/favorites', { params: { product_id } });

export const changeMyFavorites = (product_id: number, deleteFav: boolean) =>
  deleteFav ? deleteMyFavorites(product_id) : addMyFavorites(product_id);

export const getProductsForDays = ({
  page = 1,
  perPage = 20,
  forDays = 0,
}: {
  page: number;
  perPage: number;
  forDays: number;
}) =>
  axios.post(
    PRODUCTS_URL,
    {
      for_days: forDays,
    },
    {
      params: {
        page,
        per_page: perPage,
      },
    }
  );

export const getForDays = () => axios.get(FOR_DAYS_URL);
export const addForDays = (days: number) =>
  axios.post(`${FOR_DAYS_URL}`, undefined, { params: { days } });

export const editStock = (product_id: number, body: FormData) =>
  axios.post(`/api/stock_info/${product_id}`, body);

export const editReviewStatus = (review_id: number, status: string) =>
  axios.put(`/api/shop/review_status/${review_id}`, {}, { params: { status } });

export const addProductSubscribes = (productId: number, email: string) => {
  const formData = new FormData();
  formData.append('product_id', JSON.stringify(productId));
  axios.post(`/api/product_subscribes/add?email=${email}`, formData);
};

export const delProductSubscribes = (productId: number, subscribeId: number) => {
  axios.delete(`/api/product_subscribe`, {
    params: { subscribe_id: subscribeId, product_id: productId },
  });
};

export const addMultiplyProduct = (body: any) => axios.post('/api/shop/products_add', body);

export const searchProducts = (text: string) =>
  axios.get('/api/shop/products/search', { params: { text } });

export const addParameterImages = (enum_id: number, data: FormData) =>
  axios.post(`/api/shop/product_type_parameter_images/${enum_id}`, data);

export const addEnumValue = (
  parameter_id: number,
  data: {
    value: string;
    favorite: boolean;
    translations: Translations;
  }
) => axios.post(`/api/shop/parameter_enum_value`, data, { params: { parameter_id } });

export const editEnumValue = (
  id: number,
  data: {
    value: string;
    favorite: boolean;
    translations: {
      [key: string]: string;
    };
  }
) => axios.put(`/api/shop/parameter_enum_value/${id}`, data);

export const deleteEnumValue = (id: number) =>
  axios.delete(`/api/shop/parameter_enum_value/${id}`);

export const getAllProducts = () => {
  return axios.get('/api/shop/products/all');
};
export const addCurrency = (currency: string) => {
  return axios.post(
    '/api/settings/currency',
    {},
    {
      params: { currency },
    }
  );
};
