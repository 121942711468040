import { IOrderType } from '../../../interfaces/order';

export enum ListType {
  ABANDONED = 'abandoned',
  FULL = 'full',
  HISTORY = 'history',
}

export const orderTypeList: string[] = [
  'new',
  'formation',
  'ready_to_delivery',
  'in_processing',
  'payed',
  'canceled',
  'completed',
  'delivered',
];

export const getOrderTypeWithTranslates = (
  translates: {
    [type: string]: string;
  },
  isFull: boolean
): IOrderType[] => {
  const orderTypes: IOrderType[] = orderTypeList.map(item => ({
    type: item,
    translate: translates[item],
  }));
  if (isFull) {
    orderTypes.unshift({ type: 'cart', translate: translates.cart });
  }
  return orderTypes;
};
