import { IntlShape } from 'react-intl';
import { IBreadcrumb } from '../../../../../interfaces/breadcrumb';
import { getRoleBreadcrumbName } from './index';

export const usersBreadCrumbs = (
  intl: IntlShape,
  type: string,
  handle: any
): IBreadcrumb[] => {
  const breadCrumbs: IBreadcrumb[] = [];
  breadCrumbs.push({
    title: intl.formatMessage(
      { id: 'SUBMENU.USER_BY_ROLE.LIST' },
      { role: intl.formatMessage({ id: getRoleBreadcrumbName(type) }) }
    ),
    page: `users/${type}`,
    root: true,
    click: handle,
  });
  return breadCrumbs;
};
