import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { Button, TextField } from '@mui/material';

import { ButtonWithLoader } from '../../../../components/ui/Buttons';
import homeStyles from '../../../../constants/homeStyles';
import { useStylesTypeForm } from '../../products/hooks/useStyles';
import { IGroup } from '../../../../interfaces/groups';

interface IProps {
  group?: IGroup;
  editAction: (name: string) => void;
  delGroup: () => void;
  disabled: boolean;
}

const getInitialValues = (group?: IGroup) => ({
  name: !!group && group.name ? group.name : '',
});
const GroupTitleForm: React.FC<IProps> = ({ group, editAction, delGroup, disabled }) => {
  const intl = useIntl();
  const homeClasses = homeStyles();
  const classes1 = useStylesTypeForm();
  const {classes} = classes1
  return (
    <Formik
      initialValues={getInitialValues(group)}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .required(intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' }))
          .nullable(),
      })}
      onSubmit={values => {
        editAction(values.name);
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
        <div>
          <div className='kt-form'>
            <TextField
              color='secondary'
              type='text'
              label={intl.formatMessage({
                id: 'PRODUCT.TYPES.INPUT.NAME',
              })}
              margin='normal'
              name='name'
              value={values.name}
              variant='outlined'
              onBlur={handleBlur}
              onChange={handleChange}
              helperText={touched.name && errors.name}
              error={Boolean(touched.name && errors.name)}
              InputLabelProps={{
                classes: {
                  shrink: classes.labelTextFieldShrink,
                },
              }}
            />

            <div className={homeClasses.classes.buttonContainer}>
              <ButtonWithLoader loading={disabled} onPress={handleSubmit}>
                {intl.formatMessage({ id: 'CATEGORY.BUTTON.EDIT' })}
              </ButtonWithLoader>
              {group && (
                <Button
                  onClick={delGroup}
                  className={classes.btnDel}
                  variant='outlined'
                  color='secondary'
                >
                  {intl.formatMessage({ id: 'COMMON.BUTTON.DELETE' })}
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default React.memo(GroupTitleForm);
