import { makeStyles } from '@mui/styles';

export const useStylesProductIcons = makeStyles(() => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    alignItems: 'center',
    // width: 45,
  },
  title: {
    textAlign: 'center',
    fontSize: 17,
    fontWeight: 'bold',
    color: '#000',
    textTransform: 'uppercase',
    transition: 'opacity 1s ease',
    opacity: 1,
    margin: 0,
  },
}));
