import { useFormik } from 'formik';
import { isArray } from 'lodash';
import * as Yup from 'yup';
import { useFormatMessage } from '../../../../hooks';
import { TCollection } from '../types';

const getInitialValues = (collection: TCollection | null) => {
  const values: { [key: string]: any } = {
    name: collection?.name || '',
    productTypeId: collection?.product_type_id || '',
    min_price: collection?.min_price || '',
    max_price: collection?.max_price || '',
    slot1: collection?.slot1 || false,
    slot2: collection?.slot2 || false,
    slot3: collection?.slot3 || false,
    slot4: collection?.slot4 || false,
  };
  if (collection?.parameters) {
    collection.parameters.forEach(item => {
      values[`param_${item.parameter}`] = item.values;
    });
  }
  return values;
};

export const useFormikEditCollection = (
  collection: TCollection | null,
  saveCollection: (url: string, data: any) => void
) => {
  const fm = useFormatMessage();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(collection),
    validationSchema: Yup.object().shape({
      name: Yup.string().required(fm('AUTH.VALIDATION.REQUIRED_FIELD')),
    }),
    onSubmit: submitValues => {
      // clear values
      const values = { ...submitValues };
      for (const key of Object.keys(values)) {
        const value = values[key];
        if (isArray(value)) {
          if (value.length === 0 || !value.find(v => v !== '')) {
            delete values[key];
          }
        }
        if (value === '') delete values[key];
      }

      const formData = new FormData();
      formData.append('name', values.name);
      if (values.max_price) formData.append('max_price', values.max_price);
      if (values.min_price) formData.append('min_price', values.min_price);
      const params = [];
      for (const key of Object.keys(values).filter(el => el.includes('param'))) {
        const paramId = Number(key.replace('param_', ''));
        params.push({ parameter: paramId, values: values[key] });
      }
      formData.append('parameters', JSON.stringify(params));
      if (params.length === 0 && values.productTypeId) {
        formData.append('product_type_id', values.productTypeId);
      }
      if (values.slot1) formData.append('slot1', values.slot1);
      if (values.slot2) formData.append('slot2', values.slot2);
      if (values.slot3) formData.append('slot3', values.slot3);
      if (values.slot4) formData.append('slot4', values.slot4);

      let url = '/api/shop/collection';
      if (collection) url += `/${collection.id}`;
      saveCollection(url, formData);
    },
  });

  // clear params values from previous product type
  // useEffect(() => {
  //   Object.keys(formik.values)
  //     .filter(key => key.includes('param'))
  //     .forEach(key => formik.setFieldValue(key, []));
  // }, [formik.values.productTypeId]);

  return formik;
};
