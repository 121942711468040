import React from 'react';
import { Route, Routes } from 'react-router-dom';
import SystemSettingPage from './SystemSettingsTabs';

export default function SystemSettingsPage() {
  return (
    <Routes>
      <Route path='tabs' element={<SystemSettingPage />} />
    </Routes>
  );
}
