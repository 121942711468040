import React, { useCallback } from 'react';
import objectPath from 'object-path';
import clsx from 'clsx';
import { Link, useNavigate } from 'react-router-dom';

import MenuItemInner from './HMenuItemInner';
import HMenuColumn from './HMenuColumn';

type TProps = {
  item: any;
  currentUrl: string;
  rootArrowEnabled: boolean;
};

const HMenuItem: React.FC<TProps> = ({ item, currentUrl, rootArrowEnabled }) => {
  const navigate = useNavigate();

  const checkActivePage = useCallback(
    (item: any) => {
      let haveActivePage = false;

      if (item.activePages?.length) {
        for (let i = 0; i < item.activePages.length; i++) {
          const url = item.activePages[i];
          const isCurrentUrlInActivePage = currentUrl.indexOf(url) !== -1;
          if (isCurrentUrlInActivePage) {
            haveActivePage = true;
            break;
          }
        }
      }

      return haveActivePage;
    },
    [currentUrl]
  );

  const checkInActivePage = useCallback(
    (item: any) => {
      let haveInActivePage = false;

      if (item.inActivePages?.length) {
        for (let i = 0; i < item.inActivePages.length; i++) {
          const url = item.inActivePages[i];
          const isCurrentUrlisInActivePage = currentUrl.indexOf(url) !== -1;
          if (isCurrentUrlisInActivePage) {
            haveInActivePage = true;
            break;
          }
        }
      }

      return haveInActivePage;
    },
    [currentUrl]
  );

  const getItemAttrSubmenuToggle = useCallback((item: any) => {
    const toggleEvent = objectPath.get(item, 'toggle');
    if (toggleEvent === 'click') {
      return 'click';
    }

    if (objectPath.get(item, 'submenu.type') === 'tabs') {
      return 'tabs';
    }

    return 'hover';
  }, []);

  const isMenuItemIsActive = useCallback(
    (item: any): any => {
      if (checkInActivePage(item)) {
        return false;
      }

      if (checkActivePage(item)) {
        return true;
      }

      if (item.submenu) {
        return isMenuRootItemIsActive(item);
      }

      if (!item.page) {
        return false;
      }

      return currentUrl.indexOf(item.page) !== -1;
    },
    [checkInActivePage, checkActivePage, currentUrl]
  );

  const isMenuRootItemIsActive = useCallback(
    (item: any) => {
      if (checkInActivePage(item)) {
        return false;
      }

      if (checkActivePage(item)) {
        return true;
      }

      if (item.submenu && !item.submenu.type) {
        for (const _subItem of item.submenu) {
          const isActive = isMenuItemIsActive(_subItem);
          if (isActive) {
            return true;
          }
        }
        return false;
      }

      if (item.submenu && item.submenu.columns) {
        for (const _column of item.submenu.columns) {
          for (const _subItem of _column.items) {
            const isActive = isMenuItemIsActive(_subItem);
            if (isActive) {
              return true;
            }
          }
        }
        return false;
      }

      return false;
    },
    [checkInActivePage, checkActivePage, isMenuItemIsActive]
  );

  const getItemCssClasses = useCallback(
    (item: any) => {
      let classes = 'kt-menu__item';

      if (objectPath.get(item, 'submenu')) {
        classes += ' kt-menu__item--submenu';
      }

      const isActive = isMenuItemIsActive(item);
      if (!item.submenu && isActive) {
        classes += ' kt-menu__item--active kt-menu__item--here';
      }

      if (item.submenu && isActive) {
        classes += ' kt-menu__item--open kt-menu__item--here';
      }

      if (objectPath.get(item, 'resizer')) {
        classes += ' kt-menu__item--resize';
      }

      const menuType = objectPath.get(item, 'submenu.type') || 'classic';
      if (
        (objectPath.get(item, 'root') && menuType === 'classic') ||
        parseInt(objectPath.get(item, 'submenu.width'), 10) > 0
      ) {
        classes += ' kt-menu__item--rel';
      }

      const customClass = objectPath.get(item, 'custom-class');
      if (customClass) {
        classes += ' ' + customClass;
      }

      if (objectPath.get(item, 'icon-only')) {
        classes += ' kt-menu__item--icon-only';
      }

      return classes;
    },
    [isMenuItemIsActive]
  );

  const getItemMenuSubmenuClass = useCallback((item: any) => {
    let classes = '';

    const alignment = objectPath.get(item, 'alignment') || '';

    if (alignment) {
      classes += ' kt-menu__submenu--' + alignment;
    }

    const type = objectPath.get(item, 'type') || 'classic';
    if (type === 'classic') {
      classes += ' kt-menu__submenu--classic';
    }
    if (type === 'tabs') {
      classes += ' kt-menu__submenu--tabs';
    }
    if (type === 'mega') {
      if (objectPath.get(item, 'width')) {
        classes += ' kt-menu__submenu--fixed';
      }
    }

    if (objectPath.get(item, 'pull')) {
      classes += ' kt-menu__submenu--pull';
    }

    return classes;
  }, []);

  return (
    <li
      className={getItemCssClasses(item)}
      aria-haspopup='true'
      data-ktmenu-submenu-toggle={getItemAttrSubmenuToggle(item)}
      data-ktmenu-submenu-mode={item.mode}
      data-ktmenu-dropdown-toggle-class={item['dropdown-toggle-class']}
    >
      {/* if item has submenu */}
      {item.submenu && (
        <a
          className={`kt-menu__link ${clsx({
            'kt-menu__toggle': item.root || item.submenu,
          })}`}
          onClick={() => item.page && navigate(`/${item.page}`)}
        >
          <MenuItemInner item={item} />
          {rootArrowEnabled && (
            <>
              {item.submenu && item.root && (
                <>
                  <i className='kt-menu__hor-arrow la la-angle-down' />
                  <i className='kt-menu__ver-arrow la la-angle-right' />
                </>
              )}
            </>
          )}

          {item.submenu && !item.root && (
            <>
              <i className='kt-menu__hor-arrow la la-angle-right' />
              <i className='kt-menu__ver-arrow la la-angle-right' />
            </>
          )}
        </a>
      )}

      {/* if item hasn't submenu */}
      {!item.submenu && (
        <Link to={`/${item.page || ''}`} className='kt-menu__link'>
          <MenuItemInner item={item} />
        </Link>
      )}

      {/* if menu item has submenu child then recursively call new menu item component */}
      {item.submenu && (
        <div
          className={`kt-menu__submenu ${getItemMenuSubmenuClass(item)}`}
          style={{ width: item.submenu.width }}
        >
          <span
            className={`kt-menu__arrow ${clsx({
              'kt-menu__arrow--adjust': item.root,
            })}`}
          />

          {item.submenu && item.submenu.length && (
            <ul className='kt-menu__subnav'>
              {item.submenu.map((child: any, index: number) => {
                return (
                  <React.Fragment key={`hmenuListInner${index}`}>
                    <HMenuItem item={child} currentUrl={currentUrl} rootArrowEnabled={false} />
                  </React.Fragment>
                );
              })}
            </ul>
          )}

          {item.submenu.items && item.submenu.items.length && (
            <ul className='kt-menu__subnav'>
              {item.submenu.items.map((child: any, index: number) => {
                return (
                  <React.Fragment key={`hmenuListInner2${index}`}>
                    <HMenuItem item={child} currentUrl={currentUrl} rootArrowEnabled={false} />
                  </React.Fragment>
                );
              })}
            </ul>
          )}

          {item.submenu.type === 'mega' &&
            item.submenu.columns &&
            item.submenu.columns.length && (
              <div className='kt-menu__subnav'>
                <ul className='kt-menu__content'>
                  {item.submenu.columns.map((child: any, index: number) => {
                    return (
                      <React.Fragment key={`hmenuColumnInner${index}`}>
                        <HMenuColumn item={child} currentUrl={currentUrl} />
                      </React.Fragment>
                    );
                  })}
                </ul>
              </div>
            )}
        </div>
      )}
    </li>
  );
};

export default HMenuItem;
