import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Switch,
  TextField,
  Box
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Skeleton } from '@mui/lab';
import Autocomplete from '@mui/material/Autocomplete';
import moment from 'moment';
import { useIntl } from 'react-intl';

import clsx from 'clsx';
import ButtonWithLoader from '../../../../components/other/Buttons/ButtonWithLoader';
import NumberFormatCustom from '../../../../components/formComponents/NumberFormatCustom';
import { ICategoryItem } from '../../categories/interfaces';
import homeStyles from '../../../../constants/homeStyles';
import { IProductType, ITypeParameter } from '../../../../interfaces/productType';
import { useFormatMessage } from '../../../../hooks';
import { useStylesProductPage } from '../hooks/useStylesProductPage';
import CkEditorCustom from '../../../../components/formComponents/CkEditorCustom';
import { IUser } from '../../../../interfaces/user';
import { categoryLanguages } from '../../categories/utils/categoryLanguages';
import { ICountry } from '../../../auth/interfaces';
// import { useCreateChat } from '../../chats/chatsPage/hooks/useCreateChat';

interface IProps {
  id: string | undefined;
  formik: any;
  description: string | undefined;
  setDescEditorState: any;
  setDescEditorStateTranslation: any;
  editLoading: boolean;
  categories: ICategoryItem[] | undefined;
  types: IProductType[] | undefined;
  typeParams: ITypeParameter[] | undefined;
  paramsLoading: boolean;
  me: IUser;
  product: any;
  countries: ICountry[];
  setLanguage: (value: string) => void;
  paramLanguage: string;
  descEditorState: any;
  descEditorStateTranslation: any;
}

const levelsSubCategories = [1, 2, 3, 4, 5];

export const ProductForm: React.FC<IProps> = ({
  id,
  formik,
  description,
  editLoading,
  categories,
  types,
  typeParams,
  paramsLoading,
  setDescEditorState,
  me,
  product,
  countries,
  setDescEditorStateTranslation,
  paramLanguage,
  setLanguage,
  descEditorState,
  descEditorStateTranslation,
}) => {
  const fm = useFormatMessage();
  const navigate = useNavigate();
  const homeClasses = homeStyles();
  const classes1 = useStylesProductPage();
  const {classes} = classes1
  const intl = useIntl();

  const filterCountries = useMemo(() => {
    if (countries) {
      const newList: ICountry[] = [];
      countries.forEach(i => {
        if (i.id === 1 || i.id === 76) {
          newList.push({ ...i, favorite: true });
        } else {
          newList.push(i);
        }
      });
      return newList;
    }
    return [];
  }, [countries]);

  // const { createChatFetch } = useCreateChat();
  const [subCategories, setSubCategories] = useState<ICategoryItem[][]>([]);
  const [categoryTypes, setTypes] = useState<IProductType[]>([]);

  const {
    handleSubmit,
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    setFieldValue,
  } = formik;

  const productClass: 'physical' | 'digital' | 'service' = useMemo(() => {
    if (types && values.productTypeId) {
      const type = types.find(item => item.id?.toString() === values.productTypeId.toString());
      if (type) {
        return type.special_type || 'physical';
      }
    }
    return 'physical';
  }, [types, values.productTypeId]);

  useEffect(() => {
    const newCategoryTypes = categoryTypes.length > 0 ? categoryTypes : types;
    if (newCategoryTypes && values.productTypeId) {
      const type = newCategoryTypes.find(
        item => item.id?.toString() === values.productTypeId.toString()
      );
      if (!type) {
        setFieldValue('productTypeId', newCategoryTypes[0]?.id || '');
      }
    }
  }, [categoryTypes, types]);

  const getSubCategories = useCallback(
    (level: number) => {
      return subCategories[level];
    },
    [subCategories]
  );

  useEffect(() => {
    if (values.mainCategoryId && categories) {
      const newCategories = categories.find(
        item => item.id?.toString() === values.mainCategoryId.toString()
      );
      if (newCategories && newCategories.children && newCategories.children.length > 0) {
        if (
          subCategories[1] &&
          subCategories[1].length > 0 &&
          subCategories[1][0].id !== newCategories.children[0].id
        ) {
          levelsSubCategories.forEach(level => setFieldValue(`categoryId_${level}`, ''));
        }
        const subCategoriesLvl1 = newCategories.children;
        const subCategoriesLvl2: ICategoryItem | undefined =
          values.categoryId_1 &&
          subCategoriesLvl1?.length &&
          newCategories.children.find(
            item => item.id?.toString() === values.categoryId_1?.toString()
          );
        const subCategoriesLvl3: ICategoryItem | undefined =
          values.categoryId_2 &&
          subCategoriesLvl2?.children?.length &&
          subCategoriesLvl2.children.find(
            item => item.id?.toString() === values.categoryId_2?.toString()
          );
        const subCategoriesLvl4: ICategoryItem | undefined =
          values.categoryId_3 &&
          subCategoriesLvl3?.children?.length &&
          subCategoriesLvl3.children.find(
            item => item.id?.toString() === values.categoryId_3?.toString()
          );
        const subCategoriesLvl5: ICategoryItem | undefined =
          values.categoryId_4 &&
          subCategoriesLvl4?.children?.length &&
          subCategoriesLvl4.children.find(
            item => item.id?.toString() === values.categoryId_4?.toString()
          );
        if (subCategoriesLvl1.length === 0) {
          setFieldValue(`categoryId_1`, '');
          setFieldValue(`categoryId_2`, '');
          setFieldValue(`categoryId_3`, '');
          setFieldValue(`categoryId_4`, '');
          setFieldValue(`categoryId_5`, '');
        }
        if (subCategoriesLvl2?.children?.length === 0) {
          setFieldValue(`categoryId_2`, '');
          setFieldValue(`categoryId_3`, '');
          setFieldValue(`categoryId_4`, '');
          setFieldValue(`categoryId_5`, '');
        }
        if (subCategoriesLvl3?.children?.length === 0) {
          setFieldValue(`categoryId_3`, '');
          setFieldValue(`categoryId_4`, '');
          setFieldValue(`categoryId_5`, '');
        }
        if (subCategoriesLvl4?.children?.length === 0) {
          setFieldValue(`categoryId_4`, '');
          setFieldValue(`categoryId_5`, '');
        }
        if (subCategoriesLvl5?.children?.length === 0) {
          setFieldValue(`categoryId_5`, '');
        }
        setSubCategories(state => {
          const newArr = [...state];
          newArr[1] = subCategoriesLvl1.length ? subCategoriesLvl1 : [];
          newArr[2] = subCategoriesLvl2?.children?.length ? subCategoriesLvl2?.children : [];
          newArr[3] = subCategoriesLvl3?.children?.length ? subCategoriesLvl3?.children : [];
          newArr[4] = subCategoriesLvl4?.children?.length ? subCategoriesLvl4?.children : [];
          newArr[5] = subCategoriesLvl5?.children?.length ? subCategoriesLvl5?.children : [];
          return newArr;
        });
      } else {
        setSubCategories([]);
      }
    } else {
      setSubCategories([]);
    }
  }, [
    categories,
    values.mainCategoryId,
    values.categoryId_1,
    values.categoryId_2,
    values.categoryId_3,
    values.categoryId_4,
    values.categoryId_5,
  ]);

  // const newChat = useCallback((companyId?: string | number) => {
  //   if (companyId) {
  //     createChatFetch({ companyId });
  //   }
  // }, []);

  return (
    <>
      <form className={homeClasses.classes.form} onSubmit={handleSubmit} style={{ paddingTop: 0 }}>
        <TextField
          type='text'
          label={fm('COMPANY.FORM.COMPANY')}
          margin='normal'
          name='companyName'
          value={values.companyName || ''}
          variant='outlined'
          disabled
          InputLabelProps={{ shrink: true }}
        />

        <TextField
          select
          label={fm('PRODUCT.EDIT.CATEGORY')}
          margin='normal'
          name='mainCategoryId'
          value={values.mainCategoryId || ''}
          variant='outlined'
          onBlur={handleBlur('mainCategoryId')}
          onChange={e => {
            setFieldValue('mainCategoryId', e.target.value);
            setTypes([]);
          }}
          helperText={touched.mainCategoryId && errors.mainCategoryId}
          error={Boolean(touched.mainCategoryId && errors.mainCategoryId)}
        >
          {categories &&
            categories.map(item => (
              <MenuItem key={item.id?.toString()} value={item.id?.toString()}>
                {item.name}
              </MenuItem>
            ))}
        </TextField>

        {levelsSubCategories.map(level => {
          const subCategories = getSubCategories(level);
          if (subCategories && subCategories?.length > 0) {
            return (
              <TextField
                select
                label={`${fm('PRODUCT.EDIT.SUBCATEGORY')} ${level}`}
                margin='normal'
                name={`categoryId_${level}`}
                value={values[`categoryId_${level}`] || ''}
                variant='outlined'
                onBlur={handleBlur(`categoryId_${level}`)}
                onChange={e => {
                  setFieldValue(`categoryId_${level}`, e.target.value);
                  const types = subCategories.find(
                    item => item.id?.toString() === e.target.value
                  )?.types;
                  if (types) {
                    const newCategoryTypes = types.map(i => i.type);
                    setTypes(newCategoryTypes);
                  } else {
                    setTypes([]);
                  }
                }}
                disabled={!values.mainCategoryId}
                helperText={touched[`categoryId_${level}`] && errors.categoryId}
                error={Boolean(touched[`categoryId_${level}`] && errors.categoryId)}
              >
                {subCategories.length > 0 &&
                  subCategories.map(item => (
                    <MenuItem key={item.id?.toString()} value={item.id?.toString()}>
                      {item.name}
                    </MenuItem>
                  ))}
              </TextField>
            );
          }
          return <></>;
        })}

        <div className={classes.containerName}>
          <div className={classes.containerNameMainLang}>
            <TextField
              select
              margin='normal'
              label={intl.formatMessage({
                id: 'MAIN.LANGUAGE',
              })}
              value={intl.locale}
              variant='outlined'
              disabled
            >
              <MenuItem value={intl.locale}>
                {intl.formatMessage({
                  id: `CATEGORY.${intl.locale.toUpperCase()}.LANGUAGE`,
                })}
              </MenuItem>
            </TextField>
            <TextField
              type='text'
              label={fm('PRODUCT.EDIT.NAME')}
              margin='normal'
              name='name'
              value={values.name || ''}
              variant='outlined'
              onBlur={handleBlur('name')}
              onChange={handleChange}
              helperText={touched.name && errors.name}
              error={Boolean(touched.name && errors.name)}
            />
          </div>
          <div className={classes.containerNameLang}>
            <TextField
              select
              margin='normal'
              label={intl.formatMessage({
                id: 'AUTH.INPUT.LANG',
              })}
              name='language'
              value={paramLanguage}
              onChange={e => setLanguage(e.target.value)}
              variant='outlined'
            >
              {categoryLanguages
                .filter(lang => lang.code !== intl.locale)
                .map(lang => (
                  <MenuItem key={lang.code} value={lang.code}>
                    {intl.formatMessage({
                      id: `CATEGORY.${lang.code.toUpperCase()}.LANGUAGE`,
                    })}
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              type='text'
              label={`${fm('PRODUCT.EDIT.NAME')} (${paramLanguage.toUpperCase()})`}
              margin='normal'
              name={`translations.${paramLanguage}.name`}
              value={values.translations[paramLanguage]?.name || ''}
              variant='outlined'
              onChange={e =>
                setFieldValue(`translations.${paramLanguage}.name`, e.target.value)
              }
            />
          </div>
        </div>

        {productClass === 'physical' && (
          <TextField
            select
            label={fm('Units')}
            margin='normal'
            name='unit'
            value={values.unit || ''}
            variant='outlined'
            onBlur={handleBlur('unit')}
            onChange={handleChange}
            helperText={touched.unit && errors.unit}
            error={Boolean(touched.unit && errors.unit)}
          >
            <MenuItem value='шт'>{fm('PIECE')}</MenuItem>
            <MenuItem value='м.п.'>{fm('WEATHER.METER')}</MenuItem>
            <MenuItem value='м2'>{fm('SQUARE.METER')}</MenuItem>
            <MenuItem value='м3'>{fm('CUBIC.METER')}</MenuItem>
            <MenuItem value='кг'>{fm('KILOGRAM')}</MenuItem>
            <MenuItem value='л'>{fm('LITER')}</MenuItem>
          </TextField>
        )}

        <TextField
          type='text'
          label={fm('PRODUCT.EDIT.PRICE')}
          margin='normal'
          name='price'
          value={values.price || ''}
          variant='outlined'
          onBlur={handleBlur('price')}
          onChange={handleChange}
          helperText={touched.price && errors.price}
          error={Boolean(touched.price && errors.price)}
          InputProps={{
            inputComponent: NumberFormatCustom as any,
          }}
        />

        <FormControlLabel
          control={
            <Switch
              className={homeClasses.classes.switch}
              size='small'
              name='active'
              checked={values.active || false}
              onChange={handleChange}
            />
          }
          label={fm('PRODUCT.EDIT.STATUS')}
        />

        {me.is_admin && (
          <FormControlLabel
            control={
              <Switch
                className={homeClasses.classes.switch}
                size='small'
                name='favorite'
                checked={values.favorite || false}
                onChange={handleChange}
                disabled={!me?.is_admin}
              />
            }
            label={fm('PRODUCT.EDIT.FAVORITE')}
          />
        )}

        <InputLabel shrink>{`${fm(
          'PRODUCT.TABLE.DESCRIPTION'
        )} (${intl.locale.toUpperCase()})`}</InputLabel>
        <CkEditorCustom html={description || ''} setEditorStateCallback={setDescEditorState} />
        <InputLabel className={classes.labelDesc} shrink>{`${fm(
          'PRODUCT.TABLE.DESCRIPTION'
        )} (${paramLanguage.toUpperCase()})`}</InputLabel>
        <CkEditorCustom
          html={values.translations[paramLanguage]?.description || ''}
          setEditorStateCallback={setDescEditorStateTranslation}
        />

        {types && types.length > 0 && (
          <TextField
            select
            margin='normal'
            label={fm('PRODUCT.EDIT.TYPE')}
            value={values.productTypeId || ''}
            onChange={handleChange}
            name='productTypeId'
            variant='outlined'
            error={Boolean(touched.productTypeId && errors.productTypeId)}
            className={classes.labelDesc}
          >
            {(categoryTypes.length > 0 ? categoryTypes : types).map(option => (
              <MenuItem key={option.id?.toString()} value={option.id?.toString() || ''}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        )}

        <div className={classes.containerStock}>
          {productClass === 'physical' && (
            <TextField
              type='text'
              label={fm('PRODUCT.EDIT.WEIGHT')}
              margin='normal'
              name='weight'
              value={values.weight}
              variant='outlined'
              onBlur={handleBlur('weight')}
              onChange={handleChange}
              helperText={touched.weight && errors.weight}
              error={Boolean(touched.weight && errors.weight)}
              InputProps={{
                inputComponent: NumberFormatCustom as any,
              }}
              style={{ width: '32.5%' }}
            />
          )}
          {productClass === 'physical' && (
            <TextField
              type='date'
              label={fm('PRODUCT.EDIT.STOCK.EXPIRATION')}
              margin='normal'
              name='expiration_date'
              value={
                values.expiration_date
                  ? moment(values.expiration_date).format('YYYY-MM-DD')
                  : ''
              }
              variant='outlined'
              onBlur={handleBlur('expiration_date')}
              onChange={handleChange}
              helperText={touched.expiration_date && errors.expiration_date}
              error={Boolean(touched.expiration_date && errors.expiration_date)}
              style={{
                width: '32.5%',
              }}
              InputLabelProps={{ shrink: true }}
            />
          )}
          {productClass !== 'service' && (
            <TextField
              type='text'
              label={fm('PRODUCT.EDIT.STOCK.BARCODE')}
              margin='normal'
              name='barcode'
              value={values.barcode}
              variant='outlined'
              onBlur={handleBlur('barcode')}
              onChange={handleChange}
              helperText={touched.barcode && errors.barcode}
              error={Boolean(touched.barcode && errors.barcode)}
              InputProps={{
                inputComponent: NumberFormatCustom as any,
              }}
              style={{ width: productClass !== 'physical' ? '100%' : '32.5%' }}
            />
          )}
        </div>

        {productClass === 'physical' && (
          <div className={classes.containerStock}>
            <TextField
              type='text'
              label={fm('PRODUCT.EDIT.LENGTH')}
              margin='normal'
              name='length'
              value={values.length}
              variant='outlined'
              onBlur={handleBlur('length')}
              onChange={handleChange}
              helperText={touched.length && errors.length}
              error={Boolean(touched.length && errors.length)}
              InputProps={{
                inputComponent: NumberFormatCustom as any,
              }}
              style={{ width: '32.5%' }}
            />
            <TextField
              type='text'
              label={fm('PRODUCT.EDIT.WIDTH')}
              margin='normal'
              name='width'
              value={values.width}
              variant='outlined'
              onBlur={handleBlur('width')}
              onChange={handleChange}
              helperText={touched.width && errors.width}
              error={Boolean(touched.width && errors.width)}
              InputProps={{
                inputComponent: NumberFormatCustom as any,
              }}
              style={{ width: '32.5%' }}
            />
            <TextField
              type='text'
              label={fm('PRODUCT.EDIT.HEIGHT')}
              margin='normal'
              name='height'
              value={values.height}
              variant='outlined'
              onBlur={handleBlur('height')}
              onChange={handleChange}
              helperText={touched.height && errors.height}
              error={Boolean(touched.height && errors.height)}
              InputProps={{
                inputComponent: NumberFormatCustom as any,
              }}
              style={{ width: '32.5%' }}
            />
          </div>
        )}

        {productClass !== 'service' && (
          <TextField
            type='text'
            label={fm('PRODUCT.EDIT.STOCK.QUANTITY')}
            margin='normal'
            name='stockQuantity'
            value={values.stockQuantity || null}
            variant='outlined'
            onChange={handleChange}
            helperText={touched.stockQuantity && errors.stockQuantity}
            error={Boolean(touched.stockQuantity && errors.stockQuantity)}
            inputProps={{ readOnly: true }}
          />
        )}

        <TextField
          type='text'
          label={fm('PRODUCT.EDIT.AVAILABLE.QUANTITY')}
          margin='normal'
          name='stockQuantity'
          value={product?.stock_info?.available_quantity || null}
          variant='outlined'
          onChange={handleChange}
          inputProps={{ readOnly: true }}
        />

        {paramsLoading
          ? Array.from(Array(3)).map((_, idx) => (
              <Skeleton
                key={idx}
                width='100%'
                height={86}
                animation='wave'
                style={{ marginTop: -5, marginBottom: -5 }}
              />
            ))
          : typeParams?.map(item => {
              if (item.type === 'number') {
                return (
                  <TextField
                    type='text'
                    label={item.names?.[intl.locale] || item.name}
                    margin='normal'
                    name={`parameter${item.id}`}
                    value={values[`parameter${item.id}`] || ''}
                    variant='outlined'
                    onBlur={handleBlur(`parameter${item.id}`)}
                    onChange={handleChange(`parameter${item.id}`)}
                    disabled={item.admin_only && !me.is_admin}
                    helperText={
                      touched[`parameter${item.id}`] && errors[`parameter${item.id}`]
                    }
                    error={Boolean(
                      touched[`parameter${item.id}`] && errors[`parameter${item.id}`]
                    )}
                    key={item.id}
                    InputProps={{
                      inputComponent:
                        item.name !== 'Год' ? (NumberFormatCustom as any) : undefined,
                    }}
                  />
                );
              }
              if (item.type === 'enum') {
                return (
                  <>
                    <Autocomplete
                      options={
                        item.parameter_enum_values?.sort((a, b) =>
                          // eslint-disable-next-line no-nested-ternary
                          a.favorite === b.favorite ? 0 : a.favorite ? -1 : 1
                        ) || []
                      }
                      autoHighlight
                      style={{ marginTop: 16 }}
                      value={item.parameter_enum_values?.find(
                        i => i.value === values[`parameter${item.id}`]
                      )}
                      onChange={(_event: any, newValue) => {
                        setFieldValue(`parameter${item.id}`, newValue?.value || '');
                      }}
                      groupBy={i =>
                        intl.formatMessage({
                          id: i.favorite ? 'POPULAR.VARIANTS' : 'OTHER.VARIANTS',
                        })
                      }
                      noOptionsText={intl.formatMessage({ id: 'CATEGORIES.TABLE.EMPTY' })}
                      loadingText={intl.formatMessage({ id: 'COMMON.LOADING' })}
                      // disableClearable
                      getOptionLabel={option =>
                        option.value ? option.translations?.[intl.locale] || option.value : ''
                      }
                      renderOption={(props, option) => (
                        <Box
                          component='li'
                          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                          {...props}
                          value={option.value}
                        >
                          {option.translations?.[intl.locale] || option.value}
                        </Box>
                      )}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label={item.names?.[intl.locale] || item.name}
                          variant='outlined'
                          inputProps={{
                            ...params.inputProps,
                            style: { fontFamily: 'Open Sans' },
                            autoComplete: 'new-password', // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                    {/* <TextField
                      select
                      type='text'
                      label={item.names?.[intl.locale] || item.name}
                      margin='normal'
                      name={`parameter${item.id}`}
                      value={values[`parameter${item.id}`] || ''}
                      variant='outlined'
                      onBlur={handleBlur(`parameter${item.id}`)}
                      onChange={handleChange}
                      disabled={item.admin_only && !me.is_admin}
                      helperText={
                        touched[`parameter${item.id}`] && errors[`parameter${item.id}`]
                      }
                      error={Boolean(
                        touched[`parameter${item.id}`] && errors[`parameter${item.id}`]
                      )}
                      key={item.id}
                    >
                      {item.enum &&
                        item.enum.map((option, index) => (
                          <MenuItem key={option} value={option || ''}>
                            {item.translations?.[intl.locale]?.[`parameter${index}`] || option}
                          </MenuItem>
                        ))}
                    </TextField> */}
                  </>
                );
              }

              if (item.type === 'string') {
                return (
                  <div className={classes.containerName} key={item.id}>
                    <TextField
                      type='text'
                      variant='outlined'
                      margin='normal'
                      label={`${
                        item.names?.[intl.locale] || item.name
                      } (${intl.locale.toUpperCase()})`}
                      name={`parameter${item.id}`}
                      value={values[`parameter${item.id}`] || ''}
                      onBlur={handleBlur(`parameter${item.id}`)}
                      onChange={handleChange(`parameter${item.id}`)}
                      disabled={item.admin_only && !me.is_admin}
                      helperText={
                        touched[`parameter${item.id}`] && errors[`parameter${item.id}`]
                      }
                      error={Boolean(
                        touched[`parameter${item.id}`] && errors[`parameter${item.id}`]
                      )}
                      className={classes.fieldName}
                    />
                    <TextField
                      type='text'
                      variant='outlined'
                      margin='normal'
                      label={`${
                        item.names?.[intl.locale] || item.name
                      } (${paramLanguage.toUpperCase()})`}
                      name={`translations.${paramLanguage}.parameter${item.id}`}
                      value={values.translations[paramLanguage]?.[`parameter${item.id}`] || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={item.admin_only && !me.is_admin}
                      className={classes.containerNameLang}
                    />
                  </div>
                );
              }

              if (item.type === 'text') {
                return (
                  <>
                    <TextField
                      type='text'
                      label={`${
                        item.names?.[intl.locale] || item.name
                      } (${intl.locale.toUpperCase()})`}
                      margin='normal'
                      name={`parameter${item.id}`}
                      value={values[`parameter${item.id}`] || ''}
                      disabled={item.admin_only && !me.is_admin}
                      helperText={
                        touched[`parameter${item.id}`] && errors[`parameter${item.id}`]
                      }
                      error={Boolean(
                        touched[`parameter${item.id}`] && errors[`parameter${item.id}`]
                      )}
                      variant='outlined'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      multiline
                      rows={4}
                    />
                    <TextField
                      type='text'
                      label={`${
                        item.names?.[intl.locale] || item.name
                      } (${paramLanguage.toUpperCase()})`}
                      margin='normal'
                      disabled={item.admin_only && !me.is_admin}
                      name={`translations.${paramLanguage}.parameter${item.id}`}
                      value={values.translations[paramLanguage]?.[`parameter${item.id}`] || ''}
                      variant='outlined'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      multiline
                      rows={4}
                    />
                  </>
                );
              }

              if (item.type === 'multiple_enum') {
                return (
                  <>
                    <Autocomplete
                      options={
                        item.parameter_enum_values?.sort((a, b) =>
                          // eslint-disable-next-line no-nested-ternary
                          a.favorite === b.favorite ? 0 : a.favorite ? -1 : 1
                        ) || []
                      }
                      multiple
                      autoHighlight
                      style={{ marginTop: 16 }}
                      value={
                        values[`parameter${item.id}`]
                          ? values[`parameter${item.id}`].map((i: string) => {
                              const find = item.parameter_enum_values?.find(
                                el => el.value === i
                              );
                              return find;
                            })
                          : []
                      }
                      onChange={(_event: any, newValue) => {
                        newValue &&
                          setFieldValue(
                            `parameter${item.id}`,
                            newValue.map(i => i?.value)
                          );
                      }}
                      groupBy={i =>
                        intl.formatMessage({
                          id: i?.favorite ? 'POPULAR.VARIANTS' : 'OTHER.VARIANTS',
                        })
                      }
                      noOptionsText={intl.formatMessage({ id: 'CATEGORIES.TABLE.EMPTY' })}
                      loadingText={intl.formatMessage({ id: 'COMMON.LOADING' })}
                      disableClearable
                      getOptionLabel={option =>
                        option && option.value ? (option.translations?.[intl.locale] || option.value) : ''
                      }                      
                      renderOption={(props, option) => (
                        <Box
                          component='li'
                          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                          {...props}
                          value={option?.value || ''}
                        >
                          {option.translations?.[intl.locale] || option.value}
                        </Box>
                      )}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label={item.names?.[intl.locale] || item.name}
                          variant='outlined'
                          inputProps={{
                            ...params.inputProps,
                            style: { fontFamily: 'Open Sans' },
                            autoComplete: 'new-password', // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  </>
                );
              }

              if (item.type === 'country') {
                return (
                  <>
                    <Autocomplete
                      id='country-select-demo'
                      options={filterCountries.sort((a, b) =>
                        // eslint-disable-next-line no-nested-ternary
                        a.favorite === b.favorite ? 0 : a.favorite ? -1 : 1
                      )}
                      autoHighlight
                      style={{ marginTop: 16 }}
                      value={filterCountries.find(
                        i => i.name === values[`parameter${item.id}`]
                      )}
                      onChange={(_event: any, newValue) => {
                        newValue && setFieldValue(`parameter${item.id}`, newValue.name);
                      }}
                      groupBy={i =>
                        intl.formatMessage({ id: i.favorite ? 'FAVORITES' : 'OTHER' })
                      }
                      noOptionsText={intl.formatMessage({ id: 'CATEGORIES.TABLE.EMPTY' })}
                      loadingText={intl.formatMessage({ id: 'COMMON.LOADING' })}
                      disableClearable
                      getOptionLabel={option =>
                        intl.locale === 'ru' ? option.ru_name : option.en_name
                      }
                      renderOption={(props, option) => (
                        <Box
                          component='li'
                          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                          {...props}
                          value={option.id}
                        >
                          {intl.locale === 'ru' ? option.ru_name : option.en_name} +
                          {option.code}
                        </Box>
                      )}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label={item.names?.[intl.locale] || item.name}
                          variant='outlined'
                          inputProps={{
                            ...params.inputProps,
                            style: { fontFamily: 'Open Sans' },
                            autoComplete: 'new-password', // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                    {/* <TextField
                      select
                      type='text'
                      label={item.names?.[intl.locale] || item.name}
                      margin='normal'
                      color='secondary'
                      name={`parameter${item.id}`}
                      value={values[`parameter${item.id}`] || ''}
                      variant='outlined'
                      onBlur={handleBlur(`parameter${item.id}`)}
                      disabled={item.admin_only && !me.is_admin}
                      onChange={handleChange}
                      helperText={
                        touched[`parameter${item.id}`] && errors[`parameter${item.id}`]
                      }
                      error={Boolean(
                        touched[`parameter${item.id}`] && errors[`parameter${item.id}`]
                      )}
                      key={item.id}
                    >
                      {countries?.map((item: any) => (
                        <MenuItem key={item.id} value={item.name}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField> */}
                  </>
                );
              }
            })}

        <div className={classes.actions}>
          <Button
            onClick={() => navigate(-1)}
            className={classes.buttons}
            variant='outlined'
            color='primary'
          >
            {fm('CATEGORY.BUTTON.CANCEL')}
          </Button>
          <ButtonWithLoader marginLeft={10}  disabled={editLoading} loading={editLoading}>
            {id ? fm('COMMON.BUTTON.SAVE') : fm('PRODUCT.BUTTON.ADD')}
          </ButtonWithLoader>
          <Button
            disabled={!product?.company?.id}
            onClick={() => {
              // newChat(product?.company?.id);
              navigate(`/chats`, {
                state: {
                  createCompanyChat: product?.company,
                },
              });
            }}
            className={
              product?.company?.id
                ? clsx({
                    'btn btn-elevate btn-primary': true,
                    'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': editLoading,
                    [classes.isLoading]: editLoading,
                  })
                : ''
            }
            variant='contained'
            color='primary'
            style={{ marginLeft: 10 }}
          >
            {fm('CATEGORY.BUTTON.CHAT')}
          </Button>
          <Button
            style={{ marginLeft: '10px' }}
            onClick={() =>
              navigate(`/products/create`, {
                replace: true,
                state: {
                  values,
                  product,
                  descEditorState: descEditorState?.getData() || '',
                  descEditorStateTranslation: descEditorStateTranslation?.getData() || '',
                },
              })
            }
            className={classes.buttons}
            variant='outlined'
            color='primary'
          >
            {fm('COPY')}
          </Button>
        </div>
      </form>
    </>
  );
};
