/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import axios from 'axios';
import { setupAxios } from './_base';
import store, { persistor } from './app/store/store';
import App from './App';

import './index.scss'; // Standard version

import './_base/_assets/plugins/line-awesome/css/line-awesome.css';
import './_base/_assets/plugins/flaticon/flaticon.css';
import './_base/_assets/plugins/flaticon2/flaticon.css';

import ChatSocket from './app/utils/socket';

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
export const { PUBLIC_URL } = process.env;

/**
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
// /* const mock = */ mockAxios(axios);

/**
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios, store, ChatSocket);

const root = createRoot(document.getElementById('root'));

root.render(<App store={store} persistor={persistor} basename={PUBLIC_URL} />);
