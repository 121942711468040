// компонент для управления картинками без запросов к серверу,
// используется в создание (например) товаров, потому что
// еще нет сущности товара на сервере, все фото храняться в массиве
// который отправляется в запросе создания
import React from 'react';

import { useIntl } from 'react-intl';
import FilesDropzone from '../../formComponents/FilesDropzone';
import { useStyles } from '../UploadImages/hooks/useStyles';
import { ImagesGalleryOffline } from './components/ImagesGalleryOffile';
import { MainImgOffline } from './components/MainImgOffline';

interface IProps {
  images: any | undefined;
  setImages: (files: any) => void;
  withCrop?: boolean;
  avaliableNumberOfFilesToUpload?: number;
  withGallery?: boolean;
  once?: boolean;
  disableMain?: boolean;
  containerStyle?: React.CSSProperties;
}

export const UploadImagesOffline: React.FC<IProps> = ({
  images,
  setImages,
  withCrop = false,
  avaliableNumberOfFilesToUpload = 1,
  withGallery = false,
  once,
  disableMain,
  containerStyle,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.container} style={containerStyle}>
      <MainImgOffline images={images} />
      {withGallery && (
        <ImagesGalleryOffline
          images={images}
          setImages={setImages}
          disableMain={disableMain}
        />
      )}
      <FilesDropzone
        uploadFiles={(newFiles: any) => {
          if (once) {
            setImages(newFiles);
          } else {
            setImages((state: any) => [...state, ...newFiles]);
          }
        }}
        avaliableNumberOfFilesToUpload={avaliableNumberOfFilesToUpload}
        title={intl.formatMessage({ id: 'COMMON.PHOTO.LOAD' })}
        withCrop={withCrop}
      />
    </div>
  );
};
