import React, { useCallback, useState, useLayoutEffect, useEffect } from 'react';
import { useIntl } from 'react-intl';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/Delete';
import EditOutlinedIcon from '@mui/icons-material/Edit';
import {
  Typography,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  CardContent,
  Theme,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Row, Col } from 'react-bootstrap';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import AlertDialog from '../../../components/other/Dialog/AlertDialog';
import { SHeadTableCell } from '../../../components/styledComponents/Tables';
import Preloader from '../../../components/other/Preloader/Preloader';
import homeStyles from '../../../constants/homeStyles';
import { ButtonWithLoader } from '../../../components/ui/Buttons';
import { useGetGroups } from './hooks/useGetGroups';
import { IGroup } from '../../../interfaces/groups';
import { useDeleteGroup } from './hooks/useDeleteGroup';

const GroupsList: React.FC = () => {
  const [deleteId, setDeleteId] = useState<number | undefined>(-1);
  const [isAlertOpen, setAlertOpen] = useState(false);

  const homeClasses = homeStyles();
  const classes = useStyles();
  const intl = useIntl();
  const navigate = useNavigate();
  const [fetchGroups, loadingGroups, groups] = useGetGroups();
  const { handleDelete: deleteGroup, successDelete } = useDeleteGroup();

  useLayoutEffect(() => {
    fetchGroups();
  }, []);

  useEffect(() => {
    successDelete && fetchGroups();
  }, [successDelete]);

  const handleOpenDeleteDialog = useCallback((id: number | undefined) => {
    setDeleteId(id);
    setAlertOpen(true);
  }, []);

  const deleteAction = useCallback(() => {
    setAlertOpen(false);
    if (deleteId) {
      deleteGroup(deleteId);
    }
  }, [deleteId]);

  const goToCreateProductType = useCallback(() => {
    navigate('/groups/create');
  }, [navigate]);

  if (!groups || loadingGroups) return <Preloader />;

  return (
    <div style={{ maxWidth: 1440, width: '100%', margin: '0 auto' }}>
      <AlertDialog
        open={isAlertOpen}
        message={intl.formatMessage({ id: 'GROUP.DELETE.TEXT' })}
        okText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })}
        handleClose={() => {
          setAlertOpen(false);
        }}
        handleAgree={deleteAction}
      />

      <ButtonWithLoader onPress={() => goToCreateProductType()}>
        {intl.formatMessage({ id: 'GROUP.ADD' })}
      </ButtonWithLoader>

      <Row>
        <Col>
          {!groups || !groups.length ? (
            <Typography className={classes.empty} component='h5' variant='h5'>
              {intl.formatMessage({ id: 'COMMON.LIST.EMPTY' })}
            </Typography>
          ) : (
            <>
              <Card className={classes.card}>
                <CardContent className={homeClasses.classes.tableContainer}>
                  <Table aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <SHeadTableCell>
                          {intl.formatMessage({ id: 'PRODUCT.TABLE.ID' })}
                        </SHeadTableCell>
                        <SHeadTableCell>
                          {intl.formatMessage({ id: 'PRODUCT.TABLE.NAME' })}
                        </SHeadTableCell>
                        <SHeadTableCell align='right'>
                          {intl.formatMessage({ id: 'PRODUCT.TABLE.ACTIONS' })}
                        </SHeadTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {groups.map((item: IGroup) => (
                        <TableRow key={item.id}>
                          <TableCell component='th' scope='row'>
                            {item.id || '-'}
                          </TableCell>
                          <TableCell>
                            <Link
                              component={RouterLink}
                              to={`/products/types/edit/${item.id}`}
                              className={classes.buttons}
                            >
                              {item.name || '-'}
                            </Link>
                          </TableCell>
                          <TableCell align='right'>
                            <IconButton
                              color='primary'
                              aria-label='previous'
                              onClick={() => navigate(`/groups/edit/${item.id}`)}
                            >
                              <EditOutlinedIcon className={classes.icon} />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                handleOpenDeleteDialog(item.id);
                              }}
                            >
                              <DeleteOutlineOutlinedIcon
                                className={classes.icon}
                                color='error'
                              />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    empty: {
      marginBottom: 20,
      marginTop: 20,
    },
    buttons: {
      marginRight: theme.spacing(3),
    },
    card: {
      marginTop: 25,
      marginBottom: theme.spacing(3),
      borderRadius: 4,
    },
    categoryTree: {
      maxWidth: 400,
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    img: {
      width: 50,
      height: 50,
    },
    icon: {
      width: 22,
      height: 22,
    },
  })
);

export default GroupsList;
