import React, { useMemo } from 'react';
import Select from 'react-select';
import { InputLabel, TextField, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import { ITypeParameter } from '../../../../interfaces/productType';
import { useStylesSelectFiltepType } from '../hooks/useStyles';
import { useFormatMessage } from '../../../../hooks';
import SelectFilterRange from './SelectFilterRange';
import NumberFormatCustom from '../../../../components/NumberFormatCustom';
import ProductIcons from '../../../../components/ui/ProductIcons';

interface IProps {
  onChange: any;
  onChangeParam: (value: {
    parameter: number;
    values: any;
    type: string;
    name: string;
    data?: string[];
  }) => void;
  productTypeFilters: Partial<ITypeParameter>[];
  getFilters: any;
  handleChange: any;
  productTypes: any;
  selected: any;
  setSelected: any;
  deleteFilteData?: (key: number, deleteSearchParam?: boolean) => void;
  setFilterData: React.Dispatch<any>;
  parameters?: any;
  setParameters?: any;
  filterData: any;
  priceFrom: string | number;
  priceTo: string | number;
  setPriceFrom: React.Dispatch<React.SetStateAction<string | number>>;
  setPriceTo: React.Dispatch<React.SetStateAction<string | number>>;
}

const mapList = (item: any, locale: string) => ({
  value: item.id,
  label: item.names?.[locale] || item.name,
});

export const selectStyles = {
  container: (provided: any) => ({
    ...provided,
    marginBottom: 16,
    width: '100%',
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    zIndex: 999,
    display: state.selectProps.isSearchable ? provided.display : 'none',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    borderColor: state.isFocused ? 'rgba(120,220,120,1)' : '',
    backgroundColor: '#f5f8fa',
    border: 'none !important',
    opacity: state.isDisabled ? 0.5 : 1,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'rgba(54, 159, 247,0.5)' : '#fff',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#4c4c4c',
    fontWeight: 600,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontWeight: 600,
  }),
  dropdownIndicator: (provided: any, state: any) => {
    return {
      ...provided,
      display: state.selectProps.isSearchable ? provided.display : 'none',
    };
  },
};

const SelectFilterType: React.FC<IProps> = ({
  onChange,
  productTypeFilters,
  getFilters,
  productTypes,
  handleChange,
  selected,
  setSelected,
  deleteFilteData,
  setFilterData,
  setParameters,
  filterData,
  priceFrom,
  priceTo,
  setPriceFrom,
  setPriceTo,
}) => {
  const classes1 = useStylesSelectFiltepType();
  const {classes} = classes1
  const fm = useFormatMessage();
  const intl = useIntl();

  const sortFilterProductType = useMemo(
    () =>
      productTypeFilters
        ?.sort((a, b) => {
          if (a.type === 'enum' && b.type !== 'enum') {
            return -1;
          }
          if (a.type !== 'enum' && b.type === 'enum') {
            return 1;
          }
          return 0;
        })
        .filter(
          el => el?.type === 'enum' || el.type === 'number' || el?.type === 'multiple_enum'
        ),
    [productTypeFilters]
  );

  return (
    <>
      <Typography className={classes.label}>{fm('FILTERS')}</Typography>
      <Select
        placeholder={fm('PRODUCT.TYPES.ALL')}
        styles={selectStyles}
        value={{ ...selected }}
        onChange={event => {
          getFilters({ id: event?.value, filters: true });
          onChange({ id: event?.value });
          setSelected(event);
          setFilterData({});
          setParameters({});
        }}
        options={
          productTypes ? productTypes.map((item: any) => mapList(item, intl.locale)) : []
        }
        noOptionsMessage={() => 'Типы не найдены'}
      />
      {sortFilterProductType?.length ? (
        <>
          {sortFilterProductType?.map((el, index) =>
            // @ts-ignore
            el?.type === 'enum' || el?.type === 'multiple_enum' ? (
              <>
                {el.parameter_enum_values && el.parameter_enum_values.find(i => i.image) ? (
                  <div className={classes.containerIcons}>
                    {el.parameter_enum_values?.map(i => (
                      <div
                        key={i.value}
                        onClick={() => {
                          const values = filterData[el.id!]?.values || [];
                          const exist = values.find((val: string) => val === i.value);
                          if (exist) {
                            const newValues = values.filter((val: string) => val !== i.value);
                            if (newValues.length) {
                              handleChange(Number(el.id), newValues);
                            } else {
                              deleteFilteData && el.id && deleteFilteData(el.id, true);
                            }
                          } else {
                            handleChange(Number(el.id), [...values, i.value]);
                          }
                        }}
                        className={classes.containerIcon}
                        style={{
                          backgroundColor: filterData[el.id!]?.values?.find(
                            (val: string) => val === i.value
                          )
                            ? 'rgba(54,159,247,0.1)'
                            : '#fff',
                        }}
                      >
                        <ProductIcons
                          src={i.image || ''}
                          nameView
                          title={i.translations?.[intl.locale] || i.value}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <>
                    <Select
                      placeholder={el.names?.[intl.locale] || el.name}
                      styles={selectStyles}
                      isMulti
                      key={el?.id}
                      value={
                        filterData[el.id!]
                          ? filterData[el.id!].values.map((i: string) => ({
                              label: i,
                              value: i,
                            }))
                          : undefined
                      }
                      defaultValue={
                        filterData[el.id!]?.values?.map((item: any) => ({
                          value: item,
                          label: item,
                        })) || null
                      }
                      onChange={event => {
                        if (event.length) {
                          handleChange(
                            Number(el.id),
                            event.map(item => item.value)
                          );
                        } else {
                          deleteFilteData && el.id && deleteFilteData(el.id, true);
                        }
                      }}
                      options={[
                        ...(el.parameter_enum_values?.find(i => i.favorite)
                          ? [
                              {
                                label: fm('POPULAR.VARIANTS'),
                                options: el.parameter_enum_values
                                  ?.filter(i => !!i.favorite)
                                  .map(item => ({
                                    value: item.value,
                                    label: item.translations?.[intl.locale] || item.value,
                                  })),
                              },
                            ]
                          : []),
                        ...(el.parameter_enum_values?.find(i => !i.favorite)
                          ? [
                              {
                                label: fm('OTHER.VARIANTS'),
                                options:
                                  el.parameter_enum_values
                                    ?.filter(i => !i.favorite)
                                    .map(item => ({
                                      value: item.value,
                                      label: item.translations?.[intl.locale] || item.value,
                                    })) || [],
                              },
                            ]
                          : []),
                      ]}
                      noOptionsMessage={() => `${el.name} не найден (-а)`}
                    />
                    {sortFilterProductType[index + 1]?.type === 'number' && (
                      <div className={classes.containerFilter}>
                        <InputLabel className={classes.label} style={{ marginLeft: 8 }} shrink>
                          {fm('PRODUCT.FILTER.PRICE')}, ₽
                        </InputLabel>
                        <div className={classes.priceFilterWrap}>
                          <TextField
                            style={{ margin: '0 8px' }}
                            placeholder={fm('PRODUCT.FILTER.FROM')}
                            name='price_from'
                            value={priceFrom || ''}
                            onChange={e => setPriceFrom(e.target.value)}
                            InputProps={{
                              inputComponent: NumberFormatCustom as any,
                              inputMode: 'numeric',
                              className: classes.input,
                            }}
                            variant='outlined'
                          />
                          <TextField
                            style={{ margin: '0 8px' }}
                            placeholder={fm('PRODUCT.FILTER.TO')}
                            name='price_to'
                            value={priceTo || ''}
                            onChange={e => setPriceTo(e.target.value)}
                            InputProps={{
                              inputComponent: NumberFormatCustom as any,
                              inputMode: 'numeric',
                              className: classes.input,
                            }}
                            variant='outlined'
                          />
                          <button type='submit' style={{ display: 'none' }} />
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            ) : (
              el?.type === 'number' && (
                <SelectFilterRange
                  key={el.id}
                  productTypeFilter={el}
                  handleChange={handleChange}
                  deleteFilteData={deleteFilteData}
                  defaultFilter={filterData[el.id!]}
                />
              )
            )
          )}
        </>
      ) : (
        <div className={classes.containerFilter}>
          <InputLabel className={classes.label} style={{ marginLeft: 8 }} shrink>
            {fm('PRODUCT.FILTER.PRICE')}, ₽
          </InputLabel>
          <div className={classes.priceFilterWrap}>
            <TextField
              style={{ marginRight: 10 }}
              placeholder={fm('PRODUCT.FILTER.FROM')}
              name='price_from'
              value={priceFrom || ''}
              onChange={e => setPriceFrom(e.target.value)}
              InputProps={{
                inputComponent: NumberFormatCustom as any,
                inputMode: 'numeric',
                className: classes.input,
              }}
              variant='outlined'
            />
            <TextField
              placeholder={fm('PRODUCT.FILTER.TO')}
              name='price_to'
              value={priceTo || ''}
              onChange={e => setPriceTo(e.target.value)}
              InputProps={{
                inputComponent: NumberFormatCustom as any,
                inputMode: 'numeric',
                className: classes.input,
              }}
              variant='outlined'
            />
            <button type='submit' style={{ display: 'none' }} />
          </div>
        </div>
      )}
    </>
  );
};

export default SelectFilterType;
