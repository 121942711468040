import React, { useEffect, useLayoutEffect, useMemo } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { Card, TextField, Button, MenuItem, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';

import ButtonWithLoader from '../../../components/other/Buttons/ButtonWithLoader';
import Preloader from '../../../components/other/Preloader/Preloader';

import homeStyles from '../../../constants/homeStyles';
import { IAppState } from '../../../store/rootDuck';
import { actions as profileActions } from '../../../store/ducks/profile.duck';
import { actions as promocodesActions } from '../../../store/ducks/promocodes.duck';

import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';
import { IPromocode } from '../../../interfaces/promocodes';
import { useStylesPromocodeEdit } from './hooks/useStyles';
import { usePromocodeStat } from './hooks/usePromocodeStat';

const getInitialValues = (promocode: IPromocode | null) => ({
  code: promocode ? promocode.code : '',
  amount: promocode ? promocode.amount : 0,
  active: promocode ? promocode.active : 0,
});

const PromocodeEditPage: React.FC<TPropsFromRedux> = ({
  loadingMe,
  promocode,
  fetchMe,
  fetch,
  loading,
  clearEdit,
  add,
  edit,
  editLoading,
  editSuccess,
  editError,
  me,
}) => {
  const classes = useStylesPromocodeEdit();
  const homeClasses = homeStyles();
  const { promocodeId } = useParams();
  const navigate = useNavigate();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [fetchStat, successStat, loadingStat] = usePromocodeStat();
  const countries = useMemo(() => successStat && Object.keys(successStat.countries), [
    successStat,
  ]);
  const platforms = useMemo(() => successStat && Object.keys(successStat.platforms), [
    successStat,
  ]);
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
  } = useFormik({
    initialValues: getInitialValues(promocode),
    onSubmit: submitValues => {
      if (promocodeId) {
        edit({
          id: Number(promocodeId),
          data: {
            type: 'multiple',
            code: submitValues.code,
            amount: submitValues.amount,
            active: !!submitValues.active,
          },
        });
      } else {
        add({
          type: 'multiple',
          code: submitValues.code,
          amount: submitValues.amount,
          active: !!submitValues.active,
        });
      }
    },
  });

  useLayoutEffect(() => {
    if (editSuccess) {
      enqueueSnackbar(
        `${intl.formatMessage({
          id: promocodeId ? 'PROMOCODE.EDIT.SUCCESS' : 'PROMOCODE.CREATE.SUCCESS',
        })}`,
        {
          variant: 'success',
        }
      );
      clearEdit();
      navigate(-1);
    }
  }, [editSuccess]);

  useLayoutEffect(() => {
    if (editError) {
      enqueueSnackbar(
        `${intl.formatMessage({
          id: editError,
        })}`,
        {
          variant: 'error',
        }
      );
    }
  }, [editError]);

  useEffect(() => {
    fetchMe();

    return () => {
      clearEdit();
    };
  }, []);

  useEffect(() => {
    if (promocodeId) {
      me?.is_admin && fetchStat(Number(promocodeId));
      fetch(Number(promocodeId));
    }
  }, [fetch, promocodeId]);

  setLayoutSubheader({
    title: promocodeId
      ? `${intl.formatMessage({ id: 'PROMOCODES.EDIT.TITLE' })}`
      : `${intl.formatMessage({ id: 'PROMOCODES.ADD.TITLE' })}`,
    breadcrumb: [],
  });

  setLayoutFooter({ show: true });

  useEffect(() => {
    if (promocode) {
      setValues(getInitialValues(promocode));
    }
  }, [promocode, setValues]);

  if (loading || loadingMe || loadingStat) return <Preloader />;

  return (
    <>
      <Row>
        <Col>
          <Card className={homeClasses.classes.container}>
            <>
              <form className={homeClasses.classes.form} onSubmit={handleSubmit} autoComplete='off'>
                <TextField
                  type='text'
                  label={intl.formatMessage({ id: 'PROMOCODE.FORM.CODE' })}
                  margin='normal'
                  name='code'
                  value={promocodeId ? values.code : null}
                  variant='outlined'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  helperText={touched.code && errors.code}
                  error={Boolean(touched.code && errors.code)}
                />
                <TextField
                  type='number'
                  label={intl.formatMessage({ id: 'PROMOCODE.FORM.AMOUNT' })}
                  margin='normal'
                  name='amount'
                  value={promocodeId ? values.amount : null}
                  variant='outlined'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  helperText={touched.amount && errors.amount}
                  error={Boolean(touched.amount && errors.amount)}
                />
                <TextField
                  select
                  type='text'
                  label={intl.formatMessage({ id: 'PROMOCODES.TABLE.ACTIVITY' })}
                  margin='normal'
                  name='active'
                  value={Number(values.active)}
                  variant='outlined'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  helperText={touched.active && errors.active?.toString()}
                  error={Boolean(touched.active && errors.active)}
                >
                  <MenuItem value={0}>
                    {intl.formatMessage({ id: 'PROMOCODES.TABLE.INACTIVE' })}
                  </MenuItem>
                  <MenuItem value={1}>
                    {intl.formatMessage({ id: 'PROMOCODES.TABLE.ACTIVE' })}
                  </MenuItem>
                </TextField>
                {me?.is_admin && promocodeId && successStat && (
                  <div className={classes.actions}>
                    <Typography variant='h5'>
                      {intl.formatMessage({ id: 'SUBMENU.PROMOCODE.STATS' })}
                    </Typography>
                    <Typography variant='h6'>
                      {`${intl.formatMessage({ id: 'SUBMENU.PROMOCODE.STATS.USAGE' })}: ${
                        successStat.num
                      }`}
                    </Typography>
                    {countries?.length > 0 && (
                      <>
                        <Typography variant='h6'>
                          {intl.formatMessage({ id: 'SUBMENU.PROMOCODE.STATS.COUNTRIES' })}:
                        </Typography>
                        {countries.map((country: string) => (
                          <Typography variant='h6' style={{ marginLeft: 10 }}>
                            {country}: {successStat.countries[country]}
                          </Typography>
                        ))}
                      </>
                    )}
                    {countries?.length > 0 && (
                      <>
                        <Typography variant='h6'>
                          {intl.formatMessage({ id: 'SUBMENU.PROMOCODE.STATS.PLATFORMS' })}:
                        </Typography>
                        {platforms.map((platform: string) => (
                          <Typography variant='h6' style={{ marginLeft: 10 }}>
                            {platform}: {successStat.platforms[platform]}
                          </Typography>
                        ))}
                      </>
                    )}
                  </div>
                )}
                <div className={classes.actions}>
                  <Button
                    onClick={() => navigate(-1)}
                    className={classes.buttons}
                    variant='outlined'
                    color='primary'
                  >
                    {intl.formatMessage({ id: 'COMMON.BUTTON.BACK' })}
                  </Button>

                  <ButtonWithLoader disabled={editLoading} loading={editLoading}>
                    {promocodeId
                      ? intl.formatMessage({ id: 'USER.BUTTON.EDIT' })
                      : intl.formatMessage({ id: 'USER.BUTTON.ADD' })}
                  </ButtonWithLoader>
                </div>
              </form>
            </>
          </Card>
        </Col>
      </Row>
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    loadingMe: state.profile.loading,
    me: state.profile.me,
    promocode: state.promocodes.promocode,
    editSuccess: state.promocodes.editSuccess,
    editError: state.promocodes.editError,
    loading: state.companies.byIdLoading,
    editLoading: state.companies.editLoading,
  }),
  {
    fetchMe: profileActions.fetchRequest,

    fetch: promocodesActions.fetchByIdRequest,
    clearEdit: promocodesActions.clearEdit,
    add: promocodesActions.addRequest,
    edit: promocodesActions.editRequest,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PromocodeEditPage);
