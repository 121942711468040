import React from 'react';
import { useSelector } from 'react-redux';
import objectPath from 'object-path';
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import Header from './header/Header';
import SubHeader from './sub-header/SubHeader';
import HeaderMobile from './header/HeaderMobile';
import AsideLeft from './aside/AsideLeft';

import Footer from './footer/Footer';
import ScrollTop from '../partials/layout/ScrollTop';
import HTMLClassService from './HTMLClassService';
import LayoutConfig from './LayoutConfig';
import MenuConfig from './MenuConfig';
import LayoutInitializer from './LayoutInitializer';
import KtContent from './KtContent';
import HomePage from '../../app/pages/home/HomePage';

import './assets/Base.scss';
import { IAppState } from '../../app/store/rootDuck';
import { useAxiosGet } from '../../app/hooks';

const htmlClassService = new HTMLClassService();

const Layout = () => {
  const { layoutConfig, selfLayout, subheaderDisplay, asideDisplay } = useSelector(
    ({ builder }: IAppState) => ({
      layoutConfig: builder.layoutConfig,
      selfLayout: objectPath.get(builder.layoutConfig, 'self.layout'),
      asideDisplay: objectPath.get(builder.layoutConfig, 'aside.self.display'),
      subheaderDisplay: objectPath.get(builder.layoutConfig, 'subheader.display'),
    })
  );

  htmlClassService.setConfig(layoutConfig);
  // scroll to top after location changes
  // window.scrollTo(0, 0);
  const [collections] = useAxiosGet(`/api/shop/collections?slot4=true`);
  const navigate = useNavigate();
  const contentCssClasses = htmlClassService.classes.content.join(' ');
  return selfLayout !== 'blank' ? (
    <LayoutInitializer
      menuConfig={MenuConfig}
      layoutConfig={LayoutConfig}
      htmlClassService={htmlClassService}
    >
      {/* <!-- begin:: Header Mobile --> */}
      <HeaderMobile />
      {/* <!-- end:: Header Mobile --> */}

      <div className='kt-grid kt-grid--hor kt-grid--root'>
        {/* <!-- begin::Body --> */}
        <div className='kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page'>
          {/* <!-- begin:: Aside Left --> */}
          {asideDisplay && (
            <>
              <AsideLeft />
            </>
          )}
          {/* <!-- end:: Aside Left --> */}
          <div
            className='kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper'
            id='kt_wrapper'
          >
            {/* <!-- begin:: Header READY --> */}

            <Header />
            {/* <!-- end:: Header --> */}

            {subheaderDisplay && <SubHeader />}

            {/* <!-- begin:: Content --> */}
            <div
              id='kt_content'
              className={`kt-content ${contentCssClasses} kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor`}
            >
              {/* <!-- begin:: Content Body --> */}
              <KtContent>
                <HomePage>
                  <Outlet />
                </HomePage>
              </KtContent>
              {/*<!-- end:: Content Body -->*/}
            </div>
            {/* <!-- end:: Content --> */}
            <Footer collections={collections} />
          </div>
        </div>
        {/* <!-- end:: Body --> */}
      </div>
      <ScrollTop />
    </LayoutInitializer>
  ) : (
    // BLANK LAYOUT
    <div className='kt-grid kt-grid--ver kt-grid--root'>
      <KtContent>
        <HomePage>
          <Outlet />
        </HomePage>
      </KtContent>
    </div>
  );
};

export default Layout;
