import React from 'react';
import { Box, Tooltip } from '@mui/material';
import { API_DOMAIN } from '../../../constants';
import { useStylesProductIcons } from './styles';

const ProductIcons = ({
  nameView,
  tooltipName = '',
  title,
  src,
}: {
  nameView?: boolean;
  tooltipName?: string;
  title?: string;
  src?: string;
}) => {
  const classes = useStylesProductIcons();

  return (
    <Tooltip title={tooltipName}>
      <Box className={classes.box}>
        {src && <img src={`${API_DOMAIN}/${src}`} alt='' width={45} height={45} />}
        {nameView && <p className={classes.title}>{title}</p>}
      </Box>
    </Tooltip>
  );
};

export default ProductIcons;
