import { useMemo } from 'react';
import { IUser, UserRoleType } from '../interfaces/user';

export const useDefineUserRole = (
  me: IUser | undefined | null,
  role: UserRoleType | UserRoleType[]
) => {
  return useMemo(() => {
    if (!me) return null;
    if (Array.isArray(role)) {
      return me.roles.some(item => role.includes(item));
    }
    if (me.roles.includes(role)) {
      return true;
    }
    return false;
  }, [me, role]);
};
