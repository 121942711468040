import React, { useEffect } from 'react';
import { Dialog } from '@mui/material';
import { shallowEqual, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AuthTabs } from './components';
import { IAppState } from '../../store/rootDuck';

interface IProps {
  isOpen: boolean;
  handleClose: any;
  hideGuestBtn: boolean;
  initPhone?: string;
  initEmail?: string;
}

export const AuthDialog: React.FC<IProps> = ({
  isOpen,
  handleClose,
  initPhone,
  initEmail,
}) => {
  const navigate = useNavigate();
  const store = useSelector((store: IAppState) => store, shallowEqual);

  // check if user is authorized
  useEffect(() => {
    if (store.auth.user && isOpen) {
      handleClose();
      setTimeout(() => {
        // history.go(0);
        navigate(0);
      }, 100);
    }
  }, [store.auth.user]);

  return (
    <Dialog open={isOpen} onClose={handleClose} aria-labelledby='form-dialog-title'>
      <div className='kt-grid kt-grid--ver kt-grid--root'>
        <div
          id='kt_login'
          className='kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1'
        >
          <div className='kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper'>
            <AuthTabs initEmail={initEmail} initPhone={initPhone} hideGuestBtn />
          </div>
        </div>
      </div>
    </Dialog>
  );
};
