import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { Paper, Tab, Tabs } from '@mui/material';
import { useSnackbar } from 'notistack';

import Preloader from '../../../components/other/Preloader/Preloader';
import { IAppState } from '../../../store/rootDuck';
import { actions as categoriesActions } from '../../../store/ducks/categories.duck';
import { setLayoutSubheader } from '../../../utils/layout';
import useHomeStyles from '../../../constants/homeStyles';
import { actions as profileActions } from '../../../store/ducks/profile.duck';
import { actions as searchActions } from '../../../store/ducks/search.duck';
import { UploadImages } from '../../../components/ui/UploadImages';
import { useFormatMessage } from '../../../hooks';
import { UploadImagesOffline } from '../../../components/ui/UploadImagesOffline';
import { SearchForm } from './components/SearchForm';
import { useFormikSearchPage } from './hooks/useFormikSearchPage';
import homeStyles from '../homeStyles';
import { useCreateSearch } from './hooks/useCreateSearch';
import { useUploadFiles } from './hooks/useUploadFiles';
import { useDeleteFile } from './hooks/useDeleteFile';
import { useStylesSearchPage } from './hooks/useStyles';
import ProposalsForm from './components/ProposalsForm';
import { useDeleteSearch } from './hooks/useDeleteSearch';

const SearchPage: React.FC<TPropsFromRedux> = ({
  fetch,
  fetchCategories,
  categories,
  me,
  loadingMe,
  tender,
  fetchMe,
  loading,
  clearMe,
  editTender,
  editLoading,
  clearEdit,
  clearPart,
  fetchParticipation,
  participations,
  editSuccess,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const homeClasses = homeStyles();
  const styles = useHomeStyles();
  const classes = useStylesSearchPage();
  const navigate = useNavigate();
  const { id } = useParams();
  const fm = useFormatMessage();

  const [images, setImages] = useState([]);
  const { createSearch, createLoading, createId } = useCreateSearch();
  const [uploadFiles, loadingUpload, successUpload] = useUploadFiles(id);
  const [deleteFile, loadingDelete] = useDeleteFile();
  const { deleteSearchFetch, loadingDeleteSearch, successDelete } = useDeleteSearch();

  useEffect(() => {
    if (createId && !id && images.length > 0) {
      uploadFiles(createId, images);
    }
    if (createId) {
      navigate(-1);
    }
  }, [createId, id]);

  useEffect(() => {
    if (successUpload && !id) {
      navigate(-1);
    }
  }, [successUpload]);

  useEffect(() => {
    if (editSuccess) {
      enqueueSnackbar(fm('SEARCH.EDIT.SUCCESS'), {
        variant: 'success',
      });
    }
    (successDelete || editSuccess) && navigate(-1);
  }, [editSuccess, successDelete]);

  const handleDeleteSearch = useCallback(() => {
    id && deleteSearchFetch(+id);
  }, [id]);

  const uploadImages = useCallback(
    (files: File[]) => {
      tender && uploadFiles(tender.id, files);
    },
    [tender]
  );

  const [tabValue, setTabValue] = useState(0);

  setLayoutSubheader({
    title: fm('PRODUCT.MANAGEMENT'),
    breadcrumb: [
      {
        title: fm('SEARCH.TITLE'),
        root: true,
        page: 'product_search/list',
        translate: 'SEARCH.TITLE',
      },
    ],
  });

  useEffect(() => {
    fetchMe();
    fetchCategories();
    return () => {
      clearEdit();
      clearPart();
    };
  }, []);

  useEffect(() => {
    id && fetch(Number(id));
    id && fetchParticipation({ tender_id: +id, page: 1, per_page: 20 });
  }, [id]);

  useEffect(() => {
    return () => {
      clearMe();
    };
  }, []);

  // handle images when create (UploadImagesOffline.tsx)

  const { formik } = useFormikSearchPage(createSearch, editTender, tender, categories);

  if (!me || loadingMe || loading) return <Preloader />;

  return (
    <Paper className={styles.classes.container} style={{ marginTop: 0 }}>
      <div className={classes.tabs}>
        <Tabs
          value={tabValue}
          onChange={(_: React.ChangeEvent<{}>, newValue: number) => {
            setTabValue(newValue);
          }}
          indicatorColor='primary'
          variant='fullWidth'
          textColor='primary'
          style={{ minHeight: 0 }}
        >
          <Tab label={fm('SEARCH.PAGE.TAB.ONE')} className={classes.labelTab} />
          <Tab
            label={`${fm('SEARCH.PAGE.TAB.TWO')}${
              participations && participations.length > 0 ? ` (${participations.length})` : ''
            }`}
            disabled={!id}
            className={classes.labelTab}
          />
        </Tabs>
      </div>

      <div style={{ display: tabValue === 0 ? 'contents' : 'none' }}>
        <div
          className={homeClasses.classes.form}
          style={{ paddingTop: 0, maxWidth: 728, marginTop: 20 }}
        >
          {id ? (
            <UploadImages
              images={tender?.attachments}
              uploadImages={files => uploadImages(files)}
              withGallery
              deleteImage={deleteFile}
              containerStyle={{ padding: 0 }}
              avaliableNumberOfFilesToUpload={
                tender?.attachments && 10 - tender.attachments.length
              }
            />
          ) : (
            <UploadImagesOffline
              images={images}
              setImages={setImages}
              avaliableNumberOfFilesToUpload={10 - images.length}
              withGallery
              disableMain
              containerStyle={{ padding: 0 }}
            />
          )}
          <SearchForm
            id={id}
            formik={formik}
            editLoading={
              editLoading ||
              createLoading ||
              loadingUpload ||
              loadingDelete ||
              loadingDeleteSearch
            }
            categories={categories}
            handleDeleteSearch={handleDeleteSearch}
          />
        </div>
      </div>

      <div style={{ display: tabValue === 1 ? 'contents' : 'none' }}>
        <div className={classes.card}>
          <ProposalsForm id={id || ''} />
        </div>
      </div>
    </Paper>
  );
};

const connector = connect(
  (state: IAppState) => ({
    me: state.profile.me,
    loadingMe: state.profile.loading,

    categories: state.categories.categories,

    tender: state.search.tender,
    loading: state.search.byIdLoading,
    editLoading: state.search.editLoading,
    participations: state.search.participations,
    editSuccess: state.search.editSuccess,
  }),
  {
    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,

    fetchCategories: categoriesActions.fetchFullRequest,

    fetch: searchActions.fetchByIdRequest,
    editTender: searchActions.editRequest,
    clearEdit: searchActions.clearEdit,
    clearPart: searchActions.clearPart,
    fetchParticipation: searchActions.fetchParticipation,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SearchPage);
