import React from 'react';
import { TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import ButtonWithLoader from '../../../../../components/other/Buttons/ButtonWithLoader';
import { IUser } from '../../../../../interfaces/user';
import homeStyles from '../../../../../constants/homeStyles';
import { useStylesBonusesUser } from '../hooks/useStyles';

type TProps = {
  user: IUser;
  editLoading: boolean;
  addBonuses: (userId: number, amount: number) => void;
};

const BonusesUser: React.FC<TProps> = ({ user, editLoading, addBonuses }) => {
  const classes = useStylesBonusesUser();
  const homeClasses = homeStyles();
  const intl = useIntl();

  return (
    <Formik
      initialValues={{
        bonuses: '',
      }}
      onSubmit={values => {
        addBonuses(user.id, Math.round(+values.bonuses || 0));
      }}
    >
      {({ values, errors, touched, handleBlur, handleSubmit, setFieldValue }) => (
        <form className={homeClasses.classes.form} onSubmit={handleSubmit} style={{}}>
          <Typography>
            {intl.formatMessage({ id: 'PROMOCODE.FORM.AMOUNT' })}: {user!.bonus_count}
          </Typography>
          <TextField
            type='number'
            label={intl.formatMessage({ id: 'PROMOCODE.FORM.AMOUNT' })}
            margin='normal'
            name='bonuses'
            value={values.bonuses || null}
            variant='outlined'
            onBlur={handleBlur}
            onChange={e => {
              e.target.value.length < 6 && setFieldValue('bonuses', e.target.value);
            }}
            helperText={touched.bonuses && errors.bonuses}
            error={Boolean(touched.bonuses && errors.bonuses)}
          />

          <div className={classes.actions}>
            <ButtonWithLoader
              disabled={editLoading || !values.bonuses}
              loading={editLoading}
              type='submit'
            >
              {intl.formatMessage({ id: 'COMMON.BUTTON.ADD' })}
            </ButtonWithLoader>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default React.memo(BonusesUser);
