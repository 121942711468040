import React from 'react';
import { MenuSection } from './MenuSection';
import { MenuItemSeparator } from './MenuItemSeparator';
import { MenuItem } from './MenuItem';

type TProps = {
  currentUrl: any;
  layoutConfig: any;
  menuConfig: any;
};

export const MenuList: React.FC<TProps> = ({ currentUrl, menuConfig, layoutConfig }) => {
  return (
    <>
      {menuConfig.aside.items.map((child: any, index: number) => {
        return (
          <React.Fragment key={`menuList${index}`}>
            {child.section && <MenuSection item={child} />}
            {child.separator && <MenuItemSeparator />}
            {child.title && (
              <MenuItem item={child} currentUrl={currentUrl} layoutConfig={layoutConfig} />
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};
