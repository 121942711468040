import React, { useEffect } from 'react';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Link,
  Checkbox,
  Theme,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { Pagination, PaginationItem } from '@mui/lab';
import { useDefineUserRole } from '../../../../hooks';
import { useStylesProductCatalog } from '../hooks/useStyles';
import { IProduct } from '../../../../interfaces/product';
import homeStyles from '../../homeStyles';
import { SHeadTableCell } from '../../../../components/styledComponents/Tables';
import { thousands } from '../../../../utils/utils';
import { IAppState } from '../../../../store/rootDuck';

type TProps = {
  data: IProduct[];
  page: number;
  perPage: number;
  fetch?: (data: any) => void;
  checkedId: any;
  setCheckedId: any;
  checkedIdActive: number[];
  setCheckedIdActive: any;
};

export const ProductsEditPricingTable: React.FC<TProps> = ({
  data,
  page,
  perPage,
  fetch,
  checkedId,
  setCheckedId,
  setCheckedIdActive,
  checkedIdActive,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const homeClasses = homeStyles();
  const classes3 = useStylesProductCatalog();
  const classes2 = classes3.classes
  const me = useSelector((state: IAppState) => state.profile.me);
  const isAdmin = useSelector((state: IAppState) => state.auth.user?.is_admin);
  const total = useSelector((state: IAppState) => state.productsCatalog.total);
  const isAuthorized = useSelector((state: IAppState) => state.auth.user != null);

  const isManager = useDefineUserRole(me, 'ROLE_MANAGER');

  useEffect(() => {
    if (data && data.length) {
      setCheckedId(
        data.map(item => {
          return { product_id: item.id, price: item.price, original_price: item.price };
        })
      );
      setCheckedIdActive(
        data.map(item => {
          return item.id;
        })
      );
    }
  }, [data]);

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number,
    price: number,
    item: any
  ) => {
    if (event.target.checked) {
      // setCheckedId((prevCheckedId: any) => [...prevCheckedId, { product_id: item.id, price: item.price, original_price: item.price}]);
      setCheckedIdActive((prevCheckedId: any) => [...prevCheckedId, item.id]);
    } else {
      // setCheckedId((prevCheckedId: any) =>
      //   prevCheckedId.filter((item: any) => item.product_id !== id)
      // );
      setCheckedIdActive((prevCheckedId: any) =>
        prevCheckedId.filter((item: any) => item !== id)
      );
    }
  };

  const handlePriceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    productId: number
  ) => {
    const newPrice = parseFloat(event.target.value || '0');
    setCheckedId((prevCheckedId: any) =>
      prevCheckedId.map((item: any) =>
        item.product_id === productId ? { ...item, price: newPrice } : item
      )
    );
  };

  return (
    <div style={{ display: 'grid' }}>
      {!data || !data.length ? (
        <Typography className={classes.empty} component='h5' variant='h5'>
          {intl.formatMessage({ id: 'COMMON.LIST.EMPTY' })}
        </Typography>
      ) : (
        <>
          <Card className={classes.card}>
            <CardContent className={homeClasses.classes.tableContainer}>
              <Table aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ padding: 0 }}>
                      <Checkbox
                        color='primary'
                        indeterminate={
                          checkedIdActive.length > 0 && checkedIdActive.length < data.length
                        }
                        checked={checkedIdActive?.length === data.length}
                        onChange={event => {
                          if (event.target.checked) {
                            // setCheckedId(
                            //   data.map(item => {
                            //     return { product_id: item.id, price: item.price, original_price: item.price};
                            //   })
                            // );
                            setCheckedIdActive(
                              data.map(item => {
                                return item.id;
                              })
                            );
                          } else {
                            // setCheckedId([]);
                            setCheckedIdActive([]);
                          }
                        }}
                      />
                    </TableCell>
                    {isAuthorized && (isAdmin || isManager) && (
                      <SHeadTableCell className={classes.head}>
                        {intl.formatMessage({ id: 'PRODUCT.TABLE.ID' })}
                      </SHeadTableCell>
                    )}
                    <SHeadTableCell className={classes.head}>
                      {intl.formatMessage({ id: 'PRODUCT.EDIT.NAME' })}
                    </SHeadTableCell>
                    <SHeadTableCell className={classes.head}>
                      {intl.formatMessage({ id: 'MENU.COMPANY' })}
                    </SHeadTableCell>
                    <SHeadTableCell align='center' className={classes.head}>
                      {intl.formatMessage({ id: 'STOCK' })}
                    </SHeadTableCell>
                    <SHeadTableCell align='center' className={classes.head}>
                      {intl.formatMessage({ id: 'PRODUCT.CURRENT.PRICE' })}
                    </SHeadTableCell>
                    <SHeadTableCell
                      style={{ width: 50, padding: 0 }}
                      align='center'
                      className={classes.head}
                    >
                      {intl.formatMessage({ id: 'PRODUCT.NEW.PRICE' })}
                    </SHeadTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map(item => {
                    return (
                      <TableRow key={item.id}>
                        <TableCell style={{ padding: 0 }}>
                          <Checkbox
                            color='primary'
                            checked={checkedIdActive.some((el: any) => el === item.id)}
                            onChange={event =>
                              handleCheckboxChange(event, item.id || 0, item.price || 0, item)
                            }
                          />
                        </TableCell>
                        {isAuthorized && (isAdmin || isManager) && (
                          <TableCell component='th' scope='row' className={classes.cell}>
                            {item.id || '-'}
                          </TableCell>
                        )}
                        <TableCell className={classes.cell}>
                          <Link
                            component={RouterLink}
                            // to={`/products/edit/${item.id}`}
                            to={`/viewproduct/${item.id}`}
                            className={classes.buttons}
                          >
                            {item.name || '-'}
                          </Link>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {item.company ? (
                            <Link
                              component={RouterLink}
                              to={`/companies/edit/${item.company?.id}`}
                              // isAdmin
                              // ? `/companies/edit/${item.company?.id}`
                              // : `/viewcompany/${item.company?.id}`
                              className={classes.buttons}
                            >
                              {item.company?.name}
                            </Link>
                          ) : (
                            '-'
                          )}
                        </TableCell>
                        <TableCell align='center' className={classes.cell}>
                          {item.stock_info?.stock_quantity || '-'}
                        </TableCell>
                        <TableCell align='center' className={classes.cell}>
                          {item.price ? thousands(String(item.price)) : '-'}
                        </TableCell>
                        <TableCell
                          align='center'
                          style={{ width: 50 }}
                          className={classes.cell}
                        >
                          <input
                            style={{
                              width: 120,
                              border: '1px solid #369ff7',
                              padding: '5px 0',
                              borderRadius: 4,
                              textAlign: 'center',
                              outline: 'none',
                            }}
                            value={
                              checkedId.some((el: any) => el.product_id === item.id)
                                ? checkedId.find((el: any) => el.product_id === item.id).price
                                : ''
                            }
                            disabled={!checkedId.some((el: any) => el.product_id === item.id)}
                            onChange={event => handlePriceChange(event, item.id || 0)}
                            type='text'
                            name=''
                            id=''
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              {fetch && (
                <Pagination
                  variant='outlined'
                  shape='rounded'
                  page={page || 1}
                  boundaryCount={1}
                  siblingCount={2}
                  color='primary'
                  count={Math.ceil(total / perPage || 0)}
                  renderItem={item => (
                    <PaginationItem className={classes2.wrapPaginationItem} {...item} />
                  )}
                  // perPage={perPage || 20}
                  onChange={(event: object, page: number) =>
                    fetch({
                      page,
                      perPage: perPage || 0,
                      filter: { parameters: [], price_from: '', price_to: '' },
                      categoryId: 0,
                      activity: 'all',
                    })
                  }
                  style={{ marginTop: 20, width: '100%' }}
                />
              )}
            </CardContent>
          </Card>
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    empty: {
      marginBottom: 16,
    },
    buttons: {
      // marginRight: theme.spacing(3),
    },
    card: {
      marginBottom: theme.spacing(3),
      boxShadow: 'none !important',
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
    },
    categoryTree: {
      maxWidth: 400,
    },
    actions: {
      marginTop: theme.spacing(2),
    },
    img: {
      maxHeight: 44,
      cursor: 'pointer',
    },
    autoLoc: {
      maxWidth: 400,
    },
    head: {
      fontWeight: 'bold',
      fontSize: 14,
    },
    cell: {
      padding: 13,
    },
  })
);
