import React, { useMemo } from 'react';
import { Card, Grid, IconButton } from '@mui/material';
import { Delete, Star } from '@mui/icons-material';

import { useStyles } from '../hooks/useStyles';
import { API_DOMAIN } from '../../../../constants';
import { IPhoto } from '../../../../interfaces/photo';

interface IProps {
  images: IPhoto[] | undefined;
  deleteImage?: (id: IPhoto['id']) => void;
}

export const MainImg: React.FC<IProps> = ({ images, deleteImage }) => {
  const classes = useStyles();

  const mainImage = useMemo(() => {
    if (!images || images.length === 0) return;

    const mainImage = images.find(el => el.main);
    if (!mainImage) return;

    return mainImage;
  }, [images]);

  if (!images || images.length === 0 || !mainImage) return <></>;

  return (
    <Card className={classes.card} style={{ marginBottom: images.length !== 0 ? 20 : 0 }}>
      <img
        src={`${API_DOMAIN}/${mainImage.big || mainImage.path}`}
        alt=''
        className={classes.img}
      />
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        className={classes.galleryImgTitleBar}
      >
        <IconButton style={{ color: '#ffc107' }} disabled>
          <Star />
        </IconButton>

        {deleteImage && (
          <IconButton style={{ color: 'white' }} onClick={() => deleteImage(mainImage.id)}>
            <Delete />
          </IconButton>
        )}
      </Grid>
    </Card>
  );
};
