import React, { useCallback } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Typography, TextField, MenuItem } from '@mui/material';

import { IAppState } from '../../../../../store/rootDuck';
import { ICategoryItem } from '../../../categories/interfaces';
import AutocompleteCopy from '../../../../../components/formComponents/AutocompleteLocationsNew';
import { useStylesHeaderAddMultiply } from '../../hooks/useStyles';
import { useFormatMessage } from '../../../../../hooks';

const levelsSubCategories = [1, 2, 3, 4, 5];
const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

type TProps = {
  formik: any;
  openModalCompanies: () => void;
  subCategories: ICategoryItem[][];
};

const CommonParams: React.FC<TProps> = ({ formik, openModalCompanies, subCategories }) => {
  const fm = useFormatMessage();
  const classes1 = useStylesHeaderAddMultiply();
  const {classes} = classes1

  const { me } = useSelector(({ profile }: IAppState) => profile);

  const { categories } = useSelector(({ categories: { categories } }: IAppState) => ({
    categories,
  }));

  const { types } = useSelector(
    ({ productTypes: { productTypes } }: IAppState) => ({
      types: productTypes,
    }),
    shallowEqual
  );

  const { values, handleBlur, handleChange, setFieldValue } = formik;

  const getSubCategories = useCallback(
    (level: number) => {
      return subCategories[level];
    },
    [subCategories]
  );

  return (
    <div className={classes.block}>
      <Typography className={classes.title}>
        {fm('PRODUCT.ADD.MULTIPLY.PRODUCTS.COMMON.PARAMS').toLocaleUpperCase()}
      </Typography>
      <div className={classes.wrapperFields}>
        <TextField
          type='text'
          label={fm('COMPANY.NAME')}
          margin='normal'
          name='companyName'
          value={values.company?.name || ''}
          variant='outlined'
          color='primary'
          InputLabelProps={{ shrink: true }}
          InputProps={{ readOnly: true }}
          className={classes.fieldCommon200}
          onClick={openModalCompanies}
          disabled={!me?.is_admin}
        />
        <TextField
          select
          label={fm('PRODUCT.EDIT.CATEGORY')}
          //   margin='normal'
          color='primary'
          name='mainCategoryId'
          value={values.mainCategoryId || ''}
          variant='outlined'
          onBlur={handleBlur('mainCategoryId')}
          onChange={handleChange}
          SelectProps={{ autoWidth: true }}
          className={classes.fieldCommon200}
        >
          {categories &&
            categories.map(item => (
              <MenuItem
                color='primary'
                key={item.id?.toString()}
                value={item.id?.toString()}
                style={{ whiteSpace: 'normal' }}
              >
                {item.name}
              </MenuItem>
            ))}
        </TextField>

        {levelsSubCategories.map(level => {
          const subCategories = getSubCategories(level);
          if (subCategories && subCategories?.length > 0) {
            return (
              <TextField
                select
                label={`${fm('PRODUCT.EDIT.SUBCATEGORY')} ${level}`}
                color='primary'
                name={`categoryId_${level}`}
                // @ts-ignore
                value={values[`categoryId_${level}`] || ''}
                variant='outlined'
                onBlur={handleBlur(`categoryId_${level}`)}
                onChange={handleChange}
                disabled={!values.mainCategoryId}
                className={classes.fieldCommon200}
              >
                {subCategories.length > 0 &&
                  subCategories.map(item => (
                    <MenuItem key={item.id?.toString()} value={item.id?.toString()}>
                      {item.name}
                    </MenuItem>
                  ))}
              </TextField>
            );
          }
          return <></>;
        })}

        {types && types.length > 0 && (
          <TextField
            select
            label={fm('PRODUCT.EDIT.TYPE')}
            value={values.productTypeId || ''}
            onChange={handleChange}
            color='primary'
            name='productTypeId'
            variant='outlined'
            className={classes.fieldCommon180}
          >
            {types.map(option => (
              <MenuItem key={option.id?.toString()} value={option.id?.toString() || ''}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        )}

        <TextField
          select
          label={fm('ITEMS')}
          value={values.items || 1}
          onChange={handleChange}
          color='primary'
          name='items'
          variant='outlined'
          className={classes.fieldCommon80}
        >
          {items.map(option => (
            <MenuItem key={option.toString()} value={option.toString()}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div className={classes.wrapperFields}>
        <AutocompleteCopy
          value={values.location || { address: '' }}
          setSelectedLocation={location => {
            setFieldValue('location', location);
          }}
          lenghtAddress={120}
          title={fm('PRODUCT.ADDRESS')}
          styleField={{ maxWidth: 400, width: '100%', marginTop: 0 }}
          widthList={400}
          styleRoot={{
            marginTop: 10,
            marginLeft: 10,
            marginBottom: 10,
            width: '100%',
            maxWidth: 400,
          }}
          disableMargin
        />
        <TextField
          type='text'
          label={fm('PRODUCT.ADDRESS.NAME')}
          name='addressName'
          value={values.addressName}
          variant='outlined'
          onChange={handleChange}
          className={classes.addressName}
        />
      </div>
    </div>
  );
};

export default React.memo(CommonParams);
