import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LoyaltyLevelEditPage from './LoyaltyLevelEditPage';
import LoyaltyLevelPage from './LoyaltyLevelPage';

export default function Loyalties() {
  return (
    <Routes>
      <Route path='new' element={<LoyaltyLevelEditPage />} />
      <Route path='list' element={<LoyaltyLevelPage />} />
      <Route path='edit/:loyaltyId' element={<LoyaltyLevelEditPage />} />
    </Routes>
  );
}
