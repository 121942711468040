import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ProductPage from './ProductPage';
import ProductListPage from './ProductList';
import ProductTypesListPage from './ProductTypesList';
import ProductTypePage from './ProductTypePage';
import ProductByCategoryList from './ProductByCategoryList';
import ProductCatalog from './ProductCatalog';
import ProductFavorites from './ProductsFavorites';
import ProtectedRoute from '../../../router/Routes';
import { IAppState } from '../../../store/rootDuck';
import ProductsAddMultiply from './ProductsAddMultiply';
import ProductsEditPricing from './ProductsEditPricing';

export default function ProductsPage() {
  const { isAuthorized } = useSelector(({ auth }: IAppState) => ({
    isAuthorized: auth.user != null,
  }));

  return (
    <Routes>
      <Route
        path='list'
        element={<ProtectedRoute isAuthorized={isAuthorized} outlet={<ProductListPage />} />}
      />
      <Route
        path='create'
        element={<ProtectedRoute isAuthorized={isAuthorized} outlet={<ProductPage />} />}
      />
      <Route
        path='add_multiply'
        element={
          <ProtectedRoute isAuthorized={isAuthorized} outlet={<ProductsAddMultiply />} />
        }
      />
      <Route
        path='edit_pricing'
        element={
          <ProtectedRoute isAuthorized={isAuthorized} outlet={<ProductsEditPricing />} />
        }
      />
      <Route
        path='create/:parentId'
        element={<ProtectedRoute isAuthorized={isAuthorized} outlet={<ProductPage />} />}
      />
      <Route
        path='create/company/:companyIdProp/:companyNameProp?'
        element={<ProtectedRoute isAuthorized={isAuthorized} outlet={<ProductPage />} />}
      />
      <Route
        path='edit/:id'
        element={<ProtectedRoute isAuthorized={isAuthorized} outlet={<ProductPage />} />}
      />
      <Route path='list/:categoryId/:companyId?' element={<ProductByCategoryList />} />
      <Route
        path='types/list'
        element={
          <ProtectedRoute isAuthorized={isAuthorized} outlet={<ProductTypesListPage />} />
        }
      />
      <Route
        path='types/create'
        element={<ProtectedRoute isAuthorized={isAuthorized} outlet={<ProductTypePage />} />}
      />
      <Route
        path='types/edit/:typeId'
        element={<ProtectedRoute isAuthorized={isAuthorized} outlet={<ProductTypePage />} />}
      />
      <Route
        path='favorites'
        element={<ProtectedRoute isAuthorized={isAuthorized} outlet={<ProductFavorites />} />}
      />
      <Route
        path=':type'
        element={<ProtectedRoute isAuthorized={isAuthorized} outlet={<ProductListPage />} />}
      />
      <Route path='catalog' element={<ProductCatalog />} />
    </Routes>
  );
}
