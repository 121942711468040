import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Paper, Tooltip } from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from 'react-sortable-hoc';
import { useSnackbar } from 'notistack';

import { useIntl } from 'react-intl';
import TypeTitleForm from './TypeTitleForm';
import TypeParamForm from './TypeParamForm';
import ButtonWithLoader from '../../../../../components/other/Buttons/ButtonWithLoader';

import { IProductType, ITypeParameter } from '../../../../../interfaces/productType';
import homeStyles from '../../../../../constants/homeStyles';
import { useDeleteParam } from '../../hooks/useDeleteParam';
import { useFormatMessage } from '../../../../../hooks';

interface ITypeForm {
  type?: IProductType;
  typeId?: number;
  typeAction: any;
  paramAction: any;
  delProductType: any;
  setPositions: (positions: string) => void;
  disabled: boolean;
  groups: { id: number; name: string }[];
  deleteImage?: (id: number) => void;
  fetchDeleteEnumValue: (parameter_id: number) => void;
}

interface ISortableItem {
  param: ITypeParameter;
  paramAction: any;
  deleteField: (id?: number, index?: number) => void;
  index: number;
  errorMessage: () => void;
  disabledBtn: boolean;
  deleteImage?: (id: number) => void;
  groups: { id: number; name: string }[];
  fetchDeleteEnumValue: (parameter_id: number) => void;
}
const DragHandle: any = SortableHandle(({ errorMessage }: { errorMessage: () => void }) => (
  <span style={{ cursor: 'move' }} onClick={errorMessage}>
    <DragHandleIcon />
  </span>
));

const SortableItem: any = SortableElement(
  ({
    param,
    paramAction,
    deleteField,
    index,
    errorMessage,
    disabledBtn,
    groups,
    deleteImage,
    fetchDeleteEnumValue,
  }: ISortableItem) => {
    const intl = useIntl();
    return (
      <div>
        {param.id && (
          <Tooltip
            title={intl.formatMessage({
              id: 'PRODUCTS.TOOLTIP.DRAG',
            })}
          >
            <div style={{ width: '20px' }}>
              <DragHandle errorMessage={errorMessage} />
            </div>
          </Tooltip>
        )}
        <TypeParamForm
          key={param.id}
          param={param}
          paramAction={paramAction}
          index={index}
          deleteField={deleteField}
          disabled={disabledBtn}
          groups={groups}
          deleteImage={deleteImage}
          fetchDeleteEnumValue={fetchDeleteEnumValue}
        />
      </div>
    );
  }
);

const SortableList: any = SortableContainer(({ children }: { children: React.ReactNode }) => (
  <div>{children}</div>
));

const TypeForm: React.FC<ITypeForm> = ({
  type,
  typeAction,
  paramAction,
  delProductType,
  typeId,
  setPositions,
  disabled,
  groups,
  deleteImage,
  fetchDeleteEnumValue,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const fm = useFormatMessage();
  const homeClasses = homeStyles();
  const [typeParams, setTypeParams] = useState(type?.parameters || []);
  const [deleteParam] = useDeleteParam(typeId);

  useEffect(() => {
    if (type?.parameters) {
      setTypeParams(type.parameters);
    }
  }, [type]);

  const validationParams = useMemo(() => {
    if (typeParams) {
      let err = false;
      typeParams.forEach(item => {
        if (!item.id) {
          err = true;
        }
      });
      return err;
    }
  }, [typeParams]);

  const errorMessage = useCallback(() => {
    validationParams &&
      enqueueSnackbar(fm('PRODUCT.TYPE.POSITIONS.ERROR'), { variant: 'error' });
  }, [validationParams]);

  const addEmptyCropParams = () => {
    setTypeParams([...typeParams, ...[{}]]);
  };

  const deleteField = useCallback(
    (id?: number, index?: number) => {
      if (id) {
        deleteParam(id);
      } else if (index) {
        const newParams = typeParams;
        newParams.splice(index, 1);
        setTypeParams([...typeParams]);
      }
    },
    [deleteParam, typeParams, setTypeParams]
  );

  return (
    <Paper className={homeClasses.classes.container}>
      <div className={homeClasses.classes.form}>
        <TypeTitleForm type={type} delProductType={delProductType} typeAction={typeAction} />
        {typeParams && (
          <SortableList
            useDragHandle
            useWindowAsScrollContainer
            onSortEnd={({ oldIndex, newIndex }: any) => {
              if (oldIndex !== newIndex) {
                const sortParams = arrayMove(typeParams, oldIndex, newIndex);
                const positions = sortParams.reduce(
                  (accumulator, item, index) =>
                    accumulator + (index === 0 ? '' : ',') + item.id,
                  ''
                );
                setPositions(positions);
              }
            }}
          >
            {typeParams.map((param, index) => (
              <SortableItem
                key={param.id}
                param={param}
                paramAction={paramAction}
                index={index}
                deleteField={deleteField}
                disabled={validationParams || disabled}
                errorMessage={errorMessage}
                disabledBtn={disabled}
                groups={groups}
                deleteImage={deleteImage}
                fetchDeleteEnumValue={fetchDeleteEnumValue}
              />
            ))}
          </SortableList>
        )}
        {type?.id && (
          <div className={homeClasses.classes.buttonAddContainer}>
            <ButtonWithLoader onPress={addEmptyCropParams} disabled={disabled}>
              {fm('PRODUCT.TYPES.PARAMETER.BUTTON.ADD')}
            </ButtonWithLoader>
          </div>
        )}
      </div>
    </Paper>
  );
};

export default React.memo(TypeForm);
