import React, { useEffect, useLayoutEffect } from 'react';
import Preloader from '../../../../../components/other/Preloader/Preloader';
import { useGetImage } from '../hooks/useGetImage';
import { useStyleImageChat } from '../styles';

interface IProps {
  image: any;
  scrollToBottom: () => void;
}

const ImageChat: React.FC<IProps> = ({ image }) => {
  const classes = useStyleImageChat();
  const [getImage, imageResponse, loading] = useGetImage();
  useLayoutEffect(() => {
    getImage(image.id);
  }, [image]);

  useEffect(() => {
    // setTimeout(() => scrollToBottom(), 0);
  }, [loading]);

  return (
    <div style={{ height: image.height > 400 ? 400 : image.height }}>
      {loading || !imageResponse ? (
        <Preloader />
      ) : (
        <img src={imageResponse} alt={image.name} className={classes.img} />
      )}
    </div>
  );
};

export default React.memo(ImageChat);
