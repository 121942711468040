import React from 'react';

interface IProps {
  size?: string;
}

const StarActive: React.FC<IProps> = ({ size='24' }) => {
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="#369ff7" stroke="#369ff7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"/>
        </svg>
    </>
  );
};

export default StarActive;
