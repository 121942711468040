'use strict';

import KTUtil from './util';

const KTToggle = (elementId, options) => {
  const defaultOptions = {
    togglerState: '',
    targetState: '',
  };

  let element = KTUtil.get(elementId);
  const body = KTUtil.get('body');

  if (!element) return;

  let the = {
    element: element,
    options: KTUtil.deepExtend({}, defaultOptions, options),
    target: KTUtil.get(options.target),
    targetState: options.targetState,
    togglerState: options.togglerState,
    state: KTUtil.hasClasses(KTUtil.get(options.target), options.targetState) ? 'on' : 'off',
    events: [],
  };

  const eventTrigger = name => {
    for (const event of the.events) {
      if (event.name === name) {
        if (event.one && !event.fired) {
          event.fired = true;
          event.handler.call(the, the);
        } else if (!event.one) {
          event.handler.call(the, the);
        }
      }
    }
  };

  const addEvent = (name, handler, one = false) => {
    the.events.push({
      name,
      handler,
      one,
      fired: false,
    });
  };

  const toggleOn = () => {
    eventTrigger('beforeOn');
    KTUtil.addClass(the.target, the.targetState);

    if (the.togglerState) {
      KTUtil.addClass(the.element, the.togglerState);
    }

    the.state = 'on';
    eventTrigger('afterOn');
    eventTrigger('toggle');
  };

  const toggleOff = () => {
    eventTrigger('beforeOff');
    KTUtil.removeClass(the.target, the.targetState);

    if (the.togglerState) {
      KTUtil.removeClass(the.element, the.togglerState);
    }

    the.state = 'off';
    eventTrigger('afterOff');
    eventTrigger('toggle');
  };

  const toggle = e => {
    eventTrigger('beforeToggle');

    if (the.state === 'off') {
      toggleOn();
    } else {
      toggleOff();
    }

    eventTrigger('afterToggle');
    if (e) e.preventDefault();
  };

  const build = () => {
    KTUtil.addEvent(the.element, 'mouseup', toggle);
  };

  if (!KTUtil.data(the.element).has('toggle')) {
    build();
    KTUtil.data(the.element).set('toggle', the);
  } else {
    the = KTUtil.data(the.element).get('toggle');
  }

  return {
    setDefaults: options => {
      defaultOptions = options;
    },
    getState: () => {
      return the.state;
    },
    toggle: () => {
      toggle();
    },
    toggleOn: () => {
      toggleOn();
    },
    toggleOff: () => {
      toggleOff();
    },
    on: (name, handler) => {
      addEvent(name, handler);
    },
    one: (name, handler) => {
      addEvent(name, handler, true);
    },
  };
};

// webpack support
if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
  module.exports = KTToggle;
}

export default KTToggle;
