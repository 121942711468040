import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { editCompanyTariff } from '../../../../crud/tariffs.crud';
import { getResponseMessage } from '../../../../utils/utils';

export const useEditCompanyTariff = (successFunc?: () => void) => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [loadingEditCompanyTariff, setLoading] = useState(false);
  const [successEditCompany, setSuccess] = useState(false);
  const editCompanyTariffFetch = useCallback(
    async (id: number, days: number) => {
      setLoading(true);
      editCompanyTariff(id, days)
        .then(() => {
          enqueueSnackbar(`${intl.formatMessage({ id: 'COMPANY.TARIFF.CHANGED' })}`, {
            variant: 'success',
          });
          successFunc && successFunc();
          setSuccess(true);
        })
        .catch(e => {
          enqueueSnackbar(`${intl.formatMessage({ id: 'ERROR' })}: ${getResponseMessage(e)}`, {
            variant: 'error',
          });
        })
        .finally(() => {
          setLoading(false);
          setSuccess(false);
        });
    },
    [successFunc]
  );

  return {
    editCompanyTariffFetch,
    successEditCompany,
    loadingEditCompanyTariff,
  };
};
