import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { makeAxiosGetRequest, makeAxiosPostRequest } from '../utils/utils';
import { TCollection } from '../pages/home/collections/types';

type AxiosGetCallbackReturn<T = any> = {
  data: T | null;
  loading: boolean;
  err: string;
  makeRequest: (url: string) => void;
};
export const useAxiosGetCallback = <T extends any>(): AxiosGetCallbackReturn<T> => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState<any>(null);

  const makeRequest = useCallback((url: string) => {
    makeAxiosGetRequest(url, setData, setLoading, setErr);
  }, []);

  return { data, loading, err, makeRequest };
};

export const useAxiosGet = (url: string) => {
  const [data, setData] = useState<TCollection | null>(null);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState<any>(null);

  useEffect(() => {
    makeAxiosGetRequest(url, setData, setLoading, setErr);
  }, [url]);

  const reload = () => makeAxiosGetRequest(url, setData, setLoading, setErr);

  return [data, loading, err, reload];
};

export const useAxiosPostCallback = () => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState<any>(null);

  const makeRequest = useCallback((url: string, data?: any) => {
    makeAxiosPostRequest(url, data, setData, setLoading, setErr);
  }, []);

  return { data, loading, err, makeRequest };
};

export const useAxiosDeleteCallback = () => {
  const [delRes, setRes] = useState<any>(null);
  const [delLoading, setLoading] = useState(false);
  const [delErr, setErr] = useState<any>(null);

  const del = useCallback((url: string, id: string) => {
    setLoading(true);
    axios
      .delete(url + id)
      .then(res => setRes(res))
      .catch(err => {
        if (err) setErr(String(err));
      })
      .finally(() => {
        setLoading(false);
        setErr(null);
      });
  }, []);

  return { delRes, delLoading, delErr, del };
};
