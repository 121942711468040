import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  MenuItem,
  Select,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import EditIcon from '@mui/icons-material/Edit';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import LinkIcon from '@mui/icons-material/Link';

import { SHeadTableCell } from '../../styledComponents/Tables';
import TablePaginator from '../TablePaginator/TablePaginator';
import Preloader from '../../other/Preloader/Preloader';

import { IUser, UserRoles, UserRoleType } from '../../../interfaces/user';
import { IPaginationData } from '../../../interfaces/server';
import { getRoleName } from '../../../pages/home/users/users/utils';
import NoRowsOverlay from './NoRowsOverlay';

interface IProps {
  users: IUser[];
  loading: boolean;
  onEdit: (id: number) => any;
  onConnectOff?: (id: number) => any;
  onConnect?: (id: number, login: string) => any;
  connectedUsers?: number[];
  paginationData: IPaginationData;
  fetch?: (data: { page: number; perPage: number }) => void;
  showEdit?: boolean;
  selectedRole?: boolean;
  editManagerRole?: (
    manager_id: number,
    role: 'ROLE_VENDOR' | 'ROLE_VENDOR_STAFF' | 'ROLE_BUYER' | 'ROLE_BUYER_STAFF'
  ) => void;
}

const useStyles = makeStyles()(() => {
  return {
    empty: {
      height: 200,
    },
    table: {
      marginTop: 20,
    },
    emptyOverlay: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
  };
});

const UsersTable: React.FC<IProps> = ({
  users,
  loading,
  paginationData: { page, perPage, total },
  onConnectOff,
  onConnect,
  connectedUsers,
  onEdit,
  fetch,
  selectedRole,
  editManagerRole,
  showEdit,
}) => {
  const intl = useIntl();
  const classes1 = useStyles();
  const { classes } = classes1;
  const isShowSelected = useCallback(
    (roles: UserRoleType[]) => {
      if (!selectedRole) return false;
      if (!editManagerRole) return false;

      if (roles.includes('ROLE_VENDOR')) return true;
      if (roles.includes('ROLE_VENDOR_STAFF')) return true;
      if (roles.includes('ROLE_BUYER')) return true;
      if (roles.includes('ROLE_BUYER_STAFF')) return true;
      return false;
    },
    [selectedRole, editManagerRole]
  );

  const isRole = useCallback(
    (roles: UserRoleType[], role: UserRoleType) => roles.includes(role),
    []
  );

  if (loading) return <Preloader />;

  return (
    <Table aria-label='stores' className={classes.table}>
      <TableHead>
        <TableRow>
          <SHeadTableCell>{intl.formatMessage({ id: 'USER.TABLE.ID' })}</SHeadTableCell>
          <SHeadTableCell>{intl.formatMessage({ id: 'USER.TABLE.LOGIN' })}</SHeadTableCell>
          <SHeadTableCell>{intl.formatMessage({ id: 'USER.TABLE.EMAIL' })}</SHeadTableCell>
          <SHeadTableCell>{intl.formatMessage({ id: 'USER.TABLE.ROLE' })}</SHeadTableCell>
          <SHeadTableCell>{intl.formatMessage({ id: 'USER.TABLE.FIO' })}</SHeadTableCell>
          <SHeadTableCell align='right'>
            {intl.formatMessage({ id: 'USER.TABLE.ACTION' })}
          </SHeadTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {loading && (
          <TableRow>
            <TableCell className={classes.empty} colSpan={6} component='th' scope='row'>
              <Preloader />
            </TableCell>
          </TableRow>
        )}

        {!loading && !users.length && (
          <TableRow>
            <TableCell className={classes.empty} colSpan={6} component='th' scope='row'>
              <div className={classes.emptyOverlay}>
                <NoRowsOverlay label={intl.formatMessage({ id: 'MANAGER.EMPTY' })} />
              </div>
            </TableCell>
          </TableRow>
        )}

        {users.map(item => (
          <TableRow key={item.id}>
            <TableCell component='th' scope='row'>
              {item.id}
            </TableCell>
            <TableCell>{item.login}</TableCell>
            <TableCell>{item.email}</TableCell>
            <TableCell>
              {isShowSelected(item.roles) && editManagerRole ? (
                <Select
                  value={item.roles[0]}
                  onChange={(e: any) => {
                    editManagerRole(item.id, e.target.value);
                  }}
                >
                  <MenuItem
                    hidden={
                      !isRole(item.roles, UserRoles.ROLE_VENDOR) &&
                      !isRole(item.roles, UserRoles.ROLE_VENDOR_STAFF)
                    }
                    value={UserRoles.ROLE_VENDOR}
                  >
                    {intl.formatMessage({ id: 'USER.ROLES.VENDOR' })}
                  </MenuItem>
                  <MenuItem
                    hidden={
                      !isRole(item.roles, UserRoles.ROLE_VENDOR) &&
                      !isRole(item.roles, UserRoles.ROLE_VENDOR_STAFF)
                    }
                    value={UserRoles.ROLE_VENDOR_STAFF}
                  >
                    {intl.formatMessage({ id: 'USER.ROLES.VENDOR.STAFF' })}
                  </MenuItem>
                  <MenuItem
                    hidden={
                      !isRole(item.roles, UserRoles.ROLE_BUYER) &&
                      !isRole(item.roles, UserRoles.ROLE_BUYER_STAFF)
                    }
                    value={UserRoles.ROLE_BUYER}
                  >
                    {intl.formatMessage({ id: 'USER.ROLES.BUYER' })}
                  </MenuItem>
                  <MenuItem
                    hidden={
                      !isRole(item.roles, UserRoles.ROLE_BUYER) &&
                      !isRole(item.roles, UserRoles.ROLE_BUYER_STAFF)
                    }
                    value={UserRoles.ROLE_BUYER_STAFF}
                  >
                    {intl.formatMessage({ id: 'USER.ROLES.BUYER.STAFF' })}
                  </MenuItem>
                </Select>
              ) : (
                getRoleName(item.roles, intl)
              )}
            </TableCell>
            <TableCell>
              {`${item.last_name || ''} ${item.first_name || ''} ${item.middle_name || ''}`}
            </TableCell>
            <TableCell>
              {showEdit && (
                <IconButton
                  color='primary'
                  aria-label='previous'
                  onClick={() => onEdit(item.id)}
                >
                  <EditIcon />
                </IconButton>
              )}

              {onConnectOff && !onConnect && (
                <IconButton onClick={() => onConnectOff(item.id)}>
                  <LinkOffIcon color='error' />
                </IconButton>
              )}

              {onConnect && (
                <IconButton
                  onClick={
                    onConnectOff && connectedUsers && connectedUsers.includes(item.id)
                      ? () => onConnectOff(item.id)
                      : () => onConnect(item.id, item.login)
                  }
                >
                  {connectedUsers && connectedUsers.includes(item.id) ? (
                    <LinkOffIcon color='error' />
                  ) : (
                    <LinkIcon color='primary' />
                  )}
                </IconButton>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      {!!fetch && (
        <TableFooter>
          <TableRow>
            <TablePaginator
              page={page}
              perPage={perPage}
              total={total}
              realPerPage={users.length}
              fetchRows={fetch}
              label={intl.formatMessage({ id: 'USERS.TABLE.PER_PAGE' })}
            />
          </TableRow>
        </TableFooter>
      )}
    </Table>
  );
};

export default UsersTable;
