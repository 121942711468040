import React, { FC } from 'react';
import { useStyles } from './hooks/useStyles';
// import { colors } from '../../../constants/colors';
// import { space } from '../../../utils/cssSpace';

export enum TextType {
  lightH1 = 'lightH1',
  boldH1 = 'boldH1',
  lightH2 = 'lightH2',
  boldH2 = 'boldH2',
  regularH3 = 'regularH3',
  boldH3 = 'boldH3',
  regularH4 = 'regularH4',
  boldH4 = 'boldH4',
  regularH5 = 'regularH5',
  semiboldH5 = 'semiboldH5',
  mediumH6 = 'mediumH6',
  semiboldH6 = 'semiboldH6',
  regularSubtitle1 = 'regularSubtitle1',
  semiboldSubtitle1 = 'semiboldSubtitle1',
  mediumSubtitle2 = 'mediumSubtitle2',
  semiboldSubtitle2 = 'semiboldSubtitle2',
  body1 = 'body1',
  body2 = 'body2',
  semiboldButton = 'semiboldButton',
  caption = 'caption',
  overline = 'overline',
}

interface ITextProps {
  type?: TextType;
  color?: string;
  padding?: {
    top?: number | string;
    right?: number | string;
    bottom?: number | string;
    left?: number | string;
  };
  margin?: {
    top?: number | string;
    right?: number | string;
    bottom?: number | string;
    left?: number | string;
  };
}

export const Text: FC<ITextProps & React.HTMLAttributes<HTMLParagraphElement>> = ({
  type = TextType.body1,
  children,
  // color = colors.primary.black,
  margin,
  padding,
  ...props
}) => {
  const classes = useStyles();
  // const marginSpace = space('margin', margin?.top, margin?.right, margin?.bottom, margin?.left);
  // const paddingSpace = space(
  //   'padding',
  //   padding?.top,
  //   padding?.right,
  //   padding?.bottom,
  //   padding?.left
  // );

  return (
    <p
      {...props}
      className={`${classes[type]} ${props.className || ''}`}
      // style={{ color, ...marginSpace, ...paddingSpace, ...props.style }}
    >
      {children}
    </p>
  );
};
