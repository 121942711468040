import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme)=>{
  return {
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      padding: '0',
    },
    tableContainer: {
      paddingTop: theme.spacing(1),
      // paddingLeft: theme.spacing(2),
      // paddingRight: theme.spacing(2),
      paddingLeft: 0,
      paddingRight: 0,
      overflowX: 'auto',
    },
    filterColTop: {
      width: '100%',
    },
    inputFocused: {
      backgroundColor: 'white !important',
    },
    menuPaper: {
      maxHeight: 300,
    },
    empty: {
      marginBottom: 20,
      marginTop: 20,
    },
    count: {
      fontStyle: 'bold',
      fontWeight: 600,
      color: '#000',
      marginRight: 15,
    },
    filterCol: {
      width: '100%',
      marginBottom: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        width: 326,
      },
    },
    filter: {
      marginBottom: 5,
      padding: '25px 30px',
      overflow: 'visible',
    },
    label: {
      marginBottom: 7,
      fontSize: 14,
      fontWeight: 600,
      color: '#181c32',
    },
    rowFilter: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    ordersCol: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 'calc(100% - 326px - 30px)',
        '&.full': {
          width: '100%',
        },
      },
    },
    orderColHeader: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 30,
    },
    orderColHeaderItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    filterItem: {
      transition: '0.3s ease-out',

      '&.hide': {
        [theme.breakpoints.down('sm')]: {
          height: 0,
          transform: 'scaleY(0)',
          transformOrigin: 'top',
          overflow: 'hidden',
        },
      },
    },
    buttons: {
      marginRight: theme.spacing(3),
    },
    card: {
      // marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    bottomActions: {
      marginTop: theme.spacing(3),
    },
    img: {
      width: 50,
      // height: 50,
      objectFit: 'cover',
    },
    typeList: {
      backgroundColor: 'white',
      maxWidth: 200,
    },
    itemStatus: {
      borderRadius: '4px',
      backgroundColor: '#c9f7f5',
      flex: 1,
    },
    viewButton: {
      marginRight: theme.spacing(2),
      color: theme.palette.primary.dark,
      textTransform: 'none',
      boxShadow: 'none',
      borderRadius: 2,
      padding: '10px 35px',
      fontWeight: 700,
      background: '#EBECF0',

      '&:hover': {
        boxShadow: 'none',
      },
    },
    iconViewButton: {
      marginLeft: 10,
    },
    companyField: {
      display: 'flex',
      alignItems: 'center',
    },
    allCompaniesBtn: {
      marginTop: 8,
      marginLeft: 10,
      height: 52,
    },
    input: {
      width: '100%',
      padding: '18px 14px',
      borderWidth: 0,
      borderRadius: 4,
      marginTop: 15,
      outline: 'none',
      boxSizing: 'border-box',
    },
    cell: {
      // fontSize: 18,
    },
    cellItem: {
      // fontSize: 14,
    },
    icon: {
      width: 22,
      height: 22,
    },
    subTableRow: {
      backgroundColor: '#d7d7d7',
    },
}});
