import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { Paper } from '@mui/material';

import Preloader from '../../../components/other/Preloader/Preloader';
import { IAppState } from '../../../store/rootDuck';
import { actions as categoriesActions } from '../../../store/ducks/categories.duck';
import { actions as requestActions } from '../../../store/ducks/request.duck';
import { setLayoutSubheader } from '../../../utils/layout';
import useHomeStyles from '../../../constants/homeStyles';
import { actions as profileActions } from '../../../store/ducks/profile.duck';
import { useFormatMessage } from '../../../hooks';
import ImageGalleryRequest from './components/ImageGallery';
import { useFormikRequestPage } from './hooks/useFormikRequestPage';
import { useStylesSearchView } from './hooks/useStyles';

const RequestView: React.FC<TPropsFromRedux> = ({
  fetch,
  fetchCategories,
  categories,
  me,
  loadingMe,
  request,
  fetchMe,
  loading,
  clearMe,
  clearEdit,
}) => {
  const { id } = useParams();
  const styles = useHomeStyles();
  const classes = useStylesSearchView();
  const fm = useFormatMessage();

  const { formik } = useFormikRequestPage(
    () => {},
    () => {},
    request,
    categories
  );

  const { values } = formik;

  const mainCategory = useMemo(() => {
    if (categories && values.mainCategoryId) {
      return categories.find(item => item.id?.toString() === values.mainCategoryId.toString());
    }
  }, [values.mainCategoryId]);

  setLayoutSubheader({
    title: fm('REQUEST.VIEW'),
    back: true,
    breadcrumb: [
      {
        title: fm('REQUEST.TITLE'),
        root: true,
        page: 'requests/list',
        translate: 'REQUEST.TITLE',
      },
    ],
  });

  useEffect(() => {
    id && fetch(+id);
  }, [id]);

  useEffect(() => {
    fetchMe();
    fetchCategories();
    return () => {
      clearEdit();
    };
  }, []);

  useEffect(() => {
    return () => {
      clearMe();
    };
  }, []);

  if (!me || loadingMe || loading || !request) return <Preloader />;

  return (
    <Paper className={styles.classes.container} style={{ marginTop: 0 }}>
      <div className={classes.card}>
        <div className={classes.leftCol}>
          <ImageGalleryRequest request={request} />
        </div>
        <div className={classes.rightCol}>
          <div className={classes.header}>
            <div>
              <p className={classes.name}>{request.title}</p>
              {mainCategory && (
                <>
                  <p className={classes.categoryName}>{mainCategory.name}</p>
                  <p className={classes.category}>{fm('PRODUCT.EDIT.CATEGORY')}</p>
                </>
              )}
              {request.text && <p className={classes.comment}>{request.text}</p>}
            </div>
          </div>
        </div>
      </div>
    </Paper>
  );
};

const connector = connect(
  (state: IAppState) => ({
    me: state.profile.me,
    loadingMe: state.profile.loading,

    categories: state.categories.categories,

    request: state.request.request,
    loading: state.search.byIdLoading,
  }),
  {
    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,

    fetchCategories: categoriesActions.fetchFullRequest,

    fetch: requestActions.fetchByIdRequest,
    clearEdit: requestActions.clearEdit,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RequestView);
