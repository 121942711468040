import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { getResponseMessage } from '../../../../utils/utils';
import { createGroups } from '../../../../crud/productType.crud';
import { useFormatMessage } from '../../../../hooks';

export const useCreateGroup: any = () => {
  const { enqueueSnackbar } = useSnackbar();
  const fm = useFormatMessage();
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState<number | null>(null);

  const create = useCallback(async (name: string) => {
    setLoading(true);
    createGroups(name)
      .then(res => {
        setId(res.data.data.id);
        enqueueSnackbar(fm('GROUP.SAVED'), { variant: 'success' });
      })
      .catch(e => {
        enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`, { variant: 'error' });
      })
      .finally(() => {
        setId(null);
        setLoading(false);
      });
  }, []);

  return [create, loading, id];
};
