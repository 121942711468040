import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { Paper, Tab, Tabs } from '@mui/material';
import { isNumber } from 'lodash';

import Preloader from '../../../components/other/Preloader/Preloader';
import { IAppState } from '../../../store/rootDuck';
import { actions as categoriesActions } from '../../../store/ducks/categories.duck';
import { setLayoutSubheader } from '../../../utils/layout';
import useHomeStyles from '../../../constants/homeStyles';
import { actions as profileActions } from '../../../store/ducks/profile.duck';
import { actions as searchActions } from '../../../store/ducks/search.duck';
import { useFormatMessage } from '../../../hooks';
import { formatAsThousands } from '../../../utils/utils';
import ImageGallerySearch from './components/ImageGallery';
import { useFormikSearchPage } from './hooks/useFormikSearchPage';
import { levelsSubCategories, useSubCategories } from './hooks/useSubCategories';
import { ICategoryItem } from '../categories/interfaces';
import SearchAddProduct from './components/SearchAddProduct';
import { useStylesSearchView } from './hooks/useStyles';
import ProposalsForm from './components/ProposalsForm';

const SearchView: React.FC<TPropsFromRedux> = ({
  fetch,
  fetchCategories,
  categories,
  me,
  loadingMe,
  tender,
  fetchMe,
  loading,
  clearMe,
  clearEdit,
  clearPart,
  fetchParticipation,
  participations,
}) => {
  const { id } = useParams();
  const styles = useHomeStyles();
  const classes = useStylesSearchView();
  const fm = useFormatMessage();
  const { state } = useLocation();

  const [tabValue, setTabValue] = useState(0);

  const { formik } = useFormikSearchPage(
    () => {},
    () => {},
    tender,
    categories
  );

  const { values, setFieldValue } = formik;

  const [getSubCategories] = useSubCategories(values, setFieldValue, categories);

  const mainCategory = useMemo(() => {
    if (categories && values.mainCategoryId) {
      return categories.find(item => item.id?.toString() === values.mainCategoryId.toString());
    }
  }, [values.mainCategoryId]);

  setLayoutSubheader({
    title: fm('SEARCH.VIEW'),
    back: true,
    breadcrumb: [
      {
        title: fm('SEARCH.TITLE'),
        root: true,
        page: 'product_search/list',
        translate: 'SEARCH.TITLE',
      },
    ],
  });

  useEffect(() => {
    fetchMe();
    fetchCategories();
    state?.isProposals && setTabValue(1);
    return () => {
      clearEdit();
      clearPart();
    };
  }, []);

  useEffect(() => {
    id && fetch(Number(id));
    id && fetchParticipation({ tender_id: +id, page: 1, per_page: 20 });
  }, [id]);

  useEffect(() => {
    return () => {
      clearMe();
    };
  }, []);

  if (!me || loadingMe || loading || !tender) return <Preloader />;

  return (
    <Paper className={styles.classes.container} style={{ marginTop: 0 }}>
      <div className={classes.tabs}>
        <Tabs
          value={tabValue}
          onChange={(_: React.ChangeEvent<{}>, newValue: number) => {
            setTabValue(newValue);
          }}
          indicatorColor='primary'
          variant='fullWidth'
          textColor='primary'
          style={{ minHeight: 0 }}
        >
          <Tab label={fm('SEARCH.PAGE.TAB.ONE')} className={classes.labelTab} />
          <Tab
            label={`${fm('SEARCH.PAGE.TAB.TWO')}${
              participations && participations.length > 0 ? ` (${participations.length})` : ''
            }`}
            disabled={!id}
            className={classes.labelTab}
          />
        </Tabs>
      </div>

      <div style={{ display: tabValue === 0 ? 'contents' : 'none' }}>
        <div className={classes.card}>
          <div className={classes.leftCol}>
            <ImageGallerySearch search={tender} />
          </div>
          <div className={classes.rightCol}>
            <div className={classes.header}>
              <div>
                <p className={classes.name}>{tender.name}</p>
                <p className={classes.price}>
                  {isNumber(tender.price) ? formatAsThousands(tender.price) : 0}
                  <b className={classes.rub}> руб.</b>
                </p>
                {mainCategory && (
                  <>
                    <p className={classes.categoryName}>{mainCategory.name}</p>
                    <p className={classes.category}>{fm('PRODUCT.EDIT.CATEGORY')}</p>
                  </>
                )}
                {levelsSubCategories.map(level => {
                  const subCategories = getSubCategories(level);
                  const category =
                    subCategories &&
                    subCategories.find((item: ICategoryItem) => {
                      if (item && values[`categoryId_${level}`]) {
                        return (
                          item.id?.toString() === values[`categoryId_${level}`].toString()
                        );
                      }
                    });
                  if (subCategories && subCategories?.length > 0 && category) {
                    return (
                      <>
                        <p className={classes.categoryName}>{category.name}</p>
                        <p className={classes.category}>{`${fm(
                          'PRODUCT.EDIT.SUBCATEGORY'
                        )} ${level}`}</p>
                      </>
                    );
                  }
                  return <></>;
                })}
                {tender.comment && <p className={classes.comment}>{tender.comment}</p>}
                {tender.user?.id.toString() !== me.id.toString() && id && (
                  <SearchAddProduct id={id} changeTab={() => setTabValue(1)} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ display: tabValue === 1 ? 'contents' : 'none' }}>
        <div className={classes.card}>
          <ProposalsForm id={id || ''} search={tender} />
        </div>
      </div>
    </Paper>
  );
};

const connector = connect(
  (state: IAppState) => ({
    me: state.profile.me,
    loadingMe: state.profile.loading,

    categories: state.categories.categories,

    tender: state.search.tender,
    loading: state.search.byIdLoading,
    participations: state.search.participations,
  }),
  {
    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,

    fetchCategories: categoriesActions.fetchFullRequest,

    fetch: searchActions.fetchByIdRequest,
    clearEdit: searchActions.clearEdit,
    clearPart: searchActions.clearPart,
    fetchParticipation: searchActions.fetchParticipation,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SearchView);
