import React from 'react';
import { makeStyles } from '@mui/styles';

interface IProps {
  color: string;
}

const useStyles = makeStyles({
  dot: {
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: (props: IProps) => `${props.color}`,
    marginRight: 8,
    marginTop: 4,
    flex: 'none',
    alignSelf: 'flex-start',
  },
});

const ColorDot: React.FC<IProps> = ({ color }) => {
  const classes = useStyles({ color });
  return <div className={classes.dot} />;
};

export default ColorDot;
