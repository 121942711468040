import React, { useEffect, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { Button, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { useNavigate } from 'react-router-dom';

import { actions as bannersActions } from '../../../store/ducks/banners.duck';
import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';

import AlertDialog from '../../../components/ui/Dialog/AlertDialog';
import { IAppState } from '../../../store/rootDuck';
import Preloader from '../../../components/other/Preloader/Preloader';
import { IBanner, PlacementType } from './interfaces';
import BannersTree from './components/BannersTree';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    empty: {
      marginBottom: 20,
      marginTop: 20,
      color: 'rgb(0, 90, 90)',
    },
    buttons: {
      marginRight: theme.spacing(3),
    },
    card: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    categoryTree: {
      maxWidth: 400,
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    img: {
      width: 100,
      height: 50,
    },
  })
);

const BannersListPage: React.FC<TPropsFromRedux> = ({
  page,
  perPage,
  loading,
  fetch,
  delBanner,
  bannersMap,
  delSuccess,
  clearDel,
}) => {
  const [deleteId, setDeleteId] = useState<number | undefined>(-1);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [placement, setPlacement] = useState<PlacementType | null>(null);

  const classes = useStyles();
  const intl = useIntl();
  const navigate = useNavigate();

  const selectPlacement = useCallback(
    (placement: PlacementType) => {
      setPlacement(placement);
    },
    [placement]
  );

  useEffect(() => {
    fetch({ page: 1, perPage: 20 });
  }, [fetch]);

  useEffect(() => {
    if (delSuccess) {
      fetch({ page, perPage });
      clearDel();
    }
  }, [delSuccess]);

  setLayoutSubheader({
    title: intl.formatMessage({ id: 'MENU.PRODUCTS.BANNERS' }),
  });
  setLayoutFooter({ show: true });

  const handleOpenDeleteDialog = useCallback((id: number | undefined) => {
    setDeleteId(id);
    setAlertOpen(true);
  }, []);

  const deleteAction = useCallback(() => {
    setAlertOpen(false);
    if (deleteId) {
      delBanner({ id: deleteId });
    }
  }, [deleteId, delBanner]);

  const goToCreateBannerType = useCallback(
    (placementProps?: PlacementType) => {
      navigate(`/banners/create`, {
        state: { placement: placementProps || placement },
      });
    },
    [navigate, placement]
  );

  const goToEditBanner = useCallback(
    (item: IBanner) => {
      navigate(`/banners/edit/${item.id}`);
    },
    [navigate]
  );

  if (!bannersMap || loading) return <Preloader />;

  return (
    <>
      <AlertDialog
        open={isAlertOpen}
        message={intl.formatMessage({ id: 'BANNERS.DELETE.TEXT' })}
        okText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })}
        handleClose={() => {
          // clearDel();
          setAlertOpen(false);
        }}
        styleActions={{
          justifyContent: 'space-between',
        }}
        handleAgree={deleteAction}
      />

      <Button color='primary' variant='contained' onClick={() => goToCreateBannerType()}>
        {intl.formatMessage({ id: 'BANNERS.ADD.TEXT' })}
      </Button>
      <BannersTree
        banners={bannersMap || []}
        handleDeleteDialog={handleOpenDeleteDialog}
        editAction={goToEditBanner}
        pushAction={goToCreateBannerType}
        classes={classes}
        productListAction={() => {}}
        selectProductId={() => {}}
        selectPlacement={selectPlacement}
        is_admin
        is_manager={false}
      />
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    page: state.banners.page,
    perPage: state.banners.per_page,
    total: state.banners.total,
    loading: state.banners.bannersLoading,
    banners: state.banners.banners,
    delSuccess: state.banners.deleteBannerSuccess,
    bannersMap: state.banners.bannersMap,
  }),
  {
    fetch: bannersActions.bannersRequest,
    delBanner: bannersActions.deleteBannerRequest,
    clearDel: bannersActions.clearDeleteBanner,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(BannersListPage);
