import React, {FC, } from 'react';
import { makeStyles } from 'tss-react/mui';
import {IProduct} from '../../../../../interfaces/product';
import {IUser} from '../../../../../interfaces/user';
import {ICart, IGuestCart} from "../../../cart/interfaces";
import ProductsListItem from "./ProductListItem";
import TablePaginator from "../../../../../components/TablePaginator/TablePaginator";
import {useFormatMessage} from "../../../../../hooks";

const useStyles = makeStyles()((theme)=>{
  return {
    container: {
      display: 'grid',
      gap: 19,
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
}});

interface IProductsProps {
  data: IProduct[];
  cart: ICart | null;
  guestCart: IGuestCart | null;

  page?: number;
  perPage?: number;
  total?: number;
  categoryId: string;
  companyId: string;
  fetch?: (data: { page: number; perPage: number }) => void;
  handleSetCountCart: (type: 'dec' | 'inc', count: number, product: IProduct) => void;
  viewAction: any;
  editAction?: any;
  handleDeleteDialog?: any;
  isAuthorized: boolean;
  isAdmin: boolean;
  me: IUser | undefined;
  addProductLoading: boolean;
  handleCartDialog: any;
  isPaginator?: boolean;
  setProductCountLoading: boolean;
}

const ProductsList: FC<IProductsProps> = props => {
  const {
    data,
    isPaginator = true,
    categoryId,
    page,
    perPage,
    total,
    companyId,
    fetch,
  } = props;
  const fm = useFormatMessage();
  const classes1 = useStyles();
  const {classes} = classes1

  return (
    <>
      <div className={classes.container}>
        {data.map(item => {
          return (
            <ProductsListItem
              key={(item.id + item.name).toString()}
              {...props}
              product={item}
            />
          );
        })}
        {isPaginator && fetch && (
          <div style={{ display: 'flex', justifyContent: 'center' }} id='catalog-paginator'>
            <TablePaginator
              id={+categoryId}
              page={page || 1}
              perPage={perPage || 20}
              total={total || 0}
              realPerPage={data.length}
              fetchRows={fetch}
              companyId={companyId}
              rowsPerPageOptions={[12, 36, 108]}
              label={fm('PRODUCT.TABLE.PERPAGE')}
            />
          </div>
        )}
      </div>
    </>
  );
};
export default React.memo(ProductsList);
