import React, { useEffect, useRef } from 'react';
// import { useSelector, shallowEqual } from 'react-redux';
import { Tab, Tabs as TabsMaterial } from '@mui/material';
import { useFormatMessage } from '../../../hooks';
// import { IAppState } from '../../../store/rootDuck';
// import { useStyles } from './styles';
// import { toAbsoluteUrl } from '../../../../_base';

type TProps = {
  idForMenu: string;
  tabs: { id: string; isChecked?: boolean }[];
  value: number;
  setTabValue: (value: number) => void;
  isNumbers?: boolean;
  isCheckTabICon?: boolean;
  disabledTabs?: boolean;
};

const Tabs: React.FC<TProps> = ({
  // idForMenu,
  tabs,
  value,
  setTabValue,
  // isNumbers,
  // isCheckTabICon,
  disabledTabs,
}) => {
  // const classes = useStyles();
  const fm = useFormatMessage();
  const elRef: any = useRef();
  // const menuConfig = useSelector(
  //   ({ builder }: IAppState) => builder.menuConfig.aside.items,
  //   shallowEqual
  // );

  useEffect(() => {
    const el: any = elRef.current;
    if (el) {
      const onWheel = (e: any) => {
        if (e.deltaY === 0) return;
        e.preventDefault();
        el.scrollTo({
          left: el.scrollLeft + e.deltaY,
        });
      };
      el.addEventListener('wheel', onWheel);
      return () => el.removeEventListener('wheel', onWheel);
    }
  }, []);

  // const menu: any = useMemo(() => menuConfig.find(menu => menu.title === idForMenu), [
  //   menuConfig,
  //   idForMenu,
  // ]);
  return (
    <>
      <TabsMaterial
        value={value}
        onChange={(_: React.ChangeEvent<{}>, newValue: number) => {
          !disabledTabs && setTabValue(newValue);
        }}
        indicatorColor='primary'
        textColor='primary'
      >
        {tabs.map(item => (
          <Tab label={fm(item.id)} />
        ))}
      </TabsMaterial>
      {/* <div className={classes.container}>
        <PerfectScrollbar
          component='div'
          containerRef={ref => {
            elRef.current = ref;
          }}
          className={classes.scrollBar}
        >
          {tabs.map((item, index) => (
            <div
              key={item.id}
              style={{
                paddingLeft: index === 0 ? 40 : 0,
              }}
              className={classes.tabBlock}
              onClick={() => !disabledTabs && setTabValue(index)}
            >
              {menu && (
                <div
                  className={classes.btnBlock}
                  style={{
                    backgroundColor: index === value ? '#DA5C2BFF' : '#C8CEE0FF',
                  }}
                >
                  {isNumbers && (
                    <>
                      {item.isChecked ? (
                        <img
                          alt='check'
                          src={toAbsoluteUrl(
                            `/media/logos/${
                              value === index ? 'checkActiveTab.svg' : 'checkTab.svg'
                            }`
                          )}
                        />
                      ) : (
                        <b
                          className={classes.btnText}
                          style={{
                            color: index === value ? '#ffffff' : '#223D85FF',
                          }}
                        >
                          {index + 1}
                        </b>
                      )}
                    </>
                  )}
                  {!isNumbers && (
                    <>
                      {index === value && menu.activeIcon ? (
                        <img alt='logo' src={menu.activeIcon} height={22} width={22} />
                      ) : (
                        <>
                          {item.isChecked ? (
                            <img
                              alt='check'
                              src={toAbsoluteUrl(
                                `/media/logos/${
                                  value === index ? 'checkActiveTab.svg' : 'checkTab.svg'
                                }`
                              )}
                            />
                          ) : (
                            menu.defaultIcon && (
                              <img alt='logo' src={menu.defaultIcon} height={22} width={22} />
                            )
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              )}
              <b
                className={classes.btnText}
                style={{
                  color: index === value ? '#DA5C2BFF' : '#223D85FF',
                }}
              >
                {fm(item.id)}
              </b>
            </div>
          ))}
        </PerfectScrollbar>
      </div>
      <div className={classes.bottomBlocK} /> */}
    </>
  );
};

export default React.memo(Tabs);
