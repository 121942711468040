import React from 'react';
import { Routes, Route } from 'react-router-dom';
import StatisticsOrdersList from './components/StatisticsOrdersList';
import StatisticsProductsList from './components/StatisticsProductsList';
import StatisticsDetailedOrdersList from './components/StatisticsDetailedOrdersList';

export default function StatisticsPage() {
  return (
    <Routes>
      <Route path='list/orders' element={<StatisticsOrdersList />} />
      <Route path='list/products' element={<StatisticsProductsList />} />
      <Route path='list/detailed/orders' element={<StatisticsDetailedOrdersList />} />
    </Routes>
  );
}
