import { IntlShape } from 'react-intl';

export function getDocFromPathname(pathname: string, intl: IntlShape) {
  switch (pathname) {
    case 'legacy':
      return {
        code: 'user_agreement',
        name: intl.formatMessage({ id: 'SUBMENU.LEGAL_TERMS' }),
      };
    case 'privacy':
      return {
        code: 'privacy_policy',
        name: intl.formatMessage({ id: 'SUBMENU.LEGAL_PRIVACY' }),
      };
    case 'shipping':
      return {
        code: 'shipping_policy',
        name: intl.formatMessage({ id: 'SUBMENU.LEGAL_SHIPPING' }),
      };
    case 'refund':
      return {
        code: 'refund_policy',
        name: intl.formatMessage({ id: 'SUBMENU.LEGAL_REFUND' }),
      };

    default:
      return {
        code: 'user_agreement',
        name: intl.formatMessage({ id: 'DOC.USER_AGREEMENT' }),
      };
  }
}
