import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { Skeleton } from '@mui/lab';

import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';
import { useAxiosGet, useFormatMessage } from '../../../hooks';
import homeStyles from '../homeStyles';
import { ButtonWithLoader } from '../../../components/ui/Buttons';
import { useFormikEditCollection } from './hooks/useFormikEditCollection';
import useCrudSnackbar from '../../../hooks/useCrudSnackbar';
import {
  useAxiosDeleteCallback,
  useAxiosGetCallback,
  useAxiosPostCallback,
} from '../../../hooks/useAxiosGet';
import { ITypeParameter } from '../../../interfaces/productType';
import TypeParamsInputs from './components/TypeParamsInputs';
import Preloader from '../../../components/ui/Preloader/Preloader';
import { TCollection } from './types';
import { ButtonTheme } from '../../../components/ui/Buttons/ButtonWithLoader';

const EditCollection: React.FC = () => {
  const homeClasses = homeStyles();
  const fm = useFormatMessage();
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    data: collection,
    loading: collectionsLoading,
    err: collectionErr,
    makeRequest: fetchCollection,
  } = useAxiosGetCallback<TCollection>();

  useEffect(() => {
    if (id) fetchCollection(`/api/shop/collection/${id}`);
  }, [id]);

  const [productTypes, productTypesLoading, productTypesErr] = useAxiosGet(
    '/api/shop/product_types'
  );

  const {
    data: params,
    loading: paramsLoading,
    err: paramsErr,
    makeRequest: fetchParams,
  } = useAxiosGetCallback<ITypeParameter[]>();

  const {
    data: saveCollectionRes,
    loading: saveCollectionLoading,
    err: saveCollectionErr,
    makeRequest: saveCollection,
  } = useAxiosPostCallback();

  const {
    delRes: deleteCollectionRes,
    delLoading: deleteCollectionLoading,
    delErr: deleteCollectionErr,
    del: deleteCollection,
  } = useAxiosDeleteCallback();

  const { values, handleBlur, handleChange, touched, errors, handleSubmit, setFieldValue } =
    useFormikEditCollection(collection, saveCollection);

  const changeType = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (e.target.value !== values.productTypeId.toString()) {
        Object.keys(values)
          .filter(key => key.includes('param'))
          .forEach(key => setFieldValue(key, []));
        setFieldValue('productTypeId', e.target.value);
      }
    },
    [values, setFieldValue]
  );

  // fetch product type parameters
  useEffect(() => {
    if (productTypes && values.productTypeId !== '') {
      fetchParams(`/api/shop/product_type/${values.productTypeId}/parameters`);
    }
  }, [productTypes, values.productTypeId]);

  setLayoutSubheader({
    title: `${fm(id ? 'COLLECTION.EDIT' : 'COLLECTION.ADD')}`,
  });
  setLayoutFooter({ show: true });

  useCrudSnackbar({
    success: saveCollectionRes,
    error: productTypesErr || paramsErr || saveCollectionErr || collectionErr,
    successMessage: fm('COLLECTION.SAVED'),
    errorMessage: productTypesErr || paramsErr || saveCollectionErr || collectionErr,
    afterSuccess: () => {
      navigate(-1);
    },
  });

  useCrudSnackbar({
    success: deleteCollectionRes,
    error: deleteCollectionErr,
    successMessage: fm('COLLECTION.DELETE.SUCCESS'),
    errorMessage: deleteCollectionErr,
    afterSuccess: () => {
      navigate(-1);
    },
  });

  if (collectionsLoading) return <Preloader />;

  return (
    <Paper className={homeClasses.classes.container}>
      <div className={homeClasses.classes.form}>
        <TextField
          name='name'
          type='text'
          label={fm('PRODUCT.TYPES.INPUT.NAME')}
          margin='normal'
          value={values.name}
          variant='outlined'
          onBlur={handleBlur}
          onChange={handleChange}
          helperText={errors.name ? errors.name.toString() : ''}
          error={Boolean(touched.name && errors.name)}
        />

        {productTypes && productTypes.length > 0 && !productTypesLoading ? (
          <>
            <TextField
              name='productTypeId'
              select
              margin='normal'
              label={fm('PRODUCT.EDIT.TYPE')}
              value={values.productTypeId || ''}
              onChange={e => changeType(e)}
              variant='outlined'
              error={Boolean(touched.productTypeId && errors.productTypeId)}
            >
              {productTypes.map((option: any) => (
                <MenuItem key={option.id} value={String(option.id) || ''}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
            {!paramsLoading ? (
              <TypeParamsInputs
                params={params}
                values={values}
                setFieldValue={setFieldValue}
              />
            ) : (
              Array.from(Array(3)).map((_, idx) => (
                <Skeleton
                  key={idx}
                  width='100%'
                  height={86}
                  animation='wave'
                  style={{ marginTop: -5, marginBottom: -5 }}
                />
              ))
            )}
          </>
        ) : (
          <Skeleton
            width='100%'
            height={86}
            animation='wave'
            style={{ marginTop: -5, marginBottom: -5 }}
          />
        )}
        <Typography>{fm('SHOW.COLLECTION')}</Typography>
        <FormControlLabel
          // label={fm('SHOW.PLAN.ACTUAL')}
          label={fm('SHOW.COLLECTION.SIDE_MENU')}
          // className={classes.checkbox}
          // disabled={loading}
          control={
            <div>
              <Checkbox
                color='primary'
                value={values.slot1}
                onChange={e => {
                  setFieldValue('slot1', e.target.checked);
                }}
                checked={values.slot1}
              />
            </div>
          }
        />
        <FormControlLabel
          label={fm('SHOW.COLLECTION.MAIN_MENU')}
          control={
            <div>
              <Checkbox
                color='primary'
                value={values.slot2}
                onChange={e => {
                  setFieldValue('slot2', e.target.checked);
                }}
                checked={values.slot2}
              />
            </div>
          }
        />
        <FormControlLabel
          label={fm('SHOW.COLLECTION.MAIN_PAGES')}
          control={
            <div>
              <Checkbox
                color='primary'
                value={values.slot3}
                onChange={e => {
                  setFieldValue('slot3', e.target.checked);
                }}
                checked={values.slot3}
              />
            </div>
          }
        />
        <FormControlLabel
          label={fm('SHOW.COLLECTION.FOOTER')}
          control={
            <div>
              <Checkbox
                color='primary'
                value={values.slot4}
                onChange={e => {
                  setFieldValue('slot4', e.target.checked);
                }}
                checked={values.slot4}
              />
            </div>
          }
        />
        <div className={homeClasses.classes.buttonContainer}>
          <ButtonWithLoader
            loading={saveCollectionLoading}
            onPress={(e: Event) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            {fm('COLLECTION.BUTTON.SAVE')}
          </ButtonWithLoader>
          {id && (
            <ButtonWithLoader
              loading={deleteCollectionLoading}
              theme={ButtonTheme.SECONDARY}
              onPress={() => deleteCollection('/api/shop/collection/', id)}
            >
              {fm('COLLECTION.DELETE.BTN')}
            </ButtonWithLoader>
          )}
          <Button onClick={() => navigate(-1)} variant='outlined' color='primary'>
            {fm('CATEGORY.BUTTON.CANCEL')}
          </Button>
        </div>
      </div>
    </Paper>
  );
};

export default EditCollection;
