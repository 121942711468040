import React, { useState } from 'react';
import { Paper, Tab, Tabs } from '@mui/material';
import { setLayoutSubheader } from '../../../utils/layout';
import useHomeStyles from '../../../constants/homeStyles';
import { useFormatMessage } from '../../../hooks';
import { useStylesSearchView } from '../productSearch/hooks/useStyles';
import ProductSettingTab from './ProductSettingTab';
import { useStyles } from './hooks/useStyles';

const SystemSettingPage = () => {
  const styles = useHomeStyles();
  const classes = useStylesSearchView();
  const style = useStyles();
  const fm = useFormatMessage();

  const [tabValue, setTabValue] = useState(0);

  setLayoutSubheader({
    title: fm('SYSTEM.SETTINGS.TABLE.TITLE'),
  });

  return (
    <Paper className={styles.classes.container} style={{ marginTop: 0 }}>
      <div className={classes.tabs}>
        <Tabs
          value={tabValue}
          onChange={(_: React.ChangeEvent<{}>, newValue: number) => {
            setTabValue(newValue);
          }}
          indicatorColor='primary'
          variant='fullWidth'
          textColor='primary'
          style={{ minHeight: 0 }}
        >
          <Tab label={fm('SUBMENU.CATALOG')} className={classes.labelTab} />

          <Tab label={fm('SYSTEM.SETTINGS.TAB.TITLE')} className={classes.labelTab} />
        </Tabs>
      </div>

      <div style={{ display: tabValue === 0 ? 'contents' : 'none' }}>
        <div className={style.card}>
          <ProductSettingTab />
        </div>
      </div>

      <div style={{ display: tabValue === 1 ? 'contents' : 'none' }}>
        <div className={style.card}>
          <p>tab-2</p>
        </div>
      </div>
    </Paper>
  );
};

export default React.memo(SystemSettingPage);
