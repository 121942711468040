export enum UserListType {
  BUYER = 'buyers',
  MANAGER = 'managers',
  CONTENT_MANAGER = 'content-managers',
  VENDOR = 'vendors',
  STAFF = 'staff',
  BUYER_STAFF_ROLE = 'buyer-staff',
}

export const ADMIN_ROLE = 'ROLE_ADMIN';
export const BUYER_ROLE = 'ROLE_BUYER';
export const MANAGER_ROLE = 'ROLE_MANAGER';
export const VENDOR_ROLE = 'ROLE_VENDOR';
export const VENDOR_STAFF_ROLE = 'ROLE_VENDOR_STAFF';
export const CONTENT_MANAGER_ROLE = 'ROLE_CONTENT_MANAGER';
export const BUYER_STAFF_ROLE = 'ROLE_BUYER_STAFF';
export const ALL_ROLE =
  'ROLE_ADMIN,ROLE_MANAGER,ROLE_VENDOR,ROLE_USER,ROLE_VENDOR_STAFF,ROLE_CONTENT_MANAGER,ROLE_BUYER_STAFF';
