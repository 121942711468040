import React from 'react';
import { FormattedMessage } from 'react-intl';

type TProps = {
  item: any;
  parentItem?: any;
};

export const MenuItemText: React.FC<TProps> = ({ item, parentItem }) => {
  let linkTextStyle: React.CSSProperties = { whiteSpace: 'nowrap' };
  if (item.title === 'SUBMENU.LEGAL_TERMS' || item.title === 'SUBMENU.LEGAL_PRIVACY') {
    linkTextStyle = { whiteSpace: 'break-spaces', lineHeight: 1.3 };
  }

  return (
    <>
      {item.icon && <i className={`kt-menu__link-icon ${item.icon}`} />}

      {parentItem && parentItem.bullet === 'dot' && (
        <i className='kt-menu__link-bullet kt-menu__link-bullet--dot'>
          <span />
        </i>
      )}

      {parentItem && parentItem.bullet === 'line' && (
        <i className='kt-menu__link-bullet kt-menu__link-bullet--line'>
          <span />
        </i>
      )}

      <span className='kt-menu__link-text' style={linkTextStyle}>
        {!item.translate ? (
          item.title
        ) : (
          <FormattedMessage id={item.translate} defaultMessage={item.title} />
        )}
      </span>

      {item.badge && (
        <span className='kt-menu__link-badge'>
          <span className={`kt-badge ${item.badge.type}`}>{item.badge.value}</span>
        </span>
      )}

      {(item.submenu || item.dynamic) && (
        <i className='kt-menu__ver-arrow la la-angle-right' />
      )}
    </>
  );
};
