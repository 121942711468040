import { makeStyles } from 'tss-react/mui';

const homeStyles = makeStyles()(theme => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 20,
      marginBottom: 20,
      alignItems: 'center',
      justifyContent: 'center',
      boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
    },
    form: {
      maxWidth: '800px',
      width: '100%',
      padding: theme.spacing(3),
    },
    buttonContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
    },
    tableContainer: {
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      overflowX: 'auto',
    },
    textSelect: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    actionButtonsContainer: {
      flexDirection: 'column',
    },
    // table: {
    //   width: '100%',
    // },
    buttonAddContainer: {
      flex: 1,
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
    },
    cancelButtons: {
      marginRight: theme.spacing(2),
    },
    delButtons: {
      marginLeft: theme.spacing(2),
    },
    paramContainer: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    textField: {
      flexGrow: 1,
    },
    leftIcon: {
      padding: theme.spacing(1),
      marginLeft: theme.spacing(0.5),
    },
    textFieldContainer: {
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
    },
    titleText: {
      fontSize: 16,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    tableTitle: {
      fontSize: 14,
      marginTop: theme.spacing(2),
    },
    emptyTitle: {
      fontSize: 12,
      marginTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      marginLeft: theme.spacing(4),
    },
    switch: {
      margin: 10,
    },
    formActionBtns: {
      marginTop: theme.spacing(3),
    },
    container2: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: 20,
      marginBottom: 20,
      justifyContent: 'center',
      boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
    },
    form2: {
      // maxWidth: '800px',
      width: '100%',
      padding: theme.spacing(3),
      paddingTop: 0,
      maxWidth: 2000,
    },
    table: {
      maxWidth: '1100px',
      width: '100%',
      padding: theme.spacing(3),
      minWidth: '800px',
    },
    product_tab: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: 20,
      marginBottom: 20,
      alignItems: 'center',
      // justifyContent: '',
      boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
    },
  };
});

export default homeStyles;
