import { useEffect, useState } from 'react';

export const useRerenderComponentEach = (ms: number) => {
  const [count, setCount] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCount(s => s + 1);
    }, ms);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return [count];
};
