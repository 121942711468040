import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IProduct } from '../../../../interfaces/product';
import { makeAxiosDeleteRequest, makeAxiosPostRequest } from '../../../../utils/utils';
import { actions } from '../../../../store/ducks/product.duck';
import { IFile } from '../../../../interfaces/file';

export const handleProductFiles = (product: IProduct | undefined) => {
  const dispatch = useDispatch();
  const [res, setRes] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState<any>(null);

  const uploadFiles = useCallback(
    (files: File[]) => {
      const url = `/api/shop/product/${product?.id}`;
      const formData = new FormData();
      files.forEach(file => formData.append('file', file));
      makeAxiosPostRequest(url, formData, setRes, setLoading, setErr, {
        headers: { 'content-type': 'multipart/form-data' },
      });
    },
    [product?.id]
  );

  const deleteFile = useCallback((id: IFile['id']) => {
    const url = `/api/shop/product_file/${id}`;
    makeAxiosDeleteRequest(url, setRes, setLoading, setErr);
  }, []);



  useEffect(() => {
    if (res) dispatch(actions.fetchByIdSuccess(res.data));
  }, [res]);

  // cleanup
  useEffect(() => {
    setRes(null);
    setErr(null);
  }, [res, err]);

  return [err, uploadFiles, deleteFile, loading];
};
