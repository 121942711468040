import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Supply from './Supply';

export default function SupplyPage() {
  return (
    <Routes>
      <Route path='' element={<Supply />} />
    </Routes>
  );
}
