import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../../../../store/rootDuck';
import { makeAxiosGetRequest } from '../../../../../utils/utils';
import { actions as authActions } from '../../../../../store/ducks/users.duck';
import { useShowErrors } from '../../../../../hooks';
import { TUserAttr } from '../../../../../interfaces/user';

export const useUserAttributes = () => {
  const [data, setData] = useState<TUserAttr | null>(null);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState<any>(null);

  const dispatch = useDispatch();
  const userAttrStored = useSelector((store: IAppState) => store.users.userAttr, shallowEqual);

  useEffect(() => {
    if (!userAttrStored) {
      const url = '/api/attribute/variants';
      makeAxiosGetRequest(url, setData, setLoading, setErr);
    }
  }, [userAttrStored]);

  useEffect(() => {
    if (data) {
      dispatch(authActions.setUserAttr(data));
    }
  }, [data]);

  useEffect(() => setErr(null), [err]);
  useShowErrors([err]);

  return { userAttr: userAttrStored, userAttrLoading: loading, userAttrErr: err };
};
