import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDefineUserRole } from '../../../../hooks';
import { ITypeParameter } from '../../../../interfaces/productType';
import { ICompany } from '../../../../interfaces/companies';
import { IUser } from '../../../../interfaces/user';
import { IAddMultiplyProducts } from './useCreateMultiplyProducts';

const getInitValues = (
  me?: IUser
): {
  company?: ICompany | null;
  mainCategoryId?: number;
  categoryId_1?: number;
  categoryId_2?: number;
  categoryId_3?: number;
  categoryId_4?: number;
  categoryId_5?: number;
  productTypeId?: number;
  items: number;
  location: {
    lat: number;
    lng: number;
    address: string;
  } | null;
  addressName: string;
  name: string;
  description: string;
  vendor_price: string | number;
  price: string | number;
  stockQuantity: string | number;
  deliveryType: string;
  active: boolean;
  items_len?: number;
  filteredParams: ITypeParameter[];
} => {
  const isVendor = useDefineUserRole(me, ['ROLE_VENDOR', 'ROLE_VENDOR_STAFF']);
  return {
    company: isVendor ? me?.company : null,
    mainCategoryId: undefined,
    categoryId_1: undefined,
    categoryId_2: undefined,
    categoryId_3: undefined,
    categoryId_4: undefined,
    categoryId_5: undefined,
    productTypeId: undefined,
    items: 1,
    location: null,
    addressName: '',
    name: '',
    description: '',
    vendor_price: '',
    price: '',
    stockQuantity: '',
    deliveryType: '',
    active: true,
    filteredParams: [],
  };
};

export const useFormikAddMultiply = (
  fetchCreate: (body: IAddMultiplyProducts) => void,
  me?: IUser
) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitValues(me),
    validationSchema: Yup.object().shape({
      //   price: Yup.string().required(fm('PRODUCT.REQUIRED.PRICE.COMMON')).trim(),
    }),
    onSubmit: submitValues => {
      if (submitValues.items_len) {
        let categoryId;
        const body: IAddMultiplyProducts = {
          product_type_id: submitValues.productTypeId
            ? submitValues.productTypeId.toString()
            : undefined,
          companyId: submitValues.company ? submitValues.company.id.toString() : undefined,
          address: submitValues.location?.address,
          lat: submitValues.location?.lat,
          lng: submitValues.location?.lng,
          addressName: submitValues.addressName,
          items: [],
        };
        // eslint-disable-next-line no-plusplus
        for (let i = 1; i < 6; i++) {
          // @ts-ignore
          if (submitValues[`categoryId_${i}`] && !submitValues[`categoryId_${i + 1}`]) {
            // @ts-ignore
            categoryId = submitValues[`categoryId_${i}`];
            // @ts-ignore
            body.category_id = submitValues[`categoryId_${i}`]
              ? // @ts-ignore
                submitValues[`categoryId_${i}`].toString()
              : undefined;
            break;
          }
        }

        if (!categoryId && submitValues.mainCategoryId) {
          body.category_id = submitValues.mainCategoryId
            ? submitValues.mainCategoryId.toString()
            : undefined;
        }

        const itemsLen = Array.from({ length: submitValues.items_len }, (_v, k) => k + 1);

        itemsLen.forEach(i => {
          const product: any = {};
          // @ts-ignore
          product.name = submitValues[`name_${i}`];
          // @ts-ignore
          product.description = submitValues[`description_${i}`];
          // @ts-ignore
          product.price = submitValues[`price_${i}`] || 0;
          // @ts-ignore
          // product.selling_price = submitValues[`vendor_price_${i}`];
          // @ts-ignore
          product.stock = submitValues[`stockQuantity_${i}`];
          // @ts-ignore
          product.delivery_type = submitValues[`deliveryType_${i}`];
          // @ts-ignore
          product.activity = submitValues[`active_${i}`] ? 1 : 0;
          if (submitValues.filteredParams.length > 0 && submitValues.productTypeId) {
            product.parameters = {};
            submitValues.filteredParams.forEach(param => {
              const { id: paramId, type } = param;
              if (type === 'multiple_enum') {
                // @ts-ignore
                if (submitValues[`parameter${paramId}_${i}`]) {
                  // @ts-ignore
                  if (submitValues[`parameter${paramId}_${i}`].length === 0) {
                    // // @ts-ignore
                    // product.parameters[paramId] = 'null';
                  }
                  // @ts-ignore
                  product.parameters[paramId] = submitValues[`parameter${paramId}_${i}`];
                }
              } else if (
                // @ts-ignore
                submitValues[`parameter${paramId}_${i}`] &&
                // @ts-ignore
                submitValues[`parameter${paramId}_${i}`] !== ''
              ) {
                // @ts-ignore
                product.parameters[paramId] = submitValues[`parameter${paramId}_${i}`];
              } else {
                // // @ts-ignore
                // product.parameters[paramId] = 'null';
              }
            });
          }
          body.items.push(product);
        });
        fetchCreate(body);
      }
    },
  });

  return formik;
};
