import React from 'react';
import { Typography } from '@mui/material';
import { isNumber } from 'lodash';

import { useFormatMessage } from '../../../../../hooks';
import { getProductImage } from '../../../products/utils/getProductImage';
import { toAbsoluteUrl } from '../../../../../../_base';
import { cleanPhone, formatAsThousands } from '../../../../../utils/utils';
import { useStylesProposalsForm } from '../../hooks/useStyles';
import { IParticipation } from '../../../../../interfaces/search';

interface IProps {
  data: IParticipation[];
  title: string;
  handleDelete?: (partId: number) => void;
}

const Proposal: React.FC<IProps> = ({ data, title, handleDelete }) => {
  const fm = useFormatMessage();
  const classes = useStylesProposalsForm();

  return (
    <>
      <Typography className={classes.titleVendors}>{title}</Typography>
      <div className={classes.containerCards}>
        {data.map(item => {
          const product = item.items && item.items.length > 0 && item.items[0].product;
          if (!product) return <></>;
          return (
            <div className={classes.card} key={item.id.toString()}>
              <img
                src={getProductImage(product)}
                className={classes.img}
                alt='img'
                onError={(e: any) => {
                  e.target.src = toAbsoluteUrl('/images/placeholder.png');
                }}
              />
              <div className={classes.containerRating}>
                <img
                  alt='star'
                  className={classes.starIcon}
                  src={toAbsoluteUrl('/media/icons/star.svg')}
                />
                {product.rating ? (
                  <Typography className={classes.rating}>{product.rating}</Typography>
                ) : (
                  ''
                )}
              </div>
              <div className={classes.cardInfo}>
                <Typography className={classes.productName}>{product.name}</Typography>
                <div className={classes.blockPriceWrite}>
                  <p className={classes.price}>
                    {isNumber(product.price) ? formatAsThousands(product.price) : 0}
                    <b className={classes.rub}> руб.</b>
                  </p>
                  {item.company.phone_number && (
                    <div className={classes.whatsApp}>
                      <a
                        className={classes.whatsApp}
                        rel='noreferrer'
                        target='_blank'
                        href={`https://wa.me/${cleanPhone(item.company.phone_number)}`}
                      >
                        <img
                          alt='whatsApp'
                          className={classes.whatsAppIcon}
                          src={toAbsoluteUrl('/media/icons/whatsApp.svg')}
                        />
                        <Typography className={classes.write}>{fm('WRITE')}</Typography>
                      </a>
                    </div>
                  )}
                </div>
              </div>
              {item.comment.trim() && (
                <Typography className={classes.comment}>{item.comment}</Typography>
              )}
              {handleDelete && (
                <div className={classes.btnCancel} onClick={() => handleDelete(item.id)}>
                  <b className={classes.titleAdd}>
                    {fm('PUBLICATIONS.DELETE.CANCEL').toUpperCase()}
                  </b>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default React.memo(Proposal);
