export interface IProductType {
  id?: number;
  name: string;
  parameters?: ITypeParameter[];
  names?: Translations;
  special_type?: null | 'physical' | 'digital' | 'service';
}

export enum SortType {
  NEW = 'new',
  RATING = 'rating',
}

export interface IEnumValue {
  id: number;
  value: string;
  favorite: boolean;
  image?: string;
  translations?: Translations;
}

export interface ITypeParameter {
  id?: number;
  name?: string;
  type?: string;
  value?: string;
  parameter_id?: number;
  parameter_name?: string;
  required?: boolean;
  position?: number | null;
  filter_type?: 'checkbox' | 'interval' | 'input' | null;
  in_group?: {
    id: number;
    name: string;
  };
  group_id?: number | null;
  group_position?: number | null;
  names?: Translations;
  bulk_edit?: boolean;
  admin_only?: boolean;
  group_name?: string;
  parameter_enum_values?: IEnumValue[];
}

export interface Translations {
  [key: string]: any;
}
