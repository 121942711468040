import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { searchSantions } from '../../../../crud/companies.crud';
import { ICompanySanctions } from '../../../../interfaces/companies';
import { getResponseMessage } from '../../../../utils/utils';

export const useSanctionsCompany: any = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [sanctions, setSanctions] = useState<ICompanySanctions | null>(null);

  const fetchCompany = useCallback(async (inn: string) => {
    setLoading(true);
    searchSantions(inn)
      .then(({ data }) => {
        if (data?.data) {
          const searchSantions = data.data;
          if (searchSantions.text && searchSantions.data.length > 0) {
            setSanctions(searchSantions);
          } else {
            setSanctions(null);
            enqueueSnackbar(`По заданному ИНН санкций не найдено`, { variant: 'error' });
          }
        } else {
          enqueueSnackbar(`Ошибка поиска санкций`, { variant: 'error' });
        }
      })
      .catch(e => {
        enqueueSnackbar(`Ошибка: ${getResponseMessage(e)}`, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return [fetchCompany, loading, sanctions];
};
