import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Typography,
  Card,
  Table,
  CardContent,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TableFooter,
  Tooltip,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { SHeadTableCell } from '../../../components/styledComponents/Tables';
import Preloader from '../../../components/other/Preloader/Preloader';
import TablePaginator from '../../../components/tableComponents/TablePaginator/TablePaginator';

import { actions as loyaltiesActions } from '../../../store/ducks/loyalty.duck';
import { actions as profileActions } from '../../../store/ducks/profile.duck';
import { IAppState } from '../../../store/rootDuck';
import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';
import homeStyles from '../../../constants/homeStyles';
import { useStylesLoyalties } from './hooks/useStyles';

const LoyaltyLevelPage: React.FC<TPropsFromRedux> = ({
  loyalties,
  fetch,
  loading,
  me,
  fetchMe,
  loadingMe,
  clearMe,
  deleteLoyaltyLevel,
}) => {
  const classes = useStylesLoyalties();
  const navigate = useNavigate();
  const homeClasses = homeStyles();
  const [headerName, setHeaderName] = useState('');
  const intl = useIntl();

  useEffect(() => {
    fetchMe();
    return () => {
      clearMe();
    };
  }, []);

  useEffect(() => {
    setHeaderName(intl.formatMessage({ id: 'MENU.LOYALTY' }));
    fetch();
  }, [me]);

  setLayoutSubheader({
    title: headerName,
    breadcrumb: [],
  });

  setLayoutFooter({ show: true });

  if (!me || loadingMe || !loyalties || loading) return <Preloader />;

  return (
    <>
      <div style={{ marginTop: 8, display: 'flex', justifyContent: 'space-between' }}>
        <Button
          color='primary'
          variant='contained'
          onClick={() => navigate('/loyalties/new')}
          className={classes.addBtn}
        >
          {intl.formatMessage({ id: 'LOYALTY.ADD' })}
        </Button>
      </div>
      <div className={classes.container}>
        <div className={classes.ordersCol}>
          {!loyalties.length ? (
            <Typography className={classes.empty} component='h5' variant='h5'>
              {intl.formatMessage({ id: 'COMMON.LIST.EMPTY' })}
            </Typography>
          ) : (
            <>
              <Card className={classes.card}>
                <CardContent className={homeClasses.classes.tableContainer}>
                  <Table aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <SHeadTableCell>ID</SHeadTableCell>
                        <SHeadTableCell>
                          {intl.formatMessage({ id: 'LOYALTY.TABLE.NAME' })}
                        </SHeadTableCell>
                        <SHeadTableCell>
                          {intl.formatMessage({ id: 'LOYALTY.TABLE.NUM' })}
                        </SHeadTableCell>
                        <SHeadTableCell>
                          {intl.formatMessage({ id: 'LOYALTY.TABLE.DISCOUNT' })}
                        </SHeadTableCell>
                        <SHeadTableCell align='right'>
                          {intl.formatMessage({ id: 'PROMOCODES.TABLE.ACTIONS' })}
                        </SHeadTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {loyalties.map(item => (
                        <TableRow key={item.id}>
                          <TableCell component='th' scope='row'>
                            {item.id}
                          </TableCell>

                          <TableCell component='th' scope='row'>
                            {item.name}
                          </TableCell>

                          <TableCell component='th' scope='row'>
                            {item.discount_percent}
                          </TableCell>

                          <TableCell component='th' scope='row'>
                            {item.orders_amount}
                          </TableCell>

                          <TableCell align='right'>
                            <div>
                              <Tooltip
                                title={intl.formatMessage({
                                  id: 'LOYALTY.EDIT.TITLE',
                                })}
                              >
                                <IconButton
                                  color='primary'
                                  aria-label='previous'
                                  onClick={() => navigate(`/loyalties/edit/${item.id}`)}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>

                              <Tooltip
                                title={intl.formatMessage({
                                  id: 'LOYALTY.DELETE.TITLE',
                                })}
                              >
                                <IconButton color='primary' aria-label='previous'>
                                  <Typography
                                    color='secondary'
                                    onClick={() => deleteLoyaltyLevel({ id: item.id })}
                                  >
                                    <DeleteIcon color='error' />
                                  </Typography>
                                </IconButton>
                              </Tooltip>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePaginator
                          page={1}
                          realPerPage={loyalties.length}
                          perPage={20}
                          total={loyalties.length}
                          fetchRows={fetch}
                          label={intl.formatMessage({ id: 'LOYALTY.TABLE.PERPAGE' })}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </CardContent>
              </Card>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    page: state.promocodes.page,
    perPage: state.promocodes.per_page,
    total: state.promocodes.total,
    loyalties: state.loyalty.loyalties,
    loading: state.loyalty.loading,

    me: state.profile.me,
    loadingMe: state.profile.loading,
  }),
  {
    fetch: loyaltiesActions.fetchRequest,
    edit: loyaltiesActions.editRequest,
    deleteLoyaltyLevel: loyaltiesActions.delRequest,

    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(LoyaltyLevelPage);
