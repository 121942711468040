import axios from 'axios';
import { API_SEARCH_LOCATION_URI } from '../constants';

const callLocationApi = axios.create({
  baseURL: API_SEARCH_LOCATION_URI,
  headers: {
    Accept: '*/*',
  },
});

export const getLocation = (q: string, locale = 'en') => {
  const locales: any = {
    ru: 'ru_RU',
    en: 'en_US',
  };

  return callLocationApi.get(`${API_SEARCH_LOCATION_URI}&text=${q}&lang=${locales[locale]}`);
};

export const getLocationByCoord = ({ lat, lng }: any) =>
  callLocationApi.get(`${API_SEARCH_LOCATION_URI}&ll=${lat},${lng}`);
