import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme)=>{
  return {
    containerPaper: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 20,
      boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
    },
    container: {
      paddingTop: 50,
      paddingBottom: 50,
      [theme.breakpoints.down('md')]: {
        paddingLeft: 40,
        paddingRight: 40,
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: 50,
        paddingRight: 50,
      },
    },
    containerTop: {
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    containerBot: {
      marginTop: 35,
    },
    imgContainer: {
      height: 400,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        maxWidth: 280,
        height: 200,
      },
      [theme.breakpoints.only('sm')]: {
        maxWidth: 350,
        height: 250,
      },
    },
    img: {
      maxWidth: 430,
      [theme.breakpoints.down('xs')]: {
        maxWidth: 200,
      },
      [theme.breakpoints.only('sm')]: {
        maxWidth: 250,
      },
      cursor: 'pointer',
    },
    galleryContainer: {
      [theme.breakpoints.down('xs')]: {
        maxWidth: 280,
        maxHeigth: 280,
      },
      [theme.breakpoints.only('sm')]: {
        maxWidth: 350,
        maxHeigth: 350,
      },
    },
    productInfo: {
      paddingLeft: 35,
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 0,
        marginTop: 25,
      },
    },
    addToCartBtn: {},
    actions: {
      marginTop: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        marginTop: 10,
      },
    },
    richEditor: {
      [theme.breakpoints.up('lg')]: {
        width: 950,
      },
    },
    richEditor1: {},
    header: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 25,
    },
}});

export const useStylesCompanies = makeStyles()((theme)=>{
  return {
    title: {
      marginBottom: 20,
      textTransform: 'capitalize',
    },
    empty: {
      marginBottom: 20,
      marginTop: 20,
    },
    card: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    addBtn: {},
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    tariffText: {
      margin: 0,
      padding: 0,
      color: '#369ff7',
      cursor: 'pointer',
    },
    green: {
      color: '#fff',
      backgroundColor: '#566DC6FF',
    },
    companyNameAvatarWrapper: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
    },
}});
