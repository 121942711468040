import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme)=>{
  return {
    container: {
      display: 'grid',
      gridGap: 19,
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
    productCard: {
      minWidth: '100%',
      // minHeight: '300px',
      minHeight: 436,
      marginBottom: 20,
      cursor: 'pointer',
      transition: 'all .3s ease-out',
      borderRadius: '0',
      borderBottom: '2px #d8d8d8 solid',
      padding: 24,
      '&:hover': {
        transform: 'scale(1.03)',
      },
      [theme.breakpoints.up('md')]: {
        width: 176,
      },
    },
    edit: {
      backgroundColor: '#216214',
      borderRadius: 0,
      padding: '5px 30px',
      color: '#fff',
      fontSize: 14,
      fontWeight: 900,
      width: '100%',
      border: 'none',
    },
    delete: {
      color: '#441861',
      width: '100%',
      border: 'none',
      backgroundColor: 'transparent',
      fontSize: 14,
      padding: '5px 30px',
      fontWeight: 900,
      marginBottom: 16,
    },
    absoluteImg: {
      position: 'absolute',
      zIndex: 0,
      bottom: 20,
      left: 30,
    },
    containerInfo: {
      flex: 1,
      display: 'flex',
      gap: 16,
      overflow: 'hidden',
      flexDirection: 'column',
    },
    wrapInfoNameAndDesc: {
      flex: 1,
    },
    wrapInfoActions: {
      flex: 1,
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      position: 'relative',
      gap: 20,
      [theme.breakpoints.down('lg')]: {
        minWidth: '100%',
      },
    },
    infoParameters: {
      display: 'flex',
      flexDirection: 'row',
      products: 'center',
      alignItems: 'center',
      gap: 16,
      marginBottom: 17,
    },
    infoDescription: {
      lineHeight: '22px',
      fontSize: 13,
      fontWeight: 400,
      [theme.breakpoints.down('lg')]: {
        display: 'none',
      },
    },
    infoName: {
      lineHeight: 1.3,
      fontSize: 37,
      fontWeight: 300,
      marginBottom: 4,
      [theme.breakpoints.down('lg')]: {
        maxWidth: '100%',
      },
    },
    actions: {
      maxWidth: 176,
      display: 'flex',
      products: 'center',
      flexDirection: 'column',
      bottom: 0,
      right: 0,
      gap: 12,
      backgroundColor: '#fff',
      [theme.breakpoints.down('lg')]: {
        maxWidth: '100%',
      },
    },
    priceBody: {
      width: 152,
      marginBottom: 20,
    },
    priceTitle: {
      fontSize: 27,
      fontWeight: 'normal',
      color: '#000',
      textAlign: 'center',
    },
    addFav: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      alignItems: 'center',
    },
    favBtn: {
      backgroundColor: 'rgba(68, 24, 97, 0.65)',
      border: 'none',
      height: 50,
      fontSize: 30,
      color: '#fff',

      transition: 'all .3s ease-out',
      '&:hover': {
        backgroundColor: '#441861',
      },
    },
    favText: {
      fontSize: 16,
      fontWeight: 'bold',
      color: '#441861',
      margin: 0,
      textAlign: 'center',
    },
    butCart: {
      width: 152,
      borderRadius: 0,
      backgroundColor: '#4e246a',
      padding: '7px 25px 7px 20px',
      display: 'flex',
      alignItems: 'center',
      border: 'none',
      justifyContent: 'center',

      transition: 'all .3s ease-out',
      '&:hover': {
        backgroundColor: 'rgba(68, 24, 97, 0.65)',
      },
    },
    butCartText: {
      fontSize: 16,
      color: '#fff',
      // overflow: 'hidden',
      // textOverflow: 'ellipsis',
      // whiteSpace: 'nowrap',
    },
    box: {
      display: 'flex',
      gap: 24,
      justifyContent: 'space-between',
      zIndex: 1,
      height: '100%',
      [theme.breakpoints.down('lg')]: {
        gap: 10,
      },
    },
}});