import React from 'react';
import NumberFormat from 'react-number-format';

interface NumberFormatCustomProps {
  inputRef?: (instance: any) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumberFormatPrice: React.FC<NumberFormatCustomProps> = ({
  inputRef,
  onChange,
  name,
  ...other
}) => {
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values: any) => {
        onChange({
          target: {
            name,
            value: values.value,
          },
        });
      }}
      thousandSeparator=' '
      isNumericString
      decimalSeparator=','
      decimalScale={2}
    />
  );
};
export default NumberFormatPrice;
