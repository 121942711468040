// TODO: При добавлении нового языка, добавлять локализацию в I18n, например CATEGORY.KK.LANGUAGE

import { toAbsoluteUrl } from '../../../../../_base';

export const categoryLanguages = [
  {
    code: 'ru',
    name: 'Русский',
    flag: toAbsoluteUrl('/media/flags/248-russia.svg'),
  },
  {
    code: 'en',
    name: 'English',
    flag: toAbsoluteUrl('/media/flags/260-united-kingdom.svg'),
  },
  {
    code: 'cz',
    name: 'Czech',
    flag: toAbsoluteUrl('/media/flags/149-czech-republic.svg'),
  },
  //   {
  //     code: 'kk',
  //   },
];
