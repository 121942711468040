import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { searchCompanies } from '../../../../crud/companies.crud';
import { ICompany } from '../../../../interfaces/companies';
import { getResponseMessage } from '../../../../utils/utils';

export const useSearchCompany: any = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [companies, setCompanies] = useState<ICompany | null>(null);

  const fetchCompany = useCallback(async (inn: string, addIfNotFound?: boolean) => {
    setLoading(true);
    searchCompanies(inn)
      .then(({ data }) => {
        if (data?.data) {
          const searchCompanies = data.data;
          if (searchCompanies.length > 0) {
            if (!searchCompanies[0].id) {
              setNotFound(true);
              return;
            }
            setNotFound(false);
            setCompanies(searchCompanies);
          } else {
            setNotFound(true);
            !addIfNotFound &&
              enqueueSnackbar(`По заданному ИНН компании не найдены`, { variant: 'error' });
          }
        } else {
          enqueueSnackbar(`Ошибка поиска компаний`, { variant: 'error' });
        }
      })
      .catch(e => {
        enqueueSnackbar(`Ошибка: ${getResponseMessage(e)}`, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return [fetchCompany, loading, companies, notFound];
};
