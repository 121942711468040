import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { getResponseMessage } from '../../../../utils/utils';
import { deleteParticipation } from '../../../../crud/search.crud';
import { actions as searchActions } from '../../../../store/ducks/search.duck';

export const useDeleteParticipation: any = () => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const fetch = useCallback(async (id: number, tenderId: number) => {
    setLoading(true);
    deleteParticipation(id)
      .then(() => {
        setSuccess(true);
        enqueueSnackbar(
          `${intl.formatMessage({ id: 'PARTICIPATION.SUCCESS.POST.DELETE.SEARCH' })}`,
          {
            variant: 'success',
          }
        );
        dispatch(
          searchActions.fetchParticipation({ tender_id: tenderId, page: 1, per_page: 20 })
        );
      })
      .catch(e => {
        enqueueSnackbar(`${intl.formatMessage({ id: 'ERROR' })}: ${getResponseMessage(e)}`, {
          variant: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
        setSuccess(false);
      });
  }, []);

  return [fetch, loading, success];
};
