import React from 'react';
import { MenuSection } from './MenuSection';
import { MenuItemSeparator } from './MenuItemSeparator';
import { MenuItem } from './MenuItem';

type TProps = {
  item: any;
  currentUrl: string;
  layoutConfig: any;
};

export const MenuSubmenu: React.FC<TProps> = ({ item, currentUrl, layoutConfig }) => {
  return (
    <ul className='kt-menu__subnav'>
      {item.submenu.map((child: any, index: number) => (
        <React.Fragment key={index}>
          {child.section && <MenuSection item={child} />}

          {child.separator && <MenuItemSeparator />}

          {child.title && (
            <MenuItem
              item={child}
              parentItem={item}
              currentUrl={currentUrl}
              layoutConfig={layoutConfig}
            />
          )}
        </React.Fragment>
      ))}
    </ul>
  );
};
