import React from 'react';
import {
  Button,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ITariff } from '../../../../../interfaces/tariff';
import { Block } from './components';
import { TableBlockInterface } from '../../interfaces';

import { TariffsType } from '../../TariffList';
import { TariffEditFields } from '../../../../../store/ducks/tariff.duck';
import TopTableCell from '../../../../../components/other/Table/TopTableCell';
import { useFormatMessage } from '../../../../../hooks';

const useStyles = makeStyles((theme: any) => ({
  table: {
    flex: 1,
    backgroundColor: '#fff',
    overflowX: 'auto',
  },
  blackCell: {
    backgroundColor: 'rgba(150, 150, 150, 0.4)',
  },
  grayCell: {
    backgroundColor: 'rgba(150, 150, 150, 0.2)',
  },
  titleCell: {
    color: '#fff',
    width: '300px',
  },
  freeCell: {
    backgroundColor: '#666666',
    color: '#fff',
  },
  proCell: {
    backgroundColor: theme.palette.success.dark,
    color: '#fff',
  },
  tariffCell: {
    width: '200px',
  },
  button: {
    width: '100%',
  },
  freeButtonCell: {
    width: '200px',
    backgroundColor: 'rgba(150, 150, 150, 0.2)',
  },
  buyerProCell: {
    width: '200px',
    backgroundColor: theme.palette.secondary.main,
  },
  providerProCell: {
    width: '200px',
    backgroundColor: theme.palette.primary.main,
  },
}));

const TariffTable: React.FC<{
  type: TariffsType;
  tableConfig: TableBlockInterface[];
  freeTariff: ITariff;
  proTariff: ITariff;
  editable: boolean;
  loading: boolean;
  success: boolean;
  editTariff: (id: number, forRole: string, name: string, field: TariffEditFields, value: string | number | null) => void;
  submit: (id: number, field: TariffEditFields, value: string | number | null) => void;
  setModal: (value: boolean) => void;
  canPay: boolean;
  disablePay: boolean;
}> = ({
  type,
  tableConfig,
  freeTariff,
  proTariff,
  loading,
  success,
  submit,
  editTariff,
  editable,
  setModal,
  canPay,
  disablePay,
}) => {
  const fm = useFormatMessage();
    const classes = useStyles();
    const proClass =
      type === TariffsType.buyers ? classes.buyerProCell : classes.providerProCell;

    return (
      <Card className={classes.table}>
        <CardContent>
          <Table>
            <TableHead>
              <TableRow>
                <TopTableCell> </TopTableCell>
                <TopTableCell style={{color: 'white', fontWeight: 'bold'}} align='center'>{fm(freeTariff.name)}</TopTableCell>
                <TopTableCell style={{color: 'white', fontWeight: 'bold'}} align='center'>{fm(proTariff.name)}</TopTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {tableConfig.map((block, index) => (
                <Block
                  type={type}
                  block={block}
                  editable={editable}
                  key={index.toString()}
                  loading={loading}
                  success={success}
                  submit={submit}
                  editTariff={editTariff}
                  freeTariff={freeTariff}
                  proTariff={proTariff}
                />
              ))}

              {canPay && (
                <TableRow>
                  <TableCell> </TableCell>
                  <TableCell> </TableCell>
                  <TableCell className={proClass}>
                    <Button
                      onClick={() => {
                        if (!disablePay) {
                          setModal(true);
                        }
                      }}
                      className={classes.button}
                      variant='contained'
                      color={type === TariffsType.vendors ? 'secondary' : 'primary'}
                      disabled={disablePay}
                    >
                      Switch to Pro
                    </Button>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    );
  };

export default TariffTable;
