import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { getPromocodeSettings } from '../../../../crud/promocodes.crud';
import { getResponseMessage } from '../../../../utils/utils';

export const usePromocodeSettings: any = () => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState();
  const fetchStat = useCallback(async () => {
    setLoading(true);
    getPromocodeSettings()
      .then(res => {
        setSuccess(res.data.data);
      })
      .catch(e => {
        enqueueSnackbar(`${intl.formatMessage({ id: 'ERROR' })}: ${getResponseMessage(e)}`, {
          variant: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return [fetchStat, success, loading];
};
