import React, { useCallback, useLayoutEffect } from 'react';
import {
  Typography,
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  CardContent,
  TableFooter,
  Theme,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';

import { SHeadTableCell } from '../../../../components/styledComponents/Tables';
import Preloader from '../../../../components/other/Preloader/Preloader';
import homeStyles from '../../../../constants/homeStyles';
import { setLayoutFooter, setLayoutSubheader } from '../../../../utils/layout';
import { useGetShifts } from '../hooks/useGetShifts';
import { useFormatMessage } from '../../../../hooks';
import TablePaginator from '../../../../components/tableComponents/TablePaginator/TablePaginator';

const ShiftsList: React.FC = () => {
  const homeClasses = homeStyles();
  const classes = useStyles();
  const fm = useFormatMessage();
  const navigate = useNavigate();

  const { fetchShifts, loading, page, shifts, perPage, total } = useGetShifts();

  setLayoutSubheader({
    title: fm('SHIFT.LIST'),
  });
  setLayoutFooter({ show: true });

  useLayoutEffect(() => {
    fetchShifts(page, 20);
  }, []);

  const goToCreateShift = useCallback(() => {
    navigate('/shifts/add');
  }, [navigate]);

  const translates: { [type: string]: string } = {
    new: fm('SHIFT.TYPE.NEW'),
    confirmed: fm('SHIFT.TYPE.CONFIRMED'),
    canceled: fm('SHIFT.TYPE.CANCELED'),
    unfulfilled: fm('SHIFT.TYPE.UNFULFILLED'),
  };

  if (loading) return <Preloader />;

  return (
    <>
      <Button color='primary' variant='contained' onClick={() => goToCreateShift()}>
        {fm('SHIFT.BUTTON.ADD')}
      </Button>

      <Row>
        <Col>
          {!shifts.length ? (
            <Typography className={classes.empty} component='h5' variant='h5'>
              {fm('COMMON.LIST.EMPTY')}
            </Typography>
          ) : (
            <>
              <Card className={classes.card}>
                <CardContent className={homeClasses.classes.tableContainer}>
                  <Table aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <SHeadTableCell>{fm('PRODUCT.TABLE.ID')}</SHeadTableCell>
                        <SHeadTableCell>{fm('SHIFT.START.DATE')}</SHeadTableCell>
                        <SHeadTableCell>{fm('SHIFT.END.DATE')}</SHeadTableCell>
                        <SHeadTableCell>{fm('SHIFT.CATEGORY')}</SHeadTableCell>
                        <SHeadTableCell>{fm('SHIFT.STAFF')}</SHeadTableCell>
                        <SHeadTableCell>{fm('SHIFT.STATUS')}</SHeadTableCell>
                        <SHeadTableCell>{fm('SHIFT.RATE')}</SHeadTableCell>
                        <SHeadTableCell>{fm('SHIFT.STORE')}</SHeadTableCell>
                        <SHeadTableCell align='right'>
                          {fm('PRODUCT.TABLE.ACTIONS')}
                        </SHeadTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {shifts.map(item => (
                        <TableRow key={item.id}>
                          <TableCell component='th' scope='row'>
                            {item.id || '-'}
                          </TableCell>
                          <TableCell component='th' scope='row'>
                            {moment(item.start.split('+')[0]).format('yyyy-MM-DD HH:mm')}
                          </TableCell>
                          <TableCell component='th' scope='row'>
                            {moment(item.end.split('+')[0]).format('yyyy-MM-DD HH:mm')}
                          </TableCell>
                          <TableCell component='th' scope='row'>
                            {item.category?.name || '-'}
                          </TableCell>
                          <TableCell component='th' scope='row'>
                            {item.user?.login || '-'}
                          </TableCell>
                          <TableCell component='th' scope='row'>
                            {translates[item.status]}
                          </TableCell>
                          <TableCell component='th' scope='row'>
                            {item.price}
                          </TableCell>
                          <TableCell component='th' scope='row'>
                            {item.store?.name || '-'}
                          </TableCell>
                          <TableCell align='right'>
                            <IconButton
                              color='primary'
                              aria-label='previous'
                              onClick={() => navigate(`/shifts/edit/${item.id}`)}
                            >
                              <EditIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePaginator
                          page={page}
                          realPerPage={shifts.length}
                          perPage={perPage}
                          total={total}
                          fetchRows={(data: any) => fetchShifts(data.page, data.perPage)}
                          label={fm('SHIFT.TABLE.PERPAGE')}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </CardContent>
              </Card>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    empty: {
      marginBottom: 20,
      marginTop: 20,
    },
    buttons: {
      marginRight: theme.spacing(3),
    },
    card: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    categoryTree: {
      maxWidth: 400,
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    img: {
      width: 50,
      height: 50,
    },
  })
);

export default ShiftsList;
