import React, { useCallback, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { actions as searchActions } from '../../../../../store/ducks/search.duck';
import { useFormatMessage } from '../../../../../hooks';
import { IAppState } from '../../../../../store/rootDuck';
import Preloader from '../../../../../components/other/Preloader/Preloader';
import { useStylesProposalsForm } from '../../hooks/useStyles';
import TablePaginator from '../../../../../components/TablePaginator/TablePaginator';
import Proposal from './Proposal';
import { useDeleteParticipation } from '../../hooks/useDeleteParticipation';
import SearchAddProduct from '../SearchAddProduct';
import { ISearch } from '../../../../../interfaces/search';

interface IProps {
  id: string;
  search?: ISearch;
}

const ProposalsForm: React.FC<IProps> = ({ id, search }) => {
  const dispatch = useDispatch();
  const fm = useFormatMessage();
  const classes = useStylesProposalsForm();
  const [deletePart] = useDeleteParticipation();

  const { me } = useSelector(
    (store: IAppState) => ({
      me: store.profile.me,
    }),
    shallowEqual
  );
  const { loading, participations, page, perPage, total } = useSelector(
    (store: IAppState) => ({
      page: store.search.pagePart,
      loading: store.search.loadingPart,
      perPage: store.search.perPagePart,
      total: store.search.totalPart,
      participations: store.search.participations,
    }),
    shallowEqual
  );

  const handleDelete = useCallback(
    (partId: number) => {
      if (id) {
        deletePart(partId, id);
      }
    },
    [id]
  );

  const vendorsPart = useMemo(() => {
    if (participations && participations.length > 0) {
      return participations.filter(
        item => item.company?.id?.toString() !== me?.company?.id?.toString()
      );
    }
  }, [participations, me]);

  const myPart = useMemo(() => {
    if (participations && participations.length > 0) {
      return participations.filter(
        item => item.company?.id?.toString() === me?.company?.id?.toString()
      );
    }
  }, [participations, me]);

  if (!id) return <></>;

  if (loading || !participations) return <Preloader />;

  return (
    <>
      <div className={classes.container}>
        {vendorsPart && vendorsPart.length > 0 && (
          <>
            <Proposal title={fm('PARTICIPATIONS.VENDORS')} data={vendorsPart} />
          </>
        )}
        {myPart && myPart.length > 0 && (
          <>
            <Proposal
              title={fm('PARTICIPATIONS.MY')}
              data={myPart}
              handleDelete={partId => handleDelete(partId)}
            />
          </>
        )}
        {search?.user?.id.toString() !== me?.id.toString() && id && (
          <>
            <SearchAddProduct
              id={id}
              changeTab={() => {}}
              // title={fm('PARTICIPATIONS.YOUR')}
            />
          </>
        )}
        {participations && participations.length > 0 && (
          <TablePaginator
            page={page}
            perPage={perPage}
            fetchRows={(data: any) =>
              dispatch(
                searchActions.fetchParticipation({
                  tender_id: +id,
                  page: data.page,
                  per_page: data.perPage,
                })
              )
            }
            total={total}
            label={fm('PRODUCT.TABLE.PERPAGE')}
          />
        )}
      </div>
    </>
  );
};

export default ProposalsForm;
