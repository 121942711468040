import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector, connect, ConnectedProps } from 'react-redux';
import { Button, Theme } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Col, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { IAppState } from '../../../store/rootDuck';
import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';
import { actions as blogActions } from '../../../store/ducks/blog.duck';
import Preloader from '../../../components/ui/Preloader/Preloader';
import { PublicationsList } from './components';
import TablePaginator from '../../../components/TablePaginator/TablePaginator';
import useCrudSnackbar from '../../../hooks/useCrudSnackbar';
import { actions as profileActions } from '../../../store/ducks/profile.duck';
import AlertDialog from '../../../components/ui/Dialog/AlertDialog';
import { useForbidAccess } from '../../../hooks/useForbidAccess';
import { IPublication } from './interfaces';
import { useFormatMessage } from '../../../hooks';
import { UserRoles } from '../../../interfaces/user';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    empty: {
      marginBottom: 20,
      marginTop: 20,
    },
    addBtn: {
      marginBottom: 10,
      marginLeft: 10,

      [theme.breakpoints.up('xl')]: {
        marginLeft: 21,
      },
      // @ts-ignore
      [theme.breakpoints.up('xxl')]: {
        marginLeft: 25,
      },
    },
  })
);

const MyPublicationsPage: React.FC<TPropsFromRedux> = ({
  me,
  loadingMe,
  fetchMe,
  clearMe,
}) => {
  const store = useSelector((store: IAppState) => store, shallowEqual);
  const classes = useStyles();
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fm = useFormatMessage();

  const [deleteId, setDeleteId] = useState<number | undefined>(-1);
  const [width, setWidth] = useState<number | undefined>();
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [showEdit, setShowEdit] = useState(true);
  const handleDeleteDialog = useCallback((id: number | undefined) => {
    setDeleteId(id);
    setAlertOpen(true);
  }, []);

  const isAdmin = useMemo(() => me?.roles.includes(UserRoles.ROLE_ADMIN), [me]);

  const [currentPublicatoins, setCurrentPublications] = useState<IPublication[] | null>(null);
  useEffect(() => {
    setCurrentPublications(store.blog.publications);
  }, [store.blog.publications]);

  setLayoutSubheader({ title: fm('my_publications') });
  setLayoutFooter({ show: true });

  useEffect(() => {
    dispatch(profileActions.fetchRequest());
    dispatch(blogActions.publicationsRequest({ page: 1, perPage: 20, my: true }));
    return () => {
      dispatch(profileActions.clearMe());
      dispatch(blogActions.clearFetchPublications());
    };
  }, []);

  useCrudSnackbar({
    success: store.blog.deletePublicationSuccess,
    error: store.blog.deletePublicationError,
    clear: () => {
      dispatch(blogActions.clearDeletePublication());
    },
    successMessage: `${intl.formatMessage({ id: 'SUCCESS' })}`,
    errorMessage: `${intl.formatMessage({ id: 'PUBLICATIONS.DELETE.ERROR' })}: ${
      store.blog.deletePublicationError
    }`,
    afterSuccess: () => {
      dispatch(
        blogActions.publicationsRequest({
          page: store.blog.page,
          perPage: store.blog.per_page,
        })
      );
    },
    afterSuccessOrError: () => {
      setAlertOpen(false);
    },
  });

  const updateWidth = () => setWidth(window.innerWidth);

  useEffect(() => {
    updateWidth();
  }, [updateWidth]);

  useEffect(() => {
    window.addEventListener('resize', updateWidth);
  }, []);

  useEffect(() => {
    fetchMe();
  }, []);

  // useForbidAccess(store.profile.me, ['ROLE_BUYER']);

  if (
    !store.profile.me ||
    store.blog.publicationsLoading ||
    store.blog.deletePublicationLoading ||
    store.profile.loading ||
    !currentPublicatoins
  ) {
    return <Preloader />;
  }

  return (
    <>
      <AlertDialog
        open={isAlertOpen}
        message={intl.formatMessage({ id: 'PUBLICATIONS.DELETE.TEXT' })}
        okText={intl.formatMessage({ id: 'PUBLICATIONS.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'PUBLICATIONS.DELETE.CANCEL' })}
        handleClose={() => {
          setAlertOpen(false);
        }}
        handleAgree={() =>
          deleteId && dispatch(blogActions.deletePublicationRequest({ id: deleteId }))
        }
      />

      <Row>
        <Col>
          <Button
            color='primary'
            variant='contained'
            onClick={() => navigate('/blog/create')}
            className={classes.addBtn}
          >
            {intl.formatMessage({ id: 'SUBMENU.PUBLICATIONS.ADD' })}
          </Button>

          <PublicationsList
            deletePost={handleDeleteDialog}
            meId={store.profile.me.id}
            companyId={store.profile.me.company?.id || undefined}
            publications={currentPublicatoins}
            width={width}
            admin={isAdmin}
            showEdit={showEdit}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'stretch',
            }}
          >
            <TablePaginator
              page={store.blog.page}
              realPerPage={currentPublicatoins.length}
              perPage={store.blog.per_page}
              total={store.blog.total}
              fetchRows={(data: { page: number; perPage: number }) =>
                dispatch(blogActions.publicationsRequest({ ...data, my: true }))
              }
              label={intl.formatMessage({ id: 'PUBLICATIONS.TABLE.PERPAGE' })}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    me: state.profile.me,
    loadingMe: state.profile.loading,

    page: state.blog.page,
    perPage: state.blog.per_page,
    total: state.blog.total,

    loading: state.blog.publicationsLoading,
    success: state.blog.publicationsSuccess,
    error: state.blog.publicationsError,
    publications: state.blog.publications,

    deleteLoading: state.blog.deletePublicationLoading,
    deleteSuccess: state.blog.deletePublicationSuccess,
    deleteError: state.blog.deletePublicationError,
  }),
  {
    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,

    getPublications: blogActions.publicationsRequest,
    clearPublications: blogActions.clearFetchPublications,

    deletePublication: blogActions.deletePublicationRequest,
    clearDeletePublication: blogActions.clearDeletePublication,
  }
);
type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(MyPublicationsPage);
