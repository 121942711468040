import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CollectionsList from './CollectionsList';
import EditCollection from './EditCollection';
import ProductsCollection from './ProductsCollection';

export default () => (
  <Routes>
    <Route path='list' element={<CollectionsList />} />
    <Route path='create' element={<EditCollection />} />
    <Route path='edit/:id' element={<EditCollection />} />
    <Route path='products/:id' element={<ProductsCollection />} />
  </Routes>
);
