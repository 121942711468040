import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Card } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';

import { actions as categoriesActions } from '../../../store/ducks/categories.duck';
import { actions as productTypesActions } from '../../../store/ducks/productType.duck';

import Preloader from '../../../components/other/Preloader/Preloader';

import homeStyles from '../../../constants/homeStyles';
import { ICategoryParent } from './interfaces';
import { IAppState } from '../../../store/rootDuck';
import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';
import { categoriesBreadCrumbs } from './utils/createBreadcrumbs';
import { createParentList } from '../../../utils/createParentList';
import { useAddAvatar } from './hooks/useAddAvatar';
import { useFormikCategoryPage } from './hooks/useFormikCategoryPage';
import { CategoryForm } from './components/CategoryForm';

const CategoryPage: React.FC<TPropsFromRedux> = ({
  category,
  clearEdit,
  editLoading,
  editSuccess,
  editError,
  fetch,
  fetchParent,
  fetchCategories,
  loadingById,
  categoryLoading,
  parent,
  categories,
  categoryId,
  me,
  fetchTypes,
  types,
  loadingTypes,
}) => {
  const intl = useIntl();
  // const classes = useStyles();
  const homeClasses = homeStyles();
  const navigate = useNavigate();
  const { parentId, id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [parents, setParents] = useState<ICategoryParent[]>([]);
  const [addAvatar] = useAddAvatar(parentId || '');
  const [images, setImages] = useState([]);
  const [categoryLanguage, setLanguage] = useState('en');
  const formik = useFormikCategoryPage(id || '', parentId || '', categories, category);

  const handleUpload = useCallback(
    (file: File, categoryId?: number) => {
      const data = new FormData();
      data.append('photo', file);
      if (categoryId) {
        addAvatar(categoryId, data);
      } else {
        addAvatar(id, data);
      }
    },
    [id]
  );

  useEffect(() => {
    if (editSuccess || editError) {
      enqueueSnackbar(
        editSuccess
          ? `${intl.formatMessage({ id: 'CATEGORY.SNACKBAR.TITLE' })} ${
              id
                ? `${intl.formatMessage({ id: 'CATEGORY.SNACKBAR.EDIT' })}`
                : `${intl.formatMessage({ id: 'CATEGORY.SNACKBAR.ADD' })}`
            }`
          : `${intl.formatMessage({ id: 'ERROR' })} ${editError}`,
        { variant: editSuccess ? 'success' : 'error' }
      );
    }
    if (editSuccess && categoryId) {
      images.length > 0 && handleUpload(images[0], categoryId);
      navigate(-1);
    } else if (editSuccess) {
      navigate(-1);
    }
    return () => {
      clearEdit();
    };
  }, [editSuccess, editError, enqueueSnackbar, clearEdit, id, navigate, intl]);

  useEffect(() => {
    if (id) fetch(+id);
    if (parentId) fetchParent({ parentId: +parentId });
    fetchTypes();
    fetchCategories();
  }, [fetch, id, parentId, fetchCategories, fetchParent]);

  useEffect(() => {
    setParents(
      createParentList(
        categories,
        intl.formatMessage({ id: 'CATEGORY.EDIT.ROOT' }),
        parentId ? +parentId : undefined,
        '\u00B7 '
      )
    );
  }, [categories, parentId, intl]);

  setLayoutSubheader({
    title: `${
      id
        ? `${intl.formatMessage({ id: 'CATEGORY.TITLE.EDIT' })}`
        : `${intl.formatMessage({ id: 'CATEGORY.TITLE.ADD' })}`
    } ${intl.formatMessage({ id: 'CATEGORIES.TITLE' }).toLowerCase()}`,
    breadcrumb: categoriesBreadCrumbs(parent, intl),
  });
  setLayoutFooter({ show: true });

  if (loadingById || categoryLoading || loadingTypes) return <Preloader />;
  return (
    <Row>
      <Col>
        <Card className={homeClasses.classes.container}>
          <CategoryForm
            handleUpload={handleUpload}
            images={images}
            editLoading={editLoading}
            setImages={setImages}
            formik={formik}
            parents={parents}
            id={id || ''}
            category={category}
            categories={categories}
            me={me}
            setLanguage={setLanguage}
            categoryLanguage={categoryLanguage}
            types={types || []}
          />
        </Card>
      </Col>
    </Row>
  );
};

const connector = connect(
  (state: IAppState) => ({
    category: state.categories.category,
    loadingById: state.categories.byIdLoading,
    categoryLoading: state.categories.categoriesLoading,
    categories: state.categories.categories,
    editLoading: state.categories.editLoading,
    editSuccess: state.categories.editSuccess,
    editError: state.categories.editError,
    parent: state.categories.parent,
    categoryId: state.categories.categoryId,
    me: state.profile.me,
    types: state.productTypes.productTypes,
    loadingTypes: state.productTypes.loading,
  }),
  {
    fetch: categoriesActions.fetchByIdRequest,
    fetchParent: categoriesActions.fetchParentRequest,
    clearEdit: categoriesActions.clearEdit,
    fetchCategories: categoriesActions.fetchFullRequest,
    fetchTypes: productTypesActions.fetchRequest,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CategoryPage);
