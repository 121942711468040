import React from 'react';
import { Route, Routes } from 'react-router-dom';
import OrderListPage from './OrderListPage';
import OrderPage from './OrderPage';

export default function CategoriesPage() {
  return (
    <Routes>
      <Route path='list/:type' element={<OrderListPage />} />
      <Route path=':id/:mode' element={<OrderPage />} />
    </Routes>
  );
}
