import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Calendar from './calendar';
import ShiftPage from './shiftPage';
import ShiftList from './list';

export default function ShiftsPage() {
  return (
    <Routes>
      <Route path='calendar' element={<Calendar />} />
      <Route path='add' element={<ShiftPage />} />
      <Route path='edit/:id' element={<ShiftPage />} />
      <Route path='list' element={<ShiftList />} />
    </Routes>
  );
}
