import React, { useState, useCallback, useEffect, useLayoutEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Preloader from '../../../components/other/Preloader/Preloader';
import AlertDialog from '../../../components/other/Dialog/AlertDialog';

import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';
import { useFormatMessage } from '../../../hooks';
import { useGetGroup } from './hooks/useGetGroup';
import { useCreateGroup } from './hooks/useCreateGroup';
import { useEditGroup } from './hooks/useEditGroup';
import { useDeleteGroup } from './hooks/useDeleteGroup';
import { useGroupPositions } from './hooks/useGroupPositions';
import { useChangeParamGroup } from './hooks/useChangeParamGroup';
import GroupForm from './components/GroupForm';
import { useGetGroups } from './hooks/useGetGroups';
import { IGroup } from '../../../interfaces/groups';

const GroupPage: React.FC = () => {
  const [isAlertOpen, setAlertOpen] = useState(false);
  const { fetchGroup: fetchGroupParams, params } = useGetGroup();
  const [addGroup, loadingAdd, idAdd] = useCreateGroup();
  const [editGroup, loadingEdit, successEdit] = useEditGroup();
  const { handleDelete: deleteGroup, loadingDelete, successDelete } = useDeleteGroup();
  const { handlePositions, loadingPositions } = useGroupPositions(
    () => id && fetchGroupParams(+id)
  );
  const { handleParam, loadingParam } = useChangeParamGroup(() => id && fetchGroupParams(+id));
  const [fetchGroups, loadingGroups, groups] = useGetGroups();
  const fm = useFormatMessage();
  const navigate = useNavigate();
  const { id } = useParams();

  const group: IGroup | undefined = useMemo(() => {
    if (!id) return;
    return groups.find((item: IGroup) => item.id.toString() === id.toString());
  }, [groups, id]);

  useLayoutEffect(() => {
    fetchGroups();
  }, []);

  useEffect(() => {
    if (id) {
      fetchGroupParams(+id);
    }
  }, [id]);

  useEffect(() => {
    if (successEdit) {
      id && fetchGroupParams(+id);
      fetchGroups();
    }
    if (idAdd) {
      fetchGroups();
      navigate(`/groups/edit/${idAdd}`, { replace: true });
    }
  }, [idAdd, successEdit]);

  useEffect(() => {
    successDelete && navigate(-1);
  }, [successDelete]);

  setLayoutSubheader({
    show: true,
  });
  setLayoutFooter({ show: true });

  const editAction = useCallback(
    (name: string) => {
      if (id) {
        editGroup(id, name);
      } else {
        addGroup(name);
      }
    },
    [id]
  );

  const editParamAction = useCallback(
    (add?: number, remove?: number) => {
      if (id) {
        handleParam(+id, add, remove);
      }
    },
    [id]
  );

  const setPositions = useCallback(
    (positions: string) => {
      id && handlePositions(positions);
    },
    [id]
  );

  const handleDelGroup = useCallback(() => {
    setAlertOpen(true);
  }, []);

  const deleteAction = useCallback(() => {
    setAlertOpen(false);
    if (id) {
      deleteGroup(+id);
    }
  }, [id, setAlertOpen]);

  if (loadingGroups) return <Preloader />;

  return (
    <>
      <AlertDialog
        open={isAlertOpen}
        message={fm('GROUP.DELETE.TEXT')}
        okText={fm('CATEGORIES.DELETE.OK')}
        cancelText={fm('CATEGORIES.DELETE.CANCEL')}
        handleClose={() => {
          setAlertOpen(false);
        }}
        handleAgree={deleteAction}
      />
      <GroupForm
        editAction={editAction}
        group={group}
        params={params}
        delGroup={handleDelGroup}
        disabled={
          loadingAdd || loadingEdit || loadingDelete || loadingPositions || loadingParam
        }
        setPositions={setPositions}
        editParamAction={editParamAction}
        groups={groups}
      />
    </>
  );
};

export default GroupPage;
