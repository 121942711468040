import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useFormatMessage } from '../../../../hooks';
import { ICategoryItem } from '../../categories/interfaces';
import { ISearch } from '../../../../interfaces/search';

const findCategory = (item: ICategoryItem, id: string, categories: any[]): any => {
  if (!item.children) return categories;
  const category = item.children?.find(i => i.id?.toString() === id);
  if (category) {
    return [...categories, category];
  }
  for (const i of item.children) {
    if (i.children) {
      const newCategories = findCategory(i, id, categories);
      if (newCategories && newCategories.length > 0)
        return [...categories, i, ...newCategories];
    }
  }
  return categories;
};

const getInitValues = (search?: ISearch, categories?: ICategoryItem[]) => {
  const initValues: { [key: string]: any } = {
    name: search?.name || '',
    price: search?.price || '',
    description: search?.comment || null,
    status: search?.status || 'new',
    mainCategoryId: search?.category?.id,
    categoryId_1: '',
    files: [],
  };

  if (categories) {
    const id = search?.category?.id;
    let tree: ICategoryItem[] = [];
    if (id) {
      categories.forEach(item => {
        const newCategories = findCategory(item, id.toString(), []);
        if (newCategories && newCategories.length > 0) {
          tree = [item, ...newCategories];
        }
      });
      tree.forEach((item, index) => {
        if (index === 0) {
          initValues.mainCategoryId = item.id;
        } else {
          initValues[`categoryId_${index}`] = item.id;
        }
      });
    }
  }

  return initValues;
};

export const useFormikSearchPage = (
  createSearch: (data: any) => void,
  editSearch: (data: { id: number; data: any }) => void,
  search?: ISearch,
  categories?: ICategoryItem[]
) => {
  const fm = useFormatMessage();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitValues(search, categories),
    validationSchema: Yup.object().shape({
      name: Yup.string().required(fm('PRODUCT.REQUIRED.NAME')).trim(),
      price: Yup.string().required(fm('PRODUCT.REQUIRED.PRICE')).trim(),
      mainCategoryId: Yup.string().required(fm('PRODUCT.REQUIRED.CATEGORY')).trim(),
    }),
    onSubmit: submitValues => {
      const data = {
        status: submitValues.status,
        name: submitValues.name,
        price: submitValues.price.toString(),
        comment: submitValues.description || '',
        categoryId: 0,
      };

      let categoryId;
      // eslint-disable-next-line no-plusplus
      for (let i = 1; i < 6; i++) {
        if (submitValues[`categoryId_${i}`] && !submitValues[`categoryId_${i + 1}`]) {
          categoryId = +submitValues[`categoryId_${i}`];
          break;
        }
      }

      if (!categoryId && submitValues.mainCategoryId) {
        categoryId = +submitValues.mainCategoryId;
      }

      if (categoryId) {
        data.categoryId = categoryId;
      }

      if (search) {
        // edit({ id: Number(product.id), data });
        editSearch({
          id: search.id,
          data,
        });
      } else {
        createSearch(data);
        // images?.forEach(img => data.append('photo[]', img));
        // add({ body: data });
      }
    },
  });

  return { formik };
};
