import React, { createContext, useContext, useEffect, useMemo, useReducer } from 'react';

/**
 * Both context used to create inside react `redux`-like global state managed
 * entirely by react.
 *
 * @see https://kentcdodds.com/blog/how-to-use-react-context-effectively
 */

const LayoutContext = {
  /**
   * Stores layout state, can be consumed globally.
   */
  State: createContext(null),

  /**
   * Stores `dispatch` function to update layout state, intended to be internal.
   */
  Dispatch: createContext(null),
};

/**
 * Layout action types, used to filter out dispatched actions.
 */
const actionTypes = {
  /**
   * Initializes layout state from provided `{ pathname, menuConfig }` action
   * payload.
   */
  INIT: 'INIT',

  /**
   * Updates current subheader from provided `{ title }` action payload.
   */
  SET_SUBHEADER: 'SET_SUBHEADER',
  SET_FOOTER: 'SET_FOOTER',

  /**
   * Controls splash screen visibility.
   */
  SHOW_SPLASH_SCREEN: 'SHOW_SPLASH_SCREEN',
  HIDE_SPLASH_SCREEN: 'HIDE_SPLASH_SCREEN',
};

/**
 * Used to lazily create initial layout state.
 */
function init() {
  const state = {
    subheader: { title: '', breadcrumb: [], description: '' },
    splashScreen: { refs: {} },
    footer: {},
  };
  return state;
}

function reducer(state: any, { type, payload }: any) {
  if (type === actionTypes.INIT) {
    const nextState = init();

    // Update only subheader.
    return { ...state, subheader: nextState.subheader };
  }

  if (type === actionTypes.SET_SUBHEADER) {
    return { ...state, subheader: payload };
  }

  if (type === actionTypes.SET_FOOTER) {
    return { ...state, footer: payload };
  }

  if (type === actionTypes.SHOW_SPLASH_SCREEN) {
    return {
      ...state,
      splashScreen: {
        ...state.splashScreen,
        refs: { ...state.splashScreen.refs, [payload.id]: true },
      },
    };
  }

  if (type === actionTypes.HIDE_SPLASH_SCREEN) {
    const { [payload.id]: skip, ...nextRefs } = state.splashScreen.refs;

    return {
      ...state,
      splashScreen: { ...state.splashScreen, refs: nextRefs },
    };
  }

  return state;
}

/**
 * Creates layout reducer and provides it's `state` and ` dispatch`.
 */
export function LayoutContextProvider({ history, children, menuConfig }: any) {
  const [state, dispatch]: any = useReducer(
    reducer,
    { menuConfig, pathname: history.router.location.pathname },
    // See https://reactjs.org/docs/hooks-reference.html#lazy-initialization
    init
  );

  // Subscribe to history changes and reinitialize on each change.
  useEffect(() => {
    dispatch({
      type: actionTypes.INIT,
      payload: { pathname: history.router.location.pathname, menuConfig },
    });
  }, [history.router.location.pathname, menuConfig]);

  const { refs: splashScreenRefs } = state.splashScreen;
  const splashScreenVisible = useMemo(() => Object.keys(splashScreenRefs).length > 0, [
    splashScreenRefs,
  ]);

  useEffect(() => {
    const splashScreen: any = document.getElementById('splash-screen');

    if (splashScreenVisible) {
      splashScreen.classList.remove('hidden');

      return () => {
        splashScreen.classList.add('hidden');
      };
    }

    const timeout = setTimeout(() => {
      splashScreen.classList.add('hidden');
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [splashScreenVisible]);

  // Pass state and dispatch to it's contexts.
  return (
    <LayoutContext.State.Provider value={state}>
      <LayoutContext.Dispatch.Provider value={dispatch}>
        {children}
      </LayoutContext.Dispatch.Provider>
    </LayoutContext.State.Provider>
  );
}

/**
 * Used to access latest layout context state.
 *
 * @example
 *
 * export function Subheader() {
 *   return (
 *     <LayoutContextConsumer>
 *       {({ subheader: { title } }) => <h1>{title}</h1>}
 *     </LayoutContextConsumer>
 *   );
 * }
 */
export const LayoutContextConsumer = LayoutContext.State.Consumer;

/**
 * Hook to access latest layout context state.
 *
 * @example
 *
 * export function Subheader() {
 *   const { subheader: { title } } = useLayoutContext();
 *
 *   return <h1>{title}</h1>;
 * }
 */
export function useLayoutContext() {
  const context = useContext(LayoutContext.State);

  if (!context) {
    throw new Error('');
  }

  return context;
}

/**
 * Used to override layout subheader state.
 */
export function LayoutSubheader({
  title,
  breadcrumb,
  description,
  back,
  show,
  onPress,
  btnTitle,
  textBtnAddCart,
  btnAddCart,
}: any) {
  const dispatch: any = useContext(LayoutContext.Dispatch);

  useEffect(() => {
    dispatch({
      type: actionTypes.SET_SUBHEADER,
      payload: {
        title,
        breadcrumb,
        description,
        back,
        show,
        onPress,
        btnTitle,
        textBtnAddCart,
        btnAddCart,
      },
    });
  }, [
    dispatch,
    title,
    breadcrumb,
    description,
    back,
    show,
    onPress,
    btnTitle,
    textBtnAddCart,
    btnAddCart,
  ]);

  return null;
}

export function LayoutFooter({ show }: any) {
  const dispatch: any = useContext(LayoutContext.Dispatch);
  useEffect(() => {
    dispatch({
      type: actionTypes.SET_FOOTER,
      payload: { show },
    });
  }, [dispatch, show]);

  return null;
}

export function LayoutSplashScreen({ visible = false }) {
  const dispatch: any = useContext(LayoutContext.Dispatch);

  useEffect(() => {
    if (!visible) {
      return;
    }

    const id = Math.random();

    dispatch({ type: actionTypes.SHOW_SPLASH_SCREEN, payload: { id } });

    return () => {
      dispatch({ type: actionTypes.HIDE_SPLASH_SCREEN, payload: { id } });
    };
  }, [visible, dispatch]);

  return null;
}
