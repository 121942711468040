import { TableCell, styled } from '@mui/material';

const TopTableCell = styled(TableCell)(({ theme }: any) => ({
  '&.MuiTableCell-head': {
    backgroundColor: '#1e1e2d',
    color: 'white',
    fontSize: 14,
    fontWeight: 'bold',
  },
  '&.MuiTableCell-body': {
    fontSize: 14,
    fontWeight: 'bold',
  },
  [theme.breakpoints.down('xs')]: {
    '&.MuiTableCell-head': {
      fontSize: 12,
      paddingRight: 0,
      // paddingLeft: theme.spacing(2),
    },
  },
}));

export default TopTableCell;
