import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { getUploadedFile } from '../../../../../crud/chat.crud';
import ChatSocket from '../../../../../utils/socket';
import { getResponseMessage } from '../../../../../utils/utils';

export const useDownloadFile: any = () => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const fetch = useCallback(async (id: string) => {
    if (!ChatSocket.myAuthToken) await ChatSocket.getMyAuthToken();
    setLoading(true);
    getUploadedFile(id)
      .then(({ data }) => {
        URL.createObjectURL(data);
        window.open(URL.createObjectURL(data), '_self');
      })
      .catch(e => {
        enqueueSnackbar(`${intl.formatMessage({ id: 'ERROR' })}: ${getResponseMessage(e)}`, {
          variant: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  return [fetch, loading];
};
