import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Card, IconButton, Typography } from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import { useSnackbar } from 'notistack';
import GridOnIcon from '@mui/icons-material/GridOn';
import ListIcon from '@mui/icons-material/List';

import clsx from 'clsx';
import AlertDialog from '../../../components/ui/Dialog/AlertDialog';
import Preloader from '../../../components/ui/Preloader/Preloader';

import { IAppState } from '../../../store/rootDuck';
import { actions as categoriesActions } from '../../../store/ducks/categories.duck';
import { actions as productCatalogActions } from '../../../store/ducks/product-catalog.duck';
import { actions as productActions } from '../../../store/ducks/product.duck';
import { API_DOMAIN } from '../../../constants';
import { setLayoutSubheader } from '../../../utils/layout';
import { actions as productTypesActions } from '../../../store/ducks/productType.duck';
import { actions as companiesActions } from '../../../store/ducks/companies.duck';
import { actions as profileActions } from '../../../store/ducks/profile.duck';
import { ICompany } from '../../../interfaces/companies';
import { UserRoles } from '../../../interfaces/user';
import { actions as cartActions } from '../../../store/ducks/cart.duck';
import useCrudSnackbar from '../../../hooks/useCrudSnackbar';
import { useStylesProductListById } from './hooks/useStyles';
import FilterCategoriesTree from './components/FilterCategoriesTree';
import ProductsTableListComponent from './components/ProductsTableList';
import { ProductsGrid } from './components';
import { toAbsoluteUrl } from '../../../../_base';
import StarIcon from './components/StarIcon';
import AlertCompanyDetail from './components/AlertCompanyDetail';

const ProductList: React.FC<TPropsFromRedux> = ({
  setViewType,
  viewType,
  isAdmin,
  isVender,
  filter,
  fetchCatalogCategories,
  catalogCategories,
  setSearch,
  productsCount,
  isSearching,
  products,
  loading,
  fetch,
  page,
  perPage,
  total,
  delProduct,
  clearDel,
  deleteError,
  setEmpty,
  me,
  fetchMe,
  loadingMe,
  successMe,
  clearMe,
  clearProducts,

  addProductLoading,
  addProductSuccess,
  addProductError,
  addProductToCart,
  setProductGuestCart,
  companyGuestCart,
  cartCompanyId,
  fetchCompany,
  company,
  clearFetchById,
  clearParameters,
  isAuthorized,
}) => {
  const classes1 = useStylesProductListById();
  const { classes } = classes1;
  const intl = useIntl();
  const navigate = useNavigate();
  const { categoryId, companyId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteId, setDeleteId] = useState<number | undefined>(-1);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [userCompany, setUserCompany] = useState<null | ICompany>();
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(null);
  const [isOpenFileDialog, setOpenFileDialog] = useState(false);
  // const [activBanner, setActiveBanner] = useState(true);

  // const [cartProductId, setCartProductId] = useState<number>(-1);
  const [isCartAlertOpen, setCartAlertOpen] = useState(false);

  const catId = /U\d+/g.test(categoryId!!) ? categoryId!!.slice(1) : categoryId;

  useEffect(() => {
    fetchMe();
    clearParameters();
    fetchCatalogCategories({ with_products: true, company_id: +companyId!! });
    // setActiveBanner(true);
    return () => {
      clearMe();
      clearProducts();
      clearFetchById();
    };
  }, []);

  useEffect(() => {
    if (me && !userCompany) {
      setUserCompany(me.company);
    }
  }, [successMe]);

  // useEffect(() => {
  //   if (/\d+/g.test(categoryId!!) && categoryId && me && !companyId) {
  //     const companyId = me.company ? me.company.id : undefined;
  //     fetch({ page, perPage, categoryId: +catId!!, companyId });
  //   } else {
  //     setEmpty();
  //   }
  // }, [fetch, categoryId, me]);
  //
  // useEffect(() => {
  //   if (companyId && /U\d+/g.test(categoryId!!)) {
  //     if (isAdmin) {
  //       fetch({ page, perPage, companyId: +companyId });
  //     } else {
  //       fetch({ page, perPage, companyId: +companyId, active: true });
  //     }
  //     fetchCompany(+companyId);
  //   } else {
  //     setEmpty();
  //   }
  // }, []);
  useEffect(() => {
    if (categoryId && /\d+/g.test(categoryId) && me && !companyId) {
      const companyId = me.company ? me.company.id : undefined;
      fetch({ page, perPage, categoryId: catId ? +catId : undefined, companyId });
    } else {
      setEmpty();
    }
  }, [fetch, categoryId, me]);

  useEffect(() => {
    if (companyId && categoryId && /U\d+/g.test(categoryId)) {
      fetch({ page, perPage, companyId: +companyId });
      fetchCompany(+companyId);
    } else {
      setEmpty();
    }
  }, [fetch, me, companyId]);

  useEffect(() => {
    if (deleteError) {
      enqueueSnackbar(deleteError, { variant: 'error' });
      setAlertOpen(false);
      clearDel();
    }
    return () => {
      clearDel();
    };
  }, [deleteError, clearDel, enqueueSnackbar]);

  useCrudSnackbar({
    success: addProductSuccess,
    error: addProductError,
    successMessage: intl.formatMessage({ id: 'CART.ADD_PRODUCT.SUCCESS' }),
    errorMessage: `${intl.formatMessage({ id: 'ERROR' })}: ${addProductError}`,
  });

  setLayoutSubheader({
    title: 'Просмотр товаров компании',
    back: true,
  });

  const handleDeleteDialog = useCallback((id: number | undefined) => {
    setDeleteId(id);
    setAlertOpen(true);
  }, []);

  const deleteAction = useCallback(() => {
    setAlertOpen(false);
    if (userCompany !== undefined && me) {
      if (me.is_admin) {
        fetch({ page, perPage, categoryId: +categoryId!! });
        delProduct({
          page,
          perPage,
          id: deleteId,
          categoryId: +categoryId!!,
          companyId: companyId && me && /U\d+/g.test(categoryId!!) ? +companyId : undefined,
        });
      } else if (userCompany) {
        const companyId = userCompany.id;
        delProduct({ page, perPage, id: deleteId, companyId, categoryId: +categoryId!! });
      }
    }
  }, [deleteId, delProduct, perPage, page, me, userCompany, companyId, categoryId]);

  const editAction = useCallback(
    (item: any) => {
      navigate(`/products/edit/${item.id}`, { state: { isBack: true } });
    },
    [navigate]
  );

  const viewAction = useCallback(
    (item: any) => {
      navigate(`/viewproduct/${item.id}`);
    },
    [navigate]
  );

  const handleCartDialog = useCallback(
    (item: any) => {
      if (isAuthorized) {
        // const newCart = cartCompanyId ? item?.company?.id !== cartCompanyId : false;
        // if (!newCart) {
        addProductToCart({
          product_id: item.id ? item?.id : 0,
          count: 1,
        });
        // } else {
        //   setCartProductId(item);
        //   setCartAlertOpen(true);
        // }
      } else {
        // const newCart = companyGuestCart ? item?.company?.id !== companyGuestCart : false;
        // if (!newCart) {
        setProductGuestCart({ data: item, type: 'cart' });
        // } else if (countProductsGuest === 0) {
        //   setProductGuestCart({ data: item, type: 'new' });
        // } else {
        //   setCartProductId(item);
        //   setCartAlertOpen(true);
        // }
      }
    },
    [addProductToCart, cartCompanyId, companyGuestCart, setProductGuestCart]
  );

  const openModalCompany = () => {
    setOpenFileDialog(true);
  };

  const resetProducts = useCallback(() => {
    if (isAdmin || isVender) {
      fetch({ page, perPage, companyId: +companyId!! });
    }
    fetch({ page, perPage, companyId: +companyId!!, active: true });
  }, []);

  const addCartProductAction = useCallback(() => {
    if (!addProductLoading) {
      addProductToCart({
        // product_id: cartProductId,
        product_id: -1,
        count: 1,
        newCart: true,
      });
    }
  }, [addProductToCart]);

  const goToCreateProduct = useCallback(() => {
    if (companyId && company) {
      navigate(`/products/create/company/${company.id}/${company.name}`);
    } else if (me?.is_admin) {
      navigate(`/products/create/${catId}`);
    } else {
      navigate(`/products/create/company/${me?.company?.id}/${me?.company?.name}`);
    }
  }, [navigate, me, categoryId, companyId, company]);

  if (loadingMe || !categoryId) return <Preloader />;

  return (
    <>
      <AlertDialog
        open={isAlertOpen}
        message={intl.formatMessage({ id: 'PRODUCT.DELETE.TEXT' })}
        okText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })}
        handleClose={() => {
          clearDel();
          setAlertOpen(false);
        }}
        handleAgree={() => deleteAction()}
      />

      <AlertDialog
        open={isCartAlertOpen}
        message={intl.formatMessage({ id: 'CART.ALERT.TEXT' })}
        okText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })}
        handleClose={() => {
          setCartAlertOpen(false);
        }}
        handleAgree={() => addCartProductAction()}
      />

      <AlertCompanyDetail
        open={isOpenFileDialog}
        message={intl.formatMessage({ id: 'FILE.IMPORT.ALERT.TEXT' })}
        company={company}
        handleClose={() => {
          setOpenFileDialog(false);
        }}
        // handleAgree={goToUploadProducts}
      />

      {(!me?.roles.includes(UserRoles.ROLE_BUYER) &&
        !me?.roles.includes(UserRoles.ROLE_BUYER_STAFF) &&
        me?.company &&
        me.company.name) ||
        (me?.is_admin && (
          <Button
            style={{ marginBottom: 20 }}
            color='primary'
            variant='contained'
            onClick={() => goToCreateProduct()}
          >
            {intl.formatMessage({ id: 'PRODUCT.BUTTON.ADD' })}
          </Button>
        ))}

      <Row>
        <Col>
          <div
            onClick={openModalCompany}
            style={{
              background: 'white',
              padding: 20,
              borderRadius: 4,
              marginBottom: 25,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              cursor: 'pointer',
            }}
          >
            <div style={{ display: 'flex', width: '80%' }}>
              <Card
                className={classes.imgContainer}
                elevation={0}
                style={{
                  height: 'auto',
                  maxHeight: 85,
                  alignItems: 'start',
                  padding: 0,
                  margin: 0,
                }}
              >
                <img
                  src={
                    company?.logo
                      ? // @ts-ignore
                        `${API_DOMAIN}/${company.logo.path}`
                      : toAbsoluteUrl('/images/placeholder.png')
                  }
                  className={classes.imgCom}
                  alt=''
                />
              </Card>

              <div style={{ paddingLeft: 20 }}>
                <p className={classes.name}>
                  <b>{company?.brand}</b>
                </p>
                <p className={classes.listKey}>Премиум продавец</p>

                {company?.rating && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginTop: 3,
                    }}
                  >
                    <StarIcon />

                    <p className={classes.listText}>{company.rating}</p>
                  </div>
                )}
              </div>
            </div>

            <div
              style={{
                width: '10%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              <p className={classes.listKey}>О продавце</p>
            </div>
          </div>

          <div className={classes.container}>
            <div className={classes.filterCol}>
              <FilterCategoriesTree
                categories={catalogCategories || []}
                resetProducts={resetProducts}
                searchProducts={fetch}
                filter={filter}
                setSearch={setSearch}
                loading={loading}
                selectedCategoryId={selectedCategoryId}
                setSelectedCategoryId={setSelectedCategoryId}
                companyId={Number(companyId)}
                isAdmin={isAdmin}
                isVender={isVender}
              />
            </div>

            <div className={classes.productsCol}>
              {loading ? (
                <Preloader />
              ) : (
                <>
                  {!products || !products.length ? (
                    <Typography className={classes.empty} component='h5' variant='h5'>
                      {intl.formatMessage({ id: 'PRODUCT.CATALOG.LIST.EMPTY' })}
                    </Typography>
                  ) : (
                    <div className={clsx(classes.card, { [classes.revers]: isSearching })}>
                      <div>
                        <div className={classes.productColHeader}>
                          <div className={classes.productColHeaderItem}>
                            <Typography variant='h6' className={classes.count}>
                              {isSearching
                                ? `${intl.formatMessage({ id: 'ITEMS.FOUND' })} ${
                                    products.length
                                  } `
                                : intl.formatMessage({ id: 'PRODUCT.ALL.PRODUCTS' })}
                            </Typography>
                          </div>

                          <div className={classes.productColHeaderItem}>
                            <IconButton
                              onClick={() => setViewType('grid')}
                              color={viewType === 'grid' ? 'primary' : 'inherit'}
                            >
                              <GridOnIcon className={classes.icon} />
                            </IconButton>

                            <IconButton
                              onClick={() => setViewType('table')}
                              color={viewType === 'table' ? 'primary' : 'inherit'}
                            >
                              <ListIcon className={classes.icon} />
                            </IconButton>
                          </div>
                        </div>

                        {viewType === 'table' ? (
                          <ProductsTableListComponent
                            companyId=''
                            data={products}
                            page={page}
                            perPage={perPage}
                            total={total}
                            categoryId=''
                            fetch={({ page, perPage }) =>
                              fetch({ page, perPage, companyId: +companyId! })
                            }
                            isAuthorized={isAuthorized}
                            isAdmin={!!isAdmin}
                            me={me}
                            addProductLoading={addProductLoading}
                            handleCartDialog={handleCartDialog}
                            viewAction={viewAction}
                            editAction={editAction}
                            handleDeleteDialog={handleDeleteDialog}
                          />
                        ) : (
                          // <ProductsGrid
                          //   addProductLoading={addProductLoading}
                          //   isAdmin={!!isAdmin}
                          //   me={me}
                          //   handleCartDialog={handleCartDialog}
                          //   viewAction={viewAction}
                          //   editAction={editAction}
                          //   handleDeleteDialog={handleDeleteDialog}
                          //   data={products}
                          //   page={page}
                          //   perPage={perPage}
                          //   total={total}
                          //   categoryId=''
                          //   companyId=''
                          //   fetch={({ page, perPage }) => fetch({ page, perPage, filter })}
                          // />
                          <ProductsGrid
                            // isSearching={isSearching}
                            addProductLoading={addProductLoading}
                            isAuthorized={isAuthorized}
                            isAdmin={!!isAdmin}
                            me={me}
                            handleCartDialog={handleCartDialog}
                            viewAction={viewAction}
                            editAction={editAction}
                            handleDeleteDialog={handleDeleteDialog}
                            // edit={edit}
                            // isHideProduct={isHideProduct}
                            data={products}
                            page={page}
                            perPage={perPage}
                            total={productsCount}
                            categoryId=''
                            companyId=''
                            //   fetch={({ page, perPage }) =>
                            //   fetch({
                            //   page,
                            //   perPage,
                            //   filter,
                            //   categoryId: selectedCategoryId || 0,
                            // })
                            // }
                            fetch={({ page, perPage }) =>
                              fetch({
                                page,
                                perPage,
                                companyId: +companyId!,
                              })
                            }
                          />
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    products: state.products.products,
    loading: state.products.loading,
    page: state.products.page,
    perPage: state.products.per_page,
    total: state.products.total,
    deleteError: state.products.delError,

    me: state.profile.me,
    loadingMe: state.profile.loading,
    successMe: state.profile.success,

    addProductLoading: state.cart.addProductLoading,
    addProductSuccess: state.cart.addProductSuccess,
    addProductError: state.cart.addProductError,

    isSearching: state.productsCatalog.isSearching,
    productsCount: state.productsCatalog.total,
    filter: state.productsCatalog.filter,
    isAdmin: state.auth.user?.is_admin,
    isVender: state.auth.user?.is_vendor,
    viewType: state.productsCatalog.viewType,

    catalogCategories: state.categories.catalogCategories,

    cartCompanyId: state.cart.companyId,
    company: state.companies.company,
    isAuthorized: state.auth.user != null,
    companyGuestCart: state.cart.guestCart?.cartCompanyId,
  }),
  {
    fetch: productActions.fetchRequest,
    delProduct: productActions.delRequest,
    clearDel: productActions.clearDel,
    setEmpty: productActions.setEmpty,
    clearProducts: productActions.clearProducts,
    clearParameters: productTypesActions.clearParams,

    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,

    fetchCompany: companiesActions.fetchByIdRequest,
    clearFetchById: companiesActions.clearFetchById,

    addProductToCart: cartActions.addProductRequest,
    setProductGuestCart: cartActions.setProductGuestCart,

    setSearch: productCatalogActions.setSearch,
    fetchCatalogCategories: categoriesActions.fetchCatalogCategories,
    fetchPro: productCatalogActions.fetchRequest,
    setViewType: productCatalogActions.setViewType,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ProductList);
