import { makeStyles } from 'tss-react/mui';

export const useStylesReviewsList = makeStyles()(() => {
  return {
    container: {
      width: '100%',
      maxWidth: 700,
      margin: 'auto',
      padding: '40px 20px',
    },

    textTotalRating: {
      fontStyle: 'bold',
      fontWeight: 600,
      color: '#000',
      marginBottom: 30,
      textAlign: 'center',
    },

    wrapperBlock: {
      marginBottom: '2.25rem',
      '&:last-child': {
        margin: 0,
      },
    },

    wrapperUserInfo: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '2rem',
      flexWrap: 'wrap',
    },

    wrapperUserAvatar: {
      display: 'inline-block',
      flexShrink: 0,
      position: 'relative',
      borderRadius: '.475rem',
      marginRight: '1.25rem',
    },

    userAvatar: {
      width: 50,
      height: 50,
      borderRadius: '0.475rem',
    },

    userFio: {
      fontWeight: 600,
      fontSize: '1.15rem',
      wordWrap: 'break-word',
      color: '#3f4254',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },

    reviewText: {
      margin: 0,
      fontWeight: 400,
      fontSize: '1.15rem',
      color: '#5e6278',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },

    wrapperPaginator: {
      display: 'flex',
      justifyContent: 'center',
    },
  };
});

export const useStylesProductFavorites = makeStyles()(theme => {
  return {
    empty: {
      marginBottom: 20,
      marginTop: 20,
    },
    buttons: {
      marginRight: theme.spacing(3),
    },
    card: {
      marginBottom: theme.spacing(3),
    },
    categoryTree: {
      maxWidth: 400,
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    img: {
      maxHeight: 55,
    },
    countReviews: {
      fontSize: 14,
      fontFamily: 'Open Sans',
      fontWeight: 400,
      color: '#5D78FFFF',
    },
  };
});

export const useStylesProductCatalog = makeStyles()(theme => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    empty: {
      marginBottom: 20,
      marginTop: 20,
    },
    card: {
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('md')]: {
        marginTop: 20,
      },
    },
    count: {
      fontStyle: 'bold',
      fontWeight: 600,
      color: '#000',
      marginRight: 15,
    },
    filterCol: {
      width: 326,
    },
    productsCol: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 'calc(100% - 326px - 30px)',
      },
    },
    revers: {
      display: 'flex',
      flexDirection: 'column-reverse',
      justifyContent: 'flex-end',
    },
    productColHeader: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 15,
    },
    productColHeaderItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    wrapPaginationItem: {
      border: 'none',
      borderRadius: 0,
      fontSize: 12,
      fontWeight: 400,
      '&.MuiPaginationItem-outlinedSecondary.Mui-selected': {
        color: '#000',
        border: 'none !important',
        borderRadius: 0,
        backgroundColor: '#d8e3d5',
      },
      '&.MuiPaginationItem-outlined': {
        border: 'none !important',
        borderRadius: 0,
      },
    },
    title: {
      fontStyle: 'bold',
      fontWeight: 800,
      color: '#d01c1f',
      marginRight: 15,
      fontSize: 18,
    },
    logo_container: {
      display: 'flex',
      flexDirection: 'row',
      background: '#ffffff',
      [theme.breakpoints.down('sm')]: {
        width: '75%',
      },
      [theme.breakpoints.up('sm')]: {
        width: '55%',
      },
      [theme.breakpoints.up('md')]: {
        width: '55%',
      },
      [theme.breakpoints.up('lg')]: {
        width: '45%',
      },
      [theme.breakpoints.up('xl')]: {
        width: '30%',
      },
      // @ts-ignore
      [theme.breakpoints.up('xxl')]: {
        width: '22.5%',
      },
      height: 40,
      alignItems: 'center',
      paddingLeft: 10,
      borderRadius: 4,
      position: 'relative',
    },
    cansel_content: {
      cursor: 'pointer',
      position: 'absolute',
      top: 0,
      right: 5,
    },
  };
});

export const useStylesFilterType = makeStyles()(theme => {
  return {
    cardCategory: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      top: 100,
      boxShadow: 'none !important',
      overflow: 'visible',
      [theme.breakpoints.up('md')]: {},
    },
    filterButton: {
      backgroundColor: '#fff',
      cursor: 'pointer',
      width: '100%',
      height: 42,
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 600,
      color: '#404355',
      fontSize: 14,
      lineHeight: '42px',

      '&.disabled': {
        backgroundColor: '#eee',
        color: '#d6d6d6',
        cursor: 'not-allowed',
      },
    },
    showAllButton: {
      marginRight: 10,
      color: '#b5b5c3',
      fontWeight: 600,

      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    button: {
      '&:active': {
        opacity: 0.2,
      },
    },
    cardButtons: {
      justifyContent: 'flex-end',
      padding: 16,
    },
  };
});

export const useStylesSelectFiltepType = makeStyles()(theme => {
  return {
    rowFilter: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    containerFilter: {
      marginBottom: 16,
    },
    priceFilterWrap: {
      display: 'flex',
    },
    notchedOutline: {
      borderWidth: 0,
      borderColor: `transparent !important`,
    },
    cssOutlinedInput: {
      fontWeight: 600,
      '&::placeholder': {
        textOverflow: 'ellipsis !important',
        color: '#181c32',
      },
      '&$cssFocused $notchedOutline': {
        borderColor: `${theme.palette.primary.main} !important`,
      },
    },
    input: {
      height: 38,
      paddingTop: 0,
      paddingBottom: 0,
      backgroundColor: '#f5f8fa',
      padding: '0 8px',
      borderRadius: 4,
    },
    label: {
      marginBottom: 7,
      fontSize: 14,
      fontWeight: 600,
      color: '#181c32',
    },
    cssFocused: {},
    filterItem: {
      transition: '0.3s ease-out',

      '&.hide': {
        [theme.breakpoints.down('sm')]: {
          height: 0,
          transform: 'scaleY(0)',
          transformOrigin: 'top',
          overflow: 'hidden',
        },
      },
    },
    field: {
      marginTop: 15,
      marginBottom: 8,
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    containerIcons: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      alignItems: 'center',
      justifyItems: 'center',
      gap: 20,
    },
    containerIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      width: '100%',
      padding: '5px 0',
    },
  };
});

export const useStylesProductListById = makeStyles()(theme => {
  return {
    empty: {
      marginBottom: 20,
      marginTop: 20,
    },
    name: {
      fontSize: 20,
      fontWeight: 600,
      margin: 0,

      [theme.breakpoints.up('md')]: {
        fontSize: 34,
        lineHeight: 1,
      },
    },
    listText: {
      marginBottom: 0,
      fontSize: 17,
      fontWeight: 'bold',
      color: '#000000',
      paddingLeft: 10,
    },
    listKey: {
      marginBottom: 0,
      fontSize: 17,
      fontWeight: 400,
      color: '#757575',
    },
    imgContainer: {
      height: 400,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        maxWidth: 280,
        height: 200,
      },
      [theme.breakpoints.only('sm')]: {
        maxWidth: 350,
        height: 250,
      },
    },
    imgCom: {
      maxWidth: 90,
      [theme.breakpoints.down('xs')]: {
        maxWidth: 75,
      },
      [theme.breakpoints.only('sm')]: {
        maxWidth: 85,
      },
      cursor: 'pointer',
    },
    buttons: {
      marginRight: theme.spacing(3),
    },
    card: {
      // marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    categoryTree: {
      maxWidth: 400,
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    img: {
      maxHeight: 55,
    },
    icon: {
      width: 22,
      height: 22,
    },
    iconDelete: {
      color: '#0685d1',
      width: 22,
      height: 22,
    },
    cell: {
      fontSize: 18,
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    count: {
      fontStyle: 'bold',
      fontWeight: 600,
      color: '#000',
      marginRight: 15,
      fontSize: 18,
    },
    filterCol: {
      width: 326,
    },
    productsCol: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 'calc(100% - 326px - 15px)',
      },
    },
    revers: {
      display: 'flex',
      flexDirection: 'column-reverse',
      justifyContent: 'flex-end',
    },
    productColHeader: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 15,
    },
    productColHeaderItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    title: {
      fontStyle: 'bold',
      fontWeight: 800,
      color: '#d01c1f',
      marginRight: 15,
      fontSize: 18,
    },
  };
});

export const useStylesProductList = makeStyles()(theme => {
  return {
    empty: {
      marginBottom: 20,
      marginTop: 20,
    },
    buttons: {
      marginRight: theme.spacing(3),
    },
    card: {
      // marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    categoryTree: {
      maxWidth: 400,
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    img: {
      maxHeight: 55,
    },
    countReviews: {
      fontSize: 14,
      fontFamily: 'Open Sans',
      fontWeight: 400,
      color: '#5D78FFFF',
    },
    textInput: {
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: 450,
      alignItems: 'center',
    },
    createBtn: {
      marginBottom: 25,
    },
  };
});

export const useStylesTypeForm = makeStyles()(theme => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 20,
      borderRadius: 4,
      alignItems: 'center',
      justifyContent: 'center',
      boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
    },
    form: {
      maxWidth: '800px',
      width: '100%',
      padding: theme.spacing(3),
    },
    buttonAddContainer: {
      flex: 1,
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
    },
    labelTextFieldShrink: {
      color: '#000000',
    },
    icon: {
      width: 22,
      height: 22,
    },
    formControlLabel: {
      fontSize: 17,
    },
    textField: {
      flexGrow: 1,
      marginTop: 21,
      marginBottom: 0,
    },
    btnDel: {
      marginLeft: theme.spacing(2),
    },
    leftIcon: {
      height: 40,
      width: 40,
      padding: theme.spacing(1),
      marginLeft: theme.spacing(0.5),
      marginTop: theme.spacing(1),
    },
    containerName: {
      display: 'flex',
      flexDirection: 'row',
    },
    typeName: {
      width: '50%',
    },
    typeNameLang: {
      marginLeft: 8,
      width: '50%',
    },
    typeNameMainLang: {
      width: '50%',
    },
  };
});

export const useStylesModalReview = makeStyles()(theme => {
  return {
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    input: {
      width: '100%',
      '& .MuiOutlinedInput-multiline': {
        padding: '18px 25px',
        borderColor: '#5e6278',
      },
      marginBottom: 20,
    },
    containerButtons: {
      display: 'flex',
    },
    btn: {
      minWidth: 240,
    },
    btnText: {
      fontSize: 17,
      fontWeight: 'bold',
      textTransform: 'initial',
    },
    buttonFormLeft: {
      flex: 1,
      marginRight: 20,
      backgroundColor: 'transparent',
      color: '#000',
      borderColor: '#000',
      borderWidth: 1,
    },
    buttonForm: {
      flex: 1,
    },
    statisticsHeader: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      marginBottom: 16,
    },
    titleCreate: {
      fontSize: 20,
      fontWeight: 'bold',
      marginBottom: 14,
    },
    titleDesc: {
      fontSize: 20,
      fontWeight: 'bold',
      marginBottom: 19,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: 'rgba(0, 0, 0, 0.35) !important', // Цвет и прозрачность заднего фона
    },
    modalContent: {
      backgroundColor: 'white',
      borderRadius: '4px',
      padding: '26px 50px 30px',
      width: 600,
      zIndex: 2000,
    },
  };
});

export const useStylesHeaderAddMultiply = makeStyles()(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#e5e5ea',
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      padding: '15px 10px',
    },
    containerWhite: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#ffffff',
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      padding: '15px 10px',
      border: '0 solid #216214',
      borderBottomWidth: 1,
    },
    block: {
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      fontSize: 17,
      fontWeight: 700,
      marginBottom: 10,
      color: ' #000000',
    },
    wrapperFields: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    fieldCommon200: {
      width: 200,
      marginLeft: 10,
      marginBottom: 10,
      marginTop: 0,
    },
    fieldCommon180: {
      width: 180,
      marginLeft: 10,
      marginBottom: 10,
      marginTop: 0,
    },
    fieldCommon80: {
      width: 80,
      marginLeft: 10,
      marginBottom: 10,
      marginTop: 0,
    },
    addressName: {
      maxWidth: 400,
      width: '100%',
      marginLeft: 10,
      marginBottom: 10,
      marginTop: 10,
    },
    fieldCommon400: {
      maxWidth: 400,
      width: '100%',
      marginLeft: 10,
      marginBottom: 10,
    },
    fieldCommon130: {
      maxWidth: 130,
      width: '100%',
      marginLeft: 10,
      marginBottom: 10,
      marginTop: 10,
    },
    fieldCommon90: {
      maxWidth: 90,
      width: '100%',
      marginLeft: 10,
      marginBottom: 10,
      marginTop: 10,
    },
    fieldCommon135: {
      minWidth: 135,
      marginLeft: 10,
      marginBottom: 10,
      marginTop: 10,
    },
    active: {
      marginLeft: 10,
    },
    titleTop: {
      fontSize: 17,
      fontWeight: 700,
      marginBottom: 10,
      color: ' #000000',
      marginTop: 5,
    },
    param170: {
      width: 170,
      marginLeft: 10,
      marginBottom: 10,
      marginTop: 10,
      height: 55,
    },
    paramEnum: {
      width: 170,
      marginLeft: 10,
      marginBottom: 10,
      marginTop: 10,
      height: 55,
    },
    paramText: {
      width: 170,
      marginLeft: 10,
      marginBottom: 10,
      marginTop: 10,
    },
    btns: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      marginTop: 5,
      marginLeft: 10,
      marginRight: 10,
    },
    inp: {
      display: 'flex',
      gap: 10,
    },
    closeButton: {
      // borderRadius: '25px 0 25px 0',
      backgroundColor: '#441861',
      color: 'white',
      paddingLeft: 15,
      paddingRight: 15,
      marginRight: 16,
      marginBottom: 12,
      borderRadius: 0,
      '&:hover': {
        backgroundColor: '#441861',
      },
    },
  };
});

export const useStylesHeaderEditPricing = makeStyles()(theme => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#e5e5ea',
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      padding: '15px 10px',
    },
    containerWhite: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#ffffff',
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      padding: '15px 10px',
      border: '0 solid #216214',
      borderBottomWidth: 1,
    },
    block: {
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      fontSize: 17,
      fontWeight: 700,
      marginBottom: 10,
      color: ' #000000',
    },
    wrapperFields: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    fieldCommon200: {
      width: 200,
      marginLeft: 10,
      marginBottom: 10,
      marginTop: 0,
    },
    fieldCommon180: {
      width: 180,
      marginLeft: 10,
      marginBottom: 10,
      marginTop: 0,
    },
    fieldCommon80: {
      width: 80,
    },
    inp: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 10,
    },
    inpGroup1: {
      height: 50,
      minWidth: 110,
      maxWidth: 130,
      [theme.breakpoints.down('sm')]: {
        minWidth: 100,
      },
    },
  };
});

export const useStylesAddMultiply = makeStyles()(() => {
  return {
    closeButton: {
      // borderRadius: '25px 0 25px 0',
      backgroundColor: '#441861',
      color: 'white',
      paddingLeft: 15,
      paddingRight: 15,
      marginRight: 16,
      marginBottom: 12,
      '&:hover': {
        backgroundColor: '#441861',
      },
    },
    autocomplete: {
      minWidth: 400,
      marginLeft: 10,
      marginBottom: 10,
      marginTop: 0,
    },
    addressName: {
      maxWidth: 400,
      width: '100%',
      marginLeft: 10,
      marginBottom: 10,
      marginTop: 10,
    },
    fieldCommon400: {
      maxWidth: 400,
      width: '100%',
      marginLeft: 10,
      marginBottom: 10,
    },
    fieldCommon130: {
      maxWidth: 130,
      width: '100%',
      marginLeft: 10,
      marginBottom: 10,
      marginTop: 10,
    },
    fieldCommon90: {
      maxWidth: 90,
      width: '100%',
      marginLeft: 10,
      marginBottom: 10,
      marginTop: 10,
    },
    fieldCommon135: {
      minWidth: 135,
      marginLeft: 10,
      marginBottom: 10,
      marginTop: 10,
    },
    active: {
      marginLeft: 10,
    },
    titleTop: {
      fontSize: 17,
      fontWeight: 700,
      marginBottom: 10,
      color: ' #000000',
      marginTop: 5,
    },
    param170: {
      width: 170,
      marginLeft: 10,
      marginBottom: 10,
      marginTop: 10,
      height: 55,
    },
    paramEnum: {
      minWidth: 170,
      marginLeft: 10,
      marginBottom: 10,
      marginTop: 10,
      height: 55,
    },
    paramText: {
      width: 170,
      marginLeft: 10,
      marginBottom: 10,
      marginTop: 10,
    },
    btns: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      marginTop: 5,
      marginLeft: 10,
      marginRight: 10,
    },
    inp: {
      display: 'flex',
      marginTop: 10,
    },
  };
});

export const useStylesEditPricing = makeStyles()(() => {
  return {
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
      flexShrink: 0,
      flexGrow: 1,
    },
    wrapperFilter: {
      axWidth: 265,
      minWidth: 245,
      overflowY: 'auto',
      paddingLeft: 20,
      marginTop: 10,
    },
    wrapperTable: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
  };
});
