import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import UserProfile from '../../partials/layout/UserProfile';
import SearchField from '../../../app/components/ui/SearchField';
import { IUser, UserRoles } from '../../../app/interfaces/user';
import { useDefineUserRole } from '../../../app/hooks';

const useStyles = makeStyles()(theme => {
  return {
    menuContainer: {
      display: 'block',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    menu: {
      width: 25,
      height: 20,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    topbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: 'calc(100%)',
      gap: 100,
    },
    toggler: {
      height: 3,
      borderRadius: 3,
      width: '100%',
      backgroundColor: '#cecece',
    },
    title: {
      margin: 0,
      fontWeight: 'bold',
      fontSize: 15,
      padding: '5px 10px',
      backgroundColor: '#eaeaea',
      borderRadius: 5,
    },
  };
});

type TProps = {
  me?: IUser;
  showBurger?: boolean;
  isAuthorized?: boolean;
};

const Topbar: React.FC<TProps> = ({ me, showBurger, isAuthorized }) => {
  const classes1 = useStyles();
  const { classes } = classes1;
  const intl = useIntl();
  const isBuyer = useDefineUserRole(me, ['ROLE_BUYER', 'ROLE_BUYER_STAFF']);
  return (
    <div className={`kt-header__topbar ${classes.topbar}`}>
      <div className={classes.menuContainer}>
        {showBurger ? (
          <div id='kt_header_mobile_toggler' className={classes.menu}>
            <span className={classes.toggler} />
            <span className={classes.toggler} />
            <span className={classes.toggler} />
          </div>
        ) : (
          <Link to={'/products/catalog'}>
            <p className={classes.title}>
              {intl.formatMessage({ id: 'MENU.PRODUCTS.CATALOG' })}
            </p>
          </Link>
        )}
      </div>
      <div />
      {isBuyer ? (
        <>
          <SearchField isSubHeader={true} />
        </>
      ) : !isAuthorized ? (
        <>
          <SearchField isSubHeader={true} />
        </>
      ) : (
        <></>
      )}
      <UserProfile showAvatar={true} />
    </div>
  );
};

export default Topbar;
