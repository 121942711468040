import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import {
  Badge,
  Card,
  CardContent,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { createStyles, Theme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MessageIcon from '@mui/icons-material/Message';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import RateReviewIcon from '@mui/icons-material/RateReviewOutlined';

import TablePaginator from '../../../../components/TablePaginator/TablePaginator';
import { thousands } from '../../../../utils/utils';
import { IProduct } from '../../../../interfaces/product';
import { SHeadTableCell } from '../../../../components/styledComponents/Tables';
import { IUser } from '../../../../interfaces/user';
import StatusIndicator from '../../../../components/styledComponents/StatusIndicator';
import homeStyles from '../../homeStyles';
import { getProductImage } from '../utils/getProductImage';
import { toAbsoluteUrl } from '../../../../../_base';
import { useDefineUserRole } from '../../../../hooks';
// import { useCreateChat } from '../../chats/chatsPage/hooks/useCreateChat';

interface IProductsProps {
  data: IProduct[];
  page?: number;
  perPage?: number;
  total?: number;
  categoryId: string;
  companyId: string;
  fetch?: (data: { page: number; perPage: number }) => void;
  isAuthorized: boolean;
  isAdmin: boolean;
  me: IUser | undefined;
  addProductLoading: boolean;
  handleCartDialog: any;
  viewAction: any;
  editAction?: any;
  handleDeleteDialog?: any;
}
const ProductsTableListComponent: React.FC<IProductsProps> = ({
  data,
  categoryId,
  page,
  perPage,
  total,
  fetch,
  isAuthorized,
  isAdmin,
  me,
  addProductLoading,
  handleCartDialog,
  viewAction,
  editAction,
  handleDeleteDialog,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const homeClasses = homeStyles();
  const navigate = useNavigate();
  const isBuyer = useDefineUserRole(me, ['ROLE_BUYER', 'ROLE_BUYER_STAFF']);
  const isVendor = useDefineUserRole(me, ['ROLE_VENDOR', 'ROLE_VENDOR_STAFF']);
  // const { createChatFetch } = useCreateChat();

  const getStock = useCallback(
    (product: IProduct) => {
      return isBuyer || !me ? product.stock_info?.available_quantity : product.stock_quantity;
    },
    [isBuyer, me]
  );

  // const newChat = useCallback((userId?: string | number) => {
  //   createChatFetch({ userId });
  // }, []);

  return (
    <Row>
      <Col>
        {!data || !data.length ? (
          <Typography className={classes.empty} component='h5' variant='h5'>
            {intl.formatMessage({ id: 'COMMON.LIST.EMPTY' })}
          </Typography>
        ) : (
          <>
            <Card className={classes.card}>
              <CardContent className={homeClasses.classes.tableContainer}>
                <Table aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      {isAuthorized && isAdmin && (
                        <SHeadTableCell style={{ fontWeight: 'bold' }}>
                          {intl.formatMessage({ id: 'PRODUCT.TABLE.ID' })}
                        </SHeadTableCell>
                      )}
                      <SHeadTableCell style={{ fontWeight: 'bold' }}>
                        {intl.formatMessage({ id: 'PRODUCT.TABLE.PREVIEW' })}
                      </SHeadTableCell>
                      <SHeadTableCell style={{ fontWeight: 'bold' }}>
                        {intl.formatMessage({ id: 'PRODUCT.TABLE.NAME' })}
                      </SHeadTableCell>
                      <SHeadTableCell style={{ fontWeight: 'bold' }}>
                        {intl.formatMessage({ id: 'PRODUCT.TABLE.PRICE' })}
                      </SHeadTableCell>
                      {isAdmin && (
                        <SHeadTableCell style={{ fontWeight: 'bold' }}>
                          {intl.formatMessage({ id: 'PRODUCT.TABLE.STOCK.QUANTITY' })}
                        </SHeadTableCell>
                      )}
                      {!isBuyer && isAuthorized && (
                        <SHeadTableCell style={{ fontWeight: 'bold' }}>
                          {intl.formatMessage({ id: 'PRODUCT.TABLE.ACTIVE' })}
                        </SHeadTableCell>
                      )}
                      <SHeadTableCell style={{ fontWeight: 'bold' }}>
                        {intl.formatMessage({ id: 'MENU.COMPANY' })}
                      </SHeadTableCell>
                      <SHeadTableCell style={{ fontWeight: 'bold' }}>
                        {intl.formatMessage({ id: 'PRODUCT.TABLE.ACTIONS' })}
                      </SHeadTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map(item => (
                      <TableRow key={item.id}>
                        {isAuthorized && isAdmin && (
                          <TableCell scope='row'>{item.id || '-'}</TableCell>
                        )}
                        <TableCell>
                          <RouterLink to={`/viewproduct/${item.id}`}>
                            <img
                              src={getProductImage(item)}
                              className={classes.img}
                              alt=''
                              onError={(e: any) => {
                                e.target.src = toAbsoluteUrl('/images/placeholder.png');
                              }}
                            />
                          </RouterLink>
                        </TableCell>
                        <TableCell>
                          <RouterLink to={`/viewproduct/${item.id}`}>
                            {item.translations?.[intl.locale]?.name || item.name || '-'}
                          </RouterLink>
                        </TableCell>
                        <TableCell>
                          {item.price ? thousands(String(item.price)) : '-'}
                        </TableCell>
                        {isAdmin && <TableCell>{getStock(item) || '-'}</TableCell>}
                        {!isBuyer && isAuthorized && (
                          <TableCell>
                            <StatusIndicator isActive={item.active} />
                          </TableCell>
                        )}
                        <TableCell>
                          {item.company ? (
                            <Link
                              component={RouterLink}
                              to={
                                isAdmin
                                  ? `/companies/edit/${item.company?.id}`
                                  : `/viewcompany/${item.company?.id}`
                              }
                              className={classes.buttons}
                            >
                              {item.company?.name}
                            </Link>
                          ) : (
                            '-'
                          )}
                        </TableCell>
                        <TableCell>
                          <div>
                            {!isVendor && !isAdmin && (
                              // !!getStock(item) &&
                              // (getStock(item) || 0) > 0 &&
                              <IconButton
                                disabled={addProductLoading}
                                onClick={() => handleCartDialog(item)}
                              >
                                <AddShoppingCartIcon color='primary' />
                              </IconButton>
                            )}

                            <IconButton onClick={() => viewAction(item)}>
                              <VisibilityIcon color='primary' />
                            </IconButton>

                            {!isBuyer && (
                              <>
                                {editAction && (
                                  <>
                                    <IconButton onClick={() => editAction(item)}>
                                      <EditIcon color='primary' />
                                    </IconButton>
                                    <IconButton onClick={() => editAction(item, true)}>
                                      <Badge
                                        badgeContent={item.review_count || 0}
                                        color='primary'
                                      >
                                        <RateReviewIcon color='primary' />
                                      </Badge>
                                    </IconButton>
                                  </>
                                )}
                                {isAdmin && handleDeleteDialog && (
                                  <IconButton
                                    onClick={() => {
                                      handleDeleteDialog(item);
                                    }}
                                  >
                                    <DeleteIcon color='error' />
                                  </IconButton>
                                )}
                                {isBuyer ||
                                  (isVendor && (
                                    <IconButton
                                      onClick={() => {
                                        // newChat(me?.id);
                                        navigate(`/chats`, {
                                          state: {
                                            createUserChat: me?.id,
                                          },
                                        });
                                      }}
                                      color='primary'
                                      aria-label='previous'
                                    >
                                      <MessageIcon />
                                    </IconButton>
                                  ))}
                              </>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  {fetch && (
                    <TableFooter>
                      <TableRow>
                        <TablePaginator
                          id={+categoryId}
                          page={page || 1}
                          realPerPage={data.length}
                          perPage={perPage || 20}
                          total={total || 0}
                          fetchRows={fetch}
                          label={intl.formatMessage({ id: 'PRODUCT.TABLE.PERPAGE' })}
                          rowsPerPageOptions={[12, 36, 108]}
                        />
                      </TableRow>
                    </TableFooter>
                  )}
                </Table>
              </CardContent>
            </Card>
          </>
        )}
      </Col>
    </Row>
  );
};

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    empty: {
      marginBottom: 20,
    },
    buttons: {
      marginRight: theme.spacing(3),
    },
    card: {
      marginBottom: theme.spacing(3),
      boxShadow: 'none !important',
    },
    categoryTree: {
      maxWidth: 400,
    },
    actions: {
      marginTop: theme.spacing(2),
    },
    img: {
      maxHeight: 55,
    },
    autoLoc: {
      maxWidth: 400,
    },
    cell: {
      fontWeight: 'bold',
    },
  })
);

export default ProductsTableListComponent;
