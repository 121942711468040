import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { createParticipation } from '../../../../crud/orders.crud';
import { getResponseMessage } from '../../../../utils/utils';
import { useAddMainProposal } from './useAddMainProposal';

export const useCreateParticipation: any = (successFunc: () => void) => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState<any>(null);
  const [success, setSuccess] = useState(false);
  const { addMain, successMain } = useAddMainProposal();
  const fetch = useCallback(
    async (id: number, data: any, company_id?: number, isMain?: boolean) => {
      setLoading(true);
      createParticipation(id, data, company_id)
        .then((res: any) => {
          setSuccess(true);
          enqueueSnackbar(`${intl.formatMessage({ id: 'PARTICIPATION.SUCCESS.POST' })}`, {
            variant: 'success',
          });
          !isMain ? successFunc() : addMain(res.data.data.id);
        })
        .catch(e => {
          enqueueSnackbar(`${intl.formatMessage({ id: 'ERROR' })}: ${getResponseMessage(e)}`, {
            variant: 'error',
          });
        })
        .finally(() => {
          if (!isMain) {
            setLoading(false);
            setErr(null);
            setSuccess(false);
          }
        });
    },
    [successFunc]
  );

  useEffect(() => {
    if (successMain) {
      successFunc();
      setLoading(false);
      setErr(null);
      setSuccess(false);
    }
  }, [successMain]);

  return [fetch, loading, success, err];
};
