import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { getChats } from '../../../../../crud/chat.crud';
import { getCompanies } from '../../../../../crud/companies.crud';
import { TChannel } from '../../../../../interfaces/chat';
import { ICompany } from '../../../../../interfaces/companies';
import { getResponseMessage } from '../../../../../utils/utils';
import { actions as chatActions } from '../../../../../store/ducks/chat.duck';
import ChatSocket from '../../../../../utils/socket';

export const useGetChats: any = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [chats, setChats] = useState<TChannel[] | null>(null);

  const fetch = useCallback(
    async (
      page: number,
      perPage: number,
      archived?: boolean,
      user_role?: string,
      user_name?: string
    ) => {
      if (!ChatSocket.myAuthToken) await ChatSocket.getMyAuthToken();
      setLoading(true);
      setChats([]);
      dispatch(chatActions.setChats([]));
      getChats(page, perPage, archived, user_role, user_name)
        .then(async (res: any) => {
          const chats: TChannel[] = res.data.data;
          dispatch(
            chatActions.setPagination({
              page: res.data.page,
              perPage: res.data.per_page,
              total: res.data.total,
            })
          );
          getCompanies(1, 300)
            .then((res: any) => {
              const companies: ICompany[] = res.data.data;
              dispatch(chatActions.setChats(chats));
              dispatch(chatActions.setCompanies(companies));
              setChats(chats);
            })
            .catch(e => {
              enqueueSnackbar(
                `${intl.formatMessage({ id: 'ERROR' })}: ${getResponseMessage(e)}`,
                {
                  variant: 'error',
                }
              );
            })
            .finally(() => {
              setLoading(false);
            });
        })
        .catch(e => {
          enqueueSnackbar(`${intl.formatMessage({ id: 'ERROR' })}: ${getResponseMessage(e)}`, {
            variant: 'error',
          });
        });
    },
    []
  );
  return [fetch, loading, chats];
};
