import React from 'react';
import { TablePagination } from '@mui/material';
import { useIntl } from 'react-intl';
import TablePaginationActions from './TablePaginationActions';

export interface IProps {
  id?: number;
  parentId?: string;
  label?: string;
  page: number;
  realPerPage?: number;
  perPage: number;
  total: number;
  fetchRows?: any;
  statuses?: string;
  companyId?: string;
  by_user?: boolean;
  rowsPerPageOptions?: number[];
}

const TablePaginator: React.FC<IProps> = ({
  id,
  parentId,
  label,
  page,
  realPerPage,
  perPage,
  total,
  fetchRows,
  statuses,
  companyId,
  by_user,
  rowsPerPageOptions = [10, 20, 50],
}) => {
  const intl = useIntl();

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (fetchRows) {
      if (id) {
        fetchRows({ page: newPage + 1, perPage, id });
      } else if (parentId) {
        fetchRows({ page: newPage + 1, perPage, parentId: +parentId });
      } else if (statuses) {
        fetchRows({ page: newPage + 1, perPage, statuses });
      } else if (companyId) {
        fetchRows({ page: newPage + 1, perPage, companyId });
      } else {
        fetchRows({ page: newPage + 1, perPage, by_user });
      }
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (fetchRows) {
      if (id) {
        fetchRows({ page: 1, perPage: parseInt(event.target.value, 10), id });
      } else if (parentId) {
        fetchRows({ page: 1, perPage: parseInt(event.target.value, 10), parentId: +parentId });
      } else if (statuses) {
        fetchRows({ page: 1, perPage: parseInt(event.target.value, 10), statuses });
      } else if (companyId) {
        fetchRows({ page: 1, perPage: parseInt(event.target.value, 10), companyId });
      } else {
        fetchRows({ page: 1, perPage: parseInt(event.target.value, 10), by_user });
      }
    }
  };

  return (
    <TablePagination
      onChange={() => {}}
      rowsPerPageOptions={rowsPerPageOptions}
      count={total}
      rowsPerPage={perPage}
      page={page - 1}
      labelRowsPerPage={
        <div 
          style={{
          display:'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          marginTop: 10
          }}>
            {label}
        </div>
      }
      slotProps={{
        select: {
          inputProps: { 'aria-label': 'first page' },
          native: true
        },
      }}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      ActionsComponent={TablePaginationActions}
      labelDisplayedRows={({ from, to, count }) => (
        <div style={{
          display:'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          marginTop: 11
        }}>
          {`${from}${realPerPage === 1 ? '' : '-'}${
            realPerPage === 1 ? '' : to
          } ${intl.formatMessage({
            id: 'TABLE.FROM.LABEL',
          })} ${count}`}
        </div>
      )}
    />
  );
};

export default TablePaginator;
