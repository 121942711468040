import React, { useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Card,
  CardContent,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

import { useIntl } from 'react-intl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import enLocale from 'date-fns/locale/en-US';
import ruLocale from 'date-fns/locale/ru';
import moment from 'moment';
import { SHeadTableCell } from '../../../../components/styledComponents/Tables';
import Preloader from '../../../../components/other/Preloader/Preloader';
import { IAppState } from '../../../../store/rootDuck';
import { actions as statisticsActions } from '../../../../store/ducks/statistics.duck';
import homeStyles from '../../../../constants/homeStyles';

import { actions as profileActions } from '../../../../store/ducks/profile.duck';
import { useStyles } from '../hooks/useStyles';
import { UserRoles } from '../../../../interfaces/user';
import { setLayoutFooter, setLayoutSubheader } from '../../../../utils/layout';
import { useFormatMessage } from '../../../../hooks';

const StatisticsOrdersList: React.FC<
  TPropsFromRedux & { type?: string; userId?: number; smallSendMsgBtn?: boolean }
> = ({ statistics, loading, fetch, userRole, meLoading, fetchMe, clearMe, me }) => {
  const homeClasses = homeStyles();
  const classes = useStyles();
  const intl = useIntl();
  const fm = useFormatMessage();

  const [startDate, setStartDate] = React.useState<string>(
    moment(new Date()).format('YYYY.MM.DD HH:mm')
  );
  const [endDate, setEndDate] = React.useState<string>(
    moment(new Date()).format('YYYY.MM.DD HH:mm')
  );
  const [changeRange, setChangeRange] = React.useState<boolean>(false);
  const [accordStatus, setAccordStatus] = useState<string | number>(0);

  const isAdmin = useMemo(() => userRole?.[0] === UserRoles.ROLE_ADMIN, [userRole]);

  const locales: { [key: string]: any } = {
    ru: ruLocale,
    en: enLocale,
  };

  setLayoutSubheader({
    title: `${fm('SUBMENU.STATISTIC.ORDERS')}`,
  });

  setLayoutFooter({ show: true });

  const handleChangeRange = (start: string | Date, end: string | Date) => {
    const first = moment(start).format('YYYY-MM-DD HH:mm');
    const second = moment(end).format('YYYY-MM-DD HH:mm');
    setChangeRange(true);
    setStartDate(first);
    setEndDate(second);
  };

  useEffect(() => {
    if (!isAdmin && me?.company?.id) {
      if (changeRange) {
        fetch({
          company_id: me?.company?.id,
          date_from: startDate,
          date_to: endDate,
          accord_status: accordStatus.toString(),
        });
      } else {
        fetch({ company_id: me?.company?.id, accord_status: accordStatus.toString() });
      }
    } else if (isAdmin) {
      fetch({
        date_from: changeRange ? startDate : undefined,
        date_to: changeRange ? endDate : undefined,
        accord_status: accordStatus.toString(),
      });
    } else {
      fetch({});
    }
  }, [fetch, changeRange, startDate, endDate, accordStatus]);

  useEffect(() => {
    if (!me) fetchMe();
    return () => {
      clearMe();
    };
  }, []);

  if (loading || meLoading) return <Preloader />;
  return (
    <>
      <div className={classes.container}>
        <div className={classes.filterCol}>
          <Card className={classes.filter}>
            <div className={classes.filterItem}>
              <Typography className={classes.label}>
                {intl.formatMessage({
                  id: 'STATISTIC.ORDER.FILTER.DATE',
                })}
              </Typography>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={locales[intl.locale]}
              >
                <DatePicker
                  // id='received_at'
                  // variant='inline'
                  className={classes.input}
                  format='dd.MM.yyyy'
                  // margin='normal'
                  value={startDate ? dayjs(startDate) : null}
                  onChange={date => date && handleChangeRange(dayjs(date).toString(), endDate)}
                  // autoOk
                  // InputProps={{
                  //   classes: { notchedOutline: classes.input },
                  // }}
                />
              </LocalizationProvider>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={locales[intl.locale]}
              >
                <DatePicker
                  // id='received_at'
                  // variant='inline'
                  className={classes.input}
                  format='dd.MM.yyyy'
                  // margin='normal'
                  value={endDate ? dayjs(endDate) : null}
                  onChange={date =>
                    date && handleChangeRange(startDate, dayjs(date).toString())
                  }
                  // autoOk
                  // InputProps={{
                  //   classes: { notchedOutline: classes.input },
                  // }}
                />
              </LocalizationProvider>
            </div>
          </Card>
          <Card className={classes.filter}>
            <div className={classes.filterItem}>
              <Typography className={classes.label}>
                {intl.formatMessage({
                  id: 'ORDER.ACCORD.STATUS',
                })}
              </Typography>
              <TextField
                select
                margin='normal'
                value={accordStatus}
                onChange={e => setAccordStatus(e.target.value)}
                name='nds'
                variant='outlined'
              >
                <MenuItem value={0}>{fm('ORDER.FILTER.PLACEHOLDER')}</MenuItem>
                <MenuItem value={-1}>{fm('ACCORD.TYPE.CANCELED')}</MenuItem>
                <MenuItem value={1}>{fm('ORDER.TYPE.NEW')}</MenuItem>
                <MenuItem value={2}>{fm('ACCORD.TYPE.ERROR')}</MenuItem>
                <MenuItem value={3}>{fm('ACCORD.TYPE.INPROD')}</MenuItem>
                <MenuItem value={4}>{fm('ACCORD.TYPE.SHIPPED')}</MenuItem>
              </TextField>
            </div>
          </Card>
        </div>

        <div className={`${classes.ordersCol}`}>
          {loading ? (
            <Preloader />
          ) : (
            <>
              {!statistics || !statistics.stat.length ? (
                <Typography className={classes.empty} component='h5' variant='h5'>
                  {intl.formatMessage({ id: 'STATISTICS.ORDER.LIST.EMPTY' })}
                </Typography>
              ) : (
                <div className={classes.card}>
                  <Card className={classes.card}>
                    <CardContent className={homeClasses.classes.tableContainer}>
                      <Table aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <SHeadTableCell>
                              {intl.formatMessage({ id: 'MENU.COMPANY' })}
                            </SHeadTableCell>
                            <SHeadTableCell>
                              {intl.formatMessage({ id: 'STATISTIC.ORDER.TABLE.WEIGHT' })}
                            </SHeadTableCell>
                            <SHeadTableCell>
                              {intl.formatMessage({
                                id: 'STATISTIC.ORDER.TABLE.QUANTITY.GOODS',
                              })}
                            </SHeadTableCell>
                            <SHeadTableCell>
                              {intl.formatMessage({
                                id: 'STATISTIC.ORDER.TABLE.QUANTITY.ORDERS',
                              })}
                            </SHeadTableCell>
                            <SHeadTableCell>
                              {intl.formatMessage({ id: 'STATISTIC.DELIVERED.SUM' })}
                            </SHeadTableCell>
                            {isAdmin && (
                              <SHeadTableCell>
                                {intl.formatMessage({ id: 'STATISTIC.PRODUCT.ORDER.SUM' })}
                              </SHeadTableCell>
                            )}
                            {isAdmin && (
                              <SHeadTableCell>
                                {intl.formatMessage({ id: 'STATISTIC.COST.SUM' })}
                              </SHeadTableCell>
                            )}
                            <SHeadTableCell>
                              {intl.formatMessage({ id: 'SETTING.PROFILE.BONUSES' })}
                            </SHeadTableCell>
                            <SHeadTableCell>
                              {intl.formatMessage({ id: 'STATISTIC.ORDER.TABLE.COST' })}
                            </SHeadTableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {statistics.stat.map((item, ind) => (
                            <TableRow key={ind}>
                              <TableCell>{item.company.name}</TableCell>
                              <TableCell>{item.orders_weight || 0}</TableCell>
                              <TableCell>{item.products_num || 0}</TableCell>
                              <TableCell>{item.orders_count || 0}</TableCell>
                              <TableCell>{item.delivered_summ || 0}</TableCell>
                              {isAdmin && (
                                <TableCell>{item.order_products_summ || 0}</TableCell>
                              )}
                              {isAdmin && <TableCell>{item.delivery_cost || 0}</TableCell>}
                              <TableCell>{item.paid_bonuses || 0}</TableCell>
                              <TableCell>{item.orders_summ || 0}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        {/* <TablePaginator
                                page={page}
                                realPerPage={orders.length}
                                perPage={perPage}
                                total={total}
                                fetchRows={fetch}
                                label={intl.formatMessage({ id: 'ORDER.TABLE.PERPAGE' })}
                              /> */}
                      </Table>
                    </CardContent>
                  </Card>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

const connector = connect(
  (state: IAppState) => {
    return {
      meLoading: state.profile.loading,
      me: state.profile.me,

      statistics: state.stat.statisticOrders,
      loading: state.stat.loading,
      userRole: state.auth.user?.roles,
    };
  },
  {
    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,

    fetch: statisticsActions.fetchOrdersRequest,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(StatisticsOrdersList);
