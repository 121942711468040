import React, { useCallback, useEffect, useLayoutEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import {
  Card,
  CardContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useIntl } from 'react-intl';
import moment from 'moment';
import TablePaginator from '../../../../components/tableComponents/TablePaginator/TablePaginator';
import { SHeadTableCell } from '../../../../components/styledComponents/Tables';
import Preloader from '../../../../components/other/Preloader/Preloader';
import { IAppState } from '../../../../store/rootDuck';
import { actions as deliveryActions } from '../../../../store/ducks/delivery.duck';
import { actions as profileActions } from '../../../../store/ducks/profile.duck';
import homeStyles from '../../../../constants/homeStyles';
import { ListType, orderTypeList } from '../constatns';
import { ViewMode } from '../DeliveryPage';
import { IUser, UserRoles } from '../../../../interfaces/user';
import { useStyles } from '../hooks/useStyles';
import { IDelivery } from '../../../../interfaces/delivery';

const getStatusString = (type: string, selectedType?: string, me?: IUser): string => {
  switch (type) {
    case ListType.FULL:
      if (
        me?.roles.includes(UserRoles.ROLE_BUYER) ||
        me?.roles.includes(UserRoles.ROLE_BUYER_STAFF)
      ) {
        return `${
          selectedType
            ? `${selectedType}`
            : orderTypeList.filter(item => item !== 'completed').toString()
        }`;
      }
      return `${selectedType ? `${selectedType}` : orderTypeList.toString()}`;
    case ListType.ABANDONED:
      return 'cart';
    default:
      return `[]`;
  }
};

const DeliveryList: React.FC<
  TPropsFromRedux & { type: string; userId?: number; smallSendMsgBtn?: boolean }
> = ({
  delivery,
  loading,
  fetch,
  page,
  perPage,
  total,
  meLoading,
  fetchMe,
  clearMe,
  me,
  type,
}) => {
  const homeClasses = homeStyles();
  const classes = useStyles();
  const intl = useIntl();
  const navigate = useNavigate();
  // const [selectedType, setSelectedType] = useState<string>('');

  const editAction = useCallback(
    (item: IDelivery, mode: string) => {
      navigate(`/delivery/${item.id}/${mode}`);
    },
    [navigate]
  );

  useLayoutEffect(() => {
    fetch({ page, perPage });
  }, []);

  useEffect(() => {
    if (!me) fetchMe();
    return () => {
      clearMe();
    };
  }, []);

  if (loading || meLoading) return <Preloader />;

  return (
    <>
      <div className={classes.container}>
        <div
          className={`${classes.ordersCol} ${type === ListType.ABANDONED ? 'full' : ''} ${
            me?.roles[0] === UserRoles.ROLE_BUYER ||
            me?.roles[0] === UserRoles.ROLE_BUYER_STAFF
              ? 'full'
              : ''
          }`}
        >
          {loading ? (
            <Preloader />
          ) : (
            <>
              {!delivery || !delivery.length ? (
                <Typography className={classes.empty} component='h5' variant='h5'>
                  {intl.formatMessage({ id: 'PRODUCT.DELIVERY.LIST.EMPTY' })}
                </Typography>
              ) : (
                <div className={classes.card}>
                  <Card className={classes.card}>
                    <CardContent className={homeClasses.classes.tableContainer}>
                      <Table aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <SHeadTableCell>
                              {intl.formatMessage({ id: 'ORDER.TABLE.ID' })}
                            </SHeadTableCell>
                            {me?.is_admin && (
                              <SHeadTableCell>
                                {intl.formatMessage({ id: 'PRODUCT.VIEW.COMPANY' })}
                              </SHeadTableCell>
                            )}
                            <SHeadTableCell>
                              {intl.formatMessage({ id: 'ORDER.TABLE.PRODUCT_AMOUNT' })}
                            </SHeadTableCell>

                            <SHeadTableCell>
                              {intl.formatMessage({ id: 'ORDER.TABLE.DATE' })}
                            </SHeadTableCell>

                            <SHeadTableCell>
                              {intl.formatMessage({ id: 'DELIVERY.TABLE.SUM_ORDER' })}
                            </SHeadTableCell>

                            <SHeadTableCell>
                              {intl.formatMessage({ id: 'ORDER.TABLE.ACTIONS' })}
                            </SHeadTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {delivery.map(item => (
                            <TableRow key={item.id}>
                              <TableCell>{item.id}</TableCell>
                              {me?.is_admin && (
                                <TableCell>
                                  {item.company && (
                                    <Link to={`/companies/edit/${item.company?.id}`}>
                                      {item.company?.name || item.company?.brand || ''}
                                    </Link>
                                  )}
                                </TableCell>
                              )}
                              <TableCell>
                                {item?.document_products?.reduce((count, el) => {
                                  // eslint-disable-next-line no-return-assign
                                  return (count += Number(el?.amount) || 0);
                                }, 0)}
                              </TableCell>

                              <TableCell>
                                {item.exported_at
                                  ? moment(item.exported_at).format('DD.MM.YY')
                                  : ''}
                              </TableCell>

                              <TableCell>
                                {item?.document_products?.reduce((count, el) => {
                                  // eslint-disable-next-line no-return-assign
                                  return (count += el?.product?.price * el.amount || 0);
                                }, 0)}
                              </TableCell>

                              <TableCell>
                                <IconButton
                                  onClick={() => {
                                    editAction(item, ViewMode.VIEW);
                                  }}
                                >
                                  <VisibilityIcon color='primary' />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePaginator
                              // statuses={getStatusString(type, selectedType)}
                              statuses={getStatusString(type, '')}
                              page={page}
                              realPerPage={delivery.length}
                              perPage={perPage}
                              total={total}
                              fetchRows={fetch}
                              label={intl.formatMessage({ id: 'ORDER.TABLE.PERPAGE' })}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </CardContent>
                  </Card>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    meLoading: state.profile.loading,
    delivery: state.delivery.delivery,
    loading: state.delivery.loading,
    page: state.delivery.page,
    perPage: state.delivery.per_page,
    total: state.delivery.total,
    me: state.profile.me,
  }),
  {
    fetch: deliveryActions.fetchRequest,
    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(DeliveryList);
