import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { editUserTariff } from '../../../../crud/tariffs.crud';
import { getResponseMessage } from '../../../../utils/utils';

export const useEditUserTariff = (successFunc?: () => void) => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [loadingEdit, setLoading] = useState(false);
  const [successEdit, setSuccess] = useState(false);
  const editUserTariffFetch = useCallback(
    async (id: number, days: number) => {
      setLoading(true);
      editUserTariff(id, days)
        .then(() => {
          enqueueSnackbar(`${intl.formatMessage({ id: 'USER.TARIFF.CHANGED' })}`, {
            variant: 'success',
          });
          successFunc && successFunc();
          setSuccess(true);
        })
        .catch(e => {
          enqueueSnackbar(`${intl.formatMessage({ id: 'ERROR' })}: ${getResponseMessage(e)}`, {
            variant: 'error',
          });
        })
        .finally(() => {
          setLoading(false);
          setSuccess(false);
        });
    },
    [successFunc]
  );

  return { editUserTariffFetch, successEdit, loadingEdit };
};
