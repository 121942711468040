export default {
  toolbar: {
    items: [
      'undo',
      'redo',
      '|',
      'heading',
      '|',
      'fontSize',
      'fontColor',
      'bold',
      'italic',
      'underline',
      'link',
      'bulletedList',
      'numberedList',
      // 'outdent',
      // 'indent',
      'alignment',
      '|',
      // 'code',
      'insertTable',
      'imageInsert',
      'htmlEmbed',
    ],
  },
  language: { ui: 'en' },
  image: {
    toolbar: [
      'imageTextAlternative',
      'imageStyle:inline',
      'imageStyle:block',
      'imageStyle:side',
    ],
  },
  table: {
    contentToolbar: [
      'tableColumn',
      'tableRow',
      'mergeTableCells',
      'tableCellProperties',
      'tableProperties',
    ],
  },
};
