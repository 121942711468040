import React from 'react';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import clsx from 'clsx';
import { useStyles } from '../useStyles';
import { ArrowButton } from '../index';

interface IProps {
  onClick?: () => void;
  type: ArrowButton;
}

export const CustomArrow: React.FC<IProps> = ({ type, ...props }) => {
  const classes1 = useStyles();
  const { classes } = classes1;

  return (
    <div
      {...props}
      className={clsx(classes.arrow, {
        [classes.arrowLeft]: type === 'left',
        [classes.arrowRight]: type === 'right',
      })}
    >
      <ArrowRightIcon className={classes.arrowIcon} height={22} />
    </div>
  );
};
