import { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { IProductFilter } from '../../../../interfaces/product';
import { IProductType } from '../../../../interfaces/productType';
import { IAppState } from '../../../../store/rootDuck';

export interface IParams {
  parameter: number;
  values: string[];
  type: string;
  name: string;
  data?: string[];
}

export const useCustomParams: any = () => {
  const [parameters, setParameters] = useState<IParams[]>([]);
  const storeType = useSelector(
    ({ productTypes: { productType } }: IAppState) => productType,
    shallowEqual
  );
  const filter = useSelector(
    ({ productsCatalog: { filter } }: IAppState) => filter,
    shallowEqual
  );

  const generateParams = useCallback(
    (functionType?: IProductType) => {
      const params: IParams[] = [];
      const type = functionType || storeType;
      if (type?.parameters && type?.parameters.length > 0) {
        type.parameters.forEach(item => {
          if (item.type === 'number') {
            params.push({
              parameter: item.id!,
              values: ['', ''],
              type: item.type,
              name: item.name!,
            });
          }
          if (item.type === 'enum') {
            const data: any = [];
            item.parameter_enum_values!.forEach(text =>
              data.push({ label: text.value, value: text.value })
            );
            params.push({
              parameter: item.id!,
              values: [],
              data,
              type: item.type,
              name: item.name!,
            });
          }
        });
      }
      setParameters(params);
    },
    [storeType]
  );

  const changeParamsOfStore = useCallback(
    (filter: IProductFilter) => {
      if ((filter.parameters && filter.parameters.length === 0) || !filter.parameters) {
        clearParams();
      }
      if (filter.parameters && filter.parameters.length > 0 && parameters.length > 0) {
        const newParams: IParams[] = [];
        parameters.forEach(param => {
          const currentParam = filter.parameters!.find(
            (item: any) => item.parameter === param.parameter
          );
          if (currentParam) {
            if (param.type === 'number') {
              newParams.push({
                parameter: param.parameter,
                type: param.type,
                name: param.name,
                values: currentParam.values,
              });
            }
            if (param.type === 'enum') {
              const data: any = [];
              currentParam.values.forEach((text: any) =>
                data.push({ label: text, value: text })
              );
              newParams.push({
                parameter: param.parameter,
                type: param.type,
                name: param.name,
                values: data,
                data: param.data,
              });
            }
          } else {
            if (param.type === 'number') {
              newParams.push({
                parameter: param.parameter,
                values: ['', ''],
                type: param.type,
                name: param.name,
              });
            }
            if (param.type === 'enum') {
              newParams.push({
                parameter: param.parameter,
                values: [],
                data: param.data,
                type: param.type,
                name: param.name,
              });
            }
          }
        });
        setParameters([...newParams]);
      }
    },
    [parameters, setParameters]
  );

  useEffect(() => generateParams(), [storeType]);

  useEffect(() => changeParamsOfStore(filter), [filter]);

  const onChangeParam = useCallback(
    (value: IParams) => {
      const indexParam = parameters.findIndex(i => i.parameter === value.parameter);
      const newParams = parameters;
      newParams[indexParam] = value;
      setParameters([...newParams]);
    },
    [parameters, setParameters]
  );

  const clearParams = useCallback(() => {
    generateParams(storeType);
  }, [storeType]);

  const paramsForFetch = useMemo(() => {
    const newParameters: any = [];
    if (parameters.length > 0) {
      parameters.forEach(item => {
        const isValid = Boolean(item.values.find(i => Boolean(i)));
        if (isValid) {
          if (item.type === 'enum') {
            const arr: any = [];
            item.values.forEach((item: any) => arr.push(item.value));
            newParameters.push({
              parameter: item.parameter,
              values: arr,
            });
          } else {
            newParameters.push({
              parameter: item.parameter,
              values: item.values,
            });
          }
        }
      });
    }
    return newParameters;
  }, [parameters]);

  return [parameters, onChangeParam, clearParams, paramsForFetch];
};
