import { makeStyles } from 'tss-react/mui';

export const useStylesCategoriesList = makeStyles()((theme)=>{
  return {
    actions: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(3),

      '& button': {
        marginBottom: 15,

        [theme.breakpoints.up('md')]: {
          marginBottom: 0,
        },
      },
    },
    img: {
      height: 40,
      objectFit: 'contain',
    },
    lang: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    langImage: {
      width: '21px',
      height: '21px',
      borderRadius: '5px',
      overflow: 'hidden',
    },
    langTitle: {
      marginLeft: '5px',
      // fontSize: 14,
    },
    noBorder: {
      border: 'none',
    },
    icon: {
      width: 22,
      height: 22,
    },
    iconDelete: {
      width: 22,
      height: 22,
    },
    select: {
      padding: 0,
      paddingRight: 0,
    },
    selectLang: {
      width: 150,
    },
    fontNoLang: {
      // fontSize: 18,
      fontWeight: 200,
    },
    cell: {
      // fontSize: 18,
      fontWeight: 500,
    },
    containerBtns: {
      display: 'flex',
      marginTop: 8,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    wrapperFind: {
      marginBottom: 8,
      marginLeft: 16,
      height: 52,
    },
    search: {
      minWidth: 'min-content',
      marginTop: 0,
      marginBottom: 0,
    },
}});

export const useStylesCategoriesTree = makeStyles()((theme)=>{
  return {
    empty: {
      marginBottom: 20,
      marginTop: 20,
    },
    buttons: {
      marginRight: theme.spacing(3),
    },
    card: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    categoryTree: {
      maxWidth: 400,
    },
    actions: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(3),

      '& button': {
        marginBottom: 15,

        [theme.breakpoints.up('md')]: {
          marginBottom: 0,
        },
      },
    },
    topBtn: {
      // marginRight: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        marginBottom: 10,
      },
    },
    lang: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    langImage: {
      width: '21px',
      height: '21px',
      borderRadius: '5px',
      overflow: 'hidden',
    },
    select: {
      padding: 0,
      paddingRight: 0,
    },
    selectLang: {
      width: 150,
    },
    langTitle: {
      marginLeft: '5px',
      //   fontSize: 17,
    },
    fontNoLang: {
      // fontSize: 18,
      fontWeight: 200,
    },
    icon: {
      color: '#d01c1f',
      width: 22,
      height: 22,
    },
    containerBtns: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
}});

export const useStylesCategoryForm = makeStyles()((theme)=>{
  return {
    card: {
      marginTop: theme.spacing(3),
    },
    actions: {
      marginTop: theme.spacing(3),
    },
    buttons: {
      marginRight: theme.spacing(2),
    },
    switch: {
      marginLeft: theme.spacing(1),
    },
    formEdit: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      padding: theme.spacing(3),
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    cardEdit: {
      width: 'calc((100% - 24px) / 2)',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    cardAdd: {
      width: '100%',
    },
    formControl: {
      marginTop: 16,
      width: '100%',
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    categoryLink: {
      display: 'flex',
      width: '100%',
      padding: '24px 24px 0',
    },
}});

export const useStylesCategoryView = makeStyles()((theme)=>{
  return {
    empty: {
      marginBottom: 20,
      marginTop: 20,
    },
    name: {
      fontSize: 20,
      fontWeight: 600,
      margin: 0,

      [theme.breakpoints.up('md')]: {
        fontSize: 34,
        lineHeight: 1,
      },
    },
    listText: {
      marginBottom: 0,
      fontSize: 17,
      fontWeight: 'bold',
      color: '#000000',
      paddingLeft: 10,
    },
    listKey: {
      marginBottom: 0,
      fontSize: 17,
      fontWeight: 400,
      color: '#757575',
    },
    imgContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      height: 'auto',
      maxHeight: 85,
      alignItems: 'start',
      padding: 0,
      margin: 0,
      [theme.breakpoints.down('xs')]: {
        maxWidth: 280,
        height: 200,
      },
      [theme.breakpoints.only('sm')]: {
        maxWidth: 350,
        height: 250,
      },
    },
    imgCom: {
      maxWidth: 90,
      [theme.breakpoints.down('xs')]: {
        maxWidth: 75,
      },
      [theme.breakpoints.only('sm')]: {
        maxWidth: 85,
      },
      cursor: 'pointer',
    },
    buttons: {
      marginRight: theme.spacing(3),
    },
    card: {
      // marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    categoryTree: {
      maxWidth: 400,
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    img: {
      maxHeight: 55,
    },
    icon: {
      width: 22,
      height: 22,
    },
    iconDelete: {
      color: '#0685d1',
      width: 22,
      height: 22,
    },
    cell: {
      fontSize: 18,
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    count: {
      fontStyle: 'bold',
      fontWeight: 600,
      color: '#000',
      marginRight: 15,
      fontSize: 18,
    },
    filterCol: {
      width: 326,
    },
    productsCol: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 'calc(100% - 326px - 15px)',
      },
    },
    revers: {
      display: 'flex',
      flexDirection: 'column-reverse',
      justifyContent: 'flex-end',
    },
    productColHeader: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 15,
    },
    productColHeaderItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    title: {
      fontStyle: 'bold',
      fontWeight: 800,
      color: '#d01c1f',
      marginRight: 15,
      fontSize: 18,
    },
    category: {
      background: 'white',
      padding: 20,
      borderRadius: 4,
      marginBottom: 25,
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
    },
    containerImg: {
      height: 'auto',
      maxHeight: 85,
      alignItems: 'start',
      padding: 0,
      margin: 0,
    },
    containterCategory: {
      paddingLeft: 20,
    },
}});

