import React from 'react';
import HMenuItem from './HMenuItem';

type TProps = {
  item: any;
  currentUrl: string;
};

const HMenuColumn: React.FC<TProps> = ({ item, currentUrl }) => {
  return (
    <li className='kt-menu__item'>
      {item.heading && (
        <h3 className='kt-menu__heading kt-menu__toggle'>
          <span className='kt-menu__link-text'>{item.heading.title}</span>
          <i className='kt-menu__ver-arrow la la-angle-right' />
        </h3>
      )}
      {item.items && item.items.length && (
        <ul className='kt-menu__inner'>
          {item.items.map((child: any, index: number) => {
            return (
              <React.Fragment key={`hmenuColumnInnerColumn${index}`}>
                <HMenuItem item={child} currentUrl={currentUrl} rootArrowEnabled={false} />
              </React.Fragment>
            );
          })}
        </ul>
      )}
    </li>
  );
};

export default HMenuColumn;
