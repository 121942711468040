export const KEY_LANG = 'langCategories';

export const setLangList = (lang: string) => {
  localStorage.setItem(KEY_LANG, lang);
};

export const getLangList = () => {
  const lang = localStorage.getItem(KEY_LANG);
  if (lang) return lang;
  return null;
};
