import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { Button, MenuItem, TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import { Col, Row } from 'react-bootstrap';

import Preloader from '../../../components/other/Preloader/Preloader';
import AlertDialog from '../../../components/other/Dialog/AlertDialog';
import CategoriesTree from './components/CategoriesTree';
import { actions as categoriesActions } from '../../../store/ducks/categories.duck';
import { IAppState } from '../../../store/rootDuck';
import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';
import { actions as profileActions } from '../../../store/ducks/profile.duck';
import useCrudSnackbar from '../../../hooks/useCrudSnackbar';
import { useDefineUserRole } from '../../../hooks';
import { getLangList, setLangList } from './utils/langList';
import { categoryLanguages } from './utils/categoryLanguages';
import { useStylesCategoriesTree } from './hooks/useStyles';
import { ICategoryItem } from './interfaces';

const CategoriesListPage: React.FC<TPropsFromRedux> = ({
  fetch,
  clearFetch,
  categories,
  categoriesLoading,

  deleteCategory,
  clearDelete,
  deleteLoading,
  deleteSuccess,
  deleteError,

  fetchMe,
  clearMe,
  me,
  loadingMe,
}) => {
  const intl = useIntl();
  const classes1 = useStylesCategoriesTree();
  const {classes} = classes1
  const navigate = useNavigate();
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | undefined>(undefined);
  const [deleteId, setDeleteId] = useState<number | undefined>(-1);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [lang, setLang] = useState('ru');
  const isManager = useDefineUserRole(me, 'ROLE_MANAGER');

  const handleDeleteDialog = useCallback((id: number | undefined) => {
    setDeleteId(id);
    setAlertOpen(true);
  }, []);

  const deleteAction = useCallback(() => {
    setAlertOpen(false);
    deleteCategory({ id: deleteId });
  }, [deleteId, deleteCategory]);

  const editAction = useCallback(
    (item: ICategoryItem) => {
      navigate(`/categories/edit/${item.id}/${item.parent_id || ''}`);
    },
    [navigate]
  );

  const productListAction = useCallback(
    (item: ICategoryItem) => {
      navigate(`/products/list/${item.id}`);
    },
    [navigate]
  );

  const pushAction = useCallback(
    (parentId?: string) => {
      navigate(`${parentId ? `/categories/new/${parentId}` : '/categories/new'}`);
    },
    [navigate]
  );

  const productAction = useCallback(() => {
    navigate(
      selectedCategoryId ? `/products/create/${selectedCategoryId}` : `/products/create`
    );
  }, [navigate, selectedCategoryId]);

  const handleChangeLang = useCallback((value: string) => {
    setLang(value);
    setLangList(value);
  }, []);

  const viewAction = useCallback(
    (item: ICategoryItem) => {
      navigate(`/categories/view/${item.id}`);
    },
    [navigate]
  );

  useEffect(() => {
    fetchMe();
    const lang = getLangList();
    setLang(intl.locale || lang || 'ru');
    return () => {
      clearMe();
      clearDelete();
      clearFetch();
    };
  }, []);

  useEffect(() => {
    fetch();
  }, [fetch]);

  useCrudSnackbar({
    success: deleteSuccess,
    error: deleteError,
    clear: clearDelete,
    successMessage: intl.formatMessage({ id: 'CATEGORY.SNACKBAR.DELETE' }),
    errorMessage: `${intl.formatMessage({ id: 'CATEGORIES.DELETE.ERROR' })}`,
    afterSuccess: () => {
      fetch();
    },
    afterSuccessOrError: () => {
      clearDelete();
    },
  });

  setLayoutSubheader({
    title: intl.formatMessage({ id: 'CATEGORIES.TITLE.TREE' }),
  });
  setLayoutFooter({ show: true });

  if (categoriesLoading || loadingMe || deleteLoading || !categories) return <Preloader />;

  return (
    <>
      <Row className={classes.actions}>
        <div className={classes.containerBtns}>
          {me?.is_admin && (
            <Col className={classes.topBtn} md='auto'>
              <Button color='primary' variant='contained' onClick={() => pushAction()}>
                {intl.formatMessage({ id: 'CATEGORIES.BUTTON.ADD' })}
              </Button>
            </Col>
          )}

          <Col md='auto'>
            <Button color='primary' variant='contained' onClick={productAction}>
              {intl.formatMessage({ id: 'CATEGORY.BUTTON.ADD.PRODUCT' })}
            </Button>
          </Col>
        </div>

        <TextField
          select
          value={lang || 'ru'}
          onChange={e => handleChangeLang(e.target.value)}
          name='locale'
          variant='standard'
          className={classes.selectLang}
          InputProps={{
            disableUnderline: true,
          }}
          SelectProps={{
            classes: {
              icon: classes.icon,
              select: classes.select,
            },
          }}
        >
          {categoryLanguages.map(lang => (
            <MenuItem key={lang.code} value={lang.code}>
              <div className={classes.lang}>
                <span className={classes.langImage}>
                  <img src={lang.flag} alt={lang.name} />
                </span>
                <span className={classes.langTitle}>{lang.name}</span>
              </div>
            </MenuItem>
          ))}
        </TextField>
      </Row>

      <CategoriesTree
        categories={categories || []}
        handleDeleteDialog={handleDeleteDialog}
        editAction={editAction}
        pushAction={pushAction}
        classes={classes}
        productListAction={productListAction}
        selectProductId={(id: number) => {
          setSelectedCategoryId(id);
        }}
        is_admin={me?.is_admin || false}
        is_manager={isManager || false}
        lang={lang}
        viewAction={viewAction}
      />

      <AlertDialog
        open={isAlertOpen}
        message={intl.formatMessage({ id: 'CATEGORIES.DELETE.TEXT' })}
        okText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })}
        handleClose={() => {
          setAlertOpen(false);
        }}
        handleAgree={() => deleteAction()}
      />
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    categories: state.categories.categories,
    categoriesLoading: state.categories.categoriesLoading,

    deleteLoading: state.categories.deleteLoading,
    deleteSuccess: state.categories.deleteSuccess,
    deleteError: state.categories.deleteError,

    me: state.profile.me,
    loadingMe: state.profile.loading,
  }),
  {
    fetch: categoriesActions.fetchFullRequest,
    clearFetch: categoriesActions.clearFullRequest,

    deleteCategory: categoriesActions.deleteRequest,
    clearDelete: categoriesActions.clearDelete,

    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CategoriesListPage);
