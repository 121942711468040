/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useRef } from 'react';
import { FormHelperText, InputLabel } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Autocomplete from 'react-google-autocomplete';
import { useIntl } from 'react-intl';
import { useFormatMessage } from '../../../hooks';

const useStyles = makeStyles()(() => {
  return {
    root: {
      marginTop: 20,
      marginBottom: 8,
    },
    input: {
      width: '100%',
      padding: '17.5px 14px',
      borderWidth: 0,
      borderRadius: 4,
      outline: 'none',
      boxSizing: 'border-box',
    },
  };
});

interface IProps {
  value: { address: string };
  error?: boolean;
  helperText?: string;
  setSelectedLocation: (data: { address: string; lat: number; lng: number }) => void;
  disabled?: boolean;
}

const AutocompleteCopy: React.FC<IProps> = ({
  value,
  setSelectedLocation,
  error,
  helperText,
  disabled,
}) => {
  const intl = useIntl();
  const classes1 = useStyles();
  const { classes } = classes1;

  const fm = useFormatMessage();

  const outlineBorder: { [key: string]: string } = {
    default: '#c4c4c4',
    error: '#fd397a',
    focus: '#007bff',
  };

  const [type, setType] = useState<string>('default');
  const [borderWidth, setBorderWidth] = useState(1);

  useEffect(() => {
    setType(error ? 'error' : 'default');
  }, [error]);

  const [inputFocused, setInputFocused] = useState(false);
  const [currentValue, setCurrentValue] = useState('');

  const inputRef = useRef<any>();

  return (
    <div className={classes.root}>
      {disabled ? (
        <div
          className={classes.input}
          style={{
            boxShadow: `inset 0 0 0 1px #c4c4c4`,
            color: 'rgba(0, 0, 0, 0.54)',
          }}
        >
          {value.address ||
            intl.formatMessage({
              id: 'STORE.INPUT.LOCATION',
            })}
        </div>
      ) : (
        <>
          <InputLabel
            shrink={inputFocused || currentValue.length > 0 || !!value.address}
            variant='outlined'
            style={{
              display: 'inline-block',
              paddingLeft: 7,
              paddingRight: 7,
              position: 'absolute',
              background:
                inputFocused || currentValue.length > 0 || !!value.address
                  ? 'white'
                  : 'transparent',
              color: error
                ? outlineBorder.error
                : inputFocused
                ? 'rgb(0, 123, 225)'
                : 'rgba(0, 0, 0, 0.54)',
              marginLeft: -5,
            }}
          >
            {fm('STORE.INPUT.LOCATION')}
          </InputLabel>
          <Autocomplete
            ref={inputRef}
            className={classes.input}
            style={{
              // @ts-ignore
              '-webkit-appearance': 'none',
              '-webkit-box-shadow': `inset 0 0 0 ${borderWidth}px ${outlineBorder[type]}`,
              boxShadow: `inset 0 0 0 ${borderWidth}px ${outlineBorder[type]}`,
            }}
            placeholder=''
            onFocus={() => {
              setInputFocused(true);
              setBorderWidth(2);
              setType(error ? 'error' : 'focus');
            }}
            onBlur={e => {
              setInputFocused(false);
              setBorderWidth(1);
              setType(error ? 'error' : 'default');
              setCurrentValue(e.currentTarget.value);
            }}
            options={{
              types: ['geocode', 'establishment'],
              language: intl.locale === 'ru' ? 'ru' : 'en-AU',
            }}
            language={intl.locale === 'ru' ? 'ru' : 'en-AU'}
            apiKey='AIzaSyB_rjiR94heML-tlGsexMmxwtpVQ7WlcxI'
            onPlaceSelected={place => {
              setSelectedLocation({
                address: place.formatted_address,
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
              });
            }}
            onChange={e => {
              setCurrentValue(e.currentTarget.value);
              if (!e.currentTarget.value) {
                setSelectedLocation({
                  address: '',
                  lat: 0,
                  lng: 0,
                });
              }
            }}
            defaultValue={value.address || ''}
          />

          {!!helperText && (
            <FormHelperText style={{ margin: '3px 14px 0px' }} error={error}>
              {helperText}
            </FormHelperText>
          )}
        </>
      )}
    </div>
  );
};

export default AutocompleteCopy;
