import React, { useEffect, useState } from 'react';
import { TextField, Button, MenuItem } from '@mui/material';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';

import ButtonWithLoader from '../../../components/other/Buttons/ButtonWithLoader';
import Preloader from '../../../components/other/Preloader/Preloader';
import { IAppState } from '../../../store/rootDuck';
import { actions as profileActions } from '../../../store/ducks/profile.duck';
import { actions as productForDaysActions } from '../../../store/ducks/product-for-days.duck';
import { useStylesSettings } from './hooks/useStyles';


const ProductSetting: React.FC<TPropsFromRedux> = ({
  loadingMe,
  fetchMe,
  loading,
  setForDays,
  pageForDays,
  perPageForDays,
  setCurrency,
  currency
}) => {
  const classes = useStylesSettings();
  const intl = useIntl();
  const [forDaysValue, setForDaysValue] = useState<null | number>();

  const formik1 = useFormik({
    initialValues: { currency: currency || 'RUB' },
    onSubmit: values => {
      setCurrency({
        currency: values.currency.toString()
      });
    },
  });

  const formik2 = useFormik({
    initialValues: { forDays: forDaysValue },
    enableReinitialize: true,
    onSubmit: values => {
      if (values.forDays) {
        setForDays({ page: pageForDays, perPage: perPageForDays, forDays: +values.forDays });
      }
    },
  });

  useEffect(() => {
    fetchMe();
  }, []);

  if (loading || loadingMe ) return <Preloader />;

  return (
    <>
      <div>
        <form onSubmit={formik1.handleSubmit} autoComplete='off' className={classes.textInput}>
        <TextField
          select
          type='text'
          label='Выберите валюту'
          margin='normal'
          name='currency'
          value={formik1.values.currency}
          variant='outlined'
          onBlur={formik1.handleBlur}
          onChange={formik1.handleChange}
          helperText={formik1.touched.currency && formik1.errors.currency}
          error={Boolean(formik1.touched.currency && formik1.errors.currency)}
        >
          <MenuItem value="RUB">RUB</MenuItem>
          <MenuItem value="USD">USD</MenuItem>
          <MenuItem value="EUR">EUR</MenuItem>
          <MenuItem value="UAH">UAH</MenuItem>
        </TextField>

          <div className={classes.actions}>
            <ButtonWithLoader>
              {intl.formatMessage({ id: 'USER.BUTTON.ADD' })}
            </ButtonWithLoader>
          </div>
        </form>

        <form onSubmit={formik2.handleSubmit} autoComplete='off' className={classes.textInput}>
          <TextField
            type='number'
            label={intl.formatMessage({ id: 'PRODUCT.FOR.DAYS' })}
            margin='normal'
            name='forDays'
            value={formik2.values.forDays}
            variant='outlined'
            style={{ marginRight: 30 }}
            onBlur={formik2.handleBlur}
            onChange={formik2.handleChange}
            helperText={formik2.touched.forDays && formik2.errors.forDays}
            error={Boolean(formik2.touched.forDays && formik2.errors.forDays)}
          />
          
          <div className={classes.actions}>
            <Button color='primary' type='submit' variant='contained' style={{ height: '100%' }}>
              {intl.formatMessage({ id: 'COMPANY.TABLE.BUTTON.EDIT' })}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    loadingMe: state.profile.loading,
    me: state.profile.me,
    editError: state.promocodes.editError,
    loading: state.companies.byIdLoading,
    editLoading: state.companies.editLoading,

    pageForDays: state.productForDays.page,
    perPageForDays: state.productForDays.per_page,
    productsForDays: state.productForDays.productsForDays,
    forDays: state.productForDays.forDays,
    currency: state.productForDays.currensy,
  }),
  {
    fetchMe: profileActions.fetchRequest,

    setForDays: productForDaysActions.setForDays,
    setCurrency: productForDaysActions.addCurrencyAction,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ProductSetting);
