import { IntlShape } from 'react-intl';
import { IBreadcrumb } from '../../../../interfaces/breadcrumb';
import { ICategoryItem } from '../interfaces';

export const categoriesBreadCrumbs = (
  parent: ICategoryItem | null | undefined,
  intl: IntlShape
): IBreadcrumb[] => {
  const breadCrumbs: IBreadcrumb[] = [];
  if (parent) {
    addParentBreadCrumbs(parent, breadCrumbs);
  }
  breadCrumbs.push({
    title: intl.formatMessage({ id: 'MENU.CATALOG.CATEGORIES.LIST' }),
    page: 'categories/list',
    root: true,
  });
  const reverseCrumbs = breadCrumbs.reverse();

  return reverseCrumbs;
};

const addParentBreadCrumbs = (
  parent: ICategoryItem | null | undefined,
  breadCrumbs: IBreadcrumb[]
) => {
  if (parent) {
    breadCrumbs.push({
      title: parent.name,
      page: `categories/list/${parent.id}`,
    });
    addParentBreadCrumbs(parent.parent, breadCrumbs);
  }
};
