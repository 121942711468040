import React from 'react';
import { useIntl } from 'react-intl';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import LinkIcon from '@mui/icons-material/Link';
import { Link } from 'react-router-dom';

import { SHeadTableCell } from '../../styledComponents/Tables';
import TablePaginator from '../TablePaginator/TablePaginator';
import { API_DOMAIN } from '../../../constants';
import { toAbsoluteUrl } from '../../../../_base';
import { IProduct } from '../../../interfaces/product';
import { IEditPublication } from '../../../pages/home/blog/interfaces';
import { useFormatMessage } from '../../../hooks';

interface IProps {
  products: IProduct[];
  loading?: boolean;
  onEdit?: (id: number) => any;
  onConnectOff?: (id: number) => any;
  onConnect?: (product: IProduct) => any;
  fetch?: (data: { page: number; perPage: number }) => void;
  productId?: string;
  values: IEditPublication;
  page: number;
  perPage: number;
  total: number;
}

const useStyles = makeStyles()(() => {
  return {
    empty: {
      height: 200,
    },
    table: {
      marginTop: 20,

      // [theme.breakpoints.up('xl')]: {
      //   minWidth: 500,
      // },
      // // @ts-ignore
      // [theme.breakpoints.up('xxl')]: {
      //   width: 500,
      // },
    },
    emptyOverlay: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    img: {
      height: 55,
      width: 70,
      objectFit: 'cover',
    },
  };
});

const PublicationTable: React.FC<IProps> = ({
  products,
  onConnect,
  fetch,
  values,
  page,
  perPage,
  total,
}) => {
  const intl = useIntl();
  const fm = useFormatMessage();
  const classes1 = useStyles();
  const { classes } = classes1;

  return (
    <Table aria-label='stores' className={classes.table}>
      <TableHead>
        <TableRow>
          <SHeadTableCell>{intl.formatMessage({ id: 'COMPANY.TABLE.ID' })}</SHeadTableCell>
          <SHeadTableCell>Изображение</SHeadTableCell>
          <SHeadTableCell>{intl.formatMessage({ id: 'COMPANY.TABLE.NAME' })}</SHeadTableCell>
          <SHeadTableCell>Цена</SHeadTableCell>
          <SHeadTableCell>Кампания</SHeadTableCell>
          <SHeadTableCell>
            {intl.formatMessage({ id: 'COMPANY.TABLE.ACTIONS' })}
          </SHeadTableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {products.map(item => (
          <TableRow key={item.id}>
            <TableCell component='th' scope='row'>
              {item.id}
            </TableCell>
            <TableCell>
              <img
                src={
                  // @ts-ignore
                  item.photos[0]?.small
                    ? // @ts-ignore
                      `${API_DOMAIN}/${item.photos[0].small}`
                    : toAbsoluteUrl('/images/placeholder.png')
                }
                className={classes.img}
                alt={item.name}
              />
            </TableCell>
            <TableCell>
              <Link to={`/viewproduct/${item.id}`}>{item?.name}</Link>
            </TableCell>
            <TableCell style={{ width: 200 }}>{item?.price} ₽</TableCell>
            <TableCell>{item?.company?.name || '-'}</TableCell>
            <TableCell>
              {/* <IconButton
                color='primary'
                aria-label='previous'
                // onClick={() => onEdit(item?.id)}
              >
                <EditIcon />
              </IconButton> */}

              {/* {item.favorite && (
                <IconButton 
                onClick={() => onConnectOff(item?.id)}
                >
                  <LinkOffIcon color='secondary' />
                </IconButton>
              )}  */}

              {/* {(!item.favorite) && ( */}
              <IconButton
                onClick={
                  //  item.favorite
                  //   ? () => onConnectOff(item.id)
                  // @ts-ignore
                  () => onConnect(item)
                }
              >
                {values.product_id === item.id ? (
                  <LinkOffIcon color='secondary' />
                ) : (
                  <LinkIcon color='primary' />
                )}
              </IconButton>
              {/* )} */}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>

      <TableFooter>
        <TableRow>
          <TablePaginator
            page={page}
            perPage={perPage}
            total={total}
            realPerPage={products.length}
            fetchRows={fetch}
            label={fm('PRODUCTS.PAGE')}
          />
        </TableRow>
      </TableFooter>
    </Table>
  );
};

export default PublicationTable;
