import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { changeMyFavorites } from '../../../../crud/product.crud';
import { getResponseMessage } from '../../../../utils/utils';
import { useFormatMessage } from '../../../../hooks';
import { actions as productActions } from '../../../../store/ducks/product.duck';

export const useChangeFavorite: any = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const fm = useFormatMessage();
  const dispatch = useDispatch();

  const fetchFavorites = useCallback(async (product_id: number, deleteFav: boolean) => {
    setLoading(true);
    changeMyFavorites(product_id, deleteFav)
      .then(() => {
        enqueueSnackbar(
          fm(deleteFav ? 'PRODUCT.DELETE.FAVORITE.SUCCESS' : 'PRODUCT.ADD.FAVORITE.SUCCESS'),
          { variant: 'success' }
        );
        dispatch(productActions.editFavorites({ productId: product_id, deleteFav }));
        setSuccess(true);
      })
      .catch(e => {
        enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
        setSuccess(false);
      });
  }, []);

  return [fetchFavorites, loading, success];
};
