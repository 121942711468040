import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useCallback } from 'react';
import { useFormatMessage } from '../../../../../hooks';
import { ICreateBanner, Placement } from '../../interfaces';

const getValidationScheme = () => {
  const fm = useFormatMessage();
  return Yup.object().shape({
    title: Yup.string().required(fm('AUTH.VALIDATION.REQUIRED_FIELD')),
    url: Yup.string()
      .ensure()
      .when('product_id', {
        is: undefined,
        then: Yup.string().required(fm('BANNER.VALIDATION.URL_OR_PRODUCT_REQUIRED')),
      }),
  });
};

const getInitValue = () => {
  return {
    title: '',
    description: '',
    url: '',
    placement: Placement.TOP,
  };
};

export const useCustomFormik = ({ submit, image }: { submit: any; image: Blob | null }) => {
  const submitHandler = useCallback(
    (values: ICreateBanner) => {
      const data = new FormData();

      data.append('title', values.title);
      data.append('description', values.description);
      data.append('placement', values.placement);

      if (values.product_id) {
        data.append('product_id', values.product_id.toString());
      }

      if (values.url) {
        data.append('url', values.url);
      }

      if (image) {
        data.append('photo', image);
      }

      submit({ data });
    },
    [submit, image]
  );

  return useFormik({
    enableReinitialize: true,
    initialValues: getInitValue(),
    validationSchema: getValidationScheme(),
    onSubmit: submitHandler,
  });
};
