import React from 'react';
import { Card, Grid, IconButton } from '@mui/material';
import { Star } from '@mui/icons-material';

import { useStyles } from '../../UploadImages/hooks/useStyles';

interface IProps {
  images: File[];
}

export const MainImgOffline: React.FC<IProps> = ({ images }) => {
  const classes = useStyles();

  if (images.length === 0) return <></>;

  return (
    <Card style={{ position: 'relative', marginBottom: images.length === 1 ? 15 : 0 }}>
      <img
        src={URL.createObjectURL(images[0])}
        alt=''
        style={{ maxWidth: 550, maxHeight: 500 }}
      />
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        className={classes.galleryImgTitleBar}
      >
        <IconButton style={{ color: '#ffc107' }} disabled>
          <Star />
        </IconButton>
      </Grid>
    </Card>
  );
};
