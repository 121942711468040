import React, { ReactElement, useState, useEffect, useCallback, useMemo } from 'react';
import { connect, ConnectedProps, shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { Button } from '@mui/material';
import AlertDialog from '../../../components/ui/Dialog/AlertDialog';
import Preloader from '../../../components/ui/Preloader/Preloader';
import { PublicationPost } from '../../../components/ui/Blog';
import useCrudSnackbar from '../../../hooks/useCrudSnackbar';
import { IAppState } from '../../../store/rootDuck';
import { actions as blogActions } from '../../../store/ducks/blog.duck';
import { actions as profileActions } from '../../../store/ducks/profile.duck';
import { useDefineUserRole } from '../../../hooks/useDefineUserRole';
import { PublicationsList } from './components';
import { IPublication } from './interfaces';
import { ButtonWithLoader } from '../../../components/other/Buttons';
import { useFormatMessage } from '../../../hooks';

const ViewPublication: React.FC<TPropsFromRedux> = ({
  me,

  publication,
  publicationLoading,

  deleteSuccess,
  deleteError,

  clearMe,
  fetchMe,

  clearFetchPublication,
  fetchPublication,

  clearDeletePublication,
  deletePublication,
}): ReactElement => {
  const intl = useIntl();
  const fm = useFormatMessage();
  const navigate = useNavigate();
  const { id } = useParams();

  const [deleteId, setDeleteId] = useState<number | undefined>(-1);
  const [isAlertOpen, setAlertOpen] = useState(false);

  const companyId = me?.company?.id || undefined;
  const isAdmin = useDefineUserRole(me, 'ROLE_ADMIN');
  const store = useSelector((store: IAppState) => store, shallowEqual);
  const dispatch = useDispatch();
  const [favoritePublicatoins, setFavoritePublications] = useState<IPublication[] | null>(
    null
  );
  useEffect(() => {
    setFavoritePublications(store.blog.publications);
  }, [store.blog.publications]);

  useEffect(() => {
    dispatch(profileActions.fetchRequest());
    dispatch(
      blogActions.publicationsRequest({
        page: 1,
        perPage: 20,
        status: 'approved',
        favorite: true,
      })
    );
    return () => {
      dispatch(blogActions.clearFetchPublications());
    };
  }, []);

  const handleDeleteDialog = useCallback((id: number | undefined) => {
    setDeleteId(id);
    setAlertOpen(true);
  }, []);

  useCrudSnackbar({
    success: deleteSuccess,
    error: deleteError,
    clear: clearDeletePublication,
    successMessage: `${intl.formatMessage({ id: 'SUCCESS' })}`,
    errorMessage: `${intl.formatMessage({ id: 'PUBLICATIONS.DELETE.ERROR' })}: ${deleteError}`,
    afterSuccess: () => navigate('/blog/all'),
    afterSuccessOrError: () => {
      setAlertOpen(false);
    },
  });

  useEffect(() => {
    fetchMe();
    return () => {
      clearMe();
    };
  }, []);

  useEffect(() => {
    if (id) fetchPublication({ id: +id });
    return () => {
      clearFetchPublication();
    };
  }, [id]);

  const isAuthor = useMemo(() => {
    if (companyId && publication?.company?.id) {
      return companyId === publication.company.id;
    }
    if (me && publication?.user?.id) {
      return me.id === publication.user.id;
    }
    return false;
  }, [publication, companyId, me]);

  if (!publication || publicationLoading) return <Preloader />;

  return (
    <>
      <AlertDialog
        open={isAlertOpen}
        message={intl.formatMessage({ id: 'PUBLICATIONS.DELETE.TEXT' })}
        okText={intl.formatMessage({ id: 'PUBLICATIONS.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'PUBLICATIONS.DELETE.CANCEL' })}
        handleClose={() => {
          setAlertOpen(false);
        }}
        handleAgree={() => deleteId && deletePublication({ id: deleteId })}
      />

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <PublicationPost
          isView
          deletePost={handleDeleteDialog}
          key={publication.id.toString()}
          publication={publication}
          isAdmin={Boolean(isAdmin)}
        />
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <div style={{ width: '80%' }}>
            <p
              style={{
                color: '#212328',
                fontSize: 32,
                paddingBottom: 24,
                paddingTop: window.innerWidth < 1024 ? 0 : 48,
              }}
            >
              {fm('ALSO.INTEREST')}
            </p>
            <PublicationsList
              style={{ padding: 0 }}
              deletePost={handleDeleteDialog}
              meId={me?.id || 0}
              companyId={me?.company?.id || undefined}
              publications={favoritePublicatoins || []}
              isAdmin={Boolean(isAdmin)}
              width={window.innerWidth}
              btnPub={false}
            />
            <div
              style={{
                justifyContent: isAdmin ? 'space-between' : 'flex-end',
                padding: 0,
                margin: 0,
              }}
            >
              {isAdmin && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 10,
                    marginTop: 25,
                  }}
                >
                  <ButtonWithLoader onPress={() => navigate(`/blog/edit/${publication.id}`)}>
                    {intl.formatMessage({ id: 'BLOG.EDIT' })}
                  </ButtonWithLoader>

                  <Button
                    onClick={() => handleDeleteDialog(publication.id)}
                    // className={classes.buttons}
                    variant='outlined'
                    color='primary'
                  >
                    {fm('DELETE.POST')}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    me: state.profile.me,
    loading: state.blog.editPublicationLoading,
    success: state.blog.editPublicationSuccess,
    error: state.blog.editPublicationError,

    publication: state.blog.publication,
    publicationLoading: state.blog.fetchPublicationLoading,
    publicationSuccess: state.blog.fetchPublicationSuccess,
    publicationError: state.blog.fetchPublicationError,

    deleteLoading: state.blog.deletePublicationLoading,
    deleteSuccess: state.blog.deletePublicationSuccess,
    deleteError: state.blog.deletePublicationError,
  }),
  {
    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,

    fetchPublication: blogActions.fetchEditPublicationRequest,
    clearFetchPublication: blogActions.clearFetchEditPublication,

    clearDeletePublication: blogActions.clearDeletePublication,
    deletePublication: blogActions.deletePublicationRequest,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ViewPublication);
