import React from 'react';
import { useParams } from 'react-router-dom';
import TendersList from './components/TendersList';

const TenderListPage: React.FC = () => {
  const { type } = useParams();

  return <TendersList type={type || ''} smallSendMsgBtn />;
};

export default TenderListPage;
