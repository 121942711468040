'use strict';

import KTUtil from './util';

const KTScrolltop = (elementId, options) => {
  const defaultOptions = {
    offset: 300,
    speed: 600,
    toggleClass: 'kt-scrolltop--on',
  };

  let element = KTUtil.get(elementId);
  const body = KTUtil.get('body');

  if (!element) return;

  let the = {
    element: element,
    options: KTUtil.deepExtend({}, defaultOptions, options),
    events: [],
  };

  const eventTrigger = (name, args) => {
    for (const event of the.events) {
      if (event.name === name) {
        if (event.one && !event.fired) {
          event.fired = true;
          event.handler.call(the, the, args);
        } else if (!event.one) {
          event.handler.call(the, the, args);
        }
      }
    }
  };

  const addEvent = (name, handler, one = false) => {
    the.events.push({
      name,
      handler,
      one,
      fired: false,
    });
  };

  const handle = () => {
    const pos = window.pageYOffset;
    if (pos > the.options.offset) {
      KTUtil.addClass(body, the.options.toggleClass);
    } else {
      KTUtil.removeClass(body, the.options.toggleClass);
    }
  };

  const scroll = e => {
    e.preventDefault();
    KTUtil.scrollTop(0, the.options.speed);
  };

  const build = () => {
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      window.addEventListener('touchend', handle);
      window.addEventListener('touchcancel', handle);
      window.addEventListener('touchleave', handle);
    } else {
      window.addEventListener('scroll', handle);
    }

    KTUtil.addEvent(the.element, 'click', scroll);
  };

  const construct = options => {
    if (KTUtil.data(the.element).has('scrolltop')) {
      the = KTUtil.data(the.element).get('scrolltop');
    } else {
      the.options = KTUtil.deepExtend({}, defaultOptions, options);
      build();
      KTUtil.data(the.element).set('scrolltop', the);
    }
  };

  construct(options);

  return {
    setDefaults: options => {
      Object.assign(defaultOptions, options);
    },
    on: (name, handler) => {
      addEvent(name, handler);
    },
    one: (name, handler) => {
      addEvent(name, handler, true);
    },
  };
};

// webpack support
if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
  module.exports = KTScrolltop;
}

export default KTScrolltop;
