import { UserRoles } from '../interfaces/user';

export const needBindCompany = (user: any | undefined) => {
  const isManager = user.roles.includes(UserRoles.ROLE_MANAGER);
  if (!user) {
    return false;
  }

  return user.is_admin || isManager
    ? false
    : user &&
        !user.roles.includes(UserRoles.ROLE_BUYER) &&
        !user.roles.includes(UserRoles.ROLE_BUYER_STAFF) &&
        (!user.company || user.manager_status === 'In moderation');
};
