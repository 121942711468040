import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { getResponseMessage } from '../../../../utils/utils';
import { setGroupPositions } from '../../../../crud/productType.crud';
import { useFormatMessage } from '../../../../hooks';

export const useGroupPositions = (successFunc?: () => void) => {
  const { enqueueSnackbar } = useSnackbar();
  const fm = useFormatMessage();
  const [loadingPositions, setLoading] = useState(false);

  const handlePositions = useCallback(
    async (group_positions: string) => {
      setLoading(true);
      setGroupPositions(group_positions)
        .then(() => {
          successFunc && successFunc();
          enqueueSnackbar(fm('GROUP.SAVED'), { variant: 'success' });
        })
        .catch(e => {
          enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`, { variant: 'error' });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [successFunc]
  );

  return { handlePositions, loadingPositions };
};
