import React, { useEffect } from 'react';
import KTScrolltop from '../../_assets/js/scrolltop';

const ScrollTop = () => {
  const scrollTopCommonRef: any = React.useRef();

  useEffect(() => {
    const scrollTopOptions = {
      offset: 300,
      speed: 300,
    };

    KTScrolltop(scrollTopCommonRef.current, scrollTopOptions);
  }, []);

  return (
    <div id='kt_scrolltop' className='kt-scrolltop' ref={scrollTopCommonRef}>
      <i className='la la-arrow-up' />
    </div>
  );
};

export default ScrollTop;
