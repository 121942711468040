import React, { useCallback, useEffect, useMemo, useState } from 'react';
// import { useParams } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  Button,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import TablePaginator from '../../../../components/tableComponents/TablePaginator/TablePaginator';
import { SHeadTableCell } from '../../../../components/styledComponents/Tables';
import Preloader from '../../../../components/other/Preloader/Preloader';
import { IAppState } from '../../../../store/rootDuck';
import { actions as requestActions } from '../../../../store/ducks/request.duck';
import { actions as messsageActions } from '../../../../store/ducks/messages.duck';
import {
  getRequestTypeWithTranslates,
  ListType,
  requestTypeList,
} from '../../orders/constatns';
import { UserRoles } from '../../../../interfaces/user';
import { actions as profileActions } from '../../../../store/ducks/profile.duck';
import { useStyles } from '../../orders/hooks/useStyles';
import TableRowRequestList from './TableRowRequestList';
import { IRequest } from '../../../../interfaces/request';
import AlertDialog from '../../../../components/ui/Dialog/AlertDialog';

const getStatusString = (type: string, selectedOrderStatuses: string[]): string => {
  if (type === 'full') {
    if (selectedOrderStatuses.length > 0) {
      return selectedOrderStatuses.toString();
    }
    return requestTypeList.toString();
  }
  if (type === 'abandoned') return 'cart';
  return '';
};

const RequestsItem: React.FC<
  TPropsFromRedux & { type: string; userId?: number; smallSendMsgBtn?: boolean }
> = ({
  requests,
  loading,
  fetch,
  page,
  perPage,
  total,
  editStatus,
  userRole,
  meLoading,
  fetchMe,
  clearMe,
  me,
  type,
  userId,
  delRequest,
  delError,
  clearErrors,
}) => {
  const classes1 = useStyles();
  const { classes } = classes1;
  const intl = useIntl();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteId, setDeleteId] = useState<number | undefined>(-1);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(-1);
  // const [showModalMessages, setShowModalMessages] = useState(false);
  // const [currentResponse, setCurrentResponse] = useState<IRequest | null>(null);
  // const [selectedPaidId, setSelectedPaidId] = useState(-1);

  // const [selectedOrderStatuses, setSelectedOrderStatuses] = useState<string[]>([]);

  const deleteAction = useCallback(() => {
    setAlertOpen(false);
    if (deleteId) {
      delRequest({
        page,
        perPage,
        id: deleteId,
        statuses: getStatusString(type, []),
        // root: me?.is_admin
      });
    }
  }, [deleteId, page, perPage, type, delRequest, me]);

  const selectAction = useCallback(
    (id: number) => {
      setSelectedId(id);
    },
    [setSelectedId]
  );
  useEffect(() => {
    if (delError) {
      enqueueSnackbar(delError, { variant: 'error' });
      setAlertOpen(false);
      clearErrors();
    }
    return () => {
      clearErrors();
    };
  }, [delError, clearErrors, setAlertOpen, enqueueSnackbar]);

  useEffect(() => {
    fetch({ page: 1, perPage: 20 });
  }, [type, userId, me]);

  useEffect(() => {
    if (!me) fetchMe();
    return () => {
      clearMe();
    };
  }, []);

  const translates: { [type: string]: string } = {
    new: intl.formatMessage({ id: 'ORDER.TYPE.NEW' }),
    completed: intl.formatMessage({ id: 'ORDER.TYPE.COMPLETED' }),
    in_work: intl.formatMessage({ id: 'ORDER.TYPE.PROCCESS' }),
  };

  const requestTypes = getRequestTypeWithTranslates(translates, type === ListType.ABANDONED);

  const isBuyer = useMemo(
    () =>
      userRole?.[0] === UserRoles.ROLE_BUYER || userRole?.[0] === UserRoles.ROLE_BUYER_STAFF,
    [userRole]
  );

  // const closeMessages = useCallback(() => {
  //   setShowModalMessages(false);
  //   setCurrentResponse(null);
  //   clearMessages();
  // }, []);

  const handleDeleteDialog = useCallback((id: number | undefined) => {
    setDeleteId(id);
    setAlertOpen(true);
  }, []);

  const editAction = useCallback(
    (item: IRequest) => {
      navigate({ pathname: `/requests/edit/${item.id}` });
    },
    [navigate]
  );

  const viewAction = useCallback(
    (item: IRequest) => {
      navigate({ pathname: `/requests/view/${item.id}` });
    },
    [navigate]
  );

  const goToCreateRequest = useCallback(() => {
    navigate(`/requests/create`);
  }, [navigate]);

  if (meLoading) return <Preloader />;

  return (
    <>
      <Button
        color='primary'
        variant='contained'
        style={{ marginBottom: 16 }}
        onClick={goToCreateRequest}
      >
        Создать заявку
      </Button>

      <div className={classes.container}>
        <div className={`${classes.ordersCol} ${type === ListType.ABANDONED ? 'full' : ''}`}>
          {loading ? (
            <Preloader />
          ) : (
            <>
              {!requests || !requests?.length ? (
                <Typography className={classes.empty} component='h5' variant='h5'>
                  {intl.formatMessage({ id: 'REQUEST.CATALOG.LIST.EMPTY' })}
                </Typography>
              ) : (
                <div className={classes.card}>
                  <Card className={classes.card}>
                    <CardContent className={classes.tableContainer}>
                      <Table aria-label='purchases'>
                        <TableHead>
                          <TableRow>
                            <SHeadTableCell className={classes.cell} align='center'>
                              {intl.formatMessage({ id: 'ORDER.TABLE.ID' })}
                            </SHeadTableCell>

                            <SHeadTableCell align='center' className={classes.cell}>
                              {intl.formatMessage({ id: 'PUBLICATIONS.TABS.PHOTOS' })}
                            </SHeadTableCell>

                            <SHeadTableCell className={classes.cell}>
                              {intl.formatMessage({ id: 'CATEGORIES.TABLE.NAME' })}
                            </SHeadTableCell>

                            <SHeadTableCell align='center' className={classes.cell}>
                              {intl.formatMessage({ id: 'ORDER.TABLE.DATE' })}
                            </SHeadTableCell>

                            <SHeadTableCell align='center' className={classes.cell}>
                              {intl.formatMessage({ id: 'ORDER.TABLE.STATUS' })}
                            </SHeadTableCell>

                            <SHeadTableCell align='center' className={classes.cell}>
                              {intl.formatMessage({ id: 'USER.TABLE.FIO' })}
                            </SHeadTableCell>

                            <SHeadTableCell align='center' className={classes.cell}>
                              {intl.formatMessage({ id: 'AUTH.INPUT.PHONE' })}
                            </SHeadTableCell>

                            <SHeadTableCell align='center' className={classes.cell}>
                              {intl.formatMessage({ id: 'USER.TABLE.EMAIL' })}
                            </SHeadTableCell>

                            <SHeadTableCell align='right' className={classes.cell}>
                              {intl.formatMessage({ id: 'ORDER.TABLE.ACTIONS' })}
                            </SHeadTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {requests?.map(item => (
                            <TableRowRequestList
                              key={item.id.toString()}
                              item={item}
                              selectAction={selectAction}
                              handleDeleteDialog={handleDeleteDialog}
                              editAction={editAction}
                              selectedId={selectedId}
                              isBuyer={isBuyer}
                              translates={translates}
                              editStatus={editStatus}
                              requestTypes={requestTypes}
                              viewAction={viewAction}
                            />
                          ))}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePaginator
                              statuses={getStatusString(type, [])}
                              page={page}
                              realPerPage={requests?.length}
                              perPage={perPage}
                              total={total}
                              fetchRows={fetch}
                              label={intl.formatMessage({ id: 'REQUEST.TABLE.PERPAGE' })}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </CardContent>
                  </Card>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {/* {showModalMessages && (
        <MessagesDialog
          open={showModalMessages}
          handleClose={closeMessages}
          currentResponse={currentResponse}
        />
      )} */}
      <AlertDialog
        open={isAlertOpen}
        message={intl.formatMessage({ id: 'REQUEST.DELETE.TEXT' })}
        okText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })}
        handleClose={() => {
          clearErrors();
          setAlertOpen(false);
        }}
        handleAgree={() => deleteAction()}
      />
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    meLoading: state.profile.loading,
    requests: state.request.requests,
    loading: state.request.loading,
    page: state.request.page,
    perPage: state.request.per_page,
    total: state.request.total,
    userRole: state.auth.user?.roles,
    me: state.profile.me,
    delError: state.request.delError,
  }),
  {
    fetch: requestActions.fetchRequest,
    clearErrors: requestActions.clearEdit,
    editStatus: requestActions.editStatusRequest,
    delRequest: requestActions.delRequest,

    clearMessages: messsageActions.clear,

    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RequestsItem);
