import axios from 'axios';
import { IOrderForEdit } from '../interfaces/order';
import {
  getOrdersByStatus,
  getOrderurl,
  getTenderUrl,
  getTendersByStatus,
} from '../constants';

export const getOrders = (
  statuses: string,
  page: number,
  per_page: number,
  userId?: number,
  accord_order_state?: string,
  payment_status?: string,
  company_id?: string,
  date_from?: string,
  date_to?: string,
  hide_delivery_cost?: boolean,
  csv?: boolean,
  user_phone?: string | null,
  user_email?: string | null,
  user_name?: string | null,
  stock_status?: string
) => {
  const boxberry = company_id?.toString() === '-2';
  return axios.get(getOrdersByStatus(statuses), {
    params: {
      page,
      per_page,
      date_from,
      date_to,
      stock_status,
      payment_status,
      user_id: userId,
      user_email,
      user_phone,
      user_name,
      company_id: boxberry ? undefined : company_id,
      accord_order_state,
      hide_delivery_cost,
      csv,
      boxberry,
    },
  });
};

export const deleteOrder = (id: number) => axios.delete(getOrderurl(id));

export const editOrder = (id: number, order: IOrderForEdit) =>
  axios.put(getOrderurl(id), order);

export const getOrderById = (id: number) => axios.get(getOrderurl(id));

export const getTenders = (
  statuses: string,
  page: number,
  per_page: number,
  companyId?: number
) => {
  return axios.get(getTendersByStatus(statuses), {
    params: { page, per_page, company_id: companyId },
  });
};

export const editTender = (id: number, order: any) =>
  axios.put(`${getTenderUrl(id)}`, order, {
    params: {
      company_id: order.company_id,
      interested_id: order.interested_id,
      customer_id: order.customer_id,
      dop_contractor_id: order.dop_contractor_id,
      category_id: order.category_id,
    },
  });

export const deleteTender = (id: number) => axios.delete(getTenderUrl(id));

export const getTenderById = (id: number) => axios.get(getTenderUrl(id));

export const addProductToOrder = (product_id: number, id: number) =>
  axios.post(`/api/shop/tender/add_product`, undefined, {
    params: {
      id,
      product_id,
    },
  });

export const deleteProductToOrder = (product_id: number, id: number) =>
  axios.delete(`/api/shop/tender/${id}/product`, { params: { product_id } });

export const createTender = (order: any) =>
  axios.post(`/api/shop/tender/add`, order, {
    params: {
      company_id: order.company_id,
      interested_id: order.interested_id,
      customer_id: order.customer_id,
      dop_contractor_id: order.dop_contractor_id,
    },
  });

export const createParticipation = (id: number, data: any, company_id?: number) =>
  axios.post(
    `/api/shop/tender/${id}/add_proposal${company_id ? `?company_id=${company_id}` : ''}`,
    data
  );

export const getParticipations = (
  tender_id: number,
  page: number,
  per_page: number,
  company_id?: number,
  statuses?: string
) =>
  axios.get(
    `/api/shop/proposals?tender_id=${tender_id}${
      company_id ? `&company_id=${company_id}` : ''
    }&page=${page}&per_page=${per_page}${statuses ? `&statuses=${statuses}` : ''}`
  );

export const editParticipation = (id: number, data: any) =>
  axios.post(`/api/shop/proposal/${id}`, data);

export const editFormikRisk = (id: number, data: any) =>
  axios.post(`/api/shop/product/${id}`, data);

export const getTenderPdf = (id: string, data: FormData) => {
  return axios.post(`/api/shop/tender/pdf/${id}`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const getTenderSteps = (id: string) => axios.get(`/api/shop/tender_steps/${id}`);

export const addMainProposal = (id: number) => axios.post(`/api/shop/proposal/${id}/main`);
