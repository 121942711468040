import React from 'react';
import { Routes, Route } from 'react-router-dom';
import StoresList from './StoreList';
import StoreEditPage from './StorePage';

const StorePage = () => (
  <Routes>
    <Route path='list' element={<StoresList />} />
    <Route path='create/:companyId?/:companyName?' element={<StoreEditPage />} />
    <Route path='edit/:id' element={<StoreEditPage />} />
  </Routes>
);

export default StorePage;
