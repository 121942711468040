import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { deleteProduct } from '../../../../crud/product.crud';
import { getResponseMessage } from '../../../../utils/utils';

export const useDeleteProduct = () => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [loadingCommonTab, setLoading] = useState(false);
  const [successDeleteCommonTab, setSuccess] = useState(false);
  const deleteProductFetch = useCallback(async (id: number) => {
    setLoading(true);
    deleteProduct(id)
      .then(() => {
        setSuccess(true);
        enqueueSnackbar(`${intl.formatMessage({ id: 'RISK.SUCCESS.DELETE' })}`, {
          variant: 'success',
        });
      })
      .catch(e => {
        enqueueSnackbar(`${intl.formatMessage({ id: 'ERROR' })}: ${getResponseMessage(e)}`, {
          variant: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
        setSuccess(false);
      });
  }, []);
  return { deleteProductFetch, loadingCommonTab, successDeleteCommonTab };
};
