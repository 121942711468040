import React, { useCallback, useEffect, useState } from 'react';
import { Button, DialogProps, Paper, TextField } from '@mui/material';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { actions as companiesActions } from '../../../../store/ducks/companies.duck';
import { IAppState } from '../../../../store/rootDuck';
import { useDefineUserRole, useFormatMessage } from '../../../../hooks';
import { Modal } from '../../../../components/other/Modals';
import CompaniesTable from '../../../../components/tableComponents/Table/CompaniesTable';
import { IUser } from '../../../../interfaces/user';
// import NumberFormatPercent from '../../../../components/NumberFormatPercent';
import { useStylesModalAddParticipation } from '../hooks/useStyles';
// import NumberFormatPrice from '../../../../components/NumberFormatPrice';
// import TextFieldCustom from '../../../../components/TextFieldCustom';

const getInitialValues = (): any => ({
  amount: null,
  comment: '',
  company: null,
  companyInn: '',
  // rate: null,
  // franchise: null,
  main: false,
});

interface IProps {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  handleCreatePart: (submitValues: any, company_id: number, isMain?: boolean) => void;
  isMainProp: boolean;
}

const ModalAddParticipation: React.FC<IProps> = ({
  showModal,
  setShowModal,
  handleCreatePart,
  // isMainProp,
}) => {
  const fm = useFormatMessage();
  const dispatch = useDispatch();
  const classes = useStylesModalAddParticipation();
  const [openDialogCompanies, setOpenDialogCompanies] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const me = useSelector(({ profile }: IAppState) => profile.me, shallowEqual);
  const isManager = useDefineUserRole(me, 'ROLE_MANAGER');
  const {
    companies,
    loadingCompanies,
    editCompanyLoading,
    pageCompanies,
    perPageCompanies,
    totalCompanies,
    loadingSearchCompanies,
  } = useSelector(
    ({ companies }: IAppState) => ({
      companies: companies.companies,
      loadingCompanies: companies.loading,
      editCompanyLoading: companies.editLoading,
      pageCompanies: companies.page,
      perPageCompanies: companies.per_page,
      totalCompanies: companies.total,
      loadingSearchCompanies: companies.searchLoading,
    }),
    shallowEqual
  );
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(),
    validationSchema: Yup.object().shape({
      amount: Yup.number().required(fm('AUTH.VALIDATION.REQUIRED_FIELD')).nullable(),
      // comment: Yup.string().required(fm('AUTH.VALIDATION.REQUIRED_FIELD')),
      // rate: Yup.number().required(fm('AUTH.VALIDATION.REQUIRED_FIELD')).nullable(),
      // franchise: Yup.number().required(fm('AUTH.VALIDATION.REQUIRED_FIELD')).nullable(),
      company: Yup.object()
        .shape({
          id: Yup.number(),
        })
        .nullable()
        .required(fm('AUTH.VALIDATION.REQUIRED_FIELD')),
    }),
    onSubmit: submitValues => {
      const data = { ...submitValues };
      delete data.company;
      delete data.companyInn;
      delete data.main;
      handleCreatePart(data, submitValues.company.id, submitValues.main);
      setShowModal(false);
    },
  });
  const deleteCompany = useCallback(() => {
    setFieldValue('company', null);
  }, []);

  const openCompaniesDialog = useCallback(() => {
    dispatch(
      companiesActions.fetchRequest({
        perPage: 20,
        page: pageCompanies,
        // roles: 'ROLE_BUYER',
      })
    );
    setOpenDialogCompanies(true);
  }, [values, pageCompanies]);

  const openSearchCompaniesDialog = useCallback(() => {
    if (values.companyInn === '') return null;
    dispatch(
      companiesActions.searchRequest({
        page: pageCompanies,
        perPage: perPageCompanies,
        inn: values.companyInn,
        // roles: 'ROLE_BUYER',
      })
    );
    setIsSearch(true);
    setOpenDialogCompanies(true);
  }, [values]);

  useEffect(() => {
    if (!openDialogCompanies) {
      dispatch(companiesActions.clearFetch());
    }
  }, [openDialogCompanies]);

  useEffect(() => {
    !showModal && resetForm();
  }, [showModal]);

  useEffect(() => {
    return () => {
      // dispatch(companiesActions.clearEdit());
      dispatch(companiesActions.clearFetch());
    };
  }, []);

  useEffect(() => {
    if (!values.company?.id && values.main) {
      setFieldValue('main', false);
    }
  }, [values]);

  return (
    <>
      <Modal
        DialogProps={{ maxWidth: 'xl' } as DialogProps}
        open={openDialogCompanies}
        onClose={() => {
          if (!editCompanyLoading) {
            setOpenDialogCompanies(false);
            setIsSearch(false);
          }
        }}
        title={fm('COMPANY.EDIT.MOUNT_TITLE')}
        loading={loadingCompanies || loadingSearchCompanies || editCompanyLoading}
        content={
          <CompaniesTable
            me={me as IUser}
            page={pageCompanies}
            perPage={perPageCompanies}
            total={totalCompanies}
            companies={companies}
            fetch={({ page, perPage }) =>
              dispatch(
                companiesActions.fetchRequest({
                  page,
                  perPage,
                  // roles: 'ROLE_BUYER',
                  inn: isSearch ? values.companyInn : undefined,
                })
              )
            }
            onConnectUser={company => {
              setFieldValue('company', company);
              setOpenDialogCompanies(false);
              setIsSearch(false);
            }}
            hideEdit
            textConnect={fm('COMMON.BUTTON.ADD')}
          />
        }
        actions={[
          {
            title: fm('COMMON.BUTTON.CLOSE'),
            onClick: () => setOpenDialogCompanies(false),
          },
        ]}
      />
      <Modal
        DialogProps={{ maxWidth: 'xl' } as DialogProps}
        open={showModal}
        onClose={() => setShowModal(false)}
        title={fm('PARTICIPATION.TABLE.ADD')}
        loading={false}
        DialogContentProps={{
          style: { margin: 0, padding: 0, backgroundColor: '#FFFFFFFF' },
        }}
        DialogActionsProps={{ style: { backgroundColor: '#FFFFFFFF' } }}
        DialogTitleProps={{ style: { backgroundColor: '#FFFFFFFF' } }}
        DialogContentTextProps={{
          style: { margin: 0, padding: 0, backgroundColor: '#FFFFFFFF' },
        }}
        content={
          <Paper className={classes.container}>
            <div className={classes.companyField} style={{ width: '100%' }}>
              <TextField
                label={fm('COMPANY.FORM.COMPANY')}
                margin='normal'
                name={values.company?.name ? 'company' : 'companyInn'}
                placeholder={fm('COMPANY.SEARCH.BY_INN')}
                value={values.company?.name || values.companyInn}
                onChange={handleChange}
                onBlur={handleBlur}
                variant='outlined'
                disabled={!!values.company?.name}
                helperText={touched.company && errors.company?.toString()}
                error={Boolean(touched.company && errors.company)}
                InputProps={{
                  endAdornment: values.company?.name
                    ? (me?.is_admin && isManager) || (
                        <DeleteIcon
                          className={classes.inputIcon}
                          fontSize='small'
                          onClick={deleteCompany}
                        />
                      )
                    : (me?.is_admin && isManager) || (
                        <SearchIcon
                          className={classes.inputIcon}
                          color='primary'
                          fontSize='small'
                          onClick={openSearchCompaniesDialog}
                        />
                      ),
                }}
                InputLabelProps={{ shrink: true }}
              />
              {!values.company?.name && (me?.is_admin || isManager) && (
                <Button
                  className={classes.allCompaniesBtn}
                  style={{
                    marginTop: touched.company && errors.company ? -10 : 8,
                  }}
                  variant='outlined'
                  color='primary'
                  onClick={openCompaniesDialog}
                >
                  {fm('COMPANY.BUTTON.ALL')}
                </Button>
              )}
            </div>
            {/* {!isMainProp && values.company?.id && (
                 <div className={classes.checkbox}>
                <FormControlLabel
                  value={values.main}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name='main'
                  label={fm('PARTICIPATION.CEDAR')}
                  control={<Checkbox color='primary' />}
                />
              </div>
            )} */}
            <TextField
              type='text'
              label={fm('PARTICIPATION.FORM.SUM')}
              margin='normal'
              name='amount'
              value={values.amount}
              variant='outlined'
              helperText={touched.amount && errors.amount?.toString()}
              error={Boolean(touched.amount && errors.amount)}
              onBlur={handleBlur}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            />
            {/* <TextFieldCustom
              type='text'
              label={fm('PARTICIPATION.FORM.TARIFF')}
              margin='normal'
              name='rate'
              value={values.rate}
              variant='outlined'
              helperText={touched.rate && errors.rate}
              error={Boolean(touched.rate && errors.rate)}
              onBlur={handleBlur}
              onChange={handleChange}
              InputProps={{
                classes: {
                  root: classes.input,
                  focused: classes.inputFocused,
                  notchedOutline: classes.notchedOutline,
                },
                inputComponent: NumberFormatPercent as any,
              }}
              InputLabelProps={{
                classes: {
                  root: classes.labelTextField,
                  focused: classes.labelTextFieldFocused,
                  shrink: classes.labelTextFieldShrink,
                },
              }}
            /> */}
            {/* <TextFieldCustom
              type='text'
              label={fm('PARTICIPATION.FORM.FRANCHISE')}
              margin='normal'
              name='franchise'
              value={values.franchise}
              variant='outlined'
              helperText={touched.franchise && errors.franchise}
              error={Boolean(touched.franchise && errors.franchise)}
              onBlur={handleBlur}
              onChange={handleChange}
              InputProps={{
                classes: {
                  root: classes.input,
                  focused: classes.inputFocused,
                  notchedOutline: classes.notchedOutline,
                },
                inputComponent: NumberFormatPrice as any,
              }}
              InputLabelProps={{
                classes: {
                  root: classes.labelTextField,
                  focused: classes.labelTextFieldFocused,
                  shrink: classes.labelTextFieldShrink,
                },
              }}
            /> */}
            <TextField
              type='text'
              label={fm('PARTICIPATION.FORM.COMMENT')}
              margin='normal'
              name='comment'
              value={values.comment}
              variant='outlined'
              helperText={touched.comment && errors.comment?.toString()}
              error={Boolean(touched.comment && errors.comment)}
              onBlur={handleBlur}
              onChange={handleChange}
              multiline
              rows={10}
              InputLabelProps={{ shrink: true }}
            />
          </Paper>
        }
        actions={[
          {
            title: fm('PARTICIPATION.TABLE.ADD'),
            onClick: () => handleSubmit(),
          },
          {
            title: fm('COMMON.BUTTON.CLOSE'),
            onClick: () => setShowModal(false),
          },
        ]}
      />
    </>
  );
};

export default React.memo(ModalAddParticipation);
