import { useIntl } from 'react-intl';
import { TariffsType } from '../TariffList';
import { ITariff } from '../../../../interfaces/tariff';
import { TableBlockInterface } from '../interfaces';


const buyersTableConfig = (
  tariffFree: ITariff,
  tariffPro: ITariff
): TableBlockInterface[] => {
  const intl = useIntl();
  return [
    {
      title: intl.formatMessage({ id: 'TARIFF.COMMUNICATION.SYSTEM' }),
      rows: [
        {
          title: intl.formatMessage({ id: 'TARIFF.DISPLAYING.PEOPLE.PERDAY' }),
          free: {
            value: tariffFree.displaying_other_contacts,
            text: tariffFree.displaying_other_contacts || intl.formatMessage({ id: 'TARIFF.DISPLAYING.PEOPLE.UNLIMMITED' }),
            field: 'displaying_other_contacts',
          },
          pro: {
            value: tariffPro.displaying_other_contacts,
            text: tariffPro.displaying_other_contacts || intl.formatMessage({ id: 'TARIFF.DISPLAYING.PEOPLE.UNLIMMITED' }),
            field: 'displaying_other_contacts',
          },
          color: 'green',
        },
      ],
    },
    {
      title: intl.formatMessage({ id: 'TARIFF.COST' }),
      rows: [
        {
          name: intl.formatMessage({ id: 'TARIFF.COST.SEVEN.DAYS' }),
          title: 'price7days',
          free: `${tariffFree.price7days}`,
          pro: `${tariffPro.price7days}`,
        },
        {
          name: intl.formatMessage({ id: 'TARIFF.COST.THIRTY.DAYS' }),
          title: 'price30days',
          free: `${tariffFree.price30days}`,
          pro: `${tariffPro.price30days}`,
        },
        {
          name: intl.formatMessage({ id: 'TARIFF.COST.HUNDREDTWENTY.DAYS' }),
          title: 'price120days',
          free: `${tariffFree.price120days}`,
          pro: `${tariffPro.price120days}`,
        },
        {
          name: intl.formatMessage({ id: 'TARIFF.COST.YEAR' }),
          title: 'price365days',
          free: `${tariffFree.price365days}`,
          pro: `${tariffPro.price365days}`,
        },
      ],
    },
  ];
}

const providersTableConfig = (
  tariffFree: ITariff,
  tariffPro: ITariff
): TableBlockInterface[] => {
  const intl = useIntl();
  return [
    {
      title: intl.formatMessage({ id: 'TARIFF.COMMUNICATION.SYSTEM' }),
      rows: [
        {
          title: intl.formatMessage({ id: 'TARIFF.DISPLAYING.PEOPLE.PERDAY' }),
          free: {
            value: tariffFree.displaying_other_contacts,
            text: tariffFree.displaying_other_contacts || intl.formatMessage({ id: 'TARIFF.DISPLAYING.PEOPLE.UNLIMMITED' }),
            field: 'displaying_other_contacts',
          },
          pro: {
            value: tariffPro.displaying_other_contacts,
            text: tariffPro.displaying_other_contacts || intl.formatMessage({ id: 'TARIFF.DISPLAYING.PEOPLE.UNLIMMITED' }),
            field: 'displaying_other_contacts',
          },
          color: 'green',
        },
      ],
    },
    {
      title: intl.formatMessage({ id: 'TARIFF.COST' }),
      rows: [
        {
          name: intl.formatMessage({ id: 'TARIFF.COST.SEVEN.DAYS' }),
          title: 'price7days',
          free: `${tariffFree.price7days}`,
          pro: `${tariffPro.price7days}`,
        },
        {
          name: intl.formatMessage({ id: 'TARIFF.COST.THIRTY.DAYS' }),
          title: 'price30days',
          free: `${tariffFree.price30days}`,
          pro: `${tariffPro.price30days}`,
        },
        {
          name: intl.formatMessage({ id: 'TARIFF.COST.HUNDREDTWENTY.DAYS' }),
          title: 'price120days',
          free: `${tariffFree.price120days}`,
          pro: `${tariffPro.price120days}`,
        },
        {
          name: intl.formatMessage({ id: 'TARIFF.COST.YEAR' }),
          title: 'price365days',
          free: `${tariffFree.price365days}`,
          pro: `${tariffPro.price365days}`,
        },
      ],
    }
  ]
}

const getConfig = (type: TariffsType, freeTariff: ITariff, proTariff: ITariff) => {
  switch (type) {
    case TariffsType.buyers:
      return buyersTableConfig(freeTariff, proTariff);
    case TariffsType.vendors:
      return providersTableConfig(freeTariff, proTariff);
    default:
      return providersTableConfig(freeTariff, proTariff);
  }
};

export default getConfig;
