import React from 'react';
import { TextField, TextFieldProps, createTheme, ThemeProvider } from '@mui/material';

const TextFieldCustom: React.FC<TextFieldProps> = props => {
  const theme = createTheme({
    typography: {
      fontFamily: ['Open Sans'].join(','),
    },
    breakpoints: {
      // @ts-ignore
      values: {
        sm: 544, // Small screen / phone
        md: 768, // Medium screen / tablet
        lg: 1024, // Large screen / desktop
        xl: 1200,
      },
    },
    palette: {
      contrastThreshold: 3,
      primary: {
        main: '#369ff7',
        contrastText: '#fff',
      },
      secondary: {
        main: '#fd397a',
        contrastText: '#ffffff',
      },
      error: {
        main: '#fd397a',
      },
      success: {
        main: '#0abb87',
      },
    },
    overrides: {
      // @ts-ignore
      PrivateNotchedOutline: {
        legendNotched: {
          maxWidth: '0px',
        },
      },
    },
    props: {
      MuiButtonBase: {
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
      MuiPopover: {
        elevation: 1,
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <TextField style={{ width: '100%' }} {...props} />
    </ThemeProvider>
  );
};

export default TextFieldCustom;
