import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { createRequest } from '../../../../crud/request';
import { getResponseMessage } from '../../../../utils/utils';
import { IRequestData } from '../../../../interfaces/request';

export const useCreateRequest = () => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [createLoading, setLoading] = useState(false);
  const [successSearch, setSuccess] = useState(false);
  const [createId, setCreateId] = useState(null);
  const createFetch = useCallback(async (data: FormData, params: IRequestData) => {
    setLoading(true);
    createRequest(data, params)
      .then((res: any) => {
        setCreateId(res.data.data);
        setSuccess(true);
        enqueueSnackbar(`${intl.formatMessage({ id: 'REQUEST.SUCCESS.CREATE' })}`, {
          variant: 'success',
        });
      })
      .catch(e => {
        enqueueSnackbar(`${intl.formatMessage({ id: 'ERROR' })}: ${getResponseMessage(e)}`, {
          variant: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
        setSuccess(false);
        setCreateId(null);
      });
  }, []);
  return { createFetch, createLoading, successSearch, createId };
};
