import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import {
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  Box,
  IconButton,
  Checkbox,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import EditOutlinedIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

import ButtonWithLoader from '../../../../../components/other/Buttons/ButtonWithLoader';

import {
  IEnumValue,
  ITypeParameter,
  Translations,
} from '../../../../../interfaces/productType';
import homeStyles from '../../../../../constants/homeStyles';
import { useStylesTypeForm } from '../../hooks/useStyles';
import { categoryLanguages } from '../../../categories/utils/categoryLanguages';
import { UploadIcon } from '../../../../../components/ui/UploadIcon';

interface IProps {
  param: ITypeParameter;
  paramAction: any;
  deleteField: (id?: number, index?: number) => void;
  index: number;
  disabled: boolean;
  groups: { id: number; name: string }[];
  deleteImage?: (id: number) => void;
  fetchDeleteEnumValue: (parameter_id: number) => void;
}

const getInitialValues = (param: ITypeParameter, locale: string) => {
  const params: any = {
    name: param.names?.[locale] || param.name,
    enum_data: param.parameter_enum_values || [],
    type: param.type || 'number',
    id: param.id,
    required: param.required || false,
    bulk_edit: param.bulk_edit || false,
    filter_type: param.filter_type || null,
    inGroupId: param.in_group?.id || -1,
    names: param.names || {},
    adminOnly: param.admin_only || false,
  };
  return params;
};
const TypeParamForm: React.FC<IProps> = ({
  param,
  paramAction,
  deleteField,
  index,
  disabled,
  groups,
  deleteImage,
  fetchDeleteEnumValue,
}) => {
  const intl = useIntl();
  const homeClasses = homeStyles();
  const navigate = useNavigate();
  const classes1 = useStylesTypeForm();
  const {classes} = classes1
  const [enumParams, setEnumParams] = useState<
    {
      value: string;
      favorite: boolean;
      image?: File;
      translations?: Translations;
    }[]
  >([{ value: '', favorite: false }]);
  const [typeLanguage, setLanguage] = useState(intl.locale === 'en' ? 'ru' : 'en');
  const [showUploadIcons, setShowUploadIcons] = useState(false);
  const addEmptyEnumParam = () => {
    setEnumParams([...enumParams, { value: '', favorite: false }]);
  };

  useEffect(() => {
    if (param?.parameter_enum_values) {
      param.parameter_enum_values.forEach(i => i.image && setShowUploadIcons(true));
    }
  }, [param]);

  const {
    values,
    setFieldValue,
    handleSubmit,
    handleBlur,
    touched,
    errors,
    handleChange,
    isSubmitting,
  } = useFormik({
    initialValues: getInitialValues(param, intl.locale),
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required(intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' }))
        .nullable(),
    }),
    onSubmit: (values, { setSubmitting }) => {
      if (values.type === 'enum' || values.type === 'multiple_enum') {
        setEnumParams([{ value: '', favorite: false }]);
        paramAction(
          {
            type: values.type,
            name: values.name,
            // enum: enumArray,
            // enum_data: enumData,
            required: values.required,
            bulk_edit: values.bulk_edit,
            filter_type:
              values.type !== 'text' && values.type !== 'string' ? values.filter_type : null,
            id: values.id,
            inGroupId: values.inGroupId === -1 ? 0 : values.inGroupId,
            adminOnly: values.adminOnly,
            // translations,
            names: {
              [intl.locale]: values.name,
              ...values.names,
            },
          },
          values.enum_data,
          enumParams
        );
      } else {
        paramAction({
          ...values,
          filter_type:
            values.type !== 'text' && values.type !== 'string' ? values.filter_type : null,
          names: {
            [intl.locale]: values.name,
            ...values.names,
          },
          inGroupId: values.inGroupId === -1 ? 0 : values.inGroupId,
        });
      }
      setSubmitting(false);
    },
  });

  return (
    <Box
      className={homeClasses.classes.paramContainer}
      border={1}
      borderColor='#eeeeee'
      borderRadius={5}
    >
      <div>
        <div className={classes.containerName}>
          <div className={classes.typeNameMainLang}>
            <TextField
              select
              margin='normal'
              label={intl.formatMessage({
                id: 'MAIN.LANGUAGE',
              })}
              value={intl.locale}
              variant='outlined'
              disabled
            >
              <MenuItem value={intl.locale}>
                {intl.formatMessage({
                  id: `CATEGORY.${intl.locale.toUpperCase()}.LANGUAGE`,
                })}
              </MenuItem>
            </TextField>
            <TextField
              type='text'
              label={intl.formatMessage({
                id: 'PRODUCT.TYPES.PARAMETER.INPUT.NAME',
              })}
              margin='normal'
              name={`names.${intl.locale}`}
              value={values.names[intl.locale] || values.name || ''}
              variant='outlined'
              onBlur={handleBlur}
              onChange={e => {
                setFieldValue(`names.${intl.locale}`, e.target.value);
                setFieldValue('name', e.target.value);
              }}
              helperText={touched.name && errors.name?.toString()}
              error={Boolean(touched.name && errors.name)}
            />
          </div>
          <div className={classes.typeNameLang}>
            <TextField
              select
              margin='normal'
              label={intl.formatMessage({
                id: 'AUTH.INPUT.LANG',
              })}
              name='language'
              value={typeLanguage}
              onChange={e => setLanguage(e.target.value)}
              variant='outlined'
            >
              {categoryLanguages
                .filter(lang => lang.code !== intl.locale)
                .map(lang => (
                  <MenuItem key={lang.code} value={lang.code}>
                    {intl.formatMessage({
                      id: `CATEGORY.${lang.code.toUpperCase()}.LANGUAGE`,
                    })}
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              type='text'
              label={intl.formatMessage({
                id: 'PRODUCT.TYPES.PARAMETER.INPUT.NAME',
              })}
              margin='normal'
              name={`names_${typeLanguage}`}
              value={values.names[typeLanguage] || ''}
              variant='outlined'
              onBlur={handleBlur}
              onChange={e => setFieldValue(`names.${typeLanguage}`, e.target.value)}
              helperText={touched.name && errors.name?.toString()}
              error={Boolean(touched.name && errors.name)}
            />
          </div>
          {param.id && (
            <IconButton
              size='small'
              onClick={() => deleteField(param.id, index)}
              className={classes.leftIcon}
            >
              <DeleteIcon color='error' />
            </IconButton>
          )}
        </div>
        <FormControlLabel
          label={intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' })}
          control={
            <Checkbox
              color='primary'
              value={values.required}
              onChange={handleChange}
              onBlur={handleBlur}
              name='required'
              checked={values.required}
            />
          }
        />
        {values.type !== 'text' && values.type !== 'string' && (
          <Tooltip
            title={intl.formatMessage({
              id: 'PRODUCTS.TOOLTIP.FILTERS',
            })}
          >
            <FormControlLabel
              label={intl.formatMessage({ id: 'PRODUCT.TYPE.FILTER' })}
              control={
                <Checkbox
                  color='primary'
                  value={values.filter_type}
                  onChange={e =>
                    setFieldValue('filter_type', e.target.checked ? 'input' : null)
                  }
                  onBlur={handleBlur}
                  name='filter_type'
                  checked={values.filter_type === 'input'}
                />
              }
            />
          </Tooltip>
        )}
        <Tooltip
          title={intl.formatMessage({
            id: 'PRODUCTS.TOOLTIP.MASS',
          })}
        >
          <FormControlLabel
            label={intl.formatMessage({ id: 'PRODUCT.TYPE.BULK' })}
            control={
              <Checkbox
                color='primary'
                value={values.bulk_edit}
                onChange={e => setFieldValue('bulk_edit', e.target.checked)}
                onBlur={handleBlur}
                name='bulk_edit'
                checked={values.bulk_edit}
              />
            }
          />
        </Tooltip>
        <Tooltip
          title={intl.formatMessage({
            id: 'PRODUCTS.TOOLTIP.ADMIN',
          })}
        >
          <FormControlLabel
            label={intl.formatMessage({ id: 'ADMIN.ONLY' })}
            control={
              <Checkbox
                color='primary'
                value={values.adminOnly}
                onChange={e => setFieldValue('adminOnly', e.target.checked)}
                onBlur={handleBlur}
                name='adminOnly'
                checked={values.adminOnly}
              />
            }
          />
        </Tooltip>
        {values.type === 'multiple_enum' && (
          <Tooltip
            title={intl.formatMessage({
              id: 'PRODUCTS.TOOLTIP.ICON',
            })}
          >
            <FormControlLabel
              label={intl.formatMessage({ id: 'WITH.ICONS' })}
              control={
                <Checkbox
                  color='primary'
                  value={values.adminOnly}
                  onChange={e => setShowUploadIcons(e.target.checked)}
                  checked={showUploadIcons}
                />
              }
            />
          </Tooltip>
        )}
        <div className={homeClasses.classes.textFieldContainer}>
          <Tooltip
            title={intl.formatMessage({
              id: 'PRODUCTS.TOOLTIP.GROUP',
            })}
          >
            <TextField
              select
              type='text'
              label={intl.formatMessage({ id: 'PARAM.GROUPS' })}
              margin='normal'
              name='inGroupId'
              value={values.inGroupId}
              variant='outlined'
              onBlur={handleBlur}
              onChange={handleChange}
            >
              <MenuItem value={-1}>-</MenuItem>
              {groups.map(option => (
                <MenuItem key={option.id.toString()} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Tooltip>
          {param.id && param.in_group?.id && (
            <IconButton
              size='small'
              onClick={() =>
                param.in_group?.id && navigate(`/groups/edit/${param.in_group.id}`)
              }
              className={homeClasses.classes.leftIcon}
            >
              <EditOutlinedIcon className={classes.icon} />
            </IconButton>
          )}
        </div>
        <RadioGroup name='type' value={values.type} onChange={handleChange}>
          <FormControlLabel
            value='number'
            control={<Radio />}
            label={intl.formatMessage({
              id: 'PRODUCT.TYPES.PARAMETER.NUMBER',
            })}
            disabled={!!param.id}
          />
          <FormControlLabel
            value='enum'
            control={<Radio />}
            label={intl.formatMessage({
              id: 'PRODUCT.TYPES.PARAMETER.LIST',
            })}
            disabled={!!param.id}
          />
          <FormControlLabel
            value='text'
            control={<Radio />}
            label={intl.formatMessage({
              id: 'PRODUCT.TYPES.PARAMETER.TEXT',
            })}
            disabled={!!param.id}
          />
          <FormControlLabel
            value='string'
            control={<Radio />}
            label={intl.formatMessage({
              id: 'PRODUCT.TYPES.PARAMETER.STRING',
            })}
            disabled={!!param.id}
          />
          <FormControlLabel
            value='multiple_enum'
            control={<Radio />}
            label={intl.formatMessage({
              id: 'PRODUCT.TYPES.MULTIPLY.ENUM',
            })}
            disabled={!!param.id}
          />
          <FormControlLabel
            value='country'
            control={<Radio />}
            label={intl.formatMessage({ id: 'PRODUCT.TYPES.LIST.COUNTRIES' })}
            disabled={!!param.id}
          />
        </RadioGroup>
        {(values.type === 'enum' || values.type === 'multiple_enum') && values.enum_data && (
          <div>
            {values.enum_data.map((enumParam: IEnumValue, index: number) => (
              <>
                <div className={homeClasses.classes.textFieldContainer} key={`i${index}`}>
                  <TextField
                    style={{ marginRight: 5 }}
                    className={classes.typeName}
                    type='text'
                    label={`${intl.formatMessage({
                      id: 'PRODUCT.TYPES.PARAMETER.INPUT.VALUE',
                    })} (${intl.locale.toUpperCase()})`}
                    margin='normal'
                    value={enumParam.translations?.[intl.locale] || enumParam.value}
                    variant='outlined'
                    onBlur={handleBlur}
                    onChange={e => {
                      setFieldValue(`enum_data[${index}].value`, e.target.value);
                      setFieldValue(
                        `enum_data[${index}].translations.${intl.locale}`,
                        e.target.value
                      );
                    }}
                  />
                  <TextField
                    style={{ marginLeft: 5 }}
                    className={classes.typeNameLang}
                    type='text'
                    label={`${intl.formatMessage({
                      id: 'PRODUCT.TYPES.PARAMETER.INPUT.VALUE',
                    })} (${typeLanguage.toUpperCase()})`}
                    margin='normal'
                    value={enumParam.translations?.[typeLanguage] || ''}
                    variant='outlined'
                    onBlur={handleBlur}
                    onChange={e => {
                      setFieldValue(
                        `enum_data[${index}].translations.${typeLanguage}`,
                        e.target.value
                      );
                    }}
                  />
                  <IconButton
                    size='small'
                    onClick={() => {
                      setFieldValue(`enum_data[${index}].favorite`, !enumParam.favorite);
                    }}
                    className={homeClasses.classes.leftIcon}
                  >
                    {enumParam.favorite ? (
                      <StarIcon color='primary' />
                    ) : (
                      <StarBorderIcon color='primary' />
                    )}
                  </IconButton>
                  <IconButton
                    size='small'
                    onClick={() => {
                      fetchDeleteEnumValue(enumParam.id);
                    }}
                    className={homeClasses.classes.leftIcon}
                  >
                    <DeleteIcon color='error' />
                  </IconButton>
                </div>
                {showUploadIcons && (
                  <UploadIcon
                    file={
                      enumParam.image && typeof enumParam.image !== 'string'
                        ? enumParam.image
                        : undefined
                    }
                    image={typeof enumParam.image === 'string' ? enumParam.image : undefined}
                    deleteFile={() => {
                      setFieldValue(`enum_data[${index}].image`, undefined);
                    }}
                    deleteImage={
                      deleteImage && enumParam.id ? () => deleteImage(enumParam.id) : undefined
                    }
                    uploadImages={file => setFieldValue(`enum_data[${index}].image`, file[0])}
                  />
                )}
              </>
            ))}
            {enumParams.map((enumParam, index) => (
              <>
                <div className={homeClasses.classes.textFieldContainer} key={`i${index}`}>
                  <TextField
                    style={{ marginRight: 5 }}
                    className={classes.typeName}
                    type='text'
                    label={`${intl.formatMessage({
                      id: 'PRODUCT.TYPES.PARAMETER.INPUT.VALUE',
                    })} (${intl.locale.toUpperCase()})`}
                    margin='normal'
                    value={enumParam.translations?.[intl.locale] || enumParam.value}
                    variant='outlined'
                    onBlur={handleBlur}
                    onChange={e => {
                      setEnumParams(state => {
                        const copy = [...state];
                        copy[index] = {
                          ...copy[index],
                          value: e.target.value,
                          translations: {
                            ...copy[index].translations,
                            [intl.locale]: e.target.value,
                          },
                        };
                        return copy;
                      });
                    }}
                  />
                  <TextField
                    style={{ marginLeft: 5 }}
                    className={classes.typeNameLang}
                    type='text'
                    label={`${intl.formatMessage({
                      id: 'PRODUCT.TYPES.PARAMETER.INPUT.VALUE',
                    })} (${typeLanguage.toUpperCase()})`}
                    margin='normal'
                    value={enumParam.translations?.[typeLanguage] || ''}
                    variant='outlined'
                    onBlur={handleBlur}
                    onChange={e => {
                      setEnumParams(state => {
                        const copy = [...state];
                        copy[index] = {
                          ...copy[index],
                          translations: {
                            ...copy[index].translations,
                            [typeLanguage]: e.target.value,
                          },
                        };
                        return copy;
                      });
                    }}
                  />
                  <IconButton
                    size='small'
                    onClick={() => {
                      setEnumParams(state => {
                        const copy = [...state];
                        copy[index] = {
                          ...copy[index],
                          favorite: !copy[index].favorite,
                        };
                        return copy;
                      });
                    }}
                    className={homeClasses.classes.leftIcon}
                  >
                    {enumParam.favorite ? (
                      <StarIcon color='primary' />
                    ) : (
                      <StarBorderIcon color='primary' />
                    )}
                  </IconButton>
                  <IconButton
                    size='small'
                    onClick={() => {
                      setEnumParams(state => {
                        const copy = [...state];
                        copy.splice(index, 1);
                        return copy;
                      });
                    }}
                    className={homeClasses.classes.leftIcon}
                  >
                    <DeleteIcon color='error' />
                  </IconButton>
                </div>
                {showUploadIcons && (
                  <UploadIcon
                    file={enumParam.image}
                    deleteFile={() => {
                      setEnumParams(state => {
                        const copy = [...state];
                        copy[index] = {
                          ...copy[index],
                          image: undefined,
                        };
                        return copy;
                      });
                    }}
                    uploadImages={file =>
                      setEnumParams(state => {
                        const copy = [...state];
                        copy[index] = {
                          ...copy[index],
                          image: file[0],
                        };
                        return copy;
                      })
                    }
                  />
                )}
              </>
            ))}{' '}
            <IconButton
              size='small'
              onClick={addEmptyEnumParam}
              className={homeClasses.classes.leftIcon}
            >
              <AddIcon />
            </IconButton>
          </div>
        )}
        <div className={homeClasses.classes.buttonContainer}>
          <ButtonWithLoader loading={isSubmitting} onPress={handleSubmit} disabled={disabled}>
            {intl.formatMessage({ id: 'PRODUCT.TYPES.PARAMETER.BUTTON.SAVE' })}
          </ButtonWithLoader>
        </div>
      </div>
    </Box>
  );
};

export default React.memo(TypeParamForm);
