import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { put, takeLatest, call } from 'redux-saga/effects';

import { TAppActions } from '../rootDuck';

import { ICategoryItem } from '../../pages/home/categories/interfaces';
import { ActionsUnion, createAction } from '../../utils/action-helper';
import { IServerResponse } from '../../interfaces/server';
import {
  getRootCategories,
  getCategoryById,
  createCategory,
  editCategory,
  getCategoriesWithParent,
  deleteCategory,
  getFullCategories,
  setCategoriesPositions,
} from '../../crud/categories.crud';
import { getResponseMessage } from '../../utils/utils';
import { IProduct } from '../../interfaces/product';

const CLEAR_CATALOG_CATEGORIES = 'categories/CLEAR_CATALOG_CATEGORIES';
const FETCH_CATALOG_CATEGORIES_REQUEST = 'categories/FETCH_CATALOG_CATEGORIES_REQUEST';
const FETCH_CATALOG_CATEGORIES_SUCCESS = 'categories/FETCH_CATALOG_CATEGORIES_SUCCESS';
const FETCH_CATALOG_CATEGORIES_FAIL = 'categories/FETCH_CATALOG_CATEGORIES_FAIL';

const CLEAR_ROOT_REQUEST = 'categories/CLEAR_ROOT_REQUEST';
const FETCH_ROOT_REQUEST = 'categories/FETCH_ROOT_REQUEST';
const FETCH_ROOT_SUCCESS = 'categories/FETCH_ROOT_SUCCESS';
const FETCH_ROOT_FAIL = 'categories/FETCH_ROOT_FAIL';

const CLEAR_FULL_REQUEST = 'categories/CLEAR_FULL_REQUEST';
const FETCH_FULL_REQUEST = 'categories/FETCH_FULL_REQUEST';
const FETCH_FULL_SUCCESS = 'categories/FETCH_FULL_SUCCESS';
const FETCH_FULL_FAIL = 'categories/FETCH_FULL_FAIL';

const CLEAR_PARENT_REQUEST = 'categories/CLEAR_PARENT_REQUEST';
const FETCH_PARENT_REQUEST = 'categories/FETCH_PARENT_REQUEST';
const FETCH_PARENT_SUCCESS = 'categories/FETCH_PARENT_SUCCESS';
const FETCH_PARENT_FAIL = 'categories/FETCH_PARENT_FAIL';

const FETCH_BY_ID_REQUEST = 'categories/FETCH_BY_ID_REQUEST';
const FETCH_BY_ID_SUCCESS = 'categories/FETCH_BY_ID_SUCCESS';
const FETCH_BY_ID_FAIL = 'categories/FETCH_BY_ID_FAIL';

const SET_POSITIONS_REQUEST = 'categories/SET_POSITIONS_REQUEST';
const SET_POSITIONS_SUCCESS = 'categories/SET_POSITIONS_SUCCESS';
const SET_POSITIONS_FAIL = 'categories/SET_POSITIONS_FAIL';

const CLEAR_EDIT = 'categories/CLEAR_EDIT';
const ADD_REQUEST = 'categories/ADD_REQUEST';
const EDIT_REQUEST = 'categories/EDIT_REQUEST';
const EDIT_SUCCESS = 'categories/EDIT_SUCCESS';
const EDIT_FAIL = 'categories/EDIT_FAIL';

const CLEAR_DELETE = 'categories/CLEAR_DELETE';
const DELETE_REQUEST = 'categories/DELETE_REQUEST';
const DELETE_SUCCESS = 'categories/DELETE_SUCCESS';
const DELETE_FAIL = 'categories/DELETE_FAIL';
const SET_PRODUCTS_CATEGORY = 'categories/SET_PRODUCTS_CATEGORY';

export interface IInitialState {
  page: number;
  per_page: number;
  total: number;

  categories: ICategoryItem[] | undefined;
  categoriesList: ICategoryItem[] | undefined;
  categoriesLoading: boolean;
  categoriesSuccess: boolean;
  categoriesError: string | null;
  productsCategory: { category: ICategoryItem; products: IProduct[] }[];

  category: ICategoryItem | undefined;
  byIdLoading: boolean;
  byIdSuccess: boolean;
  byIdError: string | null;

  editLoading: boolean;
  editSuccess: boolean;
  editError: string | null;

  deleteLoading: boolean;
  deleteSuccess: boolean;
  deleteError: string | null;

  parent: ICategoryItem | undefined;
  parentLoading: boolean;
  parentSuccess: boolean;
  parentError: string | null;

  catalogCategories: ICategoryItem[];
  catalogLoading: boolean;
  catalogSuccess: boolean;
  catalogError: string | null;
  categoryId?: number;
}

const initialState: IInitialState = {
  page: 1,
  per_page: 20,
  total: 0,

  productsCategory: [],
  categories: undefined,
  categoriesList: undefined,
  categoriesLoading: false,
  categoriesSuccess: false,
  categoriesError: null,

  category: undefined,
  byIdLoading: false,
  byIdSuccess: false,
  byIdError: null,

  editLoading: false,
  editSuccess: false,
  editError: null,

  deleteLoading: false,
  deleteSuccess: false,
  deleteError: null,

  parent: undefined,
  parentLoading: false,
  parentSuccess: false,
  parentError: null,

  catalogCategories: [],
  catalogLoading: false,
  catalogSuccess: false,
  catalogError: '',
  categoryId: undefined,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'categories', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case CLEAR_CATALOG_CATEGORIES: {
        return {
          ...state,
          catalogCategories: [],
          catalogLoading: false,
          catalogSuccess: false,
          catalogError: null,
        };
      }
      case FETCH_CATALOG_CATEGORIES_REQUEST: {
        return {
          ...state,
          catalogCategories: [],
          catalogLoading: true,
          catalogSuccess: false,
          catalogError: null,
        };
      }
      case FETCH_CATALOG_CATEGORIES_FAIL: {
        return {
          ...state,
          catalogCategories: [],
          catalogLoading: false,
          catalogSuccess: false,
          catalogError: action.payload,
        };
      }
      case FETCH_CATALOG_CATEGORIES_SUCCESS: {
        return {
          ...state,
          catalogCategories: action.payload.data,
          catalogLoading: false,
          catalogSuccess: true,
          catalogError: null,
        };
      }

      case CLEAR_FULL_REQUEST:
      case CLEAR_ROOT_REQUEST:
        return {
          ...state,
          page: 1,
          per_page: 20,
          total: 0,
          categories: undefined,
          categoriesList: undefined,
          categoriesLoading: false,
          categoriesSuccess: false,
          categoriesError: null,
        };
      case FETCH_FULL_REQUEST:
      case FETCH_ROOT_REQUEST:
        return {
          ...state,
          categoriesLoading: true,
          categoriesSuccess: false,
          categoriesError: null,
        };
      case FETCH_FULL_SUCCESS:
        return {
          ...state,
          page: action.payload.page,
          // идет проверка на мменьшее так как есть запрос categories/full он возвращает per_page: длина массива
          per_page: action.payload.per_page,
          total: action.payload.total,
          categories: action.payload.data,
          categoriesLoading: false,
          categoriesSuccess: true,
          categoriesError: null,
        };
      case SET_PRODUCTS_CATEGORY: {
        return {
          ...state,
          loading: false,
          productsCategory: action.payload,
        };
      }
      case FETCH_ROOT_SUCCESS:
        return {
          ...state,
          page: action.payload.page,
          // идет проверка на мменьшее так как есть запрос categories/full он возвращает per_page: длина массива
          per_page: action.payload.per_page <= 20 ? 20 : action.payload.per_page,
          total: action.payload.total,
          categoriesList: action.payload.data,
          categories: action.payload.data,
          categoriesLoading: false,
          categoriesSuccess: true,
          categoriesError: null,
        };
      case FETCH_FULL_FAIL:
      case FETCH_ROOT_FAIL:
        return {
          ...state,
          categoriesLoading: false,
          categoriesError: action.payload,
        };

      case CLEAR_PARENT_REQUEST: {
        return {
          ...state,
          parent: undefined,
          parentLoading: false,
          parentSuccess: false,
          parentError: null,
        };
      }
      case FETCH_PARENT_REQUEST: {
        return {
          ...state,
          parentLoading: true,
          parentSuccess: false,
          parentError: null,
        };
      }
      case FETCH_PARENT_SUCCESS: {
        return {
          ...state,
          parent: action.payload.data,
          parentLoading: false,
          parentSuccess: true,
          parentError: null,
        };
      }
      case FETCH_PARENT_FAIL: {
        return {
          ...state,
          parentLoading: false,
          parentSuccess: false,
          parentError: action.payload,
        };
      }

      case FETCH_BY_ID_REQUEST: {
        return {
          ...state,
          category: undefined,
          byIdLoading: true,
          byIdSuccess: false,
          byIdError: null,
        };
      }
      case FETCH_BY_ID_SUCCESS: {
        return {
          ...state,
          category: action.payload.data,
          byIdLoading: false,
          byIdSuccess: true,
        };
      }
      case FETCH_BY_ID_FAIL: {
        return { ...state, byIdLoading: false, byIdError: action.payload };
      }

      case CLEAR_EDIT: {
        return {
          ...state,
          model: undefined,
          editLoading: false,
          editSuccess: false,
          editError: null,
          categoryId: undefined,
          category: undefined,
        };
      }
      case ADD_REQUEST:
      case EDIT_REQUEST: {
        return { ...state, editLoading: true, editSuccess: false, editError: null };
      }
      case EDIT_SUCCESS: {
        return { ...state, editLoading: false, editSuccess: true, categoryId: action.payload };
      }
      case EDIT_FAIL: {
        return { ...state, editLoading: false, editError: action.payload };
      }

      case SET_POSITIONS_REQUEST: {
        return {
          ...state,
          loading: true,
        };
      }
      case SET_POSITIONS_SUCCESS: {
        return {
          ...state,
          categories: action.payload,
          loading: false,
        };
      }
      case SET_POSITIONS_FAIL: {
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      }

      case CLEAR_DELETE: {
        return {
          ...state,
          deleteLoading: false,
          deleteSuccess: false,
          deleteError: null,
        };
      }
      case DELETE_REQUEST: {
        return {
          ...state,
          deleteLoading: true,
          deleteSuccess: false,
          deleteError: null,
        };
      }
      case DELETE_SUCCESS: {
        return {
          ...state,
          deleteLoading: false,
          deleteSuccess: true,
          deleteError: null,
        };
      }
      case DELETE_FAIL: {
        return {
          ...state,
          deleteLoading: false,
          deleteSuccess: false,
          deleteError: action.payload,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  clearRootRequest: () => createAction(CLEAR_ROOT_REQUEST),
  fetchRootRequest: (payload: {
    page: number;
    perPage: number;
    parentId?: number;
    text?: string;
  }) => createAction(FETCH_ROOT_REQUEST, payload),
  fetchRootSuccess: (payload: IServerResponse<ICategoryItem[]>) =>
    createAction(FETCH_ROOT_SUCCESS, payload),
  fetchRootFail: (payload: string) => createAction(FETCH_ROOT_FAIL, payload),

  clearParentRequest: () => createAction(CLEAR_PARENT_REQUEST),
  fetchParentRequest: (payload: { parentId: number }) =>
    createAction(FETCH_PARENT_REQUEST, payload),
  fetchParentSuccess: (payload: IServerResponse<ICategoryItem>) =>
    createAction(FETCH_PARENT_SUCCESS, payload),
  fetchParentFail: (payload: string) => createAction(FETCH_PARENT_FAIL, payload),

  clearFullRequest: () => createAction(CLEAR_FULL_REQUEST),
  fetchFullRequest: () => createAction(FETCH_FULL_REQUEST),
  fetchFullSuccess: (payload: IServerResponse<ICategoryItem[]>) =>
    createAction(FETCH_FULL_SUCCESS, payload),
  fetchFullFail: (payload: string) => createAction(FETCH_FULL_FAIL, payload),

  fetchByIdRequest: (payload: number) => createAction(FETCH_BY_ID_REQUEST, payload),
  fetchByIdSuccess: (payload: IServerResponse<ICategoryItem>) =>
    createAction(FETCH_BY_ID_SUCCESS, payload),
  fetchByIdFail: (payload: string) => createAction(FETCH_BY_ID_FAIL, payload),
  setProductsCategory: (payload: { category: ICategoryItem; products: IProduct[] }[]) =>
    createAction(SET_PRODUCTS_CATEGORY, payload),

  setPositionsRequest: (payload: { positions: string; categories: ICategoryItem[] }) =>
    createAction(SET_POSITIONS_REQUEST, payload),
  setPositionsSuccess: (payload: ICategoryItem[]) =>
    createAction(SET_POSITIONS_SUCCESS, payload),
  setPositionsFail: (payload: string) => createAction(SET_POSITIONS_FAIL, payload),

  clearEdit: () => createAction(CLEAR_EDIT),
  addRequest: (payload: { data: ICategoryItem }) => createAction(ADD_REQUEST, payload),
  editRequest: (payload: { id: number; data: ICategoryItem }) =>
    createAction(EDIT_REQUEST, payload),
  editSuccess: (payload?: number) => createAction(EDIT_SUCCESS, payload),
  editFail: (payload: string) => createAction(EDIT_FAIL, payload),

  clearDelete: () => createAction(CLEAR_DELETE),
  deleteRequest: (payload: { id: number | undefined }) =>
    createAction(DELETE_REQUEST, payload),
  deleteSuccess: () => createAction(DELETE_SUCCESS),
  deleteFail: (payload: string) => createAction(DELETE_FAIL, payload),

  clearCatalogCategories: () => createAction(CLEAR_CATALOG_CATEGORIES),
  // fetchCatalogCategories: () => createAction(FETCH_CATALOG_CATEGORIES_REQUEST),
  fetchCatalogCategories: (payload: { with_products?: boolean; company_id?: number }) =>
    createAction(FETCH_CATALOG_CATEGORIES_REQUEST, payload),
  errorCatalogCategories: (payload: string) =>
    createAction(FETCH_CATALOG_CATEGORIES_FAIL, payload),
  successCatalogCategories: (payload: IServerResponse<ICategoryItem[]>) =>
    createAction(FETCH_CATALOG_CATEGORIES_SUCCESS, payload),
};

export type TActions = ActionsUnion<typeof actions>;

function* fetchRootSaga({
  payload,
}: {
  payload: { page: number; perPage: number; parentId?: number; text?: string };
}) {
  try {
    const { data }: { data: IServerResponse<ICategoryItem[]> } = yield call(() =>
      getRootCategories({
        page: payload.page,
        per_page: payload.perPage,
        parentId: payload.parentId,
        text: payload.text,
      })
    );
    yield put(actions.fetchRootSuccess(data));
  } catch (e) {
    yield put(actions.fetchRootFail(e && e.response.data.message));
  }
}

function* fetchParentSaga({ payload }: { payload: { parentId: number } }) {
  try {
    const { data }: { data: IServerResponse<ICategoryItem> } = yield call(() =>
      getCategoriesWithParent({
        id: payload.parentId,
      })
    );
    yield put(actions.fetchParentSuccess(data));
  } catch (e) {
    yield put(actions.fetchParentFail(getResponseMessage(e)));
  }
}

function* fetchFullSaga() {
  try {
    const { data }: { data: IServerResponse<ICategoryItem[]> } = yield call(() =>
      getFullCategories({})
    );
    yield put(actions.fetchFullSuccess(data));
  } catch (e) {
    yield put(actions.fetchFullFail(getResponseMessage(e)));
  }
}

function* fetchByIdSaga({ payload }: { payload: number }) {
  try {
    const { data }: { data: IServerResponse<ICategoryItem> } = yield call(() =>
      getCategoryById(payload)
    );
    yield put(actions.fetchByIdSuccess(data));
  } catch (e) {
    yield put(actions.fetchByIdFail(getResponseMessage(e)));
  }
}

function* addSaga({ payload }: { payload: { data: ICategoryItem } }) {
  try {
    const { data }: { data: IServerResponse<ICategoryItem> } = yield call(() =>
      createCategory(payload.data)
    );
    yield put(actions.editSuccess(data.data.id));
    yield put(actions.fetchCatalogCategories({}));
  } catch (e) {
    yield put(actions.editFail(getResponseMessage(e)));
  }
}

function* editSaga({ payload }: { payload: { id: number; data: ICategoryItem } }) {
  try {
    yield call(() => editCategory(payload.id, payload.data));
    yield put(actions.editSuccess());
    yield put(actions.fetchCatalogCategories({}));
  } catch (e) {
    yield put(actions.editFail(getResponseMessage(e)));
  }
}

function* deleteSaga({ payload }: { payload: { id: number | undefined } }) {
  try {
    yield call(() => deleteCategory(payload.id));
    yield put(actions.deleteSuccess());
    yield put(actions.fetchCatalogCategories({}));
  } catch (e) {
    yield put(actions.deleteFail(getResponseMessage(e)));
  }
}

function* setPositionsSaga({
  payload,
}: {
  payload: { positions: string; categories: ICategoryItem[] };
}) {
  try {
    yield call(() => setCategoriesPositions(payload.positions));
    yield put(actions.setPositionsSuccess(payload.categories));
    yield put(actions.fetchCatalogCategories({}));
  } catch (e) {
    yield put(actions.setPositionsFail(getResponseMessage(e)));
  }
}

function* fetchCatalogSaga({
  payload,
}: {
  payload: { with_products?: boolean; company_id?: number };
}) {
  try {
    const { data }: { data: IServerResponse<ICategoryItem[]> } = yield call(
      () => getFullCategories(payload)
      // {
      //   only_with_products: 1,
      //   only_active: 1,
      // }
    );
    yield put(actions.successCatalogCategories(data));
  } catch (e) {
    yield put(
      actions.errorCatalogCategories((e && e?.response?.data?.message) || 'Network error')
    );
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.fetchRootRequest>>(
    FETCH_ROOT_REQUEST,
    fetchRootSaga
  );
  yield takeLatest<ReturnType<typeof actions.fetchParentRequest>>(
    FETCH_PARENT_REQUEST,
    fetchParentSaga
  );
  yield takeLatest<ReturnType<typeof actions.fetchByIdRequest>>(
    FETCH_BY_ID_REQUEST,
    fetchByIdSaga
  );
  yield takeLatest<ReturnType<typeof actions.addRequest>>(ADD_REQUEST, addSaga);
  yield takeLatest<ReturnType<typeof actions.editRequest>>(EDIT_REQUEST, editSaga);
  yield takeLatest<ReturnType<typeof actions.deleteRequest>>(DELETE_REQUEST, deleteSaga);
  yield takeLatest<ReturnType<typeof actions.fetchFullRequest>>(
    FETCH_FULL_REQUEST,
    fetchFullSaga
  );
  yield takeLatest<ReturnType<typeof actions.setPositionsRequest>>(
    SET_POSITIONS_REQUEST,
    setPositionsSaga
  );
  yield takeLatest<ReturnType<typeof actions.fetchCatalogCategories>>(
    FETCH_CATALOG_CATEGORIES_REQUEST,
    fetchCatalogSaga
  );
}
