import React, { useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

const TariffSubPayForm = ({ createSubscription, name }) => {
  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [processing, setProcessing] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const cardStyle = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#32325d',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  const handleChange = async event => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setProcessing(true);
    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name,
      },
    });

    if (error) {
      console.log('create payment method error: ', error);
      return;
    }

    setProcessing(false);

    createSubscription(paymentMethod.id);
  };

  return (
    <div className='pay-container'>
      <form id='payment-form' onSubmit={handleSubmit}>
        <CardElement id='card-element' options={cardStyle} onChange={handleChange} />

        <button type='button' disabled={processing || disabled} id='submit'>
          <span id='button-text'>
            {processing ? <div className='spinner' id='spinner' /> : 'Subscribe now'}
          </span>
        </button>

        {error && (
          <div className='card-error' role='alert'>
            {error}
          </div>
        )}
      </form>
    </div>
  );
};

export default TariffSubPayForm;
