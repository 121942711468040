import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ProductCatalog from './ProductCatalog';

export default function MainPage() {
  return (
    <Routes>
      <Route path='catalog' element={<ProductCatalog />} />
      <Route path='catalog/:isSearch' element={<ProductCatalog />} />
    </Routes>
  );
}
