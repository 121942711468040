import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useFormatMessage } from '../../../../hooks';
import { getResponseMessage } from '../../../../utils/utils';

export const useAttachmentsTypes: any = (allowFetch: boolean = false) => {
  const fm = useFormatMessage();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState<any>(null);
  const [data, setData] = useState<any>(null);

  const reFetch = useCallback(() => {
    setLoading(true);
    axios
      .get(`/api/shop/attachment/types`)
      .then((res: any) => setData(res.data.data))
      .catch(e => {
        setData(null);
        enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
        setErr(null);
      });
  }, []);

  useEffect(() => {
    if (allowFetch) reFetch();
  }, []);

  return { data, loading, err, reFetch };
};
