import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { Paper } from '@mui/material';
import { useSnackbar } from 'notistack';

import Preloader from '../../../components/other/Preloader/Preloader';
import { IAppState } from '../../../store/rootDuck';
import { actions as categoriesActions } from '../../../store/ducks/categories.duck';
import { actions as requestActions } from '../../../store/ducks/request.duck';
import { setLayoutSubheader } from '../../../utils/layout';
import useHomeStyles from '../../../constants/homeStyles';
import { actions as profileActions } from '../../../store/ducks/profile.duck';
import { UploadImages } from '../../../components/ui/UploadImages';
import { useFormatMessage, useDefineUserRole } from '../../../hooks';
import { UploadImagesOffline } from '../../../components/ui/UploadImagesOffline';
import { RequestForm } from './components/RequestForm';
import { useFormikRequestPage } from './hooks/useFormikRequestPage';
import homeStyles from '../homeStyles';
import { useCreateRequest } from './hooks/useCreateRequest';
import { useUploadFiles } from './hooks/useUploadFiles';
import { useDeleteFile } from './hooks/useDeleteFile';
import { useDeleteRequest } from './hooks/useDeleteRequest';
import { IPhoto } from '../../../interfaces/photo';

const RequestPage: React.FC<TPropsFromRedux> = ({
  fetch,
  fetchCategories,
  categories,
  me,
  loadingMe,
  fetchMe,
  loading,
  clearMe,
  editLoading,
  clearEdit,
  editSuccess,
  request,
  editRequest,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const homeClasses = homeStyles();
  const styles = useHomeStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  const fm = useFormatMessage();

  const [images, setImages] = useState([]);
  const { createFetch, createLoading, createId } = useCreateRequest();
  const [uploadFiles, loadingUpload, successUpload] = useUploadFiles(id);
  const { deleteFileFetch } = useDeleteFile();
  const [deleteRequest, loadingDeleteRequest, successDelete] = useDeleteRequest();
  const isRoleVendor = useDefineUserRole(me, ['ROLE_VENDOR', 'ROLE_VENDOR_STAFF']);
  const isBuyer = useDefineUserRole(me, ['ROLE_BUYER', 'ROLE_BUYER_STAFF']);

  useEffect(() => {
    if (createId && !id && images.length > 0) {
      uploadFiles(createId, images);
    }
    if (createId) {
      navigate(-1);
    }
  }, [createId, id]);

  useEffect(() => {
    if (successUpload && !id) {
      navigate(-1);
    }
  }, [successUpload]);

  useEffect(() => {
    if (id) {
      fetch(Number(id));
    }
  }, [id, fetch]);

  useEffect(() => {
    if (editSuccess) {
      enqueueSnackbar(fm('SEARCH.EDIT.SUCCESS'), {
        variant: 'success',
      });
    }
    (successDelete || editSuccess) && navigate(-1);
  }, [editSuccess, successDelete]);

  const handleDeleteRequest = useCallback(() => {
    id && deleteRequest(id);
  }, [id]);

  const uploadImages = useCallback(
    (files: File[]) => {
      request && uploadFiles(request.id, files);
    },
    [request]
  );

  setLayoutSubheader({
    title: isRoleVendor || isBuyer ? 'Создать заявку' : fm('REQUEST.EDIT.TITLE'),
    breadcrumb: [
      {
        title: fm('REQUEST.TITLE'),
        root: true,
        page: 'requests/list',
        translate: 'REQUEST.TITLE',
      },
    ],
  });

  useEffect(() => {
    fetchMe();
    fetchCategories();
    return () => {
      clearEdit();
    };
  }, []);

  useEffect(() => {
    return () => {
      clearMe();
    };
  }, []);

  const { formik, setDescEditorState } = useFormikRequestPage(
    createFetch,
    editRequest,
    request,
    categories,
    images
  );

  if (!me || loadingMe || loading || loadingDeleteRequest) return <Preloader />;

  return (
    <Paper className={styles.classes.container} style={{ marginTop: 0 }}>
      <div style={{ display: 'contents' }}>
        <div
          className={homeClasses.classes.form}
          style={{ paddingTop: 0, maxWidth: 728, marginTop: 20 }}
        >
          {id ? (
            <UploadImages
              images={request?.photos as IPhoto[]}
              uploadImages={files => uploadImages(files)}
              withGallery
              deleteImage={deleteFileFetch}
              containerStyle={{ padding: 0 }}
              avaliableNumberOfFilesToUpload={request?.photos && 10 - request.photos.length}
            />
          ) : (
            <UploadImagesOffline
              images={images as IPhoto[]}
              setImages={setImages}
              avaliableNumberOfFilesToUpload={10 - images.length}
              withGallery
              disableMain
              containerStyle={{ padding: 0 }}
            />
          )}
          <RequestForm
            id={id}
            text={request?.text}
            setDescEditorState={setDescEditorState}
            formik={formik}
            editLoading={editLoading || createLoading || loadingUpload}
            categories={categories}
            handleDeleteRequest={handleDeleteRequest}
          />
        </div>
      </div>
    </Paper>
  );
};

const connector = connect(
  (state: IAppState) => ({
    me: state.profile.me,
    loadingMe: state.profile.loading,
    categories: state.categories.categories,
    request: state.request.request,
    loading: state.request.loadingById,
    editLoading: state.request.editLoading,
    editSuccess: state.request.editSuccess,
  }),
  {
    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,
    fetchCategories: categoriesActions.fetchFullRequest,
    editRequest: requestActions.editRequest,
    clearEdit: requestActions.clearEdit,
    fetch: requestActions.fetchByIdRequest,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RequestPage);
