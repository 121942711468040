import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { addMultiplyProduct } from '../../../../crud/product.crud';
import { getResponseMessage } from '../../../../utils/utils';
import { useFormatMessage } from '../../../../hooks';

export interface IAddMultiplyProducts {
  category_id?: string;
  product_type_id?: string;
  company_id?: number | string | null;
  companyId?: number | string | null;
  address?: string;
  lat?: number;
  lng?: number;
  addressName?: string;
  items: {
    name: string;
    description: string;
    selling_price: number;
    price: number;
    stock: number;
    delivery_type: string;
    activity: number;
    parameters: any;
  }[];
}

export const useCreateMultiplyProducts = (successFunc?: () => void) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loadingCreate, setLoading] = useState(false);
  const [successCreate, setSuccess] = useState(false);
  const fm = useFormatMessage();

  const fetchCreate = useCallback(
    async (body: IAddMultiplyProducts) => {
      setLoading(true);
      addMultiplyProduct(body)
        .then(() => {
          enqueueSnackbar(fm('PRODUCTS.SUCCESS.CREATE'), { variant: 'success' });
          successFunc && successFunc();
          setSuccess(true);
        })
        .catch(e => {
          enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`, { variant: 'error' });
        })
        .finally(() => {
          setSuccess(false);
          setLoading(false);
        });
    },
    [successFunc]
  );

  return { fetchCreate, loadingCreate, successCreate };
};
