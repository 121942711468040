import React from 'react';
import { SimpleInfoLine } from '../../../../../components/ui/SimpleInfoLine';
import { useFormatMessage } from '../../../../../hooks';
import { IUser } from '../../../../../interfaces/user';

interface IProps {
  data: IUser['loyalty_level'];
}

export const UserDiscountsStats: React.FC<IProps> = ({ data }) => {
  const fm = useFormatMessage();

  if (!data) {
    data = { orders_summ: 0, discount_percent: 0 };
  }

  return (
    <div style={{ maxWidth: 800, margin: 'auto', padding: 24 }}>
      <SimpleInfoLine keyName={fm('amout_of_orders_for_the_year')} value={data.orders_summ} />
      <SimpleInfoLine keyName={fm('personal_discount')} value={`${data.discount_percent}%`} />
    </div>
  );
};
