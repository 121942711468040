import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { editPromocodeSettings } from '../../../../crud/promocodes.crud';
import { getResponseMessage } from '../../../../utils/utils';

export const useEditPromocodeSettings: any = () => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const fetchStat = useCallback(async (data: { amount: number; bonus: number }) => {
    setLoading(true);
    editPromocodeSettings(data)
      .then(() => {
        enqueueSnackbar(`${intl.formatMessage({ id: 'PROMOCODE.SETTINGS.SUCCESS' })}`, {
          variant: 'success',
        });
      })
      .catch(e => {
        enqueueSnackbar(`${intl.formatMessage({ id: 'ERROR' })}: ${getResponseMessage(e)}`, {
          variant: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return [fetchStat, loading];
};
