import React from 'react';
import { Card, Grid, IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';

import { useStyles } from '../hooks/useStyles';
import { API_DOMAIN } from '../../../../constants';

interface IProps {
  image?: string;
  file?: File;
  deleteFile?: () => void;
  deleteImage?: () => void;
}

export const MainImg: React.FC<IProps> = ({ image, file, deleteFile, deleteImage }) => {
  const classes = useStyles();

  if (!image && !file) return <></>;

  return (
    <Card className={classes.card} style={{ marginBottom: 20 }}>
      <img
        src={
          // eslint-disable-next-line no-nested-ternary
          file ? URL.createObjectURL(file) : image ? `${API_DOMAIN}/${image}` : undefined
        }
        alt='icon'
        className={classes.img}
      />
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        className={classes.galleryImgTitleBar}
      >
        {deleteFile && file && (
          <IconButton style={{ color: 'white' }} onClick={deleteFile}>
            <Delete />
          </IconButton>
        )}
        {deleteImage && image && (
          <IconButton style={{ color: 'white' }} onClick={deleteImage}>
            <Delete />
          </IconButton>
        )}
      </Grid>
    </Card>
  );
};
