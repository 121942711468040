import React from 'react';
import clsx from 'clsx';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface IProps {
  children: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  type?: 'button' | 'reset' | 'submit' | undefined;
  onPress?: () => {} | void;
  fullHeight?: boolean;
  marginLeft?: number;
  marginRight?: number;
}

const useStyles = makeStyles(() => ({
  isLoading: {
    paddingRight: '3.5rem!important',
  },
}));

const noop = () => {};

const ButtonWithLoader: React.FC<IProps> = ({
  children,
  type = 'submit',
  disabled = false,
  loading = false,
  onPress = noop,
  fullHeight,
  marginLeft,
  marginRight,
}) => {
  const classes = useStyles();

  return (
    <Button
      type={type}
      variant='contained'
      color='primary'
      disabled={disabled}
      onClick={onPress}
      style={{
        height: fullHeight ? '100%' : undefined,
        marginLeft: marginLeft || 0,
        marginRight: marginRight || 0,
      }}
      className={clsx({
        'btn btn-primary btn-elevate kt-login__btn-primary': true,
        'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading,
        [classes.isLoading]: loading,
      })}
    >
      {children}
    </Button>
  );
};

export default ButtonWithLoader;
