import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import Preloader from '../../../../components/ui/Preloader/Preloader';
import { IAppState } from '../../../../store/rootDuck';
import { useGetReviews } from '../hooks/useGetReviews';
import { ReviewsStyles } from '../hooks/ReviewsStyles';
import { useDefineUserRole, useFormatMessage } from '../../../../hooks';
import Rating from './Rating';
import { IProduct } from '../../../../interfaces/product';
import { TextType, Text } from '../../../../components/ui/Text';
import { Button } from '../../../../components/ui/Buttons';
import ModalReview from './ModalReview';

interface IProps {
  meAdd?: boolean;
  product: IProduct;
}

const ReviewComponent: React.FC<IProps> = ({ meAdd = false, product }) => {
  const classes = ReviewsStyles();
  const fm = useFormatMessage();
  const reviewsProduct = useSelector(
    ({ products: { reviewsProduct } }: IAppState) => reviewsProduct,
    shallowEqual
  );
  const me = useSelector(({ profile: { me } }: IAppState) => me, shallowEqual);
  const isAdmin = useDefineUserRole(me, 'ROLE_ADMIN');
  const isBuyer = useDefineUserRole(me, ['ROLE_BUYER', 'ROLE_BUYER_STAFF']);
  const productId = useMemo(() => product.id, [product]);
  const [open, setOpen] = useState(false);

  const [fetchReviews, loading] = useGetReviews();

  useEffect(() => {
    productId && fetchReviews(productId, 20, 1);
  }, [productId, fetchReviews]);

  if (loading) return <Preloader />;

  return (
    <div className={classes.reviews}>
      <div className={classes.statistics}>
        <div className={classes.statisticsHeader}>
          <Rating rating={reviewsProduct?.rating} />

          <Text style={{ fontSize: 17, marginBottom: -10 }} type={TextType.boldH3}>
            {reviewsProduct?.rating || 0}/5
          </Text>
        </div>

        {reviewsProduct && (
          <div className={classes.statisticsList}>
            {Array(5)
              .fill('')
              .map((_, index) => {
                const length =
                  reviewsProduct.reviews.filter(item => item.rating === 5 - index).length || 0;
                const percent = (length * 100) / reviewsProduct.reviews.length || 0;

                return (
                  <div key={index} className={classes.statisticsItem}>
                    <Typography className={classes.ratingInfoText}>{5 - index} зв.</Typography>
                    <div className='progress'>
                      <span style={{ width: `${percent}%` }} />
                    </div>
                    <Typography className={classes.ratingInfoText}>{length}</Typography>
                  </div>
                );
              })}
          </div>
        )}
      </div>

      {(isBuyer || isAdmin) && !meAdd && (
        <div className={classes.feedButton}>
          <Button onClick={() => setOpen(true)}>{fm('LEAVE.REVIEW')}</Button>
        </div>
      )}

      <ModalReview
        open={open}
        handleClose={() => setOpen(false)}
        product={product}
        setOpen={setOpen}
      />
    </div>
  );
};

export default ReviewComponent;
