import React from 'react';
import { IconButton, InputBase, Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import { useFormatMessage } from '../../../../../hooks';
import { getRoleByValueTab } from '../utils';

interface TProps {
  getChats: (
    page: number,
    perPage: number,
    archived?: boolean,
    user_role?: string,
    user_name?: string
  ) => void;
  valueRole: number;
  userName: string;
  setUserName: React.Dispatch<React.SetStateAction<string>>;
}

const SearchField: React.FC<TProps> = ({ getChats, valueRole, userName, setUserName }) => {
  const fm = useFormatMessage();
  return (
    <Paper
      component='form'
      sx={{
        p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        marginTop: '10px',
      }}
      onSubmit={e => {
        e.preventDefault();
        getChats(1, 30, undefined, getRoleByValueTab(valueRole), userName);
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={fm('INPUT.USERNAME')}
        value={userName}
        onChange={e => setUserName(e.target.value)}
      />
      {userName.trim() && (
        <IconButton
          sx={{ p: '10px' }}
          aria-label='search'
          onClick={() => {
            setUserName('');
            getChats(1, 30, undefined, getRoleByValueTab(valueRole), '');
          }}
        >
          <ClearIcon />
        </IconButton>
      )}
      <IconButton
        type='button'
        sx={{ p: '10px' }}
        aria-label='search'
        onClick={() => getChats(1, 30, undefined, getRoleByValueTab(valueRole), userName)}
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};

export default React.memo(SearchField);
