import React, { useEffect, useCallback, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { useFormatMessage } from '../../../../../hooks';
import { IAppState } from '../../../../../store/rootDuck';
import { ICategoryItem } from '../../../categories/interfaces';
import { ReactComponent as AllSvg } from '../../../../../../_base/layout/assets/layout-svg-icons/all.svg';
import { ITypeParameter } from '../../../../../interfaces/productType';
import { useStylesHeaderAddMultiply } from '../../hooks/useStyles';
import { ButtonWithLoader } from '../../../../../components/ui/Buttons';
import { ButtonTheme } from '../../../../../components/ui/Buttons/ButtonWithLoader';
import CommonParams from './CommonParams';
import EditParams from './EditParams';
import TypeParams from './TypeParams';

const levelsSubCategories = [1, 2, 3, 4, 5];

type TProps = {
  formik: any;
  filteredParams: ITypeParameter[];
  countries: any;
  openModalCompanies: () => void;
  close: () => void;
};

const HeaderAddMultiply: React.FC<TProps> = ({
  formik,
  filteredParams,
  countries,
  openModalCompanies,
  close,
}) => {
  const fm = useFormatMessage();
  const classes1 = useStylesHeaderAddMultiply();
  const {classes} = classes1
  const [subCategories, setSubCategories] = useState<ICategoryItem[][]>([]);
  const [show, setShow] = useState(true);

  const { categories } = useSelector(({ categories: { categories } }: IAppState) => ({
    categories,
  }));

  const { paramsLoading } = useSelector(
    ({ productTypes: { paramsLoading } }: IAppState) => ({
      paramsLoading,
    }),
    shallowEqual
  );

  const { values, setFieldValue, setValues } = formik;

  const onSaveParams = useCallback(() => {
    const newValues = { ...values };
    newValues.items_len = values.items;
    newValues.filteredParams = filteredParams;
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i <= values.items; i++) {
      newValues[`name_${i}`] = values.name;
      newValues[`description_${i}`] = values.description;
      newValues[`price_${i}`] = values.price;
      // newValues[`vendor_price_${i}`] = values.vendor_price;
      newValues[`stockQuantity_${i}`] = values.stockQuantity;
      newValues[`deliveryType_${i}`] = values.deliveryType;
      newValues[`active_${i}`] = values.active;
      if (!paramsLoading && filteredParams.length > 0) {
        filteredParams.forEach(item => {
          newValues[`parameter${item.id}_${i}`] = values[`parameter${item.id}`];
        });
      }
      setValues(newValues);
    }
  }, [values, filteredParams, paramsLoading]);

  useEffect(() => {
    if (values.mainCategoryId && categories) {
      const newCategories = categories.find(
        item => item.id?.toString() === values.mainCategoryId!.toString()
      );
      if (newCategories && newCategories.children) {
        if (
          subCategories[1] &&
          subCategories[1].length > 0 &&
          subCategories[1][0]?.id !== newCategories.children[0]?.id
        ) {
          levelsSubCategories.forEach(level => setFieldValue(`categoryId_${level}`, ''));
        }
        const subCategoriesLvl1 = newCategories.children;
        // @ts-ignore
        const subCategoriesLvl2: ICategoryItem | undefined =
          values.categoryId_1 &&
          subCategoriesLvl1?.length &&
          newCategories.children.find(
            item => item.id?.toString() === values.categoryId_1?.toString()
          );
        // @ts-ignore
        const subCategoriesLvl3: ICategoryItem | undefined =
          values.categoryId_2 &&
          subCategoriesLvl2?.children?.length &&
          subCategoriesLvl2.children.find(
            item => item.id?.toString() === values.categoryId_2?.toString()
          );
        // @ts-ignore
        const subCategoriesLvl4: ICategoryItem | undefined =
          values.categoryId_3 &&
          subCategoriesLvl3?.children?.length &&
          subCategoriesLvl3.children.find(
            item => item.id?.toString() === values.categoryId_3?.toString()
          );
        // @ts-ignore
        const subCategoriesLvl5: ICategoryItem | undefined =
          values.categoryId_4 &&
          subCategoriesLvl4?.children?.length &&
          subCategoriesLvl4.children.find(
            item => item.id?.toString() === values.categoryId_4?.toString()
          );
        if (subCategoriesLvl1.length === 0) {
          setFieldValue(`categoryId_1`, '');
          setFieldValue(`categoryId_2`, '');
          setFieldValue(`categoryId_3`, '');
          setFieldValue(`categoryId_4`, '');
          setFieldValue(`categoryId_5`, '');
        }
        if (subCategoriesLvl2?.children?.length === 0) {
          setFieldValue(`categoryId_2`, '');
          setFieldValue(`categoryId_3`, '');
          setFieldValue(`categoryId_4`, '');
          setFieldValue(`categoryId_5`, '');
        }
        if (subCategoriesLvl3?.children?.length === 0) {
          setFieldValue(`categoryId_3`, '');
          setFieldValue(`categoryId_4`, '');
          setFieldValue(`categoryId_5`, '');
        }
        if (subCategoriesLvl4?.children?.length === 0) {
          setFieldValue(`categoryId_4`, '');
          setFieldValue(`categoryId_5`, '');
        }
        if (subCategoriesLvl5?.children?.length === 0) {
          setFieldValue(`categoryId_5`, '');
        }
        setSubCategories(state => {
          const newArr = [...state];
          newArr[1] = subCategoriesLvl1.length ? subCategoriesLvl1 : [];
          newArr[2] = subCategoriesLvl2?.children?.length ? subCategoriesLvl2?.children : [];
          newArr[3] = subCategoriesLvl3?.children?.length ? subCategoriesLvl3?.children : [];
          newArr[4] = subCategoriesLvl4?.children?.length ? subCategoriesLvl4?.children : [];
          newArr[5] = subCategoriesLvl5?.children?.length ? subCategoriesLvl5?.children : [];
          return newArr;
        });
      } else {
        setSubCategories([]);
      }
    } else {
      setSubCategories([]);
    }
  }, [
    categories,
    values.mainCategoryId,
    values.categoryId_1,
    values.categoryId_2,
    values.categoryId_3,
    values.categoryId_4,
    values.categoryId_5,
  ]);

  return (
    <>
      <div className={classes.container}>
        {show && (
          <>
            <CommonParams
              formik={formik}
              openModalCompanies={openModalCompanies}
              subCategories={subCategories}
            />
            <EditParams formik={formik} />

            <TypeParams
              filteredParams={filteredParams}
              formik={formik}
              countries={countries}
            />
          </>
        )}
        <div className={classes.btns}>
          <div>
            <ButtonWithLoader theme={ButtonTheme.PRIMARY} margin onPress={close}>
              {fm('AUTH.BUTTON.BACK')}
            </ButtonWithLoader>
            {show && (
              <ButtonWithLoader
                startIcon={<AllSvg />}
                theme={ButtonTheme.SECONDARY}
                margin
                onPress={onSaveParams}
              >
                {`${fm('PRODUCT.ADD.MULTIPLY.PRODUCTS.SAVE')} (${values.items})`}
              </ButtonWithLoader>
            )}
          </div>
          <ButtonWithLoader
            theme={ButtonTheme.SECONDARY}
            margin
            onPress={() => setShow(!show)}
          >
            {fm(show ? 'PRODUCT.HIDE' : 'SHOW')}
          </ButtonWithLoader>
        </div>
      </div>
    </>
  );
};

export default React.memo(HeaderAddMultiply);
