import React, { FC, useMemo } from 'react';
import { CardActions, Card, CardMedia, Typography, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { thousands } from '../../../../../utils/utils';
import { IProduct } from '../../../../../interfaces/product';
import { useDefineUserRole, useFormatMessage } from '../../../../../hooks';
import { IUser } from '../../../../../interfaces/user';
import { getProductImage } from '../../utils/getProductImage';
import { IAppState } from '../../../../../store/rootDuck';
import { ICart, IGuestCart } from '../../../cart/interfaces';
import { Button } from '../../../../../components/ui/Buttons';
import { useStyles } from './useStyles';

interface IProductsItemProps {
  product: IProduct;
  viewAction: any;
  editAction?: any;
  handleDeleteDialog?: any;
  isAuthorized: boolean;
  isAdmin: boolean;
  me: IUser | undefined;
  addProductLoading: boolean;
  handleCartDialog: any;
  setProductCountLoading: boolean;
  handleSetCountCart: (type: 'dec' | 'inc', count: number, product: IProduct) => void;
  guestCart: IGuestCart | null;
  cart: ICart | null;
}

const ProductsListItem: FC<IProductsItemProps> = ({
  product,
  viewAction,
  editAction,
  handleDeleteDialog,
  isAdmin,
  me,
  addProductLoading,
  handleCartDialog,
  setProductCountLoading,
  guestCart,
  handleSetCountCart,
  cart,
}) => {
  const fm = useFormatMessage();
  const classes1 = useStyles();
  const {classes} = classes1
  const isBuyer = useDefineUserRole(me, ['ROLE_BUYER', 'ROLE_BUYER_STAFF']);
  const isManager = useDefineUserRole(me, 'ROLE_MANAGER');
  const isAuthorized = useSelector((state: IAppState) => state.auth.user != null);

  // eslint-disable-next-line prefer-const
  let productCount = useMemo(() => {
    if (true && product && cart) {
      const item = cart.items.find(item => item.product.id === product.id);
      if (item) {
        return item.count;
      }
    }
    return 0;
  }, [isAuthorized, product, guestCart?.goods_num, cart?.goods_num, guestCart, cart]);

  const photoProduct = getProductImage(product);

  return (
    <>
      <div className={classes.container}>
        <Card
          key={product.id}
          className={classes.productCard}
          onClick={() => viewAction(product)}
        >
          <div style={{ height: '100%' }}>
            <Box className={classes.box}>
              {photoProduct ? (
                <CardMedia
                  style={{
                    height: 235,
                    minHeight: '100%',
                    objectFit: 'contain',
                    width: 'min-content',
                    aspectRatio: '3/4',
                    // opacity: !getStock(product) || (getStock(product) || 0) < 0 ? 0.1 : 1,
                  }}
                  title={product.name}
                  src={photoProduct}
                  component='img'
                  // onError={(e: any) => {
                  //   e.target.src = toAbsoluteUrl('/images/placeholder.png');
                  // }}
                />
              ) : (
                <div
                  style={{
                    height: 235,
                    minHeight: '100%',
                    width: 120,
                    // opacity: !getStock(product) || (getStock(product) || 0) < 0 ? 0.1 : 1,
                  }}
                />
              )}
              <div className={classes.containerInfo}>
                <Box>
                  <Typography gutterBottom className={classes.infoName}>
                    {product.name}
                  </Typography>
                </Box>
                <Box className={classes.wrapInfoActions}>
                  <div className=''>
                    <Box className={classes.infoParameters}>
                      {product.rating && (
                        <Box
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 45,
                            height: 45,
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: 15,
                              fontWeight: 400,
                              color: '#000000',
                              zIndex: 100,
                            }}
                          >
                            {product.rating}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </div>

                  <div className={classes.actions}>
                    <CardActions
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: 0,
                      }}
                    >
                      <Box style={{ width: '100%' }}>
                        <div className={classes.priceBody}>
                          <p className={classes.priceTitle}>
                            ₽ {thousands(product.price.toString())}
                          </p>
                          {(isBuyer || !isAuthorized) && productCount > 0 && (
                            <div className={classes.addFav}>
                              <button
                                disabled={setProductCountLoading}
                                onClick={event => {
                                  event.stopPropagation();
                                  // eslint-disable-next-line no-plusplus
                                  handleSetCountCart('dec', --productCount!, product);
                                }}
                                className={classes.favBtn}
                                type='button'
                              >
                                -
                              </button>
                              <p className={classes.favText}>{productCount}</p>
                              <button
                                disabled={setProductCountLoading}
                                onClick={event => {
                                  event.stopPropagation();
                                  // eslint-disable-next-line no-plusplus
                                  handleSetCountCart('inc', ++productCount!, product);
                                }}
                                className={classes.favBtn}
                                type='button'
                              >
                                +
                              </button>
                            </div>
                          )}
                        </div>
                        {(isBuyer || !isAuthorized) && (
                          <button
                            disabled={
                              addProductLoading
                              // || !getStock(product) || (getStock(product) || 0) < 0
                            }
                            type='button'
                            onClick={event => {
                              event.stopPropagation();
                              handleCartDialog(product);
                            }}
                            color='primary'
                            className={classes.butCart}
                          >
                            <div
                              style={{
                                display: 'flex',
                                gap: 10,
                                alignItems: 'center',
                                fontWeight: 'bold',
                                fontSize: 20,
                                padding: '0 22px',
                                color: '#fff',
                              }}
                            >
                              {fm('PRODUCT.BUTTON.ADD_CART')}
                            </div>
                          </button>
                        )}
                        {(isAdmin || isManager) && (
                          <div>
                            {handleDeleteDialog && (
                              <Button
                                style={{
                                  width: '100%',
                                }}
                                color='secondary'
                                onClick={(event: any) => {
                                  event.stopPropagation();
                                  handleDeleteDialog(product);
                                }}
                              >
                                {fm('COMMON.BUTTON.DELETE')}
                              </Button>
                            )}

                            {editAction && (
                              <Button
                                style={{
                                  width: '100%',
                                }}
                                onClick={event => {
                                  event.stopPropagation();
                                  editAction(product);
                                }}
                              >
                                {fm('edit')}
                              </Button>
                            )}
                          </div>
                        )}
                      </Box>
                    </CardActions>
                  </div>
                </Box>
              </div>
            </Box>
          </div>
        </Card>
      </div>
    </>
  );
};
export default React.memo(ProductsListItem);
