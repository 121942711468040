import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { getChat } from '../../../../../crud/chat.crud';
import ChatSocket from '../../../../../utils/socket';
import { TChannel } from '../../../../../interfaces/chat';
import { getResponseMessage } from '../../../../../utils/utils';

export const useCreateChat = () => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [loadingCreated, setLoading] = useState(false);
  const [createChat, setCreatedChat] = useState<TChannel | null>(null);

  const createChatFetch = useCallback(async (id: any) => {
    if (!ChatSocket.myAuthToken) await ChatSocket.getMyAuthToken();
    setLoading(true);
    getChat(id)
      .then(({ data }) => {
        if (id.companyId) {
          setCreatedChat(data.data);
        } else if (id.userId) {
          setCreatedChat(data.data);
        }
      })
      .catch(e => {
        enqueueSnackbar(`${intl.formatMessage({ id: 'ERROR' })}: ${getResponseMessage(e)}`, {
          variant: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // useEffect(() => {
  //   if (chats && createdChatCompany) {
  //     const channel = chats.find(
  //       (chat: TChannel) => chat.company?.id === createdChatCompany.id
  //     );
  //     channel && setCreatedChat(channel);
  //   }
  // }, [chats, createdChatCompany]);

  return { createChatFetch, loadingCreated, createChat };
};
