import React from 'react';
import { Routes, Route } from 'react-router-dom';
import DeliveryListPage from './DeliveryListPage';
import DeliveryPage from './DeliveryPage';

export default function DeliveriesPage() {
  return (
    <Routes>
      <Route path='list/:type' element={<DeliveryListPage />} />
      <Route path=':id/:mode' element={<DeliveryPage />} />
    </Routes>
  );
}
