import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { getResponseMessage } from '../../../../utils/utils';
import { editGroup } from '../../../../crud/productType.crud';
import { useFormatMessage } from '../../../../hooks';

export const useEditGroup: any = () => {
  const { enqueueSnackbar } = useSnackbar();
  const fm = useFormatMessage();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleEdit = useCallback(async (id: number, name: string) => {
    setLoading(true);
    editGroup(id, name)
      .then(() => {
        setSuccess(true);
        enqueueSnackbar(fm('GROUP.SAVED'), { variant: 'success' });
      })
      .catch(e => {
        enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`, { variant: 'error' });
      })
      .finally(() => {
        setSuccess(false);
        setLoading(false);
      });
  }, []);

  return [handleEdit, loading, success];
};
