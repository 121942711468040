import { IPhoto } from '../../../../interfaces/photo';
import { IProduct } from '../../../../interfaces/product';
import { ICategoryItem } from '../../categories/interfaces';

export interface IBanner {
  id: number;
  photo: IPhoto;
  title: string;
  category: ICategoryItem;
  description: string;
  product: IProduct;
  textColor: string;
  paymentStatus: string;
  url: string;
  placement: PlacementType;
}

export interface ICreateBanner {
  title: string;
  category?: ICategoryItem;
  product?: IProduct;
  description: string;
  photo?: IPhoto;
  url?: string;
  category_id?: number;
  product_id?: number;
  product_name?: string;
  placement: PlacementType;
}

export interface IEditBanner {
  title?: string;
  category?: ICategoryItem;
  product?: IProduct;
  description?: string;
  url?: string;
  product_name?: string;
  placement?: PlacementType;
}

export interface IBannerTreeTable {
  banners: Map<PlacementType, IBanner[]>;
  handleDeleteDialog: (id: number | undefined) => void;
  editAction: (item: IBanner) => void;
  pushAction: (placement: PlacementType) => void;
  productListAction: (item: IBanner) => void;
  selectProductId?: (id: number) => void;
  selectPlacement?: (placement: PlacementType) => void;
  setPositions?: ({ positions, banners }: { positions: string; banners: IBanner[] }) => void;
  classes: any;
  is_admin: boolean;
  is_manager: boolean;
}

export interface IBannerTableTooltip {
  edit: NonNullable<React.ReactNode>;
  add: NonNullable<React.ReactNode>;
  delete: NonNullable<React.ReactNode>;
  productList: NonNullable<React.ReactNode>;
}

export enum Placement {
  TOP = 'top',
  MID = 'mid',
  BOTTOM = 'bottom',
}

export type PlacementType = Placement.MID | Placement.TOP | Placement.BOTTOM;

export enum TypeContentBanner {
  LINK = 'link',
  PRODUCT = 'product',
}
