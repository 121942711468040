import React, { useMemo } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import { SHeadTableCell } from '../../../../components/styledComponents/Tables';
import homeStyles from '../../homeStyles';
import { thousands } from '../../../../utils/utils';
import { API_DOMAIN } from '../../../../constants';
import { useStylesSupplyItems } from '../hooks/useStyles';

const SupplyItems: React.FC<{
  supply: any;
  setProductCount: (data: { product_id: number; count: number }) => void;
  setProductCountLoading: boolean;
}> = ({ supply, setProductCount, setProductCountLoading }) => {
  const intl = useIntl();
  const classes = useStylesSupplyItems();
  const homeClasses = homeStyles();
  const company = useMemo(() => {
    return supply && supply[0].product?.company;
  }, []);

  return (
    <Card className={classes.card}>
      <CardContent className={homeClasses.classes.tableContainer}>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              <SHeadTableCell>
                {intl.formatMessage({ id: 'PRODUCT.TABLE.PREVIEW' })}
              </SHeadTableCell>
              <SHeadTableCell>
                {intl.formatMessage({ id: 'PRODUCT.TABLE.NAME' })}
              </SHeadTableCell>
              <SHeadTableCell>
                {intl.formatMessage({ id: 'PRODUCT.TABLE.COUNT' })}
              </SHeadTableCell>
              <SHeadTableCell align='center'>
                {intl.formatMessage({ id: 'PRODUCT.TABLE.PRICE' })}
              </SHeadTableCell>
              <SHeadTableCell align='center'>
                {intl.formatMessage({ id: 'PRODUCT.TABLE.WEIGHT' })}
              </SHeadTableCell>
              <SHeadTableCell align='center' />
            </TableRow>
          </TableHead>

          <TableBody>
            {supply &&
              supply.map((item: any) => {
                return (
                  <TableRow key={item.product.id}>
                    <TableCell>
                      {item.product.photos && item.product.photos.length > 0 && (
                        <img
                          alt=''
                          src={`${API_DOMAIN}/${
                            item?.product?.photos?.find((el: any) => el.main)?.path ||
                            item.product.photos[0].path
                          }`}
                          className={classes.img}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      <Link to={`/viewproduct/${item.product.id}`} className={classes.buttons}>
                        {item.product.name}
                      </Link>
                    </TableCell>

                    <TableCell width={50}>
                      <ButtonGroup color='primary' aria-label='outlined primary button group'>
                        <Button
                          disabled={setProductCountLoading}
                          onClick={() =>
                            setProductCount({
                              product_id: item.product.id || 0,
                              count: +item.amount - 1,
                            })
                          }
                        >
                          <RemoveIcon fontSize='small' />
                        </Button>
                        <Button
                          disabled
                          style={{
                            color: 'rgb(0, 90, 90)',
                            borderColor: 'rgb(0, 90, 90)',
                          }}
                        >
                          {item.amount}
                        </Button>
                        <Button
                          style={{ borderLeft: 'none', backgroundColor: '#D3D3D3' }}
                          disabled={setProductCountLoading}
                          onClick={() => {
                            setProductCount({
                              product_id: item.product.id || 0,
                              count: +item.amount + 1,
                            });
                          }}
                        >
                          <AddIcon fontSize='small' />
                        </Button>
                      </ButtonGroup>
                    </TableCell>

                    <TableCell align='center'>
                      {thousands(String(+item.product.price * item.amount))}
                    </TableCell>

                    <TableCell align='center'>
                      {item?.product?.stock_info?.weight && thousands(String(+item?.product?.stock_info?.weight * item.amount))}
                    </TableCell>

                    <TableCell align='right'>
                      <IconButton
                        color='secondary'
                        aria-label='previous'
                        onClick={() => {
                          setProductCount({
                            product_id: item.product.id || 0,
                            count: 0,
                          });
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {company && (
          <div className={classes.empty}>
            <Typography component='h6' variant='h6'>
              {`${intl.formatMessage({ id: 'ORDER.TABLE.COMPANY' })}: `}
              <Link to={`/viewcompany/${company.id}`} className={classes.buttons}>
                {company.name}
              </Link>
            </Typography>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default SupplyItems;
