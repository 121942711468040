import { useFormatMessage } from '../../../../hooks';

export const tariffOptions = () => {
  const fm = useFormatMessage();
  return [
    {
      text: fm('TARIFF.OPTIONS_1'),
      isPrime: false,
    },
    {
      text: fm('TARIFF.OPTIONS_2'),
      isPrime: false,
    },
    {
      text: fm('TARIFF.OPTIONS_3'),
      isPrime: false,
    },
    {
      text: fm('TARIFF.OPTIONS_4'),
      isPrime: false,
    },
    {
      text: fm('TARIFF.OPTIONS_5'),
      isPrime: false,
    },
    {
      text: fm('TARIFF.OPTIONS_6'),
      isPrime: false,
    },
    {
      text: fm('TARIFF.OPTIONS_7'),
      isPrime: true,
    },
    {
      text: fm('TARIFF.OPTIONS_8'),
      isPrime: true,
    },
    {
      text: fm('TARIFF.OPTIONS_9'),
      isPrime: true,
    },
    {
      text: fm('TARIFF.OPTIONS_10'),
      isPrime: true,
    },
  ];
};
