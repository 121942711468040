import { IDelivery } from '../../../../interfaces/delivery';

export const getInitialValuesDelivery = (delivery?: IDelivery) => {
  const values: any = {
    external_id: delivery?.external_id || '',
    external_created_at: delivery?.exported_at || '',
    exported_at: delivery?.external_created_at || '',
    comment: delivery?.comment || '',
  };
  return values;
};
