import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useCallback } from 'react';
import { useFormatMessage } from '../../../../../hooks';
import { IBanner, Placement, PlacementType } from '../../interfaces';

const getValidationScheme = () => {
  const fm = useFormatMessage();

  return Yup.object().shape({
    title: Yup.string().required(fm('AUTH.VALIDATION.REQUIRED_FIELD')),
    url: Yup.string()
      .ensure()
      .when('product_id', {
        is: undefined,
        then: Yup.string().required(fm('BANNER.VALIDATION.URL_OR_PRODUCT_REQUIRED')),
      }),
  });
};

const getInitValue = (data: Partial<IBanner> | null) => {
  return {
    title: data?.title || '',
    description: data?.description || '',
    url: data?.url || '',
    product_id: data?.product?.id || '',
    category_id: data?.product?.category?.id || '',
    product_name: data?.product?.name || '',
    placement: data?.placement || Placement.TOP,
  };
};

export const useCustomFormik = ({
  data,
  id,
  submit,
  image,
}: {
  id: string;
  data: Partial<IBanner>;
  submit: any;
  image: Blob | null;
}) => {
  const submitHandler = useCallback(
    (
      values: Partial<IBanner> & {
        product_id?: number | string;
        category_id?: number | string;
        product_name?: string;
        url?: string;
        placement?: PlacementType;
      }
    ) => {
      const d = new FormData();
      if (values.title) {
        d.append('title', values.title);
      }
      if (values.description) {
        d.append('description', values.description);
      }
      d.append('placement', values.placement || Placement.TOP);
      d.append('category_id', values.category_id?.toString() || '');

      d.append('product_id', values.product_id?.toString() || '');

      d.append('url', values.url || '');

      if (image) {
        d.append('photo', image);
      }

      submit({ id: +id, data: d });
    },
    [id, submit, image]
  );

  return useFormik({
    enableReinitialize: true,
    initialValues: getInitValue(data),
    validationSchema: getValidationScheme(),
    onSubmit: submitHandler,
  });
};
