import { useCallback } from 'react';
import ChatSocket from '../../../../../utils/socket';
import { useGetChats } from './useGetChats';
import { useGetMe } from './useGetMe';

export const useInit: any = () => {
  const [getMe] = useGetMe();
  const [getChats] = useGetChats();

  const fetch = useCallback(async () => {
    await ChatSocket.getMyAuthToken();
    getMe();
    getChats(1, 30);
    ChatSocket.connectToSocket();
  }, []);
  return [fetch];
};
