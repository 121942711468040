import { useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { deleteFile } from '../../../../crud/search.crud';
import { getResponseMessage } from '../../../../utils/utils';
import { actions as searchActions } from '../../../../store/ducks/search.duck';

export const useDeleteFile: any = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const fetch = useCallback(async (id: number) => {
    setLoading(true);
    deleteFile(id)
      .then((res: any) => {
        dispatch(searchActions.fetchByIdSuccess(res.data));
        enqueueSnackbar(`${intl.formatMessage({ id: 'SEARCH.FILE.DELETED' })}`, {
          variant: 'success',
        });
        setSuccess(true);
      })
      .catch(e => {
        enqueueSnackbar(`${intl.formatMessage({ id: 'ERROR' })}: ${getResponseMessage(e)}`, {
          variant: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
        setSuccess(false);
      });
  }, []);
  return [fetch, loading, success];
};
