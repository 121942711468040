import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { editParticipation } from '../../../../crud/orders.crud';
import { getResponseMessage } from '../../../../utils/utils';

export const useEditParticipation: any = (successFunc: () => void) => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState<any>(null);
  const [success, setSuccess] = useState(false);
  const fetch = useCallback(
    async (id: number, data: any, type: string) => {
      setLoading(true);
      editParticipation(id, data)
        .then(() => {
          setSuccess(true);
          enqueueSnackbar(
            `${intl.formatMessage({
              id:
                type === 'new' ? 'PARTICIPATION.SUCCESS.POST' : 'PARTICIPATION.SUCCESS.REVOKE',
            })}`,
            {
              variant: 'success',
            }
          );
          successFunc();
        })
        .catch(e => {
          enqueueSnackbar(`${intl.formatMessage({ id: 'ERROR' })}: ${getResponseMessage(e)}`, {
            variant: 'error',
          });
        })
        .finally(() => {
          setLoading(false);
          setErr(null);
          setSuccess(false);
        });
    },
    [successFunc]
  );
  return [fetch, loading, success, err];
};
