import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { getResponseMessage } from '../../../../utils/utils';
import { getShift } from '../../../../crud/shift.crud';
import { IShift } from '../../../../interfaces/shift';

export const useGetShift = () => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [shift, setShift] = useState<IShift | null>(null);
  const fetch = useCallback(async (id: number) => {
    setLoading(true);
    getShift(id)
      .then(res => {
        setShift(res.data.data);
      })
      .catch(e => {
        enqueueSnackbar(`${intl.formatMessage({ id: 'ERROR' })}: ${getResponseMessage(e)}`, {
          variant: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  return { fetch, loading, shift };
};
