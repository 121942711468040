import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { TextField, Typography, Theme } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Formik } from 'formik';

import { useIntl } from 'react-intl';

import ButtonWithLoader from '../../../../components/other/Buttons/ButtonWithLoader';

import { IAppState } from '../../../../store/rootDuck';
import { actions as cartActions } from '../../../../store/ducks/cart.duck';
import { actions as profileActions } from '../../../../store/ducks/profile.duck';
import { actions as promocodesActions } from '../../../../store/ducks/promocodes.duck';
import { actions as i18nActions } from '../../../../../_base/ducks/i18n';

import useCrudSnackbar from '../../../../hooks/useCrudSnackbar';
import { IUser, UserRoles } from '../../../../interfaces/user';

import homeStyles from '../../../../constants/homeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actions: {
      marginTop: theme.spacing(3),
    },
    lang: {
      display: 'flex',
      alignItems: 'center',
    },
    langImage: {
      width: '30px',
      height: '30px',
      borderRadius: '5px',
      overflow: 'hidden',
    },
    langTitle: {
      marginLeft: '5px',
    },
  })
);

interface IProps {
  me: IUser;
  isCart?: boolean;
}

const Bonuses: React.FC<TPropsFromRedux & IProps> = ({
  me,
  clearEdit,
  editSuccess,
  editLoading,
  editError,
  activate,
  edit,
  getOrCreateCart,
  userRoles,
  isAdmin,
  clearEditPromo,
  isCart,
}) => {
  const classes = useStyles();
  const homeClasses = homeStyles();
  const intl = useIntl();

  useCrudSnackbar({
    success: editSuccess,
    error: editError,
    clear: clearEdit,
    successMessage: intl.formatMessage({ id: 'PROMOCODE.ACTIVATION.SUCCESS' }),
    errorMessage: `${intl.formatMessage({ id: 'ERROR' })}: ${editError}`,
    afterSuccessOrError: () => {
      clearEditPromo();
    },
    afterSuccess: () => {
      edit({
        login: me.login,
        email: me.email,
        fio: me.fio,
      });
      if (
        isCart &&
        userRoles &&
        !userRoles.includes(UserRoles.ROLE_VENDOR) &&
        !userRoles.includes(UserRoles.ROLE_VENDOR_STAFF) &&
        !isAdmin
      ) {
        getOrCreateCart();
      }
      clearEditPromo();
    },
  });

  useEffect(() => {
    return () => {
      clearEditPromo();
    };
  }, []);

  return (
    <Formik
      initialValues={{
        promocode: '',
      }}
      onSubmit={values => {
        activate(values.promocode);
      }}
    >
      {({ values, errors, touched, handleBlur, handleSubmit, setFieldValue }) => (
        <form className={homeClasses.classes.form} onSubmit={handleSubmit}>
          <Typography>
            {intl.formatMessage({ id: 'USER.EDIT.BONUSES' })}: {me!.bonus_count}
          </Typography>
          <TextField
            type='text'
            label={intl.formatMessage({ id: 'USER.EDIT.PROMOCODE' })}
            margin='normal'
            name='login'
            value={values.promocode || null}
            variant='outlined'
            onBlur={handleBlur}
            onChange={e => {
              setFieldValue('promocode', e.target.value);
            }}
            helperText={touched.promocode && errors.promocode}
            error={Boolean(touched.promocode && errors.promocode)}
          />

          <div className={classes.actions}>
            <ButtonWithLoader disabled={editLoading} loading={editLoading} type='submit'>
              {intl.formatMessage({ id: 'COMMON.BUTTON.APPLY' })}
            </ButtonWithLoader>
          </div>
        </form>
      )}
    </Formik>
  );
};

const connector = connect(
  (state: IAppState) => ({
    editSuccess: state.promocodes.editSuccess,
    editLoading: state.promocodes.editLoading,
    editError: state.promocodes.editError,
    userRoles: state.auth.user?.roles,
    isAdmin: state.auth.user?.is_admin,
  }),
  {
    clearEdit: profileActions.clearEdit,
    clearEditPromo: promocodesActions.clearEdit,
    edit: profileActions.editRequest,
    activate: promocodesActions.activateRequest,
    setLanguage: i18nActions.setLanguage,
    getOrCreateCart: cartActions.getOrCreateRequest,
    fetchMe: profileActions.fetchRequest,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Bonuses);
