import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { getTenderSteps } from '../../../../crud/orders.crud';
import { getResponseMessage } from '../../../../utils/utils';

export const useGetTenderSteps: any = () => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [steps, setSteps] = useState(null);
  const fetch = useCallback(async (id: string) => {
    setLoading(true);
    getTenderSteps(id)
      .then((res: any) => {
        setSteps(res.data.data);
        setSuccess(true);
      })
      .catch(e => {
        enqueueSnackbar(`${intl.formatMessage({ id: 'ERROR' })}: ${getResponseMessage(e)}`, {
          variant: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
        setSuccess(false);
      });
  }, []);
  return [fetch, steps, loading, success];
};
