import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { TAppActions } from '../rootDuck';

import { ActionsUnion, createAction } from '../../utils/action-helper';
import { IServerResponse } from '../../interfaces/server';
import { IProduct, IProductFilter } from '../../interfaces/product';
import { addForDays, getForDays, getProductsForDays, addCurrency,  } from '../../crud/product.crud';
import { getResponseMessage } from '../../utils/utils';

const FETCH_REQUEST = 'product-for-days/FETCH_REQUEST';
const FETCH_SUCCESS = 'product-for-days/FETCH_SUCCESS';
const FETCH_FAIL = 'product-for-days/FETCH_FAIL';
const SET_EMPTY = 'product-for-days/SET_EMPTY';

const ADD_FOR_DAYS = 'product-for-days/ADD_FOR_DAYS';
const SET_FOR_DAYS = 'product-for-days/SET_FOR_DAYS';

const DEL_REQUEST = 'product-for-days/DEL_REQUEST';
const DEL_SUCCESS = 'product-for-days/DEL_SUCCESS';
const DEL_FAIL = 'product-for-days/DEL_FAIL';
const CLEAR_DEL = 'product-for-days/CLEAR_DEL';

const ADD_CURRENCY = 'product-for-days/ADD_CURRENSY';

export interface IInitialState {
  productsForDays: IProduct[] | undefined;
  forDays: number;
  loading: boolean;
  success: boolean;
  error: string | null;
  page: number;
  per_page: number;
  total: number;

  currensy: string | null;
}

const defaultPaginatorProps = {
  forDays: 0,
  page: 1,
  per_page: 20,
  total: 0,
  currensy: null
};

const initialState: IInitialState = {
  ...defaultPaginatorProps,
  productsForDays: undefined,
  loading: false,
  success: false,
  error: null,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'product-for-days', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case SET_EMPTY: {
        return { ...state, productsForDays: [] };
      }

      case FETCH_REQUEST: {
        return {
          ...state,
          productsForDays: undefined,
          loading: true,
          success: false,
          error: null,
        };
      }

      case ADD_FOR_DAYS: {
        return {
          ...state,
          forDays: action.payload,
        };
      }

      case FETCH_SUCCESS: {
        return {
          ...state,
          page: action.payload.page,
          per_page: action.payload.per_page,
          total: action.payload.total,
          productsForDays: action.payload.data,
          loading: false,
          success: true,
        };
      }

      case FETCH_FAIL: {
        return { ...state, loading: false, error: action.payload };
      }

      case DEL_FAIL: {
        return { ...state, delError: action.payload, delSuccess: false };
      }

      case DEL_SUCCESS: {
        return { ...state, delError: null, delSuccess: true };
      }

      case CLEAR_DEL: {
        return { ...state, delError: null, delSuccess: false };
      }

      case ADD_CURRENCY: {
        return {
          ...state,
          currensy: action.payload.currency,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  fetchRequest: (payload: { page: number; perPage: number }) =>
    createAction(FETCH_REQUEST, payload),
  fetchSuccess: (payload: IServerResponse<IProduct[]>) => createAction(FETCH_SUCCESS, payload),
  fetchFail: (payload: string) => createAction(FETCH_FAIL, payload),
  setEmpty: () => createAction(SET_EMPTY),
  addForDays: (payload: number) => createAction(ADD_FOR_DAYS, payload),
  setForDays: (payload: { page: number; perPage: number; forDays: number }) =>
    createAction(SET_FOR_DAYS, payload),
  delRequest: (payload: {
    id: number | undefined;
    page: number;
    perPage: number;
    filter: IProductFilter;
  }) => createAction(DEL_REQUEST, payload),
  delFail: (payload: string) => createAction(DEL_FAIL, payload),
  delSuccess: () => createAction(DEL_SUCCESS),
  clearDel: () => createAction(CLEAR_DEL),
  addCurrencyAction: (payload: {currency: string}) => createAction(ADD_CURRENCY, payload),
};

export type TActions = ActionsUnion<typeof actions>;

function* fetchSaga({ payload }: { payload: { page: number; perPage: number } }) {
  try {
    const forDays: { data: IServerResponse<{ new_days: number }> } = yield call(() =>
      getForDays()
    );
    yield put(actions.addForDays(forDays.data.data.new_days));
    const { data }: { data: IServerResponse<IProduct[]> } = yield call(() =>
      getProductsForDays({ ...payload, forDays: +forDays.data.data.new_days })
    );

    yield put(actions.fetchSuccess(data));
  } catch (e) {
    yield put(actions.fetchFail(getResponseMessage(e)));
  }
}

function* setForDays({
  payload,
}: {
  payload: { page: number; perPage: number; forDays: number };
}) {
  try {
    const forDays: { data: IServerResponse<{ new_days: number }> } = yield call(() =>
      addForDays(+payload.forDays)
    );
    yield put(actions.addForDays(+forDays.data.data.new_days));
    yield put(actions.fetchRequest({ ...payload }));
  } catch (e) {
    yield put(actions.fetchFail(getResponseMessage(e)));
  }
}

function* addCurrencySaga({ payload }: { payload: {currency: string} }) {
  try {
    yield call(() =>
      addCurrency(payload.currency)
    );
  } catch (e) {
    yield put(actions.fetchFail(getResponseMessage(e)));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.fetchRequest>>(FETCH_REQUEST, fetchSaga);
  yield takeLatest<ReturnType<typeof actions.setForDays>>(SET_FOR_DAYS, setForDays);
  yield takeLatest<ReturnType<typeof actions.addCurrencyAction>>(ADD_CURRENCY, addCurrencySaga);
}
