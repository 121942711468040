import { useFormik } from 'formik';
import * as Yup from 'yup';
import {useState} from 'react';
import { useFormatMessage } from '../../../../hooks';
import { ICategoryItem } from '../../categories/interfaces';
import { IRequestData, IRequest } from '../../../../interfaces/request';

const findCategory = (item: ICategoryItem, id: string, categories: any[]): any => {
  if (!item.children) return categories;
  const category = item.children?.find(i => i.id?.toString() === id);
  if (category) {
    return [...categories, category];
  }
  for (const i of item.children) {
    if (i.children) {
      const newCategories = findCategory(i, id, categories);
      if (newCategories && newCategories.length > 0)
        return [...categories, i, ...newCategories];
    }
  }
  return categories;
};

const getInitValues = (request?: IRequest, categories?: ICategoryItem[]) => {
  const initValues: { [key: string]: any } = {
    title: request?.title || '',
    text: request?.text || '',
    status: request?.status || 'new',
    categoryId: request?.category?.id,
    photo: [],
  };

  if (categories) {
    const id = request?.category?.id;
    let tree: ICategoryItem[] = [];
    if (id) {
      categories.forEach(item => {
        const newCategories = findCategory(item, id.toString(), []);
        if (newCategories && newCategories.length > 0) {
          tree = [item, ...newCategories];
        }
      });
      tree.forEach((item, index) => {
        if (index === 0) {
          initValues.categoryId = item.id;
        } else {
          initValues[`categoryId_${index}`] = item.id;
        }
      });
    }
  }

  return initValues;
};

export const useFormikRequestPage = (
  createRequest: (data: FormData ,params: IRequestData) => void,
  editRequest: (data: { id: number; data: any }) => void,
  request?: IRequest,
  categories?: ICategoryItem[],
  images?: File[],
) => {
  const fm = useFormatMessage();
  const [descEditorState, setDescEditorState] = useState<any>(null);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitValues(request, categories),
    validationSchema: Yup.object().shape({
      title: Yup.string().required(fm('PRODUCT.REQUIRED.NAME')).trim(),
      // text: Yup.string().required(fm('PRODUCT.REQUIRED.PRICE')).trim(),
      categoryId: Yup.string().required(fm('PRODUCT.REQUIRED.CATEGORY')).trim(),
    }),
    onSubmit: async (submitValues) => {
      const data = new FormData();
      const params: IRequestData = {
        title: submitValues.title,
        category_id: submitValues.categoryId,

      };

      if (descEditorState) {
        params.text = descEditorState.getData() || '';
      }
      if(images) {
        images?.forEach(img => data.append('photo[]', img));
      }

      if (request) {
        editRequest({
          id: request.id,
          data: params,
        });
      } else {
        createRequest(data, params);
      }
    },
  });

  return { formik, setDescEditorState };
};
