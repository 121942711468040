import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Card, TextField, Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';

import ButtonWithLoader from '../../../components/other/Buttons/ButtonWithLoader';
import Preloader from '../../../components/other/Preloader/Preloader';
import { actions as loyaltiesActions } from '../../../store/ducks/loyalty.duck';
import homeStyles from '../../../constants/homeStyles';
import { IAppState } from '../../../store/rootDuck';
import { actions as profileActions } from '../../../store/ducks/profile.duck';

import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';
import { ILoyaltyData } from '../../../interfaces/loyalties';
import { useStylesLoyaltyEdit } from './hooks/useStyles';

const getInitialValues = (loyalty: ILoyaltyData | undefined) => ({
  name: loyalty ? loyalty.name : '',
  orders_amount: loyalty ? loyalty.orders_amount : 0,
  discount_percent: loyalty ? loyalty.discount_percent : 0,
});

const LoyaltyLevelEditPage: React.FC<TPropsFromRedux> = ({
  loadingMe,
  loyalty,
  fetchMe,
  fetch,
  loading,
  clearEdit,
  add,
  edit,
  editLoading,
}) => {
  const classes = useStylesLoyaltyEdit();
  const homeClasses = homeStyles();
  const { loyaltyId } = useParams();
  const navigate = useNavigate();
  const intl = useIntl();

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setValues } =
    useFormik({
      initialValues: getInitialValues(loyalty),
      onSubmit: submitValues => {
        if (loyaltyId) {
          edit({
            id: Number(loyaltyId),
            data: {
              name: submitValues.name,
              orders_amount: submitValues.orders_amount.toString(),
              discount_percent: submitValues.discount_percent.toString(),
            },
          });
          navigate('/loyalties/list');
        } else {
          add({
            data: {
              name: submitValues.name,
              orders_amount: submitValues.orders_amount.toString(),
              discount_percent: submitValues.discount_percent.toString(),
            },
          });
          navigate('/loyalties/list');
        }
      },
    });

  useEffect(() => {
    fetchMe();

    return () => {
      clearEdit();
    };
  }, []);

  useEffect(() => {
    if (loyaltyId) {
      fetch(Number(loyaltyId));
    }
  }, [fetch, loyaltyId]);

  setLayoutSubheader({
    title: loyaltyId
      ? `${intl.formatMessage({ id: 'LOYALTY.EDIT.TITLE' })}`
      : `${intl.formatMessage({ id: 'LOYALTY.ADD.TITLE' })}`,
    breadcrumb: [],
  });
  setLayoutFooter({ show: true });

  useEffect(() => {
    if (loyalty) {
      setValues(getInitialValues(loyalty));
    }
  }, [loyalty, setValues]);

  if (loading || loadingMe) return <Preloader />;

  return (
    <>
      <Row>
        <Col>
          <Card className={homeClasses.classes.container}>
            <>
              <form
                className={homeClasses.classes.form}
                onSubmit={handleSubmit}
                autoComplete='off'
              >
                <TextField
                  type='text'
                  label={intl.formatMessage({ id: 'LOYALTY.TABLE.NAME' })}
                  margin='normal'
                  name='name'
                  value={loyaltyId ? values.name : null}
                  variant='outlined'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  helperText={touched.name && errors.name}
                  error={Boolean(touched.name && errors.name)}
                />

                <TextField
                  type='number'
                  label={intl.formatMessage({ id: 'LOYALTY.TABLE.NUM' })}
                  margin='normal'
                  name='discount_percent'
                  value={loyaltyId ? values.discount_percent : null}
                  variant='outlined'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  helperText={touched.discount_percent && errors.discount_percent}
                  error={Boolean(touched.discount_percent && errors.discount_percent)}
                />

                <TextField
                  type='number'
                  label={intl.formatMessage({ id: 'LOYALTY.TABLE.DISCOUNT' })}
                  margin='normal'
                  name='orders_amount'
                  value={loyaltyId ? values.orders_amount : null}
                  variant='outlined'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  helperText={touched.orders_amount && errors.orders_amount}
                  error={Boolean(touched.orders_amount && errors.orders_amount)}
                />

                <div className={classes.actions}>
                  <Button
                    onClick={() => navigate(-1)}
                    className={classes.buttons}
                    variant='outlined'
                    color='primary'
                  >
                    {intl.formatMessage({ id: 'COMMON.BUTTON.BACK' })}
                  </Button>

                  <ButtonWithLoader disabled={editLoading} loading={editLoading}>
                    {loyaltyId
                      ? intl.formatMessage({ id: 'USER.BUTTON.EDIT' })
                      : intl.formatMessage({ id: 'USER.BUTTON.ADD' })}
                  </ButtonWithLoader>
                </div>
              </form>
            </>
          </Card>
        </Col>
      </Row>
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    loadingMe: state.profile.loading,
    loyalty: state.loyalty.loyalty,
    loading: state.companies.byIdLoading,
    editLoading: state.companies.editLoading,
  }),
  {
    fetchMe: profileActions.fetchRequest,

    fetch: loyaltiesActions.fetchByIdRequest,
    clearEdit: loyaltiesActions.clearEdit,
    add: loyaltiesActions.addRequest,
    edit: loyaltiesActions.editRequest,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(LoyaltyLevelEditPage);
