import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Typography,
  Card,
  Table,
  CardContent,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
} from '@mui/material';

import { useIntl } from 'react-intl';
import moment from 'moment';
import { SHeadTableCell } from '../../../components/styledComponents/Tables';
import Preloader from '../../../components/other/Preloader/Preloader';
import TablePaginator from '../../../components/tableComponents/TablePaginator/TablePaginator';
import { actions as transactionsActions } from '../../../store/ducks/transactions.duck';
import { actions as profileActions } from '../../../store/ducks/profile.duck';
import { IAppState } from '../../../store/rootDuck';
import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';
import homeStyles from '../../../constants/homeStyles';
import { useStylesPromocodes } from './hooks/useStyles';
import SettingsTransactions from './components/SettingsTransactions';
import useCrudSnackbar from '../../../hooks/useCrudSnackbar';

const TransactionPage: React.FC<TPropsFromRedux> = ({
  page,
  perPage,
  total,
  transactions,
  delTransactionSuccess,
  delTransactionError,
  clearDelTransaction,
  fetch,
  loading,
  me,
  fetchMe,
  loadingMe,
  clearMe,
  loadingTransactions,
}) => {
  const classes = useStylesPromocodes();
  const homeClasses = homeStyles();
  const [headerName, setHeaderName] = useState('');
  const intl = useIntl();
  const [changeRange, setChangeRange] = React.useState<boolean>(false);
  const [startDate, setStartDate] = React.useState<string>(
    moment(new Date()).format('YYYY.MM.DD HH:mm')
  );
  const [endDate, setEndDate] = React.useState<string>(
    moment(new Date()).format('YYYY.MM.DD HH:mm')
  );

  useCrudSnackbar({
    success: delTransactionSuccess,
    error: delTransactionError,
    clear: clearDelTransaction,
    successMessage: intl.formatMessage({ id: 'COMPANY.DELETE.SUCCESS' }),
    errorMessage: `${intl.formatMessage({ id: 'ERROR' })}: ${delTransactionError}`,
    afterSuccessOrError: () => {
      clearDelTransaction();
    },
    afterSuccess: () =>
      fetch({
        page,
        perPage,
        filterData: { start_date: startDate, end_date: endDate },
        isFilter: changeRange,
      }),
  });

  const handleChangeRange = (start: string | Date, end: string | Date) => {
    const first = moment(start).format('YYYY-MM-DD HH:mm');
    const second = moment(end).format('YYYY-MM-DD HH:mm');
    setChangeRange(true);
    setStartDate(first);
    setEndDate(second);
  };

  useEffect(() => {
    fetchMe();
    return () => {
      clearMe();
    };
  }, []);

  useEffect(() => {
    setHeaderName(intl.formatMessage({ id: 'SUBMENU.TRANSACTIONS' }));
    fetch({
      page,
      perPage,
      filterData: { start_date: startDate, end_date: endDate },
      isFilter: changeRange,
    });
  }, [me, changeRange, startDate, endDate]);

  setLayoutSubheader({
    title: headerName,
    breadcrumb: [],
  });
  setLayoutFooter({ show: true });

  if (!me || loadingMe || !transactions || loading || loadingTransactions)
    return <Preloader />;
  return (
    <>
      <div className={classes.container}>
        <div className={classes.filterCol}>
          <Card className={classes.filter}>
            <>
              {me.is_admin && (
                <SettingsTransactions {...{ handleChangeRange, startDate, endDate }} />
              )}
            </>
          </Card>
        </div>
        <div className={classes.ordersCol}>
          <Card className={classes.card}>
            <CardContent className={homeClasses.classes.tableContainer}>
              <Table aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <SHeadTableCell>
                      {intl.formatMessage({ id: 'COMPANY.TABLE.ID' })}
                    </SHeadTableCell>
                    <SHeadTableCell>
                      {intl.formatMessage({ id: 'COMPANY.TABLE.CREATED_AT' })}
                    </SHeadTableCell>
                    <SHeadTableCell>
                      {intl.formatMessage({ id: 'COMPANY.TABLE.USER' })}
                    </SHeadTableCell>
                    <SHeadTableCell>
                      {intl.formatMessage({ id: 'COMPANY.TABLE.AUTHOR' })}
                    </SHeadTableCell>
                    <SHeadTableCell>
                      {intl.formatMessage({ id: 'COMPANY.TABLE.COMMENT' })}
                    </SHeadTableCell>
                    <SHeadTableCell>
                      {intl.formatMessage({ id: 'COMPANY.TABLE.AMOUNT' })}
                    </SHeadTableCell>
                  </TableRow>
                </TableHead>
                {!transactions?.length ? (
                  <Typography className={classes.empty} component='h5' variant='h5'>
                    {intl.formatMessage({ id: 'COMMON.LIST.EMPTY' })}
                  </Typography>
                ) : (
                  <TableBody>
                    {transactions?.map((transaction: any) => {
                      const {
                        first_name: firstNameUser,
                        last_name: lastNameUser,
                        middle_name: middleNameUser,
                      } = transaction.user;
                      const author = transaction.promo_code?.author;
                      const authorName = author
                        ? `${author.last_name || ''} ${author.first_name || ''} ${
                            author.middle_name || ''
                          }`
                        : '-';
                      const promoCode = transaction.promo_code?.code;
                      return (
                        <>
                          <TableRow key={transaction.id}>
                            <TableCell>{transaction.id || '-'}</TableCell>
                            <TableCell>
                              {transaction.created_at
                                ? moment(transaction.created_at).format('DD.MM.YYYY HH:mm')
                                : '-'}
                            </TableCell>
                            <TableCell>
                              {!firstNameUser && !lastNameUser && !middleNameUser
                                ? '-'
                                : `${lastNameUser || ''} ${firstNameUser || ''} ${
                                    middleNameUser || ''
                                  }`}
                            </TableCell>
                            <TableCell>{authorName}</TableCell>
                            <TableCell>{`${transaction.comment || ''} ${
                              transaction.comment && promoCode ? ', ' : ''
                            } ${
                              promoCode
                                ? `${
                                    transaction.comment.trim() === 'promo code'
                                      ? ''
                                      : 'Promocode:'
                                  } ${promoCode}`
                                : ''
                            }`}</TableCell>
                            <TableCell>{transaction.amount || '-'}</TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                  </TableBody>
                )}
                <TableFooter>
                  <TableRow>
                    <TablePaginator
                      page={page}
                      perPage={perPage}
                      fetchRows={fetch}
                      total={total}
                      label={intl.formatMessage({ id: 'TRANSACTIONS.TABLE.TITLE' })}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    page: state.transactions.page,
    perPage: state.transactions.per_page,
    total: state.transactions.total,
    transactions: state.transactions.transactions,
    delTransactionSuccess: state.transactions.delTransactionSuccess,
    delTransactionError: state.transactions.delTransactionError,
    loading: state.promocodes.loading,
    me: state.profile.me,
    loadingMe: state.profile.loading,
    loadingTransactions: state.transactions.loading,
  }),
  {
    fetch: transactionsActions.fetchRequest,
    clearDelTransaction: transactionsActions.clearDelTransaction,
    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(TransactionPage);
