import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { getResponseMessage } from '../../../../../utils/utils';
import { deleteMePhoto } from '../../../../../crud/users.crud';

export const useDeleteMePhoto = () => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [loadingPhotoDelete, setLoading] = useState(false);
  const [successPhotoDelete, setSuccess] = useState(false);
  const deletePhoto = useCallback(async () => {
    setLoading(true);
    deleteMePhoto()
      .then(() => {
        setSuccess(true);
        enqueueSnackbar(`${intl.formatMessage({ id: 'ME.PHOTO.DELETE' })}`, {
          variant: 'success',
        });
      })
      .catch(e => {
        enqueueSnackbar(`${intl.formatMessage({ id: 'ERROR' })}: ${getResponseMessage(e)}`, {
          variant: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
        setSuccess(false);
      });
  }, []);
  return { deletePhoto, loadingPhotoDelete, successPhotoDelete };
};
