import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { createPost, uploadFile } from '../../../../../crud/chat.crud';
import ChatSocket from '../../../../../utils/socket';
import { getResponseMessage } from '../../../../../utils/utils';

export const useCreatePostFiles: any = () => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState<any>(null);
  const [success, setSuccess] = useState(false);

  const fetch = useCallback(async (id: string, message: string, files: File[]) => {
    if (!ChatSocket.myAuthToken) await ChatSocket.getMyAuthToken();
    setLoading(true);
    try {
      const fileIds = [];
      for (const file of files) {
        const { data } = await uploadFile(id, file, file.name);
        fileIds.push(data.file_infos[0].id);
      }
      createPost(id, message, {}, fileIds)
        .catch(e => {
          enqueueSnackbar(`${intl.formatMessage({ id: 'ERROR' })}: ${getResponseMessage(e)}`, {
            variant: 'error',
          });
        })
        .finally(() => {
          setLoading(false);
          setErr(null);
          setSuccess(false);
        });
    } catch (error) {
      setLoading(false);
      setErr(null);
      setSuccess(false);
      enqueueSnackbar(`${intl.formatMessage({ id: 'ERROR' })}: ${getResponseMessage(error)}`, {
        variant: 'error',
      });
    }
  }, []);
  return [fetch, loading, success, err];
};
