import React from 'react';

type TProps = {
  item: any;
};

export const MenuSection: React.FC<TProps> = ({ item }) => {
  return (
    <li className='kt-menu__section'>
      <h4 className='kt-menu__section-text'>{item.section}</h4>
      <i className='kt-menu__section-icon flaticon-more-v2' />
    </li>
  );
};
