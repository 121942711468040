import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { deleteSearch } from '../../../../crud/search.crud';
import { getResponseMessage } from '../../../../utils/utils';

export const useDeleteSearch = () => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [loadingDeleteSearch, setLoading] = useState(false);
  const [successDelete, setSuccess] = useState(false);
  const deleteSearchFetch = useCallback(async (id: number) => {
    setLoading(true);
    deleteSearch(id)
      .then(() => {
        setSuccess(true);
        enqueueSnackbar(`${intl.formatMessage({ id: 'SEARCH.DELETE.SUCCESS' })}`, {
          variant: 'success',
        });
      })
      .catch(e => {
        enqueueSnackbar(`${intl.formatMessage({ id: 'ERROR' })}: ${getResponseMessage(e)}`, {
          variant: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
        setSuccess(false);
      });
  }, []);
  return { deleteSearchFetch, loadingDeleteSearch, successDelete };
};
