import {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {makeAxiosPostRequest} from '../../../../../utils/utils';
import {actions} from '../../../../../store/ducks/profile.duck';

export const handleUploadAvatar = () => {
  const dispatch = useDispatch();
  const [res, setRes] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState<any>(null);
  
  const uploadIvatar = useCallback(
    (files: File[]) => {
      const url = 'api/me/upload_photos';
      const formData = new FormData();
      formData.append('photo', files[0]);
      makeAxiosPostRequest(url, formData, setRes, setLoading, setErr, {
        headers: { 'content-type': 'multipart/form-data' },
      });
    },
    []
  );
  
  useEffect(() => {
    if (res) dispatch(actions.uploadAvatarSuccess(res.data));
  }, [res]);
  
  // cleanup
  useEffect(() => {
    setRes(null);
    setErr(null);
  }, [res, err]);
  
  return [uploadIvatar, loading, err];
};
