import React from 'react';
import { useIntl } from 'react-intl';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import LinkIcon from '@mui/icons-material/Link';

import { SHeadTableCell } from '../../styledComponents/Tables';
import TablePaginator from '../TablePaginator/TablePaginator';
import Preloader from '../../other/Preloader/Preloader';
import { IStore } from '../../../interfaces/store';
import { IPaginationData } from '../../../interfaces/server';
import NoRowsOverlay from './NoRowsOverlay';
import { ICompany } from '../../../interfaces/companies';

interface IProps {
  stores?: IStore[];
  loading: boolean;
  onDelete?: (id: number) => any;
  onEdit?: (id: number) => any;
  onConnectOff?: (id: number, item: IStore) => any;
  onConnect?: (id: number, name: string, company: ICompany, item: IStore) => any;
  connectedStores?: number[];
  isModal?: boolean;
  paginationData: IPaginationData;
  fetch?: (data: { page: number; perPage: number }) => void;
  isAdmin?: boolean;
}

const StoresTable: React.FC<IProps> = ({
  stores,
  loading,
  paginationData: { page, perPage, total },
  onDelete,
  onEdit,
  fetch,
  onConnect,
  onConnectOff,
  connectedStores,
  isAdmin,
}) => {
  const intl = useIntl();
  const classes1 = useStyles();
  const { classes } = classes1;

  if (loading) return <Preloader />;

  return (
    <Table aria-label='simple table'>
      <TableHead>
        <TableRow>
          <SHeadTableCell>{intl.formatMessage({ id: 'PRODUCT.TABLE.ID' })}</SHeadTableCell>
          <SHeadTableCell>{intl.formatMessage({ id: 'PRODUCT.TABLE.NAME' })}</SHeadTableCell>
          <SHeadTableCell>{intl.formatMessage({ id: 'ADDRESS' })}</SHeadTableCell>
          <SHeadTableCell>{intl.formatMessage({ id: 'COMPANY.TABLE.PHONE' })}</SHeadTableCell>
          <SHeadTableCell>
            {intl.formatMessage({ id: 'PRODUCT.TABLE.ACTIONS' })}
          </SHeadTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {loading && (
          <TableRow>
            <TableCell className={classes.empty} colSpan={5} component='th' scope='row'>
              <Preloader />
            </TableCell>
          </TableRow>
        )}

        {!loading && !stores?.length && (
          <TableRow>
            <TableCell className={classes.empty} colSpan={5} component='th' scope='row'>
              <div className={classes.emptyOverlay}>
                <NoRowsOverlay label={intl.formatMessage({ id: 'STORE.EMPTY' })} />
              </div>
            </TableCell>
          </TableRow>
        )}

        {stores &&
          stores.map(item => (
            <TableRow key={item.id}>
              <TableCell component='th' scope='row'>
                {item.id || '-'}
              </TableCell>
              <TableCell>{item.name || '-'}</TableCell>
              <TableCell>{item.location?.address || '-'}</TableCell>
              <TableCell>{item.phone_number || '-'}</TableCell>
              <TableCell>
                <IconButton
                  color='primary'
                  aria-label='previous'
                  onClick={() => onEdit?.(item.id)}
                >
                  <EditIcon />
                </IconButton>
                {onDelete && isAdmin && (
                  <IconButton onClick={() => onDelete(item.id)}>
                    <DeleteIcon color='error' />
                  </IconButton>
                )}
                {onConnectOff && !onConnect && (
                  <IconButton onClick={() => onConnectOff(item.id, item)}>
                    <LinkOffIcon color='error' />
                  </IconButton>
                )}

                {onConnect && (
                  <IconButton
                    onClick={
                      onConnectOff && connectedStores && connectedStores.includes(item.id)
                        ? () => onConnectOff(item.id, item)
                        : () => onConnect(item.id, item.name, item.company, item)
                    }
                  >
                    {connectedStores && connectedStores.includes(item.id) ? (
                      <LinkOffIcon color='error' />
                    ) : (
                      <LinkIcon color='primary' />
                    )}
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePaginator
            page={page}
            perPage={perPage}
            total={total}
            realPerPage={stores?.length}
            fetchRows={fetch}
            label={intl.formatMessage({ id: 'STORES.TABLE.PERPAGE' })}
          />
        </TableRow>
      </TableFooter>
    </Table>
  );
};

const useStyles = makeStyles()(() => {
  return {
    empty: {
      height: 200,
    },
    table: {
      marginTop: 20,
    },
    emptyOverlay: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
  };
});

export default StoresTable;
