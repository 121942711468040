import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { createPost } from '../../../../../crud/chat.crud';
import ChatSocket from '../../../../../utils/socket';
import { getResponseMessage } from '../../../../../utils/utils';

export const useCreatePost: any = () => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState<any>(null);
  const [success, setSuccess] = useState(false);

  const fetch = useCallback(async (id: string, message: string) => {
    if (!ChatSocket.myAuthToken) await ChatSocket.getMyAuthToken();
    setLoading(true);
    createPost(id, message)
      .catch(e => {
        enqueueSnackbar(`${intl.formatMessage({ id: 'ERROR' })}: ${getResponseMessage(e)}`, {
          variant: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
        setErr(null);
        setSuccess(false);
      });
  }, []);
  return [fetch, loading, success, err];
};
