import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import CategoriesListPage from './CategoriesList';
import CategoriesTreePage from './CategoriesTree';
import CategoryPage from './CategoryPage';
import CategoryView from './CategoryView';
import { IAppState } from '../../../store/rootDuck';
import ProtectedRoute from '../../../router/Routes';

export default function CategoriesPage() {
  const { isAuthorized } = useSelector(({ auth }: IAppState) => ({
    isAuthorized: auth.user != null,
  }));
  return (
    <Routes>
      <Route
        path='list'
        element={
          <ProtectedRoute isAuthorized={isAuthorized} outlet={<CategoriesListPage />} />
        }
      />
      <Route
        path='tree'
        element={
          <ProtectedRoute isAuthorized={isAuthorized} outlet={<CategoriesTreePage />} />
        }
      />
      <Route
        path='list/:parentId'
        element={
          <ProtectedRoute isAuthorized={isAuthorized} outlet={<CategoriesListPage />} />
        }
      />
      <Route
        path='new'
        element={<ProtectedRoute isAuthorized={isAuthorized} outlet={<CategoryPage />} />}
      />
      <Route
        path='new/:parentId'
        element={<ProtectedRoute isAuthorized={isAuthorized} outlet={<CategoryPage />} />}
      />
      <Route
        path='edit/:id'
        element={<ProtectedRoute isAuthorized={isAuthorized} outlet={<CategoryPage />} />}
      />
      <Route
        path='edit/:id/:parentId'
        element={<ProtectedRoute isAuthorized={isAuthorized} outlet={<CategoryPage />} />}
      />
      <Route path='view/:id' element={<CategoryView />} />
    </Routes>
  );
}
