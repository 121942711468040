import React, { useCallback } from 'react';
import File from '@mui/icons-material/FileCopy';
import { CircularProgress } from '@mui/material';
import { formatBytes } from '../../../../../utils/utils';
import { useDownloadFile } from '../hooks/useDownloadFile';
import { useStyleFileChat } from '../styles';

interface IProps {
  file: any;
  isMy?: boolean;
}

const FileChat: React.FC<IProps> = ({ file, isMy }) => {
  const classes = useStyleFileChat();
  const [getFile, loading] = useDownloadFile();

  const downloadFile = useCallback(() => {
    !loading && getFile(file.id);
  }, [file, loading]);

  return (
    <>
      <div
        className={classes.container}
        onClick={downloadFile}
        style={{ justifyContent: isMy ? 'end' : 'start' }}
      >
        {loading ? <CircularProgress size={18} /> : <File color='primary' />}
        <div className={classes.file}>
          <b className={classes.fileName}>{file.name}</b>
          <b className={classes.fileSize}>{formatBytes(file.size)}</b>
        </div>
      </div>
    </>
  );
};

export default React.memo(FileChat);
