import React from 'react';
import { Route, Routes } from 'react-router-dom';
import RequestList from './RequestList';
import RequestPage from './RequestPage';
import RequestView from './RequestView';

const RequestPages = () => (
  <Routes>
    <Route path='list' element={<RequestList />} />
    <Route path='create' element={<RequestPage />} />
    <Route path='view/:id' element={<RequestView />} />
    <Route path='edit/:id' element={<RequestPage />} />
  </Routes>
);

export default RequestPages;
