/* eslint-disable react/button-has-type */
import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions as productCatalogActions } from '../../../store/ducks/product-catalog.duck';
import { ReactComponent as Menu } from '../../../../_base/layout/assets/layout-svg-icons/menu.svg';
import { ReactComponent as MenuActive } from '../../../../_base/layout/assets/layout-svg-icons/menu_active.svg';
import SearchField from '../SearchField';
import { IAppState } from '../../../store/rootDuck';
import DialogCategories from '../DialogCategories';

const HeaderMobileNavigation = () => {
  const dispatch = useDispatch();
  const { catalogCategories } = useSelector((store: IAppState) => ({
    catalogCategories: store.categories.catalogCategories,
  }));

  const { filter } = useSelector((store: IAppState) => ({
    filter: store.productsCatalog.filter,
    loading: store.productsCatalog.loading,
  }));
  const [open, setOpen] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(null);

  // useLayoutEffect(() => {
  //   dispatch(categoriesActions.fetchCatalogCategories({}));
  // }, []);

  const clear = () => {
    resetProducts();
    setSelectedCategoryId(null);
  };

  const resetProducts = useCallback(() => {
    dispatch(productCatalogActions.fetchRequest({ page: 1, perPage: 12, filter }));
  }, [catalogCategories]);

  return (
    <>
      <div style={{ cursor: 'pointer' }} onClick={() => setOpen(true)}>
        {open ? <MenuActive /> : <Menu />}
      </div>
      <SearchField clear={clear} />
      <DialogCategories
        setSelectedCategoryId={setSelectedCategoryId}
        selectedCategoryId={selectedCategoryId}
        open={open}
        close={() => setOpen(false)}
        clear={clear}
        setSearch={value => dispatch(productCatalogActions.setSearch(value))}
      />
    </>
  );
};

export default HeaderMobileNavigation;
