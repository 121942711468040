import React, { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { toAbsoluteUrl } from '../../../../../_base';
import { IFile } from '../../../../interfaces/file';

interface IProps {
  uploadFiles: (files: IFile) => void;
  setCurrentType: React.Dispatch<React.SetStateAction<string>>;
  item: any;
  currentType: string;
}

const DropZoneBtn: React.FC<IProps> = ({ uploadFiles, setCurrentType, item, currentType }) => {
  const handleDrop = useCallback(
    async (droppedFiles: any) => {
      uploadFiles(droppedFiles.slice(0, 1));
    },
    [currentType]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
  });

  const dropZoneProps = useMemo(() => getRootProps(), [getRootProps]);

  return (
    <div onClick={() => setCurrentType(item[0])}>
      <div
        style={{
          display: 'flex',
          width: 56,
          height: 56,
          minWidth: 56,
          borderRadius: 4,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#f7f9fb',
          marginLeft: 18,
          cursor: 'pointer',
        }}
        {...dropZoneProps}
      >
        <input {...getInputProps()} />
        <img alt='check' src={toAbsoluteUrl(`/media/logos/attach.svg`)} />
      </div>
    </div>
  );
};

export default React.memo(DropZoneBtn);
