import React, { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Typography, TextField, Box, Autocomplete } from '@mui/material';
import { useIntl } from 'react-intl';

import { useFormatMessage } from '../../../../../hooks';
import { IAppState } from '../../../../../store/rootDuck';
import NumberFormatCustom from '../../../../../components/formComponents/NumberFormatCustom';
import { ITypeParameter } from '../../../../../interfaces/productType';
import { useStylesHeaderAddMultiply } from '../../hooks/useStyles';
import { ICountry } from '../../../../auth/interfaces';

type TProps = {
  formik: any;
  filteredParams: ITypeParameter[];
  countries: ICountry[];
};

const TypeParams: React.FC<TProps> = ({ formik, filteredParams, countries }) => {
  const fm = useFormatMessage();
  const intl = useIntl();
  const classes1 = useStylesHeaderAddMultiply();
  const {classes} = classes1

  const { paramsLoading } = useSelector(
    ({ productTypes: { paramsLoading } }: IAppState) => ({
      paramsLoading,
    }),
    shallowEqual
  );

  const filterCountries = useMemo(() => {
    if (countries) {
      const newList: ICountry[] = [];
      countries.forEach(i => {
        if (i.id === 1 || i.id === 76) {
          newList.push({ ...i, favorite: true });
        } else {
          newList.push(i);
        }
      });
      return newList;
    }
    return [];
  }, [countries]);

  const { values, handleBlur, handleChange, setFieldValue } = formik;

  return (
    <div className={classes.block}>
      <Typography className={classes.titleTop}>
        {fm('PRODUCT.ADD.MULTIPLY.PRODUCTS.TYPE.PARAMS').toUpperCase()}
      </Typography>
      {!paramsLoading && filteredParams.length > 0 && (
        <div className={classes.wrapperFields}>
          {filteredParams.map(item => {
            if (item.type === 'number') {
              if (item.name === 'Year of manufacture') {
                return (
                  <TextField
                    type='text'
                    label={item.name}
                    color='primary'
                    name={`parameter${item.id}`}
                    // @ts-ignore
                    value={values[`parameter${item.id}`] || ''}
                    variant='outlined'
                    onBlur={handleBlur(`parameter${item.id}`)}
                    onChange={handleChange(`parameter${item.id}`)}
                    key={item.id}
                    className={classes.param170}
                    InputProps={{
                      inputComponent:
                        item.name !== 'Year of manufacture'
                          ? (NumberFormatCustom as any)
                          : undefined,
                    }}
                  />
                );
              }
              return (
                <TextField
                  type='text'
                  label={item.name}
                  color='primary'
                  name={`parameter${item.id}`}
                  // @ts-ignore
                  value={values[`parameter${item.id}`] || ''}
                  variant='outlined'
                  onBlur={handleBlur(`parameter${item.id}`)}
                  onChange={handleChange(`parameter${item.id}`)}
                  key={item.id}
                  className={classes.param170}
                  InputProps={{
                    inputComponent:
                      item.name !== 'Alkohol %Vol.' ? (NumberFormatCustom as any) : undefined,
                  }}
                />
              );
            }
            if (item.type === 'enum') {
              return (
                <>
                  <Autocomplete
                    options={
                      item.parameter_enum_values?.sort((a, b) =>
                        // eslint-disable-next-line no-nested-ternary
                        a.favorite === b.favorite ? 0 : a.favorite ? -1 : 1
                      ) || []
                    }
                    autoHighlight
                    className={classes.paramEnum}
                    value={item.parameter_enum_values?.find(
                      i => i.value === values[`parameter${item.id}`]
                    )}
                    onChange={(_event: any, newValue) => {
                      setFieldValue(`parameter${item.id}`, newValue?.value || '');
                    }}
                    groupBy={i =>
                      intl.formatMessage({
                        id: i.favorite ? 'POPULAR.VARIANTS' : 'OTHER.VARIANTS',
                      })
                    }
                    noOptionsText={intl.formatMessage({ id: 'CATEGORIES.TABLE.EMPTY' })}
                    loadingText={intl.formatMessage({ id: 'COMMON.LOADING' })}
                    getOptionLabel={option =>
                      option.value ? option.translations?.[intl.locale] || option.value : ''
                    }
                    renderOption={(props, option) => (
                      <Box
                        component='li'
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        {...props}
                        value={option.value}
                      >
                        {option.translations?.[intl.locale] || option.value}
                      </Box>
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={item.names?.[intl.locale] || item.name}
                        variant='outlined'
                        inputProps={{
                          ...params.inputProps,
                          style: { fontFamily: 'Open Sans' },
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </>
              );
            }

            if (item.type === 'string') {
              return (
                <TextField
                  key={item.id}
                  type='text'
                  variant='outlined'
                  color='primary'
                  label={item.name}
                  name={`parameter${item.id}`}
                  // @ts-ignore
                  value={values[`parameter${item.id}`] || ''}
                  onBlur={handleBlur(`parameter${item.id}`)}
                  onChange={handleChange(`parameter${item.id}`)}
                  className={classes.param170}
                />
              );
            }
            if (item.type === 'text') {
              return (
                <TextField
                  type='text'
                  label={item.name || ''}
                  color='primary'
                  name={`parameter${item.id}`}
                  // @ts-ignore
                  value={values[`parameter${item.id}`] || ''}
                  variant='outlined'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  multiline
                  rows={4}
                  className={classes.paramText}
                />
              );
            }

            if (item.type === 'multiple_enum') {
              return (
                <>
                  <Autocomplete
                    options={
                      item.parameter_enum_values?.sort((a, b) =>
                        // eslint-disable-next-line no-nested-ternary
                        a.favorite === b.favorite ? 0 : a.favorite ? -1 : 1
                      ) || []
                    }
                    multiple
                    autoHighlight
                    value={
                      values[`parameter${item.id}`]
                        ? values[`parameter${item.id}`].map((i: string) => {
                            const find = item.parameter_enum_values?.find(
                              el => el.value === i
                            );
                            return find;
                          })
                        : []
                    }
                    onChange={(_event: any, newValue) => {
                      newValue &&
                        setFieldValue(
                          `parameter${item.id}`,
                          newValue.map(i => i?.value)
                        );
                    }}
                    groupBy={i =>
                      intl.formatMessage({
                        id: i?.favorite ? 'POPULAR.VARIANTS' : 'OTHER.VARIANTS',
                      })
                    }
                    className={classes.paramEnum}
                    noOptionsText={intl.formatMessage({ id: 'CATEGORIES.TABLE.EMPTY' })}
                    loadingText={intl.formatMessage({ id: 'COMMON.LOADING' })}
                    disableClearable
                    getOptionLabel={option =>
                      option.value ? option.translations?.[intl.locale] || option.value : ''
                    }
                    renderOption={(props, option) => (
                      <Box
                        component='li'
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        {...props}
                        value={option?.value || ''}
                      >
                        {option.translations?.[intl.locale] || option.value}
                      </Box>
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={item.names?.[intl.locale] || item.name}
                        variant='outlined'
                        inputProps={{
                          ...params.inputProps,
                          style: { fontFamily: 'Open Sans' },
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </>
              );
            }

            if (item.type === 'country') {
              return (
                <>
                  <Autocomplete
                    id='country-select-demo'
                    options={filterCountries.sort((a, b) =>
                      // eslint-disable-next-line no-nested-ternary
                      a.favorite === b.favorite ? 0 : a.favorite ? -1 : 1
                    )}
                    autoHighlight
                    value={filterCountries.find(i => i.name === values[`parameter${item.id}`])}
                    onChange={(_event: any, newValue) => {
                      newValue && setFieldValue(`parameter${item.id}`, newValue.name);
                    }}
                    groupBy={i =>
                      intl.formatMessage({ id: i.favorite ? 'FAVORITES' : 'OTHER' })
                    }
                    noOptionsText={intl.formatMessage({ id: 'CATEGORIES.TABLE.EMPTY' })}
                    loadingText={intl.formatMessage({ id: 'COMMON.LOADING' })}
                    disableClearable
                    className={classes.paramEnum}
                    getOptionLabel={option =>
                      intl.locale === 'ru' ? option.ru_name : option.en_name
                    }
                    renderOption={(props, option) => (
                      <Box
                        component='li'
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        {...props}
                        value={option.id}
                      >
                        {intl.locale === 'ru' ? option.ru_name : option.en_name} +{option.code}
                      </Box>
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={item.names?.[intl.locale] || item.name}
                        variant='outlined'
                        inputProps={{
                          ...params.inputProps,
                          style: { fontFamily: 'Open Sans' },
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </>
              );
            }
          })}
        </div>
      )}
    </div>
  );
};

export default React.memo(TypeParams);
