import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import objectPath from 'object-path';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { removeCSSClass } from '../../utils/utils';
import * as builder from '../../ducks/builder';
import { MenuList } from './MenuList';
import KTUtil from '../../_assets/js/util';
import { IAppState } from '../../../app/store/rootDuck';

type TProps = {
  ulClasses?: any;
  asideClassesFromConfig?: any;
  config?: any;
};

const Menu: React.FC<TProps> = ({ ulClasses, asideClassesFromConfig, config }) => {
  const location = useLocation();
  const {
    menuConfig,
    layoutConfig,
    headerLogo,
    asideMenuAttr,
    disableAsideSelfDisplay,
  } = useSelector((store: IAppState) => ({
    menuConfig: store.builder.menuConfig,
    layoutConfig: store.builder.layoutConfig,
    headerLogo: builder.selectors.getLogo(store),
    asideMenuAttr: builder.selectors.getAttributes(store, { path: 'aside_menu' }),
    disableAsideSelfDisplay:
      builder.selectors.getConfig(store, 'aside.self.display') === false,

    ulClasses: builder.selectors.getClasses(store, {
      path: 'aside_menu_nav',
      toString: true,
    }),

    asideClassesFromConfig: builder.selectors.getClasses(store, {
      path: 'aside_menu',
      toString: true,
    }),
  }));

  const asideMenuRef: any = useRef();

  let insideTm: NodeJS.Timeout | null;
  let outsideTm: NodeJS.Timeout | null;

  useEffect(() => {
    initMenu(); // By ID

    const options = setupMenuOptions();
  }, []);

  const initMenu = useCallback(() => {
    if (
      objectPath.get(config, 'aside.menu.dropdown') !== 'true' &&
      objectPath.get(config, 'aside.self.fixed')
    ) {
      asideMenuRef?.current.setAttribute('data-ktmenu-scroll', '1');
    }

    if (objectPath.get(config, 'aside.menu.dropdown') === 'true') {
      asideMenuRef?.current.setAttribute('data-ktmenu-dropdown', '1');
      asideMenuRef?.current.setAttribute(
        'data-ktmenu-dropdown-timeout',
        objectPath.get(config, 'aside.menu.submenu.dropdown.hover-timeout')
      );
    }
  }, [config, asideMenuRef]);

  const setupMenuOptions = useCallback(() => {
    let menuOptions = {
      // vertical scroll
      scroll: null,
      // submenu setup
      submenu: {
        desktop: {
          // by default the menu mode set to accordion in desktop mode
          default: 'dropdown',
        },
        tablet: 'accordion', // menu set to accordion in tablet mode
        mobile: 'accordion', // menu set to accordion in mobile mode
      },
      // accordion setup
      accordion: {
        expandAll: false, // allow having multiple expanded accordions in the menu
      },
    };

    // init aside menu
    let menuDesktopMode = 'accordion';
    const dataKtmenuDropdown = asideMenuRef.current.getAttribute('data-ktmenu-dropdown');
    if (dataKtmenuDropdown === '1') {
      menuDesktopMode = 'dropdown';
    }

    if (typeof objectPath.get(menuOptions, 'submenu.desktop') === 'object') {
      menuOptions.submenu.desktop.default = menuDesktopMode;
    }

    return menuOptions;
  }, [asideMenuRef]);

  const mouseEnter = () => {
    // check if the left aside menu is fixed
    if (document.body.classList.contains('kt-aside--fixed')) {
      if (outsideTm) {
        clearTimeout(outsideTm);
        outsideTm = null;
      }

      insideTm = setTimeout(() => {
        // if the left aside menu is minimized
        if (
          document.body.classList.contains('kt-aside--minimize') &&
          // eslint-disable-next-line no-undef
          KTUtil.isInResponsiveRange('desktop')
        ) {
          removeCSSClass(document.body, 'kt-aside--minimize');
          document.body.classList.add('kt-aside--minimize-hover');
        }
      }, 50);
    }
  };

  const mouseLeave = () => {
    if (document.body.classList.contains('kt-aside--fixed')) {
      if (insideTm) {
        clearTimeout(insideTm);
        insideTm = null;
      }

      outsideTm = setTimeout(() => {
        // if the left aside menu is expand
        /* eslint-disable-next-line  */
        const kUtilIsResponsiveRange = KTUtil.isInResponsiveRange('desktop');
        const hasMinimizeHover = document.body.classList.contains('kt-aside--minimize-hover');
        if (
          hasMinimizeHover &&
          // eslint-disable-next-line no-undef
          kUtilIsResponsiveRange
        ) {
          // hide back the left aside menu
          removeCSSClass(document.body, 'kt-aside--minimize-hover');
          document.body.classList.add('kt-aside--minimize');
        }
      }, 100);
    }
  };

  return (
    <>
      <div
        id='kt_aside_menu'
        ref={asideMenuRef}
        style={{ maxHeight: '90vh', position: 'relative' }}
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
        data-ktmenu-vertical='1'
        className={clsx('kt-aside-menu', asideClassesFromConfig)}
        {...asideMenuAttr}
      >
        {disableAsideSelfDisplay && (
          <div className='kt-header-logo'>
            <Link to=''>
              <img alt='logo' src={headerLogo} />
            </Link>
          </div>
        )}
        <ul className={clsx('kt-menu__nav', ulClasses)}>
          <MenuList
            currentUrl={location.pathname}
            menuConfig={menuConfig}
            layoutConfig={layoutConfig}
          />
        </ul>
      </div>
    </>
  );
};

export default Menu;
