import { IntlShape } from 'react-intl';
import { IBreadcrumb } from '../../../../interfaces/breadcrumb';

export const storeBreadCrumbs = (intl: IntlShape): IBreadcrumb[] => {
  const breadCrumbs: IBreadcrumb[] = [];
  breadCrumbs.push({
    title: intl.formatMessage({ id: 'SUBMENU.STORE' }),
    page: 'stores/list',
    root: true,
  });
  return breadCrumbs;
};
