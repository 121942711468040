import React from 'react';
import { ButtonProps } from '@mui/material/Button';
import { Button, createTheme, ThemeProvider } from '@mui/material';
import Preloader from '../../ui/Preloader/Preloader';

type ButtonPropsOmit = Omit<ButtonProps, 'color'>;
interface IColorButton extends ButtonPropsOmit {
  color: string;
  darkColor?: string;
  onClick?: () => {} | void;
  title?: string;
  disable?: boolean;
  style?: object;
  loading?: boolean;
}

const ColorButton: React.FC<IColorButton> = props => {
  const { children, color, onClick, disable, style, loading, ...scrap } = props;
  const theme = createTheme({
    palette: {
      primary: {
        main: color,
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      {loading ? (
        <Preloader />
      ) : (
        <Button
          {...scrap}
          style={style}
          onClick={onClick}
          color='primary'
          variant='contained'
          disableElevation
          disabled={disable}
        >
          {children}
        </Button>
      )}
    </ThemeProvider>
  );
};

export default ColorButton;
