import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getResponseMessage } from '../../../../../utils/utils';
import { useFormatMessage } from '../../../../../hooks';
import { getStores } from '../../../../../crud/store.crud';
import { IStore } from '../../../../../interfaces/store';

export const useGetStores = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [total, setTotal] = useState(0);
  const [stores, setStores] = useState<IStore[]>([]);
  const dispatch = useDispatch();
  const fm = useFormatMessage();

  const fetchStores = useCallback(
    async (
      page: number,
      perPage: number,
      companyId?: number,
    ) => {
      setLoading(true);
      getStores(page, perPage, companyId, )
        .then(res => {
          setTotal(res.data.total);
          setPage(res.data.page);
          setPerPage(res.data.per_page);
          setStores(res.data.data);
        })
        .catch(e => {
          enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`, { variant: 'error' });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [dispatch]
  );

  return { fetchStores, loading, page, perPage, total, stores };
};
