import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => {
  return {
    box: {
      height: 10,
      width: 10,
      borderRadius: '50%',
    },
  };
});

interface IProps {
  isActive?: boolean | null;
}

const StatusIndicator: React.FC<IProps> = ({ isActive }) => {
  const classes1 = useStyles();
  const { classes } = classes1;

  return (
    <Box
      className={classes.box}
      boxShadow={isActive ? 3 : 0}
      bgcolor={isActive ? '#0abb87' : 'lightgrey'}
    />
  );
};

export default StatusIndicator;
