import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  lightH1: {
    fontWeight: 300,
    fontSize: 93,
    lineHeight: '93px',
    letterSpacing: -1.5,
  },
  boldH1: {
    fontWeight: 'bold',
    fontSize: 93,
    lineHeight: '93px',
    letterSpacing: -1.5,
  },
  lightH2: {
    fontWeight: 300,
    fontSize: 58,
    lineHeight: '58px',
    letterSpacing: -0.5,
  },
  boldH2: {
    fontWeight: 'bold',
    fontSize: 58,
    lineHeight: '58px',
    letterSpacing: -0.5,
  },
  regularH3: {
    fontWeight: 'normal',
    fontSize: 47,
    lineHeight: '47px',
    letterSpacing: 0,
  },
  boldH3: {
    fontWeight: 'bold',
    fontSize: 47,
    lineHeight: '47px',
    letterSpacing: 0,
  },
  regularH4: {
    fontWeight: 'normal',
    fontSize: 33,
    lineHeight: '33px',
    letterSpacing: 0.25,
  },
  boldH4: {
    fontWeight: 'bold',
    fontSize: 33,
    lineHeight: '33px',
    letterSpacing: 0.25,
  },
  regularH5: {
    fontWeight: 'normal',
    fontSize: 23,
    lineHeight: '23px',
    letterSpacing: 0,
  },
  semiboldH5: {
    fontWeight: 600,
    fontSize: 23,
    lineHeight: '23px',
    letterSpacing: 0,
  },
  mediumH6: {
    fontWeight: 300,
    fontSize: 19,
    lineHeight: '19px',
    letterSpacing: 0.15,
  },
  semiboldH6: {
    fontWeight: 600,
    fontSize: 19,
    lineHeight: '19px',
    letterSpacing: 0.15,
  },
  regularSubtitle1: {
    fontWeight: 'normal',
    fontSize: 16,
    lineHeight: '16px',
    letterSpacing: 0.15,
  },
  semiboldSubtitle1: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '16px',
    letterSpacing: 0.15,
  },
  mediumSubtitle2: {
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '14px',
    letterSpacing: 0.11,
  },
  semiboldSubtitle2: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '14px',
    letterSpacing: 0.11,
  },
  body1: {
    fontSize: 16,
    lineHeight: '19.2px',
  },
  body2: {
    fontSize: 14,
    lineHeight: '16.8px',
    letterSpacing: 0.25,
  },
  semiboldButton: {
    fontSize: 16,
    lineHeight: '16px',
    fontWeight: 600,
  },
  caption: {
    fontSize: 12,
    lineHeight: '12px',
    letterSpacing: 0.4,
    fontWeight: 'normal',
  },
  overline: {
    fontSize: 10,
    lineHeight: '10px',
    letterSpacing: 1.5,
  },
}));
