import { useMemo } from 'react';
import { IProduct } from '../../../../interfaces/product';
import { IUser } from '../../../../interfaces/user';

export const useDefineProductCompany = (
  me: IUser | undefined,
  product: IProduct | undefined,
  companyIdProp: string | undefined,
  companyNameProp: string | undefined
) => {
  return useMemo(() => {
    // when edit product
    if (product) {
      return { id: product.company?.id, name: product.company?.name };
    }

    // when create new product
    if (me?.company) {
      return { id: me.company.id, name: me.company.name };
    }
    if (companyIdProp) {
      return { id: Number(companyIdProp), name: companyNameProp || '' };
    }

    return undefined;
  }, [product, me, companyIdProp, companyNameProp]);
};
