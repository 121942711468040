import React from 'react';
import {
  Card,
  CardContent,
  TextField,
  MenuItem,
  Typography,
  Theme,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { useFormatMessage } from '../../../../hooks';

const useTreeItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardCategory: {
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    labelTitle: {
      fontWeight: 700,
      flexGrow: 1,
      fontSize: 14,
      color: '#282a3c',
    },
    menuItem: {
      fontSize: 13,
    },
  })
);

type TProps = {
  activity: string;
  setActivity: (value: string) => void;
  margin?: boolean;
};

const FilterActivity: React.FC<TProps> = ({ activity, setActivity, margin = true }) => {
  const fm = useFormatMessage();
  const classes = useTreeItemStyles();

  return (
    <Card
      style={{ padding: !margin ? 0 : '', margin: !margin ? 0 : '' }}
      className={classes.cardCategory}
    >
      <CardContent style={{ padding: !margin ? 0 : '', margin: !margin ? 0 : '' }}>
        <Typography
          variant='h5'
          className={classes.labelTitle}
          style={{ marginBottom: !margin ? 5 : 13 }}
        >
          {fm('PRODUCT.TABLE.ACTIVE')}
        </Typography>
        <TextField
          select
          margin='normal'
          color='primary'
          value={activity || 'all'}
          variant='outlined'
          onChange={e => setActivity(e.target.value)}
          style={{ marginTop: !margin ? 5 : '', padding: 0 }}
        >
          <MenuItem color='secondary' value='all' className={classes.menuItem}>
            {fm('ACTIVITY.ALL')}
          </MenuItem>
          <MenuItem color='secondary' value='active' className={classes.menuItem}>
            {fm('ACTIVITY.ACTIVE')}
          </MenuItem>
          <MenuItem color='secondary' value='no_active' className={classes.menuItem}>
            {fm('ACTIVITY.NO_ACTIVE')}
          </MenuItem>
        </TextField>
      </CardContent>
    </Card>
  );
};

export default FilterActivity;
