import React, { FC, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Card, CardActions, CardContent } from '@mui/material';
import clsx from 'clsx';
import SelectFilterType from './SelectFilterType';
import { IParams } from '../hooks/useCustomParams';
import { IProductFilter } from '../../../../interfaces/product';
import { IProductType } from '../../../../interfaces/productType';
import { useStylesFilterType } from '../hooks/useStyles';

interface IProps {
  resetProductTypes: (categoryId?: number | null, newFilter?: IProductFilter) => void;
  onChangeParam: (value: IParams) => void;
  setSearch: any;
  searchProducts: any;
  getFilters: any;
  productTypeFilters: any;
  productTypes: any;
  filterData: any;
  setFilterData: React.Dispatch<any>;
  selectedCategoryId: number | null;
  priceFrom: string | number;
  priceTo: string | number;
  filter: IProductFilter;
  locationStateFilter?: IProductFilter;
  productTypeId?: string;
  setPriceFrom: React.Dispatch<React.SetStateAction<string | number>>;
  setPriceTo: React.Dispatch<React.SetStateAction<string | number>>;
  setLoadingTypeId: (value: boolean) => void;
  setNewType: (value: number | null | 'disabled') => void;
  newTypeId: number | 'disabled' | null;
}

const FilterType: FC<IProps> = ({
  resetProductTypes,
  onChangeParam,
  setSearch,
  searchProducts,
  getFilters,
  productTypeFilters,
  productTypes,
  filterData,
  setFilterData,
  selectedCategoryId,
  locationStateFilter,
  productTypeId,
  priceFrom,
  priceTo,
  setPriceFrom,
  setPriceTo,
  newTypeId,
  setNewType,
}) => {
  const classes1 = useStylesFilterType();
  const {classes} = classes1
  const intl = useIntl();
  const [selectedTypeId, setSelectedTypeId] = useState<number | null>(null);
  const [parameters, setParameters] = useState();
  const [selected, setSelected] = useState<{ label: string; value: string | number }>({
    label: intl.formatMessage({ id: 'PRODUCT.TYPES.ALL' }),
    value: '',
  });

  // const checkProductTypeSearch = useCallback(() => {
  //   const { productType } = objectParams;
  //   productType && setLoadingTypeId(true);
  //   if (productType && productTypes) {
  //     const type = productTypes.find((i: IProductType) => i.id?.toString() === productType);
  //     if (type && type.id) {
  //       setSelected({ label: type.name, value: type.id });
  //       setSelectedTypeId(type.id);
  //       getFilters(type.id);
  //     } else {
  //       clearAllParam(['category', 'priceFrom', 'priceTo']);
  //     }
  //   }
  // }, [objectParams, productTypes, clearAllParam]);

  // useEffect(() => {
  //   if (productTypes) {
  //     const type = productTypes.find(
  //       (i: IProductType) => i.id?.toString() === selected.value.toString()
  //     );
  //     if (!type) {
  //       // clearAllParam(['category', 'productType', 'priceFrom', 'priceTo']);
  //       // setFilterData({});
  //       // setSelected({
  //       //   label: intl.formatMessage({ id: 'PRODUCT.TYPES.ALL' }),
  //       //   value: '',
  //       // });
  //     }
  //   }
  // }, [productTypes]);

  // useEffect(() => {
  //   productTypes && checkProductTypeSearch();
  // }, [productTypes]);

  useEffect(() => {
    if (selectedTypeId) {
      localStorage.setItem('selectedTypeId', selectedTypeId.toString());
    }
  }, [selectedTypeId]);

  useEffect(() => {
    if (newTypeId !== 'disabled') {
      if (newTypeId) {
        const type = productTypes.find(
          (item: IProductType) => item.id?.toString() === newTypeId.toString()
        );
        setSelectedTypeId(newTypeId);
        type && type.id && setSelected({ label: type.name, value: type.id });
        type && getFilters({ id: type.id, filters: true });
      } else {
        setSelectedTypeId(null);
        setSelected({ label: intl.formatMessage({ id: 'PRODUCT.TYPES.ALL' }), value: '' });
      }
      setNewType('disabled');
    }
  }, [newTypeId]);

  // useEffect(() => {
  //   const { productType } = objectParams;
  //   const storeTypeFilter = localStorage.getItem('typeFilters');
  //   const storeTypeFilterParse = storeTypeFilter && JSON.parse(storeTypeFilter);
  //   if (storeTypeFilterParse) {
  //     if (productType && productType.toString() === storeTypeFilterParse.value.toString()) {
  //       storeTypeFilterParse.value && setParams('productType', storeTypeFilterParse.value);
  //       const data = storeTypeFilterParse;
  //       setSelected(data);
  //       data.value && getFilters(data.value);
  //     }
  //   }
  // }, []);

  const handleChange = (parameter: number, values: string[], isDelete?: boolean) => {
    // setParams(`param${parameter}`, values.map((el: any) => (el === '' ? 0 : el)).toString());
    setFilterData((prev: any) => {
      const obj = { ...prev };
      if (isDelete) {
        delete obj[parameter];
      } else {
        obj[parameter] = { values };
      }
      return obj;
    });
  };

  const deleteFilteData = (key: number) => {
    if (!filterData[key]) return;
    setFilterData((current: any) => {
      const copy = { ...current };
      // deleteSearchParam && deleteParam(`param${key}`);
      delete copy[key];
      return copy;
    });
  };

  const fetch = useCallback(
    (parameters: any, typeId?: number) => {
      if (priceFrom.toString().length > 0) {
        // setParams('priceFrom', priceFrom.toString());
        localStorage.setItem('priceFrom', priceFrom.toString());
      } else {
        // deleteParam('priceFrom');
        localStorage.removeItem('priceFrom');
      }
      if (priceTo.toString().length > 0) {
        // setParams('priceTo', priceTo.toString());
        localStorage.setItem('priceTo', priceTo.toString());
      } else {
        // deleteParam('priceTo');
        localStorage.removeItem('priceTo');
      }
      // eslint-disable-next-line guard-for-in
      // for (const key in filterData) {
      // setParams(
      //   `param${key}`,
      //   filterData[key].values.map((el: any) => (el === '' ? 0 : el)).toString()
      // );
      // }
      setSearch(true);
      if (typeId || priceFrom || priceTo) {
        searchProducts({
          categoryId: selectedCategoryId || 0,
          filter: { parameters, price_from: priceFrom, price_to: priceTo },
        });
      } else {
        selectedTypeId &&
          searchProducts({
            categoryId: selectedCategoryId || 0,
            filter: { parameters, price_from: priceFrom, price_to: priceTo },
          });
      }
    },
    [selectedTypeId, selectedCategoryId, priceFrom, priceTo]
  );

  useEffect(() => {
    locationStateFilter?.parameters && setParameters(locationStateFilter.parameters);
  }, [locationStateFilter]);

  useEffect(() => {
    if (productTypes && productTypeId) {
      const type = productTypes.find(
        (item: IProductType) => item.id?.toString() === productTypeId.toString()
      );
      setSelectedTypeId(+productTypeId);
      type && type.id && setSelected({ label: type.name, value: type.id });
      // if (locationStateFilter?.parameters) {
      //   const params = [];
      //   // eslint-disable-next-line guard-for-in
      //   for (const key in locationStateFilter.parameters) {
      //     params.push({ parameter: +key, values: locationStateFilter.parameters[key] });
      //   }
      //   fetch(params, +productTypeId);
      // }
    }
  }, [productTypes, productTypeId]);

  const select = (id: number | null) => {
    setSelectedTypeId(id);
  };

  const handleItem = (id: number | null) => {
    select(id);
  };

  const handleRootLabel = useCallback(
    (item: IProductType) => {
      if (item.id) {
        handleItem(item.id);
      } else {
        handleItem(null);
      }
    },
    [select]
  );
  const clear = () => {
    resetProductTypes(selectedCategoryId, {
      parameters: [],
      price_from: '',
      price_to: '',
    });
    if (!selectedCategoryId) setSearch(!!selectedCategoryId);
    setSelectedTypeId(null);
    setSelected({ label: intl.formatMessage({ id: 'PRODUCT.TYPES.ALL' }), value: '' });
    // clearAllParam(['category']);
    localStorage.removeItem('filter');
    localStorage.removeItem('selectedTypeId');
    localStorage.removeItem('priceTo');
    localStorage.removeItem('priceFrom');
    setFilterData({});
    setPriceFrom('');
    setPriceTo('');
  };

  // useEffect(() => {
  //   // eslint-disable-next-line guard-for-in
  //   for (const key in filterData) {
  //     setParams(
  //       `param${key}`,
  //       filterData[key].values.map((el: any) => (el === '' ? 0 : el)).toString()
  //     );
  //   }
  // }, [filterData]);

  const submit = () => {
    const data = [];
    // const skipValues = Object.keys(filterData);
    // clearAllParam([
    //   'category',
    //   'productType',
    //   'priceFrom',
    //   'priceTo',
    //   ...skipValues.map(i => `param${i}`),
    // ]);
    // eslint-disable-next-line guard-for-in
    for (const key in filterData) {
      // setParams(
      //   `param${key}`,
      //   filterData[key].values.map((el: any) => (el === '' ? 0 : el)).toString()
      // );
      data.push({ parameter: Number(key), ...filterData[key] });
    }
    fetch(data);
  };

  return (
    <form>
      <Card className={classes.cardCategory}>
        <CardContent>
          <SelectFilterType
            onChangeParam={onChangeParam}
            onChange={handleRootLabel}
            productTypeFilters={productTypeFilters}
            getFilters={getFilters}
            handleChange={handleChange}
            productTypes={productTypes}
            selected={selected}
            setSelected={(event: any) => {
              // setParams('productType', event.value);
              // clearAllParam(['category', 'productType', 'priceFrom', 'priceTo']);
              setFilterData({});
              setSelected(event);
            }}
            deleteFilteData={deleteFilteData}
            setFilterData={setFilterData}
            parameters={parameters}
            setParameters={setParameters}
            filterData={filterData}
            priceFrom={priceFrom}
            priceTo={priceTo}
            setPriceFrom={setPriceFrom}
            setPriceTo={setPriceTo}
          />
        </CardContent>
        <CardActions className={classes.cardButtons}>
          <Button
            className={clsx(classes.button)}
            color='primary'
            variant='outlined'
            onClick={clear}
          >
            {intl.formatMessage({ id: 'PRODUCT.FILTER.RESET' })}
          </Button>
          <Button
            className={clsx(classes.button)}
            color='primary'
            variant='contained'
            onClick={submit}
          >
            {intl.formatMessage({ id: 'PRODUCT.FILTER.SEARCH' })}
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};

export default FilterType;
