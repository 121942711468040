import { useCallback, useState } from 'react';
import { useShowErrors } from './useShowErrors';

// https://developers.cloudpayments.ru/#ustanovka-vidzheta
declare global {
  interface Window {
    cp: any;
  }
}

export const useOpenPayWidget: () => [
  (userId: number, price: number, callBack: () => void) => void,
  boolean
] = () => {
  const [err, setErr] = useState(null);
  const [success, setSuccess] = useState(false);
  useShowErrors([err]);

  const payOperation = useCallback((orderId: number, price: number, callBack: () => void) => {
    const widget = new window.cp.CloudPayments();

    const options = {
      publicId: 'test_api_00000000000000000000002',
      description: 'Оплата товара',
      amount: price,
      currency: 'RUB',
      // accountId: 'user@example.com',
      // invoiceId: '1234567',
      skin: 'modern',
      data: {
        order_id: orderId,
      },
    };
    const callbacks = {
      // onSuccess: (options: any) => {
      onSuccess: () => {
        callBack();
        setSuccess(true);
      },
      // onFail: (reason: any, options: any) => {
      onFail: (reason: any) => {
        // console.log('onFail:', reason, options);
        if (reason !== 'User has cancelled') {
          setErr(reason);
          setErr(null);
        }
      },
      // onComplete: (paymentResult: any, options: any) => {
      onComplete: () => {
        // console.log('onComplete:', paymentResult, options);
      },
    };
    widget.pay('auth', options, callbacks);
  }, []);
  return [payOperation, success];
};
